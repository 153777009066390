import IntlMessages from "@crema/utility/IntlMessages";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
  SxProps,
  TablePagination,
  Theme,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import FillProductTask40Icon from "components/Icons/FillProductTask40Icon";
import StarFilledIcon from "components/Icons/StarFilledIcon";
import React, { useCallback, useMemo, useState } from "react";
import { textColor } from "shared/constants/AppConst";

import AppScrollbar from "../AppScrollbar";
import AppTooltip from "../AppTooltip";

interface StarredModel {
  id: number | string;
  taskId?: string;
  taskUrl?: string;
  taskName?: string;
  productName?: string;
}

const initStarredList: StarredModel[] = [
  {
    id: "1",
    taskId: "TK#001",
    taskUrl: "/products/123/deliverables/Risk Management/System Hazard Analysis/sha/100/worksheet",
    taskName: "System Hazard Analysis (V1.0)",
    productName: "Thermometers",
  },
  {
    id: "2",
    taskId: "TK#014",
    taskUrl: "/products/123/deliverables/Risk Management/Design FMEA/df/101/worksheet",
    taskName: "Design FMEA (V1.1)",
    productName: "Thermometers",
  },
  {
    id: "3",
    taskId: "TK#003",
    taskUrl: "/products/123/deliverables/Design Controls/User Needs/und/101/worksheet",
    taskName: "User Needs (V1.1)",
    productName: "Thermometers",
  },
  {
    id: "4",
    taskId: "TK#003",
    taskUrl: "/products/123/deliverables/Design Controls/User Needs/und/101/worksheet",
    taskName: "User Needs (V1.1)",
    productName: "Thermometers",
  },
  {
    id: "5",
    taskId: "TK#003",
    taskUrl: "/products/123/deliverables/Design Controls/User Needs/und/101/worksheet",
    taskName: "User Needs (V1.1)",
    productName: "Thermometers",
  },
  {
    id: "6",
    taskId: "TK#003",
    taskUrl: "/products/123/deliverables/Design Controls/User Needs/und/101/worksheet",
    taskName: "User Needs (V1.1)",
    productName: "Thermometers",
  },
  {
    id: "7",
    taskId: "TK#003",
    taskUrl: "/products/123/deliverables/Design Controls/User Needs/und/101/worksheet",
    taskName: "User Needs (V1.1)",
    productName: "Thermometers",
  },
  {
    id: "8",
    taskId: "TK#003",
    taskUrl: "/products/123/deliverables/Design Controls/User Needs/und/101/worksheet",
    taskName: "User Needs (V1.1)",
    productName: "Thermometers",
  },
  {
    id: "9",
    taskId: "TK#003",
    taskUrl: "/products/123/deliverables/Design Controls/User Needs/und/101/worksheet",
    taskName: "User Needs (V1.1)",
    productName: "Thermometers",
  },
  {
    id: "10",
    taskId: "TK#003",
    taskUrl: "/products/123/deliverables/Design Controls/User Needs/und/101/worksheet",
    taskName: "User Needs (V1.1)",
    productName: "Thermometers",
  },
  {
    id: "11",
    taskId: "TK#003",
    taskUrl: "/products/123/deliverables/Design Controls/User Needs/und/101/worksheet",
    taskName: "User Needs (V1.1)",
    productName: "Thermometers",
  },
];

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  ".starred-top": {
    padding: theme.spacing(3, 6),
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  ".starred-divider": {
    height: "16px",
    alignSelf: "auto",
  },
  ".starred-title": {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "1.44",
    color: "rgba(0, 0, 0, 0.85)",
  },
  ".remove-btn-text": {
    fontSize: "14px",
    color: textColor[85],
    fontWeight: "normal",
  },
  ".header-actions": {
    ".markAllAsRead-btn": {
      marginRight: "6px",
    },
    ".MuiFormControlLabel-root": {
      marginLeft: "0",
    },
  },
  ".starred-box": {
    maxHeight: "calc(100vh - 180px)",
    width: "548px",
    overflowY: "auto",
  },
  ".starred-list": {
    width: "100%",
    paddingLeft: "24px",
    paddingRight: "24px",
    backgroundColor: "#FFF",
    ".MuiListItem-root": {
      padding: theme.spacing(5, 0),
      maxWidth: "640px",
      width: "500px",
      ".MuiListItemAvatar-root": {
        marginTop: "0",
        marginRight: "12px",
        minWidth: "44px",
      },
      ".title": {
        lineHeight: "22px",
        fontWeight: "bold",
        color: textColor[85],
      },
      ".datetime": {
        color: "rgba(0, 0, 0, 0.45)",
      },
      ".task-productName": {
        fontSize: "12px",
        lineHeight: "20px",
        color: textColor[45],
      },
    },
  },
}));

interface AppsStarredIconProps {
  drawerPosition?: "left" | "top" | "right" | "bottom";
  tooltipPosition?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  isMenu?: boolean;
  sxStarredIconStyle?: SxProps<Theme>;
}

const AppsStarredIcon: React.FC<AppsStarredIconProps> = ({
  drawerPosition = "right",
  tooltipPosition = "bottom",
  isMenu = false,
  sxStarredIconStyle = {},
}) => {
  const [starredList, setStarredList] = useState<StarredModel[]>(initStarredList);

  const [showMessage, setShowMessage] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(100);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRemoveStarred = useCallback(
    (value: StarredModel) => {
      let newStarredList = starredList.filter((x) => x.id !== value.id);
      setStarredList(newStarredList);
    },
    [starredList]
  );
  const handleRemoveAll = () => {
    setStarredList([]);
  };

  const list = useMemo(() => {
    if (!starredList) {
      return <></>;
    }
    const listDom = [];
    starredList.forEach((x, index) => {
      listDom.push(
        <React.Fragment key={`React.Fragment-${x.id}`}>
          <ListItem alignItems="flex-start" className="flex-wrap cursor-pointer">
            {/* <ListItemButton> */}
            <Box className="flex" sx={{ width: "100%" }}>
              <ListItemAvatar>
                <Box>
                  <FillProductTask40Icon />
                </Box>
              </ListItemAvatar>
              <Box className="flex items-center justify-between flex-wrap" sx={{ width: "100%" }}>
                <Box className="flex items-center justify-between" sx={{ width: "100%" }}>
                  <Link href={x.taskUrl} underline="none">
                    <Box className="title">
                      {x.taskId} {x.taskName}
                    </Box>
                  </Link>
                </Box>
                <Box className="task-productName" sx={{ width: "100%" }}>
                  {x.productName}
                </Box>
              </Box>
              <Box className="flex items-start">
                <IconButton
                  onClick={() => {
                    handleRemoveStarred(x);
                  }}
                  className="flex justify-center items-center"
                  sx={{ marginRight: "10px" }}
                >
                  <StarFilledIcon />
                </IconButton>
                <MoreHorizIcon />
              </Box>
            </Box>
            {/* </ListItemButton> */}
          </ListItem>
          {index === starredList.length - 1 ? "" : <Divider component="li" />}
        </React.Fragment>
      );
    });
    return listDom;
  }, [starredList, handleRemoveStarred]);

  return (
    <>
      {isMenu ? (
        <Box component="span" onClick={handleClick}>
          Message
        </Box>
      ) : (
        <AppTooltip title="Starred" placement={tooltipPosition}>
          <IconButton
            className="icon-btn"
            sx={{
              borderRadius: "50%",
              width: 30,
              height: 30,
              color: (theme) => theme.palette.text.secondary,
              // backgroundColor: (theme) => `transparent`,//theme.palette.background.default,
              // border: 1,
              borderColor: "transparent",
              "&:hover, &:focus": {
                color: (theme) => theme.palette.text.primary,
                backgroundColor: (theme) => alpha(theme.palette.background.default, 0.9),
                borderColor: (theme) => alpha(theme.palette.text.secondary, 0.25),
              },
            }}
            onClick={handleClick}
            size="large"
          >
            <StarBorderIcon />
          </IconButton>
        </AppTooltip>
      )}
      <Popover
        id="starred-box"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          padding: "12px 24px",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: "50%",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translate(50%, -50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Container>
          <Box className="starred-top flex justify-between items-center">
            <Box className="starred-title">
              <IntlMessages id="common.myStarred" />
            </Box>
            <Box className="header-actions flex items-center justify-between">
              <Box className="markAllAsRead-btn">
                <Button onClick={handleRemoveAll}>
                  <Box className="remove-btn-text">
                    <IntlMessages id="starred.remove.all" />
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>
          <AppScrollbar className="starred-box">
            <List className="starred-list">{list}</List>
          </AppScrollbar>

          <Box className="page-box" sx={{ padding: "0 24px" }}>
            <TablePagination
              component="div"
              count={total}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Container>
      </Popover>
    </>
  );
};

export default AppsStarredIcon;
