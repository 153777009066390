import Icon from "@mui/material/Icon";

const OtherTimeSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm1 3v4h3v2h-5V7h2z"
      fill="#000"
      fillRule="evenodd"
      opacity=".6"
    />
  </svg>
);

const OtherTimeSelectedSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm1 3v4h3v2h-5V7h2z"
      fill="#2e6ccb"
      fillRule="evenodd"
    />
  </svg>
);

interface OtherTimeIconProps {
  selected?: boolean;
}

const OtherTimeIcon = (props: OtherTimeIconProps) => {
  if (props.selected) {
    return <Icon component={OtherTimeSelectedSvg} />;
  } else {
    return <Icon component={OtherTimeSvg} />;
  }
};

export default OtherTimeIcon;
