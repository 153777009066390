import IntlMessages from "@crema/utility/IntlMessages";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { Box, Button, IconButton, Popover, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { textColor } from "shared/constants/AppConst";

import { SearchStarredAndRecentlyItemModel } from "./SearchStarredAndRecentlyModel";

const DropListContainer = styled(Box)(({ theme }) => ({
  minWidth: 350,
  padding: "8px",
  ".drop_list_header": {
    width: "100%",
  },
  ":& .MuiTextField-root": {
    width: "100%",
  },
  "& .MuiSvgIcon-root": {
    mr: 0.5,
  },
  "& .MuiInputBase-root": {
    padding: "0px 14px",
    height: "32px",
    width: "100%",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px",
  },
  "& .MuiInput-underline:before": {
    borderBottom: 1,
    borderColor: "divider",
  },
  ".drop_list_content": {
    marginTop: "8px",
  },
  ".drop_list_footer": {
    marginTop: "8px",
  },
  ".list_item": {
    position: "relative",
    width: "100%",
    padding: "8px 0px 8px 16px",
  },
  ".list_item:hover": {
    background: "#2e6ccb",
  },
  ".list_item_text": {
    color: "rgba(0,0,0,0.85)",
    cursor: "pointer",
  },
  ".list_item_icon": {
    position: "absolute",
    color: "rgb(245, 154, 35)",
    right: "8px",
    top: "50%",
    cursor: "pointer",
    transform: "translateY(-50%)",
  },
}));

interface SearchStarredAndRecentlyProps {
  current: string;
  placeholderId: string;
  starredList: SearchStarredAndRecentlyItemModel[];
  recentlyList: SearchStarredAndRecentlyItemModel[];
  starredTitleId: string;
  recentlyTitleId: string;
  viewButtonTextId?: string;
  handleViewButton: () => void;
}

const SearchStarredAndRecently: React.FC<SearchStarredAndRecentlyProps> = (props: SearchStarredAndRecentlyProps) => {
  const navigate = useNavigate();

  const {
    current,
    placeholderId,
    starredList,
    recentlyList,
    starredTitleId,
    recentlyTitleId,
    viewButtonTextId,
    handleViewButton,
  } = props;
  const { messages } = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [searchText, setSearchText] = React.useState<string>("");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const requestSearch = (value: string) => {
    setSearchText(value);
  };

  const starredToggle = (item: SearchStarredAndRecentlyItemModel) => {
    console.log(item);
  };

  return (
    <>
      <Typography
        color="text.primary"
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          color: textColor[85],
        }}
        onClick={handleClick}
      >
        {current} {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DropListContainer>
          <Box className="drop_list_header">
            <TextField
              variant="outlined"
              value={searchText}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value)}
              placeholder={String(messages[placeholderId])}
              InputProps={{
                startAdornment: (
                  <AppSvgIcon size={16} color="action">
                    {"material-outline:search"}
                  </AppSvgIcon>
                ),
                endAdornment: (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      style={{ visibility: searchText ? "visible" : "hidden" }}
                      onClick={() => {
                        requestSearch("");
                      }}
                    >
                      <AppSvgIcon size={16} color="action">
                        {"material-outline:clear"}
                      </AppSvgIcon>
                    </IconButton>
                  </Box>
                ),
              }}
            />
          </Box>
          <Box className="drop_list_content">
            <Box>
              <Typography color={"#2e6ccb"}>
                <IntlMessages id={starredTitleId} />
              </Typography>
              <Box>
                {starredList.map((item) => (
                  <Box key={item.id} className="list_item">
                    <Typography
                      onClick={() => {
                        navigate(item.url);
                        handleClose();
                      }}
                      className="list_item_text"
                    >
                      {item.name}
                    </Typography>
                    <StarIcon
                      onClick={() => {
                        starredToggle(item);
                      }}
                      className="list_item_icon"
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography color={"#2e6ccb"}>
                <IntlMessages id={recentlyTitleId} />
              </Typography>
              <Box>
                {recentlyList.map((item) => (
                  <Box key={item.id} className="list_item">
                    <Typography
                      onClick={() => {
                        navigate(item.url);
                        handleClose();
                      }}
                      className="list_item_text"
                    >
                      {item.name}
                    </Typography>
                    <StarOutlineIcon
                      onClick={() => {
                        starredToggle(item);
                      }}
                      className="list_item_icon"
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          {viewButtonTextId && (
            <Box className="drop_list_footer">
              <Button onClick={handleViewButton} sx={{ width: "100%" }} variant="contained">
                <IntlMessages id={viewButtonTextId} />
              </Button>
            </Box>
          )}
        </DropListContainer>
      </Popover>
    </>
  );
};

export default SearchStarredAndRecently;
