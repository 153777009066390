import IntlMessages from "@crema/utility/IntlMessages";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ListModel } from "components/CustomTable/CustomTableModel";
import TableToolBar from "components/CustomTable/TableToolBar";
import CustomizeIcon from "components/Icons/CustomizeIcon";
import ExportIcon from "components/Icons/ExportIcon";
import WordIcon from "components/Icons/WordIcon";
import React from "react";

import Gantt from "./Gantt";

const GanttChartContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  ".table_tree": {
    width: "100%",
    height: "calc(100vh - 290px)",
    paddingTop: "8px",
  },
}));

const GanttChart = () => {
  const [searchText, setSearchText] = React.useState("");
  const [filter1, setFilter1] = React.useState<any>("");
  const [filterList1] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter1",
    },
  ]);
  const [filter2, setFilter2] = React.useState<any>("");
  const [filterList2] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter2",
    },
  ]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openList = Boolean(anchorEl);

  const handleSelected = (item: any) => {
    console.log(item);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
  };

  return (
    <GanttChartContainer>
      <Box className="table_tree">
        <Gantt />
      </Box>
    </GanttChartContainer>
  );
};

export default GanttChart;
