import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import React, { useMemo, useState } from "react";

import ContentHeader from "../components/ContentHeader";
import AllItemDetails from "./AllItemDetails";
import LeftMenu, { MenuData } from "./components/LeftMenu";
import CoverageAnalysis from "./CoverageAnalysis";
import ExcelDefault from "./ExcelDefault";
import ExcelForRoundtrip from "./ExcelForRoundtrip";
import FMEARiskAnalysis from "./FMEARiskAnalysis";
import RequirementType from "./RequirementType";
import TestCaseType from "./TestCaseType";
import UserNeedType from "./UserNeedType";
import VerificationAndValidationType from "./VerificationAndValidationType";
import WordDefault from "./WordDefault";

const menuData: MenuData[] = [
  {
    index: 1,
    type: "AllItemDetails",
    text: "All Item Details",
  },
  {
    index: 2,
    type: "ExcelDefault",
    text: "Export to Excel Default",
  },
  {
    index: 3,
    type: "WordDefault",
    text: "Export to Word Default",
  },
  {
    index: 4,
    type: "UserNeedType",
    text: "User Need Type Export",
  },
  {
    index: 5,
    type: "RequirementType",
    text: "Requirement Type Export",
  },
  {
    index: 6,
    type: "TestCaseType",
    text: "Test Case Type Export",
  },
  {
    index: 7,
    type: "VerificationAndValidationType",
    text: "Verification & Validation Type Export",
  },
  {
    index: 8,
    type: "CoverageAnalysis",
    text: "Coverage Analysis Export",
  },
  {
    index: 9,
    type: "FMEARiskAnalysis",
    text: "FMEA Risk Analysis Export",
  },
  {
    index: 10,
    type: "ExcelForRoundtrip",
    text: "Export to Excel for Roundtrip",
  },
];

const ExportTemplatesContainer = styled(Box)(({ theme }) => ({
  ".content-layout": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "calc(100vh - 104px)",
  },
  ".left-container": {
    width: "20%",
    height: "100%",
    overflowY: "auto",
    borderRight: "1px solid #0000001f",
    ".top_btn_upload": {
      ".MuiListItemIcon-root": {
        minWidth: "35px",
      },
    },
  },
  ".right-container": {
    width: "80%",
    padding: "20px",
    ".Mui-disabled": {
      color: "rgba(0, 0, 0, 0.25)",
    },
  },
}));

interface ExportTemplatesProps {}

const ExportTemplates = (props: ExportTemplatesProps) => {
  const [menuList, setMenuList] = useState([...menuData]);
  const [selectedMenu, setSelectedMenu] = useState(menuData[0]);

  const handleMenuChange = (menu: MenuData) => {
    setSelectedMenu(menu);
  };

  const handleImportTemplate = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {};

  const templateNode = useMemo(() => {
    let node;
    switch (selectedMenu.type) {
      case "AllItemDetails":
        node = <AllItemDetails titleText={selectedMenu.text} />;
        break;
      case "ExcelDefault":
        node = <ExcelDefault titleText={selectedMenu.text} />;
        break;
      case "WordDefault":
        node = <WordDefault titleText={selectedMenu.text} />;
        break;
      case "UserNeedType":
        node = <UserNeedType titleText={selectedMenu.text} />;
        break;
      case "RequirementType":
        node = <RequirementType titleText={selectedMenu.text} />;
        break;
      case "TestCaseType":
        node = <TestCaseType titleText={selectedMenu.text} />;
        break;
      case "VerificationAndValidationType":
        node = <VerificationAndValidationType titleText={selectedMenu.text} />;
        break;
      case "CoverageAnalysis":
        node = <CoverageAnalysis titleText={selectedMenu.text} />;
        break;
      case "FMEARiskAnalysis":
        node = <FMEARiskAnalysis titleText={selectedMenu.text} />;
        break;
      case "ExcelForRoundtrip":
        node = <ExcelForRoundtrip titleText={selectedMenu.text} />;
        break;
      default:
        node = <AllItemDetails titleText={selectedMenu.text} />;
        break;
    }
    return node;
  }, [selectedMenu]);

  return (
    <ExportTemplatesContainer>
      <ContentHeader titleId="admin.nav.item.exportTemplates" />
      <Box className="content-layout">
        <Box className="left-container">
          <Box>
            <List component="nav" className="top_btn_upload" aria-label="secondary mailbox folder">
              <ListItemButton onClick={(event) => handleImportTemplate(event)}>
                <ListItemIcon>
                  <AppSvgIcon color="action">{"material-outline:file_upload"}</AppSvgIcon>
                </ListItemIcon>
                <ListItemText primary={<IntlMessages id="setting.export.templates.uploadTemplate" />} />
              </ListItemButton>
            </List>
          </Box>
          <Box>
            <LeftMenu menuList={menuList} onMenuChange={handleMenuChange} />
          </Box>
        </Box>
        <Box className="right-container">
          <Box></Box>
          <AppScrollbar
            sx={{
              height: "calc(100%)",
              padding: "20px",
              background: "#fff",
              boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)",
            }}
          >
            <Box>{templateNode}</Box>
          </AppScrollbar>
        </Box>
      </Box>
    </ExportTemplatesContainer>
  );
};

export default ExportTemplates;
