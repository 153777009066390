import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Chip, InputAdornment, Link, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import ButtonMenu from "components/ButtonMenu";
import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import React from "react";
import { useNavigate } from "react-router-dom";
import { borderColor, borderRadius, chipColor, textColor } from "shared/constants/AppConst";

const ToolBarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "8px",
  ".doc-id": {
    paddingRight: theme.spacing(2),
  },
  ".task-id": {
    fontWeight: "bold",
    fontSize: 16,
    color: chipColor.stage,
  },
  ".process-name": {
    paddingRight: theme.spacing(3),
    fontSize: 16,
    fontWeight: "bold",
    color: textColor[85],
  },
}));

interface ToolBarProps {
  versionId: number | string;
  versionLists: any[];
  menuList: ButtonMenuListItemModel[];
  docId?: string;
  taskId?: string;
  taskType?: string;
  processData?: any;
  selectedValue?: ButtonMenuListItemModel;
  onVersionChange?: (version: number | string) => void;
  setSelectedValue: (item: ButtonMenuListItemModel) => void;
}

const ToolBar: React.FC<ToolBarProps> = (props: ToolBarProps) => {
  const {
    versionId,
    versionLists,
    menuList,
    docId,
    taskId,
    taskType,
    processData,
    onVersionChange,
    selectedValue,
    setSelectedValue,
  } = props;

  const navigate = useNavigate();

  const handleVersionChange = (event: SelectChangeEvent) => {
    onVersionChange(event.target.value);
  };

  const handleSelectedValue = (item: ButtonMenuListItemModel) => {
    setSelectedValue(item);
  };

  return (
    <ToolBarContainer>
      <Box className="flex items-center">
        <Box className="flex doc-id">
          <Chip
            size="small"
            label={docId}
            sx={{
              textAlign: "center",
              background: "linear-gradient(102deg, #55afff, #4977cc)",
              color: "#fff",
              height: "20px",
              borderRadius: "8px",
              borderBottomRightRadius: "0px",
              fontSize: "12px",
            }}
          />
        </Box>
        <Box className="flex process-name">
          <Link sx={{ fontWeight: "bold", mr: 1 }} underline="none">
            {taskId}
          </Link>
          {processData?.value}
        </Box>
        <Select
          sx={{
            minWidth: 60,
            m: 0,
            background: "#fff",
            "& .MuiInputAdornment-root": {
              color: textColor[85],
            },
            "& .MuiTypography-body1": {
              color: textColor[85],
            },
            "& .MuiInputBase-input": {
              padding: "6px 0px",
              color: textColor[85],
            },
          }}
          id="version-select"
          value={versionId}
          onChange={handleVersionChange}
          startAdornment={
            <InputAdornment position="start">
              <IntlMessages id="product.deliverables.task.version" />:
            </InputAdornment>
          }
        >
          {versionLists.map((v) => (
            <MenuItem key={v.id} value={v.id}>
              {v.label}
              <Chip
                size="small"
                label={v.status}
                sx={{
                  textAlign: "center",
                  background: chipColor.versionBgColor,
                  color: textColor[85],
                  fontSize: 12,
                  m: 2,
                  height: "20px",
                  borderRadius: borderRadius.main,
                  margin: "0px 8px",
                }}
              />
            </MenuItem>
          ))}
        </Select>
        <Box sx={{ ml: 3 }}>
          <ButtonMenu
            selectedValue={selectedValue}
            setSelectedValue={handleSelectedValue}
            list={menuList}
            variant="outlined"
            sxStyle={{
              sx: {
                background: "#fff",
                color: textColor[85],
                borderRadius: "4px",
                border: "solid 1px rgba(0, 0, 0, 0.15)",
              },
            }}
          />
        </Box>
        <Box
          className="flex cursor-pointer"
          sx={{ ml: 3, background: "#fff", padding: 1.5 }}
          onClick={() => {
            const currentItem = menuList.filter((item) => item.id === selectedValue.id)[0];
            if (currentItem) {
              const currentPath = currentItem.url.split("/");
              navigate(`/products/123/${taskType}/${currentPath[currentPath.length - 1]}`);
            } else {
              navigate(`/products/123/${taskType}/worksheet`);
            }
          }}
        >
          <AppSvgIcon size={16} color="#2e6ccb">
            {"material-outline:open_in_new"}
          </AppSvgIcon>
        </Box>
      </Box>
      <Box className="flex items-center"></Box>
    </ToolBarContainer>
  );
};

export default ToolBar;
