import { Calculate } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import MyTree from "components/MyTree";
import React from "react";

import TreeTable from "./TreeTable";

const DataSourcesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "8px",
  height: "calc(65vh - 70px)",
  width: "100%",
  minWidth: "1000px",
  borderTop: "1px solid #0000001f",
}));

const dataset1: any[] = [
  {
    value: "Satety Risks",
    id: "satety-risks",
    opened: true,
    items: [
      {
        value: "Hazards",
        id: "hazards",
        opened: true,
        items: [
          {
            value: "Hazard Category",
            id: "hazard-category",
            opened: true,
            items: [
              {
                value: "HAZ#001 Therapeutic",
                id: "therapeutic",
                opened: true,
              },
              {
                value: "HAZ#002 Electrical Energy",
                id: "electrical-energy",
                opened: true,
              },
              {
                value: "HAZ#003 Mechanical Energy",
                id: "mechanical-energy",
                opened: true,
              },
              {
                value: "HAZ#004 Biological Energy",
                id: "biological-energy",
                opened: true,
              },
            ],
          },
        ],
      },
    ],
  },
];

const dataset2: any[] = [
  {
    value: "Design Inputs",
    id: "Design Inputs",
    opened: true,
    items: [
      {
        value: "Design Input Requirements",
        id: "Design Input Requirements",
        opened: true,
        items: [
          {
            value: "SYS#001 The system shall measure temperature in the range of 34oC to 44oC",
            id: "SYS#001 The system shall measure temperature in the range of 34oC to 44oC",
            opened: true,
          },
          {
            value:
              "SYS#002 The system shall measure temperature with a accuracy of 5% when temperature is measured 3 inches +/- 1 inch from the forehead.",
            id: "SYS#002 The system shall measure temperature with a accuracy of 5% when temperature is measured 3 inches +/- 1 inch from the forehead.",
            opened: true,
          },
          {
            value: "SYS#003 The system shall weigh no more than 6 ounces",
            id: "SYS#003 The system shall weigh no more than 6 ounces",
            opened: true,
          },
          {
            value: "SYS#023 The system shall weigh no more than 4 ounces",
            id: "SYS#023 The system shall weigh no more than 4 ounces",
            opened: true,
          },
          {
            value: "SYS#004 The system shall have a footprint no larger than 3.0 X 2.0 X 6.0 inches",
            id: "SYS#004 The system shall have a footprint no larger than 3.0 X 2.0 X 6.0 inches",
            opened: true,
          },
          {
            value: "SYS#005 The system shall provide a method of recognizing patients based on a biometric.",
            id: "SYS#005 The system shall provide a method of recognizing patients based on a biometric.",
            opened: true,
          },
        ],
      },
    ],
  },
];

const dataset3: any[] = [
  {
    value: "Design Inputs",
    id: "Design Inputs",
    opened: true,
    items: [
      {
        value: "User Needs",
        id: "User Needs",
        opened: true,
        items: [
          {
            value: "UND#001 The user needs a device that can measure temperature accurately and quickly.",
            id: "UND#001 The user needs a device that can measure temperature accurately and quickly.",
            opened: true,
          },
          {
            value:
              "UND#002 The user needs to able to accuratly track the temperature of multiple patients when using the device",
            id: "UND#002 The user needs to able to accuratly track the temperature of multiple patients when using the device",
            opened: true,
          },
          {
            value: "UND#003 The user needs the device to read body temperature without pain or discomfort.",
            id: "UND#003 The user needs the device to read body temperature without pain or discomfort.",
            opened: true,
          },
          {
            value: "UND#004 The user needs a device that is easy to use.",
            id: "UND#004 The user needs a device that is easy to use.",
            opened: true,
          },
          {
            value: "UND#005 The user needs to use the device using only one hand, either the right or left had.",
            id: "UND#005 The user needs to use the device using only one hand, either the right or left had.",
            opened: true,
          },
          {
            value:
              "UND#006 The user needs a device that will indicate a fever without the user needing to read the numbers.",
            id: "UND#006 The user needs a device that will indicate a fever without the user needing to read the numbers.",
            opened: true,
          },
        ],
      },
    ],
  },
];

interface DataSourcesProps {
  dataSourceType: any;
}

const DataSources: React.FC<DataSourcesProps> = (props: DataSourcesProps) => {
  const [dataset, setDataSet] = React.useState([]);

  React.useEffect(() => {
    if (props.dataSourceType?.id && Number(props.dataSourceType.id) < 6) {
      setDataSet(dataset1);
      setSelected("hazard-category");
    } else if (Number(props.dataSourceType?.id) === 6) {
      setDataSet(dataset2);
      setSelected("Design Input Requirements");
    } else if (Number(props.dataSourceType?.id) === 7) {
      setDataSet(dataset3);
      setSelected("User Needs");
    } else {
      setDataSet([]);
      setSelected("");
    }
  }, [props.dataSourceType]);

  const [selected, setSelected] = React.useState<any>("hazard-category");

  const onNodeSelect = (event: object, value: string) => {
    setSelected(value);
  };

  return (
    <>
      {props.dataSourceType && (
        <DataSourcesContainer>
          {dataset.length > 0 && (
            <MyTree
              selected={selected}
              onNodeSelect={onNodeSelect}
              data={dataset}
              sx={{
                width: "20%",
                padding: "12px 16px 0px 16px",
                borderRight: "1px solid #0000001f",
                backgroundColor: "#FAFAFA",
              }}
            />
          )}
          {selected && <TreeTable dataSourceType={props.dataSourceType} />}
        </DataSourcesContainer>
      )}
    </>
  );
};

export default DataSources;
