export const NAV_COLLAPSED = "NAV_COLLAPSED";
export const TOGGLE_NAV_COLLAPSED = "TOGGLE_NAV_COLLAPSED";
export const SET_INITIAL_PATH = "SET_INITIAL_PATH";
export const SHOW_NAV = "SHOW_NAV";
export const CLOSE_NAV = "CLOSE_NAV";
export const ROUTE_CHANGE = "@@router/LOCATION_CHANGE";
export const UPDATE_MODULE_ID = "UPDATE_MODULE_ID";
export const UPDATE_PRODUCT_MODULE_ID = "UPDATE_PRODUCT_MODULE_ID";

export interface ToggleNavCollapsedAction {
  type: typeof TOGGLE_NAV_COLLAPSED;
}
export interface NavCollapsedAction {
  type: typeof NAV_COLLAPSED;
}

export interface SetInitialPathAction {
  type: typeof SET_INITIAL_PATH;
  initialPath: string | undefined;
}

export interface RouteChangeAction {
  type: typeof ROUTE_CHANGE;
}

export interface AdminSettingModuleIdChangeAction {
  type: typeof UPDATE_MODULE_ID;
  moduleId: string;
}

export interface ProductSettingModuleIdChangeAction {
  type: typeof UPDATE_PRODUCT_MODULE_ID;
  moduleId: string;
}

export type SettingsActionTypes =
  | ToggleNavCollapsedAction
  | SetInitialPathAction
  | NavCollapsedAction
  | RouteChangeAction
  | AdminSettingModuleIdChangeAction
  | ProductSettingModuleIdChangeAction;
