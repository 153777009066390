import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/material";
import CustomTable from "components/CustomTable";
import { TableToolBarModel } from "components/CustomTable/CustomTableModel";
import React from "react";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

const columns: Column[] = [
  {
    id: "name",
    label: "common.name",
    minWidth: 200,
    align: "left",
  },
  {
    id: "version",
    label: "workspace.form.version",
    minWidth: 50,
    align: "left",
  },
  {
    id: "date",
    label: "common.date",
    minWidth: 80,
    align: "left",
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    minWidth: 60,
    align: "left",
    format: (value, record) => {
      return (
        <Box>
          <EditIcon sx={{ mr: 2, cursor: "pointer", fontSize: "1.25rem" }} />
          <DeleteIcon sx={{ cursor: "pointer", fontSize: "1.25rem" }} />
        </Box>
      );
    },
  },
];

const rows: any[] = [
  {
    id: "1",
    name: "FDA Infusion Pump Guidance",
    version: "---",
    date: "Dec, 2014",
  },
];

const toolbar: TableToolBarModel = {
  buttons: [
    {
      id: "common.customize",
      icon: TableHeaderButtonsIconType.SETTING,
      type: "outlined",
      onChange: () => {},
    },
  ],
};

interface ExternalReferenceProps {
  [x: string]: any;
}

const ExternalReference: React.FC<ExternalReferenceProps> = () => {
  return (
    <Box>
      <CustomTable
        toolBar={toolbar}
        columns={columns}
        rows={rows}
        showPage={false}
        maxHeight="auto"
        addRow={() => {}}
        addRowTextId="common.add"
      />
    </Box>
  );
};

export default ExternalReference;
