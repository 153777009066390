import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "components/CustomTable";
import React from "react";
import { textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import ApprovalModal from "../ApprovalModal";
import SelectMenbersModal from "../SelectMenbers";
import WorkflowModal from "../WorkflowModal";

const columns: Column[] = [
  {
    id: "name",
    label: "common.name",
    minWidth: 180,
    align: "left",
  },
  {
    id: "emali",
    label: "common.email",
    minWidth: 180,
    align: "left",
  },
  {
    id: "purpose",
    label: "common.purpose",
    minWidth: 220,
    align: "left",
  },
  {
    id: "description",
    label: "common.change.description",
    minWidth: 220,
    align: "left",
  },
  {
    id: "comment",
    label: "common.reviewer.comment",
    minWidth: 180,
    align: "left",
  },
  {
    id: "status",
    label: "common.status",
    minWidth: 100,
    align: "left",
  },
  {
    id: "routeTime",
    label: "common.route.date",
    minWidth: 180,
    align: "left",
  },
  {
    id: "createdTime",
    label: "common.created.date",
    minWidth: 180,
    align: "left",
  },
  {
    id: "signatureTime",
    label: "common.review.signature.date",
    minWidth: 180,
    align: "left",
  },
];

const rows = [
  {
    id: "1",
    name: "Jason(J)",
    emali: "jason@gessnet.com",
    purpose: 'Electrontic approve/reject to close "Task (#6) Route Test"',
    description: "None",
    comment: "None",
    status: "Not Sent",
    routeTime: "10/27/2022 10:12:14",
    createdTime: "None",
    signatureTime: "None",
  },
  {
    id: "2",
    name: "Mick(M)",
    emali: "mick@gessnet.com",
    purpose: 'Electrontic approve/reject to close "Task (#6) Route Test"',
    description: "None",
    comment: "None",
    status: "Not Sent",
    routeTime: "10/27/2022 10:12:14",
    createdTime: "None",
    signatureTime: "None",
  },
  {
    id: "2",
    name: "Jack(J)",
    emali: "jack@gessnet.com",
    purpose: 'Electrontic approve/reject to close "Task (#6) Route Test"',
    description: "None",
    comment: "None",
    status: "Not Sent",
    routeTime: "10/27/2022 10:12:14",
    createdTime: "None",
    signatureTime: "None",
  },
];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: "8px",
  background: "#eff0f2",
  padding: theme.spacing(1),
  "& .MuiToggleButtonGroup-grouped": {
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    border: "none",
    "&:last-of-type": {
      marginRight: theme.spacing(0),
    },
  },
  "& .MuiToggleButton-root.Mui-selected, & .MuiToggleButton-root:hover": {
    color: textColor[85],
    background: theme.palette.background.paper,
  },
}));

const ApprovalContainer = styled(Box)(({ theme }) => ({}));

interface ApprovalProps {}
const Approval = (props: ApprovalProps) => {
  const [status, setStatus] = React.useState("Draft");
  const [openApprovalModal, setOpenApprovalModal] = React.useState<boolean>(false);
  const [openWorkflowModal, setOpenWorkflowModal] = React.useState<boolean>(false);
  const [openSelectMenbersModal, setOpenSelectMenbersModal] = React.useState<boolean>(false);

  const handleStatus = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setStatus(newAlignment || "Draft");
  };

  return (
    <ApprovalContainer>
      <Box className="flex items-center justify-end" sx={{ mb: 3 }}>
        <Button
          onClick={() => {
            setOpenWorkflowModal(true);
          }}
          sx={{
            mr: 2,
          }}
          variant="outlined"
        >
          <IntlMessages id="admin.nav.item.workflow" />
        </Button>
        <StyledToggleButtonGroup
          size="small"
          value={status}
          exclusive
          onChange={handleStatus}
          aria-label="text alignment"
        >
          <ToggleButton value="Draft">Draft</ToggleButton>
          <ToggleButton value="In Review">In Review</ToggleButton>
          <ToggleButton value="Approved">Approved</ToggleButton>
        </StyledToggleButtonGroup>
      </Box>
      <CustomTable rows={rows} columns={columns} maxHeight={500} />
      <Box className="flex items-center justify-end">
        {status === "Draft" && (
          <Button
            onClick={() => {
              setOpenSelectMenbersModal(true);
            }}
            variant="contained"
          >
            <IntlMessages id="setting.process.send.for.approval" />
          </Button>
        )}
        {status === "In Review" && (
          <Button
            onClick={() => {
              setOpenApprovalModal(true);
            }}
            variant="contained"
          >
            <IntlMessages id="common.approval" />
          </Button>
        )}
      </Box>
      {openApprovalModal && (
        <ApprovalModal
          open={openApprovalModal}
          handleClose={() => {
            setOpenApprovalModal(false);
          }}
        />
      )}
      {openSelectMenbersModal && (
        <SelectMenbersModal
          open={openSelectMenbersModal}
          handleClose={() => {
            setOpenSelectMenbersModal(false);
          }}
          titleId="common.select.reviewers"
        />
      )}
      {openWorkflowModal && (
        <WorkflowModal
          open={openWorkflowModal}
          handleClose={() => {
            setOpenWorkflowModal(false);
          }}
        />
      )}
    </ApprovalContainer>
  );
};

export default Approval;
