import "handsontable/dist/handsontable.full.css";

import { HotTable } from "@handsontable/react";
import { Box, TablePagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getCellType } from "handsontable/cellTypes";
import { registerAllModules } from "handsontable/registry";
import { taskData } from "mock/index";
import React from "react";
import { useParams } from "react-router-dom";
import { bgColor, textColor } from "shared/constants/AppConst";

const ExcelTableContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  ".new-style": {
    background: "#ff8376 !important",
  },
  ".changed-style": {
    background: "#ffbe71 !important",
  },
  ".heaer-style": {
    fontWeight: "bold",
    background: bgColor.tableHeaderColor + " !important",
  },
}));

const data = [];

const columns = [];

interface ExcelTableProps {
  bottomOpen: boolean;
  setBottomOpen: (value: any) => void;
  setOpenSettingsModal: (value: any) => void;
  setSelectedValue: (value: any) => void;
  setLayout: (value: any) => void;
  layout: string;
}

const ExcelTable: React.FC<ExcelTableProps> = (props: ExcelTableProps) => {
  const routeParam = useParams();
  const [mockData, setMockData] = React.useState(null);

  React.useEffect(() => {
    routeParam?.type && setMockData(taskData[routeParam.type]);
  }, [routeParam]);

  const [hotSettings, setHotSettings] = React.useState<any>({
    // data: createSpreadsheetData(100, 26),
    data: mockData?.excelTableData ? mockData.excelTableData : data,
    columns: mockData?.excelTableColumns ? mockData.excelTableColumns : columns,
    // autoColumnSize: { syncLimit: 5 },
    rowHeaders: true,
    colHeaders: true,
    fixedRowsTop: 1,
    fixedColumnsStart: 1,
    cells: (row) => {
      if (row === 0) {
        return {
          readOnly: true,
          className: "heaer-style",
        };
      }
    },
    dropdownMenu: false,
    collapsibleColumns: true,
    filters: false,
    readOnly: true,
    manualColumnResize: true,
    manualRowResize: true,
    colWidths: 120,
    rowHeights: 32,
    width: "100%",
    height: "100%",
    // stretchH: "'all'", // 'none' is default
    manualColumnMove: false,
    manualRowMove: false,
    // fixedRowsTop: 1,
    // fixedColumnsStart: 1,
    // colHeaders: mockData?.excelTableColHeaders
    //   ? mockData.excelTableColHeaders
    //   : [
    //       "Hazard Category",
    //       "System Hazard",
    //       "Hazardous Situation",
    //       "Sequence of Events/Causes",
    //       "Harm",
    //       "Harm: Safety Severity",
    //       "HS: Safety P2",
    //     ],
    // columns: [
    //   {
    //     editor: CustomEditor
    //   }
    // ],
    contextMenu: {
      items:
        routeParam?.type === "sha" || routeParam.type === "df" || routeParam.type === "ura"
          ? {
              details: {
                name: "Details",
                callback: (key, options) => {
                  props.setSelectedValue({ id: "11", label: "Details" });
                  props.setBottomOpen(true);
                  props.setOpenSettingsModal(false);
                  if (props.layout === "up_down") {
                    props.setLayout("");
                  }
                },
              },
              impactAnalysis: {
                name: "Impact Analysis",
                callback: (key, options) => {
                  props.setSelectedValue({ id: "12", label: "Impact Analysis" });
                  props.setBottomOpen(true);
                  props.setOpenSettingsModal(false);
                  if (props.layout === "up_down") {
                    props.setLayout("");
                  }
                },
              },
              analysis: {
                name: "Fault Tree Analysis",
                callback: (key, options) => {
                  props.setSelectedValue({ id: "13", label: "Fault Tree Analysis" });
                  props.setBottomOpen(true);
                  props.setOpenSettingsModal(false);
                  if (props.layout === "up_down") {
                    props.setLayout("");
                  }
                },
              },
              risks: {
                name: "Related Risks",
                callback: (key, options) => {
                  props.setSelectedValue({ id: "14", label: "Related Risks" });
                  props.setBottomOpen(true);
                  props.setOpenSettingsModal(false);
                  if (props.layout === "up_down") {
                    props.setLayout("");
                  }
                },
              },
              assuranceCase: {
                name: "Assurance Case",
                callback: (key, options) => {
                  props.setSelectedValue({ id: "15", label: "Assurance Case" });
                  props.setBottomOpen(true);
                  props.setOpenSettingsModal(false);
                  if (props.layout === "up_down") {
                    props.setLayout("");
                  }
                },
              },
              treeTableView: {
                name: "Tree Table View",
                callback: (key, options) => {
                  props.setSelectedValue({ id: "16", label: "Tree Table View" });
                  props.setBottomOpen(true);
                  props.setOpenSettingsModal(false);
                  if (props.layout === "up_down") {
                    props.setLayout("");
                  }
                },
              },
              sendToReview: {
                name: "Send to Review",
                callback: (key, options) => {},
              },
              FreezeColumn: {
                name: "Freeze/Unfreeze Column",
                callback: (key, options) => {},
              },
              FreezeRow: {
                name: "Freeze/Unfreeze Row",
                callback: (key, options) => {},
              },
            }
          : {
              details: {
                name: "Details",
                callback: (key, options) => {
                  props.setSelectedValue({ id: "11", label: "Details" });
                  props.setBottomOpen(true);
                  props.setOpenSettingsModal(false);
                  if (props.layout === "up_down") {
                    props.setLayout("");
                  }
                },
              },
              impactAnalysis: {
                name: "Impact Analysis",
                callback: (key, options) => {
                  props.setSelectedValue({ id: "12", label: "Impact Analysis" });
                  props.setBottomOpen(true);
                  props.setOpenSettingsModal(false);
                  if (props.layout === "up_down") {
                    props.setLayout("");
                  }
                },
              },
              sendToReview: {
                name: "Send to Review",
                callback: (key, options) => {},
              },
              FreezeColumn: {
                name: "Freeze/Unfreeze Column",
                callback: (key, options) => {},
              },
              FreezeRow: {
                name: "Freeze/Unfreeze Row",
                callback: (key, options) => {},
              },
            },
    },
    licenseKey: "non-commercial-and-evaluation",
  });

  const getCell = (type) => {
    if (type === "sha" || type === "df") {
      return [
        { row: 1, col: 1, className: "new-style" },
        { row: 3, col: 3, className: "changed-style" },
      ];
    } else if (type === "dir" || type === "tm") {
      return [
        { row: 1, col: 1, className: "new-style" },
        { row: 2, col: 1, className: "changed-style" },
      ];
    } else if (routeParam.type === "ura") {
      return [
        { row: 1, col: 1, className: "new-style" },
        { row: 2, col: 12, className: "changed-style" },
      ];
    }
  };

  React.useEffect(() => {
    setHotSettings({
      // data: createSpreadsheetData(100, 26),
      data: mockData?.excelTableData ? mockData.excelTableData : data,
      columns: mockData?.excelTableColumns ? mockData.excelTableColumns : columns,
      // autoColumnSize: { syncLimit: 5 },
      rowHeaders: true,
      colHeaders: true,
      fixedRowsTop: 1,
      fixedColumnsStart: 1,
      cells: (row) => {
        if (row === 0) {
          return {
            readOnly: true,
            className: "heaer-style",
          };
        }
      },
      dropdownMenu: false,
      collapsibleColumns: true,
      filters: false,
      // colWidths: 120,
      readOnly: true,
      manualColumnResize: true,
      manualRowResize: true,
      colWidths: 120,
      rowHeights: 32,
      width: "100%",
      height: "100%",
      // stretchH: "'all'", // 'none' is default
      manualColumnMove: false,
      manualRowMove: false,
      // fixedRowsTop: 1,
      // fixedColumnsStart: 1,
      cell: getCell(routeParam?.type),
      // colHeaders: mockData?.excelTableColHeaders
      //   ? mockData.excelTableColHeaders
      //   : [
      //       "Hazard Category",
      //       "System Hazard",
      //       "Hazardous Situation",
      //       "Sequence of Events/Causes",
      //       "Harm",
      //       "Harm: Safety Severity",
      //       "HS: Safety P2",
      //     ],
      // columns: [
      //   {
      //     editor: CustomEditor
      //   }
      // ],
      contextMenu: {
        items:
          routeParam?.type === "sha" || routeParam.type === "df" || routeParam.type === "ura"
            ? {
                items: {
                  details: {
                    name: "Details",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "11", label: "Details" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  impactAnalysis: {
                    name: "Impact Analysis",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "12", label: "Impact Analysis" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  analysis: {
                    name: "Fault Tree Analysis",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "13", label: "Fault Tree Analysis" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  risks: {
                    name: "Related Risks",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "14", label: "Related Risks" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  assuranceCase: {
                    name: "Assurance Case",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "15", label: "Assurance Case" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  treeTableView: {
                    name: "Tree Table View",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "16", label: "Tree Table View" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  sendToReview: {
                    name: "Send to Review",
                    callback: (key, options) => {},
                  },
                  FreezeColumn: {
                    name: "Freeze/Unfreeze Column",
                    callback: (key, options) => {},
                  },
                  FreezeRow: {
                    name: "Freeze/Unfreeze Row",
                    callback: (key, options) => {},
                  },
                },
              }
            : {
                details: {
                  name: "Details",
                  callback: (key, options) => {
                    props.setSelectedValue({ id: "11", label: "Details" });
                    props.setBottomOpen(true);
                    props.setOpenSettingsModal(false);
                    if (props.layout === "up_down") {
                      props.setLayout("");
                    }
                  },
                },
                impactAnalysis: {
                  name: "Impact Analysis",
                  callback: (key, options) => {
                    props.setSelectedValue({ id: "12", label: "Impact Analysis" });
                    props.setBottomOpen(true);
                    props.setOpenSettingsModal(false);
                    if (props.layout === "up_down") {
                      props.setLayout("");
                    }
                  },
                },
                sendToReview: {
                  name: "Send to Review",
                  callback: (key, options) => {},
                },
                FreezeColumn: {
                  name: "Freeze/Unfreeze Column",
                  callback: (key, options) => {},
                },
                FreezeRow: {
                  name: "Freeze/Unfreeze Row",
                  callback: (key, options) => {},
                },
              },
      },
      licenseKey: "non-commercial-and-evaluation",
    });
  }, [mockData, props, routeParam?.type]);

  registerAllModules();

  const beforeOnCellMouseDown = (mouseEvent, coords, td) => {
    if (mouseEvent.button === 2) {
      var cellCoords = { row: coords.row, col: coords.col };
      if (coords.row >= -1) {
        //if (coords.col >-1) {
        setMenuItemsVisible(cellCoords);
      }
    }
  };

  const setMenuItemsVisible = (cellCoords) => {
    console.log(cellCoords);
    if (cellCoords.row === -1) {
      setHotSettings({
        ...hotSettings,
        contextMenu: false,
      });
    } else {
      setHotSettings({
        ...hotSettings,
        contextMenu:
          routeParam?.type === "sha" || routeParam.type === "df" || routeParam.type === "ura"
            ? {
                items: {
                  details: {
                    name: "Details",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "11", label: "Details" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  impactAnalysis: {
                    name: "Impact Analysis",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "12", label: "Impact Analysis" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  analysis: {
                    name: "Fault Tree Analysis",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "13", label: "Fault Tree Analysis" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  risks: {
                    name: "Related Risks",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "14", label: "Related Risks" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  assuranceCase: {
                    name: "Assurance Case",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "15", label: "Assurance Case" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  treeTableView: {
                    name: "Tree Table View",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "16", label: "Tree Table View" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  sendToReview: {
                    name: "Send to Review",
                    callback: (key, options) => {},
                  },
                  FreezeColumn: {
                    name: "Freeze/Unfreeze Column",
                    callback: (key, options) => {},
                  },
                  FreezeRow: {
                    name: "Freeze/Unfreeze Row",
                    callback: (key, options) => {},
                  },
                },
              }
            : {
                items: {
                  details: {
                    name: "Details",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "11", label: "Details" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  impactAnalysis: {
                    name: "Impact Analysis",
                    callback: (key, options) => {
                      props.setSelectedValue({ id: "12", label: "Impact Analysis" });
                      props.setBottomOpen(true);
                      props.setOpenSettingsModal(false);
                      if (props.layout === "up_down") {
                        props.setLayout("");
                      }
                    },
                  },
                  sendToReview: {
                    name: "Send to Review",
                    callback: (key, options) => {},
                  },
                  FreezeColumn: {
                    name: "Freeze/Unfreeze Column",
                    callback: (key, options) => {},
                  },
                  FreezeRow: {
                    name: "Freeze/Unfreeze Row",
                    callback: (key, options) => {},
                  },
                },
              },
      });
    }
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ExcelTableContainer className="spreadsheet-box">
      <Box
        id="spreadsheet"
        sx={{
          width: "100%",
          height: props.bottomOpen ? "calc(60vh - 240px)" : "calc(100vh - 240px)",
          ".handsontable .htDimmed": {
            background: "#FFF",
            color: textColor[85],
            fontSize: "14px",
            fontFamily: "Roboto",
          },
          ".handsontable thead tr th": {
            color: textColor[85],
            // background: bgColor.tableHeaderColor,
            fontWeight: "bold",
            height: "39px !important",
            fontSize: "14px",
            fontFamily: "Roboto",
          },
          ".handsontable thead th .relative": {
            padding: "9px 4px",
          },
        }}
      >
        {mockData && <HotTable settings={hotSettings} stretchH={"all"} beforeOnCellMouseDown={beforeOnCellMouseDown} />}
      </Box>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </ExcelTableContainer>
  );
};

export default ExcelTable;
