import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Chip, InputAdornment, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { borderColor, borderRadius, chipColor, textColor } from "shared/constants/AppConst";

// When selecting a non root node

const ToolBarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "12px",
  ".task-name": {
    paddingRight: theme.spacing(4),
    fontWeight: "600",
    color: "#2e6ccb",
  },
  ".process-name": {
    borderLeft: `1px solid ${borderColor.dividerColor}`,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontWeight: "600",
  },
}));

interface ToolBarProps {
  versionId: number | string;
  versionLists: any[];
  taskName?: string;
  processName?: string;
  onVersionChange?: (version: number | string) => void;
}

const ToolBar: React.FC<ToolBarProps> = (props: ToolBarProps) => {
  const { versionId, versionLists, taskName, processName, onVersionChange } = props;

  const handleVersionChange = (event: SelectChangeEvent) => {
    onVersionChange(event.target.value);
  };

  return (
    <ToolBarContainer>
      <Box className="flex items-center">
        <Box className="flex task-name">{taskName}</Box>
        <Box className="flex process-name">{processName}</Box>
        <Select
          sx={{
            minWidth: 60,
            m: 0,
            background: "#fff",
            "& .MuiInputAdornment-root": {
              color: textColor[85],
            },
            "& .MuiTypography-body1": {
              color: textColor[85],
            },
            "& .MuiInputBase-input": {
              padding: "6px 0px",
              color: textColor[85],
            },
          }}
          id="version-select"
          value={versionId}
          onChange={handleVersionChange}
          startAdornment={
            <InputAdornment position="start">
              <IntlMessages id="product.deliverables.task.version" />
            </InputAdornment>
          }
        >
          {versionLists.map((v) => (
            <MenuItem key={v.id} value={v.id}>
              {v.label}
              <Chip
                size="small"
                label={v.status}
                sx={{
                  textAlign: "center",
                  background: chipColor.versionBgColor,
                  color: textColor[85],
                  m: 2,
                  height: "20px",
                  borderRadius: borderRadius.main,
                  margin: "0px 8px",
                }}
              />
            </MenuItem>
          ))}
        </Select>
      </Box>
    </ToolBarContainer>
  );
};

export default ToolBar;
