import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import React, { useEffect, useMemo, useState } from "react";
import { bgColor, borderColor, boxShadow } from "shared/constants/AppConst";

import { relationshipData } from "./d";
import DerivedTable from "./DerivedTable";
import IntegratedProductsTable from "./IntegratedProductsTable";
import IntegrationComponentTable from "./IntegrationComponentTable";
import ParentTable from "./ParentTable";
import ProductRelationshipsDiagram from "./ProductRelationshipsDiagram";

const Container = styled(Box)(({ theme }) => ({
  ".content-layout": {
    flexDirection: "row",
    width: "100%",
    background: theme.palette.background.paper,
    padding: theme.spacing(5),
    ".diagram-layout": {
      width: "100%",
      border: `1px solid ${borderColor.dividerColor}`,
    },
    ".tables": {
      width: "100%",
      marginTop: theme.spacing(4),
    },
  },
}));

const Relationships = () => {
  return (
    <Container>
      <AppScrollbar
        sx={{
          background: bgColor.main,
          padding: (theme) => theme.spacing(5),
          height: "calc(100vh - 104px)",
          boxShadow: boxShadow.cardBoxShadow,
        }}
      >
        <Box className="content-layout flex items-start flex-wrap">
          <Box className="diagram-layout">
            <ProductRelationshipsDiagram nodeData={relationshipData.nodeData} linkData={relationshipData.linkData} />
          </Box>
          <Box className="tables">
            <Grid container spacing={4}>
              <Grid item xs={4} md={4}>
                <IntegrationComponentTable currentProductName={"DDD Main(R1.0)"} />
              </Grid>
              <Grid item xs={4} md={4}>
                <IntegratedProductsTable currentProductName={"DDD Main(R1.0)"} />
              </Grid>
              <Grid item xs={4} md={4}>
                <DerivedTable />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </AppScrollbar>
    </Container>
  );
};

export default Relationships;
