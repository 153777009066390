import {
  ProductsActionTypes,
  UPDATE_LEFT_TREE_OPEN,
  UPDATE_LEFT_TREE_SELECTED,
  UPDATE_productsCategory,
  UPDATE_productsDetailMenu,
  UPDATE_STAGE,
  UPDATE_VARIANT,
  UPDATE_VERSION,
} from "../../types/actions/Products.action";
import { Product } from "../../types/models/Products";

const initialWorkspaces: Product = {
  version: "102",
  stage: "Production",
  variant: "102",
  productsCategory: "1",
  productsDetailMenu: "1",
  leftTreeOpen: true,
  selectProcess: null,
};

const Workspaces = (state = initialWorkspaces, action: ProductsActionTypes): Product => {
  switch (action.type) {
    case UPDATE_STAGE:
      return {
        ...state,
        stage: action.stage,
      };
    case UPDATE_VERSION:
      return {
        ...state,
        version: action.version,
      };
    case UPDATE_VARIANT:
      return {
        ...state,
        variant: action.variant,
      };
    case UPDATE_productsCategory:
      return {
        ...state,
        productsCategory: action.productsCategory,
      };
    case UPDATE_LEFT_TREE_OPEN:
      return {
        ...state,
        leftTreeOpen: action.leftTreeOpen,
      };
    case UPDATE_productsDetailMenu:
      return {
        ...state,
        productsDetailMenu: action.productsDetailMenu,
      };
    case UPDATE_LEFT_TREE_SELECTED:
      return {
        ...state,
        selectProcess: action.selectProcess,
      };
    default:
      return state;
  }
};

export default Workspaces;
