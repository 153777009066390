import CustomTable from "components/CustomTable";
import { TableToolBarModel } from "components/CustomTable/CustomTableModel";
import React from "react";
import { useIntl } from "react-intl";
import { Column } from "types/models/Common";

interface HistoryTableProps {
  level: number;
}

const HistoryTable: React.FC<HistoryTableProps> = ({ level }) => {
  const { messages } = useIntl();

  const columns: Column[] = [
    {
      id: "module",
      label: "common.module",
      minWidth: 80,
      align: "left",
    },
    {
      id: "process",
      label: "workspace.form.process",
      minWidth: 80,
      align: "left",
    },
    {
      id: "setting",
      label: "common.setting",
      minWidth: 80,
      align: "left",
    },
    {
      id: "details",
      label: "common.details",
      minWidth: 200,
      align: "left",
    },
    {
      id: "by",
      label: "common.by",
      minWidth: 50,
      align: "left",
    },
    {
      id: "date",
      label: "common.date",
      minWidth: 100,
      align: "left",
    },
  ];

  const rows: any[] = [
    {
      id: "1",
      module: "Risk Management",
      process: "System Hazard Analysis",
      setting: "Worksheet - Format",
      details: "Change Tree Table option to Excel Table option.",
      by: "James",
      date: "15:03:34 8/12/2022",
    },
    {
      id: "2",
      module: "Risk Management",
      process: "---",
      setting: "Item Types",
      details: "Add a new item type, [Hazardous Situation]",
      by: "Alex",
      date: "11:25:24 8/25/2022",
    },
  ];

  const toolbar: TableToolBarModel = {
    showSearch: true,
    filters: [
      {
        value: "",
        list: [
          {
            id: "rm",
            name: String(messages["common.risk.management"]),
          },
          {
            id: "ac",
            name: String(messages["common.assurance.case.management"]),
          },
          {
            id: "req",
            name: String(messages["common.requirement.management"]),
          },
        ],
        setValue: () => {},
      },
      {
        value: "",
        list: [
          {
            id: "sha",
            name: "System Hazard Analysis",
          },
        ],
        setValue: () => {},
      },
    ],
    saveFilter: () => {},
  };

  return <CustomTable toolBar={toolbar} columns={columns} rows={rows} showPage={false} maxHeight="auto" />;
};

export default HistoryTable;
