import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import React from "react";
import { bgColor, boxShadow } from "shared/constants/AppConst";

import ContentHeader from "../components/ContentHeader";

const ProductRelationships = () => {
  const [rows] = React.useState([
    {
      order: 1,
      name: "Integrates",
      opposite_name: "is Integrated by",
      description: "",
      system_default: true,
    },
    {
      order: 2,
      name: "Derives",
      opposite_name: "is Derived from",
      description: "",
      system_default: true,
    },
  ]);

  return (
    <>
      <ContentHeader titleId="admin.product.relationships" />
      <AppScrollbar
        sx={{
          height: "calc(100vh - 104px)",
          padding: (theme) => theme.spacing(5),
        }}
      >
        <Box
          sx={{
            padding: (theme) => theme.spacing(5),
            background: (theme) => theme.palette.background.paper,
            boxShadow: boxShadow.cardBoxShadow,
          }}
        >
          <CustomTable
            rows={rows}
            columns={[
              {
                id: "order",
                label: "setting.product.column.order",
                minWidth: 150,
                align: "left",
              },
              {
                id: "name",
                label: "setting.product.column.name",
                minWidth: 150,
                align: "left",
              },
              {
                id: "opposite_name",
                label: "setting.product.column.opposite.name",
                minWidth: 300,
                align: "left",
              },
              {
                id: "system_default",
                label: "setting.product.column.system.default",
                minWidth: 300,
                align: "left",
                format: (value, record) => {
                  return (
                    <Box sx={{ display: "flex" }}>
                      {value ? (
                        <AppSvgIcon size={16} color="#6dc347">
                          material-solid:done
                        </AppSvgIcon>
                      ) : (
                        ""
                      )}
                    </Box>
                  );
                },
              },
            ]}
            showPage={false}
          />
        </Box>
      </AppScrollbar>
    </>
  );
};

export default ProductRelationships;
