import AppScrollbar from "@crema/core/AppScrollbar";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { Box, Button, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ListModel } from "components/CustomTable/CustomTableModel";
import TableToolBar from "components/CustomTable/TableToolBar";
import ExportDropdown from "components/ExportDropdown";
import { useState } from "react";

import ExcelTable from "./ExcelTable";
import TreeGrid from "./TreeGrid";
const WorksheetContainer = styled(Card)(({ theme }) => ({
  // margin: '0 24px 24px 24px',
  padding: theme.spacing(0),
  width: "100%",
  ".worksheet-top": {
    width: "100%",
  },
  ".tool-bar": {
    width: "100%",
  },
  ".worksheet-content": {
    width: "100%",
    marginTop: "8px",
    display: "flex",
  },
}));
interface WorksheetProps {
  layoutType: number;
  id: string;
}
const Worksheet = (props: WorksheetProps) => {
  const [searchText, setSearchText] = useState("");
  const [filter1, setFilter1] = useState<any>("");

  const [filterList1] = useState<ListModel[]>([
    {
      id: "1",
      name: "Filter1",
    },
  ]);
  const [filter2, setFilter2] = useState<any>("");
  const [filterList2] = useState<ListModel[]>([
    {
      id: "1",
      name: "Filter2",
    },
  ]);
  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
  };
  return (
    <WorksheetContainer>
      <AppScrollbar
        sx={{
          padding: (theme) => theme.spacing(3),
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box className="worksheet-top flex justify-between items-center">
            <Box className="tool-bar">
              {
                <TableToolBar
                  showSearch={props?.layoutType === 1 ? true : false}
                  searchText={searchText}
                  requestSearch={requestSearch}
                >
                  {
                    <>
                      <ExportDropdown exportToWord={() => {}} exportToExcel={() => {}} exportToPdf={() => {}} />
                    </>
                  }
                </TableToolBar>
              }
            </Box>
          </Box>
          <Box className="worksheet-content">
            {props?.layoutType === 1 ? <ExcelTable id={props.id} /> : <TreeGrid id={props.id} />}
          </Box>
        </Box>
      </AppScrollbar>
    </WorksheetContainer>
  );
};

export default Worksheet;
