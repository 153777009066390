import AppGridContainer from "@crema/core/AppGridContainer";
import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import bubblePng from "assets/imgs/bubble.png";
import progressPng from "assets/imgs/progress.png";
import proportionPng from "assets/imgs/proportion.png";
import statisticsPng from "assets/imgs/statistics.png";
import AppSvgIcon from "components/AppSvgIcon";
import React from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";

const Container = styled(Box)(({ theme }) => ({
  ".dashboard-action-container": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  ".dashboard-content-top": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  ".dashboard-progress": {},
  ".dashboard-proportion": {},
  ".dashboard-bubble": {},
  ".dashboard-content-bottom": {},
}));

const Dashboard = () => {
  return (
    <Container>
      <AppScrollbar
        sx={{
          padding: (theme) => theme.spacing(5),
          maxHeight: "calc(100vh - 104px)",
          background: bgColor.main,
        }}
      >
        <Box className="dashboard-action-container">
          <Button
            sx={{
              color: textColor[85],
              border: `solid 1px ${borderColor.dividerColor}`,
              fontWeight: "normal",
            }}
            startIcon={
              <AppSvgIcon size={16} color="action">
                {"material-outline:star"}
              </AppSvgIcon>
            }
            variant="outlined"
          >
            <IntlMessages id="common.star" />
          </Button>
          <Button
            sx={{
              color: textColor[85],
              border: `solid 1px ${borderColor.dividerColor}`,
              fontWeight: "normal",
              mx: 2,
            }}
            startIcon={
              <AppSvgIcon size={16} color="action">
                {"material-outline:share"}
              </AppSvgIcon>
            }
            variant="outlined"
          >
            <IntlMessages id="common.share" />
          </Button>
          <Button
            sx={{
              color: textColor[85],
              border: `solid 1px ${borderColor.dividerColor}`,
              fontWeight: "normal",
            }}
            startIcon={
              <AppSvgIcon size={16} color="action">
                {"material-outline:settings"}
              </AppSvgIcon>
            }
            variant="outlined"
          >
            <IntlMessages id="common.customize" />
          </Button>
        </Box>
        <AppGridContainer sx={{ mt: 0 }}>
          <Grid item xs={12} md={4}>
            <img style={{ width: "100%" }} src={progressPng} alt="" />
          </Grid>
          <Grid item xs={12} md={4}>
            <img style={{ width: "100%" }} src={proportionPng} alt="" />
          </Grid>
          <Grid item xs={12} md={4}>
            <img style={{ width: "100%" }} src={bubblePng} alt="" />
          </Grid>
          <Grid item xs={12} md={12}>
            <img style={{ width: "100%" }} src={statisticsPng} alt="" />
          </Grid>
        </AppGridContainer>
        {/* <Box className="dashboard-content-top">
          <Box className="dashboard-progress"></Box>
          <Box className="dashboard-proportion"></Box>
          <Box className="dashboard-bubble"></Box>
        </Box>
        <Box className="dashboard-content-bottom"></Box> */}
      </AppScrollbar>
    </Container>
  );
};

export default Dashboard;
