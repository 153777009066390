import { Box, IconButton, SvgIcon, TableCell, TableRow } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import AddBoxIcon from "components/Icons/AddBoxIcon";
import FolderIcon from "components/Icons/FolderIcon";
import RemoveBoxIcon from "components/Icons/RemoveBoxIcon";
import * as React from "react";
import { TreeTableBaseModel } from "types/models/Common";

interface MyTableRowProps {
  row: TreeTableBaseModel;
  columns: any;
  renderRow: (row, columns, selectedRow, setReloadClickCell, onRowClick, enableIsOpen) => void;
  setReloadClickCell: () => void;
  selectedRow: any;
  isFolder: boolean;
  rowSpan?: number;
  enableIsOpen?: boolean;
  onRowClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>, row: any) => void;
}

export default function MyTableRow(props: MyTableRowProps) {
  const { row, columns, renderRow, selectedRow, setReloadClickCell, isFolder, onRowClick, rowSpan, enableIsOpen } =
    props;
  const [open, setOpen] = React.useState(enableIsOpen ? !!row.isOpen : true);
  return (
    <>
      <TableRow
        key={row.id}
        sx={
          rowSpan
            ? {
                "& > *": { borderBottom: "unset" },
              }
            : {
                "& > *": { borderBottom: "unset" },
                "& .table_cell:first-of-type": {
                  borderLeft: "none",
                },
              }
        }
        className={`${row.rowClickable ? "table_row_clickable" : ""} ${
          isFolder ? "table_row_folder" : "table_row_task"
        }`}
      >
        {columns.map((column, index) => {
          const value = row[column.id];
          if (rowSpan === 0 && index === 0) {
            return;
          }
          if (index === 0) {
            return (
              <TableCell
                onClick={
                  row.rowClickable
                    ? (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
                        onRowClick && onRowClick(event, row);
                      }
                    : () => {}
                }
                key={column.id}
                id={row.id + "_" + column.id}
                className={`${
                  selectedRow && selectedRow.id === row.id
                    ? "table_cell table_body_cell table_selected"
                    : "table_cell table_body_cell"
                }`}
                sx={{
                  paddingLeft:
                    row.children?.length > 0
                      ? (row?.level ? 4 * row.level : 0) * 4 + "px !important"
                      : (row?.level ? 4 * row.level : 4) * 4 + 20 + "px !important",
                }}
                rowSpan={rowSpan ? rowSpan : 1}
                align={column.align}
              >
                <Box onClick={(event) => {}} className=" flex items-start">
                  {row.children?.length > 0 ? (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      hidden={row.children?.length > 0 ? false : true}
                      sx={{ mr: 1, p: 0, mt: "2px" }}
                      onClick={() => {
                        setOpen(!open);
                        // setReloadClickCell();
                      }}
                    >
                      {/* {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}
                      {open ? (
                        <RemoveBoxIcon />
                      ) : (
                        // <AppSvgIcon>material-solid:remove</AppSvgIcon>
                        <AddBoxIcon />
                        // <AppSvgIcon>material-solid:add</AppSvgIcon>
                      )}
                    </IconButton>
                  ) : (
                    <IconButton
                      sx={{ p: 0 }}
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        setOpen(!open);
                        setReloadClickCell();
                      }}
                    ></IconButton>
                  )}
                  {isFolder && (
                    <Box sx={{ mr: 1, mt: "2px" }}>
                      <FolderIcon />
                      {/* <AppSvgIcon>{open ? "material-solid:folder_open" : "material-solid:folder"}</AppSvgIcon> */}
                    </Box>
                  )}
                  {row.startIcon}
                  {column.format ? column.format(value, row) : value}
                </Box>
              </TableCell>
            );
          } else if (column.id === "actions") {
            return (
              <TableCell
                key={column.id}
                id={row.id + "_" + column.id}
                className={
                  selectedRow && selectedRow.id === row.id
                    ? "table_cell table_body_cell table_selected table_actions"
                    : "table_cell table_body_cell table_actions"
                }
                align={column.align}
              >
                {column.format ? column.format("", row) : ""}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                onClick={
                  row.rowClickable
                    ? (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
                        onRowClick && onRowClick(event, row);
                      }
                    : () => {}
                }
                key={column.id}
                id={row.id + "_" + column.id}
                className={
                  selectedRow && selectedRow.id === row.id
                    ? "table_cell table_body_cell table_selected"
                    : "table_cell table_body_cell"
                }
                align={column.align}
              >
                {column.format ? column.format(value, row) : value}
              </TableCell>
            );
          }
        })}
      </TableRow>
      {open && Array.isArray(row.children) && row.children.length > 0
        ? row.children.map((child) =>
            renderRow(child, columns, selectedRow, setReloadClickCell, onRowClick, enableIsOpen)
          )
        : null}
    </>
  );
}
