import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Card, CardContent, IconButton, Link, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Column } from "types/models/Common";

import Feeds from "../Feeds";
import RichEditorTemplate from "../RichEditorTemplate";

const mockData = {
  richEditorHtml: {
    html: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus</p>",
  },
  feeds: [
    {
      source: "DDD-TK#001 System Hazard Analysis (V1.0)",
      sourceUrl: "/products/123/deliverables/Risk Management/System Hazard Analysis/sha/100/worksheet",
      id: "FD#001",
      content: "Fault Tree Analysis should be modified according to SHA.",
      status: "Open",
      scope: "DDD-TK#032 Fault mode and effect analysis (V1.0)",
      creationTime: "09:12:21 3/13/2022",
    },
    {
      source: "DDD-TK#002 System Hazard Analysis (V1.0)",
      sourceUrl: "/products/123/deliverables/Risk Management/System Hazard Analysis/sha/100/worksheet",
      id: "FD#002",
      content: "SHA in SDDDB and SDDDC should be updated.",
      status: "Open",
      scope: "DDD-TK#032 Fault mode and effect analysis (V1.0)",
      creationTime: "06:12:21 3/14/2022",
    },
    {
      source: "DDD-TK#003 System Hazard Analysis (V1.0)",
      sourceUrl: "/products/123/deliverables/Risk Management/System Hazard Analysis/sha/100/worksheet",
      id: "FD#003",
      content: "Need create a system requirement",
      status: "In Process",
      scope: "",
      creationTime: "08:12:21 5/15/2022",
    },
  ],
};

const ImpactSummaryContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  ".rich_editor": {
    display: "block",
  },
  ".feeds-box": {
    ".title": {
      fontSize: "14px",
      fontWeight: "500",
      color: "rgba(0, 0, 0, 0.85)",
    },
    ".search-box": {
      marginRight: theme.spacing(2),
    },
  },
}));
const CardContainer = styled(Card)(({ theme }) => ({
  "&.MuiCard-root": {
    padding: theme.spacing(0),
    boxShadow: "none",
    borderRadius: 0,
    ".MuiCardHeader-root": {
      padding: theme.spacing(4, 0),
    },
  },
}));

interface ImpactSummaryProps {}
const ImpactSummary = (props: ImpactSummaryProps) => {
  // const params = useParams();
  // console.log(params);

  const navigate = useNavigate();

  const { messages } = useIntl();
  const [richEditorHtml, setRichEditorHtml] = useState(mockData.richEditorHtml);

  const [columns, setColumns] = useState<Column[]>([
    {
      id: "source",
      labelText: "Source",
      format: (value, record) => {
        return (
          <Link
            sx={{ marginLeft: 2, cursor: "pointer" }}
            underline="none"
            onClick={() => {
              navigate(record.sourceUrl);
            }}
          >
            {value}
          </Link>
        );
      },
    },
    {
      id: "id",
      labelText: "ID",
    },
    {
      id: "content",
      labelText: "Content",
    },
    {
      id: "status",
      labelText: "Status",
    },
    {
      id: "scope",
      labelText: "Impact Scope",
    },
    {
      id: "creationTime",
      labelText: "Creation Time",
    },
  ]);
  const [feeds, setFeeds] = useState(mockData.feeds);

  const [searchText, setSearchText] = useState<string>();

  const handleRequestSearch = (value: string) => {
    setSearchText(value);
  };

  return (
    <ImpactSummaryContainer>
      <Box className="rich_editor">
        <CardContainer>
          <CardContent>
            <RichEditorTemplate
              data={richEditorHtml.html}
              inEdit={true}
              onChange={(data) => {
                setRichEditorHtml({
                  html: data,
                });
              }}
            />
          </CardContent>
        </CardContainer>
      </Box>
      <Box className="feeds-box">
        <CardContainer>
          {/* <Box className="flex justify-between items-center">
            <Box className="title">
              <IntlMessages id="product.deliverables.impactSummary.feeds" />
            </Box>
            <Box className="right-actions flex items-center">
              <Box className="search-box">
                <TextField
                  variant="outlined"
                  value={searchText}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRequestSearch(event.target.value)}
                  placeholder={String(messages["common.search"])}
                  InputProps={{
                    startAdornment: (
                      <AppSvgIcon size={16} color="action">
                        {"material-outline:search"}
                      </AppSvgIcon>
                    ),
                    endAdornment: (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          title="Clear"
                          aria-label="Clear"
                          size="small"
                          style={{ visibility: searchText ? "visible" : "hidden" }}
                          onClick={() => {
                            handleRequestSearch("");
                          }}
                        >
                          <AppSvgIcon size={16} color="action">
                            {"material-outline:clear"}
                          </AppSvgIcon>
                        </IconButton>
                      </Box>
                    ),
                  }}
                  sx={{
                    width: {
                      xs: 1,
                      sm: "auto",
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    "& .MuiSvgIcon-root": {
                      mr: 0.5,
                    },
                    "& .MuiInputBase-root": {
                      padding: "0px 14px",
                      height: "32px",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "0px",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: 1,
                      borderColor: "divider",
                    },
                  }}
                />
              </Box>
              <Box sx={{ marginRight: "8px" }}>
                <Button
                  variant="outlined"
                  className="add-button"
                  startIcon={<AppSvgIcon color="action">{"material-outline:add_circle"}</AppSvgIcon>}
                >
                  <IntlMessages id="product.deliverables.impactSummary.feeds.add" />
                </Button>
              </Box>
              <Box sx={{ marginRight: "8px" }}>
                <Button
                  variant="outlined"
                  className="setting-button"
                  startIcon={<AppSvgIcon color="action">{"material-outline:settings"}</AppSvgIcon>}
                >
                  <IntlMessages id="product.deliverables.impactSummary.feeds.customize" />
                </Button>
              </Box>
            </Box>
          </Box> */}
          <CardContent>
            <Box>
              {/* <CustomTable columns={columns} rows={feeds} /> */}
              <Feeds />
            </Box>
          </CardContent>
        </CardContainer>
      </Box>
    </ImpactSummaryContainer>
  );
};

export default ImpactSummary;
