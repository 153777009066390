export enum RuleTabEnum {
  Severity = "Severity",
  P1 = "P1",
  P2 = "P2",
  Ph = "Ph",
  Acceptance = "Acceptance",
  ControlOption = "ControlOption",
  Rename = "Rename",
}
export enum RuleEnum {
  AutomaticPassDownstream = "AutomaticPassDownstream", // For P2
  Manual = "Manual", // For Ph, Acceptance
  FormulaP1XP2 = "FormulaP1XP2", // Formula (Ph = P1 * P2), For Ph
  FormulaP1AddP2 = "FormulaP1AddP2", // Formula (Ph = P1 + P2), For Ph
  FormulaAccPhXSev = "FormulaAccPhXSev", // Formula (Acc = Ph * Sev) ,For Acceptance
  FormulaAccPhAddSev = "FormulaAccPhAddSev", // Formula (Acc = Ph + Sev), For Acceptance
  LookupTable = "LookupTable",
}

export interface RiskPolicyDataModel {
  id: number | string;
  name?: string;
  type?: string;
  description?: string;
  displayOrder?: number | string;
  isBased?: boolean;
}

export interface ProcessLevelKeyValueModel {
  label: string;
  key: string;
  defaultValue?: boolean | string | number;
}
export interface ProcessLevelDataModel {
  checkboxListName: string;
  radioBoxList: ProcessLevelKeyValueModel[];
}

export interface SeverityModel {
  id: number | string;
  description?: string;
  abbreviation?: string;
  value?: number | string;
  displayOrder?: number | string;
  summary?: number | string;
}

export interface P1Model {
  id: number | string;
  description?: string;
  abbreviation?: string;
  value?: number | string;
  probability?: number | string;
  displayOrder?: number | string;
  summary?: number | string;
}

export interface P2Model {
  id: number | string;
  description?: string;
  abbreviation?: string;
  value?: number | string;
  probability?: number | string;
  displayOrder?: number | string;
  summary?: number | string;
}

export interface P2TableModel {
  tableList?: P2Model[];
}

export interface PhModel {
  id: number | string;
  description?: string;
  abbreviation?: string;
  value?: number | string;
  displayOrder?: number | string;
  summary?: number | string;
  statusColor?: string;
}

export interface PhTableModel {
  tableList?: PhModel[];
}

export interface AcceptanceModel {
  id: number | string;
  description?: string;
  abbreviation?: string;
  minValue?: number | string;
  maxValue?: number | string;
  displayOrder?: number | string;
  summary?: number | string;
  statusColor?: string;
}

export interface AcceptanceTableModel {
  tableList?: AcceptanceModel[];
}

export interface ControlOptionModel {
  id: number | string;
  description?: string;
  abbreviation?: string;
  value?: number | string;
  displayOrder?: number | string;
  summary?: number | string;
}

export interface LookupTableModel {
  [key: string]: any;
}

export interface RenameModel {
  id: number | string;
  key?: string;
  title?: string;
  description?: string;
}
