const COLUMN_LABEL_BASE = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const COLUMN_LABEL_BASE_LENGTH = COLUMN_LABEL_BASE.length;

export const spreadsheetColumnLabel = function (index) {
  let dividend = index + 1;
  let columnLabel = "";
  let modulo;

  while (dividend > 0) {
    modulo = (dividend - 1) % COLUMN_LABEL_BASE_LENGTH;
    columnLabel = String.fromCharCode(65 + modulo) + columnLabel;
    dividend = parseInt((dividend - modulo) / COLUMN_LABEL_BASE_LENGTH, 10);
  }

  return columnLabel;
};

export const createSpreadsheetData = function (rows = 100, columns = 4) {
  const _rows = [];
  let i;
  let j;

  const defaultData = [
    [
      "ID",
      "Hazard",
      "Hazardous Situation",
      "Sequence of Events(SoE)",
      "Analysis of Failure Modes",
      "Harm",
      "Severity",
      "P1 SOE (U)",
      "P1 (U)",
      "P2 (U)",
      "POH (U)",
      "Risk (U)",
      "Risk Controls",
      "Risk Control Introduced Risks",
      "P1 SOE (M)",
      "P1 (M)",
      "P2 (M)",
      "POH (M)",
      "Risk Region (M)",
      "RC Requirements",
      "V & V",
    ],
    [
      "HA#001",
      "Incorrect Temperature Measurement",
      "Undetected fever",
      "",
      "",
      "Untreated underlying conditions",
      "4",
      "",
      "2",
      "1",
      "2",
      "Med",
      "",
      "",
      "",
      "1",
      "1",
      "1",
      "Low",
      "",
      "",
    ],
    [
      "HA#002",
      "Hazard 2",
      "HS 2",
      "",
      "",
      "Pain, discomfort",
      "2",
      "",
      "2",
      "3",
      "2",
      "Low",
      "",
      "",
      "",
      "1",
      "3",
      "1",
      "Low",
      "",
      "",
    ],
    [
      "HA#003",
      "Hazard 3",
      "HS 3",
      "Thermometers malfunction",
      "FMEA#001",
      "",
      "",
      "3",
      "",
      "",
      "",
      "",
      "The thermometer has a 3 yr reliability",
      "",
      "1",
      "",
      "",
      "",
      "",
      "SYS#016 The system shall function within specification for at least 36 months without needing replacement of any parts.",
      "VAL-1 Self taking of temperature",
    ],
    [
      "HA#003",
      "Hazard 3",
      "HS 3",
      "Thermometers malfunction",
      "FMEA#003",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    [
      "HA#003",
      "Hazard 3",
      "HS 3",
      "Thermometers malfunction",
      "FMEA#005",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    [
      "HA#004",
      "Hazard 4",
      "HS 4",
      "SOE 4",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "Self-test is performed at power on",
      "",
      "",
      "",
      "",
      "",
      "",
      "SYS#022 The system shall perform a check of the thermometer electronics at power up",
      "VAL-1 Self taking of temperature",
    ],
    [
      "HA#005",
      "Hazard 5",
      "HS 5",
      "SOE 5",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "The IFU instructs not to use the thermometer if the indication of a successful self-test is not provided",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
  ];
  defaultData.forEach((d) => {
    _rows.push(d);
  });

  for (i = 0; i < rows; i++) {
    const row = [];

    for (j = 0; j < columns; j++) {
      // row.push(spreadsheetColumnLabel(j) + (i + 1));
      row.push("");
    }
    _rows.push(row);
  }

  return _rows;
};
