import Icon from "@mui/material/Icon";
import * as React from "react";

const FillProductSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="smndxdwlla">
        <stop stopColor="#088F62" offset="0%" />
        <stop stopColor="#51BCA5" offset="100%" />
      </linearGradient>
    </defs>
    <g transform="translate(2 2)" fill="none" fillRule="evenodd">
      <rect fill="url(#smndxdwlla)" width="12" height="12" rx="1" />
      <g transform="translate(2 2)" fill="#FFF">
        <rect width="3" height="3" rx=".5" />
        <rect x="5" width="3" height="3" rx=".5" />
        <rect opacity=".6" x="5" y="5" width="3" height="3" rx=".5" />
        <rect y="5" width="3" height="3" rx=".5" />
      </g>
    </g>
  </svg>
);

const FillProductIcon: React.FunctionComponent = () => {
  return <Icon component={FillProductSvg} />;
};

export default FillProductIcon;
