import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { Box, Checkbox } from "@mui/material";
import CustomTable from "components/CustomTable";
// import { TableToolBarModel } from "components/CustomTable/CustomTableModel";
// import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

const columns: Column[] = [
  {
    id: "order",
    label: "workspaces.checklist.item.order",
    minWidth: 50,
    align: "left",
  },
  {
    id: "questions",
    label: "common.columns.questions",
    minWidth: 300,
    align: "left",
  },
  {
    id: "checkboxes",
    label: "sidebar.mui.inputs.checkboxes",
    minWidth: 80,
    align: "left",
    format: (value, record) => {
      return (
        <Checkbox
          sx={{
            "&.MuiCheckbox-root": {
              padding: "2px",
            },
            "& .MuiSvgIcon-root": { fontSize: 16 },
          }}
          disabled
        />
      );
    },
  },
  {
    id: "comments",
    label: "common.comments",
    minWidth: 200,
    align: "left",
  },
  {
    id: "references",
    label: "common.columns.references",
    minWidth: 150,
    align: "left",
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    minWidth: 150,
    align: "left",
    format: (value, record) => {
      return (
        <Box>
          <EditIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
          <DeleteIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
          <NorthIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
          <SouthIcon sx={{ cursor: "pointer", fontSize: "16px" }} />
        </Box>
      );
    },
  },
];

const rows: any[] = [
  {
    order: "1",
    questions: "Has the data from the CAPA system been reviewed for relevance?",
    checked: false,
    comments: [],
    references: "",
  },
  {
    order: "2",
    questions: "Have the FDA safety databases been reviewed for relevance?",
    checked: false,
    comments: [],
    references: "",
  },
  {
    order: "3",
    questions: "Have the complaints and field issues of the legacy device been reviewed for applicability?",
    checked: false,
    comments: [],
    references: "",
  },
  {
    order: "4",
    questions: "Have the hazards for similar devices been reviewed for applicability?",
    checked: false,
    comments: [],
    references: "",
  },
  {
    order: "5",
    questions: "Have the clinical rationales for harm severity and P2 been provided?",
    checked: false,
    comments: [],
    references: "",
  },
  {
    order: "6",
    questions:
      "Have the contributing factors due to use / environmental conditions and system interactions been considered for foreseeable scenario enumeration?",
    checked: false,
    comments: [],
    references: "",
  },
];

// const toolbar: TableToolBarModel = {
//   buttons: [
//     {
//       id: "common.customize",
//       icon: TableHeaderButtonsIconType.SETTING,
//       type: "outlined",
//       onChange: () => {},
//     },
//   ],
// };

const Checklist = () => {
  return (
    <CustomTable
      // toolBar={toolbar}
      columns={columns}
      rows={rows}
      showPage={false}
      maxHeight="auto"
      addRow={() => {}}
      addRowTextId="common.add"
    />
  );
};

export default Checklist;
