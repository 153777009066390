import Icon from "@mui/material/Icon";
import * as React from "react";

const TechnicalFileSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <rect fill="#01579B" opacity=".1" width="20" height="20" rx="4" />
      <g fill="#01579B" fillRule="nonzero">
        <path d="M16.983 2.5H8.19a.517.517 0 0 0-.518.517v13.966c0 .285.232.517.518.517h8.793a.517.517 0 0 0 .517-.517V3.017a.517.517 0 0 0-.517-.517zm-4.397 12.414a1.034 1.034 0 1 1 0-2.07 1.034 1.034 0 0 1 0 2.07zm2.586-6.724H10a.259.259 0 1 1 0-.518h5.172a.259.259 0 1 1 0 .518zm0-2.587H10a.259.259 0 0 1 0-.517h5.172a.259.259 0 1 1 0 .517zM7.155 12.328H3.017V4.569h4.138v-.517H2.76a.259.259 0 0 0-.259.258v9.31c0 .144.116.26.259.26h4.396v-1.552zM7.155 15.431H4.31a.259.259 0 1 0 0 .517h2.845v-.517z" />
      </g>
    </g>
  </svg>
);

const TechnicalFileIcon: React.FunctionComponent = () => {
  return <Icon component={TechnicalFileSvg} />;
};

export default TechnicalFileIcon;
