import IntlMessages from "@crema/utility/IntlMessages";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  styled,
  TablePagination,
  Theme,
} from "@mui/material";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import AppScrollbar from "../AppScrollbar";
import AppTooltip from "../AppTooltip";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  ".notification-top": {
    padding: theme.spacing(1, 6),
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  ".notifications-divider": {
    height: "16px",
    alignSelf: "auto",
  },
  ".notification-title": {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "1.44",
    color: "rgba(0, 0, 0, 0.85)",
  },
  ".header-actions": {
    ".markAllAsRead-btn": {
      marginRight: "6px",
    },
    ".MuiFormControlLabel-root": {
      marginLeft: "0",
    },
    // ".notifications-only-unread": {
    //   padding: theme.spacing(0),
    // },
  },
  ".notification-box": {
    width: "688px",
    maxHeight: "calc(100vh - 180px)",
  },
  ".notification-list": {
    width: "100%",
    paddingLeft: "24px",
    paddingRight: "24px",
    backgroundColor: "#FFF",
    ".MuiListItem-root": {
      padding: theme.spacing(3, 0),
      maxWidth: "640px",
      ".MuiListItemAvatar-root": {
        // marginTop: "0",
        minWidth: "44px",
      },
      ".title": {
        lineHeight: "22px",
        fontWeight: "bold",
      },
      ".datetime": {
        color: "rgba(0, 0, 0, 0.45)",
      },
      ".task-name": {
        lineHeight: "22px",
      },
    },
  },
}));

interface NotificationModel {
  id?: number | string;
  avatar?: string;
  personName?: string;
  notificationTitle?: string;
  taskName?: string;
  productUrl?: string;
  taskUrl?: string;
  productName?: string;
  createDate?: string;
  isRead?: boolean;
}

const createNotificationModels = async (onlyUnread?: boolean) => {
  const p = new Promise<NotificationModel[]>((resolve, reject) => {
    setTimeout(() => {
      let notifications: NotificationModel[] = [
        {
          id: 2,
          avatar: null,
          notificationTitle: "Lucy commented on an issue",
          taskName: "TK#012 assign multiple individual admin account",
          taskUrl: "/products/123/dir/worksheet",
          productUrl: "/products",
          productName: "Thermometers",
          createDate: "15:21:49 01/14/2022",
          isRead: false,
        },
        {
          id: 3,
          avatar: null,
          notificationTitle: "Lucy commented on an issue",
          taskName: "TK#011 Allow to import Risk ID and Risk Comments through Bulk Append",
          taskUrl: "/products/123/und/worksheet",
          productUrl: "/products",
          productName: "Thermometers",
          createDate: "16:02:10 01/02/2022",
          isRead: false,
        },
        {
          id: 4,
          avatar: null,
          notificationTitle: "Lucy commented on an issue",
          taskName: "TK#004 Allow to import Risk ID and Risk Comments through Bulk Append",
          taskUrl: "/products/123/sha/worksheet",
          productUrl: "/products",
          productName: "Thermometers",
          createDate: "10:53:03 12/28/2021",
          isRead: true,
        },
        {
          id: 5,
          avatar: null,
          notificationTitle: "Lucy commented on an issue",
          taskName: "TK#006 Allow to import Risk ID and Risk Comments through Bulk Append",
          taskUrl: "/products/123/df/worksheet",
          productUrl: "/products",
          productName: "Thermometers",
          createDate: "12:51:09 12/20/2021",
          isRead: true,
        },
      ];
      if (onlyUnread) {
        notifications = [
          {
            id: 2,
            avatar: null,
            notificationTitle: "Lucy commented on an issue",
            taskName: "TK#012 assign multiple individual admin account",
            taskUrl: "/products/123/dir/worksheet",
            productUrl: "/products",
            productName: "Thermometers",
            createDate: "15:21:49 01/14/2022",
            isRead: false,
          },
          {
            id: 3,
            avatar: null,
            notificationTitle: "Lucy commented on an issue",
            taskName: "TK#011 Allow to import Risk ID and Risk Comments through Bulk Append",
            taskUrl: "/products/123/und/worksheet",
            productUrl: "/products",
            productName: "Thermometers",
            createDate: "16:02:10 01/02/2022",
            isRead: false,
          },
        ];
      }
      resolve(notifications);
    }, 200);
  });
  const r = await p;
  return r;
};

interface AppNotificationsProps {
  drawerPosition?: "left" | "top" | "right" | "bottom";
  tooltipPosition?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  isMenu?: boolean;
  sxNotificationContentStyle?: SxProps<Theme>;
}

const AppNotifications: React.FC<AppNotificationsProps> = ({
  drawerPosition = "right",
  tooltipPosition = "bottom",
  isMenu = false,
  sxNotificationContentStyle = {},
}) => {
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [onlyUnread, setOnlyUnread] = useState<boolean>(false);

  const [notificationData, setNotificationData] = useState<NotificationModel[]>([]);

  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(100);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createNotificationModelsFn = async (onlyUnread) => {
    const res = await createNotificationModels(onlyUnread);
    if (res) {
      setNotificationData(res);
    }
  };
  useEffect(() => {
    console.log(`onlyUnread: ${onlyUnread}`);
    createNotificationModelsFn(onlyUnread);
  }, [onlyUnread]);

  const handleRead = (value) => {
    console.log(value);
  };

  const list = useMemo(() => {
    if (!notificationData) {
      return <></>;
    }
    const listDom = [];
    notificationData.forEach((x, index) => {
      listDom.push(
        <React.Fragment key={`React.Fragment-${x.id}`}>
          <ListItem alignItems="flex-start" className="flex-wrap">
            <Box className="flex" sx={{ width: "100%" }}>
              <ListItemAvatar>
                <Avatar alt={x.personName} src={x.avatar ? x.avatar : "/static/images/avatar/1.jpg"} />
              </ListItemAvatar>
              <Box className="flex items-center justify-between flex-wrap" sx={{ width: "100%" }}>
                <Box className="flex items-center justify-between" sx={{ width: "100%" }}>
                  <Box className="title">{x.notificationTitle}</Box>
                  <Box className="datetime flex justify-center items-center relative">
                    <Box sx={{ lineHeight: "21px" }}>{x.createDate}</Box>
                    {x.isRead === false ? (
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "20px",
                          right: "-4px",
                        }}
                        onClick={() => {
                          handleRead(x);
                        }}
                      >
                        <Box
                          className="flex justify-center items-center"
                          sx={{
                            width: "16px",
                            height: "16px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "8px",
                              height: "8px",
                              bgcolor: "#de350b",
                              borderRadius: "50%",
                            }}
                          ></Box>
                        </Box>
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                <Box className="task-name" sx={{ width: "100%" }}>
                  <Link href={x.taskUrl} underline="none">
                    {x.taskName}
                  </Link>
                </Box>
                <Box sx={{ width: "100%", marginTop: "8px" }}>
                  <Chip
                    size="small"
                    label={x.productName}
                    onClick={() => {
                      navigate(x.productUrl);
                    }}
                    sx={{
                      textAlign: "center",
                      background: "rgba(143, 146, 161, 0.2)",
                      color: "rgba(0, 0, 0, 0.85)",
                      height: "20px",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </ListItem>
          {index === notificationData.length - 1 ? "" : <Divider component="li" />}
        </React.Fragment>
      );
    });
    return listDom;
  }, [notificationData]);

  return (
    <>
      {isMenu ? (
        <Box component="span" onClick={handleClick}>
          Message
        </Box>
      ) : (
        <AppTooltip title="Notification" placement={tooltipPosition}>
          <IconButton
            className="icon-btn"
            sx={{
              borderRadius: "50%",
              width: 30,
              height: 30,
              color: (theme) => theme.palette.text.secondary,
              // backgroundColor: (theme) => `transparent`,//theme.palette.background.default,
              // border: 1,
              borderColor: "transparent",
              "&:hover, &:focus": {
                color: (theme) => theme.palette.text.primary,
                backgroundColor: (theme) => alpha(theme.palette.background.default, 0.9),
                borderColor: (theme) => alpha(theme.palette.text.secondary, 0.25),
              },
            }}
            onClick={handleClick}
            size="large"
          >
            <NotificationsNoneIcon />
          </IconButton>
        </AppTooltip>
      )}

      <Popover
        id="notifications-box"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          padding: "12px 24px",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: "50%",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translate(50%, -50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Container>
          <Box className="notification-top flex justify-between items-center">
            <Box className="notification-title">
              <IntlMessages id="academy.notifications" />
            </Box>
            <Box className="header-actions flex items-center justify-between">
              <Box className="markAllAsRead-btn">
                <Button>
                  <IntlMessages id="notifications.markAllAsRead" />
                </Button>
              </Box>
              <Divider className="notifications-divider" orientation="vertical" flexItem />
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      className="notifications-only-unread"
                      value={onlyUnread}
                      onChange={(e) => {
                        setOnlyUnread(e.target.checked);
                      }}
                    />
                  }
                  label={<IntlMessages id="notifications.only.unread" />}
                />
              </Box>
            </Box>
          </Box>
          <AppScrollbar className="notification-box">
            <List className="notification-list">{list}</List>
          </AppScrollbar>

          <Box className="page-box" sx={{ padding: "0 24px" }}>
            <TablePagination
              component="div"
              count={total}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Container>
      </Popover>
    </>
  );
};

export default AppNotifications;
