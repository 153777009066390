import Icon from "@mui/material/Icon";
import * as React from "react";

const FolderSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" fill="none">
      <path
        d="M13.474 13.52H2.526A.524.524 0 0 1 2 12.998V4.442c0-.292.24-.522.526-.522h10.948c.294 0 .526.237.526.522v8.556c0 .292-.24.522-.526.522z"
        fill="#FF9A00"
      />
      <path d="M8.24 6.32H2V3.51a.549.549 0 0 1 .547-.55H6.82c.241 0 .454.157.525.392L8.24 6.32z" fill="#FF9A00" />
      <path
        d="M12.982 13.04H3.498a.53.53 0 0 1-.538-.522V5.402a.53.53 0 0 1 .538-.522h9.484c.3 0 .538.237.538.522v7.116c.007.285-.238.522-.538.522z"
        fill="#FFF"
      />
      <path
        d="M13.474 13.52H2.526A.52.52 0 0 1 2 13.008V6.352a.52.52 0 0 1 .526-.512h10.948a.52.52 0 0 1 .526.512v6.656a.52.52 0 0 1-.526.512z"
        fill="#FFC700"
      />
      <path
        d="M6.066 7.83H3.221c-.14 0-.261-.12-.261-.275 0-.148.114-.275.26-.275h2.84c.14 0 .26.12.26.275 0 .148-.114.276-.254.276zm0 1.85H3.221c-.14 0-.261-.12-.261-.275 0-.156.114-.276.26-.276h2.84c.14 0 .26.12.26.276 0 .155-.114.275-.254.275z"
        fill="#FFF8DE"
      />
    </g>
  </svg>
);

const FolderIcon: React.FunctionComponent = () => {
  return <Icon component={FolderSvg} />;
};

export default FolderIcon;
