import { SelectProcessData } from "types/models/Products";

import {
  ProductsActionTypes,
  UPDATE_LEFT_TREE_OPEN,
  UPDATE_LEFT_TREE_SELECTED,
  UPDATE_productsCategory,
  UPDATE_productsDetailMenu,
  UPDATE_STAGE,
  UPDATE_VARIANT,
  UPDATE_VERSION,
} from "../../types/actions/Products.action";

export const handleVersion = (version: string): ProductsActionTypes => ({
  type: UPDATE_VERSION,
  version,
});

export const handleStage = (stage: string): ProductsActionTypes => ({
  type: UPDATE_STAGE,
  stage,
});

export const handleVariant = (variant: string | string): ProductsActionTypes => ({
  type: UPDATE_VARIANT,
  variant,
});

export const handleCategory = (productsCategory: string): ProductsActionTypes => ({
  type: UPDATE_productsCategory,
  productsCategory,
});

export const handleMenu = (productsDetailMenu: string): ProductsActionTypes => ({
  type: UPDATE_productsDetailMenu,
  productsDetailMenu,
});

export const handleLeftTreeOpen = (leftTreeOpen: boolean): ProductsActionTypes => ({
  type: UPDATE_LEFT_TREE_OPEN,
  leftTreeOpen,
});

export const handleLeftTreeSelected = (selectProcess: SelectProcessData): ProductsActionTypes => ({
  type: UPDATE_LEFT_TREE_SELECTED,
  selectProcess,
});
