import IntlMessages from "@crema/utility/IntlMessages";
import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useIntl } from "react-intl";
import { textColor } from "shared/constants/AppConst";

const DestinationContainer = styled(Box)(({ theme }) => ({
  ".tabs-box": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  ".tab-sub-table": {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderTop: "0",
    padding: theme.spacing(3),
    background: "#FFF",
    ".new-input": {
      marginBottom: theme.spacing(3),
    },
  },
}));

const DestinationProductList = [
  {
    id: 1,
    name: "Drug Delivery Device",
  },
  {
    id: 2,
    name: "Drug Delivery Device",
  },
  {
    id: 3,
    name: "Drug Delivery Device",
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number | string) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

interface DestinationProps {
  destinationProduct: string;
}
const Destination = (props: DestinationProps) => {
  const { destinationProduct } = props;
  const { messages } = useIntl();

  const [value, setValue] = useState<string>("new-release");

  const [destination, setDestination] = useState(destinationProduct);
  const [newProduct, setNewProduct] = useState("");
  const [newRelease, setNewRelease] = useState("");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <DestinationContainer>
      <Box
        className="w-full flex items-center"
        sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
      >
        <h3>
          <IntlMessages id="product.saveAsNew.select.destination" />
        </h3>
      </Box>
      <Box>
        <Box className="flex tabs-box">
          <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab
              value={"new-release"}
              label={<IntlMessages id="product.saveAsNew.tabs.newRelease" />}
              {...a11yProps("new-release")}
            />
            <Tab
              value={"new-product"}
              label={<IntlMessages id="product.saveAsNew.tabs.newProduct" />}
              {...a11yProps("new-product")}
            />
          </Tabs>
        </Box>
        <Box className="tab-sub-table">
          <TabPanel value={value} index={"new-release"}>
            <Grid container spacing={2} className="items-center new-input">
              <Grid item xs={5} md={5}>
                <Box>
                  <IntlMessages id="product.saveAsNew.destination.product" />:
                </Box>
              </Grid>
              <Grid item xs={7} md={7}>
                <Typography>{destinationProduct}</Typography>
                {/* <TextField
                  variant="outlined"
                  defaultValue={destinationProduct}
                  placeholder={String(messages["common.pleaseEnter"])}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setNewRelease(e.target.value);
                  }}
                />
                <Select
                  labelId="select-header"
                  label=""
                  value={destination}
                  // labelWidth={100}
                  onChange={(e) => setDestination(e.target.value)}
                  fullWidth
                >
                  {DestinationProductList.map((x) => (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select> */}
              </Grid>
            </Grid>
            <Grid container spacing={2} className="items-center new-input">
              <Grid item xs={5} md={5}>
                <Box>
                  <IntlMessages id="product.saveAsNew.new.variant" />:
                </Box>
              </Grid>
              <Grid item xs={7} md={7}>
                <TextField
                  variant="outlined"
                  value={newRelease}
                  placeholder={String(messages["common.pleaseEnter"])}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setNewRelease(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={"new-product"}>
            <Grid container spacing={2} className="items-center new-input">
              <Grid item xs={5} md={5}>
                <Box className="flex-initial">
                  <IntlMessages id="product.saveAsNew.new.product" />:
                </Box>
              </Grid>
              <Grid item xs={7} md={7}>
                <TextField
                  className="flex-initial"
                  variant="outlined"
                  value={newProduct}
                  placeholder={String(messages["common.pleaseEnter"])}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setNewProduct(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="items-center new-input">
              <Grid item xs={5} md={5}>
                <Box>
                  <IntlMessages id="product.saveAsNew.new.variant" />:
                </Box>
              </Grid>
              <Grid item xs={7} md={7}>
                <TextField
                  variant="outlined"
                  value={newRelease}
                  placeholder={String(messages["common.pleaseEnter"])}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setNewRelease(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </Box>
    </DestinationContainer>
  );
};
export default Destination;
