import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, styled } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { bgColor, boxShadow, textColor } from "shared/constants/AppConst";

import FlowDiagram from "./components/FlowDiagram";

const DiagramContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  ".workflow-diagram-box": {
    width: "100%",
    minHeight: "200px",
    ".diagram-box": {
      width: "100%",
      height: "inherit",
      minHeight: "200px",
    },
  },
}));

const flowData = {
  nodeData: [
    {
      key: 1,
      text: "●",
      color: "#FFF",
      figure: "Ellipse",
      width: 40,
      height: 40,
      stroke: "#000",
    },
    { key: 2, text: "Draft", color: "#FFF", width: 40, height: 40, stroke: "#000" },
    { key: 3, text: "In Review", color: "#FFF", stroke: "#00FFFF" },
    { key: 4, text: "Approved", color: "#FFF", stroke: "#016FA0" },
    { key: 5, text: "Rejected", color: "#FFF", stroke: "#D9001B" },
  ],
  linkData: [
    {
      key: "1_2",
      from: 1,
      to: 2,
      fromSpot: "Right",
      toSpot: "Left",
      text: "Initialize",
      textColor: "#63A103",
    },
    {
      key: "2_3",
      from: 2,
      to: 3,
      fromSpot: "Right",
      toSpot: "Left",
      text: "Review",
      textColor: "#00AAAA",
    },
    {
      key: "3_2",
      from: 3,
      to: 2,
      fromSpot: "Left",
      toSpot: "Right",
      text: "Rework",
      textColor: "#F59A23",
    },
    {
      key: "3_4",
      from: 3,
      to: 4,
      fromSpot: "Right",
      toSpot: "Left",
      text: "Approve",
      textColor: "#0000FF",
    },
    {
      key: "4_2",
      from: 4,
      to: 2,
      fromSpot: "Top",
      toSpot: "Top",
      text: "Rework",
      textColor: "#F59A23",
    },
    {
      key: "3_5",
      from: 3,
      to: 5,
      fromSpot: "Bottom",
      toSpot: "Top",
      text: "Reject",
      textColor: "#D9001B",
    },
    {
      key: "5_2",
      from: 5,
      to: 2,
      fromSpot: "Left",
      toSpot: "Bottom",
      text: "Rework",
      textColor: "#F59A23",
    },
  ],
};

const dataJson = {
  class: "GraphLinksModel",
  linkKeyProperty: "key",
  nodeDataArray: [
    {
      key: 1,
      text: "●",
      color: "#FFF",
      figure: "Ellipse",
      width: 40,
      height: 40,
      stroke: "#000",
      loc: "-56.5 67.7560791015625",
    },
    { key: 2, text: "Draft", color: "#FFF", width: 40, height: 40, stroke: "#000", loc: "69.5 67.7560791015625" },
    { key: 3, text: "In Review", color: "#FFF", stroke: "#00FFFF", loc: "259.03564453125 67.7560791015625" },
    { key: 4, text: "Approved", color: "#FFF", stroke: "#016FA0", loc: "466.18603515625 67.7560791015625" },
    { key: 5, text: "Rejected", color: "#FFF", stroke: "#D9001B", loc: "259.18603515625 140.7560791015625" },
  ],
  linkDataArray: [
    { key: "1_2", from: 1, to: 2, fromSpot: "Right", toSpot: "Left", text: "Initialize", textColor: "#63A103" },
    { key: "2_3", from: 2, to: 3, fromSpot: "Right", toSpot: "Left", text: "Review", textColor: "#00AAAA" },
    { key: "3_2", from: 3, to: 2, text: "Rework", textColor: "#F59A23" },
    { key: "3_4", from: 3, to: 4, fromSpot: "Right", toSpot: "Left", text: "Approve", textColor: "#0000FF" },
    { key: "4_2", from: 4, to: 2, fromSpot: "Top", toSpot: "Top", text: "Rework", textColor: "#F59A23" },
    { key: "3_5", from: 3, to: 5, fromSpot: "Bottom", toSpot: "Top", text: "Reject", textColor: "#D9001B" },
    { key: "5_2", from: 5, to: 2, fromSpot: "Left", toSpot: "Bottom", text: "Rework", textColor: "#F59A23" },
  ],
};

interface CollaborationProps {
  [x: string]: any;
  isPadding?: boolean;
}

const Collaboration: React.FC<CollaborationProps> = (props) => {
  const params = useParams();
  console.log(params);
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AppScrollbar
      sx={{
        maxHeight: "calc(100vh - 105px)",
        background: bgColor.main,
        padding: props?.isPadding ? (theme) => theme.spacing(5) : 0,
      }}
    >
      <Box
        sx={{
          padding: (theme) => theme.spacing(5),
          background: (theme) => theme.palette.background.paper,
          boxShadow: boxShadow.cardBoxShadow,
        }}
      >
        <Box className="w-full flex justify-between items-center" sx={{ height: "48px" }}>
          <Box sx={{ fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}>
            <IntlMessages id="process.collaboration.workflow" />
          </Box>
        </Box>
        <Box className="w-full border">
          <DiagramContainer>
            <FlowDiagram {...flowData} dataJson={dataJson} className="workflow-diagram-box" />
          </DiagramContainer>
        </Box>
        <Box
          className="w-full flex items-center"
          sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
        >
          <IntlMessages id="process.collaboration.subTasks" />
        </Box>
        <Box className="w-full border" sx={{ height: "280px" }}>
          sub tasks
        </Box>
      </Box>
    </AppScrollbar>
  );
};

export default Collaboration;
