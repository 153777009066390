import { Box, CircularProgress } from "@mui/material";

interface CircularLoadingProps {
  loading?: boolean;
  children?: React.ReactNode;
}
const CircularLoading = (props: CircularLoadingProps) => {
  const { loading, children } = props;
  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default CircularLoading;
