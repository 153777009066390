import IntlMessages from "@crema/utility/IntlMessages";
import { Box, styled, Tab, Tabs } from "@mui/material";
import React from "react";
import { boxShadow, textColor } from "shared/constants/AppConst";

import GraphicView from "./GraphicView";
import TableView from "./TableView";

function a11yProps(index: number | string) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Container = styled(Box)(({ theme }) => ({
  ".MuiTabs-root": {
    backgroundColor: "#dce0e6",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    minHeight: "auto",
    marginBottom: theme.spacing(0),
    ".MuiTabs-scroller": {
      width: "auto",
    },
    ".MuiTab-root": {
      padding: "1px 8px",
      minWidth: "auto",
      minHeight: "24px",
      textTransform: "capitalize",
      color: textColor[65],
      fontWeight: "400",
    },
    ".Mui-selected": {
      color: textColor[85],
      borderRadius: "2px",
      boxShadow: boxShadow.cardBoxShadow,
      backgroundColor: theme.palette.background.paper,
      fontWeight: "500",
    },
    ".MuiTabs-indicator": {
      display: "none",
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const ItemRelationships = () => {
  const [index, setIndex] = React.useState("graphic");

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setIndex(newValue);
  };

  return (
    <Container>
      <Box
        sx={{
          padding: "20px",
          background: "#fff",
          boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box className="flex">
          <Tabs value={index} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab value={"graphic"} label={<IntlMessages id="common.graphic.view" />} {...a11yProps("graphic")} />
            <Tab value={"table"} label={<IntlMessages id="common.table.view" />} {...a11yProps("table")} />
          </Tabs>
        </Box>
        <Box sx={{ height: "calc(100% - 49px)" }}>
          <TabPanel index={"graphic"} value={index}>
            <GraphicView />
          </TabPanel>
          <TabPanel index={"table"} value={index}>
            <TableView />
          </TabPanel>
        </Box>
      </Box>
    </Container>
  );
};

export default ItemRelationships;
