import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import MyTree from "components/MyTree";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { textColor } from "shared/constants/AppConst";

const dataSourceMockData = {
  "Risk Management": {
    dataSourceDataSet: [
      {
        id: "Risk Management",
        value: "Risk Management",
        isBold: true,
        items: [
          {
            id: "Safety Risks",
            value: "Safety Risks",
            isBold: true,
            items: [
              {
                id: "Safety Harms",
                value: "Safety Harms",
              },
              {
                id: "Hazards",
                value: "Hazards",
                isBold: true,
                items: [
                  {
                    id: "Hazard Category",
                    value: "Hazard Category",
                  },
                  {
                    id: "System Hazards",
                    value: "System Hazards",
                  },
                  {
                    id: "Hazardous Situations",
                    value: "Hazardous Situations",
                  },
                  {
                    id: "Causes",
                    value: "Causes",
                  },
                  {
                    id: "Basic Events",
                    value: "Basic Events",
                  },
                  {
                    id: "Risk Controls",
                    value: "Risk Controls",
                  },
                ],
              },
            ],
          },
          {
            id: "Security Risks",
            value: "Security Risks",
            isBold: true,
            items: [
              {
                id: "Security Harms",
                value: "Security Harms",
              },
              {
                id: "Threats",
                value: "Threats",
                isBold: true,
                items: [
                  {
                    id: "Sub Threats",
                    value: "Threats",
                  },
                  {
                    id: "Vulnerabilities",
                    value: "Vulnerabilities",
                  },
                  {
                    id: "Threat Attacks",
                    value: "Threat Attacks",
                  },
                ],
              },
            ],
          },
          {
            id: "FMEAs",
            value: "FMEAs",
            isBold: true,
            items: [
              {
                id: "Design FMEA",
                value: "Design FMEA",
              },
              {
                id: "Process FMEA",
                value: "Process FMEA",
              },
              {
                id: "Product Error Codes",
                value: "Product Error Codes",
              },
              {
                id: "Code Lib",
                value: "Code Lib",
              },
            ],
          },
          {
            id: "Evidences",
            value: "Evidences",
            isBold: true,
            items: [
              {
                id: "Risk Benefit Analysis Ref",
                value: "Risk Benefit Analysis Ref.",
              },
            ],
          },
        ],
      },
    ],
  },
  "Requirement Management": {
    dataSourceDataSet: [
      {
        id: "Design Inputs",
        value: "Design Inputs",
        isBold: true,
        items: [
          {
            id: "User Needs",
            value: "User Needs",
          },
          {
            id: "Design Input Requirements",
            value: "Design Input Requirements",
          },
          {
            id: "HW Subsystem Requirements",
            value: "HW Subsystem Requirements",
          },
          {
            id: "SW Subsystem Requirements",
            value: "SW Subsystem Requirements",
          },
        ],
      },
      {
        id: "Design Outputs",
        value: "Design Outputs",
        isBold: true,
        items: [
          {
            id: "Design Specifications",
            value: "Design Specifications",
          },
          {
            id: "DMR Design Outputs",
            value: "DMR Design Outputs",
          },
        ],
      },
      {
        id: "Design Verifications",
        value: "Design Verifications",
        isBold: true,
        items: [
          {
            id: "System Verifications",
            value: "System Verifications",
          },
          {
            id: "SW Verifications",
            value: "SW Verifications",
          },
          {
            id: "HW Verifications",
            value: "HW Verifications",
          },
          {
            id: "Test Cases",
            value: "Test Cases",
          },
        ],
      },
      {
        id: "Design Validations",
        value: "Design Validations",
        isBold: true,
        items: [
          {
            id: "Validations",
            value: "Validations",
          },
        ],
      },
      {
        id: "Design Transfer",
        value: "Design Transfer",
        isBold: true,
        items: [
          {
            id: "IQ_OQ_PQ",
            value: "IQ/OQ/PQ",
          },
        ],
      },
    ],
  },
  "Design Controls": {
    dataSourceDataSet: [
      {
        id: "Design Controls",
        value: "Design Controls",
        isBold: true,
        items: [
          {
            id: "Design Inputs",
            value: "Design Inputs",
            isBold: true,
            items: [
              {
                id: "User Needs",
                value: "User Needs",
              },
              {
                id: "Design Input Requirements",
                value: "Design Input Requirements",
              },
              {
                id: "HW Subsystem Requirements",
                value: "HW Subsystem Requirements",
              },
              {
                id: "SW Subsystem Requirements",
                value: "SW Subsystem Requirements",
              },
            ],
          },
          {
            id: "Design Outputs",
            value: "Design Outputs",
            isBold: true,
            items: [
              {
                id: "Design Specifications",
                value: "Design Specifications",
              },
              {
                id: "DMR Design Outputs",
                value: "DMR Design Outputs",
              },
            ],
          },
          {
            id: "Design Verifications",
            value: "Design Verifications",
            isBold: true,
            items: [
              {
                id: "System Verifications",
                value: "System Verifications",
              },
              {
                id: "SW Verifications",
                value: "SW Verifications",
              },
              {
                id: "HW Verifications",
                value: "HW Verifications",
              },
              {
                id: "Test Cases",
                value: "Test Cases",
              },
            ],
          },
          {
            id: "Design Validations",
            value: "Design Validations",
            isBold: true,
            items: [
              {
                id: "Validations",
                value: "Validations",
              },
            ],
          },
          {
            id: "Design Transfer",
            value: "Design Transfer",
            isBold: true,
            items: [
              {
                id: "IQ_OQ_PQ",
                value: "IQ/OQ/PQ",
              },
            ],
          },
        ],
      },
    ],
  },
};

const DataSources = () => {
  const params = useParams();
  const { pid } = params;

  const [dataSourceDataSet, setDataSourceDataSet] = useState([]);
  const [moduleName, setModuleName] = useState<string>("");

  useEffect(() => {
    if (pid) {
      const mockData = dataSourceMockData[pid];
      if (mockData) {
        setDataSourceDataSet(mockData.dataSourceDataSet);
        setModuleName(" of " + pid);
      }
    }
  }, [pid]);

  const [selected, setSelected] = React.useState<any>("Design Controls");

  const onNodeSelect = (event: object, value: string) => {
    setSelected(value);
  };

  return (
    <>
      <Box
        sx={{
          paddingBottom: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Box
          className="w-full flex items-center"
          sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
        >
          <IntlMessages id="common.data.sources" />
          {moduleName}
        </Box>
      </Box>
      <AppScrollbar
        sx={{
          height: "calc(100% - 56px)",
          width: "100%",
          padding: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MyTree
            selected={selected}
            onNodeSelect={onNodeSelect}
            data={dataSourceDataSet}
            useStyledTree={true}
            showFolder={true}
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </AppScrollbar>
    </>
  );
};

export default DataSources;
