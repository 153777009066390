import IntlMessages from "@crema/utility/IntlMessages";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { Box, Button, InputAdornment, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { styled } from "@mui/material/styles";
import ButtonMenu from "components/ButtonMenu";
import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import HistoryModal from "pages/admin/ProcessSettings/HistoryModal";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { handleSelectedModuleIdAction } from "redux/actions";
import { borderColor, textColor } from "shared/constants/AppConst";

const statusLists: any[] = [
  {
    id: "1",
    label: "Draft",
  },
  {
    id: "2",
    label: "In Review",
  },
  {
    id: "3",
    label: "Approved",
  },
];

// When selecting the root node
const moduleMenuList = (pid: string) => {
  const moduleMenuMockData = {
    "Design Controls": [
      // {
      //   id: "1",
      //   labelId: "common.item.types",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/itemTypes/${pid}`,
      // },
      // {
      //   id: "2",
      //   labelId: "common.item.relationships",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/itemRelationships/${pid}`,
      // },
      // {
      //   id: "3",
      //   labelId: "common.item.workflow",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/itemWorkflow/${pid}`,
      // },
      {
        id: "4",
        labelId: "common.data.sources",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/dataSources/${pid}`,
      },
      // {
      //   id: "5",
      //   labelId: "common.rules",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/rules/moduleLevel/${pid}`,
      // },
      // {
      //   id: "6",
      //   labelId: "common.industry.standards",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/industryStandards/${pid}`,
      // },
      // {
      //   id: "7",
      //   labelId: "common.process.flow",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/processFlow/${pid}`,
      // },
      // {
      //   id: "8",
      //   labelId: "common.sops",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/sops/${pid}`,
      // },
      // {
      //   id: "9",
      //   labelId: "common.deliverable.workflow",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/deliverableWorkflow/${pid}`,
      // },
      // {
      //   id: "10",
      //   labelId: "common.metrics.config",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/metricsConfig/${pid}`,
      // },
    ],
    "Risk Management": [
      // {
      //   id: "1",
      //   labelId: "common.item.types",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/itemTypes/${pid}`,
      // },
      // {
      //   id: "2",
      //   labelId: "common.item.relationships",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/itemRelationships/${pid}`,
      // },
      // {
      //   id: "3",
      //   labelId: "common.item.workflow",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/itemWorkflow/${pid}`,
      // },
      {
        id: "4",
        labelId: "common.data.sources",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/dataSources/${pid}`,
      },
      // {
      //   id: "5",
      //   labelId: "common.rules",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/rules/moduleLevel/${pid}`,
      // },
      // {
      //   id: "6",
      //   labelId: "common.industry.standards",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/industryStandards/${pid}`,
      // },
      // {
      //   id: "7",
      //   labelId: "common.process.flow",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/processFlow/${pid}`,
      // },
      // {
      //   id: "8",
      //   labelId: "common.sops",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/sops/${pid}`,
      // },
      // {
      //   id: "9",
      //   labelId: "common.deliverable.workflow",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/deliverableWorkflow/${pid}`,
      // },
      // {
      //   id: "10",
      //   labelId: "common.metrics.config",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/metricsConfig/${pid}`,
      // },
    ],
    "Requirement Management": [
      // {
      //   id: "1",
      //   labelId: "common.item.types",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/itemTypes/${pid}`,
      // },
      // {
      //   id: "2",
      //   labelId: "common.item.relationships",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/itemRelationships/${pid}`,
      // },
      // {
      //   id: "3",
      //   labelId: "common.item.workflow",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/itemWorkflow/${pid}`,
      // },
      {
        id: "4",
        labelId: "common.data.sources",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/dataSources/${pid}`,
      },
      // {
      //   id: "6",
      //   labelId: "common.industry.standards",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/industryStandards/${pid}`,
      // },
      // {
      //   id: "7",
      //   labelId: "common.process.flow",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/processFlow/${pid}`,
      // },
      // {
      //   id: "8",
      //   labelId: "common.sops",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/sops/${pid}`,
      // },
      // {
      //   id: "9",
      //   labelId: "common.deliverable.workflow",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/deliverableWorkflow/${pid}`,
      // },
      // {
      //   id: "10",
      //   labelId: "common.metrics.config",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/metricsConfig/${pid}`,
      // },
    ],
  };
  return moduleMenuMockData[pid] || moduleMenuMockData["Design Controls"];
};

// When selecting a non root node
const processMenuList = (moduleId: string, pid: string) => {
  const processMenuMockData = {
    "Risk Management": [
      {
        id: "1",
        labelId: "common.scope",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/scope/${pid}`,
      },
      {
        id: "2",
        labelId: "common.input",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/input/tab/${pid}/required`,
      },
      // {
      //   id: "3",
      //   labelId: "common.rules",
      //   icon: <AddLinkIcon />,
      //   url: `/admin/processSettings/label/rules/processLevel/${pid}`,
      // },
      {
        id: "4",
        labelId: "common.collaboration",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/collaboration/${pid}`,
      },
      {
        id: "5",
        labelId: "common.worksheet",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/worksheet/tab/${pid}/format`,
      },
      {
        id: "6",
        labelId: "common.impact.summary",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/ImpactSummary/${pid}`,
      },
      {
        id: "7",
        labelId: "common.checklist",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/checklist/${pid}`,
      },
      {
        id: "8",
        labelId: "common.report",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/report/${pid}`,
      },
    ],
    "Requirement Management": [
      {
        id: "1",
        labelId: "common.scope",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/scope/${pid}`,
      },
      {
        id: "2",
        labelId: "common.input",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/input/tab/${pid}/required`,
      },
      {
        id: "4",
        labelId: "common.collaboration",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/collaboration/${pid}`,
      },
      {
        id: "5",
        labelId: "common.worksheet",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/worksheet/tab/${pid}/format`,
      },
      {
        id: "6",
        labelId: "common.impact.summary",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/ImpactSummary/${pid}`,
      },
      {
        id: "8",
        labelId: "common.report",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/report/${pid}`,
      },
    ],
    "Design Controls": [
      {
        id: "1",
        labelId: "common.scope",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/scope/${pid}`,
      },
      {
        id: "2",
        labelId: "common.input",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/input/tab/${pid}/required`,
      },
      {
        id: "4",
        labelId: "common.collaboration",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/collaboration/${pid}`,
      },
      {
        id: "5",
        labelId: "common.worksheet",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/worksheet/tab/${pid}/format`,
      },
      {
        id: "6",
        labelId: "common.impact.summary",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/ImpactSummary/${pid}`,
      },
      {
        id: "8",
        labelId: "common.report",
        icon: <AddLinkIcon />,
        url: `/admin/processSettings/label/report/${pid}`,
      },
    ],
  };
  return processMenuMockData[moduleId] || processMenuMockData["Design Controls"];
};

const ToolBarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "12px",
  ".toolbar-left": {
    display: "flex",
    background: "#FFF",
  },
}));

interface ToolBarProps {
  selectedProcess: any;
}

const ToolBar: React.FC<ToolBarProps> = ({ selectedProcess }) => {
  const location = useLocation();
  const [status, setStatus] = React.useState(statusLists[0]?.id);
  const [menuList, setMenuList] = React.useState([]);
  const [moduleId, setModuleId] = React.useState("Design Controls");
  const [selectedValue, setSelectedValue] = React.useState<ButtonMenuListItemModel>();
  const [openHistoryModal, setOpenHistoryModal] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const handleSelectedValue = (item: ButtonMenuListItemModel) => {
    setSelectedValue(item);
  };

  React.useEffect(() => {
    if (
      selectedProcess
      // && location.pathname.endsWith("/processSettings/label")
    ) {
      const pid = selectedProcess.id;
      if (selectedProcess.isModule) {
        setModuleId(pid);
      }
      const newMenuList = selectedProcess.isModule ? moduleMenuList(pid) : processMenuList(moduleId, pid);

      if (newMenuList) {
        setMenuList(newMenuList);
        setSelectedValue(newMenuList[0]);
        newMenuList[0].url && navigate(newMenuList[0].url);
      }
    }
  }, [selectedProcess, moduleId]);

  React.useEffect(() => {
    if (moduleId) {
      if (dispatch) {
        dispatch(handleSelectedModuleIdAction(moduleId));
      }
    }
  }, [moduleId, dispatch]);
  return (
    <ToolBarContainer>
      <Box className="toolbar-left">
        <Select
          sx={{
            minWidth: 60,
            m: 0,
            background: "#fff",
            "& .MuiInputAdornment-root": {
              color: textColor[85],
            },
            "& .MuiInputBase-input": {
              padding: "6px 0px",
              color: textColor[85],
            },
          }}
          id="status-select"
          value={status}
          onChange={handleStatusChange}
          startAdornment={<InputAdornment position="start">Status:</InputAdornment>}
        >
          {statusLists.map((profile) => (
            <MenuItem key={profile.id} value={profile.id}>
              {profile.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className="toolbar-right">
        {!selectedProcess.isModule && (
          <ButtonMenu
            selectedValue={selectedValue}
            setSelectedValue={handleSelectedValue}
            list={menuList}
            variant="outlined"
            sxStyle={{
              sx: {
                mr: 2,
                background: "#fff",
                color: textColor[85],
                borderRadius: "4px",
                border: "solid 1px rgba(0, 0, 0, 0.15)",
              },
            }}
          />
        )}

        {/* <More actionLevel={selectedProcess.isModule ? 1 : 2} /> */}
        <Button
          sx={{
            color: textColor[85],
            border: `solid 1px ${borderColor.dividerColor}`,
            fontWeight: "normal",
            background: (theme) => theme.palette.background.paper,
          }}
          startIcon={<AddLinkIcon />}
          variant="outlined"
          onClick={() => {
            setOpenHistoryModal(true);
          }}
        >
          <IntlMessages id="setting.process.history" />
        </Button>
      </Box>
      {openHistoryModal && (
        <HistoryModal
          open={openHistoryModal}
          handleClose={() => {
            setOpenHistoryModal(false);
          }}
          actionLevel={selectedProcess.isModule ? 1 : 2}
        />
      )}
    </ToolBarContainer>
  );
};

export default ToolBar;
