import IntlMessages from "@crema/utility/IntlMessages";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import React, { ReactNode } from "react";

interface TemplateHeaderProps {
  title: ReactNode;
  subTitle?: ReactNode;
  children?: ReactNode;
}
const TemplateHeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
  ".flex": {
    flexWrap: "wrap",
    padding: "0",
    "& h2": {
      width: "100%",
    },
    "& .sub-title": {
      marginTop: theme.spacing(4),
      width: "100%",
    },
  },
  ".flex-buttons": {
    display: "flex",
    alignItems: "end",
    paddingRight: theme.spacing(4),
  },
}));
const TemplateHeader: React.FC<TemplateHeaderProps> = (props) => {
  const { title, subTitle, children } = props;

  return (
    <TemplateHeaderContainer>
      <Box
        sx={{
          px: 6,
        }}
        className="flex justify-between items-center"
      >
        <h2>{title}</h2>
        <div className="sub-title">{subTitle}</div>
      </Box>
      <Box className="flex-buttons">{children && children}</Box>
    </TemplateHeaderContainer>
  );
};

export default TemplateHeader;
