import { useThemeContext } from "@crema/utility/AppContextProvider/ThemeContextProvider";
import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

interface AppLogoProps {
  color?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({ color }) => {
  const { theme } = useThemeContext();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: { xs: 40, sm: 56 },
        paddingX: 0,
        paddingY: 4,
        mr: 6,
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
          height: { xs: 24, sm: 42 },
        },
        "& img": {
          height: { md: 36 },
        },
      }}
      className="app-logo"
      onClick={() => {
        navigate("/home");
      }}
    >
      <Box component="img" src="/assets/images/gess-net-logo@2x.png"></Box>
      <Box sx={{ width: "1px", height: "24px", m: "0 16px", opacity: "0.4", backgroundColor: "#fff" }}></Box>
      <Box component="img" src="/assets/images/qm-space-white@2x.png"></Box>
    </Box>
  );
};

export default AppLogo;
