import TaskItemIcon from "components/Icons/TaskItemIcon";
import { Box } from "@mui/material";

export const taskData = {
  sha: {
    product: "Thermometers (Main R1.0)",
    task: "TK#001 System Hazard Analysis V1.0",
    excelTableColHeaders: [
      "ID",
      "Hazard",
      "Hazardous Situation",
      "Sequence of Events(SoE)",
      "Analysis of Failure Modes",
      "Harm",
      "Severity",
      "P1 SOE (U)",
      "P1 (U)",
      "P2 (U)",
      "POH (U)",
      "Risk (U)",
      "Risk Controls",
      "Risk Control Introduced Risks",
      "P1 SOE (M)",
      "P1 (M)",
      "P2 (M)",
      "POH (M)",
      "Risk Region (M)",
      "RC Requirements",
      "V & V",
    ],
    excelTableColumns: [
      {
        data: "id",
      },
      {
        data: "hazard",
      },
      {
        data: "hazardous_situation",
      },
      {
        data: "sequence_of_events",
      },
      {
        data: "analysis_of_failure_modes",
      },
      {
        data: "harm",
      },
      {
        data: "severity",
      },
      {
        data: "p1_soe_u",
      },
      {
        data: "p1_u",
      },
      {
        data: "p2_u",
      },
      {
        data: "poh_u",
      },
      {
        data: "risk_u",
      },
      {
        data: "risk_controls",
      },
      {
        data: "risk_control_introduced_risks",
      },
      {
        data: "p1_soe_m",
      },
      {
        data: "p1_m",
      },
      {
        data: "p2_m",
      },
      {
        data: "poh_m",
      },
      {
        data: "risk_region_m",
      },
      {
        data: "rc_requirements",
      },
      {
        data: "v_v",
      },
    ],
    // excelTableData1: [
    //   [
    //     "ID",
    //     "Hazard",
    //     "Hazardous Situation",
    //     "Sequence of Events(SoE)",
    //     "Analysis of Failure Modes",
    //     "Harm",
    //     "Severity",
    //     "P1 SOE (U)",
    //     "P1 (U)",
    //     "P2 (U)",
    //     "POH (U)",
    //     "Risk (U)",
    //     "Risk Controls",
    //     "Risk Control Introduced Risks",
    //     "P1 SOE (M)",
    //     "P1 (M)",
    //     "P2 (M)",
    //     "POH (M)",
    //     "Risk Region (M)",
    //     "RC Requirements",
    //     "V & V",
    //   ],
    //   [
    //     "HA#001",
    //     "Incorrect Temperature Measurement",
    //     "Undetected fever",
    //     "",
    //     "",
    //     "Untreated underlying conditions",
    //     "4",
    //     "",
    //     "2",
    //     "1",
    //     "2",
    //     "Med",
    //     "",
    //     "",
    //     "",
    //     "1",
    //     "1",
    //     "1",
    //     "Low",
    //     "",
    //     "",
    //   ],
    // ],
    excelTableData: [
      {
        id: "ID",
        hazard: "Hazard",
        hazardous_situation: "Hazardous Situation",
        sequence_of_events: "Sequence of Events(SoE)",
        analysis_of_failure_modes: "Analysis of Failure Modes",
        harm: "Harm",
        severity: "Severity",
        p1_soe_u: "P1 SOE (U)",
        p1_u: "P1 (U)",
        p2_u: "P2 (U)",
        poh_u: "POH (U)",
        risk_u: "Risk (U)",
        risk_controls: "Risk Controls",
        risk_control_introduced_risks: "Risk Control Introduced Risks",
        p1_soe_m: "P1 SOE (M)",
        p1_m: "P1 (M)",
        p2_m: "P2 (M)",
        poh_m: "POH (M)",
        risk_region_m: "Risk Region (M)",
        rc_requirements: "RC Requirements",
        v_v: "V & V",
      },
      {
        id: "HA#001",
        hazard: "Incorrect Temperature Measurement",
        hazardous_situation: "Undetected fever",
        sequence_of_events: "",
        analysis_of_failure_modes: "",
        harm: "Untreated underlying conditions",
        severity: "4",
        p1_soe_u: "",
        p1_u: "2",
        p2_u: "1",
        poh_u: "2",
        risk_u: "Med",
        risk_controls: "",
        risk_control_introduced_risks: "",
        p1_soe_m: "",
        p1_m: "1",
        p2_m: "1",
        poh_m: "1",
        risk_region_m: "Low",
        rc_requirements: "",
        v_v: "",
      },
      {
        id: "HA#002",
        hazard: "",
        hazardous_situation: "",
        sequence_of_events: "",
        analysis_of_failure_modes: "",
        harm: "Pain, discomfort",
        severity: "2",
        p1_soe_u: "",
        p1_u: "2",
        p2_u: "3",
        poh_u: "2",
        risk_u: "Low",
        risk_controls: "",
        risk_control_introduced_risks: "",
        p1_soe_m: "",
        p1_m: "1",
        p2_m: "3",
        poh_m: "1",
        risk_region_m: "Low",
        rc_requirements: "",
        v_v: "",
      },
      {
        id: "HA#003",
        hazard: "",
        hazardous_situation: "",
        sequence_of_events: "Thermometers malfunction",
        analysis_of_failure_modes: "FMEA#001\nFMEA#003\nFMEA#005",
        harm: "",
        severity: "",
        p1_soe_u: "3",
        p1_u: "",
        p2_u: "",
        poh_u: "",
        risk_u: "",
        risk_controls: "The thermometer has a 3 yr reliability",
        risk_control_introduced_risks: "",
        p1_soe_m: "1",
        p1_m: "",
        p2_m: "",
        poh_m: "",
        risk_region_m: "",
        rc_requirements: "SYS#016",
        v_v: "VAL-1 Self taking of temperature",
      },
      {
        id: "HA#004",
        hazard: "",
        hazardous_situation: "",
        sequence_of_events: "",
        analysis_of_failure_modes: "",
        harm: "",
        severity: "",
        p1_soe_u: "",
        p1_u: "",
        p2_u: "",
        poh_u: "",
        risk_u: "",
        risk_controls: "Self-test is performed at power on",
        risk_control_introduced_risks: "",
        p1_soe_m: "",
        p1_m: "",
        p2_m: "",
        poh_m: "",
        risk_region_m: "",
        rc_requirements: "SYS#022",
        v_v: "VAL-1 Self taking of temperature",
      },
      {
        id: "HA#005",
        hazard: "",
        hazardous_situation: "",
        sequence_of_events: "",
        analysis_of_failure_modes: "",
        harm: "",
        severity: "",
        p1_soe_u: "",
        p1_u: "",
        p2_u: "",
        poh_u: "",
        risk_u: "",
        risk_controls:
          "The IFU instructs not to use the thermometer if the indication of a successful self-test is not provided",
        risk_control_introduced_risks: "",
        p1_soe_m: "",
        p1_m: "",
        p2_m: "",
        poh_m: "",
        risk_region_m: "",
        rc_requirements: "",
        v_v: "",
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
    ],
    workItemAnalysis: {
      title: "SOE#003 Thermometers malfunction",
      feedsRows: [
        {
          id: "FD#123",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343458 Fail to physically set up pump",
          description: "Fault Tree Analysis should be modified according to SHA.",
        },
        {
          id: "FD#124",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343456 Fail to set up administration set",
          description: "SHA in SDDDB and SDDDC should be updated.",
        },
        {
          id: "FD#125",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#003 Process FMEA (V1.0)",
          scope2: "S#2312356 difficulty to lift pump",
          description: "Need create a system requirement",
        },
      ],
      details: {
        description: "try to add description, table, images, code blocks, and more",
        baseInfo: [
          {
            id: "1",
            label: "Context",
            value: "user/operator not able to setup the pump system timely",
          },
          {
            id: "2",
            label: "Assumption",
            value:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin",
          },
          {
            id: "3",
            label: "Strategy",
            value:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin",
          },
          {
            id: "4",
            label: "Argument",
            value:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin",
          },
        ],
        categories: [
          {
            id: "1",
            label: "HM: Harms",
            checkboxes: [
              {
                id: "11",
                label: "Overdoes",
                checked: false,
              },
              {
                id: "12",
                label: "Underdoes",
                checked: false,
              },
              {
                id: "13",
                label: "Infection",
                checked: false,
              },
            ],
          },
          {
            id: "2",
            label: "ToC: Type of Cause",
            checkboxes: [
              {
                id: "21",
                label: "Hardware",
                checked: false,
              },
              {
                id: "22",
                label: "Software",
                checked: false,
              },
              {
                id: "23",
                label: "Human Factor",
                checked: false,
              },
            ],
          },
          {
            id: "3",
            label: "HZ: Hazards",
            checkboxes: [
              {
                id: "31",
                label: "Under Delivery",
                checked: false,
              },
              {
                id: "32",
                label: "Over Delivery  ",
                checked: false,
              },
            ],
          },
          {
            id: "4",
            label: "RRN: Regulatory Review Need",
            checkboxes: [
              {
                id: "41",
                label: "Yes",
                checked: false,
              },
              {
                id: "42",
                label: "No",
                checked: false,
              },
            ],
          },
        ],
        commentList: [
          {
            id: "1",
            title: "TSK#ABC-123 SHA1.0",
            replyContent: "",
            children: [
              {
                id: "11",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fred",
                type: "Comment",
                date: "09/29/2021",
                content: "Comment for a comment",
                re: "@TSK#ABC-123",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
          {
            id: "2",
            title: "CL3-RISK-45 Design for easy setup and self check the healthiness of pump during setup",
            replyContent: "",
            children: [
              {
                id: "21",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fubin",
                type: "Answer",
                date: "09/29/2021",
                content: "Reject!answer",
                re: "@CL3-RISK-45",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
        ],
      },
      relationships: {
        nodeDataArray: [
          {
            key: 0,
            text: "RC#001 The thermometer has a 3 yr reliability",
            loc: "111.26142374915396 128.08898316152442",
          },
          { key: 1, text: "COFM#001 The LCD is malfunctioning", loc: "413.78427124746185 128.08898316152442" },
          {
            key: 2,
            text: "FMEA#001 Temperature measurement not displayed",
            loc: "716.3071187457698 52.54181925696648",
          },
          { key: 3, text: "EOF#001 Fever not detected", loc: "1018.8299662440777 52.54181925696648" },
          {
            key: 4,
            text: "SOE#003 Thermometers malfunction",
            color: "lightgreen",
            loc: "1328.3528137423848 136.54181925696645",
          },
          { key: 5, text: "HS#003", loc: "1595.875661240693 136.5418192569665" },
          { key: 6, text: "HA#003", loc: "1898.398508739001 136.5418192569665" },
          {
            key: 7,
            text: "SYS#016 The system shall function within specification for at least 36 months without needing replacement of any parts.",
            loc: "2200.9213562373093 136.5418192569665",
          },
          {
            key: 8,
            text: "FMEA#003 Temperature measurement displayed incorrectly",
            loc: "716.3071187457698 136.3450622630869",
          },
          { key: 9, text: "EOF#003 Fever indicated when no fever exists", loc: "1018.8299662440777 136.3450622630869" },
          { key: 10, text: "FMEA#005", loc: "716.3071187457698 211.89222616764488" },
          {
            key: 11,
            text: "EOF#005 Fever not indicated when fever exists",
            loc: "1018.8299662440777 211.89222616764488",
          },
        ],
        linkDataArray: [
          { key: 1, from: 0, to: 1 },
          { key: 2, from: 1, to: 2 },
          { key: 3, from: 2, to: 3 },
          { key: 4, from: 3, to: 4 },
          { key: 5, from: 4, to: 5 },
          { key: 6, from: 5, to: 6 },
          { key: 7, from: 6, to: 7 },
          { key: 8, from: 1, to: 8 },
          { key: 9, from: 8, to: 9 },
          { key: 10, from: 9, to: 4 },
          { key: 11, from: 1, to: 10 },
          { key: 12, from: 10, to: 11 },
          { key: 13, from: 11, to: 4 },
        ],
      },
    },
    perspectives: {
      dataSources: {
        dataSourceTypeList: [
          {
            id: "1",
            label: "Hazard Category",
          },
          {
            id: "2",
            label: "System Hazards",
          },
          {
            id: "3",
            label: "Hazardous Situations",
          },
          {
            id: "4",
            label: "Sequence of Events/Causes",
          },
          {
            id: "5",
            label: "Safety Harms",
          },
        ],
        treeData: [
          {
            value: "Safety Risks",
            id: "safety-risks",
            opened: true,
            items: [
              {
                value: "Hazards",
                id: "hazards",
                opened: true,
                items: [
                  {
                    value: "Hazard Category",
                    id: "hazard-category",
                    opened: true,
                    items: [
                      {
                        value: "HAZ#001 Therapeutic",
                        id: "therapeutic",
                        opened: true,
                      },
                      {
                        value: "HAZ#002 Electrical Energy",
                        id: "electrical-energy",
                        opened: true,
                      },
                      {
                        value: "HAZ#003 Mechanical Energy",
                        id: "mechanical-energy",
                        opened: true,
                      },
                      {
                        value: "HAZ#004 Biological Energy",
                        id: "biological-energy",
                        opened: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    resources: {
      Deliverables: {
        excelTable: {
          columns: [
            {
              data: "feature",
            },
            {
              data: "failure_mode",
            },
            {
              data: "cause_of_failure",
            },
            {
              data: "local_effect",
            },
            {
              data: "system_effect",
            },
            {
              data: "hazardous_situations",
            },
            {
              data: "harms",
            },
            {
              data: "p1",
            },
            {
              data: "ph",
            },
            {
              data: "risk_index",
            },
            {
              data: "add_control",
            },
            {
              data: "p2",
            },
            {
              data: "severity",
            },
          ],
          colHeaders: [
            "Feature/Function",
            "Failure Mode",
            "Cause of Failure",
            "Local Effect",
            "System Effect",
            "Hazardous Situations",
            "Harms",
            "P1",
            "Ph",
            "Risk Index",
            "Add Control",
            "HS:P2",
            "Hams:Severity",
          ],
          data: [
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Electrical component failure",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Less than intended drug solution is delivered",
              harms: "Loss of mobility",
              p1: "4\n2",
              ph: "TBD\nTBD",
              risk_index: "TBD\nTBD",
              add_control:
                "The Power Supply automatically switches from AC mode to battery mode whenever AC power is unavailable",
              p2: "",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Electrical component failure",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Loss of mobility",
              p1: "3\n2",
              ph: "PR\nOC",
              risk_index: "Hight\nMED",
              add_control: "One gree LED lamp is provided to illuminate the AC Mains connected (AC On) indicator",
              p2: "4\n4",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Electrical component failure",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Delay of treatment",
              p1: "3\n2",
              ph: "OC\nOC",
              risk_index: "MED\nMED",
              add_control: "One gree LED lamp is provided to illuminate the AC Mains connected (AC On) indicator",
              p2: "4\n4",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Death",
              p1: "3\n2",
              ph: "OC\nRE",
              risk_index: "MED\nMED",
              add_control: "One gree LED lamp is provided to illuminate the AC Mains connected (AC On) indicator",
              p2: "2\n2",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Loose connector",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Less than intended drug solution is delivered",
              harms: "Loss of mobility",
              p1: "2",
              ph: "TBD\nTBD",
              risk_index: "TBD\nTBD",
              add_control: "",
              p2: "",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Loose connector",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Delay of treatment",
              p1: "3",
              ph: "OC\nTBD",
              risk_index: "MED\nTBD",
              add_control: "",
              p2: "4\n4",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC supply voltage is less than required",
              cause_of_failure: "AC power line overloaded",
              local_effect: "Insufficient AC supply voltage",
              system_effect: "Pump cannot be operated with AC supply",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Loss of mobility",
              p1: "5\n2",
              ph: "FR\nOC",
              risk_index: "High\nMED",
              add_control:
                "The Power Supply automatically switches from AC mode to battery mode whenever AC power is unavailable",
              p2: "4\n4",
              severity: "3\n3",
            },
          ],
        },
      },
    },
  },
  ura: {
    product: "Thermometers (Main R1.0)",
    task: "TK#001 System Hazard Analysis V1.0",
    excelTableColHeaders: [
      "ID",
      "Use Scenario",
      "Task Description",
      "Potential Use Eror",
      "Foreseeable sequence of events",
      "Hazardous Situation",
      "Harm",
      "Severity",
      "P1 (U)",
      "P2 (U)",
      "PH (U)",
      "Risk (U)",
      "Risk Control",
      "P1 (M)",
      "P2 (M)",
      "PH (M)",
      "Risk(M)",
      "Requirements",
      "V & V",
    ],
    excelTableColumns: [
      {
        data: "id",
      },
      {
        data: "use_scenario",
      },
      {
        data: "task_description",
      },
      {
        data: "potential_use_error",
      },
      {
        data: "foreseeable_swquence_of_events",
      },
      {
        data: "hazardous_situation",
      },
      {
        data: "harm",
      },
      {
        data: "severity",
      },
      {
        data: "p1_u",
      },
      {
        data: "p2_u",
      },
      {
        data: "ph_u",
      },
      {
        data: "risk_u",
      },
      {
        data: "risk_control",
      },
      {
        data: "p1_m",
      },
      {
        data: "p2_m",
      },
      {
        data: "ph_m",
      },
      {
        data: "risk_m",
      },
      {
        data: "requirements",
      },
      {
        data: "v_v",
      },
    ],
    excelTableData: [
      {
        id: "ID",
        use_scenario: "Use Scenario",
        task_description: "Task Description",
        potential_use_error: "Potential Use Eror",
        foreseeable_swquence_of_events: "Foreseeable sequence of events",
        hazardous_situation: "Hazardous Situation",
        harm: "Harm",
        severity: "Severity",
        p1_u: "P1 (U)",
        p2_u: "P2 (U)",
        ph_u: "PH (U)",
        risk_u: "Risk (U)",
        risk_control: "Risk Control",
        p1_m: "P1 (M)",
        p2_m: "P2 (M)",
        ph_m: "PH (M)",
        risk_m: "Risk(M)",
        requirements: "Requirements",
        v_v: "V & V",
      },
      {
        id: "UER#001",
        use_scenario: "Gate keeper uses the thermomenter to screen visitors",
        task_description: "Measure visitor's forehead temperature",
        potential_use_error: "thermometer is held too far away from the forehead",
        foreseeable_swquence_of_events: "false positive measurement",
        hazardous_situation: "failed to identify a potential Covid infected patient",
        harm: "Covid infection",
        severity: "3",
        p1_u: "",
        p2_u: "",
        ph_u: "",
        risk_u: "",
        risk_control: "detects the distance and inform the user for proper distance",
        p1_m: "",
        p2_m: "",
        ph_m: "",
        risk_m: "",
        requirements: "DID#005",
        v_v: "VT#567",
      },
      {
        id: "",
        use_scenario: "",
        task_description: "",
        potential_use_error: "",
        foreseeable_swquence_of_events: "",
        hazardous_situation: "",
        harm: "",
        severity: "",
        p1_u: "",
        p2_u: "",
        ph_u: "",
        risk_u: "",
        risk_control: "IFU instructs user on proper distance during the measurement",
        p1_m: "",
        p2_m: "",
        ph_m: "",
        risk_m: "",
        requirements: "",
        v_v: "",
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
    ],
    workItemAnalysis: {
      title: "UER#001 thermometer is held too far away from the forehead",
      feedsRows: [
        {
          id: "FD#123",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343458 Fail to physically set up pump",
          description: "Fault Tree Analysis should be modified according to SHA.",
        },
        {
          id: "FD#124",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343456 Fail to set up administration set",
          description: "SHA in SDDDB and SDDDC should be updated.",
        },
        {
          id: "FD#125",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#003 Process FMEA (V1.0)",
          scope2: "S#2312356 difficulty to lift pump",
          description: "Need create a system requirement",
        },
      ],
      details: {
        description: "try to add description, table, images, code blocks, and more",
        baseInfo: [
          {
            id: "1",
            label: "Context",
            value: "user/operator not able to setup the pump system timely",
          },
          {
            id: "2",
            label: "Assumption",
            value:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin",
          },
          {
            id: "3",
            label: "Strategy",
            value:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin",
          },
          {
            id: "4",
            label: "Argument",
            value:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin",
          },
        ],
        categories: [
          {
            id: "1",
            label: "HM: Harms",
            checkboxes: [
              {
                id: "11",
                label: "Overdoes",
                checked: false,
              },
              {
                id: "12",
                label: "Underdoes",
                checked: false,
              },
              {
                id: "13",
                label: "Infection",
                checked: false,
              },
            ],
          },
          {
            id: "2",
            label: "ToC: Type of Cause",
            checkboxes: [
              {
                id: "21",
                label: "Hardware",
                checked: false,
              },
              {
                id: "22",
                label: "Software",
                checked: false,
              },
              {
                id: "23",
                label: "Human Factor",
                checked: false,
              },
            ],
          },
          {
            id: "3",
            label: "HZ: Hazards",
            checkboxes: [
              {
                id: "31",
                label: "Under Delivery",
                checked: false,
              },
              {
                id: "32",
                label: "Over Delivery  ",
                checked: false,
              },
            ],
          },
          {
            id: "4",
            label: "RRN: Regulatory Review Need",
            checkboxes: [
              {
                id: "41",
                label: "Yes",
                checked: false,
              },
              {
                id: "42",
                label: "No",
                checked: false,
              },
            ],
          },
        ],
        commentList: [
          {
            id: "1",
            title: "TSK#ABC-123 SHA1.0",
            replyContent: "",
            children: [
              {
                id: "11",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fred",
                type: "Comment",
                date: "09/29/2021",
                content: "Comment for a comment",
                re: "@TSK#ABC-123",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
          {
            id: "2",
            title: "CL3-RISK-45 Design for easy setup and self check the healthiness of pump during setup",
            replyContent: "",
            children: [
              {
                id: "21",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fubin",
                type: "Answer",
                date: "09/29/2021",
                content: "Reject!answer",
                re: "@CL3-RISK-45",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
        ],
      },
      relationships: {
        nodeDataArray: [
          {
            key: 0,
            text: "VT#567",
            loc: "111.26142374915396 158.08898316152442",
          },
          { key: 1, text: "DID#005", loc: "413.78427124746185 158.08898316152442" },
          {
            key: 2,
            text: "detects the distance and inform the user for proper distance",
            loc: "716.3071187457698 158.08898316152442",
          },
          {
            key: 3,
            text: "thermometer is held too far away from the forehead",
            color: "lightgreen",
            loc: "1018.8299662440777 158.08898316152442",
          },
          {
            key: 4,
            text: "false positive measurement",
            loc: "1328.3528137423848 158.08898316152442",
          },
          {
            key: 5,
            text: "failed to identify a potential Covid infected patient",
            loc: "1595.875661240693 158.08898316152442",
          },
          { key: 6, text: "Covid infection", loc: "1898.398508739001 158.08898316152442" },
          {
            key: 7,
            text: "Gate keeper uses the thermometer to screen visitors",
            loc: "413.78427124746185 56.5418192569665",
          },
          {
            key: 8,
            text: "Measure visitor's forehead temperature",
            loc: "716.3071187457698 56.5418192569665",
          },
        ],
        linkDataArray: [
          { key: 1, from: 0, to: 1, dash: [2, 2] },
          { key: 2, from: 1, to: 2, dash: [2, 2] },
          { key: 3, from: 2, to: 3, dash: [2, 2] },
          { key: 4, from: 3, to: 4 },
          { key: 5, from: 4, to: 5 },
          { key: 6, from: 5, to: 6 },
          { key: 7, from: 7, to: 8, dash: [2, 2] },
          { key: 8, from: 8, to: 3, dash: [2, 2] },
        ],
      },
    },
    perspectives: {
      dataSources: {
        dataSourceTypeList: [
          {
            id: "1",
            label: "Hazard Category",
          },
          {
            id: "2",
            label: "System Hazards",
          },
          {
            id: "3",
            label: "Hazardous Situations",
          },
          {
            id: "4",
            label: "Sequence of Events/Causes",
          },
          {
            id: "5",
            label: "Safety Harms",
          },
        ],
        treeData: [
          {
            value: "Safety Risks",
            id: "safety-risks",
            opened: true,
            items: [
              {
                value: "Hazards",
                id: "hazards",
                opened: true,
                items: [
                  {
                    value: "Hazard Category",
                    id: "hazard-category",
                    opened: true,
                    items: [
                      {
                        value: "HAZ#001 Therapeutic",
                        id: "therapeutic",
                        opened: true,
                      },
                      {
                        value: "HAZ#002 Electrical Energy",
                        id: "electrical-energy",
                        opened: true,
                      },
                      {
                        value: "HAZ#003 Mechanical Energy",
                        id: "mechanical-energy",
                        opened: true,
                      },
                      {
                        value: "HAZ#004 Biological Energy",
                        id: "biological-energy",
                        opened: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    resources: {
      Deliverables: {
        excelTable: {
          columns: [
            {
              data: "feature",
            },
            {
              data: "failure_mode",
            },
            {
              data: "cause_of_failure",
            },
            {
              data: "local_effect",
            },
            {
              data: "system_effect",
            },
            {
              data: "hazardous_situations",
            },
            {
              data: "harms",
            },
            {
              data: "p1",
            },
            {
              data: "ph",
            },
            {
              data: "risk_index",
            },
            {
              data: "add_control",
            },
            {
              data: "p2",
            },
            {
              data: "severity",
            },
          ],
          colHeaders: [
            "Feature/Function",
            "Failure Mode",
            "Cause of Failure",
            "Local Effect",
            "System Effect",
            "Hazardous Situations",
            "Harms",
            "P1",
            "Ph",
            "Risk Index",
            "Add Control",
            "HS:P2",
            "Hams:Severity",
          ],
          data: [
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Electrical component failure",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Less than intended drug solution is delivered",
              harms: "Loss of mobility",
              p1: "4\n2",
              ph: "TBD\nTBD",
              risk_index: "TBD\nTBD",
              add_control:
                "The Power Supply automatically switches from AC mode to battery mode whenever AC power is unavailable",
              p2: "",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Electrical component failure",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Loss of mobility",
              p1: "3\n2",
              ph: "PR\nOC",
              risk_index: "Hight\nMED",
              add_control: "One gree LED lamp is provided to illuminate the AC Mains connected (AC On) indicator",
              p2: "4\n4",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Electrical component failure",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Delay of treatment",
              p1: "3\n2",
              ph: "OC\nOC",
              risk_index: "MED\nMED",
              add_control: "One gree LED lamp is provided to illuminate the AC Mains connected (AC On) indicator",
              p2: "4\n4",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Death",
              p1: "3\n2",
              ph: "OC\nRE",
              risk_index: "MED\nMED",
              add_control: "One gree LED lamp is provided to illuminate the AC Mains connected (AC On) indicator",
              p2: "2\n2",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Loose connector",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Less than intended drug solution is delivered",
              harms: "Loss of mobility",
              p1: "2",
              ph: "TBD\nTBD",
              risk_index: "TBD\nTBD",
              add_control: "",
              p2: "",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Loose connector",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Delay of treatment",
              p1: "3",
              ph: "OC\nTBD",
              risk_index: "MED\nTBD",
              add_control: "",
              p2: "4\n4",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC supply voltage is less than required",
              cause_of_failure: "AC power line overloaded",
              local_effect: "Insufficient AC supply voltage",
              system_effect: "Pump cannot be operated with AC supply",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Loss of mobility",
              p1: "5\n2",
              ph: "FR\nOC",
              risk_index: "High\nMED",
              add_control:
                "The Power Supply automatically switches from AC mode to battery mode whenever AC power is unavailable",
              p2: "4\n4",
              severity: "3\n3",
            },
          ],
        },
      },
    },
  },
  df: {
    product: "Thermometers (Main R1.0)",
    task: "TK#014 Design FMEA V1.1",
    excelTableColHeaders: [
      "ID",
      "Item",
      "Function",
      "Failure Mode",
      "Effect of Failure",
      "Trace to Higher Level",
      "Impact",
      "Cause of Failure Mode",
      "Design Controls",
      "Trace to System Requirements",
      "Prob of Occ",
      "Detection Controls",
      "Detection",
      "RPN",
    ],
    excelTableColumns: [
      {
        data: "id",
      },
      {
        data: "item",
      },
      {
        data: "function",
      },
      {
        data: "failure_mode",
      },
      {
        data: "effect_of_failure",
      },
      {
        data: "trace_to_higher_level",
      },
      {
        data: "impact",
      },
      {
        data: "cause_of_failure_mode",
      },
      {
        data: "design_controls",
      },
      {
        data: "trace_to_system_requirements",
      },
      {
        data: "prob_of_occ",
      },
      {
        data: "detection_controls",
      },
      {
        data: "detection",
      },
      {
        data: "rpn",
      },
    ],
    excelTableData: [
      {
        id: "ID",
        item: "Item",
        function: "Function",
        failure_mode: "Failure Mode",
        effect_of_failure: "Effect of Failure",
        trace_to_higher_level: "Trace to Higher Level",
        impact: "Impact",
        cause_of_failure_mode: "Cause of Failure Mode",
        design_controls: "Design Controls",
        trace_to_system_requirements: "Trace to System Requirements",
        prob_of_occ: "Prob of Occ",
        detection_controls: "Detection Controls",
        detection: "Detection",
        rpn: "RPN",
      },
      {
        id: "FMEA#001",
        item: "LCD Display",
        function: "IDisplay temperature measurement",
        failure_mode: "Temperature measurement not displayed",
        effect_of_failure: "Fever not detected",
        trace_to_higher_level: "HA#003",
        impact: "5",
        cause_of_failure_mode: "The LCD is malfunctioning",
        design_controls: "The LEDs provide a redundant method of indicating a fever",
        trace_to_system_requirements: "SYS#010",
        prob_of_occ: "2",
        detection_controls: "The LCD Display is tested at power on",
        detection: "1",
        rpn: "10",
      },
      {
        id: "FMEA#002",
        item: "",
        function: "",
        failure_mode: "",
        effect_of_failure: "",
        trace_to_higher_level: "",
        impact: "5",
        cause_of_failure_mode: "LCD is damaged from dropping",
        design_controls: "The thermometer can withstand a 5 ft drop",
        trace_to_system_requirements: "",
        prob_of_occ: "3",
        detection_controls: "The LCD Display is tested at power on",
        detection: "3",
        rpn: "45",
      },
      {
        id: "FMEA#003",
        item: "",
        function: "",
        failure_mode: "Temperature measurement displayed incorrectly",
        effect_of_failure: "Fever indicated when no fever exists",
        trace_to_higher_level: "HA#003",
        impact: "3",
        cause_of_failure_mode: "The LCD is malfunctioning",
        design_controls: "The LEDs provide a redundant method of indicating a fever",
        trace_to_system_requirements: "SYS#010",
        prob_of_occ: "2",
        detection_controls: "The LCD Display is tested at power on",
        detection: "1",
        rpn: "6",
      },
      {
        id: "FMEA#004",
        item: "",
        function: "",
        failure_mode: "",
        effect_of_failure: "",
        trace_to_higher_level: "",
        impact: "3",
        cause_of_failure_mode: "LCD is damaged from dropping",
        design_controls: "The thermometer can withstand a 5 ft drop",
        trace_to_system_requirements: "",
        prob_of_occ: "3",
        detection_controls: "The LCD Display is tested at power on",
        detection: "3",
        rpn: "27",
      },
      {
        id: "FMEA#005",
        item: "",
        function: "",
        failure_mode: "",
        effect_of_failure: "Fever not indicated when fever exists",
        trace_to_higher_level: "HA#003",
        impact: "3",
        cause_of_failure_mode: "The LCD is malfunctioning",
        design_controls: "The LEDs provide a redundant method of indicating a fever",
        trace_to_system_requirements: "SYS#010",
        prob_of_occ: "2",
        detection_controls: "The LCD Display is tested at power on",
        detection: "1",
        rpn: "8",
      },
      {
        id: "FMEA#006",
        item: "",
        function: "",
        failure_mode: "",
        effect_of_failure: "",
        trace_to_higher_level: "",
        impact: "4",
        cause_of_failure_mode: "LCD is damaged from dropping",
        design_controls: "The thermometer can withstand a 5 ft drop",
        trace_to_system_requirements: "",
        prob_of_occ: "3",
        detection_controls: "The LCD Display is tested at power on",
        detection: "3",
        rpn: "36",
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
    ],
    workItemAnalysis: {
      title: "FMEA#001 Temperature measurement not displayed",
      feedsRows: [
        {
          id: "FD#123",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343458 Fail to physically set up pump",
          description: "Fault Tree Analysis should be modified according to SHA.",
        },
        {
          id: "FD#124",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343456 Fail to set up administration set",
          description: "SHA in SDDDB and SDDDC should be updated.",
        },
        {
          id: "FD#125",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#003 Process FMEA (V1.0)",
          scope2: "S#2312356 difficulty to lift pump",
          description: "Need create a system requirement",
        },
      ],
      details: {
        description: "try to add description, table, images, code blocks, and more",
        baseInfo: [
          {
            id: "1",
            label: "Context",
            value: "user/operator not able to setup the pump system timely",
          },
          {
            id: "2",
            label: "Assumption",
            value:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin",
          },
          {
            id: "3",
            label: "Strategy",
            value:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin",
          },
          {
            id: "4",
            label: "Argument",
            value:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin",
          },
        ],
        categories: [
          {
            id: "1",
            label: "HM: Harms",
            checkboxes: [
              {
                id: "11",
                label: "Overdoes",
                checked: false,
              },
              {
                id: "12",
                label: "Underdoes",
                checked: false,
              },
              {
                id: "13",
                label: "Infection",
                checked: false,
              },
            ],
          },
          {
            id: "2",
            label: "ToC: Type of Cause",
            checkboxes: [
              {
                id: "21",
                label: "Hardware",
                checked: false,
              },
              {
                id: "22",
                label: "Software",
                checked: false,
              },
              {
                id: "23",
                label: "Human Factor",
                checked: false,
              },
            ],
          },
          {
            id: "3",
            label: "HZ: Hazards",
            checkboxes: [
              {
                id: "31",
                label: "Under Delivery",
                checked: false,
              },
              {
                id: "32",
                label: "Over Delivery  ",
                checked: false,
              },
            ],
          },
          {
            id: "4",
            label: "RRN: Regulatory Review Need",
            checkboxes: [
              {
                id: "41",
                label: "Yes",
                checked: false,
              },
              {
                id: "42",
                label: "No",
                checked: false,
              },
            ],
          },
        ],
        commentList: [
          {
            id: "1",
            title: "TSK#ABC-123 SHA1.0",
            replyContent: "",
            children: [
              {
                id: "11",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fred",
                type: "Comment",
                date: "09/29/2021",
                content: "Comment for a comment",
                re: "@TSK#ABC-123",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
          {
            id: "2",
            title: "CL3-RISK-45 Design for easy setup and self check the healthiness of pump during setup",
            replyContent: "",
            children: [
              {
                id: "21",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fubin",
                type: "Answer",
                date: "09/29/2021",
                content: "Reject!answer",
                re: "@CL3-RISK-45",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
        ],
      },
      relationships: {
        nodeDataArray: [
          {
            key: 0,
            text: "DES-C#001 The LEDs provide a redundant method of indicating a fever",
            loc: "111.26142374915396 98.57148031080791",
          },
          {
            key: 1,
            text: "DET-C#001 The LCD Display is tested at power on",
            loc: "111.26142374915396 218.40438437076983",
          },
          { key: 2, text: "COFM#001 The LCD is malfunctioning", loc: "413.78427124746185 98.57148031080791" },
          {
            key: 3,
            text: "FMEA#001 Temperature measurement not displayed",
            color: "lightgreen",
            loc: "716.3071187457698 98.57148031080791",
          },
          { key: 4, text: "EOF#001 Fever not detected", loc: "1018.8299662440777 52.54181925696646" },
          { key: 5, text: "SOE#003 Thermometers malfunction", loc: "1321.3528137423855 52.54181925696646" },
          { key: 6, text: "HS#003", loc: "1623.8756612406933 52.54181925696646" },
          { key: 7, text: "HA#003", loc: "1926.398508739001 52.54181925696647" },
          {
            key: 8,
            text: "SYS#016 The system shall function within specification for at least 36 months without needing replacement of any parts.",
            loc: "2228.921356237309 52.54181925696647",
          },
          { key: 9, text: "FUN#001 Display temperature measurement", loc: "1018.8299662440777 136.3450622630869" },
          { key: 10, text: "78321 LCD Display", loc: "1321.3528137423855 136.3450622630869" },
          {
            key: 11,
            text: "SYS#010 The system shall provide an audible indicator when the device reads above the fever threshold.",
            loc: "1623.8756612406933 136.3450622630869",
          },
        ],
        linkDataArray: [
          { key: 1, from: 0, to: 2 },
          { key: 2, from: 1, to: 2 },
          { key: 3, from: 2, to: 3 },
          { key: 4, from: 3, to: 4 },
          { key: 5, from: 4, to: 5 },
          { key: 6, from: 5, to: 6 },
          { key: 7, from: 6, to: 7 },
          { key: 8, from: 7, to: 8 },
          { key: 9, from: 3, to: 9 },
          { key: 10, from: 9, to: 10 },
          { key: 11, from: 10, to: 11 },
        ],
      },
    },
    perspectives: {
      dataSources: {
        dataSourceTypeList: [
          {
            id: "1",
            label: "Hazard Category",
          },
          {
            id: "2",
            label: "System Hazards",
          },
          {
            id: "3",
            label: "Hazardous Situations",
          },
          {
            id: "4",
            label: "Sequence of Events/Causes",
          },
          {
            id: "5",
            label: "Safety Harms",
          },
        ],
        treeData: [
          {
            value: "Safety Risks",
            id: "safety-risks",
            opened: true,
            items: [
              {
                value: "Hazards",
                id: "hazards",
                opened: true,
                items: [
                  {
                    value: "Hazard Category",
                    id: "hazard-category",
                    opened: true,
                    items: [
                      {
                        value: "HAZ#001 Therapeutic",
                        id: "therapeutic",
                        opened: true,
                      },
                      {
                        value: "HAZ#002 Electrical Energy",
                        id: "electrical-energy",
                        opened: true,
                      },
                      {
                        value: "HAZ#003 Mechanical Energy",
                        id: "mechanical-energy",
                        opened: true,
                      },
                      {
                        value: "HAZ#004 Biological Energy",
                        id: "biological-energy",
                        opened: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    resources: {
      Deliverables: {
        excelTable: {
          columns: [
            {
              data: "feature",
            },
            {
              data: "failure_mode",
            },
            {
              data: "cause_of_failure",
            },
            {
              data: "local_effect",
            },
            {
              data: "system_effect",
            },
            {
              data: "hazardous_situations",
            },
            {
              data: "harms",
            },
            {
              data: "p1",
            },
            {
              data: "ph",
            },
            {
              data: "risk_index",
            },
            {
              data: "add_control",
            },
            {
              data: "p2",
            },
            {
              data: "severity",
            },
          ],
          colHeaders: [
            "Feature/Function",
            "Failure Mode",
            "Cause of Failure",
            "Local Effect",
            "System Effect",
            "Hazardous Situations",
            "Harms",
            "P1",
            "Ph",
            "Risk Index",
            "Add Control",
            "HS:P2",
            "Hams:Severity",
          ],
          data: [
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Electrical component failure",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Less than intended drug solution is delivered",
              harms: "Loss of mobility",
              p1: "4\n2",
              ph: "TBD\nTBD",
              risk_index: "TBD\nTBD",
              add_control:
                "The Power Supply automatically switches from AC mode to battery mode whenever AC power is unavailable",
              p2: "",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Electrical component failure",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Loss of mobility",
              p1: "3\n2",
              ph: "PR\nOC",
              risk_index: "Hight\nMED",
              add_control: "One gree LED lamp is provided to illuminate the AC Mains connected (AC On) indicator",
              p2: "4\n4",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Electrical component failure",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Delay of treatment",
              p1: "3\n2",
              ph: "OC\nOC",
              risk_index: "MED\nMED",
              add_control: "One gree LED lamp is provided to illuminate the AC Mains connected (AC On) indicator",
              p2: "4\n4",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Death",
              p1: "3\n2",
              ph: "OC\nRE",
              risk_index: "MED\nMED",
              add_control: "One gree LED lamp is provided to illuminate the AC Mains connected (AC On) indicator",
              p2: "2\n2",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Loose connector",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Less than intended drug solution is delivered",
              harms: "Loss of mobility",
              p1: "2",
              ph: "TBD\nTBD",
              risk_index: "TBD\nTBD",
              add_control: "",
              p2: "",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC line fuse failure",
              cause_of_failure: "Loose connector",
              local_effect: "Loss of AC power",
              system_effect: "No AC power supply to pump",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Delay of treatment",
              p1: "3",
              ph: "OC\nTBD",
              risk_index: "MED\nTBD",
              add_control: "",
              p2: "4\n4",
              severity: "3\n3",
            },
            {
              feature: "Provide power to pump",
              failure_mode: "AC supply voltage is less than required",
              cause_of_failure: "AC power line overloaded",
              local_effect: "Insufficient AC supply voltage",
              system_effect: "Pump cannot be operated with AC supply",
              hazardous_situations: "Not able to timely start solution delivery",
              harms: "Loss of mobility",
              p1: "5\n2",
              ph: "FR\nOC",
              risk_index: "High\nMED",
              add_control:
                "The Power Supply automatically switches from AC mode to battery mode whenever AC power is unavailable",
              p2: "4\n4",
              severity: "3\n3",
            },
          ],
        },
      },
    },
  },
  und: {
    product: "Thermometers (Main R1.0)",
    task: "TK#101 User Needs V1.1",
    treeDataSet: [
      {
        id: "Design Inputs",
        value: "Design Inputs",
        items: [
          {
            id: "User Needs",
            value: "User Needs",
            items: [
              {
                id: "The user needs a device that can measure temperature accurately and quickly",
                value: "The user needs a device that can measure temperature accurately and quickly",
              },
              {
                id: "The user needs to able to accurately track the temperature of multiple patients when using the device",
                value:
                  "The user needs to able to accurately track the temperature of multiple patients when using the device",
              },
              {
                id: "The user needs the device to read body temperature without pain or discomfort.",
                value: "The user needs the device to read body temperature without pain or discomfort.",
              },
              {
                id: "The user needs a device that is easy to use.",
                value: "The user needs a device that is easy to use.",
              },
              {
                id: "The user needs to use the device using only one hand, either the right or left had.",
                value: "The user needs to use the device using only one hand, either the right or left had.",
              },
              {
                id: "The user needs a device that will indicate a fever without the user needing to read the numbers.",
                value:
                  "The user needs a device that will indicate a fever without the user needing to read the numbers.",
              },
              {
                id: "The user needs a device that is easy to clean.",
                value: "The user needs a device that is easy to clean.",
              },
              {
                id: "The user needs the device to track readings over a period of 6 months",
                value: "The user needs the device to track readings over a period of 6 months",
              },
              {
                id: "The user needs the device to provide a method of taking readings on different patients without needing disinfection.",
                value:
                  "The user needs the device to provide a method of taking readings on different patients without needing disinfection.",
              },
              {
                id: "The user needs the device to accurately measure temperature on any part of the body.",
                value: "The user needs the device to accurately measure temperature on any part of the body.",
              },
              {
                id: "The user needs the device to facilitate updating the patient's chart with temperature recorded.",
                value:
                  "The user needs the device to facilitate updating the patient's chart with temperature recorded.",
              },
              {
                id: "The user needs to be able to easily dispose of the product.",
                value: "The user needs to be able to easily dispose of the product.",
              },
              {
                id: "The user needs to be able to make results available to a physician.",
                value: "The user needs to be able to make results available to a physician.",
              },
              {
                id: "The user needs to easily store the product.",
                value: "The user needs to easily store the product.",
              },
              {
                id: "The user needs to be notified when temperature measurements are outside the specified range.",
                value: "The user needs to be notified when temperature measurements are outside the specified range.",
              },
              {
                id: "The user needs the device to last for two years without needing replacement of any parts.",
                value: "The user needs the device to last for two years without needing replacement of any parts.",
              },
              {
                id: "The user needs the device to units and temperatures that they are accustomed to.",
                value: "The user needs the device to units and temperatures that they are accustomed to.",
              },
              {
                id: "The user needs the device to meet regulatory requirements in the countries for which it is intended to be sold.",
                value:
                  "The user needs the device to meet regulatory requirements in the countries for which it is intended to be sold.",
              },
              {
                id: "The user needs the device to work on all patient populations.",
                value: "The user needs the device to work on all patient populations.",
              },
            ],
          },
        ],
      },
    ],
    treeTableColumns: [
      {
        id: "name",
        label: "common.name",
        minWidth: 200,
        align: "left",
      },
      {
        id: "id",
        label: "common.display.id",
        minWidth: 200,
        align: "left",
      },
      {
        id: "user_types",
        label: "common.user.types",
        minWidth: 200,
        align: "left",
      },
      {
        id: "priority",
        label: "common.priority",
        minWidth: 200,
        align: "left",
      },
      {
        id: "rationale",
        label: "common.rationale",
        minWidth: 200,
        align: "left",
      },
    ],
    treeTableRows: [
      {
        name: "User Needs",
        id: "UND#100",
        user_types: "",
        priority: "",
        rationale: "",
        level: 1,
        isFolder: true,
        children: [
          {
            name: "The user needs a device that can measure temperature accurately and quickly.",
            id: "UND#001",
            user_types: "Patient|Healthcare|Provider|Caregiver",
            priority: "High",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs to able to accurately track the temperature of multiple patients when using the device",
            id: "UND#002",
            user_types: "Healthcare Provider|Caregiver",
            priority: "High",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs the device to read body temperature without pain or discomfort.",
            id: "UND#003",
            user_types: "Patient",
            priority: "Med",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs a device that is easy to use.",
            id: "UND#004",
            user_types: "Patient|Healthcare|Provider|Caregiver",
            priority: "Med",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs to use the device using only one hand, either the right or left had.",
            id: "UND#005",
            user_types: "Patient|Healthcare|Provider|Caregiver",
            priority: "High",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs a device that will indicate a fever without the user needing to read the numbers.",
            id: "UND#006",
            user_types: "Patient|Healthcare|Provider|Caregiver",
            priority: "High",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs a device that is easy to clean.",
            id: "UND#007",
            user_types: "Patient|Healthcare|Provider|Caregiver",
            priority: "High",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs the device to track readings over a period of 6 months",
            id: "UND#008",
            user_types: "Patient|Healthcare|Provider|Caregiver",
            priority: "High",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs the device to provide a method of taking readings on different patients without needing disinfection.",
            id: "UND#009",
            user_types: "Healthcare|Provider|Caregiver",
            priority: "Med",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs the device to accurately measure temperature on any part of the body.",
            id: "UND#010",
            user_types: "Patient|Healthcare|Provider|Caregiver",
            priority: "Med",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs the device to facilitate updating the patient's chart with temperature recorded.",
            id: "UND#011",
            user_types: "Healthcare|Provider",
            priority: "CTO",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs to be able to easily dispose of the product.",
            id: "UND#016",
            user_types: "Patient|Healthcare|Provider|Caregiver",
            priority: "High",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs to be able to make results available to a physician.",
            id: "UND#017",
            user_types: "Patient|Healthcare|Provider|Caregiver",
            priority: "Med",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs to easily store the product.",
            id: "UND#018",
            user_types: "Patient|Healthcare|Provider|Caregiver",
            priority: "High",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs to be notified when temperature measurements are outside the specified range",
            id: "UND#019",
            user_types: "Patient|Healthcare|Provider|Caregiver",
            priority: "Med",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs the device to last for two years without needing replacement of any parts.",
            id: "UND#012",
            user_types: "Business",
            priority: "Med",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs the device to units and temperatures that they are accustomed to.",
            id: "UND#013",
            user_types: "Business",
            priority: "Low",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs the device to meet regulatory requirements in the countries for which it is intended to be sold.",
            id: "UND#014",
            user_types: "Business",
            priority: "High",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            name: "The user needs the device to work on all patient populations.",
            id: "UND#015",
            user_types: "Business",
            priority: "High",
            rationale: "",
            level: 2,
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
        ],
      },
    ],
    workItemAnalysis: {
      title: "UND#001 The User needs a device that  can measure temperature accurately and quickly",
      feedsRows: [
        {
          id: "FD#123",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343458 Fail to physically set up pump",
          description: "Fault Tree Analysis should be modified according to SHA.",
        },
        {
          id: "FD#124",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343456 Fail to set up administration set",
          description: "SHA in SDDDB and SDDDC should be updated.",
        },
        {
          id: "FD#125",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#003 Process FMEA (V1.0)",
          scope2: "S#2312356 difficulty to lift pump",
          description: "Need create a system requirement",
        },
      ],
      details: {
        commentList: [
          {
            id: "1",
            title: "TSK#ABC-123 SHA1.0",
            replyContent: "",
            children: [
              {
                id: "11",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fred",
                type: "Comment",
                date: "09/29/2021",
                content: "Comment for a comment",
                re: "@TSK#ABC-123",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
          {
            id: "2",
            title: "CL3-RISK-45 Design for easy setup and self check the healthiness of pump during setup",
            replyContent: "",
            children: [
              {
                id: "21",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fubin",
                type: "Answer",
                date: "09/29/2021",
                content: "Reject!answer",
                re: "@CL3-RISK-45",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
        ],
      },
      relationships: {
        nodeDataArray: [
          {
            key: 0,
            text: "UND#001 The User needs a device that  can measure temperature accurately and quickly.",
            color: "lightgreen",
            loc: "111.26142374915396 172.3652374375991",
          },
          {
            key: 1,
            text: "SYS#001 The system shall measure temperature in the range of 34oC to 44oC",
            loc: "413.78427124746185 49.03500570143293",
          },
          {
            key: 2,
            text: "DS#002 The design shall provide a temperature range of 30oC to 50oC",
            loc: "716.3071187457698 49.03500570143294",
          },
          { key: 3, text: "569032 Electronic Assembly", loc: "1018.8299662440777 19.51750285071647" },
          { key: 4, text: "3Q#001 Material Qualification", loc: "1018.8299662440777 78.5525085521494" },
          {
            key: 5,
            text: "SYS#002 The system shall measure temperature with a accuracy of 5% when temperature is measured 3 inches +/- 1 inch from the forehead.",
            loc: "413.78427124746185 195.38006796451984",
          },
          {
            key: 6,
            text: "HW#012 The temperature sensor shall be able to measure temperature to a resolution of 0.01 oC.",
            loc: "716.3071187457698 149.35040691067837",
          },
          {
            key: 7,
            text: "SW#581 The software shall be able to display temperature in the range of 30oC to 50oC.",
            loc: "716.3071187457698 249.6658081199238",
          },
          { key: 8, text: "VAL#001 Self taking of temperature", loc: "413.78427124746185 303.9515482753278" },
        ],
        linkDataArray: [
          { key: 1, from: 0, to: 1 },
          { key: 2, from: 1, to: 2 },
          { key: 3, from: 2, to: 3 },
          { key: 4, from: 2, to: 4 },
          { key: 5, from: 0, to: 5 },
          { key: 6, from: 5, to: 6 },
          { key: 7, from: 5, to: 7 },
          { key: 8, from: 0, to: 8 },
        ],
      },
    },
    resources: {
      Deliverables: {
        excelTable: {
          columns: [
            {
              data: "id",
            },
            {
              data: "design_input_requirements",
            },
            {
              data: "priority",
            },
            {
              data: "requirement_criticality",
            },
            {
              data: "rationale",
            },
            {
              data: "und_links",
            },
            {
              data: "revision",
            },
            {
              data: "verifications",
            },
          ],
          colHeaders: [
            "ID",
            "Design Input Requirements",
            "Priority",
            "Requirement Criticality",
            "Rationale",
            "UND Links",
            "Revision",
            "Verifications",
          ],
          data: [
            {
              id: "SYS#001",
              design_input_requirements: "The system shall measure temperature in the range of 34oC to 44oC",
              priority: "Medium",
              requirement_criticality: "None",
              rationale: "The typical environmental temperature for the countries in which the device will be sold",
              und_links: "UND#001",
              revision: "V1",
              verifications: "",
            },
            {
              id: "SYS#002",
              design_input_requirements:
                "The system shall measure temperature with a accuracy of 5% when temperature is measured 3 inches +/- 1 inch from the forehead.",
              priority: "High",
              requirement_criticality: "CTQ",
              rationale: "Competitive Advantage",
              und_links: "UND#001 UND#009",
              revision: "V1",
              verifications: "",
            },
            {
              id: "SYS#003",
              design_input_requirements: "The system shall weigh no more than 6 ounces",
              priority: "",
              requirement_criticality: "",
              rationale: "",
              und_links: "UND#005",
              revision: "V1",
              verifications: "",
            },
            {
              id: "SYS#004",
              design_input_requirements: "The system shall have a footprint no larger than 3.0 X 2.0 X 6.0 inches",
              priority: "",
              requirement_criticality: "",
              rationale: "",
              und_links: "UND#005",
              revision: "V1",
              verifications: "",
            },
            {
              id: "SYS#005",
              design_input_requirements:
                "The system shall provide a method of recognizing patients based on a biometric.",
              priority: "",
              requirement_criticality: "",
              rationale: "",
              und_links: "UND#002",
              revision: "V1",
              verifications: "",
            },
          ],
        },
      },
    },
  },
  dir: {
    product: "Thermometers (Main R1.0)",
    task: "TK#102 Design Input Requirements V1.1",
    excelTableColHeaders: [
      "Req ID",
      "Design Input Requirements",
      "Priority",
      "Requirement Criticality",
      "Rationale",
      "UND Links",
      "RC Link",
      "Version",
      "Verification",
      "Requirement Type",
    ],
    excelTableColumns: [
      {
        data: "id",
      },
      {
        data: "design_input_requirements",
      },
      {
        data: "priority",
      },
      {
        data: "requirement_criticality",
      },
      {
        data: "rationale",
      },
      {
        data: "und_links",
      },
      {
        data: "rc_links",
      },
      {
        data: "version",
      },
      {
        data: "verification",
      },
      {
        data: "requirement_type",
      },
    ],
    excelTableData: [
      {
        id: "Req ID",
        design_input_requirements: "Design Input Requirements",
        priority: "Priority",
        requirement_criticality: "Requirement Criticality",
        rationale: "Rationale",
        und_links: "UND Links",
        rc_links: "RC Link",
        version: "Version",
        verification: "Verification",
        requirement_type: "Requirement Type",
      },
      {
        id: "SYS#001",
        design_input_requirements: "The system shall measure temperature in the range of 34oC to 44oC",
        priority: "Medium",
        requirement_criticality: "None",
        rationale: "The typical environmental temperature for the countries in which the device will be sold",
        und_links: "UND#001",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#002",
        design_input_requirements:
          "The system shall measure temperature with a accuracy of 5% when temperature is measured 3 inches +/- 1 inch from the forehead.",
        priority: "High",
        requirement_criticality: "CTQ",
        rationale: "Competitive Advantage",
        und_links: "UND#001\nUND#009",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#003",
        design_input_requirements: "The system shall weigh no more than 6 ounces",
        priority: "",
        requirement_criticality: "",
        rationale: "",
        und_links: "UND#005",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#004",
        design_input_requirements: "The system shall have a footprint no larger than 3.0 X 2.0 X 6.0 inches",
        priority: "",
        requirement_criticality: "",
        rationale: "",
        und_links: "UND#005",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#005",
        design_input_requirements: "The system shall provide a method of recognizing patients based on a biometric.",
        priority: "",
        requirement_criticality: "",
        rationale: "",
        und_links: "UND#002",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#006",
        design_input_requirements: "The system shall automatically assign readings taken based on the biometric used.",
        priority: "",
        requirement_criticality: "",
        rationale: "",
        und_links: "UND#002",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#007",
        design_input_requirements:
          "The system shall capture the biometric and read the temperature based on one user action.",
        priority: "",
        requirement_criticality: "",
        rationale: "",
        und_links: "UND#002\nUND#004\nUND#015",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#008",
        design_input_requirements:
          "The system shall be able to read the temperature without coming into contact with the user.",
        priority: "",
        requirement_criticality: "",
        rationale: "",
        und_links: "UND#003\nUND#008",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#009",
        design_input_requirements:
          "The system shall provide a visual indicator when the device reads above the fever threshold.",
        priority: "",
        requirement_criticality: "",
        rationale: "",
        und_links: "UND#004",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#010",
        design_input_requirements:
          "The system shall provide an audible indicator when the device reads above the fever threshold.",
        priority: "Medium",
        requirement_criticality: "CTS",
        rationale: "Implements a safety mitigation",
        und_links: "UND#004",
        rc_links: "FMEA#001\nFMEA#003\nFMEA#005",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#011",
        design_input_requirements: "The system shall withstand daily cleaning with alcohol wipes.",
        priority: "Medium",
        requirement_criticality: "CTS",
        rationale: "Implements a safety mitigation",
        und_links: "UND#006",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#012",
        design_input_requirements:
          "The system shall be able to maintain at least six months of data for at least 10 patients.",
        priority: "Medium",
        requirement_criticality: "Normal",
        rationale: "Allow tracking of fevers for at least two patients.",
        und_links: "UND#007",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#013",
        design_input_requirements: "The system shall be able to read the temperature on any part of the body.",
        priority: "High",
        requirement_criticality: "CTQ",
        rationale: "Competitive Advantage",
        und_links: "UND#009\nUND#015",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#014",
        design_input_requirements:
          "The system shall be able to transmit results in a format consistent with EHR protocols.",
        priority: "Medium",
        requirement_criticality: "Normal",
        rationale: "Allow updating of HER records.",
        und_links: "UND#010",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#015",
        design_input_requirements: "The system shall be able to transmit results to an application wirelessly.",
        priority: "Medium",
        requirement_criticality: "Normal",
        rationale: "Allow updating of HER records.",
        und_links: "UND#010",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#016",
        design_input_requirements:
          "The system shall function within specification for at least 36 months without needing replacement of any parts.",
        priority: "High",
        requirement_criticality: "KPI",
        rationale: "Competitive Advantage",
        und_links: "UND#011",
        rc_links: "HA#003",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#017",
        design_input_requirements: "The system shall be able to display temperature in oC.",
        priority: "Medium",
        requirement_criticality: "Normal",
        rationale: "To facilitate use in the various countries",
        und_links: "UND#013",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#018",
        design_input_requirements: "The system shall be able to display temperature in oF.",
        priority: "Medium",
        requirement_criticality: "Normal",
        rationale: "To facilitate use in the various countries",
        und_links: "UND#013",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#019",
        design_input_requirements:
          "The system user interface shall be designed such that no words requiring translation are utilized.",
        priority: "Low",
        requirement_criticality: "Normal",
        rationale: "Ease of Use",
        und_links: "UND#013",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#020",
        design_input_requirements: "The system shall comply with IEC6060101:2015.",
        priority: "High",
        requirement_criticality: "Normal",
        rationale: "Standards Compliance",
        und_links: "UND#014",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#021",
        design_input_requirements: "The system shall comply with ISO 15223:2021.",
        priority: "High",
        requirement_criticality: "Normal",
        rationale: "Standards Compliance",
        und_links: "UND#014",
        rc_links: "",
        version: "V1",
        verification: "",
        requirement_type: "",
      },
      {
        id: "SYS#022",
        design_input_requirements: "The system shall perform a check of the thermometer electronics at power up.",
        priority: "High",
        requirement_criticality: "Normal",
        rationale: "Safety",
        und_links: "N/A",
        rc_links: "HA#004",
        version: "",
        verification: "",
        requirement_type: "",
      },
    ],
    workItemAnalysis: {
      title: "SYS#001 The System shall measure temperature in the range of 34oC to 44oC",
      feedsRows: [
        {
          id: "FD#123",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343458 Fail to physically set up pump",
          description: "Fault Tree Analysis should be modified according to SHA.",
        },
        {
          id: "FD#124",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343456 Fail to set up administration set",
          description: "SHA in SDDDB and SDDDC should be updated.",
        },
        {
          id: "FD#125",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#003 Process FMEA (V1.0)",
          scope2: "S#2312356 difficulty to lift pump",
          description: "Need create a system requirement",
        },
      ],
      details: {
        commentList: [
          {
            id: "1",
            title: "TSK#ABC-123 SHA1.0",
            replyContent: "",
            children: [
              {
                id: "11",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fred",
                type: "Comment",
                date: "09/29/2021",
                content: "Comment for a comment",
                re: "@TSK#ABC-123",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
          {
            id: "2",
            title: "CL3-RISK-45 Design for easy setup and self check the healthiness of pump during setup",
            replyContent: "",
            children: [
              {
                id: "21",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fubin",
                type: "Answer",
                date: "09/29/2021",
                content: "Reject!answer",
                re: "@CL3-RISK-45",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
        ],
      },
      relationships: {
        nodeDataArray: [
          {
            key: 0,
            text: "UND#001 The User needs a device that  can measure temperature accurately and quickly.",
            loc: "86.26142374915396 58.03500570143294",
          },
          {
            key: 1,
            text: "SYS#001 The system shall measure temperature in the range of 34oC to 44oC",
            color: "lightgreen",
            loc: "388.7842712474618 58.03500570143294",
          },
          {
            key: 2,
            text: "DS#002 The design shall provide a temperature range of 30oC to 50oC",
            loc: "691.3071187457697 58.03500570143296",
          },
          { key: 3, text: "569032 Electronic Assembly", loc: "993.8299662440779 28.51750285071647" },
          { key: 4, text: "3Q#001 Material Qualification", loc: "993.8299662440779 87.55250855214945" },
        ],
        linkDataArray: [
          { key: 1, from: 0, to: 1 },
          { key: 2, from: 1, to: 2 },
          { key: 3, from: 2, to: 3 },
          { key: 4, from: 2, to: 4 },
        ],
      },
    },
    resources: {
      Deliverables: {
        excelTable: {
          columns: [
            {
              data: "name",
            },
            {
              data: "id",
            },
            {
              data: "user_types",
            },
            {
              data: "priority",
            },
            {
              data: "rationale",
            },
          ],
          colHeaders: ["Name", "ID", "User Types", "Priority", "Rationale", "UND Links"],
          data: [
            {
              id: "UND#001",
              name: "The user needs a device that can measure temperature accurately and quickly.",
              user_types: "Patient\nHealthcare\nProvider\nCaregiver",
              priority: "High",
              rationale: "",
            },
            {
              id: "UND#002",
              name: "The user needs to able to accurately track the temperature of multiple patients when using the device",
              user_types: "Healthcare Provider\nCaregiver",
              priority: "High",
              rationale: "",
            },
            {
              id: "UND#003",
              name: "The user needs the device to read body temperature without pain or discomfort.",
              user_types: "Patient",
              priority: "Med",
              rationale: "",
            },
            {
              id: "UND#004",
              name: "The user needs a device that is easy to use.",
              user_types: "Patient\nHealthcare\nProvider\nCaregiver",
              priority: "Med",
              rationale: "",
            },
            {
              id: "UND#005",
              name: "The user needs to use the device using only one hand, either the right or left had.",
              user_types: "Patient\nHealthcare\nProvider\nCaregiver",
              priority: "High",
              rationale: "",
            },
          ],
        },
      },
    },
  },
  tc: {
    product: "Thermometers (Main R1.0)",
    task: "TK#025 Test Cases V1.1",
    treeDataSet: [
      {
        id: "Design Verifications",
        value: "Design Verifications",
        items: [
          {
            id: "Test Cases",
            value: "Test Cases",
            items: [
              {
                id: "Fever Detection",
                value: "Fever Detection",
              },
              {
                id: "Cleaning",
                value: "Cleaning",
              },
            ],
          },
        ],
      },
    ],
    treeTableColumns: [
      {
        id: "id",
        label: "invoice.id",
        minWidth: 100,
        align: "left",
      },
      {
        id: "name",
        label: "common.test.case.title",
        minWidth: 200,
        align: "left",
      },
      {
        id: "steps",
        label: "common.test.description",
        minWidth: 200,
        align: "left",
      },
      {
        id: "criteria",
        label: "common.acceptance.criteria",
        minWidth: 200,
        align: "left",
      },
      {
        id: "equipment",
        label: "common.equipment.and.supplies.needed",
        minWidth: 200,
        align: "left",
      },
      {
        id: "tested",
        label: "common.requirement.tested",
        minWidth: 200,
        align: "left",
      },
      {
        id: "result",
        label: "common.result",
        minWidth: 200,
        align: "left",
      },
    ],
    treeTableRows: [
      {
        id: "TC#100",
        name: "Test Cases",
        steps: "",
        criteria: "",
        equipment: "",
        tested: "",
        result: "",
        isFolder: true,
        children: [
          {
            id: "TC#001",
            name: "Fever Detection",
            steps: [
              "1.  Set the fever threshold to 38oC",
              "2.  Set the temperature simulator to 38.5oC",
              "3.  Wait 20 seconds +/- 2 seconds",
              "4.  Record results",
            ],
            criteria:
              "Within 20 seconds of the temperature simulator being set to 38.5oC, the system activates a visual  and an audible indicator.",
            equipment: "Temperature Simulator",
            tested: "SYS#010",
            result: "",
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            id: "TC#002",
            name: "Cleaning",
            steps: [
              "1.  Visually inspect the thermometer to ensure that there is no visible damage",
              "2.  Wipe the entire surface of the thermometer with an alcohol wipe.",
              "3.  Wait 10 seconds and repeat X 25 times",
              "4.  Visually inspec the thermometer and record observations.",
              "5.  Test the thermometer as per Final Test Specification and Record Results.",
            ],
            criteria:
              "The thermometer will have no visible signs of physical damage and will operate within specification after exposure to worst case simulated cleaning.",
            equipment: "Alcohol wipes",
            tested: "SYS#011",
            result: "",
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
          {
            id: "TC#003",
            name: "Cleaning",
            steps: [
              "1.  Visually inspect the thermometer to ensure that there is no visible damage",
              "2.  Wipe the entire surface of the thermometer with an alcohol wipe.",
              "3.  Wait 10 seconds and repeat X 25 times",
              "4.  Visually inspec the thermometer and record observations.",
              "5.  Test the thermometer as per Final Test Specification and Record Results.",
            ],
            criteria:
              "The thermometer will have no visible signs of physical damage and will operate within specification after exposure to worst case simulated cleaning.",
            equipment: "Alcohol wipes",
            tested: "SYS#011",
            result: "",
            startIcon: (
              <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                <TaskItemIcon />
              </Box>
            ),
          },
        ],
      },
    ],
    workItemAnalysis: {
      title: "TC#001 Fever Detection",
      feedsRows: [
        {
          id: "FD#123",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343458 Fail to physically set up pump",
          description: "Fault Tree Analysis should be modified according to SHA.",
        },
        {
          id: "FD#124",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343456 Fail to set up administration set",
          description: "SHA in SDDDB and SDDDC should be updated.",
        },
        {
          id: "FD#125",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#003 Process FMEA (V1.0)",
          scope2: "S#2312356 difficulty to lift pump",
          description: "Need create a system requirement",
        },
      ],
      details: {
        commentList: [
          {
            id: "1",
            title: "TSK#ABC-123 SHA1.0",
            replyContent: "",
            children: [
              {
                id: "11",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fred",
                type: "Comment",
                date: "09/29/2021",
                content: "Comment for a comment",
                re: "@TSK#ABC-123",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
          {
            id: "2",
            title: "CL3-RISK-45 Design for easy setup and self check the healthiness of pump during setup",
            replyContent: "",
            children: [
              {
                id: "21",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fubin",
                type: "Answer",
                date: "09/29/2021",
                content: "Reject!answer",
                re: "@CL3-RISK-45",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
        ],
      },
      relationships: {
        nodeDataArray: [
          { key: 0, text: "TC#001 Fever Detection", color: "lightgreen", loc: "344.3071187457697 -15.714259844596029" },
          {
            key: 1,
            text: "SYS#010 The system shall provide an audible indicator when the device reads above the fever threshold.",
            loc: "41.78427124746179 -15.714259844596029",
          },
          {
            key: 2,
            text: "UND#004 The user needs a device that is easy to use.",
            loc: "-260.73857625084605 -15.714259844596022",
          },
        ],
        linkDataArray: [
          { key: 1, from: 1, to: 0 },
          { key: 2, from: 2, to: 1 },
        ],
      },
    },
    resources: {
      Deliverables: {
        excelTable: {
          columns: [
            {
              data: "id",
            },
            {
              data: "design_input_requirements",
            },
            {
              data: "priority",
            },
            {
              data: "requirement_criticality",
            },
            {
              data: "rationale",
            },
            {
              data: "und_links",
            },
            {
              data: "revision",
            },
            {
              data: "verifications",
            },
          ],
          colHeaders: [
            "ID",
            "Design Input Requirements",
            "Priority",
            "Requirement Criticality",
            "Rationale",
            "UND Links",
            "Revision",
            "Verifications",
          ],
          data: [
            {
              id: "SYS#001",
              design_input_requirements: "The system shall measure temperature in the range of 34oC to 44oC",
              priority: "Medium",
              requirement_criticality: "None",
              rationale: "The typical environmental temperature for the countries in which the device will be sold",
              und_links: "UND#001",
              revision: "V1",
              verifications: "",
            },
            {
              id: "SYS#002",
              design_input_requirements:
                "The system shall measure temperature with a accuracy of 5% when temperature is measured 3 inches +/- 1 inch from the forehead.",
              priority: "High",
              requirement_criticality: "CTQ",
              rationale: "Competitive Advantage",
              und_links: "UND#001 UND#009",
              revision: "V1",
              verifications: "",
            },
            {
              id: "SYS#003",
              design_input_requirements: "The system shall weigh no more than 6 ounces",
              priority: "",
              requirement_criticality: "",
              rationale: "",
              und_links: "UND#005",
              revision: "V1",
              verifications: "",
            },
            {
              id: "SYS#004",
              design_input_requirements: "The system shall have a footprint no larger than 3.0 X 2.0 X 6.0 inches",
              priority: "",
              requirement_criticality: "",
              rationale: "",
              und_links: "UND#005",
              revision: "V1",
              verifications: "",
            },
            {
              id: "SYS#005",
              design_input_requirements:
                "The system shall provide a method of recognizing patients based on a biometric.",
              priority: "",
              requirement_criticality: "",
              rationale: "",
              und_links: "UND#002",
              revision: "V1",
              verifications: "",
            },
          ],
        },
      },
    },
  },
  tm: {
    product: "Thermometers (Main R1.0)",
    task: "TK#023 Design Control Traceability Matrix V1.1",
    excelTableColHeaders: [
      "User Needs",
      "Design Input Requirements",
      "HW Subsystem Requirements",
      "SW Subsystem Requirements",
      "System Design Specification\n(manufacturing requirements)",
      "DMR Design OutPuts",
      "System Verifications",
      "SW Verifications",
      "HW Verifications",
      "Validations",
      "IQ/OQ/PO",
    ],
    excelTableColumns: [
      {
        data: "user_needs",
      },
      {
        data: "design_input_requirements",
      },
      {
        data: "hw_subsystem_requirements",
      },
      {
        data: "sw_subsystem_requirements",
      },
      {
        data: "design_specification",
      },
      {
        data: "dmr_design_outputs",
      },
      {
        data: "system_verifications",
      },
      {
        data: "sw_verifications",
      },
      {
        data: "hw_verifications",
      },
      {
        data: "verifications",
      },
      {
        data: "iq_oq_pq",
      },
    ],
    excelTableData: [
      {
        id: "User Needs",
        user_needs: "User Needs",
        design_input_requirements: "Design Input Requirements",
        hw_subsystem_requirements: "HW Subsystem Requirements",
        sw_subsystem_requirements: "SW Subsystem Requirements",
        design_specification: "System Design Specification\n(manufacturing requirements)",
        dmr_design_outputs: "DMR Design OutPuts",
        system_verifications: "System Verifications",
        sw_verifications: "SW Verifications",
        hw_verifications: "HW Verifications",
        verifications: "Validations",
        iq_oq_pq: "IQ/OQ/PO",
      },
      {
        id: "1",
        user_needs: "UND#001 The user needs a device that can measure temperature accurately and quickly.",
        design_input_requirements: "SYS#001 The system shall measure temperature in the range of 34oC to 44oC",
        hw_subsystem_requirements: "",
        sw_subsystem_requirements: "",
        design_specification: "DS#002 The design shall provide a temperature range of 30oC to 50oC.",
        dmr_design_outputs: "569032 Electronic Assembly",
        system_verifications: "",
        sw_verifications: "",
        hw_verifications: "",
        verifications: "VAL-1",
        iq_oq_pq: "Material Qualification",
      },
      {
        id: "2",
        user_needs: "",
        design_input_requirements:
          "SYS#002 The system shall measure temperature with a accuracy of 5% when temperature is measured 3 inches +/- 1 inch from the forehead.",
        hw_subsystem_requirements:
          "HW#012 The temperature sensor shall be able to measure temperature to a resolution of 0.01 oC.",
        sw_subsystem_requirements:
          "SW#581 The software shall be able to display temperature in the range of 30oC to 50oC.",
        design_specification: "",
        dmr_design_outputs: "",
        system_verifications: "",
        sw_verifications: "",
        hw_verifications: "",
        verifications: "",
        iq_oq_pq: "",
      },
      {
        id: "3",
        user_needs: "",
        design_input_requirements:
          "SYS#022 The system shall provide a visual indicator when the thermometer is within the required distance to make a measurement.",
        hw_subsystem_requirements:
          "HW#001 The hardware shall detect the distance of the human tissue using xxx technology",
        sw_subsystem_requirements:
          "SW#582 The software shall provide indication when the thermometer is within distance to make a measurement.",
        design_specification:
          "DS#003 The design shall provide a blinking LCD backlight when it senses human tissue within 5 inches.",
        dmr_design_outputs: "78321 LCD Display\n87000 Distance Sensor",
        system_verifications: "",
        sw_verifications: "",
        hw_verifications: "",
        verifications: "",
        iq_oq_pq: "Material Qualification",
      },
      {
        id: "4",
        user_needs: "UND#002 The user needs the device to last for two years without needing replacement of any parts",
        design_input_requirements: "SYS#012 The system shall have durability of at least two years",
        hw_subsystem_requirements: "",
        sw_subsystem_requirements: "",
        design_specification: "",
        dmr_design_outputs: "",
        system_verifications: "",
        sw_verifications: "",
        hw_verifications: "",
        verifications: "",
        iq_oq_pq: "",
      },
      {},
      {
        id: "5",
        user_needs:
          "UND#003 The user needs to able to accurately track the temperature of multiple patients when using the device",
        design_input_requirements:
          "SYS#006 The system shall automatically assign readings taken based on the biometric used.",
        hw_subsystem_requirements: "",
        sw_subsystem_requirements:
          "SW.620 The software shall store the following data for each temperature measurement\nRetina Scan data\nTemperature measurement\nDate\nTime",
        design_specification: "",
        dmr_design_outputs: "",
        system_verifications: "",
        sw_verifications: "",
        hw_verifications: "",
        verifications: "",
        iq_oq_pq: "",
      },
      {
        id: "6",
        user_needs: "UND#004 The user needs a device to read body temperature without pain or discomfort",
        design_input_requirements:
          "SYS#014 The system shall be able to read temperature without causing pain or discomfort",
        hw_subsystem_requirements: "",
        sw_subsystem_requirements: "",
        design_specification: "",
        dmr_design_outputs: "569032 Electronic Assembly",
        system_verifications: "",
        sw_verifications: "",
        hw_verifications: "",
        verifications: "VAL-1",
        iq_oq_pq: "IQ/OQ/PQ Assembly Test",
      },
      {
        id: "7",
        user_needs: "UND#005 The user needs a device that is easy to use",
        design_input_requirements: "SYS#013 The system shall be easy to use",
        hw_subsystem_requirements: "",
        sw_subsystem_requirements: "",
        design_specification: "",
        dmr_design_outputs: "",
        system_verifications: "",
        sw_verifications: "",
        hw_verifications: "",
        verifications: "VAL-1",
        iq_oq_pq: "",
      },
      {
        id: "8",
        user_needs:
          "UND#006 The user needs a device that will indicate fever without the user needing to read the numbers.",
        design_input_requirements:
          "SYS#009 If a temperature higher than 38oC is detected, the system shall light the red LED.",
        hw_subsystem_requirements: "",
        sw_subsystem_requirements:
          "SW#659 The software shall activate an audible alarm when the temperature measured is above the fever threshold.",
        design_specification: "",
        dmr_design_outputs: "78321 LCD Display",
        system_verifications: "",
        sw_verifications: "",
        hw_verifications: "",
        verifications: "",
        iq_oq_pq: "",
      },
      {
        id: "9",
        user_needs: "UND#007 The user needs a device that is easy to clean.",
        design_input_requirements: "SYS#005 The system shall withstand cleaning.",
        hw_subsystem_requirements: "",
        sw_subsystem_requirements: "",
        design_specification: "",
        dmr_design_outputs: "",
        system_verifications: "",
        sw_verifications: "",
        hw_verifications: "",
        verifications: "",
        iq_oq_pq: "",
      },
      {
        id: "10",
        user_needs: "UND#009 The user needs a device to track readings over a period of 6 months",
        design_input_requirements: "SYS.011 The system shall be able to store data for at least 6 months ",
        hw_subsystem_requirements: "",
        sw_subsystem_requirements: "",
        design_specification: "DS#005 The design shall provide memory storage capacity of 1 TB.",
        dmr_design_outputs: "",
        system_verifications: "",
        sw_verifications: "",
        hw_verifications: "",
        verifications: "",
        iq_oq_pq: "",
      },
      {
        id: "11",
        user_needs:
          "UND#010 The user needs the device to provide a method of taking readings on different patients without needing disinfection",
        design_input_requirements:
          "SYS#003 The system shall frequently be able to perform a retina scan and associate it with a patient.",
        hw_subsystem_requirements: "",
        sw_subsystem_requirements: "",
        design_specification: "",
        dmr_design_outputs: "",
        system_verifications: "",
        sw_verifications: "",
        hw_verifications: "",
        verifications: "",
        iq_oq_pq: "",
      },
      {
        id: "12",
        user_needs: "UND#011 The user needs the device to accurately measure temperature on any part of the body",
        design_input_requirements: "SYS#015 The system shall accurately measure temperature on any area of the body",
        hw_subsystem_requirements: "",
        sw_subsystem_requirements: "",
        design_specification: "",
        dmr_design_outputs: "78321 LCD Display",
        system_verifications: "",
        sw_verifications: "",
        hw_verifications: "",
        verifications: "VAL-1",
        iq_oq_pq: "",
      },
      {
        id: "13",
        user_needs:
          "UND#012 The user needs the device to facilitate updating the patient's chart with temperature recorded",
        design_input_requirements:
          "SYS#010 The system shall transfer readings in a format that is understandable by EHS systems so that patient records can be updated with the readings obtained.",
        hw_subsystem_requirements: "",
        sw_subsystem_requirements: "",
        design_specification: "",
        dmr_design_outputs: "",
        system_verifications: "",
        sw_verifications: "",
        hw_verifications: "",
        verifications: "",
        iq_oq_pq: "",
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
    ],
    workItemAnalysis: {
      title: "SYS#001 The System shall measure temperature in the range of 34oC to 44oC",
      feedsRows: [
        {
          id: "FD#123",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343458 Fail to physically set up pump",
          description: "Fault Tree Analysis should be modified according to SHA.",
        },
        {
          id: "FD#124",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#002 Design FMEA (V1.0)",
          scope2: "S#2343456 Fail to set up administration set",
          description: "SHA in SDDDB and SDDDC should be updated.",
        },
        {
          id: "FD#125",
          category: "Requirement Impact Analysis",
          source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
          source2: "S#2345343 Incorrectly controlled delivery",
          scope1: "DDD-TK#003 Process FMEA (V1.0)",
          scope2: "S#2312356 difficulty to lift pump",
          description: "Need create a system requirement",
        },
      ],
      details: {
        commentList: [
          {
            id: "1",
            title: "TSK#ABC-123 SHA1.0",
            replyContent: "",
            children: [
              {
                id: "11",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fred",
                type: "Comment",
                date: "09/29/2021",
                content: "Comment for a comment",
                re: "@TSK#ABC-123",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
          {
            id: "2",
            title: "CL3-RISK-45 Design for easy setup and self check the healthiness of pump during setup",
            replyContent: "",
            children: [
              {
                id: "21",
                avatar:
                  "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
                name: "Fubin",
                type: "Answer",
                date: "09/29/2021",
                content: "Reject!answer",
                re: "@CL3-RISK-45",
                count: 0,
                isAgree: false,
                mute: false,
                showMute: true,
                canDelete: true,
              },
            ],
          },
        ],
      },
      relationships: {
        nodeDataArray: [
          {
            key: 0,
            text: "UND#001 The User needs a device that  can measure temperature accurately and quickly.",
            loc: "-298.73857625084605 -5.964994298567078",
          },
          {
            key: 1,
            text: "SYS#001 The system shall measure temperature in the range of 34oC to 44oC",
            color: "lightgreen",
            loc: "3.784271247461902 -5.964994298567078",
          },
          {
            key: 2,
            text: "DS#002 The design shall provide a temperature range of 30oC to 50oC",
            loc: "306.30711874576946 -5.964994298567049",
          },
          { key: 3, text: "569032 Electronic Assembly", loc: "608.8299662440768 -35.48249714928353" },
          { key: 4, text: "3Q#001 Material Qualification", loc: "608.8299662440768 23.552508552149476" },
        ],
        linkDataArray: [
          { key: 1, from: 0, to: 1 },
          { key: 2, from: 1, to: 2 },
          { key: 3, from: 2, to: 3 },
          { key: 4, from: 2, to: 4 },
        ],
      },
    },
    resources: {
      Deliverables: {
        excelTable: {
          columns: [
            {
              data: "name",
            },
            {
              data: "id",
            },
            {
              data: "user_types",
            },
            {
              data: "priority",
            },
            {
              data: "rationale",
            },
          ],
          colHeaders: ["Name", "ID", "User Types", "Priority", "Rationale", "UND Links"],
          data: [
            {
              id: "UND#001",
              name: "The user needs a device that can measure temperature accurately and quickly.",
              user_types: "Patient\nHealthcare\nProvider\nCaregiver",
              priority: "High",
              rationale: "",
            },
            {
              id: "UND#002",
              name: "The user needs to able to accurately track the temperature of multiple patients when using the device",
              user_types: "Healthcare Provider\nCaregiver",
              priority: "High",
              rationale: "",
            },
            {
              id: "UND#003",
              name: "The user needs the device to read body temperature without pain or discomfort.",
              user_types: "Patient",
              priority: "Med",
              rationale: "",
            },
            {
              id: "UND#004",
              name: "The user needs a device that is easy to use.",
              user_types: "Patient\nHealthcare\nProvider\nCaregiver",
              priority: "Med",
              rationale: "",
            },
            {
              id: "UND#005",
              name: "The user needs to use the device using only one hand, either the right or left had.",
              user_types: "Patient\nHealthcare\nProvider\nCaregiver",
              priority: "High",
              rationale: "",
            },
          ],
        },
      },
    },
  },
};
