import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import SimplePopup from "components/SimplePopup";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

const perspectivesMockData = {
  sha: {
    checkboxList: [
      {
        id: "System Hazard Analysis - 1",
        key: "risk_heat_map",
        labelId: "common.risk.heat.map",
        defaultValue: true,
      },
      {
        id: "System Hazard Analysis - 2",
        key: "cycle_time",
        labelId: "common.cycle.time",
        defaultValue: true,
      },
    ],
  },
  und: {
    checkboxList: [
      {
        id: "User Needs - 1",
        key: "risk_heat_map",
        labelId: "common.risk.heat.map",
        defaultValue: false,
      },
      {
        id: "User Needs - 2",
        key: "cycle_time",
        labelId: "common.cycle.time",
        defaultValue: true,
      },
      {
        id: "User Needs - 3",
        key: "pie_chart",
        labelId: "sidebar.recharts.pieChart",
        defaultValue: true,
      },
      {
        id: "User Needs - 4",
        key: "bar_chart",
        labelId: "sidebar.recharts.barChart",
        defaultValue: true,
      },
    ],
  },
  dir: {
    checkboxList: [
      {
        id: "Design Input Requirements - 1",
        key: "risk_heat_map",
        labelId: "common.risk.heat.map",
        defaultValue: false,
      },
      {
        id: "Design Input Requirements - 2",
        key: "cycle_time",
        labelId: "common.cycle.time",
        defaultValue: true,
      },
      {
        id: "Design Input Requirements - 3",
        key: "pie_chart",
        labelId: "sidebar.recharts.pieChart",
        defaultValue: true,
      },
      {
        id: "Design Input Requirements - 4",
        key: "bar_chart",
        labelId: "sidebar.recharts.barChart",
        defaultValue: true,
      },
    ],
  },
  tc: {
    checkboxList: [
      {
        id: "Test Cases - 1",
        key: "risk_heat_map",
        labelId: "common.risk.heat.map",
        defaultValue: false,
      },
      {
        id: "Test Cases - 2",
        key: "cycle_time",
        labelId: "common.cycle.time",
        defaultValue: true,
      },
      {
        id: "Test Cases - 3",
        key: "pie_chart",
        labelId: "sidebar.recharts.pieChart",
        defaultValue: true,
      },
      {
        id: "Test Cases - 4",
        key: "bar_chart",
        labelId: "sidebar.recharts.barChart",
        defaultValue: true,
      },
    ],
  },
  tm: {
    checkboxList: [
      {
        id: "Design Control Traceability Matrix - 1",
        key: "risk_heat_map",
        labelId: "common.risk.heat.map",
        defaultValue: false,
      },
      {
        id: "Design Control Traceability Matrix - 2",
        key: "cycle_time",
        labelId: "common.cycle.time",
        defaultValue: true,
      },
      {
        id: "Design Control Traceability Matrix - 3",
        key: "pie_chart",
        labelId: "sidebar.recharts.pieChart",
        defaultValue: true,
      },
      {
        id: "Design Control Traceability Matrix - 4",
        key: "bar_chart",
        labelId: "sidebar.recharts.barChart",
        defaultValue: true,
      },
    ],
  },
};

interface MetricsSettingModalProps {
  handleClose: () => void;
  open: boolean;
  type: string;
}

const MetricsSettingModal: React.FC<MetricsSettingModalProps> = ({ open, handleClose, type }) => {
  const { messages } = useIntl();

  const [state, setState] = useState({});

  const [checkboxList, setCheckboxList] = useState([]);

  useEffect(() => {
    if (type) {
      const mockData = perspectivesMockData[type];
      if (mockData) {
        setCheckboxList(mockData.checkboxList);
        let defaultState = {};
        if (mockData.checkboxList) {
          mockData.checkboxList.forEach((x) => {
            defaultState[x.key] = x.defaultValue;
          });
        }
        setState(defaultState);
      }
    }
  }, [type]);

  const save = () => {
    handleClose && handleClose();
  };

  return (
    <SimplePopup
      handleClose={handleClose}
      open={open}
      titleId="common.setting"
      okTextId="common.ok"
      save={save}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "800px",
          width: "800px",
        },
        "& .MuiDialogContent-root": {
          padding: "20px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box sx={{ color: "rgba(0,0,0,0.85)", fontWeight: "bold" }}>
          <IntlMessages id="common.select.applicable.metrics" />
        </Box>
        <Box>
          <FormGroup sx={{ mt: 3 }}>
            {checkboxList.map((x) => {
              return (
                <FormControlLabel
                  key={x.id}
                  control={
                    <Checkbox
                      name={x.key}
                      checked={state[x.key] || false}
                      onChange={() => {
                        setState({
                          ...state,
                          [x.key]: !state[x.key],
                        });
                      }}
                    />
                  }
                  label={String(messages[x.labelId])}
                />
              );
            })}
          </FormGroup>
        </Box>
      </Box>
    </SimplePopup>
  );
};

export default MetricsSettingModal;
