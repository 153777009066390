import { Dialog, DialogContent, DialogTitle, ModalProps } from "@mui/material";
import {
  render as reactRender,
  unmount as reactUnmount,
} from "rc-util/lib/React/render";
import React from "react";

import destroyFns from "./destroyFns";

function ModalWrapper(
  title: React.ReactNode,
  content: React.ReactNode,
  props?: ModalProps
) {
  const container = document.createDocumentFragment();

  function destroy() {
    for (let i = 0; i < destroyFns.length; i++) {
      const fn = destroyFns[i];
      if (fn === close) {
        destroyFns.splice(i, 1);
        break;
      }
    }

    reactUnmount(container);
  }
  // const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    destroy();
  };

  function render() {
    setTimeout(() => {
      reactRender(
        <Dialog onClose={handleClose} open={true} sx={{}} {...props}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent dividers>{content}</DialogContent>
        </Dialog>,
        container
      );
    });
  }

  render();
  destroyFns.push(destroy);

  return {
    destroy: destroy,
  };
}
export default ModalWrapper;
