import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import React from "react";
import { textColor } from "shared/constants/AppConst";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1890FF",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1890FF",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const CycleTimeContainer = styled(Box)(({ theme }) => ({
  color: textColor[85],
  ".label": {
    fontWeight: "bold",
    minWidth: "130px",
  },
  ".value": {
    width: "40%",
  },
}));

export default function CycleTime() {
  const [estimate, setEstimate] = React.useState<string>("6 days");
  const [steps, setSteps] = React.useState<any>([
    {
      id: "1",
      label: "Draft",
      operator: "James",
      operationTime: "2017-10-01 12:00",
    },
    {
      id: "3",
      label: "Review & Comment",
    },
    {
      id: "4",
      label: "Comment Resolution",
    },
    {
      id: "5",
      label: "Ready for Approval Route",
    },
    {
      id: "6",
      label: "Route Approval",
    },
  ]);

  return (
    <CycleTimeContainer>
      <Box
        sx={{
          display: "flex",
          mb: 6,
        }}
      >
        <Box className="label">
          <IntlMessages id="common.original.estimate" />:
        </Box>
        <Box className="value">{estimate}</Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Box className="label">
          <IntlMessages id="common.time.tracking" />:
        </Box>
        <Box className="value">
          <BorderLinearProgress variant="determinate" value={50} />
          <Box>1 day logged</Box>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 6,
        }}
      >
        <Stepper activeStep={1} alternativeLabel connector={<QontoConnector />}>
          {steps.map((item) => (
            <Step key={item.id}>
              <StepLabel>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "rgba(0,0,0,0.85)",
                    fontSize: "14px",
                  }}
                >
                  <Box sx={{ fontWeight: "bold" }}>{item.label}</Box>
                  <Box>{item?.operator}</Box>
                  <Box>{item?.operationTime}</Box>
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </CycleTimeContainer>
  );
}
