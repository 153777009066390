import $ from "components/Gojs";
import * as go from "gojs";
import { ReactDiagram } from "gojs-react";
import React, { useMemo, useRef } from "react";
import { LinkData, NodeData } from "types/models/GoJs";

interface DiagramProps {
  nodeData: NodeData[];
  linkData: LinkData[];
}

let diagram;
const KAPPA = 4 * ((Math.sqrt(2) - 1) / 3);

const Diagram = (props: DiagramProps) => {
  const { nodeData, linkData } = props;

  const diagramRef = useRef(null);

  const handleDiagramChange = (diagram) => {
    const a = diagram.model.toJson();
    console.log(a);
  };

  const initDiagram = () => {
    diagram = $(go.Diagram, {
      padding: 20,
      "undoManager.isEnabled": false, // must be set to allow for model change listening, now use false,
      // $(go.TreeLayout, { layerSpacing: 150 }),
      allowTextEdit: false,
      allowDrop: false,
      // allowMove: false,
      // layout: $(go.LayeredDigraphLayout, { setsPortSpots: false }),
    });

    go.Shape.defineFigureGenerator("Database", function (shape, w, h) {
      var geo = new go.Geometry();
      var cpxOffset = KAPPA * 0.5;
      var cpyOffset = KAPPA * 0.1;
      var fig = new go.PathFigure(w, 0.1 * h, true);
      geo.add(fig);

      // Body
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.9 * h));
      fig.add(
        new go.PathSegment(go.PathSegment.Bezier, 0.5 * w, h, w, (0.9 + cpyOffset) * h, (0.5 + cpxOffset) * w, h)
      );
      fig.add(
        new go.PathSegment(go.PathSegment.Bezier, 0, 0.9 * h, (0.5 - cpxOffset) * w, h, 0, (0.9 + cpyOffset) * h)
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.1 * h));
      fig.add(
        new go.PathSegment(go.PathSegment.Bezier, 0.5 * w, 0, 0, (0.1 - cpyOffset) * h, (0.5 - cpxOffset) * w, 0)
      );
      fig.add(
        new go.PathSegment(go.PathSegment.Bezier, w, 0.1 * h, (0.5 + cpxOffset) * w, 0, w, (0.1 - cpyOffset) * h)
      );
      var fig2 = new go.PathFigure(w, 0.1 * h, false);
      geo.add(fig2);
      // Rings
      fig2.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          0.2 * h,
          w,
          (0.1 + cpyOffset) * h,
          (0.5 + cpxOffset) * w,
          0.2 * h
        )
      );
      fig2.add(
        new go.PathSegment(go.PathSegment.Bezier, 0, 0.1 * h, (0.5 - cpxOffset) * w, 0.2 * h, 0, (0.1 + cpyOffset) * h)
      );
      fig2.add(new go.PathSegment(go.PathSegment.Move, w, 0.2 * h));
      fig2.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          0.3 * h,
          w,
          (0.2 + cpyOffset) * h,
          (0.5 + cpxOffset) * w,
          0.3 * h
        )
      );
      fig2.add(
        new go.PathSegment(go.PathSegment.Bezier, 0, 0.2 * h, (0.5 - cpxOffset) * w, 0.3 * h, 0, (0.2 + cpyOffset) * h)
      );
      fig2.add(new go.PathSegment(go.PathSegment.Move, w, 0.3 * h));
      fig2.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          0.4 * h,
          w,
          (0.3 + cpyOffset) * h,
          (0.5 + cpxOffset) * w,
          0.4 * h
        )
      );
      fig2.add(
        new go.PathSegment(go.PathSegment.Bezier, 0, 0.3 * h, (0.5 - cpxOffset) * w, 0.4 * h, 0, (0.3 + cpyOffset) * h)
      );
      geo.spot1 = new go.Spot(0, 0.4);
      geo.spot2 = new go.Spot(1, 0.9);
      return geo;
    });

    let graphLinksModel = new go.GraphLinksModel();
    graphLinksModel.linkKeyProperty = "key";
    diagram.model = graphLinksModel;

    diagram.nodeTemplate = $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
      },
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Shape,
        {
          name: "SHAPE",
          fill: "white",
          portId: "",
          fromSpot: go.Spot.AllSides,
          toSpot: go.Spot.AllSides,
        },
        new go.Binding("fill", "color"),
        new go.Binding("figure", "figure"),
        new go.Binding("width", "width"),
        new go.Binding("height", "height"),
        new go.Binding("stroke", "stroke"),
        new go.Binding("fontSize", "fontSize")
      ),
      $(
        go.TextBlock,
        { margin: 8, editable: true, stroke: "#000" },
        new go.Binding("text").makeTwoWay(),
        new go.Binding("stroke", "textColor")
      )
    );

    diagram.groupTemplate = $(
      go.Group,
      "Spot",
      {
        layerName: "Background",
        ungroupable: true,
        locationSpot: go.Spot.Center,
        selectionObjectName: "BODY",
        computesBoundsAfterDrag: true, // allow dragging out of a Group that uses a Placeholder
        handlesDragDropForMembers: true, // don't need to define handlers on Nodes and Links
        avoidable: false,
        // layout: $(go.LayeredDigraphLayout),
      },
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Panel,
        "Auto",
        { name: "BODY" },
        $(
          go.Shape,
          {
            parameter1: 10,
            fill: "white",
            strokeWidth: 2,
            stroke: "transparent",
            portId: "",
            cursor: "pointer",
            fromLinkable: true,
            toLinkable: true,
            fromLinkableDuplicates: true,
            toLinkableDuplicates: true,
            fromSpot: go.Spot.AllSides,
            toSpot: go.Spot.AllSides,
          },
          new go.Binding("fill"),
          new go.Binding("stroke", "color"),
          new go.Binding("strokeWidth", "thickness"),
          new go.Binding("strokeDashArray", "dash")
        ),
        $(go.Placeholder, { background: "transparent", margin: 10 })
      ),
      $(
        go.TextBlock,
        {
          alignment: go.Spot.Top,
          alignmentFocus: go.Spot.Bottom,
          font: "bold 12pt sans-serif",
          editable: true,
        },
        new go.Binding("text"),
        new go.Binding("stroke", "color")
      )
    );

    // go.Shape.defineArrowheadGeometry(
    //   "Database",
    //   "F1 m 0,0 l 8,4 -8,4 2,-4 z"
    // );
    diagram.linkTemplate = $(
      go.Link,
      {
        routing: go.Link.AvoidsNodes,
        // corner: 10,
      },
      new go.Binding("routing", "routing"),
      new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
      new go.Binding("toSpot", "toSpot", go.Spot.parse),
      $(
        go.Shape,
        { strokeWidth: 2, stroke: "#000" },
        new go.Binding("stroke", "color"),
        new go.Binding("strokeDashArray", "dash"),
        new go.Binding("strokeWidth", "thickness")
      ),
      $(
        go.Shape,
        { fromArrow: "Backward", visible: false },
        new go.Binding("fromArrow", "fromArrow"),
        new go.Binding("visible", "dir", (dir) => dir === 2),
        new go.Binding("fill", "arrowColor"),
        new go.Binding("scale", "scale", (t) => (2 + t) / 3)
      ),
      $(
        go.Shape,
        { toArrow: "Standard" },
        new go.Binding("toArrow", "toArrow"),
        new go.Binding("visible", "dir", (dir) => dir >= 1),
        new go.Binding("fill", "arrowColor"),
        new go.Binding("scale", "scale", (t) => (2 + t) / 3)
      ),
      $(
        go.Panel,
        "Auto",
        $(
          go.Shape, // the label background, which becomes transparent around the edges
          {
            // fill: "rgba(255, 255, 255, 1)",
            fill: "rgba(255, 255, 255, 0)",
            stroke: null,
          },
          // can use else background color for line text.
          new go.Binding("fill", "textBgColor")
        ),
        $(
          go.TextBlock,
          { alignmentFocus: new go.Spot(0, 1, -4, 0), editable: false },
          new go.Binding("text").makeTwoWay(), // TwoWay due to user editing with TextEditingTool
          new go.Binding("stroke", "textColor")
        )
      )
    );

    diagram.addModelChangedListener((e) => {
      console.log(e);
      handleDiagramChange(diagram);
    });
    return diagram;
  };

  return (
    <ReactDiagram
      ref={diagramRef}
      initDiagram={initDiagram}
      divClassName="diagram-box"
      nodeDataArray={nodeData}
      linkDataArray={linkData}
    />
  );
};

export default Diagram;
