import Icon from "@mui/material/Icon";
import * as React from "react";

const WordSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M8.576 12.516c0 .417-.114.738-.344.964-.23.226-.552.338-.968.338h-.809V11.32h.865c.401 0 .71.102.929.307.219.205.327.501.327.889zm-.7.024c0-.23-.046-.399-.136-.51-.09-.11-.229-.165-.413-.165h-.196v1.399h.15c.205 0 .356-.06.451-.179.096-.119.144-.3.144-.545zM11.362 12.565c0 .42-.103.74-.31.96-.206.218-.508.327-.905.327-.392 0-.693-.11-.902-.33-.209-.22-.313-.54-.313-.96 0-.416.104-.734.311-.953.208-.22.51-.329.907-.329.398 0 .7.11.904.327.206.217.308.537.308.958zm-1.72 0c0 .484.169.725.505.725.17 0 .298-.059.38-.176.083-.118.124-.3.124-.55 0-.248-.042-.433-.125-.552-.084-.119-.21-.179-.376-.179-.338.001-.507.245-.507.732zM12.926 11.838a.43.43 0 0 0-.376.197c-.09.131-.135.313-.135.544 0 .481.182.721.548.721.11 0 .218-.015.322-.046.103-.03.207-.068.312-.111v.57a1.727 1.727 0 0 1-.708.14c-.377 0-.666-.11-.867-.329-.201-.219-.302-.535-.302-.948 0-.259.05-.486.146-.682.098-.196.238-.347.421-.452.183-.106.398-.158.645-.158.27 0 .528.058.774.176l-.206.531a2.379 2.379 0 0 0-.277-.11.97.97 0 0 0-.297-.043z"
        fill="rgba(0,0,0,0.85)"
        fillRule="nonzero"
      />
      <path stroke="rgba(0,0,0,0.85)" d="M11.59 3v3.818h3.82" />
      <path
        stroke="rgba(0,0,0,0.85)"
        strokeLinecap="square"
        d="M15.41 9.682V6.818L11.59 3h-7v6.682M4.59 15.41V17h10.82v-1.59M3 9.682h14v5.727H3z"
      />
    </g>
  </svg>
);

const WordIcon: React.FunctionComponent = () => {
  return <Icon component={WordSvg} />;
};

export default WordIcon;
