import { Box } from "@mui/material";
import { ListModel } from "components/CustomTable/CustomTableModel";
import TableToolBar from "components/CustomTable/TableToolBar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleActivitiesType } from "redux/actions";
import { AppState } from "redux/store";

import ActivityList from "./ActivityList";

export default function Activities() {
  const { moduleList } = useSelector<AppState, AppState["workspaces"]>(({ workspaces }) => workspaces);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(handleActivitiesType(0));
    };
  }, [dispatch]);

  console.log(moduleList);
  const [searchText, setSearchText] = React.useState("");
  const [filter1, setFilter1] = React.useState<any>(1);
  const [filterList1] = React.useState<ListModel[]>([
    {
      id: 1,
      name: "My Activities",
    },
    {
      id: 2,
      name: "All Activities",
    },
  ]);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
  };

  return (
    <Box>
      {
        <TableToolBar
          showSearch={true}
          searchText={searchText}
          requestSearch={requestSearch}
          saveFilter={() => {
            console.log("save filter");
          }}
          filters={[
            {
              value: filter1,
              list: filterList1,
              setValue: setFilter1,
              disabledNone: true,
            },
          ]}
        />
      }
      <ActivityList />
    </Box>
  );
}
