import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import MyTreeGrid from "./MyTreeGrid";

const TreeTaleViewContainer = styled(Box)(({ theme }) => ({
  padding: "8px 0",
  minWidth: "1200px",
  ".details-content": {
    marginTop: "12px",
  },
}));

const TreeTaleView = () => {
  return (
    <TreeTaleViewContainer>
      <Box className="details-content">
        <Box>
          <MyTreeGrid />
        </Box>
      </Box>
    </TreeTaleViewContainer>
  );
};

export default TreeTaleView;
