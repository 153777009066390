import IntlMessages from "@crema/utility/IntlMessages";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import Diagram from "../components/Diagram";
import PreviewModal from "../components/PreviewModal";
import SettingModal from "../components/SettingModal";

const Format = () => {
  const { messages } = useIntl();
  const [showSettingModal, setShowSettingModal] = React.useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = React.useState<boolean>(false);
  const [format, setFormat] = React.useState<string>("1");

  return (
    <Box>
      <Box
        sx={{
          mb: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ color: "rgba(0,0,0,0.85)", fontWeight: "bold" }}>
            <IntlMessages id="common.select.format" />
          </Box>
          <Box>
            <FormControl sx={{ ml: 2 }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="1"
                value={format}
                onChange={(e) => {
                  setFormat(e.target.value);
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label={<IntlMessages id="common.excel.table" />} />
                <FormControlLabel value="2" control={<Radio />} label={<IntlMessages id="common.tree.table" />} />
                <FormControlLabel value="3" control={<Radio />} label={<IntlMessages id="common.assurance.case" />} />
                <FormControlLabel value="4" control={<Radio />} label={<IntlMessages id="common.fault.tree" />} />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Button
            onClick={() => {
              setShowSettingModal(true);
            }}
            sx={{
              color: "rgba(0,0,0,0.85)",
              border: "solid 1px rgba(0, 0, 0, 0.12)",
              fontWeight: "normal",
              mr: 2,
            }}
            variant="outlined"
          >
            <IntlMessages id="common.show.settings.table" />
          </Button>
          <Button
            sx={{
              color: "rgba(0,0,0,0.85)",
              border: "solid 1px rgba(0, 0, 0, 0.12)",
              fontWeight: "normal",
            }}
            variant="outlined"
            onClick={() => {
              setShowPreviewModal(true);
            }}
          >
            <IntlMessages id="common.preview" />
          </Button>
        </Box>
        <Box>
          <Button
            sx={{
              color: "rgba(0,0,0,0.85)",
              border: "solid 1px rgba(0, 0, 0, 0.12)",
              fontWeight: "normal",
              mr: 2,
            }}
            startIcon={<AddCircleIcon />}
            variant="outlined"
          >
            <IntlMessages id="common.column" />
          </Button>
          <Button
            sx={{
              color: "rgba(0,0,0,0.85)",
              border: "solid 1px rgba(0, 0, 0, 0.12)",
              fontWeight: "normal",
            }}
            variant="outlined"
            onClick={() => {}}
          >
            <IntlMessages id="common.legends" />
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: "100%", height: "500px", mt: 2 }}>
        <Diagram />
      </Box>
      {showSettingModal && (
        <SettingModal
          open={showSettingModal}
          handleClose={() => {
            setShowSettingModal(false);
          }}
        />
      )}
      {showPreviewModal && (
        <PreviewModal
          open={showPreviewModal}
          handleClose={() => {
            setShowPreviewModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default Format;
