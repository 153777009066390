import { Box, Button } from "@mui/material";
import * as Dockable from "components/Dockable";
import DrawerHelper from "libs/Drawer/DrawerHelper";
import React from "react";

import HandsontablePage from "../HandsontablePage";

export interface Page2Props {
  dockableRef?: Dockable.RefState<Dockable.State>;
}
const Page2: React.FC<Page2Props> = (props) => {
  const { dockableRef } = props;
  return (
    <>
      <h2>Sample page 2</h2>
      <Box sx={{ my: 2 }}>You can kick start your app</Box>
      <Button
        variant="text"
        onClick={() =>
          DrawerHelper.show(
            "static Drawer",
            <Page2 dockableRef={dockableRef} />,
            { size: "xl", drawerProps: { hideBackdrop: true } },
            dockableRef
          )
        }
      >
        DrawerHelper.show Page2
      </Button>
      <Button
        variant="text"
        className=" h-xs"
        onClick={() => DrawerHelper.show("static Drawer", <HandsontablePage />, { size: "xl" })}
      >
        DrawerHelper.show HandsontablePage
      </Button>
    </>
  );
};

export default Page2;
