import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { textColor } from "shared/constants/AppConst";

const heatMapType = [
  {
    color: "#ff8f76",
    name: "High Risk",
  },
  {
    color: "#fec976",
    name: "Middle Risk",
  },
  {
    color: "#72bcff",
    name: "Low Risk",
  },
  {
    color: "#e6e6e6",
    name: "TBD to be defined",
  },
];

const HeatMapContainer = styled(Box)(({ theme }) => ({
  ".my_table_container": {
    width: "100%",
  },
  ".table_header_cell, .table_header": {
    background: "#F0F0F0",
    color: "rgba(0,0,0,0.85)",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
    position: "relative",
  },
  ".table_body_cell": {
    color: "rgba(0,0,0,0.85)",
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell:first-of-type": {
    borderLeft: "1px solid  rgba(0, 0, 0, 0.09)",
    background: "#f5f6f7",
    fontWeight: "bold",
  },
  ".table_header_cell:first-of-type": {
    background: "#F0F0F0",
  },
  "thead tr:first-of-type .table_header_cell": {
    borderTop: "1px solid  rgba(0, 0, 0, 0.09)",
  },
  ".table_cell": {
    padding: "6px",
    minWidth: "170px",
    borderRight: "1px solid  rgba(0, 0, 0, 0.09)",
    borderBottom: "1px solid  rgba(0, 0, 0, 0.09)",
  },
  ".cursor-pointer": {
    cursor: "pointer",
  },
  ".margin-right": {
    marginRight: "8px",
  },
}));

const HeatMap = () => {
  const [columns, setColumns] = React.useState<any[]>([
    {
      id: "attributes",
      label: "Ph\\Sev",
    },
    {
      id: "tbd",
      label: "TBD",
    },
    {
      id: "negligible",
      label: "Negligible:1",
    },
    {
      id: "minor",
      label: "Minor:2",
    },
    {
      id: "serious",
      label: "Serious:3",
    },
    {
      id: "cirtical",
      label: "Cirtical:4",
    },
    {
      id: "catastrophic",
      label: "Catastrophic:5",
    },
  ]);
  const [rows, setRows] = React.useState<any[]>([
    {
      id: 1,
      attributes: "TBD",
      tbd: {
        value: 0,
        level: 0,
      },
      negligible: {
        value: 0,
        level: 0,
      },
      minor: {
        value: 0,
        level: 0,
      },
      serious: {
        value: 46,
        level: 0,
      },
      cirtical: {
        value: 0,
        level: 0,
      },
      catastrophic: {
        value: 0,
        level: 0,
      },
    },
    {
      id: 2,
      attributes: "Improbable:1-2",
      tbd: {
        value: 0,
        level: 0,
      },
      negligible: {
        value: 0,
        level: 1,
      },
      minor: {
        value: 0,
        level: 1,
      },
      serious: {
        value: 0,
        level: 1,
      },
      cirtical: {
        value: 0,
        level: 1,
      },
      catastrophic: {
        value: 0,
        level: 2,
      },
    },
    {
      id: 3,
      attributes: "Remote:3-4",
      tbd: {
        value: 0,
        level: 0,
      },
      negligible: {
        value: 0,
        level: 1,
      },
      minor: {
        value: 0,
        level: 1,
      },
      serious: {
        value: 2,
        level: 2,
      },
      cirtical: {
        value: 0,
        level: 2,
      },
      catastrophic: {
        value: 0,
        level: 2,
      },
    },
    {
      id: 4,
      attributes: "Occasional:5-6",
      tbd: {
        value: 0,
        level: 0,
      },
      negligible: {
        value: 0,
        level: 1,
      },
      minor: {
        value: 0,
        level: 2,
      },
      serious: {
        value: 3,
        level: 2,
      },
      cirtical: {
        value: 1,
        level: 2,
      },
      catastrophic: {
        value: 0,
        level: 3,
      },
    },
    {
      id: 5,
      attributes: "Probable:7-8",
      tbd: {
        value: 0,
        level: 0,
      },
      negligible: {
        value: 0,
        level: 2,
      },
      minor: {
        value: 0,
        level: 2,
      },
      serious: {
        value: 0,
        level: 3,
      },
      cirtical: {
        value: 0,
        level: 3,
      },
      catastrophic: {
        value: 0,
        level: 3,
      },
    },
    {
      id: 6,
      attributes: "Frequent:9-10",
      tbd: {
        value: 0,
        level: 0,
      },
      negligible: {
        value: 0,
        level: 2,
      },
      minor: {
        value: 0,
        level: 2,
      },
      serious: {
        value: 0,
        level: 3,
      },
      cirtical: {
        value: 0,
        level: 0,
      },
      catastrophic: {
        value: 0,
        level: 3,
      },
    },
  ]);

  const renderRow = (row, columns) => {
    return (
      <TableRow hover key={row.id} sx={{ borderBottom: "1px solid  rgba(0, 0, 0, 0.09)" }} role="checkbox">
        {columns.map((column) => {
          const value = row[column.id];
          if (column.id === "attributes") {
            return (
              <TableCell
                sx={{ minWidth: column.minWidth }}
                className="table_cell table_body_cell"
                key={column.id}
                align="left"
              >
                {value}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                sx={{ minWidth: column.minWidth, background: getColor(value.level) }}
                className="table_cell table_body_cell"
                key={column.id}
                align="center"
              >
                {value.value}
              </TableCell>
            );
          }
        })}
      </TableRow>
    );
  };

  const getColor = (level: number) => {
    switch (level) {
      case 0:
        return "#e6e6e6";
      case 1:
        return "#72bcff";
      case 2:
        return "#fec976";
      case 3:
        return "#ff8f76";
      default:
        return "#ccc";
    }
  };

  return (
    <HeatMapContainer>
      <Box className="flex items-center justify-between">
        <Box
          className="flex items-center"
          sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
        >
          <h3>
            <IntlMessages id="common.risk.heat.map" />
          </h3>
        </Box>
        <Box className="flex items-center">
          {heatMapType.map((item) => {
            return (
              <Box key={item.color} className="flex items-center">
                <Box sx={{ width: "8px", height: "8px", background: item.color, mx: "6px", borderRadius: "50%" }}></Box>
                <Box sx={{ color: textColor[65], fontSize: 12 }}>{item.name}</Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box>
        <TableContainer className="my_table_container">
          <AppScrollbar
            sx={{
              width: "100%",
              maxHeight: 400,
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ background: "#F0F0F0", color: "rgba(0,0,0,0.85)", fontSize: "14px" }}>
                <TableRow className="table_header">
                  {columns.map((column) => (
                    <TableCell
                      className="table_cell table_header_cell"
                      key={column.id}
                      align={column.id === "attributes" ? "left" : "center"}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {rows && rows.length > 0 && <TableBody>{rows.map((row) => renderRow(row, columns))}</TableBody>}
            </Table>
          </AppScrollbar>
        </TableContainer>
      </Box>
    </HeatMapContainer>
  );
};

export default HeatMap;
