import $ from "components/Gojs";
import * as go from "gojs";
import { ReactDiagram } from "gojs-react";
import { taskData } from "mock/index";
import * as React from "react";
import { useParams } from "react-router-dom";

interface DiagramProps {}

const Diagram: React.FC<DiagramProps> = (props: DiagramProps) => {
  const routeParam = useParams();
  const [mockData, setMockData] = React.useState(null);

  React.useEffect(() => {
    routeParam?.type && setMockData(taskData[routeParam.type]);
  }, [routeParam]);

  React.useEffect(() => {
    if (mockData) {
      setNodeDataArray(mockData.workItemAnalysis.relationships.nodeDataArray);
      setLinkDataArray(mockData.workItemAnalysis.relationships.linkDataArray);
    }
  }, [mockData]);

  const [nodeDataArray, setNodeDataArray] = React.useState<Array<go.ObjectData>>([]);
  const [linkDataArray, setLinkDataArray] = React.useState<Array<go.ObjectData>>([]);
  const ref = React.useRef();

  // const handleDiagramChange = (diagram) => {
  //   const a = diagram.model.toJson();
  //   console.log(a);
  // };

  const initDiagram = (): go.Diagram => {
    const diagram = $(go.Diagram, {
      padding: 20,
      "undoManager.isEnabled": false, // must be set to allow for model change listening, now use false,
      // $(go.TreeLayout, { layerSpacing: 150 }),
      allowTextEdit: false,
      allowDrop: false,
      //allowMove: false,
      contentAlignment: go.Spot.Left,
      layout: $(go.TreeLayout, { layerSpacing: 80 }),
    });

    let graphLinksModel = new go.GraphLinksModel();
    graphLinksModel.linkKeyProperty = "key";
    diagram.model = graphLinksModel;

    diagram.nodeTemplate = $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
      },
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Shape,
        "RoundedRectangle",
        {
          name: "SHAPE",
          fill: "white",
          portId: "",
          fromSpot: go.Spot.AllSides,
          toSpot: go.Spot.AllSides,
        },
        new go.Binding("fill", "color"),
        new go.Binding("figure", "figure"),
        new go.Binding("width", "width"),
        new go.Binding("height", "height"),
        new go.Binding("stroke", "stroke"),
        new go.Binding("fontSize", "fontSize")
      ),
      $(
        go.TextBlock,
        { margin: 8, editable: false, maxLines: 6, overflow: go.TextBlock.OverflowEllipsis, width: 200 },
        new go.Binding("text").makeTwoWay()
      )
    );

    diagram.linkTemplate = $(
      go.Link,
      {
        routing: go.Link.AvoidsNodes,
        // corner: 10,
      },
      new go.Binding("routing", "routing"),
      new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
      new go.Binding("toSpot", "toSpot", go.Spot.parse),
      $(
        go.Shape,
        { strokeWidth: 2, stroke: "#000" },
        new go.Binding("stroke", "color"),
        new go.Binding("strokeDashArray", "dash"),
        new go.Binding("strokeWidth", "thickness")
      ),
      $(
        go.Shape,
        { fromArrow: "Backward", visible: false },
        new go.Binding("fromArrow", "fromArrow"),
        new go.Binding("visible", "dir", (dir) => dir === 2),
        new go.Binding("fill", "arrowColor"),
        new go.Binding("scale", "scale", (t) => (2 + t) / 3)
      ),
      $(
        go.Shape,
        { toArrow: "Standard" },
        new go.Binding("toArrow", "toArrow"),
        new go.Binding("visible", "dir", (dir) => dir >= 1),
        new go.Binding("fill", "arrowColor"),
        new go.Binding("scale", "scale", (t) => (2 + t) / 3)
      ),
      $(
        go.TextBlock,
        { alignmentFocus: new go.Spot(0, 1, -4, 0), editable: false },
        new go.Binding("text").makeTwoWay(), // TwoWay due to user editing with TextEditingTool
        new go.Binding("stroke", "textColor")
      )
    );

    // diagram.addModelChangedListener((e) => {
    //   console.log(e);
    //   handleDiagramChange(diagram);
    // });

    return diagram;
  };

  return (
    <>
      <ReactDiagram
        ref={ref}
        style={{ width: "100%", height: "190px" }}
        divClassName="diagram-component"
        initDiagram={initDiagram}
        nodeDataArray={nodeDataArray}
        linkDataArray={linkDataArray}
      />
    </>
  );
};

export default Diagram;
