import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { tabStyle } from "shared/constants/AppConst";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Worksheet = () => {
  const navigate = useNavigate();
  const [index, setIndex] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };
  return (
    <>
      <Box>
        <Tabs
          value={index}
          onChange={handleChangeTab}
          sx={{
            borderBottom: "1px solid rgba(1,1,1,0.12)",
            ...tabStyle,
          }}
        >
          <Tab
            onClick={() => {
              navigate("format");
            }}
            label={<IntlMessages id="common.format" />}
            {...a11yProps(0)}
          />
          <Tab
            onClick={() => {
              navigate("analysis");
            }}
            label={<IntlMessages id="common.item.analysis" />}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <Box sx={{ pt: 2 }}>
        <Outlet />
      </Box>
    </>
  );
};

export default Worksheet;
