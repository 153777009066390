import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import React from "react";
import { borderColor, boxShadow, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import ContentHeader from "../components/ContentHeader";

const initRows = [
  {
    id: "1",
    time: "7:36:21 4/3/2022",
    from_version: "4.3.3.55",
    to_version: "4.3.3.56",
    success: true,
    message: "Success Full upgrade from [4.3.3.55] to [4.3.3.56]",
  },
  {
    id: "2",
    time: "7:27:57 3/3/2022",
    from_version: "4.3.3.54",
    to_version: "4.3.3.55",
    success: true,
    message: "Success Full upgrade from [4.3.3.54] to [4.4.4.55]",
  },
  {
    id: "3",
    time: "18:55:26 2/16/2022",
    from_version: "4.3.3.49",
    to_version: "4.3.3.54",
    success: true,
    message: "Success Full upgrade from [4.3.3.49] to [4.3.3.54]",
  },
];

const UpgradeContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  ".upgrade_content": {
    width: "100%",
    height: "calc(100vh - 104px)",
    padding: theme.spacing(5),
  },
}));

const Upgrade = () => {
  const [rows, setRows] = React.useState<any[]>(initRows);
  const [editRow, setEditRow] = React.useState<any>(null);
  const [description, setDescription] = React.useState<any>("");
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);

  const columns: Column[] = [
    {
      id: "time",
      label: "common.time.upgraded",
      minWidth: 150,
      align: "left",
    },
    {
      id: "from_version",
      label: "common.from.version",
      minWidth: 150,
      align: "left",
    },
    {
      id: "to_version",
      label: "common.to.version",
      minWidth: 150,
      align: "left",
    },
    {
      id: "success",
      label: "common.success",
      minWidth: 100,
      align: "left",
      format: (value, record) => {
        return (
          <Box sx={{ display: "flex" }}>
            {value ? (
              <AppSvgIcon size={16} color="#6dc347">
                material-solid:done
              </AppSvgIcon>
            ) : (
              ""
            )}
          </Box>
        );
      },
    },
    {
      id: "message",
      label: "common.message",
      minWidth: 300,
      align: "left",
    },
    {
      id: "action",
      label: "setting.product.column.actions",
      minWidth: 100,
      align: "left",
      format: (_, record) => {
        return (
          <Button
            sx={{
              color: "rgba(0,0,0,0.85)",
              border: "solid 1px rgba(0, 0, 0, 0.12)",
              fontWeight: "normal",
              height: "20px",
              paddingTop: "1px",
              paddingBottom: "1px",
            }}
            onClick={() => {
              setEditRow(record);
              setShowEditModal(true);
            }}
            variant="outlined"
          >
            <IntlMessages id="common.details" />
          </Button>
        );
      },
    },
  ];

  return (
    <UpgradeContainer>
      <ContentHeader titleId="admin.nav.item.upgrade" />
      <AppScrollbar className="upgrade_content">
        <Box
          sx={{
            padding: (theme) => theme.spacing(5),
            pt: 0,
            background: (theme) => theme.palette.background.paper,
            boxShadow: boxShadow.cardBoxShadow,
          }}
        >
          <Box
            className="w-full flex items-center"
            sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
          >
            <h3>
              <IntlMessages id="common.site.upgrade" />
            </h3>
          </Box>
          <Box sx={{ width: "100%", mt: 4, borderTop: `1px solid ${borderColor.dividerColor}` }}>
            <Box sx={{ pt: 3, color: "rgba(0,0,0,0.85)" }}>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ mr: 2, fontWeight: "bold" }}>
                  <IntlMessages id="common.current.version" />:
                </Box>
                <Box>4.3.3</Box>
              </Box>
              <Box sx={{ display: "flex", my: 3 }}>
                <Box sx={{ mr: 2, fontWeight: "bold" }}>
                  <IntlMessages id="common.available.version" />:
                </Box>
                <Box>4.3.3</Box>
              </Box>
              <Box sx={{ fontWeight: "bold" }}>
                <IntlMessages id="common.description" />:
              </Box>
              <Box>
                <TextField
                  id="outlined-multiline-flexible"
                  label=""
                  multiline
                  fullWidth
                  maxRows={4}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Box>
              <Box sx={{ mt: 3, textAlign: "right" }}>
                <Button
                  sx={{ color: "rgba(0,0,0,0.85)", border: "solid 1px rgba(0, 0, 0, 0.12)", fontWeight: "normal" }}
                  variant="outlined"
                >
                  <IntlMessages id="common.authenticate" />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            padding: (theme) => theme.spacing(5),
            pt: 0,
            background: (theme) => theme.palette.background.paper,
            mt: (theme) => theme.spacing(5),
            boxShadow: boxShadow.cardBoxShadow,
          }}
        >
          <Box
            className="w-full flex items-center"
            sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
          >
            <h3>
              <IntlMessages id="common.upgrade.history" />
            </h3>
          </Box>
          <Box sx={{ width: "100%", mt: 4, borderTop: `1px solid ${borderColor.dividerColor}` }}>
            <Box>
              <CustomTable columns={columns} rows={rows} />
            </Box>
          </Box>
        </Box>
      </AppScrollbar>
    </UpgradeContainer>
  );
};

export default Upgrade;
