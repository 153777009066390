import { Theme } from "@mui/material";
import Icon from "@mui/material/Icon";
import { styled, SxProps } from "@mui/material/styles";
import { Box } from "@mui/system";
import clsx from "clsx";
import { forwardRef, ReactNode, Ref } from "react";
import { CremaTheme } from "types/AppContextPropsType";

type MainColor = "inherit" | "disabled" | "primary" | "secondary" | "action" | "error" | "info" | "success" | "warning";
interface AppSvgIconProps {
  theme?: CremaTheme;
  size?: number | string;
  sxStyle?: SxProps<Theme>;
  color?: MainColor | string;
  children: ReactNode;
  className?: string;

  [x: string]: any;
}

const Root = styled(Box)(({ theme, ...props }: AppSvgIconProps) => ({
  width: props.size,
  height: props.size,
  minWidth: props.size,
  minHeight: props.size,
  fontSize: props.size,
  lineHeight: props.size,
  color: {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    info: theme.palette.info.main,
    success: theme.palette.success.main,
    warning: theme.palette.warning.main,
    action: theme.palette.action.active,
    error: theme.palette.error.main,
    disabled: theme.palette.action.disabled,
    inherit: undefined,
  }[props.color],
}));

const AppSvgIcon = forwardRef(
  (
    { children = "", className, size = 20, color = "inherit", sxStyle, ...rest }: AppSvgIconProps,
    ref: Ref<HTMLAnchorElement>
  ) => {
    let iconText = children as string;

    if (!iconText.includes(":")) {
      return <Icon ref={ref} {...rest} />;
    }

    const iconPath = iconText.replace(":", ".svg#");

    return (
      <Root
        {...rest}
        component="svg"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        className={clsx(className)}
        ref={ref}
        size={size}
        sx={{
          flexShrink: 0,
          fill: "currentColor",
          ...(sxStyle || {}),
        }}
        color={color}
      >
        <use xlinkHref={`/assets/icons/${iconPath}`} />
      </Root>
    );
  }
);

export default AppSvgIcon;
