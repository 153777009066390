import { Box } from "@mui/material";
import $ from "components/Gojs";
import * as go from "gojs";
import { ReactDiagram } from "gojs-react";
import { MouseEventHandler, useMemo, useRef } from "react";
import { LinkData, NodeData } from "types/models/GoJs";

interface FlowDiagramProps {
  nodeData: NodeData[];
  linkData: LinkData[];
  className?: string;
  dataJson?: any;
}

const FlowDiagram = (props: FlowDiagramProps) => {
  const { nodeData, linkData, className, dataJson } = props;

  const handleDiagramChange = (diagram) => {
    const a = diagram.model.toJson();
    console.log(a);
  };

  const diagramRef = useRef(null);
  let diagram: go.Diagram = null;

  const initDiagram = () => {
    diagram = $(go.Diagram, {
      padding: 20,
      "undoManager.isEnabled": false, // must be set to allow for model change listening, now use false,
      // $(go.TreeLayout, { layerSpacing: 150 }),
      allowTextEdit: false,
      allowDrop: false,
      // allowMove: false,
      // layout: $(go.LayeredDigraphLayout, { setsPortSpots: false }),
    });

    let graphLinksModel = new go.GraphLinksModel();
    graphLinksModel.linkKeyProperty = "key";
    diagram.model = graphLinksModel;

    diagram.nodeTemplate = $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
      },
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Shape,
        {
          name: "SHAPE",
          fill: "white",
          portId: "",
          fromSpot: go.Spot.AllSides,
          toSpot: go.Spot.AllSides,
        },
        new go.Binding("fill", "color"),
        new go.Binding("figure", "figure"),
        new go.Binding("width", "width"),
        new go.Binding("height", "height"),
        new go.Binding("stroke", "stroke"),
        new go.Binding("fontSize", "fontSize")
      ),
      $(go.TextBlock, { margin: 8, editable: true }, new go.Binding("text").makeTwoWay())
    );
    diagram.linkTemplate = $(
      go.Link,
      {
        routing: go.Link.AvoidsNodes,
        corner: 4,
      },
      new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
      new go.Binding("toSpot", "toSpot", go.Spot.parse),
      $(go.Shape, { strokeWidth: 1 }),
      $(go.Shape, { fromArrow: "Backward", visible: false }),
      $(go.Shape, { toArrow: "Standard" }),
      $(
        go.Panel,
        "Auto",
        $(
          go.Shape, // the label background, which becomes transparent around the edges
          {
            fill: "rgba(255, 255, 255, 1)",
            stroke: null,
          }
        ),
        $(
          go.TextBlock,
          { alignmentFocus: new go.Spot(0, 1, -4, 0), editable: false },
          new go.Binding("text").makeTwoWay(), // TwoWay due to user editing with TextEditingTool
          new go.Binding("stroke", "textColor")
        )
      )
    );
    diagram.addModelChangedListener((e) => {
      console.log(e);
      handleDiagramChange(diagram);
    });
    return diagram;
  };
  const diagramDom = useMemo(() => {
    return (
      <ReactDiagram
        ref={diagramRef}
        initDiagram={initDiagram}
        divClassName="diagram-box"
        nodeDataArray={dataJson && dataJson.nodeDataArray ? dataJson.nodeDataArray : nodeData}
        linkDataArray={dataJson && dataJson.linkDataArray ? dataJson.linkDataArray : linkData}
      />
    );
  }, [diagramRef, nodeData, linkData, dataJson]);

  return (
    <>
      <Box className={`flex ${className}`}>{diagramDom}</Box>
    </>
  );
};

export default FlowDiagram;
