import $ from "components/Gojs";
import * as go from "gojs";
import { ReactDiagram } from "gojs-react";
import * as React from "react";

interface DiagramProps {}

const Diagram: React.FC<DiagramProps> = (props: DiagramProps) => {
  const [nodeDataArray, setNodeDataArray] = React.useState<Array<go.ObjectData>>([
    { key: 0, text: "Hazard Category", group: "group_1" },
    { key: 1, text: "System Hazard", group: "group_1" },
    { key: 3, text: "Foreseeable Scenarios", group: "group_1" },
    { key: 2, text: "Hazardous Situation", group: "group_1" },
    { key: 5, text: "Harm", group: "group_1" },
    { key: 6, text: "Severity", color: "lightgreen", group: "group_1" },
    { key: 4, text: "P2", color: "lightgreen", group: "group_1" },
    { key: "group_1", isGroup: true, text: "", fill: "#FFF" },
  ]);
  const [linkDataArray, setLinkDataArray] = React.useState<Array<go.ObjectData>>([
    { key: 4, from: 3, to: 2, color: "rgba(0,0,0,0.85)" },
    { key: 1, from: 1, to: 0, dash: [6, 3], toArrow: "Standard" },
    { key: 7, from: 5, to: 6, toArrow: "Diamond", dash: [6, 3] },
    { key: 3, from: 2, to: 1, dash: [6, 3], toArrow: "Standard" },
    { key: 6, from: 2, to: 5, color: "rgba(0,0,0,0.85)" },
    { key: 5, from: 2, to: 4, toArrow: "Diamond", dash: [6, 3] },
    { key: 2, from: 4, to: 5, toArrow: "Diamond", dash: [6, 3] },
  ]);
  const ref = React.useRef();

  const initDiagram = (): go.Diagram => {
    const diagram = $(go.Diagram, {
      padding: 20,
      "undoManager.isEnabled": false, // must be set to allow for model change listening, now use false,
      // $(go.TreeLayout, { layerSpacing: 150 }),
      allowTextEdit: false,
      allowDrop: false,
      // allowMove: false,
      layout: $(go.GridLayout, { spacing: new go.Size(80, 80) }),
    });

    let graphLinksModel = new go.GraphLinksModel();
    graphLinksModel.linkKeyProperty = "key";
    diagram.model = graphLinksModel;

    diagram.nodeTemplate = $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
      },
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Shape,
        {
          name: "SHAPE",
          fill: "white",
          portId: "",
          fromSpot: go.Spot.AllSides,
          toSpot: go.Spot.AllSides,
        },
        new go.Binding("fill", "color"),
        new go.Binding("figure", "figure"),
        new go.Binding("width", "width"),
        new go.Binding("height", "height"),
        new go.Binding("stroke", "stroke"),
        new go.Binding("fontSize", "fontSize")
      ),
      $(go.TextBlock, { margin: 8, editable: false }, new go.Binding("text").makeTwoWay())
    );

    diagram.groupTemplate = $(
      go.Group,
      "Spot",
      {
        layerName: "Background",
        ungroupable: true,
        locationSpot: go.Spot.Center,
        selectionObjectName: "BODY",
        computesBoundsAfterDrag: true, // allow dragging out of a Group that uses a Placeholder
        handlesDragDropForMembers: true, // don't need to define handlers on Nodes and Links
        avoidable: false,
        layout: $(go.LayeredDigraphLayout),
      },
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Panel,
        "Auto",
        { name: "BODY" },
        $(
          go.Shape,
          {
            parameter1: 10,
            fill: "white",
            strokeWidth: 2,
            stroke: "transparent",
            portId: "",
            cursor: "pointer",
            fromLinkable: true,
            toLinkable: true,
            fromLinkableDuplicates: true,
            toLinkableDuplicates: true,
            fromSpot: go.Spot.AllSides,
            toSpot: go.Spot.AllSides,
            width: 800,
          },
          new go.Binding("fill"),
          new go.Binding("stroke", "color"),
          new go.Binding("strokeWidth", "thickness"),
          new go.Binding("strokeDashArray", "dash")
        ),
        $(go.Placeholder, { background: "transparent", margin: 10 })
      ),
      $(
        go.TextBlock,
        {
          alignment: go.Spot.Top,
          alignmentFocus: go.Spot.Bottom,
          font: "bold 12pt sans-serif",
          editable: false,
        },
        new go.Binding("text"),
        new go.Binding("stroke", "color")
      )
    );

    // go.Shape.defineArrowheadGeometry(
    //   "Database",
    //   "F1 m 0,0 l 8,4 -8,4 2,-4 z"
    // );
    diagram.linkTemplate = $(
      go.Link,
      {
        routing: go.Link.AvoidsNodes,
        // corner: 10,
      },
      new go.Binding("routing", "routing"),
      new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
      new go.Binding("toSpot", "toSpot", go.Spot.parse),
      $(
        go.Shape,
        { strokeWidth: 2, stroke: "#000" },
        new go.Binding("stroke", "color"),
        new go.Binding("strokeDashArray", "dash"),
        new go.Binding("strokeWidth", "thickness")
      ),
      $(
        go.Shape,
        { fromArrow: "Backward", visible: false },
        new go.Binding("fromArrow", "fromArrow"),
        new go.Binding("visible", "dir", (dir) => dir === 2),
        new go.Binding("fill", "arrowColor"),
        new go.Binding("scale", "scale", (t) => (2 + t) / 3)
      ),
      $(
        go.Shape,
        { toArrow: "Standard" },
        new go.Binding("toArrow", "toArrow"),
        new go.Binding("visible", "dir", (dir) => dir >= 1),
        new go.Binding("fill", "arrowColor"),
        new go.Binding("scale", "scale", (t) => (2 + t) / 3)
      ),
      $(
        go.TextBlock,
        { alignmentFocus: new go.Spot(0, 1, -4, 0), editable: false },
        new go.Binding("text").makeTwoWay(), // TwoWay due to user editing with TextEditingTool
        new go.Binding("stroke", "textColor")
      )
    );

    diagram.addModelChangedListener((e) => {
      console.log(e);
      handleDiagramChange(diagram);
    });

    return diagram;
  };

  const handleDiagramChange = (diagram) => {
    const a = diagram.model.toJson();
    console.log(a);
  };

  return (
    <>
      <ReactDiagram
        ref={ref}
        style={{ width: "100%", height: "100%" }}
        divClassName="diagram-component"
        initDiagram={initDiagram}
        nodeDataArray={nodeDataArray}
        linkDataArray={linkDataArray}
      />
    </>
  );
};

export default Diagram;
