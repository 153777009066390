import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import CircularLoading from "components/CircularLoading";
import MyCollapseTable from "components/MyCollapseTable";
import React, { useEffect, useState } from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import { LookupTableModel } from "../RiskPolicy/models";

const Container = styled(Box)(({ theme }) => ({
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell": {
    border: `1px solid  ${borderColor.dividerColor}`,
  },
  ".table-toolbar": {
    display: "flex",
    justifyContent: "end",
    padding: theme.spacing(2, 0),
    ".add-button": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const LookupDropdownList = ["TBD", "Improbable", "Remote", "Occasional", "Probable", "Frequent"];
interface ColumnData {
  id: string;
  label: string;
  type: "text" | "dropdown";
}
const SelectMenuItem = (props: { value: string | number; dataSource: any[] }) => {
  const { value, dataSource } = props;
  const [selected, setSelected] = useState(value);
  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value);
  };
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <Select
        value={selected}
        defaultValue={value}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        {dataSource.map((l) => (
          <MenuItem key={l} value={l}>
            {l}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
const genericColumns = (columnsData: ColumnData[]) => {
  return columnsData.map((x) => {
    return {
      id: x.id,
      label: x.label,
      format: (value, record) => {
        if (x.type === "text") {
          return <>{value}</>;
        } else if (x.type === "dropdown") {
          return <SelectMenuItem value={value} dataSource={LookupDropdownList} />;
        }
      },
    } as Column;
  });
};

const initColumnsData: ColumnData[] = [
  {
    id: "p2_p1",
    label: "P2\\P1",
    type: "text",
  },
  {
    id: "1v1",
    label: "1:1",
    type: "dropdown",
  },
  {
    id: "2v2",
    label: "2:2",
    type: "dropdown",
  },
  {
    id: "3v3",
    label: "3:3",
    type: "dropdown",
  },
  {
    id: "4v4",
    label: "4:4",
    type: "dropdown",
  },
  {
    id: "5v5",
    label: "5:5",
    type: "dropdown",
  },
];

const createData = async (rowId) => {
  const p = new Promise<LookupTableModel[]>((resolve, reject) => {
    setTimeout(() => {
      resolve([
        {
          id: `${rowId}01`,
          p2_p1: "1: 1",
          "1v1": "TBD",
          "2v2": "TBD",
          "3v3": "TBD",
          "4v4": "TBD",
          "5v5": "TBD",
        },
        {
          id: `${rowId}02`,
          p2_p1: "2: 2",
          "1v1": "TBD",
          "2v2": "TBD",
          "3v3": "TBD",
          "4v4": "TBD",
          "5v5": "TBD",
        },
        {
          id: `${rowId}03`,
          p2_p1: "3: 3",
          "1v1": "TBD",
          "2v2": "TBD",
          "3v3": "TBD",
          "4v4": "TBD",
          "5v5": "TBD",
        },
        {
          id: `${rowId}04`,
          p2_p1: "4: 4",
          "1v1": "TBD",
          "2v2": "TBD",
          "3v3": "TBD",
          "4v4": "TBD",
          "5v5": "TBD",
        },
        {
          id: `${rowId}05`,
          p2_p1: "5: 5",
          "1v1": "TBD",
          "2v2": "TBD",
          "3v3": "TBD",
          "4v4": "TBD",
          "5v5": "TBD",
        },
      ]);
    }, 200);
  });
  const r = await p;
  return r;
};
interface LookupTableProps {
  rowId?: number | string;
}
const LookupTable = (props: LookupTableProps) => {
  const { rowId } = props;

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState<LookupTableModel[]>([]);
  const [loading, setLoading] = useState(true);

  const createDataFn = async (rowId) => {
    setLoading(true);
    const a = await createData(rowId);
    setRows(a);

    setLoading(false);
  };

  useEffect(() => {
    const c = genericColumns(initColumnsData);
    console.log(c);
    setColumns(c);
    createDataFn(rowId);
  }, []);

  return (
    <>
      <CircularLoading loading={loading}>
        <Container>
          {!loading ? (
            <MyCollapseTable columns={columns} rows={rows} showPage={true} collapse={false}></MyCollapseTable>
          ) : (
            <></>
          )}
        </Container>
      </CircularLoading>
    </>
  );
};

export default LookupTable;
