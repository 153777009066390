import { Dialog, ModalProps } from "@mui/material";
import React from "react";

import destroyFns from "./destroyFns";
import ModalWrapper from "./ModalWrapper";

const ModalHelper = () => {};

ModalHelper.create = (
  title: React.ReactNode,
  comp: React.ReactNode,
  props?: ModalProps
) => {
  return ModalWrapper(title, comp, props);
};

// ModalHelper.static = (
//   title: string,
//   comp: React.ReactNode,
//   props: ModalProps
// ) => {};

ModalHelper.destroyAll = () => {
  while (destroyFns.length) {
    const destroy = destroyFns.pop();
    destroy && destroy();
  }
};

export default ModalHelper;
