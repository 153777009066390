import Icon from "@mui/material/Icon";
import * as React from "react";

const OpenSubtasksSvg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="gfgaubup6a">
        <stop stopColor="#6656C2" offset="0%" />
        <stop stopColor="#9E8AED" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect fill="#5243AA" opacity=".1" width="40" height="40" rx="4" />
      <path d="M5 5h30v30H5z" />
      <path
        d="M7.5 5.357c-1.2 0-2.143.943-2.143 2.143 0 1.2.943 2.143 2.143 2.143 1.2 0 2.143-.943 2.143-2.143 0-1.2-.943-2.143-2.143-2.143zm0 7.5c-1.2 0-2.143.943-2.143 2.143 0 1.2.943 2.143 2.143 2.143 1.2 0 2.143-.943 2.143-2.143 0-1.2-.943-2.143-2.143-2.143zm0 7.5c-1.2 0-2.143.943-2.143 2.143 0 1.2.943 2.143 2.143 2.143 1.2 0 2.143-.943 2.143-2.143 0-1.2-.943-2.143-2.143-2.143zM24.19 6.43H13.31c-.272 0-.453.214-.453.535v2.143c0 .322.181.536.453.536h10.88c.272 0 .453-.214.453-.536V6.964c0-.321-.181-.535-.453-.535zm0 7.5H13.31c-.272 0-.453.214-.453.535v2.143c0 .322.181.536.453.536h10.88c.272 0 .453-.214.453-.536v-2.143c0-.321-.181-.535-.453-.535zm0 7.5H13.31c-.272 0-.453.214-.453.535v2.143c0 .322.181.536.453.536h10.88c.272 0 .453-.214.453-.536v-2.143c0-.321-.181-.535-.453-.535z"
        fill="url(#gfgaubup6a)"
        transform="translate(5 5)"
      />
    </g>
  </svg>
);

const OpenSubtasksIcon: React.FunctionComponent = () => {
  return <Icon component={OpenSubtasksSvg} />;
};

export default OpenSubtasksIcon;
