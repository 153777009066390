import IntlMessages from "@crema/utility/IntlMessages";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Button, Divider, Menu, MenuItem, Typography } from "@mui/material";
import ExportIcon from "components/Icons/ExportIcon";
import WordIcon from "components/Icons/WordIcon";
import React from "react";

interface ExportDropdownProps {
  exportToWord: () => void;
  exportToExcel: () => void;
  exportToPdf: () => void;
  otherItems?: React.ReactNode[];
  sxStyle?: any;
  variant?: string;
}

const ExportDropdown: React.FC<ExportDropdownProps> = (props) => {
  const { exportToWord, exportToExcel, exportToPdf, sxStyle, otherItems, variant } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openList = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        sx={{
          marginLeft: 2,
          color: "rgba(0,0,0,0.85)",
        }}
        variant={variant ? variant : "text"}
        onClick={handleClick}
        startIcon={<ExportIcon />}
        endIcon={openList ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        {...sxStyle}
      >
        <IntlMessages id="sidebar.mui.dataGrid.export" />
      </Button>
      <Menu
        id="export-menus"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            exportToWord();
          }}
        >
          <WordIcon />
          <Typography sx={{ pl: 2 }}>
            <IntlMessages id="common.export.to.word" />
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            exportToExcel();
          }}
        >
          <WordIcon />
          <Typography sx={{ pl: 2 }}>
            <IntlMessages id="common.export.to.excel" />
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            exportToPdf();
          }}
        >
          <WordIcon />
          <Typography sx={{ pl: 2 }}>
            <IntlMessages id="common.export.to.pdf" />
          </Typography>
        </MenuItem>
        {otherItems && <Divider light />}
        {otherItems}
      </Menu>
    </Box>
  );
};

export default ExportDropdown;
