// import React from 'react';
import { RouteObject } from "react-router";
import { Navigate } from "react-router-dom";
import { authRole, RoutePermittedRole } from "shared/constants/AppConst";

/**
 * @param {Object} structure - The passed object that defines the routes.
 * @param {boolean} structure.isAuthenticated - [Required] in order to differentiate between LoggedIn/Loggedout users
 * @param {string} structure.userRole - [Optional] in order to differentiate between admin and normal users
 * @param {object} [structure.anonymousStructure] - it's an object that has only [ routes ] array, [ these routes available for All personas ]
 * @param {object} [structure.authorizedStructure] - it's an object that has [ fallbackPath: {string}, routes: {array} ], fallbackPath: is used for redirect when a logged [in] user tried to access unAuthorized route, routes: only The Logged [in] Routes Available
 * @param {object} [structure.unAuthorizedStructure] - it's an object that has [ fallbackPath: {string}, routes: {array} ], fallbackPath: is used for redirect when a logged [out] user tried to access route that requires [Authorization] , routes: only The Logged [out] Routes Available
 * @param {component} [structure.component fallbackComponent] - in order to redirect in all cases if the route doesn't match.
 * @param {unAuthorizedComponent} [structure.unAuthorizedComponent] - in order to show not permitted route.
 * @returns {Array}
 */

const generateRoutes = (structure: any) => {
  const {
    isAuthenticated = false,
    anonymousStructure = {},
    authorizedStructure = {},
    unAuthorizedStructure = {},
    userRole = authRole.user,
  } = structure || {};

  const dynamicRoutes: RouteObject[] = [];

  if (anonymousStructure) {
    dynamicRoutes.push(...routesGenerator(isAuthenticated, anonymousStructure, "anonymous"));
  }

  if (authorizedStructure) {
    const auRouters = routesGenerator(
      isAuthenticated,
      authorizedStructure,
      "authorized",
      isAuthenticated ? userRole : null
    );
    dynamicRoutes.push(...auRouters);
  }

  if (unAuthorizedStructure) {
    dynamicRoutes.push(...routesGenerator(isAuthenticated, unAuthorizedStructure, "unAuthorized"));
  }
  return dynamicRoutes;
};

const deepRoutesGenerator = (
  unAuthorizedComponent: any,
  isAuthenticated: boolean = false,
  routes: any[],
  isAnonymous: boolean,
  isAuthorized: boolean,
  fallbackPath: string = "",
  userRole?: any
) => {
  const generatedRoutes: any[] = [];
  routes.forEach((route /*index*/) => {
    const {
      path = "",
      permittedRole = RoutePermittedRole.User,
      // routeProps = {},
      redirectPath = "",
      showRouteIf = true,
    } = route || {};
    // Show Route only [ in The list ] if this prop is true
    if (showRouteIf) {
      // check the mandatory props for a routes
      if (!path) {
        console.log(
          "A [route] is skipped because one of the following, No valid [path] prop provided for the route",
          isAuthenticated
        );
      } else {
        if (isAnonymous) {
          return generatedRoutes.push(route);
        }
        if (isAuthorized) {
          const renderCondition = isAuthorized ? isAuthenticated : !isAuthenticated;

          if (Array.isArray(route.path)) {
            route.path.map((path: string) => {
              const newRoute = renderCondition
                ? userRole.indexOf(permittedRole) > -1
                  ? {
                      element: route.element,
                      path: path,
                      permittedRole: route.permittedRole,
                    }
                  : {
                      path: path,
                      element: unAuthorizedComponent,
                    }
                : {
                    path: path,
                    element: <Navigate to={redirectPath || fallbackPath} replace />,
                  };
              if (route.children) {
                const newChildren = deepRoutesGenerator(
                  unAuthorizedComponent,
                  isAuthenticated,
                  route.children,
                  isAnonymous,
                  isAuthorized,
                  fallbackPath,
                  userRole
                );
                newRoute["children"] = newChildren;
              }
              return generatedRoutes.push(newRoute);
            });
          } else {
            const newRoute = renderCondition
              ? userRole.indexOf(permittedRole) > -1
                ? route
                : {
                    path: route.path,
                    element: unAuthorizedComponent,
                  }
              : {
                  path: route.path,
                  element: <Navigate to={redirectPath || fallbackPath} replace />,
                };
            if (route.children) {
              const newChildren = deepRoutesGenerator(
                unAuthorizedComponent,
                isAuthenticated,
                route.children,
                isAnonymous,
                isAuthorized,
                fallbackPath,
                userRole
              );
              newRoute["children"] = newChildren;
            }
            generatedRoutes.push(newRoute);
          }
          return generatedRoutes;
        }
        const renderCondition = isAuthorized ? isAuthenticated : !isAuthenticated;
        if (Array.isArray(route.path)) {
          route.path.map((path: string) => {
            const newRoute = renderCondition
              ? {
                  element: route.element,
                  path: path,
                  permittedRole: route.permittedRole,
                }
              : {
                  path: path,
                  element: <Navigate to={redirectPath || fallbackPath} replace />,
                };
            if (route.children) {
              const newChildren = deepRoutesGenerator(
                unAuthorizedComponent,
                isAuthenticated,
                route.children,
                isAnonymous,
                isAuthorized,
                fallbackPath,
                userRole
              );
              newRoute["children"] = newChildren;
            }
            return generatedRoutes.push(newRoute);
          });
        } else {
          const newRoute = renderCondition
            ? route
            : {
                path: route.path,
                element: <Navigate to={redirectPath || fallbackPath} replace />,
              };
          if (route.children) {
            const newChildren = deepRoutesGenerator(
              unAuthorizedComponent,
              isAuthenticated,
              route.children,
              isAnonymous,
              isAuthorized,
              fallbackPath,
              userRole
            );
            newRoute["children"] = newChildren;
          }
          generatedRoutes.push(newRoute);
        }
        return generatedRoutes;
      }
    }
  });
  return generatedRoutes;
};

/**
 * path: string
 * component: React.Component
 * routeProps: Object -----> To override route props
 * userRole: string -----> To override route props
 * redirectPath: String ----> To redirect to specific location
 * showRouteIf: to override when to show the component or when to [ Navigate ]
 */
const routesGenerator = (
  isAuthenticated: boolean = false,
  routeSet: any = {},
  type: string = "anonymous",
  userRole?: any
): any => {
  let generatedRoutes: any[] = [];
  const { fallbackPath = "" } = routeSet || {};

  const isAnonymous = type === "anonymous";
  const isAuthorized = type === "authorized";

  if (routeSet?.routes) {
    const routes = routeSet.routes;
    if (Array.isArray(routes) && routes.length > 0) {
      generatedRoutes = deepRoutesGenerator(
        routeSet.unAuthorizedComponent,
        isAuthenticated,
        routes,
        isAnonymous,
        isAuthorized,
        fallbackPath,
        userRole
      );
    }
  } else {
    console.log(`[routes] prop can't be found in ${type}Structure Object`);
  }
  return generatedRoutes;
};

export default generateRoutes;
