import IntlMessages from "@crema/utility/IntlMessages";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddLinkIcon from "@mui/icons-material/AddLink";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Card, CardContent, IconButton, Menu, MenuItem, Tab, Tabs, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import ExportDropdown from "components/ExportDropdown";
import CustomizeIcon from "components/Icons/CustomizeIcon";
import MyTree from "components/MyTree";
import ReleaseModal from "pages/products/components/ReleaseModal";
import GanttChart from "pages/products/GanttChart";
import Tasks from "pages/products/Tasks";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { AppState } from "redux/store";
import { bgColor, boxShadow, textColor, treeStyle } from "shared/constants/AppConst";

function a11yProps(index: number | string) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

const deepFind =
  (pred) =>
  ([x, ...xs] = []) =>
    x && (pred(x) ? x : deepFind(pred)(x.items) || deepFind(pred)(xs));

const findById = (id) => (dataSet) => deepFind((o) => o.id === id)(dataSet);

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const initDataset: any[] = [
  {
    value: "Pre-Production",
    id: "Pre-Production",
    opened: true,
    isBold: true,
    items: [
      {
        value: "1. Concept & Requirements",
        id: "1. Concept & Requirements",
        opened: true,
      },
      {
        value: "2. Design & Development",
        id: "2. Design & Development",
      },
      {
        value: "3. Verification & Validation",
        id: "3. Verification & Validation",
      },
      {
        value: "4. Regulatory Approval",
        id: "4. Regulatory Approval",
      },
    ],
  },
  {
    value: "Production",
    id: "Production",
    isBold: true,
  },
  {
    value: "Post-Production",
    id: "Post-Production",
    isBold: true,
  },
];

const ProductLifeCycleContainer = styled(Box)(({ theme }) => ({
  ".content-layout": {
    padding: "20px !important",
    background: bgColor.main,
    height: "calc(100vh - 155px)",
    width: "100%",
  },
  ".content-container": {
    display: "flex",
    width: "calc(100%)",
    padding: theme.spacing(5),
    paddingLeft: 0,
    background: "#fff",
    boxShadow: boxShadow.cardBoxShadow,
    height: "calc(100vh - 195px)",
  },
  ".table-container": {
    width: "calc(100% - 320px)",
    ".MuiTabs-root": {
      backgroundColor: "#dce0e6",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
      minHeight: "auto",
      marginBottom: theme.spacing(0),
      ".MuiTabs-scroller": {
        width: "auto",
      },
      ".MuiTab-root": {
        padding: "1px 8px",
        minWidth: "auto",
        minHeight: "24px",
        textTransform: "capitalize",
        color: textColor[65],
        fontWeight: "400",
      },
      ".Mui-selected": {
        color: textColor[85],
        borderRadius: "2px",
        boxShadow: boxShadow.cardBoxShadow,
        backgroundColor: theme.palette.background.paper,
        fontWeight: "500",
      },
      ".MuiTabs-indicator": {
        display: "none",
      },
    },
  },
}));

export default function ProductLifeCycle() {
  const { messages } = useIntl();
  const [index, setIndex] = React.useState("tasks");

  const { stage } = useSelector<AppState, AppState["products"]>(({ products }) => products);

  const [selected, setSelected] = React.useState<any>("Pre-Production");
  const [openReleaseModal, setOpenReleaseModal] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<any>("");
  const [dataset, setDataset] = React.useState<any[]>([]);

  const [actionList, setActionList] = React.useState<ButtonMenuListItemModel[]>([
    {
      id: "1",
      labelId: "common.add",
      icon: <AddCircleIcon sx={{ fontSize: "16px !important" }} />,
    },
    {
      id: "2",
      labelId: "common.edit",
      icon: <EditIcon sx={{ fontSize: "16px !important" }} />,
    },
    {
      id: "3",
      labelId: "common.delete",
      icon: <DeleteIcon sx={{ fontSize: "16px !important" }} />,
    },
  ]);

  const ergodic = useCallback((tree: any[], value: string) => {
    for (let item of tree) {
      if (item.value === value) {
        item.currentStage = true;
      } else {
        item.currentStage = false;
      }
      if (item.items && item.items.length > 0) {
        ergodic(item.items, value);
      }
    }
  }, []);

  React.useEffect(() => {
    ergodic(initDataset, stage);
    setDataset([...initDataset]);
  }, [ergodic, stage]);

  const handleClickDom = useCallback(
    (e) => {
      if (e.target.id.includes("more_button_")) {
        const list = e.target.id.split("more_button_");
        const nodeId = list[list.length - 1];
        const currentItem = findById(nodeId.toString())(dataset);
        if (currentItem && currentItem.value === stage) {
          setActionList([
            {
              id: "1",
              labelId: "common.add",
              icon: <AddCircleIcon sx={{ fontSize: "16px !important" }} />,
            },
            {
              id: "2",
              labelId: "common.edit",
              icon: <EditIcon sx={{ fontSize: "16px !important" }} />,
            },
            {
              id: "3",
              labelId: "common.delete",
              icon: <DeleteIcon sx={{ fontSize: "16px !important" }} />,
            },
            {
              id: "4",
              labelId: "common.upgrade.stage",
              icon: <AddLinkIcon sx={{ fontSize: "16px !important" }} />,
            },
          ]);
        } else {
          setActionList([
            {
              id: "1",
              labelId: "common.add",
              icon: <AddCircleIcon sx={{ fontSize: "16px !important" }} />,
            },
            {
              id: "2",
              labelId: "common.edit",
              icon: <EditIcon sx={{ fontSize: "16px !important" }} />,
            },
            {
              id: "3",
              labelId: "common.delete",
              icon: <DeleteIcon sx={{ fontSize: "16px !important" }} />,
            },
          ]);
        }
        handleClick(e.target);
      }
    },
    [dataset]
  );

  React.useEffect(() => {
    window.addEventListener("click", handleClickDom);
    return () => {
      window.removeEventListener("click", handleClickDom);
    };
  }, [dataset, handleClickDom]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSelected = (item: ButtonMenuListItemModel) => {
    if (item.labelId === "common.upgrade.stage") {
      setOpenReleaseModal(true);
    }
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onNodeSelect = (event: object, value: string) => {
    setSelected(value);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setIndex(newValue);
  };

  const requestSearch = (valuie) => {
    setSearchText(valuie);
  };

  return (
    <ProductLifeCycleContainer>
      <Box className="page-content">
        <Card sx={{ py: 0, px: 0 }}>
          <CardContent className="content-layout">
            <Box className="content-container">
              <MyTree
                selected={selected}
                onNodeSelect={onNodeSelect}
                data={dataset}
                useStyledTree={true}
                showMoreIcon={true}
                showNodeStatus={true}
                showFolder={true}
                sx={{
                  width: "329px",
                  height: "100%",
                  ...treeStyle,
                  padding: "0px 20px 0px 20px",
                  marginRight: "0px",
                  borderRight: "1px solid #e6e6e6",
                }}
              />
              <Menu
                id="button-menus"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiSvgIcon-root": {
                    marginRight: 2,
                  },
                  "& .MuiMenuItem-root": {
                    color: "rgba(0,0,0,0.85)",
                  },
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {actionList.map((item) => (
                  <MenuItem
                    key={item.id}
                    onClick={() => {
                      handleSelected(item);
                    }}
                  >
                    {item.icon}
                    <IntlMessages id={item.labelId} />
                  </MenuItem>
                ))}
              </Menu>
              <Box
                className="table-container"
                sx={{
                  pl: (theme) => theme.spacing(5),
                }}
              >
                <Box className="flex items-center justify-between">
                  <Box className="flex items-center justify-between">
                    <Box className="flex">
                      <Tabs value={index} onChange={handleChangeTab} aria-label="basic tabs example">
                        <Tab
                          value={"tasks"}
                          label={<IntlMessages id="workspace.column.tasks" />}
                          {...a11yProps("tasks")}
                        />
                        <Tab
                          value={"gantt"}
                          label={<IntlMessages id="products.gantt.chart" />}
                          {...a11yProps("gantt")}
                        />
                      </Tabs>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                      <TextField
                        variant="outlined"
                        value={searchText}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value)}
                        placeholder={String(messages["common.search"])}
                        InputProps={{
                          startAdornment: (
                            <AppSvgIcon size={16} color="action">
                              {"material-outline:search"}
                            </AppSvgIcon>
                          ),
                          endAdornment: (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: searchText ? "visible" : "hidden" }}
                                onClick={() => {
                                  requestSearch("");
                                }}
                              >
                                <AppSvgIcon size={16} color="action">
                                  {"material-outline:clear"}
                                </AppSvgIcon>
                              </IconButton>
                            </Box>
                          ),
                        }}
                        sx={{
                          width: {
                            xs: 1,
                            sm: "auto",
                          },
                          m: 0,
                          "& .MuiSvgIcon-root": {
                            mr: 0.5,
                          },
                          "& .MuiInputBase-root": {
                            padding: "0px 14px",
                            height: "32px",
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "0px",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottom: 1,
                            borderColor: "divider",
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className="flex items-center justify-between">
                    <ExportDropdown exportToWord={() => {}} exportToExcel={() => {}} exportToPdf={() => {}} />
                    <Button
                      sx={{
                        marginLeft: 2,
                        color: textColor[85],
                      }}
                      variant="text"
                      onClick={() => {}}
                      startIcon={<CustomizeIcon />}
                    >
                      <IntlMessages id="common.customize" />
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ height: "calc(100% - 49px)" }}>
                  <TabPanel index={"tasks"} value={index}>
                    <Tasks />
                  </TabPanel>
                  <TabPanel index={"gantt"} value={index}>
                    <GanttChart />
                  </TabPanel>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {openReleaseModal && (
        <ReleaseModal
          open={openReleaseModal}
          handleClose={() => {
            setOpenReleaseModal(false);
          }}
        />
      )}
    </ProductLifeCycleContainer>
  );
}
