import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Grid, Step, StepLabel, Stepper } from "@mui/material";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import React from "react";
import { borderColor, textColor } from "shared/constants/AppConst";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1890FF",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1890FF",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const TaskCycleTimeContainer = styled(Box)(({ theme }) => ({}));

const TaskCycleTime = () => {
  const [estimate, setEstimate] = React.useState<string>("6 days");
  const [steps, setSteps] = React.useState<any>([
    {
      id: "1",
      label: "Draft",
      operator: "James",
      operationTime: "2017-10-01 12:00",
    },
    {
      id: "3",
      label: "Review & Comment",
    },
    {
      id: "4",
      label: "Comment Resolution",
    },
    {
      id: "5",
      label: "Ready for Approval Route",
    },
    {
      id: "6",
      label: "Route Approval",
    },
  ]);
  return (
    <TaskCycleTimeContainer>
      <Box
        className="w-full flex items-center"
        sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
      >
        <h3>
          <IntlMessages id="common.task.cycle.time" />
        </h3>
      </Box>
      <Box
        sx={{
          background: "#fafafa",
          mb: (theme) => theme.spacing(5),
          padding: (theme) => theme.spacing(4, 5),
          height: "88px",
        }}
      >
        <Grid container>
          <Grid item xs={3}>
            <Box
              className="flex"
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                px: (theme) => theme.spacing(5),
                color: textColor[85],
              }}
            >
              <Box sx={{ mb: 2 }} className="label font-bold">
                <IntlMessages id="common.original.estimate" />:
              </Box>
              <Box className="value">{estimate}</Box>
            </Box>
          </Grid>
          <Grid item xs={9} sx={{ borderLeft: `1px solid ${borderColor.dividerColor}` }}>
            <Box
              className="flex"
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                px: (theme) => theme.spacing(5),
              }}
            >
              <Box sx={{ mb: 2 }} className="label font-bold">
                <IntlMessages id="common.time.tracking" />:
              </Box>
              <Box>
                <BorderLinearProgress variant="determinate" value={50} />
                <Box sx={{ mt: 1 }}>1 day logged</Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: (theme) => theme.spacing(12) }}>
        <Stepper activeStep={1} alternativeLabel connector={<QontoConnector />}>
          {steps.map((item) => (
            <Step key={item.id}>
              <StepLabel>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: textColor[85],
                    fontSize: "14px",
                  }}
                >
                  <Box sx={{ fontWeight: "bold" }}>{item.label}</Box>
                  <Box
                    sx={{
                      color: textColor[65],
                      fontSize: "12px",
                      mt: 2,
                    }}
                    className="flex items-center justify-center"
                  >
                    {item?.operator && <AppSvgIcon size={16}>material-outline:account_circle</AppSvgIcon>}
                    {item?.operator}
                  </Box>
                  <Box
                    sx={{
                      color: textColor[65],
                      fontSize: "12px",
                    }}
                  >
                    {item?.operationTime}
                  </Box>
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </TaskCycleTimeContainer>
  );
};

export default TaskCycleTime;
