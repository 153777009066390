import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import IntlMessages from "@crema/utility/IntlMessages";
import { Avatar, Box, Button, Grid, IconButton, InputAdornment } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import MyTextField from "components/MyTextField";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { textColor } from "shared/constants/AppConst";
import * as yup from "yup";

import ChangeScope from "./ChangeScope";
import ScopeForm from "./ScopeForm";

const scopeMockData = {
  "System Hazard Analysis": {
    formData: {
      name: "System Hazard Analysis",
      id: "TK#",
      status: "Draft",
      version: "",
      owner: "",
    },
    descriptionRichHtml: "<p>A brief instruction about this deliverable.</p>",
    changeScopeTree: [
      {
        id: "Risk_Management",
        name: "Risk Management",
        level: 1,
        isFolder: true,
        inScope: true,
        addNewItem: false,
        allowToEdit: false,
        allowToLink: false,
        customize: false,
        children: [
          {
            id: "Safety_Risks",
            name: "Safety Risks",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
            children: [
              {
                id: "Safety_Harms",
                name: "Safety Harms",
                level: 3,
                isFolder: true,
                inScope: true,
                addNewItem: false,
                allowToEdit: false,
                allowToLink: false,
                customize: false,
              },
              {
                id: "Hazards",
                name: "Hazards",
                level: 3,
                isFolder: true,
                inScope: true,
                addNewItem: false,
                allowToEdit: false,
                allowToLink: false,
                customize: false,
                children: [
                  {
                    id: "Hazard_Category",
                    name: "Hazard Category",
                    level: 4,
                    isFolder: true,
                    inScope: true,
                    addNewItem: false,
                    allowToEdit: false,
                    allowToLink: false,
                    customize: false,
                  },
                  {
                    id: "System_Hazards",
                    name: "System Hazards",
                    level: 4,
                    isFolder: true,
                    inScope: true,
                    addNewItem: false,
                    allowToEdit: false,
                    allowToLink: false,
                    customize: false,
                  },
                  {
                    id: "Hazardous_Situations",
                    name: "Hazardous Situations",
                    level: 4,
                    isFolder: true,
                    inScope: true,
                    addNewItem: false,
                    allowToEdit: false,
                    allowToLink: false,
                    customize: false,
                  },
                  {
                    id: "Causes",
                    name: "Causes",
                    level: 4,
                    isFolder: true,
                    inScope: true,
                    addNewItem: false,
                    allowToEdit: false,
                    allowToLink: false,
                    customize: false,
                  },
                  {
                    id: "Basic_Events",
                    name: "Basic Events",
                    level: 4,
                    isFolder: true,
                    inScope: true,
                    addNewItem: false,
                    allowToEdit: false,
                    allowToLink: false,
                    customize: false,
                  },
                  {
                    id: "Risk_Controls",
                    name: "Risk Controls",
                    level: 4,
                    isFolder: true,
                    inScope: true,
                    addNewItem: false,
                    allowToEdit: false,
                    allowToLink: false,
                    customize: false,
                  },
                ],
              },
            ],
          },
          {
            id: "Security_Risks",
            name: "Security Risks",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
            children: [
              {
                id: "Security_Harms",
                name: "Security Harms",
                level: 3,
                isFolder: true,
                inScope: true,
                addNewItem: false,
                allowToEdit: false,
                allowToLink: false,
                customize: false,
              },
              {
                id: "Threats",
                name: "Threats",
                level: 3,
                isFolder: true,
                inScope: true,
                addNewItem: false,
                allowToEdit: false,
                allowToLink: false,
                customize: false,
                children: [
                  {
                    id: "Sub_Threats",
                    name: "Threats",
                    level: 4,
                    isFolder: true,
                    inScope: true,
                    addNewItem: false,
                    allowToEdit: false,
                    allowToLink: false,
                    customize: false,
                  },
                  {
                    id: "Vulnerabilities",
                    name: "Vulnerabilities",
                    level: 4,
                    isFolder: true,
                    inScope: true,
                    addNewItem: false,
                    allowToEdit: false,
                    allowToLink: false,
                    customize: false,
                  },
                  {
                    id: "Threat_Attacks",
                    name: "Threat Attacks",
                    level: 4,
                    isFolder: true,
                    inScope: true,
                    addNewItem: false,
                    allowToEdit: false,
                    allowToLink: false,
                    customize: false,
                  },
                ],
              },
            ],
          },
          {
            id: "FMEAs",
            name: "FMEAs",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
            children: [
              {
                id: "Design_FMEA",
                name: "Design FMEA",
                level: 3,
                isFolder: true,
                inScope: true,
                addNewItem: false,
                allowToEdit: false,
                allowToLink: false,
                customize: false,
              },
              {
                id: "Process_FMEA",
                name: "Process FMEA",
                level: 3,
                isFolder: true,
                inScope: true,
                addNewItem: false,
                allowToEdit: false,
                allowToLink: false,
                customize: false,
              },
              {
                id: "Product_Error_Codes",
                name: "Product Error Codes",
                level: 3,
                isFolder: true,
                inScope: true,
                addNewItem: false,
                allowToEdit: false,
                allowToLink: false,
                customize: false,
              },
              {
                id: "Code_Lib",
                name: "Code Lib",
                level: 3,
                isFolder: true,
                inScope: true,
                addNewItem: false,
                allowToEdit: false,
                allowToLink: false,
                customize: false,
              },
            ],
          },
          {
            id: "Evidences",
            name: "Evidences",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
            children: [
              {
                id: "Risk_Benefit_Analysis_Ref",
                name: "Risk Benefit Analysis Ref.",
                level: 3,
                isFolder: true,
                inScope: true,
                addNewItem: false,
                allowToEdit: false,
                allowToLink: false,
                customize: false,
              },
            ],
          },
        ],
      },
    ],
  },
  "User Needs": {
    formData: {
      name: "User Needs",
      id: "TK#",
      status: "Draft",
      version: "",
      owner: "",
    },
    descriptionRichHtml: "<p>A brief instruction about this deliverable.</p>",
    changeScopeTree: [
      {
        id: "Design_Inputs",
        name: "Design Inputs",
        level: 1,
        isFolder: true,
        inScope: true,
        addNewItem: false,
        allowToEdit: false,
        allowToLink: false,
        customize: false,
        children: [
          {
            id: "User_Needs",
            name: "User Needs",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
        ],
      },
    ],
  },
  "Design Input Requirements": {
    formData: {
      name: "Design Input Requirements",
      id: "TK#",
      status: "Draft",
      version: "",
      owner: "",
    },
    descriptionRichHtml: "<p>A brief instruction about this deliverable.</p>",
    changeScopeTree: [
      {
        id: "Design_Inputs",
        name: "Design Inputs",
        level: 1,
        isFolder: true,
        inScope: true,
        addNewItem: false,
        allowToEdit: false,
        allowToLink: false,
        customize: false,
        children: [
          {
            id: "Design_Input_Requirements",
            name: "Design Input Requirements",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
        ],
      },
    ],
  },
  "Test Cases": {
    formData: {
      name: "Test Cases",
      id: "TK#",
      status: "Draft",
      version: "",
      owner: "",
    },
    descriptionRichHtml: "<p>A brief instruction about this deliverable.</p>",
    changeScopeTree: [
      {
        id: "Design_Verifications",
        name: "Design Verifications",
        level: 1,
        isFolder: true,
        inScope: true,
        addNewItem: false,
        allowToEdit: false,
        allowToLink: false,
        customize: false,
        children: [
          {
            id: "Test_Cases",
            name: "Test Cases",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
        ],
      },
    ],
  },
  "Design Control Traceability Matrix": {
    formData: {
      name: "Design Control Traceability Matrix",
      id: "TK#",
      status: "Draft",
      version: "",
      owner: "",
    },
    descriptionRichHtml: "<p>A brief instruction about this deliverable.</p>",
    changeScopeTree: [
      {
        id: "Design_Inputs",
        name: "Design Inputs",
        level: 1,
        isFolder: true,
        inScope: true,
        addNewItem: false,
        allowToEdit: false,
        allowToLink: false,
        customize: false,
        children: [
          {
            id: "User_Needs",
            name: "User Needs",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
          {
            id: "Design_Input_Requirements",
            name: "Design Input Requirements",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
          {
            id: "HW_Subsystem_Requirements",
            name: "HW Subsystem Requirements",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
          {
            id: "SW_Subsystem_Requirements",
            name: "SW Subsystem Requirements",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
        ],
      },
      {
        id: "Design_Outputs",
        name: "Design Outputs",
        level: 1,
        inScope: true,
        addNewItem: false,
        allowToEdit: false,
        allowToLink: false,
        customize: false,
        isFolder: true,
        children: [
          {
            id: "Design_Specifications",
            name: "Design Specifications",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
          {
            id: "DMR_Design_Outputs",
            name: "DMR Design Outputs",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
        ],
      },
      {
        id: "Design_Verifications",
        name: "Design Verifications",
        level: 1,
        inScope: true,
        addNewItem: false,
        allowToEdit: false,
        allowToLink: false,
        customize: false,
        isFolder: true,
        children: [
          {
            id: "System_Verifications",
            name: "System Verifications",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
          {
            id: "SW_Verifications",
            name: "SW Verifications",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
          {
            id: "HW_Verifications",
            name: "HW Verifications",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
        ],
      },
      {
        id: "Design_Validations",
        name: "Design Validations",
        level: 1,
        inScope: true,
        addNewItem: false,
        allowToEdit: false,
        allowToLink: false,
        customize: false,
        isFolder: true,
        children: [
          {
            id: "Validations",
            name: "Validations",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
        ],
      },
      {
        id: "Design_Transfer",
        name: "Design Transfer",
        level: 1,
        inScope: true,
        addNewItem: false,
        allowToEdit: false,
        allowToLink: false,
        customize: false,
        isFolder: true,
        children: [
          {
            id: "IQ_OQ_PQ",
            name: "IQ/OQ/PQ",
            level: 2,
            isFolder: true,
            inScope: true,
            addNewItem: false,
            allowToEdit: false,
            allowToLink: false,
            customize: false,
          },
        ],
      },
    ],
  },
};

interface ScopeProps {
  [x: string]: any;
}

const Scope: React.FC<ScopeProps> = () => {
  const [edit, setEdit] = React.useState(false);
  const [desc, setDesc] = React.useState("A brief instruction about this deliverable.");
  const params = useParams();
  const { pid } = params;

  const [dataSet, setDataSet] = React.useState([]);
  const [formData, setFormData] = React.useState();
  useEffect(() => {
    if (pid) {
      const scopeData = scopeMockData[pid];
      console.log(scopeData);
      if (scopeData) {
        setFormData(scopeData.formData);
        setDesc(scopeData.descriptionRichHtml);
        setDataSet(scopeData.changeScopeTree);
      }
    }
  }, [pid]);
  const { messages } = useIntl();
  const validationSchema = yup.object({
    name: yup.string().required(messages["validation.nameRequired"] as string),
  });
  const handleSave = () => {
    console.log(document.getElementById("submit"));
    document.getElementById("submit").click();
  };

  const handleCancel = () => {
    document.getElementById("cancel").click();
  };

  const cancel = () => {
    setEdit(false);
  };

  return (
    <>
      <Box className="w-full flex justify-between items-center">
        <Box
          className="flex items-center"
          sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
        >
          <IntlMessages id="process.scope.basicInfo" />
        </Box>
        <Box>
          <Box sx={{ display: edit ? "none" : "inline-block" }}>
            <Button
              sx={{ height: "32px", borderRadius: "4px" }}
              variant="outlined"
              color="inherit"
              startIcon={<AppSvgIcon size={16}>{"material-solid:edit"}</AppSvgIcon>}
              onClick={() => {
                setEdit(true);
              }}
            >
              <IntlMessages id="common.edit" />
            </Button>
          </Box>
          <Box sx={{ display: edit ? "inline-block" : "none" }}>
            <Button
              className="text-color-08"
              sx={{
                height: "32px",
                mr: 3,
                borderRadius: "4px",
                border: "solid 1px rgba(0, 0, 0, 0.15)",
                color: textColor["85"],
              }}
              onClick={handleCancel}
              startIcon={
                <AppSvgIcon size={16} color={textColor["65"]}>
                  {"material-solid:cancel"}
                </AppSvgIcon>
              }
              variant="outlined"
            >
              <IntlMessages id="common.cancel" />
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              startIcon={<AppSvgIcon size={16}>{"material-solid:save"}</AppSvgIcon>}
              variant="contained"
            >
              <IntlMessages id="common.save" />
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className="w-full flex justify-between flex-wrap" sx={{ height: "calc(100% - 32px)" }}>
        <Box className="w-full">
          <Formik
            initialValues={{
              name: "System Hazard Analysis",
              id: "TK#",
              status: "Draft",
              version: "",
              owner: "",
              description: "",
              changeScope: [],
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, setValues }) => {
              console.log(data);
              setValues({
                ...data,
              });
              setSubmitting(false);
              setEdit(false);
            }}
          >
            {(formik) => <ScopeForm formData={formData} formik={formik} edit={edit} onCancel={cancel} />}
          </Formik>
          <Box
            className="w-full flex items-center"
            sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
          >
            <IntlMessages id="common.description" />
          </Box>
          <Box
            sx={{
              "& .ck.ck-content": {
                width: "100%",
                height: "250px",
              },
            }}
          >
            <CKEditor
              editor={ClassicEditor}
              data={desc}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDesc(data);
                // console.log({ event, editor, data });
              }}
            />
          </Box>
        </Box>
        <Box className="w-full">
          <Box
            className="w-full flex items-center"
            sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
          >
            <IntlMessages id="process.scope.changeScope" />
          </Box>
          <Box className="w-full flex-col" sx={{ height: "calc(100% - 26px)" }}>
            <ChangeScope edit={edit} width="99%" dataSet={dataSet}></ChangeScope>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Scope;
