import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Chip, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import SimplePopup from "components/SimplePopup";
import * as React from "react";
import { borderColor, borderRadius, chipColor, textColor } from "shared/constants/AppConst";
const Container = styled(Box)(({ theme }) => ({
  ".list-container": {
    width: "300px",
    height: "200px",
    border: `1px solid ${borderColor.dividerColor}`,
    borderRadius: "5px",
  },
  ".list-container-item": {
    width: "100%",
    padding: "8px 4px",
    color: textColor[85],
  },
}));

interface ReleaseModalProps {
  handleClose: () => void;
  open: boolean;
}

export default function ReleaseModal(props: ReleaseModalProps) {
  const { open, handleClose } = props;

  const [stage, setStage] = React.useState<string>("1");
  const [deliverables, setDeliverables] = React.useState<string[]>([
    "Tk#002 Process FMEA V1.0",
    "Tk#003 Process FMEA V1.0",
    "Tk#004 Process FMEA V1.0",
    "Tk#005 Process FMEA V1.0",
    "Tk#006 Process FMEA V1.0",
    "Tk#007 Process FMEA V1.0",
    "Tk#008 Process FMEA V1.0",
    "Tk#009 Process FMEA V1.0",
    "Tk#010 Process FMEA V1.0",
  ]);
  const [feeds, setFeeds] = React.useState<string[]>([
    "FD#123 Fault Tree Analysis should be modified according to SHA.",
  ]);

  const save = () => {};

  return (
    <SimplePopup
      handleClose={handleClose}
      open={open}
      titleId="common.upgrade.stage"
      okTextId="common.promote"
      save={save}
    >
      <Container>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ color: textColor[85] }}>
            <IntlMessages id="common.variant" />:
          </Box>
          <Box sx={{ color: textColor[85], ml: 2 }}>Main</Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
          <Box sx={{ color: textColor[85] }}>
            <IntlMessages id="products.release" />:
          </Box>
          <Box sx={{ color: textColor[85], ml: 2 }}>R2.0</Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
          <Box sx={{ color: textColor[85] }}>
            <IntlMessages id="common.stage" />:
          </Box>
          <Box sx={{ color: textColor[85], ml: 2 }}>
            <Chip
              size="small"
              label="Pre-Production"
              sx={{
                textAlign: "center",
                fontSize: "14px",
                background: "#3366ff1a",
                color: chipColor.stage,
                mx: 2,
                p: 1,
                borderRadius: borderRadius.main,
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
          <Box sx={{ color: textColor[85] }}>
            <IntlMessages id="common.status" />:
          </Box>
          <Box sx={{ color: textColor[85], ml: 2 }}>
            <Chip
              size="small"
              label="Draft"
              sx={{
                textAlign: "center",
                fontSize: "14px",
                background: "#fdedd8",
                color: textColor[85],
                mx: 2,
                p: 1,
                borderRadius: borderRadius.main,
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
          <Box sx={{ color: textColor[85], mr: 2 }}>
            <IntlMessages id="common.promote.stage.to" />:
          </Box>
          <Select
            sx={{ minWidth: "200px", lineHeight: "inherit" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={stage}
            label=""
            onChange={(e) => {
              setStage(e.target.value);
            }}
          >
            <MenuItem value="1">Concept & Requirements</MenuItem>
            <MenuItem value="2">Production</MenuItem>
          </Select>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", my: 4 }}>
          <Box sx={{ color: textColor[85], mr: 2 }}>
            <IntlMessages id="products.not.approved.deliverables" />:
          </Box>
          <AppScrollbar className="list-container">
            {deliverables.map((item, index) => {
              return (
                <Box className="list-container-item" key={item + index}>
                  {item}
                </Box>
              );
            })}
          </AppScrollbar>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Box sx={{ color: textColor[85], mr: 2 }}>
            <IntlMessages id="products.not.resolved.impact.feeds" />:
          </Box>
          <AppScrollbar className="list-container">
            {feeds.map((item, index) => {
              return (
                <Box className="list-container-item" key={item + index}>
                  {item}
                </Box>
              );
            })}
          </AppScrollbar>
        </Box>
      </Container>
    </SimplePopup>
  );
}
