import { Box, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "components/CustomTable";
import FillTaskIcon from "components/Icons/FillTaskIcon";
import { getImpactAnalysisColumns } from "mock/FeedsData";
import React from "react";
import { useNavigate } from "react-router-dom";
import { statusBgColor } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

import AddFeed from "../AddFeed";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const ImpactAnalysisContainer = styled(Box)(({ theme }) => ({}));
const initRows = [
  {
    id: "IF#001",
    content: "Fault Tree Analysis should be modified according to SHA.",
    status: "Open",
    source: "PRD#001-TK#001 System Hazard Analysis (V1.0)",
    scope: "PRD#002-TK#014 Design FMEA (V1.1)",
    type: "df",
    module: "Risk Management",
    processId: "Design FMFA",
    version: "101",
    type1: "sha",
    module1: "Risk Management",
    processId1: "System Hazard Analysis",
    version1: "100",
    time: "09:12:21 3/13/2022",
  },
  {
    id: "IF#002",
    content: "SHA in SDDDB and SDDDC should be updated.",
    status: "Open",
    type: "sha",
    module: "Risk Management",
    processId: "System Hazard Analysis",
    version: "100",
    type1: "sha",
    module1: "Risk Management",
    processId1: "System Hazard Analysis",
    version1: "102",
    source: "PRD#001-TK#001 System Hazard Analysis (V1.0)",
    scope: "PRD#002-TK#031 System Hazard Analysis (V1.2)",
    time: "09:12:21 3/13/2022",
  },
  {
    id: "IF#003",
    content: "Need create a system requirement",
    status: "In Process",
    source: "PRD#001-TK#001 System Hazard Analysis (V1.0)",
    scope: "",
    module: "",
    processId: "",
    version: "",
    type1: "sha",
    module1: "Risk Management",
    processId1: "System Hazard Analysis",
    version1: "100",
    time: "09:12:21 3/13/2022",
  },
];

const ImpactAnalysis = () => {
  const navigate = useNavigate();
  const [openFeedModal, setOpenFeedModal] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState<any[]>(initRows);
  const columns: any[] = getImpactAnalysisColumns(navigate);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = initRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  return (
    <ImpactAnalysisContainer>
      <CustomTable
        toolBar={{
          showSearch: true,
          searchText: searchText,
          requestSearch: requestSearch,
          buttons: [
            {
              id: "common.customize",
              icon: TableHeaderButtonsIconType.SETTING,
              onChange: () => {},
            },
            {
              id: "workspace.button.feed",
              icon: TableHeaderButtonsIconType.ADD,
              type: "text",
              color: "#2e6ccb",
              isBlue: true,
              onChange: () => setOpenFeedModal(true),
            },
          ],
        }}
        columns={columns}
        rows={rows}
      />
      {<AddFeed open={openFeedModal} handleClose={() => setOpenFeedModal(false)} />}
    </ImpactAnalysisContainer>
  );
};

export default ImpactAnalysis;
