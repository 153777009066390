import { Box, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "components/CustomTable";
import FillProductIcon from "components/Icons/FillProductIcon";
import FillTaskIcon from "components/Icons/FillTaskIcon";
import { getOtherTypeColumns } from "mock/FeedsData";
import React from "react";
import { useNavigate } from "react-router-dom";
import { statusBgColor } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

import AddFeed from "../AddFeed";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const ManualContainer = styled(Box)(({ theme }) => ({}));

const initRows = [
  {
    id: "FD#001",
    content: "Import Corp ABC ma file succeed",
    status: "Open",
    productID: "PRD#001",
    tasks: "",
    createdBy: "James",
    source: "Manual",
    creationTime: "09:12:21 3/13/2022",
  },
  {
    id: "FD#002",
    content: "Able to create a kanban for requirement management",
    status: "In Process",
    productID: "PRD#001",
    tasks: "TK#345 System Hazard Analysis(V1.1)",
    createdBy: "Jack",
    type: "sha",
    module: "Risk Management",
    processId: "System Hazard Analysis",
    version: "101",
    source: "Manual",
    creationTime: "09:12:21 3/13/2022",
  },
  {
    id: "FD#004",
    content: "Need create a Fault tree analysis graphic",
    status: "Done",
    productID: "PRD#001",
    type: "und",
    module: "Design Controls",
    processId: "User Needs",
    version: "101",
    tasks: "TK#101 User Needs (V1.1)",
    createdBy: "Aaron",
    source: "Manual",
    creationTime: "09:12:21 3/13/2022",
  },
  {
    id: "FD#005",
    content: "Need test the new feature",
    status: "Open",
    productID: "PRD#001",
    type: "dir",
    module: "Design Controls",
    processId: "Design Input Requirements",
    version: "101",
    tasks: "TK#102 Design Input Requirements (V1.1)",
    createdBy: "Lucy",
    source: "Manual",
    creationTime: "09:12:21 3/13/2022",
  },
  {
    id: "FD#006",
    content: "Need Imporove the performance",
    status: "Open",
    productID: "",
    tasks: "",
    createdBy: "Cergory",
    source: "Manual",
    creationTime: "09:12:21 3/13/2022",
  },
];

const Manual = () => {
  const navigate = useNavigate();
  const [openFeedModal, setOpenFeedModal] = React.useState<boolean>(false);

  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState<any[]>(initRows);
  const columns: any[] = getOtherTypeColumns(navigate);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = initRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  return (
    <ManualContainer>
      <CustomTable
        toolBar={{
          showSearch: true,
          searchText: searchText,
          requestSearch: requestSearch,
          buttons: [
            {
              id: "common.customize",
              icon: TableHeaderButtonsIconType.SETTING,
              onChange: () => {},
            },
            {
              id: "workspace.button.feed",
              icon: TableHeaderButtonsIconType.ADD,
              type: "text",
              color: "#2e6ccb",
              isBlue: true,
              onChange: () => setOpenFeedModal(true),
            },
          ],
        }}
        columns={columns}
        rows={rows}
      />
      {<AddFeed open={openFeedModal} handleClose={() => setOpenFeedModal(false)} />}
    </ManualContainer>
  );
};

export default Manual;
