import AppScrollbar from "@crema/core/AppScrollbar";
import { Box } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import { ProductStageData, StageSet } from "mock/ProductStageData";
import React from "react";
import { boxShadow } from "shared/constants/AppConst";

import ContentHeader from "../components/ContentHeader";
import Delete from "./Delete";
import Detail from "./Detail";
import EditSet, { EditSetProps } from "./EditSet";

const ProductLifecycles = () => {
  const [stageSets, setStageSet] = React.useState<StageSet[]>(ProductStageData);

  return (
    <>
      <ContentHeader titleId="admin.product.lifecycles" />
      <AppScrollbar
        sx={{
          height: "calc(100vh - 104px)",
          padding: (theme) => theme.spacing(5),
        }}
      >
        <Box
          sx={{
            padding: (theme) => theme.spacing(5),
            background: (theme) => theme.palette.background.paper,
            boxShadow: boxShadow.cardBoxShadow,
          }}
        >
          <CustomTable
            columns={[
              {
                id: "order",
                label: "setting.product.column.order",
                minWidth: 150,
                align: "left",
              },
              {
                id: "name",
                label: "setting.product.column.name",
                minWidth: 150,
                align: "left",
              },
              {
                id: "description",
                label: "setting.product.column.description",
                minWidth: 300,
                align: "left",
              },
              {
                id: "system_default",
                label: "setting.product.column.system.default",
                minWidth: 300,
                align: "left",
                format: (value, record) => {
                  return (
                    <Box sx={{ display: "flex" }}>
                      {value ? (
                        <AppSvgIcon size={16} color="#6dc347">
                          material-solid:done
                        </AppSvgIcon>
                      ) : (
                        ""
                      )}
                    </Box>
                  );
                },
              },
              {
                id: "actions",
                label: "setting.product.column.actions",
                minWidth: 300,
                align: "left",
                format: (value, record) => {
                  const props: EditSetProps = {
                    name: record.name,
                    order: record.order,
                    description: record.description,
                  };

                  return (
                    <Box className="flex-row items-center">
                      <Detail
                        stages={record.stages}
                        transitions={record.transitions}
                        nodeDataArray={record.nodeDataArray}
                        linkDataArray={record.linkDataArray}
                      />
                      {record.editable && <EditSet {...props} />}
                      {record.deletable && <Delete />}
                    </Box>
                  );
                },
              },
            ]}
            rows={stageSets}
            addRow={() => {
              console.log("add row");
            }}
            addRowTextId="common.add.new"
            showPage={false}
            maxHeight="auto"
          />
        </Box>
      </AppScrollbar>
    </>
  );
};

export default ProductLifecycles;
