import { Box, Button } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import DrawerHelper from "libs/Drawer/DrawerHelper";
import ModalHelper from "libs/Modal/ModalHelper";
import React from "react";
import { useNavigate } from "react-router-dom";

import DynamicForm from "../DynamicForm";
import HandsontablePage from "../HandsontablePage";
import Page2 from "../Page2";
import Spec from "../Spec";

const SamplePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <h2>Demos</h2>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <Button variant="text" onClick={() => navigate("/p/123/settings")}>
          Settings
        </Button>
        <Button variant="text" onClick={() => navigate("/sample/handsontable")}>
          handsontable
        </Button>
        <Button variant="text" onClick={() => navigate("/sample/Gojs")}>
          Gojs-Diagram
        </Button>
        <Button variant="text" onClick={() => navigate("/sample/TreeView")}>
          TreeView
        </Button>
        <Button variant="text" onClick={() => navigate("/sample/RichTextView")}>
          RichText
        </Button>
        <Button variant="text" onClick={() => navigate("/sample/dockable")}>
          dockable
        </Button>
        <Button variant="text" onClick={() => navigate("/sample/Icons")}>
          Icons
        </Button>
        <Button variant="text" onClick={() => navigate("/sample/ckeditor")}>
          CKEditor
        </Button>
        <Button variant="text" onClick={() => DrawerHelper.show("dynamic form", <DynamicForm />, { size: "xl" })}>
          {/* onClick={() => navigate("/sample/DynamicForm")} */}
          DynamicForm
        </Button>
        <Button variant="text" onClick={() => navigate("/sample/DynamicForm")} >
          DynamicForm
        </Button>
        <Button variant="text" onClick={() => ModalHelper.create("static Modal", <Page2 />)}>
          ModalHelper.show
        </Button>
        <Button variant="text" onClick={() => DrawerHelper.show("static Drawer", <HandsontablePage />, { size: "xl" })}>
          DrawerHelper.show HandsontablePage
        </Button>
        <Button variant="text" onClick={() => DrawerHelper.show("static Drawer", <Page2 />, { size: "xl" })}>
          DrawerHelper.show Page2
        </Button>
        <Button variant="text" onClick={() => DrawerHelper.show("static Drawer", <Spec />, { size: "xl" })}>
          Spec
        </Button>
      </Box>
      <AppSvgIcon color="action">material-outline:notifications</AppSvgIcon>
      <AppSvgIcon color="action">custom-outline:add</AppSvgIcon>

      <Box>
        <Box className="text-secondary text-lg p-16 w-1/2 mr-6">tailwind</Box>
      </Box>
      <Box className="h-md border bg-cover">
        <Spec />
      </Box>
    </>
  );
};

export default SamplePage;
