import Icon from "@mui/material/Icon";
import * as React from "react";

const SendSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.744 3.256a.875.875 0 0 0-.913-.205L3.581 7.426a.874.874 0 0 0-.097 1.606l4.013 2.006 5.128-3.663-3.664 5.13 2.006 4.012a.878.878 0 0 0 1.608-.098l4.375-12.25a.877.877 0 0 0-.206-.913z"
      fill="rgba(0,0,0,0.85)"
      fillRule="nonzero"
    />
  </svg>
);

const SendWhiteSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.744 3.256a.875.875 0 0 0-.913-.205L3.581 7.426a.874.874 0 0 0-.097 1.606l4.013 2.006 5.128-3.663-3.664 5.13 2.006 4.012a.878.878 0 0 0 1.608-.098l4.375-12.25a.877.877 0 0 0-.206-.913z"
      fill="#fff"
      fillRule="nonzero"
    />
  </svg>
);

const SendIcon: React.FunctionComponent<any> = (props) => {
  if (props?.isWhite) {
    return <Icon component={SendWhiteSvg} />;
  } else {
    return <Icon component={SendSvg} />;
  }
};

export default SendIcon;
