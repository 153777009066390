import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import AppsIcon from "@mui/icons-material/Apps";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  Grid,
  List,
  ListItem,
  Popover,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Icons from "components/Icons";
import CustomizeIcon from "components/Icons/CustomizeIcon";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { handleModuleList } from "redux/actions";
import { AppState } from "redux/store";
import { bgColor, borderColor, borderRadius, boxShadow, tabStyle, textColor } from "shared/constants/AppConst";

import Activities from "../Activities";
import DeliverableTaskStatus from "../DeliverableTaskStatus";
import Feeds from "../Feeds";
import HeatMap from "../HeatMap";
import { itemType } from "../QuickStatstics";
import QuickStatstics from "../QuickStatstics";
import Task from "../Task";
import TaskCycleTime from "../TaskCycleTime";
import WorkItemStatus from "../WorkItemStatus";

const starredProducts: itemType[] = [
  {
    id: 1,
    title: "Products",
    icon: <Icons.Products />,
    count: 302,
  },
  {
    id: 2,
    title: "Open Tasks",
    icon: <Icons.OpenTasks />,
    count: 645,
  },
  {
    id: 3,
    title: "Closed Tasks",
    icon: <Icons.ClosedTasks />,
    count: 1438,
  },
  {
    id: 4,
    title: "Open Subtasks",
    icon: <Icons.OpenSubtasks />,
    count: 495,
  },
  {
    id: 5,
    title: "Open Feeds",
    icon: <Icons.OpenFeeds />,
    count: 290,
  },
  {
    id: 6,
    title: "Users",
    icon: <Icons.Users />,
    count: 38,
  },
];

function a11yProps(index: number | string) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const DashboardContainer = styled(Box)(({ theme }) => ({}));

enum dashboardEnum {
  All = "All",
  IP = "Individual Product",
  PG = "Product Group",
}

const Dashboard = () => {
  const location = useLocation();
  useEffect(() => {
    if (location?.pathname.indexOf("tasks") !== -1) {
      setIndex("work.streams.tasks");
    } else if (location?.pathname.indexOf("feeds") !== -1) {
      setIndex("work.streams.feeds");
    } else if (location?.pathname.indexOf("activities") !== -1) {
      setIndex("work.streams.activities");
    }
  }, [location]);
  const { moduleList } = useSelector<AppState, AppState["workspaces"]>(({ workspaces }) => workspaces);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [index, setIndex] = useState("work.streams.tasks");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = () => {
    const element = document.getElementById("target");
    setAnchorEl(element);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeList = (item) => {
    const newList = [...moduleList];
    const currentItem = newList.filter((x) => x.id === item.id)[0];
    if (currentItem) {
      currentItem.checked = !currentItem.checked;
    }
    dispatch(handleModuleList(newList));
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setIndex(newValue);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [value, setValue] = useState(dashboardEnum.All);

  const handleTabChange = (event: React.SyntheticEvent, newValue: dashboardEnum) => {
    setValue(newValue);
  };

  return (
    <DashboardContainer>
      <Box
        className="product-info-bar"
        sx={{
          px: (theme) => theme.spacing(5),
          height: 48,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 0,
          background: (theme) => theme.palette.background.paper,
          borderBottom: `1px solid  ${borderColor.dividerColor}`,
          boxShadow: boxShadow.cardBoxShadow,
          "& .left-wrapper": {
            flex: 1,
          },
        }}
      >
        <Box className="left-wrapper">
          <Tabs
            value={value}
            onChange={handleTabChange}
            sx={{
              ...tabStyle,
              "& .MuiButtonBase-root": {
                minHeight: "48px",
                textTransform: "none",
              },
              borderBottom: 1,
              borderColor: "#e6e6e6",
            }}
            aria-label="basic tabs example"
          >
            <Tab value={dashboardEnum.All} label={<IntlMessages id="common.all" />} {...a11yProps(dashboardEnum.All)} />
            <Tab
              value={dashboardEnum.IP}
              label={<IntlMessages id="common.individual.product" />}
              {...a11yProps(dashboardEnum.IP)}
            />
            <Tab
              value={dashboardEnum.PG}
              label={<IntlMessages id="common.product.group" />}
              {...a11yProps(dashboardEnum.PG)}
            />
          </Tabs>
        </Box>
        <Box className="right-wrapper">
          <Button sx={{ color: textColor[85] }} startIcon={<CustomizeIcon />} variant="outlined">
            <IntlMessages id="common.customize" />
          </Button>
        </Box>
      </Box>
      <AppScrollbar
        sx={{
          maxHeight: "calc(100vh - 105px)",
          background: bgColor.main,
          padding: (theme) => theme.spacing(5),
        }}
      >
        <Box
          sx={{
            padding: (theme) => theme.spacing(5),
            boxShadow: boxShadow.cardBoxShadow,
            background: (theme) => theme.palette.background.paper,
          }}
        >
          <Box
            sx={{
              "div:last-child": {
                marginRight: 0,
              },
            }}
            className="flex items-center justify-content-flex-start"
          >
            {starredProducts.map((item: itemType) => (
              <QuickStatstics key={item.id} item={item} />
            ))}
          </Box>
        </Box>
        <Box sx={{ mt: (theme) => theme.spacing(5) }}>
          <Grid container spacing={5}>
            <Grid item xs={8}>
              <AppScrollbar
                sx={{
                  padding: (theme) => theme.spacing(5),
                  pt: 0,
                  boxShadow: boxShadow.cardBoxShadow,
                  background: (theme) => theme.palette.background.paper,
                  height: "328px",
                }}
              >
                <HeatMap />
              </AppScrollbar>
            </Grid>
            <Grid item xs={4}>
              <AppScrollbar
                sx={{
                  padding: (theme) => theme.spacing(5),
                  pt: 0,
                  boxShadow: boxShadow.cardBoxShadow,
                  background: (theme) => theme.palette.background.paper,
                  height: "328px",
                }}
              >
                <WorkItemStatus />
              </AppScrollbar>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: (theme) => theme.spacing(5) }}>
          <Grid container spacing={5}>
            <Grid item xs={8}>
              <AppScrollbar
                sx={{
                  padding: (theme) => theme.spacing(5),
                  pt: 0,
                  boxShadow: boxShadow.cardBoxShadow,
                  background: (theme) => theme.palette.background.paper,
                  height: "328px",
                }}
              >
                <TaskCycleTime />
              </AppScrollbar>
            </Grid>
            <Grid item xs={4}>
              <AppScrollbar
                sx={{
                  padding: (theme) => theme.spacing(5),
                  pt: 0,
                  boxShadow: boxShadow.cardBoxShadow,
                  background: (theme) => theme.palette.background.paper,
                  height: "328px",
                }}
              >
                <DeliverableTaskStatus />
              </AppScrollbar>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            padding: (theme) => theme.spacing(5),
            pt: 0,
            mt: (theme) => theme.spacing(5),
            boxShadow: boxShadow.cardBoxShadow,
            background: (theme) => theme.palette.background.paper,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box
              className="w-full flex items-center"
              sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
            >
              <h3>
                <IntlMessages id="common.work.streams" />
              </h3>
            </Box>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box className="flex items-center">
                  <Box sx={{ marginRight: "10px" }}>
                    <Stack direction="row" spacing={2}>
                      {moduleList
                        .filter((x) => x.checked)
                        .map((item) => (
                          <Chip
                            sx={{
                              textAlign: "center",
                              fontSize: "14px",
                              borderRadius: borderRadius.main,
                              "& .MuiChip-label": {
                                padding: "7px 12px 7px 12px",
                                color: textColor[85],
                              },
                            }}
                            className="text-color-08"
                            onDelete={() => {
                              handleChangeList(item);
                            }}
                            onClick={handleClick}
                            key={item.id}
                            label={item.label}
                          />
                        ))}
                    </Stack>
                  </Box>
                  <Button
                    sx={{ color: textColor[85], border: `solid 1px ${borderColor.dividerColor}` }}
                    id="target"
                    onClick={handleClick}
                    startIcon={<AppsIcon />}
                    variant="outlined"
                  >
                    <IntlMessages id="workspace.modules" />
                  </Button>
                </Box>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  sx={{ marginTop: "10px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <React.Fragment>
                    <List
                      sx={{
                        "& .MuiListItem-root:hover": {
                          background: "rgba(0, 0, 0, 0.05)",
                        },
                      }}
                    >
                      {moduleList.map((item) => (
                        <ListItem
                          key={item.id}
                          sx={{
                            padding: "2px 10px",
                          }}
                          className="text-color-08"
                        >
                          <Box
                            sx={{
                              cursor: "pointer",
                              color: textColor[85],
                            }}
                            className="flex items-center"
                            onClick={() => {
                              handleChangeList(item);
                            }}
                          >
                            <Checkbox checked={item.checked} sx={{ padding: "0 12px" }} />
                            <Box>{item.label}</Box>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  </React.Fragment>
                </Popover>
              </Box>
            </ClickAwayListener>
          </Box>
          <Box sx={{ width: "100%", borderTop: `solid 1px ${borderColor.dividerColor}` }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Tabs value={index} onChange={handleChangeTab} sx={tabStyle}>
                <Tab value={"work.streams.tasks"} label={<IntlMessages id="workspace.tasks" />} {...a11yProps(0)} />
                <Tab value={"work.streams.feeds"} label={<IntlMessages id="workspace.feeds" />} {...a11yProps(1)} />
                <Tab
                  value={"work.streams.activities"}
                  label={<IntlMessages id="workspace.activities" />}
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <Box sx={{ pt: 2 }}>
              <TabPanel value={index} index={"work.streams.tasks"}>
                <Task />
              </TabPanel>
              <TabPanel value={index} index={"work.streams.feeds"}>
                <Feeds />
              </TabPanel>
              <TabPanel value={index} index={"work.streams.activities"}>
                <Activities />
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </AppScrollbar>
    </DashboardContainer>
  );
};

export default Dashboard;
