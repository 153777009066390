import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import CircularLoading from "components/CircularLoading";
import AddIcon from "components/Icons/AddIcon";
import MyCollapseTable from "components/MyCollapseTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import { ControlOptionModel } from "../RiskPolicy/models";

const Container = styled(Box)(({ theme }) => ({
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell": {
    border: `1px solid  ${borderColor.dividerColor}`,
  },
  ".table-toolbar": {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(0),
    ".table-toolbar-actions": {
      ".refresh-btn": {
        color: textColor[85],
      },
      ".add-button": {
        marginLeft: theme.spacing(2),
      },
    },
  },
}));

const initColumns: Column[] = [
  {
    id: "description",
    label: "common.description",
  },
  {
    id: "abbreviation",
    label: "common.abbreviation",
  },
  {
    id: "value",
    label: "common.value",
  },
  {
    id: "displayOrder",
    label: "setting.product.column.displayOrder",
  },
  {
    id: "summary",
    label: "common.summary",
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    format: (value, record) => {
      return <></>;
    },
  },
];

const createData = async (rowId) => {
  const p = new Promise<ControlOptionModel[]>((resolve, reject) => {
    setTimeout(() => {
      resolve([
        {
          id: `${rowId}01`,
          description: "By Design",
          abbreviation: "DE",
          value: "1",
          displayOrder: 1,
          summary: "",
        },
        {
          id: `${rowId}02`,
          description: "By Protective Measure",
          abbreviation: "PM",
          value: "2",
          displayOrder: 2,
          summary: "",
        },
        {
          id: `${rowId}03`,
          description: "By Information",
          abbreviation: "IN",
          value: "3",
          displayOrder: 3,
          summary: "",
        },
        {
          id: `${rowId}04`,
          description: "By Applying Controls Over Causes",
          abbreviation: "CC",
          value: "4",
          displayOrder: 4,
          summary: "",
        },
      ]);
    }, 200);
  });
  const r = await p;
  return r;
};
interface ControlOptionProps {
  rowId?: number | string;
  editable?: boolean;
  rowsData?: ControlOptionModel[];
  onServerComplete?: (rows: ControlOptionModel[]) => void;
}
const ControlOption = (props: ControlOptionProps) => {
  const { rowId, editable, rowsData, onServerComplete } = props;

  const [columns, setColumns] = useState([...initColumns]);
  const [rows, setRows] = useState<ControlOptionModel[]>(rowsData || []);
  const [loading, setLoading] = useState(true);

  const createDataFn = async (rowId) => {
    setLoading(true);
    const a = await createData(rowId);
    setRows(a);
    onServerComplete && onServerComplete(a);
    setLoading(false);
  };

  useEffect(() => {
    if (!rowsData || rowsData.length === 0) createDataFn(rowId);
    else setLoading(false);
  }, []);

  const handleEditClick = (row: ControlOptionModel) => {
    console.log("ControlOption handleEditClick: ", row);
  };

  const handleDeleteClick = (row: ControlOptionModel) => {
    console.log("ControlOption handleDeleteClick: ", row);
  };

  useEffect(() => {
    const newColumns: Column[] = [];
    initColumns.forEach((c) => {
      if (c.id === "actions" && editable) {
        newColumns.push({
          id: "actions",
          label: "setting.product.column.actions",
          format: (value, record) => {
            return (
              <Box>
                <IconButton
                  sx={{ padding: "2px" }}
                  onClick={() => {
                    handleEditClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:edit"}
                  </AppSvgIcon>
                </IconButton>
                <IconButton
                  sx={{ padding: "2px" }}
                  onClick={() => {
                    handleDeleteClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:delete"}
                  </AppSvgIcon>
                </IconButton>
              </Box>
            );
          },
        });
      } else {
        newColumns.push(c);
      }
    });
    setColumns([...newColumns]);
  }, [editable]);

  const handleAddClick = () => {
    console.log("ControlOption handleAddClick");
  };
  const toolbar = useMemo(() => {
    if (!editable) {
      return <></>;
    } else {
      return (
        <Box className="table-toolbar flex justify-between">
          <Box></Box>
          <Box className="table-toolbar-actions flex justify-center items-center">
            <Button
              className="refresh-btn"
              startIcon={<AppSvgIcon color={textColor["65"]}>{"material-outline:refresh"}</AppSvgIcon>}
            >
              <IntlMessages id="common.refresh" />
            </Button>
            <Button
              variant="text"
              className="add-button"
              color="primary"
              startIcon={<AddIcon isBlue={true} />}
              onClick={handleAddClick}
            >
              <IntlMessages id="common.add" />
            </Button>
          </Box>
        </Box>
      );
    }
  }, [editable]);
  return (
    <>
      <CircularLoading loading={loading}>
        <Container>
          {!loading ? (
            <MyCollapseTable
              toolbar={toolbar}
              columns={columns}
              rows={rows}
              showPage={true}
              collapse={false}
            ></MyCollapseTable>
          ) : (
            <></>
          )}
        </Container>
      </CircularLoading>
    </>
  );
};

export default ControlOption;
