import IntlMessages from "@crema/utility/IntlMessages";
import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import { useEffect, useState } from "react";
import { textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import EditRelationshipModal from "../EditRelationShipModal";
import AddIntegratedModal from "./AddIntegratedModal";

const Container = styled(Box)(({ theme }) => ({
  ".no-data-box": {
    border: "1px solid rgba(0,0,0,0.12)",
    borderTop: "0",
    height: "45px",
  },
}));

const initColumns: Column[] = [
  {
    id: "taskName",
    labelText: "System Products",
  },
  {
    id: "actions",
    labelText: "",
  },
];
interface IntegratedProductModel {
  id: number | string;
  taskName: string;
}
const initRows: IntegratedProductModel[] = [
  {
    id: 1,
    taskName: "SMS Main(R1.0)",
  },
];
interface IntegratedProductsTableProps {
  currentProductName: string;
}

const IntegratedProductsTable = (props: IntegratedProductsTableProps) => {
  const { currentProductName } = props;
  const [columns, setColumns] = useState<Column[]>([]);

  const [open, setOpen] = useState<boolean>(false);

  const [editOpen, setEditOpen] = useState(false);

  const [editData, setEditData] = useState<IntegratedProductModel>();

  const [rows, setRows] = useState([]);

  const handleAddModalClose = () => {
    setOpen(false);
  };

  const handleEditClick = (value) => {
    setEditData(value);
    setEditOpen(true);
    console.log(value);
  };
  const handleDeleteClick = (value) => {
    console.log(value);
  };
  useEffect(() => {
    const nColumns: Column[] = [];
    initColumns.forEach((x) => {
      if (x.id === "actions") {
        nColumns.push({
          ...x,
          format: (value: any, record: any) => {
            return (
              <Box>
                <IconButton
                  sx={{ padding: "2px" }}
                  onClick={() => {
                    handleEditClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:edit"}
                  </AppSvgIcon>
                </IconButton>
                <IconButton
                  sx={{ padding: "2px" }}
                  onClick={() => {
                    handleDeleteClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:delete"}
                  </AppSvgIcon>
                </IconButton>
              </Box>
            );
          },
        });
      } else {
        nColumns.push(x);
      }
    });
    setColumns(nColumns);
    setRows(initRows);
  }, []);
  return (
    <Container>
      <CustomTable
        columns={columns}
        rows={rows}
        showPage={false}
        addRow={() => {
          console.log("add row");
          setOpen(true);
        }}
        addRowTextId="common.new"
      />
      <AddIntegratedModal open={open} currentProductId={1} handleClose={handleAddModalClose} />
      {editOpen && editData && (
        <EditRelationshipModal
          open={editOpen}
          integrationComponentProductId={1}
          integrationComponentProductName={currentProductName}
          integratedProductId={editData.id}
          integratedProductName={editData.taskName}
          handleClose={() => {
            setEditOpen(false);
          }}
        />
      )}
    </Container>
  );
};
export default IntegratedProductsTable;
