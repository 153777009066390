import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { boxShadow, tabStyle } from "shared/constants/AppConst";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Workflow = () => {
  const navigate = useNavigate();
  const [index, setIndex] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };
  return (
    <>
      <Box>
        <Tabs
          value={index}
          onChange={handleChangeTab}
          sx={{
            borderBottom: "1px solid rgba(1,1,1,0.12)",
            boxShadow: boxShadow.cardBoxShadow,
            background: (theme) => theme.palette.background.paper,
            ...tabStyle,
          }}
        >
          <Tab
            onClick={() => {
              navigate("workItem");
            }}
            label={<IntlMessages id="common.work.item" />}
            {...a11yProps(0)}
          />
          <Tab
            onClick={() => {
              navigate("deliverable");
            }}
            label={<IntlMessages id="common.deliverable" />}
            {...a11yProps(1)}
          />
          <Tab
            onClick={() => {
              navigate("feed");
            }}
            label={<IntlMessages id="workspace.button.feed" />}
            {...a11yProps(2)}
          />
          <Tab
            onClick={() => {
              navigate("comment");
            }}
            label={<IntlMessages id="common.comment" />}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <AppScrollbar
        sx={{
          padding: (theme) => theme.spacing(5),
          height: "calc(100vh - 113px)",
        }}
      >
        <Outlet />
      </AppScrollbar>
    </>
  );
};

export default Workflow;
