// const arrowData = {
//   0: "Standard",
//   1: "Backward",
//   2: "Triangle",
//   3: "BackwardTriangle",
//   4: "Boomerang",
//   5: "BackwardBoomerang",
//   6: "SidewaysV",
//   7: "BackwardV",
//   8: "OpenTriangle",
//   9: "BackwardOpenTriangle",
//   10: "OpenTriangleLine",
//   11: "BackwardOpenTriangleLine",
//   12: "OpenTriangleTop",
//   13: "BackwardOpenTriangleTop",
//   14: "OpenTriangleBottom",
//   15: "BackwardOpenTriangleBottom",
//   16: "HalfTriangleTop",
//   17: "BackwardHalfTriangleTop",
//   18: "HalfTriangleBottom",
//   19: "BackwardHalfTriangleBottom",
//   20: "ForwardSemiCircle",
//   21: "BackwardSemiCircle",
//   22: "Feather",
//   23: "BackwardFeather",
//   24: "DoubleFeathers",
//   25: "BackwardDoubleFeathers",
//   26: "TripleFeathers",
//   27: "BackwardTripleFeathers",
//   28: "ForwardSlash",
//   29: "BackSlash",
//   30: "DoubleForwardSlash",
//   31: "DoubleBackSlash",
//   32: "TripleForwardSlash",
//   33: "TripleBackSlash",
//   34: "Fork",
//   35: "BackwardFork",
//   36: "LineFork",
//   37: "BackwardLineFork",
//   38: "CircleFork",
//   39: "BackwardCircleFork",
//   40: "CircleLineFork",
//   41: "BackwardCircleLineFork",
//   42: "Circle",
//   43: "Block",
//   44: "StretchedDiamond",
//   45: "Diamond",
//   46: "Chevron",
//   47: "StretchedChevron",
//   48: "NormalArrow",
//   49: "X",
//   50: "TailedNormalArrow",
//   51: "DoubleTriangle",
//   52: "BigEndArrow",
//   53: "ConcaveTailArrow",
//   54: "RoundedTriangle",
//   55: "SimpleArrow",
//   56: "AccelerationArrow",
//   57: "BoxArrow",
//   58: "TriangleLine",
//   59: "CircleEndedArrow",
//   60: "DynamicWidthArrow",
//   61: "EquilibriumArrow",
//   62: "FastForward",
//   63: "Kite",
//   64: "HalfArrowTop",
//   65: "HalfArrowBottom",
//   66: "OpposingDirectionDoubleArrow",
//   67: "PartialDoubleTriangle",
//   68: "LineCircle",
//   69: "DoubleLineCircle",
//   70: "TripleLineCircle",
//   71: "CircleLine",
//   72: "DiamondCircle",
//   73: "PlusCircle",
//   74: "OpenRightTriangleTop",
//   75: "OpenRightTriangleBottom",
//   76: "Line",
//   77: "DoubleLine",
//   78: "TripleLine",
//   79: "PentagonArrow",
// };
import * as go from "gojs";
const colors = {
  white: "#FFF",
  pink: "rgba(236, 128, 141, 1)",
  black: "#000",
};
export const flowData = {
  nodeData: [
    {
      key: "1_Hazard_Category",
      text: "Hazard Category",
      color: "#FFF",
      group: "group_1",
      loc: "425.15185546875 183.1451416015625",
    },
    {
      key: "1_System_Hazard",
      text: "System Hazard",
      color: "#FFF",
      group: "group_1",
      loc: "608.588134765625 183.1451416015625",
    },
    {
      key: "1_Foreseeable_Scenarios",
      text: "Foreseeable Scenarios",
      color: "rgba(236, 128, 141, 1)",
      stroke: "#000",
      group: "group_1",
      loc: "608.436279296875 243.1451416015625",
    },
    {
      key: "1_Risk_Control",
      text: "System Risk Control",
      color: "#FFF",
      stroke: "#000",
      group: "group_1",
      loc: "430.384033203125 243.1451416015625",
    },
    {
      key: "1_Hazardous_Situation",
      text: "Hazardous Situation",
      color: "rgba(236, 128, 141, 1)",
      stroke: "#000",
      group: "group_1",
      loc: "878.596923828125 243.1451416015625",
    },
    {
      key: "1_P2",
      text: "P2",
      color: "rgba(236, 128, 141, 1)",
      group: "group_1",
      loc: "987.201904296875 180.1451416015625",
    },
    {
      key: "1_Harm",
      text: "Harm",
      color: "rgba(236, 128, 141, 1)",
      group: "group_1",
      loc: "1082.59326171875 243.1451416015625",
    },
    {
      key: "1_Severity",
      text: "Severity",
      color: "rgba(236, 128, 141, 1)",
      group: "group_1",
      loc: "1221.352294921875 238.1451416015625",
    },
    {
      key: "2_Module_Part",
      text: "Module / Part",
      color: "#FFF",
      group: "group_2",
      loc: "206.741455078125 329.69390055338545",
    },
    {
      key: "2_Design_Function",
      text: "Design Function",
      color: "#FFF",
      group: "group_2",
      loc: "352.356689453125 329.69390055338545",
    },
    {
      key: "2_Failure_Mode",
      text: "Failure Mode",
      color: "#FFF",
      group: "group_2",
      loc: "503.413818359375 329.69390055338545",
    },
    {
      key: "2_Failure_Effect",
      text: "Failure Effect",
      color: "#FFF",
      group: "group_2",
      loc: "634.69580078125 329.69390055338545",
    },
    {
      key: "2_Foreseeable_Scenarios",
      text: "Foreseeable Scenarios",
      color: "rgba(236, 128, 141, 1)",
      group: "group_2",
      loc: "797.385986328125 329.69390055338545",
    },
    {
      key: "2_Hazardous_Situation",
      text: "Hazardous Situation",
      color: "rgba(236, 128, 141, 1)",
      group: "group_2",
      loc: "965.985595703125 329.69390055338545",
    },
    {
      key: "2_Harm",
      text: "Harm",
      color: "rgba(236, 128, 141, 1)",
      group: "group_2",
      loc: "1221.98193359375 329.69390055338545",
    },
    {
      key: "2_P2",
      text: "P2",
      color: "rgba(236, 128, 141, 1)",
      group: "group_2",
      loc: "1115.950439453125 395.69390055338545",
    },
    {
      key: "2_Risk_Control",
      text: "Risk Control",
      color: "#FFF",
      group: "group_2",
      loc: "320.196533203125 396.69390055338545",
    },
    {
      key: "2_Cause_of_Failure",
      text: "Cause of Failure",
      color: "#FFF",
      group: "group_2",
      loc: "502.8671875 396.69390055338545",
    },
    { key: "2_P1", text: "P1", color: "#FFF", group: "group_2", loc: "942.732666015625 396.69390055338545" },
    { key: "2_Ph", text: "Ph", color: "#FFF", group: "group_2", loc: "1018.923095703125 521.6939005533854" },
    { key: "2_Risk", text: "Risk", color: "#FFF", group: "group_2", loc: "1089.12353515625 633.6939005533854" },
    {
      key: "2_Severity",
      text: "Severity",
      color: "rgba(236, 128, 141, 1)",
      group: "group_2",
      loc: "1231.174560546875 517.6939005533854",
    },
    {
      key: "group_1",
      isGroup: true,
      text: "System Hazard Analysis",
      fill: "#FFF",
      loc: "809.352294921875 211.6451416015625",
    },
    { key: "group_2", isGroup: true, text: "Design FMEA", fill: "#FFF", loc: "709.674560546875 481.69390055338545" },
  ],
  linkData: [
    {
      key: 1,
      from: "1_System_Hazard",
      to: "1_Hazard_Category",
      dash: [6, 3],
      // text: "sub item of",
      // textColor: "#016fa0",
    },
    {
      key: 2,
      from: "1_Foreseeable_Scenarios",
      to: "1_System_Hazard",
      dash: [6, 3],
      // text: "sub item of",
      // textColor: "#016fa0",
    },
    {
      key: 3,
      from: "1_Foreseeable_Scenarios",
      to: "1_Hazardous_Situation",
      // text: "a cause of",
      // textColor: "#016fa0",
    },
    {
      key: 4,
      from: "1_Hazardous_Situation",
      to: "1_Harm",
      // text: "a cause of",
      // textColor: "#016fa0",
    },
    {
      key: 5,
      from: "1_Harm",
      to: "1_Severity",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
      // text: "attribute",
      // textColor: "#016fa0",
    },
    {
      key: 6,
      from: "1_Hazardous_Situation",
      to: "1_P2",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
      // text: "attribute",
      // textColor: "#016fa0",
    },
    {
      key: 7,
      from: "1_P2",
      to: "1_Harm",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
      // text: "attribute",
      // textColor: "#016fa0",
    },
    {
      key: 8,
      from: "2_Design_Function",
      to: "2_Module_Part",
      dash: [6, 3],
      // text: "sub item of",
      // textColor: "#016fa0",
    },
    {
      key: 9,
      from: "2_Failure_Mode",
      to: "2_Design_Function",
      dash: [6, 3],
      // text: "sub item of",
      // textColor: "#016fa0",
    },
    {
      key: 10,
      from: "2_Cause_of_Failure",
      to: "2_Failure_Mode",
      // text: "a cause of",
      // textColor: "#016fa0",
    },
    {
      key: 11,
      from: "2_Risk_Control",
      to: "2_Cause_of_Failure",
      dash: [6, 3],
      // text: "sub item of",
      // textColor: "#016fa0",
    },
    {
      key: 12,
      from: "2_Failure_Mode",
      to: "2_Failure_Effect",
      // text: "a cause of",
      // textColor: "#016fa0",
    },
    {
      key: 13,
      from: "2_Failure_Effect",
      to: "2_Foreseeable_Scenarios",
      // text: "a cause of",
      // textColor: "#016fa0",
    },
    {
      key: 14,
      from: "2_Foreseeable_Scenarios",
      to: "2_Hazardous_Situation",
      // text: "a cause of",
      // textColor: "#016fa0",
    },
    {
      key: 15,
      from: "2_Hazardous_Situation",
      to: "2_Harm",
      // text: "a cause of",
      // textColor: "#016fa0",
    },
    {
      key: 16,
      from: "2_Cause_of_Failure",
      to: "2_P1",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
      // text: "attribute",
      // textColor: "#016fa0",
    },
    {
      key: 17,
      from: "2_P1",
      to: "2_Ph",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
      // text: "attribute",
      // textColor: "#016fa0",
    },
    {
      key: 18,
      from: "2_P1",
      to: "2_Hazardous_Situation",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
      // text: "attribute",
      // textColor: "#016fa0",
    },
    {
      key: 19,
      from: "2_Hazardous_Situation",
      to: "2_P2",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
      // text: "attribute",
      // textColor: "#016fa0",
    },
    {
      key: 20,
      from: "2_P2",
      to: "2_Ph",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
      // text: "attribute",
      // textColor: "#016fa0",
    },
    {
      key: 21,
      from: "2_Ph",
      to: "2_Risk",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
      // text: "attribute",
      // textColor: "#016fa0",
    },
    {
      key: 22,
      from: "2_P2",
      to: "2_Harm",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
      // text: "attribute",
      // textColor: "#016fa0",
    },
    {
      key: 23,
      from: "2_Harm",
      to: "2_Severity",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
      // text: "attribute",
      // textColor: "#016fa0",
    },
    {
      key: 24,
      from: "2_Severity",
      to: "2_Risk",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
      // text: "attribute",
      // textColor: "#016fa0",
    },
    {
      key: 25,
      from: "2_Foreseeable_Scenarios",
      to: "1_Foreseeable_Scenarios",
      toArrow: "CircleFork",
      color: "#02a7f0",
      arrowColor: "#02a7f0",
      dash: [12, 4, 2, 4],
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      // text: "data source of",
      // textColor: "#016fa0",
    },
    {
      key: 26,
      from: "2_Hazardous_Situation",
      to: "1_Hazardous_Situation",
      toArrow: "CircleFork",
      color: "#02a7f0",
      arrowColor: "#02a7f0",
      dash: [12, 4, 2, 4],
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      // text: "data source of",
      // textColor: "#016fa0",
    },
    {
      key: 27,
      from: "2_Harm",
      to: "1_Harm",
      toArrow: "CircleFork",
      color: "#02a7f0",
      arrowColor: "#02a7f0",
      dash: [12, 4, 2, 4],
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      // text: "data source of",
      // textColor: "#016fa0",
    },
    {
      key: 28,
      from: "1_Risk_Control",
      to: "1_Foreseeable_Scenarios",
      dash: [6, 3],
    },
  ],
  // nodeData: [
  //   // group 1
  //   {
  //     key: "1_Hazard_Category",
  //     text: "Hazard Category",
  //     color: colors.white,
  //     group: "group_1",
  //     //figure: "Ellipse", width: 40, height: 40, stroke: "#000",
  //   },
  //   {
  //     key: "1_System_Hazard",
  //     text: "System Hazard",
  //     color: colors.white,
  //     group: "group_1",
  //     // width: 40, height: 40, stroke: "#000",
  //   },
  //   {
  //     key: "1_Foreseeable_Scenarios",
  //     text: "Foreseeable Scenarios",
  //     color: colors.pink,
  //     stroke: "#000",
  //     group: "group_1",
  //   },
  //   {
  //     key: "1_Hazardous_Situation",
  //     text: "Hazardous Situation",
  //     color: colors.pink,
  //     stroke: colors.black,
  //     group: "group_1",
  //   },
  //   { key: "1_P2", text: "P2", color: colors.pink, group: "group_1" },
  //   { key: "1_Harm", text: "Harm", color: colors.pink, group: "group_1" },
  //   { key: "1_Severity", text: "Severity", color: colors.pink, group: "group_1" },
  //   // group 2
  //   { key: "2_Module_Part", text: "Module / Part", color: colors.white, group: "group_2" },
  //   { key: "2_Design_Function", text: "Design Function", color: colors.white, group: "group_2" },
  //   { key: "2_Failure_Mode", text: "Failure Mode", color: colors.white, group: "group_2" },
  //   { key: "2_Failure_Effect", text: "Failure Effect", color: colors.white, group: "group_2" },
  //   { key: "2_Foreseeable_Scenarios", text: "Foreseeable Scenarios", color: colors.pink, group: "group_2" },
  //   { key: "2_Hazardous_Situation", text: "Hazardous Situation", color: colors.pink, group: "group_2" },
  //   { key: "2_Harm", text: "Harm", color: colors.pink, group: "group_2" },
  //   { key: "2_P2", text: "P2", color: colors.pink, group: "group_2" },
  //   { key: "2_Risk_Control", text: "Risk Control", color: colors.white, group: "group_2" },
  //   { key: "2_Cause_of_Failure", text: "Cause of Failure", color: colors.white, group: "group_2" },
  //   { key: "2_P1", text: "P1", color: colors.white, group: "group_2" },
  //   { key: "2_Ph", text: "Ph", color: colors.white, group: "group_2" },
  //   { key: "2_Risk", text: "Risk", color: colors.white, group: "group_2" },
  //   { key: "2_Severity", text: "Severity", color: colors.pink, group: "group_2" },
  //   { key: "group_1", isGroup: true, text: "System Hazard Analysis", fill: "#FFF" },
  //   { key: "group_2", isGroup: true, text: "Design FMEA", fill: "#FFF" },
  // ],
  // linkData: [
  //   // group 1
  //   {
  //     key: 1,
  //     from: "1_System_Hazard",
  //     to: "1_Hazard_Category",
  //     dash: [6, 3],
  //   },
  //   {
  //     key: 2,
  //     from: "1_Foreseeable_Scenarios",
  //     to: "1_Hazardous_Situation",
  //   },
  //   {
  //     key: 3,
  //     from: "1_Hazardous_Situation",
  //     to: "1_System_Hazard",
  //     dash: [6, 3],
  //   },
  //   {
  //     key: 4,
  //     from: "1_Hazardous_Situation",
  //     to: "1_Harm",
  //   },
  //   {
  //     key: 5,
  //     from: "1_Harm",
  //     to: "1_Severity",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 6,
  //     from: "1_Hazardous_Situation",
  //     to: "1_P2",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 7,
  //     from: "1_P2",
  //     to: "1_Harm",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   // group 2
  //   {
  //     key: 8,
  //     from: "2_Design_Function",
  //     to: "2_Module_Part",
  //     dash: [6, 3],
  //   },
  //   {
  //     key: 9,
  //     from: "2_Failure_Mode",
  //     to: "2_Design_Function",
  //     dash: [6, 3],
  //   },
  //   {
  //     key: 10,
  //     from: "2_Cause_of_Failure",
  //     to: "2_Failure_Mode",
  //   },
  //   {
  //     key: 11,
  //     from: "2_Risk_Control",
  //     to: "2_Cause_of_Failure",
  //     dash: [6, 3],
  //   },
  //   {
  //     key: 12,
  //     from: "2_Failure_Mode",
  //     to: "2_Failure_Effect",
  //   },
  //   {
  //     key: 13,
  //     from: "2_Failure_Effect",
  //     to: "2_Foreseeable_Scenarios",
  //   },
  //   {
  //     key: 14,
  //     from: "2_Foreseeable_Scenarios",
  //     to: "2_Hazardous_Situation",
  //   },
  //   {
  //     key: 15,
  //     from: "2_Hazardous_Situation",
  //     to: "2_Harm",
  //   },
  //   {
  //     key: 16,
  //     from: "2_Cause_of_Failure",
  //     to: "2_P1",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 17,
  //     from: "2_P1",
  //     to: "2_Ph",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 18,
  //     from: "2_P1",
  //     to: "2_Hazardous_Situation",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 19,
  //     from: "2_Hazardous_Situation",
  //     to: "2_P2",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 20,
  //     from: "2_P2",
  //     to: "2_Ph",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 21,
  //     from: "2_Ph",
  //     to: "2_Risk",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 22,
  //     from: "2_P2",
  //     to: "2_Harm",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 23,
  //     from: "2_Harm",
  //     to: "2_Severity",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 24,
  //     from: "2_Severity",
  //     to: "2_Risk",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },

  //   // 2 - 1
  //   {
  //     key: 25,
  //     from: "2_Foreseeable_Scenarios",
  //     to: "1_Foreseeable_Scenarios",
  //     toArrow: "Diamond",
  //     color: "#02a7f0",
  //     arrowColor: "#02a7f0",
  //     dash: [12, 4, 2, 4],
  //     routing: go.Link.Normal,
  //   },
  //   {
  //     key: 26,
  //     from: "2_Hazardous_Situation",
  //     to: "1_Hazardous_Situation",
  //     toArrow: "Diamond",
  //     color: "#02a7f0",
  //     arrowColor: "#02a7f0",
  //     dash: [12, 4, 2, 4],
  //     routing: go.Link.Normal,
  //   },
  //   {
  //     key: 27,
  //     from: "2_Harm",
  //     to: "1_Harm",
  //     toArrow: "Diamond",
  //     color: "#02a7f0",
  //     arrowColor: "#02a7f0",
  //     dash: [12, 4, 2, 4],
  //     routing: go.Link.Normal,
  //   },
  // ],
};

export const flowData2 = {
  // nodeData: [
  //   {
  //     key: "1_Hazard_Category",
  //     text: "Hazard Category",
  //     color: "#FFF",
  //     group: "group_1",
  //     loc: "272.15185546875 -111.8548583984375",
  //   },
  //   {
  //     key: "1_System_Hazard",
  //     text: "System Hazard",
  //     color: "#FFF",
  //     group: "group_1",
  //     loc: "427.588134765625 -111.8548583984375",
  //   },
  //   {
  //     key: "1_Foreseeable_Scenarios",
  //     text: "Foreseeable Scenarios",
  //     color: "rgba(236, 128, 141, 1)",
  //     stroke: "#000",
  //     group: "group_1",
  //     loc: "187.436279296875 -18.8548583984375",
  //   },
  //   {
  //     key: "1_Hazardous_Situation",
  //     text: "Hazardous Situation",
  //     color: "rgba(236, 128, 141, 1)",
  //     stroke: "#000",
  //     group: "group_1",
  //     loc: "451.596923828125 -18.8548583984375",
  //   },
  //   {
  //     key: "1_P2",
  //     text: "P2",
  //     color: "rgba(236, 128, 141, 1)",
  //     group: "group_1",
  //     loc: "632.201904296875 -87.8548583984375",
  //   },
  //   {
  //     key: "1_Harm",
  //     text: "Harm",
  //     color: "rgba(236, 128, 141, 1)",
  //     group: "group_1",
  //     loc: "730.59326171875 -13.8548583984375",
  //   },
  //   {
  //     key: "1_Severity",
  //     text: "Severity",
  //     color: "rgba(236, 128, 141, 1)",
  //     group: "group_1",
  //     loc: "861.352294921875 -13.8548583984375",
  //   },
  //   {
  //     key: "2_Module_Part",
  //     text: "Module / Part",
  //     color: "#FFF",
  //     group: "group_2",
  //     loc: "98.741455078125 326.69390055338545",
  //   },
  //   {
  //     key: "2_Design_Function",
  //     text: "Design Function",
  //     color: "#FFF",
  //     group: "group_2",
  //     loc: "262.356689453125 326.69390055338545",
  //   },
  //   {
  //     key: "2_Failure_Mode",
  //     text: "Failure Mode",
  //     color: "#FFF",
  //     group: "group_2",
  //     loc: "442.413818359375 326.69390055338545",
  //   },
  //   {
  //     key: "2_Failure_Effect",
  //     text: "Failure Effect",
  //     color: "#FFF",
  //     group: "group_2",
  //     loc: "586.69580078125 326.69390055338545",
  //   },
  //   {
  //     key: "2_Foreseeable_Scenarios",
  //     text: "Foreseeable Scenarios",
  //     color: "rgba(236, 128, 141, 1)",
  //     group: "group_2",
  //     loc: "768.385986328125 326.69390055338545",
  //   },
  //   {
  //     key: "2_Hazardous_Situation",
  //     text: "Hazardous Situation",
  //     color: "rgba(236, 128, 141, 1)",
  //     group: "group_2",
  //     loc: "954.985595703125 326.69390055338545",
  //   },
  //   {
  //     key: "2_Harm",
  //     text: "Harm",
  //     color: "rgba(236, 128, 141, 1)",
  //     group: "group_2",
  //     loc: "1210.98193359375 326.69390055338545",
  //   },
  //   {
  //     key: "2_P2",
  //     text: "P2",
  //     color: "rgba(236, 128, 141, 1)",
  //     group: "group_2",
  //     loc: "1095.950439453125 445.69390055338545",
  //   },
  //   {
  //     key: "2_Risk_Control",
  //     text: "Risk Control",
  //     color: "#FFF",
  //     group: "group_2",
  //     loc: "259.196533203125 445.69390055338545",
  //   },
  //   {
  //     key: "2_Cause_of_Failure",
  //     text: "Cause of Failure",
  //     color: "#FFF",
  //     group: "group_2",
  //     loc: "441.8671875 445.69390055338545",
  //   },
  //   {
  //     key: "2_P1",
  //     text: "P1",
  //     color: "#FFF",
  //     group: "group_2",
  //     loc: "589.732666015625 445.69390055338545",
  //   },
  //   {
  //     key: "2_Ph",
  //     text: "Ph",
  //     color: "#FFF",
  //     group: "group_2",
  //     loc: "833.923095703125 485.69390055338545",
  //   },
  //   {
  //     key: "2_Risk",
  //     text: "Risk",
  //     color: "#FFF",
  //     group: "group_2",
  //     loc: "1097.12353515625 529.6939005533854",
  //   },
  //   {
  //     key: "2_Severity",
  //     text: "Severity",
  //     color: "rgba(236, 128, 141, 1)",
  //     group: "group_2",
  //     loc: "1219.174560546875 529.6939005533854",
  //   },
  //   {
  //     key: "group_1",
  //     isGroup: true,
  //     text: "System Hazard Analysis",
  //     fill: "#FFF",
  //     loc: "502.28857421875 -62.8548583984375",
  //   },
  //   {
  //     key: "group_2",
  //     isGroup: true,
  //     text: "Design FMEA",
  //     fill: "#FFF",
  //     loc: "649.674560546875 428.1939005533854",
  //   },
  // ],
  // linkData: [
  //   {
  //     key: 1,
  //     from: "1_System_Hazard",
  //     to: "1_Hazard_Category",
  //     dash: [6, 3],
  //   },
  //   {
  //     key: 2,
  //     from: "1_Foreseeable_Scenarios",
  //     to: "1_Hazardous_Situation",
  //   },
  //   {
  //     key: 3,
  //     from: "1_Hazardous_Situation",
  //     to: "1_System_Hazard",
  //     dash: [6, 3],
  //   },
  //   {
  //     key: 4,
  //     from: "1_Hazardous_Situation",
  //     to: "1_Harm",
  //   },
  //   {
  //     key: 5,
  //     from: "1_Harm",
  //     to: "1_Severity",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 6,
  //     from: "1_Hazardous_Situation",
  //     to: "1_P2",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 7,
  //     from: "1_P2",
  //     to: "1_Harm",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 8,
  //     from: "2_Design_Function",
  //     to: "2_Module_Part",
  //     dash: [6, 3],
  //   },
  //   {
  //     key: 9,
  //     from: "2_Failure_Mode",
  //     to: "2_Design_Function",
  //     dash: [6, 3],
  //   },
  //   {
  //     key: 10,
  //     from: "2_Cause_of_Failure",
  //     to: "2_Failure_Mode",
  //   },
  //   {
  //     key: 11,
  //     from: "2_Risk_Control",
  //     to: "2_Cause_of_Failure",
  //     dash: [6, 3],
  //   },
  //   {
  //     key: 12,
  //     from: "2_Failure_Mode",
  //     to: "2_Failure_Effect",
  //   },
  //   {
  //     key: 13,
  //     from: "2_Failure_Effect",
  //     to: "2_Foreseeable_Scenarios",
  //   },
  //   {
  //     key: 14,
  //     from: "2_Foreseeable_Scenarios",
  //     to: "2_Hazardous_Situation",
  //   },
  //   {
  //     key: 15,
  //     from: "2_Hazardous_Situation",
  //     to: "2_Harm",
  //   },
  //   {
  //     key: 16,
  //     from: "2_Cause_of_Failure",
  //     to: "2_P1",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 17,
  //     from: "2_P1",
  //     to: "2_Ph",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 18,
  //     from: "2_P1",
  //     to: "2_Hazardous_Situation",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 19,
  //     from: "2_Hazardous_Situation",
  //     to: "2_P2",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 20,
  //     from: "2_P2",
  //     to: "2_Ph",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 21,
  //     from: "2_Ph",
  //     to: "2_Risk",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 22,
  //     from: "2_P2",
  //     to: "2_Harm",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 23,
  //     from: "2_Harm",
  //     to: "2_Severity",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 24,
  //     from: "2_Severity",
  //     to: "2_Risk",
  //     toArrow: "Diamond",
  //     color: "#7f7f7f",
  //     arrowColor: "#7f7f7f",
  //     dash: [8, 4, 2, 4],
  //   },
  //   {
  //     key: 25,
  //     from: "2_Foreseeable_Scenarios",
  //     to: "1_Foreseeable_Scenarios",
  //     toArrow: "CircleFork",
  //     color: "#02a7f0",
  //     arrowColor: "#02a7f0",
  //     dash: [12, 4, 2, 4],
  //     routing: {
  //       class: "go.EnumValue",
  //       classType: "Link",
  //       name: "Normal",
  //     },
  //   },
  //   {
  //     key: 26,
  //     from: "2_Hazardous_Situation",
  //     to: "1_Hazardous_Situation",
  //     toArrow: "CircleFork",
  //     color: "#02a7f0",
  //     arrowColor: "#02a7f0",
  //     dash: [12, 4, 2, 4],
  //     routing: {
  //       class: "go.EnumValue",
  //       classType: "Link",
  //       name: "Normal",
  //     },
  //   },
  //   {
  //     key: 27,
  //     from: "2_Harm",
  //     to: "1_Harm",
  //     toArrow: "CircleFork",
  //     color: "#02a7f0",
  //     arrowColor: "#02a7f0",
  //     dash: [12, 4, 2, 4],
  //     routing: {
  //       class: "go.EnumValue",
  //       classType: "Link",
  //       name: "Normal",
  //     },
  //   },
  // ],
  nodeData: [
    // group 1
    {
      key: "1_Hazard_Category",
      text: "Hazard Category",
      color: colors.white,
      group: "group_1",
      //figure: "Ellipse", width: 40, height: 40, stroke: "#000",
    },
    {
      key: "1_System_Hazard",
      text: "System Hazard",
      color: colors.white,
      group: "group_1",
      // width: 40, height: 40, stroke: "#000",
    },
    {
      key: "1_Foreseeable_Scenarios",
      text: "Foreseeable Scenarios",
      color: colors.pink,
      stroke: "#000",
      group: "group_1",
    },
    {
      key: "1_Risk_Control",
      text: "System Risk Control",
      color: "#FFF",
      stroke: "#000",
      group: "group_1",
    },
    {
      key: "1_Hazardous_Situation",
      text: "Hazardous Situation",
      color: colors.pink,
      stroke: colors.black,
      group: "group_1",
    },
    { key: "1_P2", text: "P2", color: colors.pink, group: "group_1" },
    { key: "1_Harm", text: "Harm", color: colors.pink, group: "group_1" },
    { key: "1_Severity", text: "Severity", color: colors.pink, group: "group_1" },
    // group 2
    { key: "2_Module_Part", text: "Module / Part", color: colors.white, group: "group_2" },
    { key: "2_Design_Function", text: "Design Function", color: colors.white, group: "group_2" },
    { key: "2_Failure_Mode", text: "Failure Mode", color: colors.white, group: "group_2" },
    { key: "2_Failure_Effect", text: "Failure Effect", color: colors.white, group: "group_2" },
    { key: "2_Foreseeable_Scenarios", text: "Foreseeable Scenarios", color: colors.pink, group: "group_2" },
    { key: "2_Hazardous_Situation", text: "Hazardous Situation", color: colors.pink, group: "group_2" },
    { key: "2_Harm", text: "Harm", color: colors.pink, group: "group_2" },
    { key: "2_P2", text: "P2", color: colors.pink, group: "group_2" },
    { key: "2_Risk_Control", text: "Risk Control", color: colors.white, group: "group_2" },
    { key: "2_Cause_of_Failure", text: "Cause of Failure", color: colors.white, group: "group_2" },
    { key: "2_P1", text: "P1", color: colors.white, group: "group_2" },
    { key: "2_Ph", text: "Ph", color: colors.white, group: "group_2" },
    { key: "2_Risk", text: "Risk", color: colors.white, group: "group_2" },
    { key: "2_Severity", text: "Severity", color: colors.pink, group: "group_2" },
    { key: "group_1", isGroup: true, text: "System Hazard Analysis", fill: "#FFF" },
    { key: "group_2", isGroup: true, text: "Design FMEA", fill: "#FFF" },
  ],
  linkData: [
    // group 1
    {
      key: 1,
      from: "1_System_Hazard",
      to: "1_Hazard_Category",
      dash: [6, 3],
    },
    {
      key: 2,
      from: "1_Foreseeable_Scenarios",
      to: "1_Hazardous_Situation",
    },
    {
      key: 3,
      from: "1_Hazardous_Situation",
      to: "1_System_Hazard",
      dash: [6, 3],
    },
    {
      key: 4,
      from: "1_Hazardous_Situation",
      to: "1_Harm",
    },
    {
      key: 5,
      from: "1_Harm",
      to: "1_Severity",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 6,
      from: "1_Hazardous_Situation",
      to: "1_P2",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 7,
      from: "1_P2",
      to: "1_Harm",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    // group 2
    {
      key: 8,
      from: "2_Design_Function",
      to: "2_Module_Part",
      dash: [6, 3],
    },
    {
      key: 9,
      from: "2_Failure_Mode",
      to: "2_Design_Function",
      dash: [6, 3],
    },
    {
      key: 10,
      from: "2_Cause_of_Failure",
      to: "2_Failure_Mode",
    },
    {
      key: 11,
      from: "2_Risk_Control",
      to: "2_Cause_of_Failure",
      dash: [6, 3],
    },
    {
      key: 12,
      from: "2_Failure_Mode",
      to: "2_Failure_Effect",
    },
    {
      key: 13,
      from: "2_Failure_Effect",
      to: "2_Foreseeable_Scenarios",
    },
    {
      key: 14,
      from: "2_Foreseeable_Scenarios",
      to: "2_Hazardous_Situation",
    },
    {
      key: 15,
      from: "2_Hazardous_Situation",
      to: "2_Harm",
    },
    {
      key: 16,
      from: "2_Cause_of_Failure",
      to: "2_P1",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 17,
      from: "2_P1",
      to: "2_Ph",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 18,
      from: "2_P1",
      to: "2_Hazardous_Situation",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 19,
      from: "2_Hazardous_Situation",
      to: "2_P2",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 20,
      from: "2_P2",
      to: "2_Ph",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 21,
      from: "2_Ph",
      to: "2_Risk",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 22,
      from: "2_P2",
      to: "2_Harm",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 23,
      from: "2_Harm",
      to: "2_Severity",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 24,
      from: "2_Severity",
      to: "2_Risk",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },

    // 2 - 1
    {
      key: 25,
      from: "2_Foreseeable_Scenarios",
      to: "1_Foreseeable_Scenarios",
      toArrow: "CircleFork",
      color: "#02a7f0",
      arrowColor: "#02a7f0",
      dash: [12, 4, 2, 4],
      routing: go.Link.Normal,
    },
    {
      key: 26,
      from: "2_Hazardous_Situation",
      to: "1_Hazardous_Situation",
      toArrow: "CircleFork",
      color: "#02a7f0",
      arrowColor: "#02a7f0",
      dash: [12, 4, 2, 4],
      routing: go.Link.Normal,
    },
    {
      key: 27,
      from: "2_Harm",
      to: "1_Harm",
      toArrow: "CircleFork",
      color: "#02a7f0",
      arrowColor: "#02a7f0",
      dash: [12, 4, 2, 4],
      routing: go.Link.Normal,
    },
    {
      key: 28,
      from: "1_Foreseeable_Scenarios",
      to: "1_Risk_Control",
      dash: [6, 3],
    },
  ],
};
// eslint-disable-next-line import/no-anonymous-default-export
