import {
  NAV_COLLAPSED,
  SET_INITIAL_PATH,
  SettingsActionTypes,
  TOGGLE_NAV_COLLAPSED,
  UPDATE_MODULE_ID,
  UPDATE_PRODUCT_MODULE_ID,
} from "../../types/actions/Settings.action";
export const toggleNavCollapsed = () => ({ type: TOGGLE_NAV_COLLAPSED });
export const onNavCollapsed = () => ({ type: NAV_COLLAPSED });

export const setInitialPath = (initialPath: string): SettingsActionTypes => ({
  type: SET_INITIAL_PATH,
  initialPath,
});

export const handleSelectedModuleIdAction = (moduleId: string): SettingsActionTypes => ({
  type: UPDATE_MODULE_ID,
  moduleId,
});

export const handleProductSettingSelectedModuleIdAction = (moduleId: string): SettingsActionTypes => ({
  type: UPDATE_PRODUCT_MODULE_ID,
  moduleId,
});
