import {
  UPDATE_ACTIVITIES_TYPE,
  UPDATE_DETAIL_MENU,
  UPDATE_FEEDS_TYPE,
  UPDATE_MODULE_LIST,
  WorkspacesActionTypes,
} from "../../types/actions/Workspaces.action";
import { Workspace } from "../../types/models/Workspaces";

const initialWorkspaces: Workspace = {
  moduleList: [
    // {
    //   id: 1,
    //   checked: false,
    //   label: "Requirement Management",
    //   icon: "/assets/images/file.svg",
    // },
    {
      id: 5,
      checked: false,
      label: "Design Controls",
      icon: "/assets/images/file.svg",
    },
    {
      id: 2,
      checked: false,
      label: "Risk Management",
      icon: "/assets/images/file.svg",
    },
    {
      id: 3,
      checked: false,
      label: "Cybersecurity Management",
      icon: "/assets/images/file.svg",
    },
    {
      id: 4,
      checked: false,
      label: "Assurance Case Management",
      icon: "/assets/images/data.svg",
    },
    {
      id: 6,
      checked: false,
      label: "Production Management",
      icon: "/assets/images/data.svg",
    },
    {
      id: 7,
      checked: false,
      label: "Index File Management",
      icon: "/assets/images/data.svg",
    },
  ],
  feedsType: 0,
  activitiesType: 0,
  workspacesDetailMenu: "5",
};

const Workspaces = (state = initialWorkspaces, action: WorkspacesActionTypes): Workspace => {
  switch (action.type) {
    case UPDATE_MODULE_LIST:
      return {
        ...state,
        moduleList: action.moduleList,
      };
    case UPDATE_FEEDS_TYPE:
      console.log(action);
      return {
        ...state,
        feedsType: action.feedsType,
      };
    case UPDATE_ACTIVITIES_TYPE:
      return {
        ...state,
        activitiesType: action.activitiesType,
      };
    case UPDATE_DETAIL_MENU:
      return {
        ...state,
        workspacesDetailMenu: action.workspacesDetailMenu,
      };
    default:
      return state;
  }
};

export default Workspaces;
