import { Box } from "@mui/material";
import { styled } from "@mui/styles";
import AppSvgIcon from "components/AppSvgIcon";
import MyTreeTable from "components/MyTreeTable";
import { ProductStage } from "mock/ProductStageData";
import React from "react";
import { textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

const StageTableContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  "& .MuiTableCell-root": {
    border: "1px solid rgba(0,0,0,0.12)",
  },
  "&.stage-table-container .MuiIconButton-root": {
    top: 0,
    right: 0,
  },
}));

interface StageProps {
  stages: ProductStage[];
}

const StageTable: React.FC<StageProps> = ({ stages }) => {
  const columns: Column[] = [
    {
      id: "name",
      label: "common.name",
      minWidth: 300,
      align: "left",
    },
    {
      id: "displayOrder",
      label: "setting.product.column.displayOrder",
      minWidth: 100,
      align: "left",
    },
    {
      id: "approved",
      label: "setting.product.column.make.release.approved",
      minWidth: 100,
      align: "left",
    },
    {
      id: "actions",
      label: "setting.product.column.actions",
      minWidth: 50,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex place-content-center">
            <AppSvgIcon color={textColor["65"]} size="20px" className="mr-6">
              material-solid:add_circle
            </AppSvgIcon>
            <AppSvgIcon color={textColor["65"]} size="20px" className="mr-6">
              material-solid:edit
            </AppSvgIcon>
            <AppSvgIcon color={textColor["65"]} size="20px" className="mr-6">
              material-solid:delete
            </AppSvgIcon>
          </Box>
        );
      },
    },
  ];

  return (
    <StageTableContainer className="stage-table-container">
      <Box className="w-full">
        <MyTreeTable columns={columns} rows={stages} showPage={false} maxHeight="500px" />
      </Box>
    </StageTableContainer>
  );
};

export default StageTable;
