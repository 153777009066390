import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import CircularLoading from "components/CircularLoading";
import AddIcon from "components/Icons/AddIcon";
import MyCollapseTable from "components/MyCollapseTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import { AcceptanceModel, RuleEnum } from "../RiskPolicy/models";
import CheckboxRadioGroup, { CheckboxGroupOutputModel, GroupItem } from "./CheckboxRadioGroup";

const Container = styled(Box)(({ theme }) => ({
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell": {
    border: `1px solid  ${borderColor.dividerColor}`,
  },
  ".table-toolbar": {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(0),
    ".table-toolbar-actions": {
      ".refresh-btn": {
        color: textColor[85],
      },
      ".add-button": {
        marginLeft: theme.spacing(2),
      },
    },
  },
}));

const initColumns: Column[] = [
  {
    id: "description",
    label: "common.description",
  },
  {
    id: "abbreviation",
    label: "common.abbreviation",
  },
  {
    id: "minValue",
    label: "common.minValue",
  },
  {
    id: "maxValue",
    label: "common.maxValue",
  },
  {
    id: "displayOrder",
    label: "setting.product.column.displayOrder",
  },
  {
    id: "summary",
    label: "common.summary",
  },
  // {
  //   id: "actions",
  //   label: "setting.product.column.actions",
  //   format: (value, record) => {
  //     return <></>;
  //   },
  // },
];

const createData = async (rowId) => {
  const p = new Promise<AcceptanceModel[]>((resolve, reject) => {
    setTimeout(() => {
      resolve([
        {
          id: `${rowId}01`,
          description: "LOW",
          abbreviation: "LOW",
          minValue: "1",
          maxValue: "5",
          displayOrder: 1,
          summary: "",
          statusColor: "#000",
        },
        {
          id: `${rowId}02`,
          description: "Medium",
          abbreviation: "MED",
          minValue: "6",
          maxValue: "10",
          displayOrder: 2,
          summary: "",
          statusColor: "#000",
        },
        {
          id: `${rowId}03`,
          description: "High",
          abbreviation: "HI",
          minValue: "11",
          maxValue: "15",
          displayOrder: 3,
          summary: "",
          statusColor: "#000",
        },
      ]);
    }, 200);
  });
  const r = await p;
  return r;
};
interface AcceptanceProps {
  rowId?: number | string;
  editable?: boolean;
  tabData?: AcceptanceTabData;
  onServerComplete?: (data: AcceptanceTabData) => void;
}
interface AcceptanceTabData {
  rowsData?: AcceptanceModel[];
  groupItems?: GroupItem[];
  acceptanceCheckbox?: CheckboxGroupOutputModel;
}

const Acceptance = (props: AcceptanceProps) => {
  const { rowId, editable, tabData, onServerComplete } = props;

  const [groupItems, setGroupItems] = useState<GroupItem[]>(tabData && tabData.groupItems ? tabData.groupItems : []);
  const [acceptanceCheckbox, setAcceptanceCheckbox] = useState<CheckboxGroupOutputModel>(
    tabData && tabData.acceptanceCheckbox ? tabData.acceptanceCheckbox : {}
  );

  const [columns, setColumns] = useState([...initColumns]);
  const [rows, setRows] = useState<AcceptanceModel[]>(tabData && tabData.rowsData ? tabData.rowsData : []);
  const [loading, setLoading] = useState(true);

  const createDataFn = async (rowId) => {
    setLoading(true);
    const a = await createData(rowId);
    setRows(a);

    const g: GroupItem[] = [
      {
        key: "acceptanceRadio",
        defaultValue: RuleEnum.FormulaAccPhAddSev,
        type: "radio",
        radioList: [
          {
            labelKey: RuleEnum.Manual,
            value: RuleEnum.Manual,
          },
          {
            labelKey: RuleEnum.FormulaAccPhXSev,
            value: RuleEnum.FormulaAccPhXSev,
          },
          {
            labelKey: RuleEnum.FormulaAccPhAddSev,
            value: RuleEnum.FormulaAccPhAddSev,
          },
          {
            labelKey: RuleEnum.LookupTable,
            value: RuleEnum.LookupTable,
          },
        ],
      },
    ];
    const cg = {
      AcceptanceRadio: RuleEnum.FormulaAccPhAddSev,
    };
    setGroupItems(g);
    // setAcceptanceCheckbox(cg);

    onServerComplete &&
      onServerComplete({
        rowsData: a,
        groupItems: g,
        acceptanceCheckbox: cg,
      });
    setLoading(false);
  };

  useEffect(() => {
    if (!tabData || !tabData.rowsData || tabData.rowsData.length === 0) {
      createDataFn(rowId);
    } else setLoading(false);
  }, []);

  useEffect(() => {
    // console.log(acceptanceCheckbox);
  }, [acceptanceCheckbox]);

  const handleAcceptanceCheckboxChange = useCallback(
    (output: CheckboxGroupOutputModel) => {
      setAcceptanceCheckbox(output);
      onServerComplete &&
        onServerComplete({
          rowsData: rows,
          groupItems: groupItems,
          acceptanceCheckbox: output,
        });
    },
    [groupItems, onServerComplete, rows]
  );

  // const handleEditClick = (row: AcceptanceModel) => {
  //   console.log("Acceptance handleEditClick: ", row);
  // };

  // const handleDeleteClick = (row: AcceptanceModel) => {
  //   console.log("Acceptance handleDeleteClick: ", row);
  // };

  // useEffect(() => {
  //   const newColumns: Column[] = [];
  //   initColumns.forEach((c) => {
  //     if (c.id === "actions" && editable) {
  //       newColumns.push({
  //         id: "actions",
  //         label: "setting.product.column.actions",
  //         format: (value, record) => {
  //           return (
  //             <Box>
  //               <IconButton
  //                 sx={{ padding: "2px" }}
  //                 onClick={() => {
  //                   handleEditClick(record);
  //                 }}
  //               >
  //                 <AppSvgIcon size={16} color={textColor["65"]}>
  //                   {"material-solid:edit"}
  //                 </AppSvgIcon>
  //               </IconButton>
  //               <IconButton
  //                 sx={{ padding: "2px" }}
  //                 onClick={() => {
  //                   handleDeleteClick(record);
  //                 }}
  //               >
  //                 <AppSvgIcon size={16} color={textColor["65"]}>
  //                   {"material-solid:delete"}
  //                 </AppSvgIcon>
  //               </IconButton>
  //             </Box>
  //           );
  //         },
  //       });
  //     } else {
  //       newColumns.push(c);
  //     }
  //   });
  //   setColumns([...newColumns]);
  // }, [editable]);

  // const handleAddClick = () => {
  //   console.log("Acceptance handleAddClick");
  // };

  const toolbar = useMemo(() => {
    if (loading) {
      return <></>;
    } else {
      return (
        <Box className="table-toolbar flex justify-between">
          <Box sx={{ padding: "0 8px" }}>
            <CheckboxRadioGroup
              selected={tabData ? tabData.acceptanceCheckbox : undefined}
              groups={groupItems}
              onGroupChange={handleAcceptanceCheckboxChange}
            />
          </Box>
          {/* {editable ? (
            <Box className="table-toolbar-actions flex justify-center items-center">
              <Button
                className="refresh-btn"
                startIcon={<AppSvgIcon color={textColor["65"]}>{"material-outline:refresh"}</AppSvgIcon>}
              >
                <IntlMessages id="common.refresh" />
              </Button>
              <Button
                variant="text"
                className="add-button"
                color="primary"
                startIcon={<AddIcon isBlue={true} />}
                onClick={handleAddClick}
              >
                <IntlMessages id="common.add" />
              </Button>
            </Box>
          ) : (
            <></>
          )} */}
        </Box>
      );
    }
  }, [loading, tabData, groupItems, handleAcceptanceCheckboxChange]);
  return (
    <>
      <CircularLoading loading={loading}>
        <Container>
          {!loading ? (
            <MyCollapseTable
              toolbar={toolbar}
              columns={columns}
              rows={rows}
              showPage={true}
              collapse={false}
            ></MyCollapseTable>
          ) : (
            <></>
          )}
        </Container>
      </CircularLoading>
    </>
  );
};

export default Acceptance;
