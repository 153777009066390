import IntlMessages from "@crema/utility/IntlMessages";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import AddIcon from "components/Icons/AddIcon";
import MyCollapseTable from "components/MyCollapseTable";
import SimplePopup from "components/SimplePopup";
import { Form, Formik, FormikProps, useFormik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";
import { CollapseColumn } from "types/models/Common";
import * as yup from "yup";

import RelationshipTable from "./RelationshipTable";

const Container = styled(Box)(({ theme }) => ({
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell": {
    border: `1px solid  ${borderColor.dividerColor}`,
  },
  ".table-toolbar": {
    padding: theme.spacing(2, 0),
    ".refresh-btn": {
      color: textColor[85],
    },
    ".add-button": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const initColumns: CollapseColumn[] = [
  {
    id: "integrationComponent",
    label: "",
    labelText: "Component Product",
    collapse: true,
  },
  {
    id: "integrated",
    label: "",
    labelText: "System Product",
  },
  {
    id: "actions",
    label: "",
    labelText: "Operations",
  },
];

const getRows = async (integrationComponentProductId, integratedProductId) => {
  const p = new Promise<any[]>((resolve, reject) => {
    setTimeout(() => {
      resolve([
        {
          id: 1,
          integrationComponent: `${integrationComponentProductId}_1`,
          integrated: `${integratedProductId}_1`,
        },
      ]);
    }, 200);
  });
  const r = await p;
  return r;
};

const getTasksByIntegrationComponentProductId = async (productId) => {
  const p = new Promise<any[]>((resolve, reject) => {
    setTimeout(() => {
      resolve([
        {
          id: `${productId}_1`,
          name: "TK#001 System hazard Analysis (V1.0)",
        },
        {
          id: `${productId}_2`,
          name: "TK#100 User Needs (V1.0)",
        },
      ]);
    }, 200);
  });
  const r = await p;
  return r;
};

const getTasksByIntegratedProductId = async (productId) => {
  const p = new Promise<any[]>((resolve, reject) => {
    setTimeout(() => {
      resolve([
        {
          id: `${productId}_1`,
          name: "TK#001 Design FMEA (V1.0)",
        },
        {
          id: `${productId}_2`,
          name: "TK#105 Design Input Requirement (V1.0)",
        },
      ]);
    }, 200);
  });
  const r = await p;
  return r;
};

interface EditRelationshipModalProps {
  open: boolean;
  integrationComponentProductId: number | string;
  integrationComponentProductName?: string;
  integratedProductId: number | string;
  integratedProductName?: string;
  handleClose: () => void;
}

const EditRelationshipModal = (props: EditRelationshipModalProps) => {
  const {
    open,
    integrationComponentProductId,
    integrationComponentProductName,
    integratedProductId,
    integratedProductName,
    handleClose,
  } = props;

  const [initFormData, setInitFormData] = useState({});
  const [columns, setColumns] = useState([...initColumns]);

  const formik = useFormik({
    initialValues: initFormData,
    onSubmit: (data, { setSubmitting, resetForm }) => {
      console.log(data);
      resetForm();
      setSubmitting(false);
    },
  });

  const [tasksByIntegrationComponentProductId, setTasksByIntegrationComponentProductId] = useState([]);
  const [tasksByIntegratedProductId, setTasksByIntegratedProductId] = useState([]);
  const [rows, setRows] = useState([]);

  const handleDeleteClick = (record: any) => {
    console.log("handleDeleteClick", record);
  };

  const getRowsFn = async (integrationComponentProductId, integratedProductId) => {
    const res = await getRows(integrationComponentProductId, integratedProductId);
    setRows(res);
  };

  const getTasksByIntegrationComponentProductIdFn = async (productId) => {
    const res = await getTasksByIntegrationComponentProductId(productId);
    setTasksByIntegrationComponentProductId(res);
  };

  const getTasksByIntegratedProductIdFn = async (productId) => {
    const res = await getTasksByIntegratedProductId(productId);
    setTasksByIntegratedProductId(res);
  };

  useEffect(() => {
    getTasksByIntegrationComponentProductIdFn(integrationComponentProductId);
    getTasksByIntegratedProductIdFn(integratedProductId);
    // set rows
    getRowsFn(integrationComponentProductId, integratedProductId);
  }, []);

  useEffect(() => {
    console.log("integrationComponentProductName", integrationComponentProductName);
    console.log("integratedProductName", integratedProductName);
    const nC: CollapseColumn[] = [];
    initColumns.forEach((x) => {
      if (x.id === "integrationComponent") {
        nC.push({
          ...x,
          label: "",
          labelText: integrationComponentProductName,
          format: (value, record) => {
            const formName = `integrationComponent_${record.id}`;
            // formik.setFieldValue(formName, value);
            return (
              <Box>
                <Select
                  labelId="select-header"
                  label=""
                  sx={{
                    width: "400px",
                  }}
                  defaultValue={value}
                  // value={formik.values[formName]}
                  // labelWidth={100}
                  onChange={(e) => {
                    formik.setFieldValue(formName, e.target.value);
                  }}
                  fullWidth
                >
                  {tasksByIntegrationComponentProductId.map((x) => (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            );
          },
        });
      } else if (x.id === "integrated") {
        nC.push({
          ...x,
          label: "",
          labelText: integratedProductName,
          format: (value, record) => {
            const formName = `integrated_${record.id}`;
            // formik.setFieldValue(formName, value);
            return (
              <Box>
                <Select
                  labelId="select-header"
                  sx={{
                    width: "400px",
                  }}
                  label=""
                  defaultValue={value}
                  // value={formik.values[formName]}
                  // labelWidth={100}
                  onChange={(e) => {
                    formik.setFieldValue(formName, e.target.value);
                  }}
                  fullWidth
                >
                  {tasksByIntegratedProductId.map((x) => (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            );
          },
        });
      } else if (x.id === "actions") {
        nC.push({
          ...x,
          format: (value, record) => {
            return (
              <Box>
                <IconButton
                  sx={{ padding: "2px" }}
                  onClick={() => {
                    handleDeleteClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:delete"}
                  </AppSvgIcon>
                </IconButton>
              </Box>
            );
          },
        });
      } else {
        nC.push(x);
      }
    });
    setColumns(nC);
  }, [
    integrationComponentProductName,
    integratedProductName,
    tasksByIntegrationComponentProductId,
    tasksByIntegratedProductId,
  ]);

  useEffect(() => {
    console.log(formik.values);
  }, [formik]);
  const handleSave = () => {
    console.log();
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  useEffect(() => {
    rows.forEach((x) => {
      const keys = Object.keys(x);
      keys.forEach((k) => {
        formik.setFieldValue(`${k}_${x.id}`, x[k]);
      });
    });
  }, [rows]);

  const handleAddClick = () => {
    console.log("add");
    const nRows = [...rows];
    const nRowsSort = nRows.sort((a, b) => b.id - a.id);
    const maxId = nRowsSort[0].id;
    const newId = maxId + 1;
    nRows.push({
      id: newId,
      integrationComponent: `${integrationComponentProductId}_1`,
      integrated: `${integratedProductId}_1`,
    });

    setRows(nRows);
  };
  const toolbar = (
    <Box className="table-toolbar flex justify-between">
      <Box></Box>
      <Box className="table-toolbar-actions flex justify-center items-center">
        <Button
          variant="text"
          className="add-button"
          color="primary"
          startIcon={<AddIcon isBlue={true} />}
          onClick={handleAddClick}
        >
          <IntlMessages id="common.add" />
        </Button>
      </Box>
    </Box>
  );
  return (
    <Box>
      <form
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        noValidate
        autoComplete="off"
      >
        <SimplePopup
          handleClose={handleCancel}
          open={open}
          save={handleSave}
          titleId="products.relationships.edit.configuration"
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "1300px",
              minWidth: "1300px",
            },
            "& .MuiDialogContent-root": {
              padding: 0,
            },
            "& .edit-configuration": {
              width: "100%",
              padding: "12px 24px",
            },
            "& .module-level-box": {
              width: "100%",
            },
            "& .my_collapse_table_container": {
              ".MuiCollapse-root": {
                background: "#f5f7ff",
              },
              ".MuiIconButton-root": {
                top: 0,
                right: 0,
              },
            },
          }}
        >
          <Box>
            <Box className="flex items-center edit-configuration">
              <Container className="module-level-box">
                <MyCollapseTable
                  toolbar={toolbar}
                  columns={columns}
                  rows={rows}
                  showPage={false}
                  collapse={true}
                  renderChildren={(row) => {
                    return <RelationshipTable formik={formik} rowId={row.id} />;
                  }}
                />
              </Container>
            </Box>
          </Box>
        </SimplePopup>
      </form>
    </Box>
  );
};

export default EditRelationshipModal;
