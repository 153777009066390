import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import doorsPng from "assets/imgs/doors.png";
import interfacesPng from "assets/imgs/interfaces.png";
import polarionPng from "assets/imgs/polarion.png";
import React from "react";

const InterfacesContainer = styled(Box)(({ theme }) => ({
  marginTop: "8px",
  minWidth: "1000px",
}));

interface InterfacesProps {
  rmType: any;
}

const Interfaces: React.FC<InterfacesProps> = (props: InterfacesProps) => {
  const getPage = (type) => {
    switch (type) {
      case "1":
        return <img alt="" src={interfacesPng} />;
      case "2":
        return <img alt="" src={polarionPng} />;
      case "3":
        return <img alt="" src={doorsPng} />;
      default:
        return <img alt="" src={interfacesPng} />;
    }
  };

  return <InterfacesContainer>{getPage(props.rmType)}</InterfacesContainer>;
};

export default Interfaces;
