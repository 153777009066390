import { List } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import Icons from "components/Icons";
import React from "react";

import HorizontalItem from "../HorizontalNav/HorizontalItem";

const AppTopMenu = () => {
  return (
    <>
      <List
        className="navbarNav"
        sx={{
          "& .MuiListItem-root.active": {
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            borderRadius: "4px",
          },
          "& .MuiListItem-root": {
            minHeight: 36,
            padding: "2px 16px",
          },
          "& .MuiListItem-root:first-of-type": {
            padding: "2px",
          },
        }}
      >
        <HorizontalItem
          item={{
            id: "home",
            title: "Home",
            messageId: "",
            type: "item",
            icon: <Icons.HomeIcon sx={{ marginTop: "-12px", fontSize: 24, color: "#fff" }} />,
            // icon: <AppSvgIcon>material-solid:home</AppSvgIcon>,
            url: "/home",
          }}
          nestedLevel={0}
        />
        <HorizontalItem
          item={{
            id: "product",
            title: "Products",
            messageId: "menu.product",
            type: "item",
            icon: "",
            url: "/products",
          }}
          nestedLevel={0}
        />
        <HorizontalItem
          item={{
            id: "dashboard",
            title: "Dashboard",
            messageId: "menu.dashboard",
            type: "item",
            icon: "",
            url: "/dashboard",
          }}
          nestedLevel={0}
        />
        {/* <HorizontalItem
          item={{
            id: "library",
            title: "library",
            messageId: "menu.library",
            type: "item",
            icon: "",
            url: "/library",
          }}
          nestedLevel={0}
        /> */}
        <HorizontalItem
          item={{
            id: "settings",
            title: "settings",
            messageId: "menu.settings",
            type: "item",
            icon: "",
            url: "/admin/general",
          }}
          nestedLevel={0}
        />
      </List>
    </>
  );
};

export default AppTopMenu;
