import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

interface ForwardAndBackwardProps {}

const ForwardAndBackward: React.FC<ForwardAndBackwardProps> = () => {
  const navigate = useNavigate();
  const [backCount, setBackCount] = React.useState<number>(0);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          width: "20px",
          height: "20px",
          background: "#fff",
          p: "3px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          borderTopLeftRadius: "3px",
          borderBottomLeftRadius: "3px",
          cursor: "pointer",
          " & .MuiSvgIcon-root": {
            width: "14px",
          },
        }}
      >
        <ArrowBackIosNewIcon
          onClick={() => {
            setBackCount(backCount + 1);
            navigate(-1);
          }}
        />
      </Box>
      <Box
        sx={{
          width: "20px",
          height: "20px",
          p: "3px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: "16px",
          cursor: backCount === 0 ? "default" : "pointer",
          background: backCount === 0 ? "#fbfbfb" : "#fff",
          opacity: backCount === 0 ? 0.4 : 1,
          border: "1px solid rgba(0, 0, 0, 0.1)",
          borderLeft: "none",
          borderTopRightRadius: "3px",
          borderBottomRightRadius: "3px",
          " & .MuiSvgIcon-root": {
            width: "14px",
          },
        }}
      >
        <ArrowForwardIosIcon
          onClick={() => {
            if (backCount > 0) {
              setBackCount(backCount - 1);
              navigate(1);
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default ForwardAndBackward;
