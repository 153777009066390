import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import Diagram from "./Diagram";

const AssuranceCaseContainer = styled(Box)(({ theme }) => ({
  padding: "8px 0",
  minWidth: "1200px",
  ".details-content": {
    marginTop: "8px",
  },
}));

const AssuranceCase = () => {
  return (
    <AssuranceCaseContainer>
      <Box className="details-content">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Diagram />
        </Box>
      </Box>
    </AssuranceCaseContainer>
  );
};

export default AssuranceCase;
