import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Grid, Typography } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import SimplePopup from "components/SimplePopup";
import * as React from "react";
import { useState } from "react";

import { relationshipData } from "./d";
import Destination from "./Destination";
import ProductRelationshipsDiagram from "./ProductRelationshipsDiagram";
import SaveOptions from "./SaveOptions";

interface SaveAsNewModalProps {
  productName?: string;
  releaseVersion?: string;
  variant?: string;
  handleClose: () => void;
  open: boolean;
}

const SaveAsNewModal = (props: SaveAsNewModalProps) => {
  const { productName, releaseVersion, variant, open, handleClose } = props;
  const [isSync, setIsSync] = useState<boolean>(true);
  const [prefix, setPrefix] = useState<string>("");
  const [relation, setRelation] = useState<any>("3");
  const [type, setType] = useState<any>("3");

  const [relationshipsOpen, setRelationshipsOpen] = useState(false);
  const handleRelationshipsOpen = () => {
    setRelationshipsOpen(!relationshipsOpen);
  };
  const handleSave = () => {
    console.log();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <SimplePopup
      handleClose={handleClose}
      open={open}
      titleId="product.saveAsNew"
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "1300px",
          minWidth: "1300px",
        },
        "& .MuiDialogContent-root": {
          padding: 0,
        },
        "& .top": {
          background: "#FAFAFA",
          padding: "12px 24px",
        },
      }}
    >
      <Box>
        <Box className="top flex items-center">
          <Box className="flex items-center">
            <IntlMessages id="product.saveAsNew.baseProduct" />:
            <Typography className="font-bold" sx={{ marginLeft: "8px" }}>
              {productName}
            </Typography>
          </Box>
          <Box className="flex items-center" sx={{ marginLeft: "24px" }}>
            <IntlMessages id="products.form.base.variant" />:
            <Typography className="font-bold" sx={{ marginLeft: "8px" }}>
              {variant}
            </Typography>
          </Box>
          <Box className="flex items-center" sx={{ marginLeft: "24px" }}>
            <IntlMessages id="product.saveAsNew.baseRelease" />:
            <Typography className="font-bold" sx={{ marginLeft: "8px" }}>
              {releaseVersion}
            </Typography>
          </Box>
        </Box>
        <Grid
          sx={{
            borderTop: "1px solid rgba(0,0,0,0.12)",
            background: "#FAFAFA",
          }}
          container
          spacing={0}
        >
          <Grid item sx={{ padding: "12px", borderRight: "1px solid rgba(0,0,0,0.12)" }} xs={8} md={8}>
            <SaveOptions
              isSync={isSync}
              handleIsSync={setIsSync}
              handlePrefix={setPrefix}
              prefix={prefix}
              handleRelation={setRelation}
              relation={relation}
              handleType={setType}
              type={type}
            />
          </Grid>
          <Grid item sx={{ padding: "12px" }} xs={4} md={4}>
            <Destination destinationProduct={productName} />
          </Grid>
        </Grid>

        <Grid
          sx={{ padding: "12px", borderTop: "1px solid rgba(0,0,0,0.12)", background: "#FAFAFA" }}
          container
          spacing={0}
        >
          <Box className="actions flex items-center justify-between" sx={{ width: "100%" }}>
            <Box>
              <Button
                endIcon={
                  relationshipsOpen ? (
                    <AppSvgIcon color="#2e6ccb">{"material-outline:arrow_upward"}</AppSvgIcon>
                  ) : (
                    <AppSvgIcon color="#2e6ccb">{"material-outline:arrow_downward"}</AppSvgIcon>
                  )
                }
                onClick={handleRelationshipsOpen}
              >
                <IntlMessages id="product.saveAsNew.relationShips" />
              </Button>
            </Box>
            <Box className="flex buttons items-center justify-between" sx={{ width: "200px" }}>
              <Button
                sx={{ height: "32px", borderRadius: "4px" }}
                color="primary"
                variant="contained"
                onClick={() => {
                  handleSave();
                }}
              >
                <IntlMessages id="common.save" />
              </Button>
              <Button
                className="text-color-08"
                sx={{
                  height: "32px",
                  mr: 3,
                  borderRadius: "4px",
                  border: "solid 1px rgba(0, 0, 0, 0.2)",
                }}
                onClick={handleCancel}
                variant="outlined"
              >
                <IntlMessages id="common.cancel" />
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid
          sx={{ padding: "12px", borderTop: "1px solid rgba(0,0,0,0.12)", background: "#FAFAFA" }}
          container
          spacing={0}
        >
          {relationshipsOpen && (
            <ProductRelationshipsDiagram nodeData={relationshipData.nodeData} linkData={relationshipData.linkData} />
          )}
        </Grid>
      </Box>
    </SimplePopup>
  );
};

export default SaveAsNewModal;
