import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Grid, IconButton, InputAdornment } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import MySelectField from "components/MySelectField";
import MyTextField from "components/MyTextField";
import SimplePopup from "components/SimplePopup";
import { Form, Formik } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
// import { blue } from "@mui/material/colors";
import * as yup from "yup";

interface AddTaskProps {
  handleClose: () => void;
  open: boolean;
}

export default function AddTask(props: AddTaskProps) {
  const { open, handleClose } = props;
  const { messages } = useIntl();
  const [statusList] = React.useState([
    {
      id: 1,
      name: "Open",
    },
    {
      id: 2,
      name: "In Process",
    },
    {
      id: 3,
      name: "Finished",
    },
    {
      id: 4,
      name: "Coding",
    },
    {
      id: 5,
      name: "Review",
    },
  ]);
  const [versionList] = React.useState([
    {
      id: 1,
      name: "V1.0",
    },
    {
      id: 2,
      name: "V1.1",
    },
    {
      id: 3,
      name: "V1.2",
    },
    {
      id: 4,
      name: "V1.3",
    },
    {
      id: 5,
      name: "V2.0",
    },
    {
      id: 6,
      name: "V2.1",
    },
    {
      id: 7,
      name: "V2.2",
    },
    {
      id: 8,
      name: "V2.3",
    },
    {
      id: 9,
      name: "V3.0",
    },
    {
      id: 10,
      name: "None ",
    },
  ]);
  const [processList] = React.useState([
    {
      id: 1,
      name: "Risk Mangement > Risk Analysis and Assessment > Top Down Analysis > System Hazard Analysis",
    },
    {
      id: 2,
      name: "Risk Mangement > Risk Analysis and Assessment > Top Down Analysis > Fault Tree Analysis",
    },
  ]);
  const [productList] = React.useState([
    {
      id: 1,
      name: "None",
    },
    {
      id: 2,
      name: "Drug Delivery Device",
    },
    {
      id: 3,
      name: "Drug Delivery Device",
    },
  ]);
  const [projectList] = React.useState([
    {
      id: 1,
      name: "None",
    },
    {
      id: 2,
      name: "Project A",
    },
    {
      id: 3,
      name: "Project B",
    },
  ]);

  const validationSchema = yup.object({
    name: yup.string().max(5, "5435").required(String(messages["validation.emailRequired"])),
    status: yup.string().required(String(messages["validation.emailRequired"])),
  });

  const save = () => {
    const submitDom = document.getElementById("submit");
    submitDom && submitDom.click();
  };

  return (
    <SimplePopup handleClose={handleClose} open={open} titleId="workspace.create.task" save={save}>
      <Formik
        initialValues={{
          id: "",
          name: "",
          status: "",
          description: "",
          version: "",
          process: "",
          product: "",
          project: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          console.log(data);
          handleClose();
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <MyTextField
                  name="name"
                  fullWidth
                  label="workspace.form.name"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          title="Clear"
                          aria-label="Clear"
                          size="small"
                          style={{ visibility: values.name ? "visible" : "hidden" }}
                          onClick={() => {
                            setFieldValue("name", "");
                          }}
                        >
                          <AppSvgIcon size={16} color="action">
                            {"material-outline:clear"}
                          </AppSvgIcon>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <MyTextField name="id" fullWidth label="workspace.form.id" />
              </Grid>
              <Grid item xs={6} md={6}>
                <MySelectField
                  label="workspace.form.status"
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                  }}
                  name="status"
                  fullWidth
                  list={statusList}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <MySelectField
                  label="workspace.form.version"
                  onChange={(e) => {
                    setFieldValue("version", e.target.value);
                  }}
                  name="version"
                  fullWidth
                  list={versionList}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <MyTextField name="description" fullWidth multiline rows="3" label="workspace.form.description" />
              </Grid>
              <Grid item xs={12} md={12}>
                <MySelectField
                  label="workspace.form.process"
                  onChange={(e) => {
                    setFieldValue("process", e.target.value);
                  }}
                  name="process"
                  fullWidth
                  list={processList}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <MySelectField
                  label="workspace.form.product"
                  onChange={(e) => {
                    setFieldValue("product", e.target.value);
                  }}
                  name="product"
                  fullWidth
                  list={productList}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <MySelectField
                  label="workspace.form.project"
                  onChange={(e) => {
                    setFieldValue("project", e.target.value);
                  }}
                  name="project"
                  fullWidth
                  list={projectList}
                />
              </Grid>
              <Grid sx={{ display: "none" }} item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    id="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <IntlMessages id="common.save" />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SimplePopup>
  );
}
