import { Box, Grid } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import MyTextField from "components/MyTextField";
import SimplePopup from "components/SimplePopup";
import { Form, Formik } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { textColor } from "shared/constants/AppConst";
import * as yup from "yup";

interface DialogProps {
  handleClose: () => void;
  open: boolean;
  name: string;
  order: number;
  description: string;
}

const EditSetDialog: React.FC<DialogProps> = ({ handleClose, open, name, order, description }) => {
  const { messages } = useIntl();
  const validationSchema = yup.object({
    name: yup.string().required(String(messages["validation.nameRequired"])),
  });

  return (
    <SimplePopup handleClose={handleClose} save={() => {}} open={open} titleId="setting.product.stage.editSet">
      <Formik
        initialValues={{
          name: name,
          order: order,
          description: description,
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          console.log(data);
          handleClose();
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <MyTextField name="name" fullWidth label="setting.product.column.name" required={true} />
              </Grid>
              <Grid item xs={12} md={12}>
                <MyTextField name="order" fullWidth type="number" label="setting.product.column.order"></MyTextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <MyTextField
                  name="description"
                  fullWidth
                  multiline
                  rows="3"
                  label="setting.product.column.description"
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SimplePopup>
  );
};

export interface EditSetProps {
  name: string;
  order: number;
  description: string;
}

const EditSet: React.FC<EditSetProps> = ({ name, order, description }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const data = { name, order, description, open, handleClose };

  return (
    <Box className=" inline-block">
      <AppSvgIcon
        onClick={handleClickOpen}
        size={16}
        color={textColor["65"]}
        sxStyle={{ cursor: "pointer", mr: "4px" }}
      >
        material-solid:edit
      </AppSvgIcon>
      <EditSetDialog {...data} />
    </Box>
  );
};

export default EditSet;
