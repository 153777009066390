import DeleteIcon from "@mui/icons-material/Delete";
import { Box, styled } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import * as go from "gojs";
import React, { useEffect } from "react";
import { Column } from "types/models/Common";

const Container = styled(Box)(({ theme }) => ({}));

const relationshipTypeRows = [
  {
    code: "effects",
    name: "effects",
    opposite_name: "is effected by",
    description: "",
    rules: "Config",
    default: "",
  },
  {
    code: "assesses",
    name: "assesses",
    opposite_name: "is assessed by",
    description: "",
    rules: "Config",
    default: "",
  },
  {
    code: "relates",
    name: "relates to",
    opposite_name: "is related to",
    description: "",
    rules: "Config",
    default: true,
  },
  {
    code: "leads",
    name: "leads to",
    opposite_name: "is caused by",
    description: "",
    rules: "Config",
    default: false,
  },
  {
    code: "verifies",
    name: "verifies",
    opposite_name: "is verified by",
    description: "",
    rules: "Config",
    default: false,
  },
  {
    code: "duplicates",
    name: "duplicates",
    opposite_name: "is duplicated by",
    description: "",
    rules: "Config",
    default: false,
  },
  {
    code: "depends_on",
    name: "depends on",
    opposite_name: "blocks",
    description: "",
    rules: "Config",
    default: false,
  },
  {
    code: "derived_from",
    name: "is derived from",
    opposite_name: "is derived by",
    description: "",
    rules: "Config",
    default: false,
  },
  {
    code: "validates",
    name: "validates",
    opposite_name: "is validated by",
    description: "",
    rules: "Config",
    default: false,
  },
];

const WorkItemRelationshipTypes = () => {
  const relationshipColumns: Column[] = [
    {
      id: "code",
      label: "common.code",
      minWidth: 100,
      align: "left",
    },
    {
      id: "name",
      label: "setting.product.column.name",
      minWidth: 200,
      align: "left",
    },
    {
      id: "opposite_name",
      label: "common.columns.opposite.name",
      minWidth: 200,
      align: "left",
    },
    {
      id: "description",
      label: "setting.product.column.description",
      minWidth: 400,
      align: "left",
    },
    {
      id: "default",
      label: "common.columns.default",
      minWidth: 150,
      align: "left",
      format: (value, record) => {
        return (
          <Box sx={{ display: "flex" }}>
            {value ? (
              <AppSvgIcon size={16} color="#6dc347">
                material-solid:done
              </AppSvgIcon>
            ) : (
              ""
            )}
          </Box>
        );
      },
    },
    {
      id: "actions",
      label: "setting.product.column.actions",
      align: "left",
      format: (value, record) => {
        return (
          <Box>
            <DeleteIcon sx={{ cursor: "pointer", fontSize: "16px" }} />
          </Box>
        );
      },
    },
  ];

  const [relationshipRows, setRelationshipRows] = React.useState([]);

  useEffect(() => {
    setRelationshipRows(relationshipTypeRows);
  }, []);

  return (
    <Container>
      <Box
        sx={{
          padding: "20px",
          background: "#fff",
          boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box>
          <CustomTable
            columns={relationshipColumns}
            rows={relationshipRows}
            showPage={false}
            maxHeight="auto"
            addRow={() => {
              console.log("add row");
            }}
            addRowTextId="common.add"
            // groupTextId="setting.item.relationship.types"
          />
        </Box>
      </Box>
    </Container>
  );
};

export default WorkItemRelationshipTypes;
