import CustomTable from "components/CustomTable";
import SimplePopup from "components/SimplePopup";
import React from "react";
import { Column } from "types/models/Common";

interface HistoryModalProps {
  handleClose: () => void;
  open: boolean;
}

const initRows: any[] = [
  {
    revision: "5",
    baselines: "Baseline SHA 1.0",
    details: '."Description." changed',
    dateTime: "03:34:21 10/03/2020",
    by: "James",
  },
  {
    revision: "4",
    baselines: "",
    details: '."Status." changed from ."In Process." to ."Approved."',
    dateTime: "04:06:26 10/02/2020",
    by: "Fred",
  },
  {
    revision: "3",
    baselines: "",
    details: '."Status." changed from ."Draft." to ."In Process."',
    dateTime: "10:46:09 09/26/2020",
    by: "Aaron",
  },
  {
    revision: "2",
    baselines: "",
    details: '."Name." changed from ."Incorrect delivery." to ."Incorrectly controlled delivery."',
    dateTime: "08:09:57 09/26/2020",
    by: "Aaron",
  },
  {
    revision: "1",
    baselines: "",
    details: "Initial creation",
    dateTime: "07:24:34 09/25/2020",
    by: "Aaron",
  },
];

const HistoryModal: React.FC<HistoryModalProps> = ({ open, handleClose }) => {
  const [columns, setColumns] = React.useState<Column[]>([
    {
      id: "revision",
      label: "common.revision",
      minWidth: 200,
      align: "left",
    },
    {
      id: "baselines",
      label: "common.baselines",
      minWidth: 200,
      align: "left",
    },
    {
      id: "details",
      label: "common.change.details",
      minWidth: 200,
      align: "left",
    },
    {
      id: "dateTime",
      label: "common.date.time",
      minWidth: 200,
      align: "left",
    },
    {
      id: "by",
      label: "common.by",
      minWidth: 200,
      align: "left",
    },
  ]);
  const [rows, setRows] = React.useState<any[]>(initRows);

  return (
    <SimplePopup
      handleClose={handleClose}
      open={open}
      titleId="setting.process.history"
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "1200px",
          minWidth: "1200px",
        },
        "& .MuiTablePagination-actions .MuiIconButton-root": {
          top: 0,
          right: 0,
        },
      }}
    >
      <CustomTable columns={columns} rows={rows} />
    </SimplePopup>
  );
};

export default HistoryModal;
