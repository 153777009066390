import "handsontable/dist/handsontable.full.css";

import { HotTable } from "@handsontable/react";
import { Box, TablePagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import { registerAllModules } from "handsontable/registry";
import { taskData } from "mock";
import React from "react";
import { bgColor, textColor } from "shared/constants/AppConst";

const ExcelTableContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(5),
  ".heaer-style": {
    fontWeight: "bold",
    background: bgColor.tableHeaderColor + " !important",
  },
}));

interface ExcelTableProps {
  id: string;
}

const ExcelTable: React.FC<ExcelTableProps> = (props: ExcelTableProps) => {
  const [mockData, setMockData] = React.useState(null);

  React.useEffect(() => {
    if (props.id) {
      if (props.id === "System Hazard Analysis") {
        setMockData(taskData["sha"]);
      } else if (props.id === "Design FMEA") {
        setMockData(taskData["df"]);
      } else if (props.id === "Design Input Requirements") {
        setMockData(taskData["dir"]);
      } else if (props.id === "Design Control Traceability Matrix") {
        setMockData(taskData["tm"]);
      } else {
        setMockData(null);
      }
    }
  }, [props.id]);

  React.useEffect(() => {
    if (props.id) {
      if (props.id === "System Hazard Analysis") {
        setMockData(taskData["sha"]);
      } else if (props.id === "Design FMEA") {
        setMockData(taskData["df"]);
      } else if (props.id === "Design Input Requirements") {
        setMockData(taskData["dir"]);
      } else if (props.id === "Design Control Traceability Matrix") {
        setMockData(taskData["tm"]);
      } else {
        setMockData(null);
      }
    }
  }, [props.id]);

  React.useEffect(() => {
    setHotSettings({
      // data: createSpreadsheetData(100, 26),
      data: mockData?.excelTableData ? mockData.excelTableData : [],
      columns: mockData?.excelTableColumns ? mockData.excelTableColumns : [],
      // autoColumnSize: { syncLimit: 5 },
      rowHeaders: true,
      colHeaders: true,
      fixedRowsTop: 1,
      cells: (row) => {
        if (row === 0) {
          return {
            readOnly: true,
            className: "heaer-style",
          };
        }
      },
      dropdownMenu: false,
      collapsibleColumns: true,
      filters: false,
      // colWidths: 120,
      readOnly: true,
      manualColumnResize: true,
      manualRowResize: true,
      colWidths: 120,
      rowHeights: 32,
      width: "100%",
      height: "100%",
      // stretchH: "'all'", // 'none' is default
      manualColumnMove: false,
      manualRowMove: false,
      // fixedColumnsStart: 1,
      // fixedRowsTop: 1,
      // colHeaders: mockData?.excelTableColHeaders ? mockData.excelTableColHeaders : [],
      // columns: [
      //   {
      //     editor: CustomEditor
      //   }
      // ],
      contextMenu: false,
      licenseKey: "non-commercial-and-evaluation",
    });
  }, [mockData]);

  const [hotSettings, setHotSettings] = React.useState<any>({});

  registerAllModules();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ExcelTableContainer>
      <Box
        id="spreadsheet"
        sx={{
          width: "100%",
          height: "calc(100vh - 170px)",
          ".handsontable .htDimmed": {
            background: "#FFF",
            color: textColor[85],
            fontSize: "14px",
            fontFamily: "Roboto",
          },
          ".handsontable thead tr th": {
            color: textColor[85],
            // background: bgColor.tableHeaderColor,
            fontWeight: "bold",
            height: "39px !important",
            fontSize: "14px",
            fontFamily: "Roboto",
          },
          ".handsontable thead th .relative": {
            padding: "9px 4px",
          },
        }}
      >
        {hotSettings?.data?.length > 0 && <HotTable settings={hotSettings} stretchH={"all"} />}
      </Box>
      {hotSettings?.data?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={hotSettings?.data?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </ExcelTableContainer>
  );
};

export default ExcelTable;
