import { Box, InputAdornment, MenuItem, Select, styled } from "@mui/material";
import CustomTable from "components/CustomTable";
import React from "react";
import { boxShadow, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

const CvssContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(5),
  background: theme.palette.background.paper,
  boxShadow: boxShadow.cardBoxShadow,
}));

const Cvss = () => {
  const [statusPickList, setStatusPickList] = React.useState("1");

  return (
    <CvssContainer>
      <Box sx={{ mb: (theme) => theme.spacing(3) }}>
        <Select
          sx={{
            minWidth: 60,
            background: "#fff",
            "& .MuiInputAdornment-root": {
              color: textColor[85],
            },
            "& .MuiTypography-body1": {
              color: textColor[85],
            },
            "& .MuiInputBase-input": {
              padding: "6px 0px",
              color: textColor[85],
            },
          }}
          id="status-pick-list-select"
          value={statusPickList}
          onChange={(e) => {
            setStatusPickList(e.target.value);
          }}
          startAdornment={<InputAdornment position="start">Status Pick List:</InputAdornment>}
        >
          <MenuItem value="1">System Default Work Item Status</MenuItem>
        </Select>
      </Box>
      <Box>
        <CustomTable columns={[]} rows={[]} showPage={true} />
      </Box>
    </CvssContainer>
  );
};

export default Cvss;
