import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Avatar, Box, Button, IconButton, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import { ListModel } from "components/CustomTable/CustomTableModel";
import TableToolBar from "components/CustomTable/TableToolBar";
import React from "react";

const CommentContainer = styled(Box)(({ theme }) => ({
  ".comment-content": {
    width: "100%",
    marginTop: "8px",
  },
  ".comment-list-task-item": {
    width: "100%",
    border: "1px solid rgba(1,1,1,0.12)",
    borderRadius: "5px",
    marginBottom: "24px",
  },
  ".comment-list-comment": {
    background: "#ffff",
    borderBottom: "1px solid rgba(1,1,1,0.12)",
    padding: "12px 24px",
  },
  ".comment-list-task-title": {
    fontSie: "16px",
    fontWeight: "bold",
    color: "#2e6ccb",
    marginBottom: "16px",
  },
  ".comment-list-comment-item": {
    display: "flex",
    margin: "12px 0",
    marginBottom: "1px solid rgba(1,1,1,0.12)",
  },
  ".comment-list-comment-item-right": {
    marginLeft: "12px",
  },
  ".comment-list-comment-item-right-info": {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
  ".comment-list-comment-item-right-name": {
    color: "rgba(0,0,0,0.85)",
    fontWeight: "bold",
  },
  ".comment-list-comment-item-right-type": {
    background: "#EAEAEA",
    padding: "4px 6px",
    borderRadius: "5px",
    margin: "0 16px",
  },
  ".comment-list-comment-item-right-date": {
    color: "rgba(0,0,0,0.85)",
  },
  ".comment-list-comment-item-right-content": {
    marginTop: "12px",
    color: "rgba(0,0,0,0.85)",
  },
  ".comment-list-comment-action": {
    marginTop: "24px",
    display: "flex",
    alignItems: "center",
  },
  ".comment-list-task-input": {
    background: "F7F7F7",
    display: "flex",
    alignItem: "center",
    padding: "12px 24px",
  },
  ".comment-list-comment-item-avatar": {
    width: "40px",
  },
  ".comment-list-task-input-container": {
    width: "calc(100%)",
    marginLeft: "12px",
    display: "flex",
    alignItems: "center",
  },
}));

const Comments = () => {
  const [commentList, setCommentList] = React.useState<any[]>([
    {
      id: "1",
      title: "TSK#ABC-123 SHA1.0",
      replyContent: "",
      children: [
        {
          id: "11",
          avatar: <AppSvgIcon size={40}>material-outline:account_circle</AppSvgIcon>,
          // avatar:
          //   "https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2",
          name: "Fred",
          type: "Comment",
          date: "09/29/2021",
          content: "Comment for a comment",
          re: "@TSK#ABC-123",
          count: 0,
          isAgree: false,
          mute: false,
          showMute: true,
          canDelete: true,
        },
      ],
    },
    {
      id: "2",
      title: "CL3-RISK-45 Design for easy setup and self check the healthiness of pump during setup",
      replyContent: "",
      children: [
        {
          id: "21",
          avatar: <AppSvgIcon size={40}>material-outline:account_circle</AppSvgIcon>,
          name: "Fubin",
          type: "Answer",
          date: "09/29/2021",
          content: "Reject!answer",
          re: "@CL3-RISK-45",
          count: 0,
          isAgree: false,
          mute: false,
          showMute: true,
          canDelete: true,
        },
      ],
    },
  ]);
  const [searchText, setSearchText] = React.useState("");
  const [filter1, setFilter1] = React.useState<any>("");
  const [filterList1] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter1",
    },
  ]);
  const [filter2, setFilter2] = React.useState<any>("");
  const [filterList2] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter2",
    },
  ]);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
  };

  const handleReplyContent = (value, id) => {
    const newCommentList = [...commentList];
    const targetItem = newCommentList.filter((item) => item.id === id)[0];
    if (targetItem) {
      targetItem.replyContent = value;
    }
    setCommentList(newCommentList);
  };

  const handleDelete = (itemId, id) => {
    const newCommentList = [...commentList];
    const targetItem = newCommentList.filter((item) => item.id === itemId)[0];
    if (targetItem) {
      targetItem.children.forEach((item) => {
        if (item.id === id) {
          targetItem.children.splice(item, 1);
        }
      });
    }
    setCommentList(newCommentList);
  };

  const clearReplyContent = () => {
    const newCommentList = [...commentList].map((item) => {
      item.replyContent = "";
      return item;
    });
    setCommentList(newCommentList);
  };

  const handelEnter = (code, id) => {
    if (code === 13) {
      const newCommentList = [...commentList];
      const targetItem = newCommentList.filter((item) => item.id === id)[0];
      if (targetItem && targetItem.replyContent) {
        targetItem.children.push({
          id: id + "" + (targetItem.children.length + 1),
          avatar: <AppSvgIcon size={40}>material-outline:account_circle</AppSvgIcon>,
          name: "Fubin",
          type: "Answer",
          date: "09/29/2021",
          content: targetItem.replyContent,
          count: 0,
          isAgree: false,
          mute: false,
          showMute: true,
          canDelete: true,
        });
        targetItem.replyContent = "";
      }
      setCommentList(newCommentList);
    }
  };

  return (
    <CommentContainer>
      <Box className="comment-toolbar">
        {
          <TableToolBar
            showSearch={true}
            searchText={searchText}
            requestSearch={requestSearch}
            saveFilter={() => {
              console.log("save filter");
            }}
            filters={[
              {
                value: filter1,
                list: filterList1,
                setValue: setFilter1,
              },
              {
                value: filter2,
                list: filterList2,
                setValue: setFilter2,
              },
            ]}
          />
        }
      </Box>
      <Box className="comment-content">
        {commentList.map((item) => {
          return (
            <Box className="comment-list-task-item" key={item.id}>
              <Box className="comment-list-comment">
                <Box className="comment-list-task-title">{item.title}</Box>
                {item.children?.map((comment) => {
                  return (
                    <Box key={comment.id} className="comment-list-comment-item">
                      <Box className="comment-list-comment-item-avatar">
                        {typeof comment.avatar === "string" ? <Avatar alt="" src={comment.avatar} /> : comment.avatar}
                      </Box>
                      <Box className="comment-list-comment-item-right">
                        <Box className="comment-list-comment-item-right-info">
                          <Box className="comment-list-comment-item-right-name">{comment.name}</Box>
                          <Box className="comment-list-comment-item-right-type">{comment.type}</Box>
                          <Box className="comment-list-comment-item-right-date">{comment.date}</Box>
                        </Box>
                        <Box className="comment-list-comment-item-right-content">{comment.content}</Box>
                        {comment.re && (
                          <Box sx={{ mt: 4 }}>
                            RE:
                            <span style={{ color: "#2e6ccb" }}>{comment.re}</span>
                          </Box>
                        )}
                        <Box className="comment-list-comment-action">
                          {comment.isAgree ? (
                            <ThumbUpAltIcon sx={{ cursor: "pointer" }} />
                          ) : (
                            <ThumbUpOffAltIcon sx={{ cursor: "pointer" }} />
                          )}
                          {
                            <Box
                              sx={
                                comment.count
                                  ? {
                                      display: "block",
                                      ml: 1,
                                      padding: "2px 4px",
                                      border: "1px solid rgba(1,1,1,0.12)",
                                      borderRadius: "5px",
                                    }
                                  : { display: "none" }
                              }
                            >
                              {comment.count}
                            </Box>
                          }
                          {comment.showMute ? (
                            comment.mute ? (
                              <Button sx={{ color: "rgba(0,0,0,0.85)" }} variant="text">
                                Unmute
                              </Button>
                            ) : (
                              <Button sx={{ color: "rgba(0,0,0,0.85)" }} variant="text">
                                Mute
                              </Button>
                            )
                          ) : (
                            ""
                          )}
                          {comment.canDelete && (
                            <Button
                              onClick={() => {
                                handleDelete(item.id, comment.id);
                              }}
                              sx={{ color: "rgba(0,0,0,0.85)" }}
                              variant="text"
                            >
                              Delete
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Box className="comment-list-task-input">
                <Box className="comment-list-comment-item-avatar">
                  <AppSvgIcon size={40}>material-outline:account_circle</AppSvgIcon>
                  {/* <Avatar
                    alt=""
                    src="https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2"
                  /> */}
                </Box>
                <Box className="comment-list-task-input-container">
                  <TextField
                    variant="outlined"
                    value={item.replyContent}
                    fullWidth
                    onKeyDown={(e) => {
                      handelEnter(e.keyCode, item.id);
                    }}
                    placeholder="Add your reply"
                    onFocus={() => {
                      clearReplyContent();
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleReplyContent(event.target.value, item.id)
                    }
                    InputProps={{
                      endAdornment: (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            m: 0,
                          }}
                        >
                          <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{
                              visibility: item.replyContent ? "visible" : "hidden",
                            }}
                            onClick={() => {
                              handleReplyContent("", item.id);
                            }}
                          >
                            <AppSvgIcon size={16} color="action">
                              {"material-outline:clear"}
                            </AppSvgIcon>
                          </IconButton>
                        </Box>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </CommentContainer>
  );
};

export default Comments;
