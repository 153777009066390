export const flowData = [
  {
    id: 1,
    x: 880,
    y: 105,
    text: "Does user \n remember his \n password?",
    type: "process",
    css: "light_shape",
    fontColor: "#fff",
    fill: "#3DA0E3",
    stroke: "#3DA0E3",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 2,
    x: 1080,
    y: 125,
    width: 50,
    height: 50,
    text: "XOR",
    type: "circle",
    css: "logic_op",
    fontColor: "#fff",
    fill: "#7D8495",
    stroke: "#7D8495",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 3,
    x: 1160,
    y: 40,
    text: "User forgets \n his password",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 3.1,
    x: 1340,
    y: 40,
    text: "Send an E-mail \n with new \n password",
    type: "process",
    css: "light_shape",
    fontColor: "#fff",
    fill: "#3DA0E3",
    stroke: "#3DA0E3",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 3.2,
    x: 1520,
    y: 40,
    text: "E-mail sent",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 4,
    x: 1160,
    y: 180,
    text: "User \n remembers his \n password",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 5,
    x: 1340,
    y: 180,
    text: "User types in \n login and \n password",
    type: "process",
    css: "light_shape",
    fontColor: "#fff",
    fill: "#3DA0E3",
    stroke: "#3DA0E3",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 6,
    x: 1520,
    y: 180,
    text: "Data typed in",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 7,
    x: 1700,
    y: 180,
    text: "Proof \n completion and \n correctness",
    type: "process",
    css: "light_shape",
    fontColor: "#fff",
    fill: "#3DA0E3",
    stroke: "#3DA0E3",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 7.1,
    x: 1745,
    y: 600,
    width: 50,
    height: 50,
    text: "XOR",
    type: "circle",
    css: "logic_op",
    fontColor: "#fff",
    fill: "#7D8495",
    stroke: "#7D8495",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 7.2,
    x: 1880,
    y: 320,
    text: "User logged in",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 7.3,
    x: 1880,
    y: 450,
    text: "Complete \n logging in",
    type: "process",
    css: "light_shape",
    fontColor: "#fff",
    fill: "#3DA0E3",
    stroke: "#3DA0E3",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 7.4,
    x: 1880,
    y: 580,
    text: "Data is correct",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 7.5,
    x: 1530,
    y: 580,
    text: "Data is not \n correct",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 8,
    x: 1700,
    y: 40,
    text: "System",
    type: "subroutine",
    css: "system",
    fontColor: "#fff",
    fill: "#7E6BAD",
    stroke: "#7E6BAD",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 9,
    x: 1880,
    y: 180,
    text: "Database",
    type: "database",
    css: "database",
    fontColor: "#fff",
    fill: "#7E6BAD",
    stroke: "#7E6BAD",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 10,
    x: 0,
    y: 220,
    text: "Visitor enters \n the website",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 11,
    x: 180,
    y: 220,
    text: "Logged in user?",
    type: "process",
    css: "light_shape",
    fontColor: "#fff",
    fill: "#3DA0E3",
    stroke: "#3DA0E3",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 12,
    x: 365,
    y: 240,
    width: 50,
    height: 50,
    text: "XOR",
    type: "circle",
    css: "logic_op",
    fontColor: "#fff",
    fill: "#7D8495",
    stroke: "#7D8495",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 13,
    x: 320,
    y: 120,
    text: "User is \n logged in",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 14,
    x: 320,
    y: 320,
    text: "User is not \n logged in",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 15,
    x: 490,
    y: 320,
    text: "Registered \n user?",
    type: "process",
    css: "light_shape",
    fontColor: "#fff",
    fill: "#3DA0E3",
    stroke: "#3DA0E3",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 16,
    x: 660,
    y: 340,
    width: 50,
    height: 50,
    text: "XOR",
    type: "circle",
    css: "logic_op",
    fontColor: "#fff",
    fill: "#7D8495",
    stroke: "#7D8495",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 17,
    x: 730,
    y: 240,
    text: "User is \n registered",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 18,
    x: 730,
    y: 400,
    text: "User is not \n registered",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 19,
    x: 905,
    y: 420,
    width: 50,
    height: 50,
    text: "XOR",
    type: "circle",
    css: "logic_op",
    fontColor: "#fff",
    fill: "#7D8495",
    stroke: "#7D8495",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 20,
    x: 860,
    y: 510,
    text: "User types in \n needed data",
    type: "process",
    css: "light_shape",
    fontColor: "#fff",
    fill: "#3DA0E3",
    stroke: "#3DA0E3",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 21,
    x: 860,
    y: 640,
    text: "Data typed in",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 22,
    x: 860,
    y: 770,
    text: "Proof \n completion and \n correctness",
    type: "process",
    css: "light_shape",
    fontColor: "#fff",
    fill: "#3DA0E3",
    stroke: "#3DA0E3",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 23,
    x: 700,
    y: 770,
    text: "System",
    type: "subroutine",
    css: "system",
    fontColor: "#fff",
    fill: "#7E6BAD",
    stroke: "#7E6BAD",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 24,
    x: 860,
    y: 890,
    text: "Database",
    type: "database",
    css: "database",
    fontColor: "#fff",
    fill: "#7E6BAD",
    stroke: "#7E6BAD",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 25,
    x: 1065,
    y: 790,
    width: 50,
    height: 50,
    text: "XOR",
    type: "circle",
    css: "logic_op",
    fontColor: "#fff",
    fill: "#7D8495",
    stroke: "#7D8495",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 26,
    x: 1020,
    y: 640,
    text: "Data is not \n correct",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 27,
    x: 1020,
    y: 890,
    text: "Data is \n correct",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 28,
    x: 1245,
    y: 910,
    width: 50,
    height: 50,
    text: "AND",
    type: "circle",
    css: "logic_op",
    fontColor: "#fff",
    fill: "#7D8495",
    stroke: "#7D8495",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 29,
    x: 1200,
    y: 770,
    text: "Write account \n data into \n database",
    type: "process",
    css: "light_shape",
    fontColor: "#fff",
    fill: "#3DA0E3",
    stroke: "#3DA0E3",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 29.1,
    x: 1380,
    y: 770,
    text: "Data writing \n done",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 29.2,
    x: 1200,
    y: 640,
    text: "Database",
    type: "database",
    css: "database",
    fontColor: "#fff",
    fill: "#7E6BAD",
    stroke: "#7E6BAD",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 30,
    x: 1330,
    y: 890,
    text: "Send activation \n e-mail",
    type: "process",
    css: "light_shape",
    fontColor: "#fff",
    fill: "#3DA0E3",
    stroke: "#3DA0E3",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 31,
    x: 1510,
    y: 890,
    text: "Activation \n e-mail sent",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 32,
    x: 1690,
    y: 890,
    text: "User activates \n his account",
    type: "process",
    css: "light_shape",
    fontColor: "#fff",
    fill: "#3DA0E3",
    stroke: "#3DA0E3",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    id: 33,
    x: 1870,
    y: 889,
    text: "Account \n activated",
    type: "preparation",
    css: "dark_shape",
    fontColor: "#fff",
    fill: "#33B579",
    stroke: "#33B579",
    extraLinesStroke: "#B8C6D6",
    strokeWidth: 1,
    width: 140,
    height: 90,
    fontSize: 14,
    lineHeight: 14,
    strokeDash: "0",
    textAlign: "center",
    textVerticalAlign: "center",
    fontStyle: "normal",
    fixed: false,
    editable: true,
    strokeType: "line",
  },
  {
    from: 1,
    to: 2,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968723",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1020, y: 150 },
      { x: 1080, y: 150 },
    ],
    width: 60,
    height: 0,
    x: 1020,
    y: 150,
  },
  {
    from: 2,
    to: 3,
    type: "dash",
    toSide: "left",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968724",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "top",
    points: [
      { x: 1105, y: 125 },
      { x: 1105, y: 105 },
      { x: 1105, y: 85 },
      { x: 1140, y: 85 },
      { x: 1160, y: 85 },
    ],
    width: 55,
    height: 40,
    x: 1105,
    y: 125,
  },
  {
    from: 2,
    to: 7.5,
    type: "dash",
    fromSide: "bottom",
    toSide: "top",
    backArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968725",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    points: [
      { x: 1105, y: 175 },
      { x: 1105, y: 195 },
      { x: 1105, y: 560 },
      { x: 1600, y: 560 },
      { x: 1600, y: 580 },
    ],
    width: 495,
    height: 405,
    x: 1105,
    y: 175,
  },
  {
    from: 2,
    to: 3.2,
    type: "dash",
    fromSide: "top",
    toSide: "top",
    stroke: "#7D8495",
    id: "u1659688968726",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    points: [
      { x: 1105, y: 125 },
      { x: 1105, y: 105 },
      { x: 1105, y: 20 },
      { x: 1590, y: 20 },
      { x: 1590, y: 40 },
    ],
    width: 485,
    height: 85,
    x: 1105,
    y: 125,
  },
  {
    from: 3,
    to: 3.1,
    type: "line",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968727",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1300, y: 85 },
      { x: 1340, y: 85 },
    ],
    width: 40,
    height: 0,
    x: 1300,
    y: 85,
  },
  {
    from: 3.1,
    to: 3.2,
    type: "line",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968728",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1480, y: 85 },
      { x: 1520, y: 85 },
    ],
    width: 40,
    height: 0,
    x: 1480,
    y: 85,
  },
  {
    from: 2,
    to: 4,
    type: "dash",
    toSide: "left",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968729",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "bottom",
    points: [
      { x: 1105, y: 175 },
      { x: 1105, y: 195 },
      { x: 1105, y: 225 },
      { x: 1140, y: 225 },
      { x: 1160, y: 225 },
    ],
    width: 55,
    height: 50,
    x: 1105,
    y: 175,
  },
  {
    from: 4,
    to: 5,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968730",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1300, y: 225 },
      { x: 1340, y: 225 },
    ],
    width: 40,
    height: 0,
    x: 1300,
    y: 225,
  },
  {
    from: 5,
    to: 6,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968731",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1480, y: 225 },
      { x: 1520, y: 225 },
    ],
    width: 40,
    height: 0,
    x: 1480,
    y: 225,
  },
  {
    from: 6,
    to: 7,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968732",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1660, y: 225 },
      { x: 1700, y: 225 },
    ],
    width: 40,
    height: 0,
    x: 1660,
    y: 225,
  },
  {
    from: 7,
    to: 7.1,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968733",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "bottom",
    toSide: "top",
    points: [
      { x: 1770, y: 270 },
      { x: 1770, y: 600 },
    ],
    width: 0,
    height: 330,
    x: 1770,
    y: 270,
  },
  {
    from: 7.1,
    to: 7.4,
    type: "dash",
    toSide: "left",
    stroke: "#7D8495",
    id: "u1659688968734",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    points: [
      { x: 1795, y: 625 },
      { x: 1880, y: 625 },
    ],
    width: 85,
    height: 0,
    x: 1795,
    y: 625,
  },
  {
    from: 7.1,
    to: 7.5,
    type: "dash",
    toSide: "right",
    stroke: "#7D8495",
    id: "u1659688968735",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "left",
    points: [
      { x: 1745, y: 625 },
      { x: 1670, y: 625 },
    ],
    width: 75,
    height: 0,
    x: 1745,
    y: 625,
  },
  {
    from: 7.2,
    to: 7.3,
    type: "dash",
    backArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968736",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "bottom",
    toSide: "top",
    points: [
      { x: 1950, y: 410 },
      { x: 1950, y: 450 },
    ],
    width: 0,
    height: 40,
    x: 1950,
    y: 410,
  },
  {
    from: 7.3,
    to: 7.4,
    type: "dash",
    backArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968737",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "bottom",
    toSide: "top",
    points: [
      { x: 1950, y: 540 },
      { x: 1950, y: 580 },
    ],
    width: 0,
    height: 40,
    x: 1950,
    y: 540,
  },
  {
    from: 7,
    to: 8,
    type: "dash",
    toSide: "bottom",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968738",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "top",
    points: [
      { x: 1770, y: 180 },
      { x: 1770, y: 130 },
    ],
    width: 0,
    height: 50,
    x: 1770,
    y: 180,
  },
  {
    from: 7,
    to: 9,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968739",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1840, y: 225 },
      { x: 1880, y: 225 },
    ],
    width: 40,
    height: 0,
    x: 1840,
    y: 225,
  },
  {
    from: 10,
    to: 11,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968740",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 140, y: 265 },
      { x: 180, y: 265 },
    ],
    width: 40,
    height: 0,
    x: 140,
    y: 265,
  },
  {
    from: 11,
    to: 12,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968741",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 320, y: 265 },
      { x: 365, y: 265 },
    ],
    width: 45,
    height: 0,
    x: 320,
    y: 265,
  },
  {
    from: 12,
    to: 13,
    type: "dash",
    forwardArrow: "filled",
    toSide: "bottom",
    stroke: "#7D8495",
    id: "u1659688968742",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "top",
    points: [
      { x: 390, y: 240 },
      { x: 390, y: 210 },
    ],
    width: 0,
    height: 30,
    x: 390,
    y: 240,
  },
  {
    from: 12,
    to: 14,
    type: "dash",
    forwardArrow: "filled",
    toSide: "top",
    stroke: "#7D8495",
    id: "u1659688968743",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "bottom",
    points: [
      { x: 390, y: 290 },
      { x: 390, y: 320 },
    ],
    width: 0,
    height: 30,
    x: 390,
    y: 290,
  },
  {
    from: 14,
    to: 15,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968744",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 460, y: 365 },
      { x: 490, y: 365 },
    ],
    width: 30,
    height: 0,
    x: 460,
    y: 365,
  },
  {
    from: 15,
    to: 16,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968745",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 630, y: 365 },
      { x: 660, y: 365 },
    ],
    width: 30,
    height: 0,
    x: 630,
    y: 365,
  },
  {
    from: 16,
    to: 17,
    type: "dash",
    forwardArrow: "filled",
    toSide: "left",
    stroke: "#7D8495",
    id: "u1659688968746",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "top",
    points: [
      { x: 685, y: 340 },
      { x: 685, y: 320 },
      { x: 685, y: 285 },
      { x: 710, y: 285 },
      { x: 730, y: 285 },
    ],
    width: 45,
    height: 55,
    x: 685,
    y: 340,
  },
  {
    from: 16,
    to: 33,
    type: "dash",
    backArrow: "filled",
    fromSide: "bottom",
    toSide: "bottom",
    stroke: "#7D8495",
    id: "u1659688968747",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    points: [
      { x: 685, y: 390 },
      { x: 685, y: 410 },
      { x: 685, y: 999 },
      { x: 1940, y: 999 },
      { x: 1940, y: 979 },
    ],
    width: 1255,
    height: 589,
    x: 685,
    y: 390,
  },
  {
    from: 17,
    to: 1,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968748",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "top",
    toSide: "left",
    points: [
      { x: 800, y: 240 },
      { x: 800, y: 220 },
      { x: 800, y: 150 },
      { x: 860, y: 150 },
      { x: 880, y: 150 },
    ],
    width: 80,
    height: 90,
    x: 800,
    y: 240,
  },
  {
    from: 17,
    to: 18,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968749",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "bottom",
    toSide: "top",
    points: [
      { x: 800, y: 330 },
      { x: 800, y: 400 },
    ],
    width: 0,
    height: 70,
    x: 800,
    y: 330,
  },
  {
    from: 18,
    to: 19,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968750",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 870, y: 445 },
      { x: 905, y: 445 },
    ],
    width: 35,
    height: 0,
    x: 870,
    y: 445,
  },
  {
    from: 19,
    to: 20,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968751",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "bottom",
    toSide: "top",
    points: [
      { x: 930, y: 470 },
      { x: 930, y: 510 },
    ],
    width: 0,
    height: 40,
    x: 930,
    y: 470,
  },
  {
    from: 19,
    to: 26,
    type: "dash",
    backArrow: "filled",
    fromSide: "bottom",
    stroke: "#7D8495",
    id: "u1659688968752",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    toSide: "top",
    points: [
      { x: 930, y: 470 },
      { x: 930, y: 490 },
      { x: 930, y: 620 },
      { x: 1090, y: 620 },
      { x: 1090, y: 640 },
    ],
    width: 160,
    height: 170,
    x: 930,
    y: 470,
  },
  {
    from: 20,
    to: 22,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968753",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "bottom",
    toSide: "top",
    points: [
      { x: 930, y: 600 },
      { x: 930, y: 770 },
    ],
    width: 0,
    height: 170,
    x: 930,
    y: 600,
  },
  {
    from: 22,
    to: 23,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968754",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "left",
    toSide: "right",
    points: [
      { x: 860, y: 815 },
      { x: 840, y: 815 },
    ],
    width: 20,
    height: 0,
    x: 860,
    y: 815,
  },
  {
    from: 22,
    to: 24,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968755",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "bottom",
    toSide: "top",
    points: [
      { x: 930, y: 860 },
      { x: 930, y: 890 },
    ],
    width: 0,
    height: 30,
    x: 930,
    y: 860,
  },
  {
    from: 22,
    to: 25,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968756",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1000, y: 815 },
      { x: 1065, y: 815 },
    ],
    width: 65,
    height: 0,
    x: 1000,
    y: 815,
  },
  {
    from: 25,
    to: 26,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968757",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "top",
    toSide: "bottom",
    points: [
      { x: 1090, y: 790 },
      { x: 1090, y: 730 },
    ],
    width: 0,
    height: 60,
    x: 1090,
    y: 790,
  },
  {
    from: 25,
    to: 27,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968758",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "bottom",
    toSide: "top",
    points: [
      { x: 1090, y: 840 },
      { x: 1090, y: 890 },
    ],
    width: 0,
    height: 50,
    x: 1090,
    y: 840,
  },
  {
    from: 27,
    to: 28,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968759",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1160, y: 935 },
      { x: 1245, y: 935 },
    ],
    width: 85,
    height: 0,
    x: 1160,
    y: 935,
  },
  {
    from: 28,
    to: 29,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968760",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "top",
    toSide: "bottom",
    points: [
      { x: 1270, y: 910 },
      { x: 1270, y: 860 },
    ],
    width: 0,
    height: 50,
    x: 1270,
    y: 910,
  },
  {
    from: 29,
    to: 29.1,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968761",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1340, y: 815 },
      { x: 1380, y: 815 },
    ],
    width: 40,
    height: 0,
    x: 1340,
    y: 815,
  },
  {
    from: 29,
    to: 29.2,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968762",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "top",
    toSide: "bottom",
    points: [
      { x: 1270, y: 770 },
      { x: 1270, y: 730 },
    ],
    width: 0,
    height: 40,
    x: 1270,
    y: 770,
  },
  {
    from: 28,
    to: 30,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968763",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1295, y: 935 },
      { x: 1330, y: 935 },
    ],
    width: 35,
    height: 0,
    x: 1295,
    y: 935,
  },
  {
    from: 30,
    to: 31,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968764",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1470, y: 935 },
      { x: 1510, y: 935 },
    ],
    width: 40,
    height: 0,
    x: 1470,
    y: 935,
  },
  {
    from: 31,
    to: 32,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968765",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1650, y: 935 },
      { x: 1690, y: 935 },
    ],
    width: 40,
    height: 0,
    x: 1650,
    y: 935,
  },
  {
    from: 32,
    to: 33,
    type: "dash",
    forwardArrow: "filled",
    stroke: "#7D8495",
    id: "u1659688968766",
    connectType: "elbow",
    strokeWidth: 2,
    cornersRadius: 0,
    title: {
      fontSize: 14,
      lineHeight: 14,
      textAlign: "center",
      textVerticalAlign: "center",
      fontStyle: "normal",
      fontWeight: "normal",
      fontColor: "#4C4C4C",
      fill: "#FFF",
      draggable: true,
      editable: true,
      hidden: false,
    },
    fromSide: "right",
    toSide: "left",
    points: [
      { x: 1830, y: 935 },
      { x: 1850, y: 935 },
      { x: 1850, y: 934 },
      { x: 1870, y: 934 },
    ],
    width: 40,
    height: 1,
    x: 1830,
    y: 935,
  },
];
export const itemWorkflowDat = [
  {
    id: 1,
    text: "Item Created",
    type: "circle",
    fill: "#cfcfcf",
    fontColor: "#fff",
    editable: false,
  },
  {
    id: 2,
    text: "Draft",
    type: "process",
    fill: "#3DA0E3",
    fontColor: "#fff",
    editable: false,
  },
  {
    id: 3,
    text: "In Review",
    type: "process",
    fill: "#3DA0E3",
    fontColor: "#fff",
    editable: false,
  },
  {
    id: 4,
    text: "Approved",
    type: "process",
    fill: "#3DA0E3",
    fontColor: "#fff",
    editable: false,
  },
  {
    id: 5,
    text: "Rejected",
    type: "process",
    fill: "#3DA0E3",
    fontColor: "#fff",
    editable: false,
  },

  {
    from: 1,
    to: 2,
    type: "line",
    forwardArrow: "filled",
    connectType: "elbow",
    title: {
      editable: false,
      fontColor: "green",
      text: [
        {
          editable: false,
          type: "$linetext",
          text: "Initialize",
          textVerticalAlign: "top",
        },
      ],
    },
  },
  {
    from: 2,
    to: 3,
    type: "line",
    fromSide: "top",
    toSide: "top",
    forwardArrow: "filled",
    connectType: "elbow",
    title: {
      editable: false,
      fontColor: "green",
      text: [
        {
          editable: false,
          type: "$linetext",
          text: "Review",
          textVerticalAlign: "top",
        },
      ],
    },
  },
  {
    from: 3,
    to: 4,
    type: "line",
    forwardArrow: "filled",
    connectType: "elbow",
    title: {
      editable: false,
      fontColor: "#5b5bd2",
      text: [
        {
          editable: false,
          type: "$linetext",
          text: "Approve",
          textVerticalAlign: "top",
        },
      ],
    },
  },
  {
    from: 3,
    to: 5,
    type: "line",
    fromSide: "bottom",
    toSide: "bottom",
    forwardArrow: "filled",
    connectType: "elbow",
    title: {
      editable: false,
      fontColor: "#b41d1d",
      text: [
        {
          editable: false,
          type: "$linetext",
          text: "Reject",
          textVerticalAlign: "top",
        },
      ],
    },
  },
  {
    from: 3,
    to: 2,
    type: "line",
    forwardArrow: "filled",
    connectType: "elbow",
    title: {
      editable: false,
      fontColor: "#a7a714",
      text: [
        {
          editable: false,
          type: "$linetext",
          text: "Rework",
          textVerticalAlign: "bottom",
        },
      ],
    },
  },
  {
    from: 4,
    to: 2,
    type: "line",
    forwardArrow: "filled",
    connectType: "elbow",
    title: {
      editable: false,
      fontColor: "#a7a714",
      text: [
        {
          editable: false,
          type: "$linetext",
          text: "Rework",
          textVerticalAlign: "top",
        },
      ],
    },
  },
  {
    from: 5,
    to: 2,
    type: "line",
    forwardArrow: "filled",
    connectType: "elbow",
    title: {
      editable: false,
      fontColor: "#a7a714",
      text: [
        {
          editable: false,
          type: "$linetext",
          text: "Rework",
          textVerticalAlign: "top",
        },
      ],
    },
  },
];
