import TaskItemIcon from "components/Icons/TaskItemIcon";
import { ModuleType } from "shared/constants/AppEnums";

const DCData: any[] = [
  {
    id: "Design_Inputs",
    value: "Design Inputs",
    level: 1,
    isFolder: true,
    expanded: true,
    items: [
      {
        id: "User_Needs",
        value: "User Needs",
        level: 2,
        isFolder: true,
        expanded: true,
        items: [
          {
            id: "UND#001",
            value: "UND#001 The user needs a device that can measure temperature accurately and quickly.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "UND#002",
            value:
              "UND#002 The user needs to able to accurately track the temperature of multiple patients when using the device.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "UND#003",
            value: "UND#003 The user needs the device to read body temperature without pain or discomfort.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "UND#004",
            value: "UND#004 The user needs a device that is easy to use.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design_Input_Requirements",
        value: "Design Input Requirements",
        level: 2,
        isFolder: true,
        expanded: true,
        items: [
          {
            id: "SYS#001",
            value: "SYS#001 The system shall measure temperature in the range of 34oC to 44oC.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "SYS#002",
            value:
              "SYS#002 The system shall measure temperature with a accuracy of 5% when temperature is measured 3 inches +/- 1 inch from the forehead.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "SYS#003",
            value: "SYS#003 The system shall weigh no more than 6 ounces.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "SYS#004",
            value: "SYS#004 The system shall have a footprint no larger than 3.0 X 2.0 X 6.0 inches.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "HW_Subsystem_Requirements",
        value: "HW Subsystem Requirements",
        level: 2,
        isFolder: true,
        expanded: true,
        items: [
          {
            id: "HW#001",
            value: "HW#001 The hardware shall detect the distance of the human tissue using xxx technology.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "HW#002",
            value: "HW#002 The temperature sensor shall be able to measure temperature to a resolution of 0.01 oC.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "SW_Subsystem_Requirements",
        value: "SW Subsystem Requirements",
        level: 2,
        isFolder: true,
        expanded: true,
        items: [
          {
            id: "SW#581",
            value: "SW#581 The software shall be able to display  temperature  in the range of 30oC to 50oC.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "SW#582",
            value: "SW#582 The software shall detect when the thermometer is within distance to make a measurement.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
        ],
      },
    ],
  },
  {
    id: "Design_Outputs",
    value: "Design Outputs",
    level: 1,
    isFolder: true,
    expanded: true,
    items: [
      {
        id: "Design_Specifications",
        value: "Design Specifications",
        level: 2,
        isFolder: true,
        expanded: true,
        items: [
          {
            id: "DS#002",
            value: "DS#002 The design shall provide a temperature range of 30oC to 50oC.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "DS#003",
            value:
              "DS#003 The design shall provide a blinking LCD backlight when it senses human tissue within 5 inches.",
            level: 3,
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "DMR_Design_Outputs",
        value: "DMR Design Outputs",
        level: 2,
        isFolder: true,
        expanded: true,
        items: [
          {
            id: "989346",
            value: "989346 5V Battery",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "78321",
            value: "78321 LCD Display",
            level: 3,
            labelIcon: TaskItemIcon,
          },
        ],
      },
    ],
  },
  {
    id: "Design_Verifications",
    value: "Design Verifications",
    level: 1,
    isFolder: true,
    expanded: true,
    items: [
      {
        id: "System_Verifications",
        value: "System Verifications",
        level: 2,
        isFolder: true,
        expanded: true,
      },
      {
        id: "SW_Verifications",
        value: "SW Verifications",
        level: 2,
        isFolder: true,
        expanded: true,
      },
      {
        id: "HW_Verifications",
        value: "HW Verifications",
        level: 2,
        isFolder: true,
        expanded: true,
      },
      {
        id: "Test_Cases",
        value: "Test Cases",
        level: 2,
        isFolder: true,
        expanded: true,
        items: [
          {
            id: "TC#001",
            value: "TC#001 Fever Detection",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "TC#002",
            value: "TC#002 Cleaning",
            level: 3,
            labelIcon: TaskItemIcon,
          },
        ],
      },
    ],
  },
  {
    id: "Design_Validations",
    value: "Design Validations",
    level: 1,
    isFolder: true,
    expanded: true,
    items: [
      {
        id: "Validations",
        value: "Validations",
        level: 2,
        isFolder: true,
        expanded: true,
        items: [
          {
            id: "VAL#001",
            value: "VAL#001 Self taking of temperature",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "VAL#002",
            value: "VAL#002 Taking temperature of a another person",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "VAL#003",
            value: "VAL#003 Taking temperature on multiple patients",
            level: 3,
            labelIcon: TaskItemIcon,
          },
        ],
      },
    ],
  },
  {
    id: "Design_Transfer",
    value: "Design Transfer",
    level: 1,
    isFolder: true,
    expanded: true,
    items: [
      {
        id: "IQ_OQ_PQ",
        value: "IQ/OQ/PQ",
        level: 2,
        isFolder: true,
        expanded: true,
        items: [
          {
            id: "3Q#001",
            value: "3Q#001 Material Qualification",
            level: 3,
            labelIcon: TaskItemIcon,
          },
          {
            id: "3Q#002",
            value: "3Q#002 IQ/OQ/PQ Assembly Test",
            level: 3,
            labelIcon: TaskItemIcon,
          },
        ],
      },
    ],
  },
];

const RISKData: any[] = [
  {
    id: "Risk_Management",
    value: "Risk Management",
    level: 1,
    isFolder: true,
    expanded: true,
    items: [
      {
        id: "Safety_Risks",
        value: "Safety Risks",
        level: 2,
        isFolder: true,
        expanded: true,
        items: [
          {
            id: "Safety_Harms",
            value: "Safety Harms",
            level: 3,
            isFolder: true,
            expanded: true,
            items: [
              {
                id: "HARM#001",
                value: "HARM#001 Untreated underlying conditions",
                level: 4,
                labelIcon: TaskItemIcon,
              },
              {
                id: "HARM#002",
                value: "HARM#002 Pain, discomfort",
                level: 4,
                labelIcon: TaskItemIcon,
              },
            ],
          },
          {
            id: "Hazards",
            value: "Hazards",
            level: 3,
            isFolder: true,
            expanded: true,
            items: [
              {
                id: "Hazard_Category",
                value: "Hazard Category",
                level: 4,
                isFolder: true,
                expanded: true,
              },
              {
                id: "System_Hazards",
                value: "System Hazards",
                level: 4,
                isFolder: true,
                expanded: true,
                items: [
                  {
                    id: "HZ#001",
                    value: "HZ#001 Incorrect Temperature Measurement",
                    level: 5,
                    labelIcon: TaskItemIcon,
                  },
                ],
              },
              {
                id: "Hazardous_Situations",
                value: "Hazardous Situations",
                level: 4,
                isFolder: true,
                expanded: true,
                items: [
                  {
                    id: "HS#001",
                    value: "HS#001 Undetected fever",
                    level: 5,
                    labelIcon: TaskItemIcon,
                  },
                ],
              },
              {
                id: "Causes",
                value: "Causes",
                level: 4,
                isFolder: true,
                expanded: true,
              },
              {
                id: "Basic_Events",
                value: "Basic Events",
                level: 4,
                isFolder: true,
                expanded: true,
                items: [
                  {
                    id: "SOE#003",
                    value: "SOE#003 Thermometers malfunction",
                    level: 5,
                    labelIcon: TaskItemIcon,
                  },
                ],
              },
              {
                id: "Risk_Controls",
                value: "Risk Controls",
                level: 4,
                isFolder: true,
                expanded: true,
                items: [
                  {
                    id: "RC#001",
                    value: "RC#001 The thermometer has a 3 yr reliability",
                    level: 5,
                    labelIcon: TaskItemIcon,
                  },
                  {
                    id: "RC#002",
                    value: "RC#002 Self-test is performed at power on",
                    level: 5,
                    labelIcon: TaskItemIcon,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "Security_Risks",
        value: "Security Risks",
        level: 2,
        isFolder: true,
        expanded: true,
        items: [
          {
            id: "Security_Harms",
            value: "Security Harms",
            level: 3,
            isFolder: true,
            expanded: true,
          },
          {
            id: "Threats",
            value: "Threats",
            level: 3,
            isFolder: true,
            expanded: true,
            items: [
              {
                id: "Sub_Threats",
                value: "Threats",
                level: 4,
                isFolder: true,
                expanded: true,
              },
              {
                id: "Vulnerabilities",
                value: "Vulnerabilities",
                level: 4,
                isFolder: true,
                expanded: true,
              },
              {
                id: "Threat_Attacks",
                value: "Threat Attacks",
                level: 4,
                isFolder: true,
                expanded: true,
              },
            ],
          },
        ],
      },
      {
        id: "FMEAs",
        value: "FMEAs",
        level: 2,
        isFolder: true,
        expanded: true,
        items: [
          {
            id: "Design_FMEA",
            value: "Design FMEA",
            level: 3,
            isFolder: true,
            expanded: true,
            items: [
              {
                id: "FMEA#001",
                value: "FMEA#001 Temperature measurement not displayed",
                level: 4,
                labelIcon: TaskItemIcon,
              },
              {
                id: "FMEA#003",
                value: "FMEA#003 Temperature measurement displayed incorrectly",
                level: 4,
                labelIcon: TaskItemIcon,
              },
            ],
          },
          {
            id: "Process_FMEA",
            value: "Process FMEA",
            level: 3,
            isFolder: true,
            expanded: true,
          },
          {
            id: "Product_Error_Codes",
            value: "Product Error Codes",
            level: 3,
            isFolder: true,
            expanded: true,
          },
          {
            id: "Code_Lib",
            value: "Code Lib",
            level: 3,
            isFolder: true,
            expanded: true,
          },
        ],
      },
      {
        id: "Evidences",
        value: "Evidences",
        level: 2,
        isFolder: true,
        expanded: true,
        items: [
          {
            id: "Risk_Benefit_Analysis_Ref",
            value: "Risk Benefit Analysis Ref.",
            level: 3,
            isFolder: true,
            expanded: true,
          },
        ],
      },
    ],
  },
];

const CYBERData: any[] = [
  {
    id: "Cybersecurity_Risk_Management",
    value: "Cybersecurity Risk Management",
    level: 1,
    isFolder: true,
  },
];

const PRODUCTData: any[] = [
  {
    id: "Production_Management",
    value: "Production Management",
    level: 1,
    isFolder: true,
  },
];

const TFData: any[] = [
  {
    id: "Index_File_Management",
    value: "Index File Management",
    level: 1,
    isFolder: true,
  },
];

const ACData: any[] = [
  {
    id: "Assurance_Case_Management",
    value: "Assurance Case Management",
    level: 1,
    isFolder: true,
  },
];

export const ProductWorkItems = {
  [ModuleType.DCD]: DCData,
  [ModuleType.REQ]: DCData,
  [ModuleType.RISK]: RISKData,
  [ModuleType.CYBER]: CYBERData,
  [ModuleType.AC]: ACData,
  [ModuleType.PRODUCTION]: PRODUCTData,
  [ModuleType.TF]: TFData,
};

export const WorkItems = {
  ["Design Controls"]: DCData,
  ["Risk Management"]: RISKData,
  ["Cybersecurity Risk Management"]: CYBERData,
  ["Assurance Case Management"]: ACData,
  ["Production Management"]: PRODUCTData,
  ["Index File Management"]: TFData,
};
