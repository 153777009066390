import { Box, Grid, Input, InputAdornment, Slider } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import React from "react";

interface ZoomControllerProps {
  onchange?: (zoom: Number) => void;
}
const ZoomController: React.FC<ZoomControllerProps> = (props) => {
  const { onchange } = props;

  const [value, setValue] = React.useState<number>(100);

  const handleChange = (value) => {
    setValue(value);
    onchange(value);
  }
  const zoomIn = () => {
    if (value === 200) {
      handleChange(200);
    } else {
      handleChange(Number(value) + 10);
    }
  };
  const zoomOut = () => {
    if (value == 10) {
      handleChange(10);
    } else {
      handleChange(Number(value) - 10);
    }
  };

  const handleSliderChange = (event: Event, newValue: number) => {
    handleChange(newValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value === "" ? 100 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 10) {
      handleChange(10);
    } else if (value > 100) {
      handleChange(100);
    }
  };

  const marks = [
    {
      value: 0,
    },
    {
      value: 100,
    },
    {
      value: 400,
    },
  ];

  return (<><Box className="zoom-control-wrapper">
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <AppSvgIcon size={16} onClick={zoomOut}>
          material-outline:remove
        </AppSvgIcon>
      </Grid>
      <Grid item xs>
        <Slider
          value={typeof value === "number" ? value : 0}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          marks={marks}
          size="small"
          step={10}
          min={10}
          max={200}
        />
      </Grid>
      <Grid item>
        <AppSvgIcon size={16} onClick={zoomIn}>
          material-outline:add
        </AppSvgIcon>
      </Grid>
      <Grid item>
        <Input
          value={value}
          size="small"
          onChange={handleInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: 10,
            min: 10,
            max: 200,
            type: "number",
            "aria-labelledby": "input-slider",
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>

  </Box></>);
};

export default ZoomController;
