import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Divider, List } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import VerticalCollapse from "layout/components/VerticalNav/VerticalCollapse";
import VerticalItem from "layout/components/VerticalNav/VerticalItem";
import NavVerticalGroup from "layout/components/VerticalNav/VerticalNavGroup";
import React from "react";
import { Outlet } from "react-router-dom";
import { bgColor, borderColor, sidebarStyle } from "shared/constants/AppConst";

import adminConfigData, { AdminRouterConfigData } from "./adminConfigData";

const CustomList = styled(List)(({ theme }) => ({}));

interface AdminWrapperProps {}

const AdminWrapper: React.FC<AdminWrapperProps> = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        width: "100%",
        height: "100%",
        background: "#fff",
      }}
    >
      <AppScrollbar
        sx={{
          width: 270,
          // height: "100%",
          backgroundColor: (theme) => theme.palette.background.default,
          height: "calc(100vh - 56px)",
          borderRight: `1px solid ${borderColor.sidebarBorderRightColor}`,
          // boxShadow: "1px 0 0 0 #dadfe6",
          // boxShadow: '8px 0 12px 4px rgba(0, 0, 0, 0.02), 6px 0 12px -8px rgba(0, 0, 0, 0.04)',
        }}
      >
        <CustomList
          sx={{
            ...sidebarStyle,
            borderRight: 0,
          }}
        >
          {adminConfigData.map((item: AdminRouterConfigData) => (
            <React.Fragment key={item.id}>
              {item.type === "group" && <NavVerticalGroup item={item} level={item.level} />}

              {item.type === "title" && (
                <Box className="slider-title">
                  <h4>
                    <IntlMessages id={item!.messageId} />
                  </h4>
                </Box>
              )}

              {item.type === "collapse" && <VerticalCollapse item={item} level={item.level} />}

              {item.type === "divider" && (
                <Divider
                  sx={{
                    width: "calc(100% - 28px)",
                    margin: "30px 0 16px 16px",
                  }}
                />
              )}

              {item.type === "item" && <VerticalItem item={item} level={item.level} />}
            </React.Fragment>
          ))}
        </CustomList>
      </AppScrollbar>
      <Box
        sx={{
          width: "calc(100% - 270px)",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AppScrollbar
          sx={{
            margin: "0",
            width: "100%",
            backgroundColor: "transparent",
            height: "calc(100vh - 56px)",
            background: bgColor.main,
          }}
        >
          <Outlet />
        </AppScrollbar>
      </Box>
    </Box>
  );
};

export default AdminWrapper;
