import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Popover, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { textColor } from "shared/constants/AppConst";

import TreeOption from "./CustomOption";
import { VariantSelectItemModel } from "./VariantSelectModel";

const deepFind =
  (pred) =>
  ([x, ...xs] = []) =>
    x && (pred(x) ? x : deepFind(pred)(x.children) || deepFind(pred)(xs));

const findById = (id) => (dataSet) => deepFind((o) => o.id === id)(dataSet);

const DropListContainer = styled(Box)(({ theme }) => ({
  minWidth: 150,
  padding: "8px",
  ".drop_list_header": {
    width: "100%",
  },
  ":& .MuiTextField-root": {
    width: "100%",
  },
  "& .MuiSvgIcon-root": {
    mr: 0.5,
  },
  "& .MuiInputBase-root": {
    padding: "0px 14px",
    height: "32px",
    width: "100%",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px",
  },
  "& .MuiInput-underline:before": {
    borderBottom: 1,
    borderColor: "divider",
  },
  ".drop_list_content": {
    marginTop: "8px",
  },
  ".drop_list_footer": {
    marginTop: "8px",
  },
  ".list_item": {
    position: "relative",
    width: "100%",
    padding: "8px 0px 8px 16px",
  },
  ".list_item:hover": {
    background: "#2e6ccb",
  },
  ".list_item_text": {
    color: "rgba(0,0,0,0.85)",
    cursor: "pointer",
  },
  ".list_item_icon": {
    position: "absolute",
    color: "rgb(245, 154, 35)",
    right: "8px",
    top: "50%",
    cursor: "pointer",
    transform: "translateY(-50%)",
  },
}));

interface VariantSelectProps {
  value: string | number;
  variants: VariantSelectItemModel[];
  onChange: (value: number | string) => void;
}

const VariantSelect: React.FC<VariantSelectProps> = (props: VariantSelectProps) => {
  const { value, variants, onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [text, setText] = React.useState<string>("");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const currentItem = findById(value)(variants);
    currentItem && setText(currentItem.label);
  }, [value, variants]);

  const handleSelected = (value: VariantSelectItemModel) => {
    onChange(value.id);
    setAnchorEl(null);
  };

  const renderTree = (item: VariantSelectItemModel, selected?: string) => {
    return (
      <TreeOption
        key={item.id}
        dataItem={item}
        defaultExpanded={true}
        selected={selected}
        renderChildren={renderTree}
        onClick={handleSelected}
      />
    );
  };

  return (
    <>
      <Typography
        color="text.primary"
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          color: textColor[85],
        }}
        onClick={handleClick}
      >
        {text} {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DropListContainer>
          <Box className="drop_list_content">{variants.map((item) => renderTree(item))}</Box>
        </DropListContainer>
      </Popover>
    </>
  );
};

export default VariantSelect;
