import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import Chart from "react-apexcharts";
import { boxShadow, textColor } from "shared/constants/AppConst";

const WorkItemStatusContainer = styled(Box)(({ theme }) => ({
  ".MuiTabs-root": {
    backgroundColor: "#dce0e6",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    minHeight: "auto",
    marginBottom: theme.spacing(0),
    ".MuiTabs-scroller": {
      width: "auto",
    },
    ".MuiTab-root": {
      padding: "1px 8px",
      minWidth: "auto",
      minHeight: "24px",
      textTransform: "capitalize",
      color: textColor[65],
      fontWeight: "400",
    },
    ".Mui-selected": {
      color: textColor[85],
      borderRadius: "2px",
      boxShadow: boxShadow.cardBoxShadow,
      backgroundColor: theme.palette.background.paper,
      fontWeight: "500",
    },
    ".MuiTabs-indicator": {
      display: "none",
    },
  },
}));

const a11yProps = (index: number | string) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

enum ItemStatusEnum {
  Requirement = "Requirement",
  TC = "Test Case",
}

const StatusType = [
  {
    color: "#2e6ccb",
    name: "Draf",
  },
  {
    color: "#ff991f",
    name: "In Review",
  },
  {
    color: "#45b812",
    name: "Approved",
  },
  {
    color: "#5243aa",
    name: "Effective",
  },
  {
    color: "#e63825",
    name: "Rejected",
  },
];

const WorkItemStatus = () => {
  const [value, setValue] = React.useState(ItemStatusEnum.Requirement);

  const handleTabChange = (event: React.SyntheticEvent, newValue: ItemStatusEnum) => {
    setValue(newValue);
  };

  const [options, setOptions] = React.useState({
    xaxis: {
      categories: ["Draft", " In Review", "Approved", "Effective", "Rejected"],
      labels: {
        show: true,
        style: {
          colors: textColor[45],
          fontSize: "12px",
          fontFamily: "Roboto",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: textColor[45],
          fontSize: "12px",
          fontFamily: "Roboto",
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "28",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    colors: ["#2e6ccb", "#ff991f", "#45b812", "#5243aa", "#e63825"],
    dataLabels: {
      enabled: true,
      // formatter: function (val) {
      //   return val + "%";
      // },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: [textColor[65]],
      },
    },
  });
  const [series, setSeries] = React.useState([
    {
      name: "",
      data: [
        {
          x: "Draft",
          y: 846,
          fillColor: "#2e6ccb",
          strokeColor: "#2e6ccb",
        },
        {
          x: "In Review",
          y: 375,
          fillColor: "#ff991f",
          strokeColor: "#ff991f",
        },
        {
          x: "Approved",
          y: 680,
          fillColor: "#45b812",
          strokeColor: "#45b812",
        },
        {
          x: "Effective",
          y: 948,
          fillColor: "#5243aa",
          strokeColor: "#5243aa",
        },
        {
          x: "Rejected",
          y: 76,
          fillColor: "#e63825",
          strokeColor: "#e63825",
        },
      ],
    },
  ]);

  return (
    <WorkItemStatusContainer>
      <Box className="flex items-center justify-between">
        <Box
          className="flex items-center"
          sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
        >
          <h3>
            <IntlMessages id="common.work.item.status" />
          </h3>
        </Box>
        <Box className="flex">
          <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab
              value={ItemStatusEnum.Requirement}
              label={<IntlMessages id="common.requirement" />}
              {...a11yProps(ItemStatusEnum.Requirement)}
            />
            <Tab
              value={ItemStatusEnum.TC}
              label={<IntlMessages id="common.test.case" />}
              {...a11yProps(ItemStatusEnum.TC)}
            />
          </Tabs>
        </Box>
      </Box>
      <Box className="flex items-center justify-center">
        <Chart options={options} series={series} type="bar" width={534} height={200} />
      </Box>
      <Box className="flex items-center justify-center">
        {StatusType.map((item) => {
          return (
            <Box key={item.color} className="flex items-center">
              <Box sx={{ width: "8px", height: "8px", background: item.color, mx: "6px", borderRadius: "50%" }}></Box>
              <Box sx={{ color: textColor[65], fontSize: 12 }}>{item.name}</Box>
            </Box>
          );
        })}
      </Box>
    </WorkItemStatusContainer>
  );
};

export default WorkItemStatus;
