import IntlMessages from "@crema/utility/IntlMessages";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { Menu, MenuItem } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import React from "react";
import { textColor } from "shared/constants/AppConst";

import CreateVersionModal from "./CreateVersionModal";
import HistoryModal from "./HistoryModal";

interface MoreActionsProps {
  actionLevel: number; // 0: profile level; 1: module level; 2: process level.
  [x: string]: any;
}

const MoreActions: React.FC<MoreActionsProps> = ({ actionLevel }) => {
  const [openCreateVersionModal, setOpenCreateVersionModal] = React.useState<boolean>(false);
  const [openHistoryModal, setOpenHistoryModal] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppSvgIcon
        size="32px"
        color={textColor["85"]}
        sxStyle={{
          display: "inline-block",
          cursor: "pointer",
          my: 2,
          ml: 2,
          p: "5px",
          border: "solid 1px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
        }}
        onClick={handleClick}
      >
        material-outline:more_horiz
      </AppSvgIcon>
      <Menu
        id="more-menus"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiSvgIcon-root": {
            marginRight: 2,
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenCreateVersionModal(true);
            handleClose();
          }}
        >
          <AddLinkIcon />
          <IntlMessages id="setting.process.createVersion" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenHistoryModal(true);
            handleClose();
          }}
        >
          <AddLinkIcon />
          <IntlMessages id="setting.process.history" />
        </MenuItem>
      </Menu>
      {openCreateVersionModal && (
        <CreateVersionModal
          open={openCreateVersionModal}
          handleClose={() => {
            setOpenCreateVersionModal(false);
          }}
        />
      )}
      {openHistoryModal && (
        <HistoryModal
          open={openHistoryModal}
          handleClose={() => {
            setOpenHistoryModal(false);
          }}
          actionLevel={actionLevel}
        />
      )}
    </>
  );
};

export default MoreActions;
