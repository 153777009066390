import { Box } from "@mui/material";
import * as Dockable from "components/Dockable";
import React, { useEffect, useRef } from "react";

import HandsontablePage from "../HandsontablePage";
import Page2 from "../Page2";

const DockablePage = () => {
  const stateRef = (window["dockableRef"] = Dockable.useDockable((state) => {
    Dockable.createDockedPanel(state, state.rootPanel, Dockable.DockMode.Full, <HandsontablePage />);

    Dockable.createDockedPanel(state, state.rootPanel, Dockable.DockMode.Full, <Page2 />, "dockable window");

    Dockable.createDockedPanel(state, state.rootPanel, Dockable.DockMode.Bottom, <Page2 />);
    Dockable.createDockedPanel(state, state.rootPanel, Dockable.DockMode.Right, <Page2 />);
  }));

  const mounting = useRef(true);

  useEffect(() => {
    console.log("ComponentDidMount");
    if (mounting.current) {
    }

    return () => {
      console.log("ComponentWillUnmount");
      delete window["dockableRef"];
    };
  }, []);

  return (
    <>
      <Box sx={{ width: "100vw", height: "calc(100vh - 56px)" }}>
        <Dockable.Container state={stateRef} />
      </Box>
    </>
  );
};

export default DockablePage;
