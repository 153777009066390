import { Box, Menu } from "@mui/material";
import React from "react";
import { SketchPicker } from "react-color";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";

interface ColorPickerProps {
  color: string;
  disabled: boolean;
  handleColor: (color) => void;
  style?: object;
}

const ColorPicker: React.FC<ColorPickerProps> = (props: ColorPickerProps) => {
  const { color, handleColor, disabled, style } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          minWidth: "200px",
          height: "32px",
          position: "relative",
          padding: "2px 4px 2px 4px",
          border: `1px solid ${borderColor.dividerColor}`,
          borderRadius: "4px",
          color: disabled ? textColor[45] : textColor[85],
          ...style,
        }}
        className="flex items-center"
      >
        <Box>{color}</Box>
        <Box
          sx={{
            width: "20px",
            height: "20px",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: "4px",
            background: color,
            cursor: disabled ? "default" : "pointer",
            border: `1px solid ${borderColor.dividerColor}`,
          }}
          onClick={disabled ? () => {} : handleClick}
        ></Box>
      </Box>
      <Menu
        id="button-menus"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& .MuiSvgIcon-root": {
            marginRight: 2,
          },
          "& .MuiMenuItem-root": {
            color: "rgba(0,0,0,0.85)",
          },
          "& .MuiMenu-list": {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box>
          <SketchPicker
            color={color}
            onChangeComplete={(color) => {
              handleColor(color.hex);
            }}
          />
        </Box>
      </Menu>
    </>
  );
};

export default ColorPicker;
