import { DrawerProps } from "@mui/material";
import * as Dockable from "components/Dockable";
import React from "react";

import DrawerWrapper from "./DrawerWrapper";

export interface WindowProps {
  /** 大小；例如：lg、600，默认：`lg` */
  size?: "sm" | "md" | "lg" | "xl" | "" | number;
  drawerProps?: DrawerProps;
  actions?: React.ReactNode;
}

const DrawerHelper = () => {};

DrawerHelper.show = (
  title: React.ReactNode,
  comp: React.ReactNode,
  props?: WindowProps,
  dockableRef?: Dockable.RefState<Dockable.State>
) => {
  return DrawerWrapper(title, comp, props, dockableRef);
};

// ModalHelper.static = (
//   title: string,
//   comp: React.ReactNode,
//   props: ModalProps
// ) => {};

export default DrawerHelper;
