import AppScrollbar from "@crema/core/AppScrollbar";
import { Avatar, Box, Divider, Link, List, ListItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import * as React from "react";

const ListItemContainer = styled(Box)(({ theme }) => ({
  ".list-item": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  ".list-item-left": {
    display: "flex",
    alignItems: "flex-start",
  },
  ".list-avatar-container": {
    width: "40px",
    marginRight: "5px",
  },
}));

interface ActivityListProps {}

const list = [
  {
    id: 1,
    operator: "James",
    action: "edited the Status of",
    active: "CL3-SR-3",
    platform: "Android Integration",
    content: '"Status" changed from "Under Review" to "Draft"',
    productLink: "/",
    product: "DDD",
    updateTime: "11/26/2021",
  },
  {
    id: 2,
    operator: "James",
    action: "edited the Status of",
    active: "CL3-SR-3",
    platform: "Android Integration",
    content: '"Status" changed from "Under Review" to "Draft"',
    productLink: "/",
    product: "DDD",
    updateTime: "11/26/2021",
  },
  {
    id: 3,
    operator: "James",
    action: "edited the Status of",
    active: "CL3-SR-3",
    platform: "Android Integration",
    content: '"Status" changed from "Under Review" to "Draft"',
    productLink: "/",
    product: "DDD",
    updateTime: "11/26/2021",
  },
];

export default function ActivityList(props: ActivityListProps) {
  return (
    <List>
      <AppScrollbar
        sx={{
          maxHeight: 420,
        }}
      >
        {list.map((item) => (
          <ListItemContainer key={item.id}>
            <ListItem className="list-item">
              <Box className="list-item-left">
                <Box className="list-avatar-container">
                  <AppSvgIcon size={40}>material-outline:account_circle</AppSvgIcon>
                  {/* <Avatar
                    alt="Travis Howard"
                    src="https://d1icd6shlvmxi6.cloudfront.net/gsc/AER2NA/0e/93/7c/0e937c08739543d2a712536bed4e84af/images/company_-_workspaces/u634.svg?pageId=6908fed2-2e3f-40e6-90f7-8e117e7fbcc2"
                  /> */}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    color: "rgba(0,0,0,0.85)",
                    fontSize: 14,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      marginBottom: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 700,
                        marginRight: "2px",
                      }}
                    >
                      {item.operator}
                    </Box>
                    <Box
                      sx={{
                        fontWeight: 700,
                        marginRight: "2px",
                      }}
                    >
                      {item.action}
                    </Box>
                    <Box
                      sx={{
                        fontWeight: 700,
                        marginRight: "2px",
                        color: "#2e6ccb",
                      }}
                    >
                      {item.active}
                    </Box>
                    <Box
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      {item.platform}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginBottom: "5px",
                      color: "rgba(0,0,0,0.85)",
                    }}
                  >
                    {item.content}
                  </Box>
                  <Box
                    sx={{
                      marginBottom: "5px",
                      color: "#2e6ccb",
                    }}
                  >
                    <Link underline="none" href="/">
                      {item.product}
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  color: "rgba(0,0,0,0.45)",
                }}
              >
                {item.updateTime}
              </Box>
            </ListItem>
            <Divider variant="fullWidth" component="li" />
          </ListItemContainer>
        ))}
      </AppScrollbar>
    </List>
  );
}
