import AppScrollbar from "@crema/core/AppScrollbar";
import { Box, Drawer, DrawerProps, IconButton } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import clsx from "clsx";
import AppSvgIcon from "components/AppSvgIcon";
import * as Dockable from "components/Dockable";
import _ from "lodash";
import { render as reactRender, unmount as reactUnmount } from "rc-util/lib/React/render";
import React from "react";
import SimpleBarReact from "simplebar-react";

import { WindowProps } from "./DrawerHelper";

const StyledSimpleBarReact = styled(SimpleBarReact)(() => ({
  height: "100%",
  width: "100%",
}));

const DrawerWrapper = (
  title: React.ReactNode,
  content: React.ReactNode,
  props?: WindowProps,
  dockableRef?: Dockable.RefState<Dockable.State>
) => {
  dockableRef = dockableRef ?? window["dockableRef"];

  const defaultProps = {
    anchor: "right",
    hideBackdrop: false,
  };

  let settings = _.merge(defaultProps, props?.drawerProps);
  const { size } = props || { size: "lg" };

  let cls = "";
  let width = "";

  if (size) {
    if (typeof size === "number") {
      width = `${size}px`;
    } else {
      cls = `window-${size}`;
    }
  }

  // const [isOpen, setOpen] = React.useState(true);
  // const { anchor } = props;
  function handleClose(event, reason) {
    // setOpen(false);
    destroy();
  }

  function dockWindow() {
    destroy();
    Dockable.spawnDocking(dockableRef, Dockable.DockMode.Full, content as JSX.Element);
  }

  function floatingWindow() {
    destroy();
    Dockable.spawnFloating(dockableRef, content as JSX.Element);
  }

  const container = document.createDocumentFragment();

  function render() {
    setTimeout(() => {
      reactRender(
        <Drawer
          className={cls}
          sx={{
            top: "56px",
            height: "calc(100vh - 56px)",
            "& .MuiDrawer-paper": {
              top: "57px",
              width: width,

              display: "flex",
              height: "100%",
              flexDirection: "column",
              "& .header": {
                height: 48,
                padding: "8px 8px 8px 16px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                boxShadow: "0 1px #dadfe6",
                zIndex: 100,
                justifyContent: "space-between",
                ".title": {
                  fontSize: 16,
                  fontWeight: 600,
                  "&:hover .copy-link": {
                    opacity: 1,
                  },
                  "& .copy-link": {
                    cursor: "pointer",
                    padding: 0,
                    margin: "0 2px",
                    width: 24,
                    height: 24,
                    borderRadius: 1,
                    opacity: 0,
                  },
                },
                "& .menu-wrapper": {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  "& .menu-item": {
                    cursor: "pointer",
                    padding: 0,
                    margin: "0 2px",
                    width: 24,
                    height: 24,
                    borderRadius: 1,
                    "&:hover": {
                      color: (theme) => alpha(theme.palette.text.primary, 0.9),
                      // backgroundColor: (theme) => alpha(theme.palette.background.default, 0.9)
                    },
                  },
                },
              },
              "& .body": {
                flex: 1,
                // padding: 2,
                boxSizing: "border-box",
              },
            },
            "&.window-xl .MuiDrawer-paper": {
              width: 1024,
            },
            "&.window-lg .MuiDrawer-paper": {
              width: 900,
            },
            "&.window-md .MuiDrawer-paper": {
              width: 600,
            },
            "&.window-sm .MuiDrawer-paper": {
              width: 300,
            },
          }}
          open={true}
          {...settings}
          onClose={handleClose}
        >
          <div className="header">
            <div className="title flex-1">
              {title}{" "}
              <IconButton className="copy-link">
                <AppSvgIcon size={18}>material-outline:content_copy</AppSvgIcon>
              </IconButton>
            </div>
            <div className="menu-wrapper">
              {dockableRef ? (
                <IconButton className="menu-item" onClick={dockWindow}>
                  <AppSvgIcon size={18}>material-outline:launch</AppSvgIcon>
                </IconButton>
              ) : null}
              <IconButton className="menu-item" onClick={floatingWindow}>
                <AppSvgIcon size={18}>material-outline:flip_to_front</AppSvgIcon>
              </IconButton>
              <IconButton className="menu-item" onClick={(event) => handleClose(event, "")}>
                <AppSvgIcon size={18}>material-outline:close</AppSvgIcon>
              </IconButton>
              {props?.actions}
            </div>
          </div>
          <div className="body">{content}</div>
          {/* <StyledSimpleBarReact className="body">{content}</StyledSimpleBarReact> */}
        </Drawer>,
        container
      );
    });
  }

  function destroy() {
    reactUnmount(container);
  }

  render();

  return {
    destroy: destroy,
  };
};

export default DrawerWrapper;
