export enum ThemeStyle {
  MODERN = "modern",
  STANDARD = "standard",
}

export enum ThemeStyleRadius {
  MODERN = 30,
  STANDARD = 8,
  NONE = 0,
}

export enum ThemeMode {
  LIGHT = "light",
  DARK = "dark",
}

export enum LayoutType {
  FULL_WIDTH = "full-width",
  BOXED = "boxed",
  FRAMED = "framed",
}

export enum MenuStyle {
  DEFAULT = "default",
  STANDARD = "standard",
  ROUNDED = "rounded",
  ROUNDED_REVERSE = "rounded-reverse",
  CURVED_MENU = "curved-menu",
}

export enum LayoutDirection {
  RTL = "rtl",
  LTR = "ltr",
}

export enum NavStyle {
  DEFAULT = "default",
}

export enum FooterType {
  FIXED = "fixed",
  FLUID = "fluid",
}

export enum HeaderType {
  FLUID = "fluid",
  FIXED = "fixed",
}

export enum RouteTransition {
  NONE = "none",
  SLIDE_LEFT = "slideLeft",
  SLIDE_RIGHT = "slideRight",
  SLIDE_UP = "slideUp",
  SLIDE_DOWN = "slideDown",
}

export enum Fonts {
  LIGHT = "300",
  REGULAR = "400",
  MEDIUM = "500",
  SEMI_BOLD = "600",
  BOLD = "700",
}

export enum AuthType {
  AWS_COGNITO = "aws_cognito",
  AUTH0 = "auth0",
  JWT_AUTH = "jwt_auth",
}

export enum TableHeaderButtonsIconType {
  ADD = "add",
  SETTING = "setting",
  EXPORT = "export",
}

export enum ModuleType {
  RISK = "risk_management",
  CYBER = "cybersecurity_management",
  AC = "assurance_case_management",
  REQ = "requirement_management",
  DCD = "design_controls",
  PRODUCTION = "production_management",
  TF = "index_file_management",
}

export enum WorkItemStatus {
  Draft = "Draft",
  InReview = "In Review",
  Approved = "Approved",
  Effective = "Effective",
  Rejected = "Rejected",
}
