import Icon from "@mui/material/Icon";
import * as React from "react";

const DataSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.145 6.652h-4.87a.609.609 0 0 1-.608-.608V3.609c0-.336.272-.609.608-.609h4.87c.336 0 .609.273.609.609v2.435a.609.609 0 0 1-.609.608zm0 9.74h-4.87a.609.609 0 0 1-.608-.61v-2.434c0-.336.272-.609.608-.609h4.87c.336 0 .609.273.609.609v2.435a.609.609 0 0 1-.609.608zM6.101 7.26H7.32v4.87H6.1V7.26zm8.522-.609h-1.217V5.435h-3.044V4.217h3.653c.336 0 .608.273.608.61v1.825zm-.608 8.522h-3.653v-1.217h3.044v-1.218h1.217v1.826a.609.609 0 0 1-.608.61zm0-3.043a.608.608 0 0 1-.314-.087l-3.043-1.827a.609.609 0 0 1 0-1.043L13.7 7.348a.609.609 0 0 1 .627 0l3.044 1.826a.609.609 0 0 1 0 1.043l-3.044 1.827a.608.608 0 0 1-.313.087z"
      fill="rgba(0,0,0,0.85)"
      fillRule="nonzero"
    />
  </svg>
);

const DataIcon: React.FunctionComponent = () => {
  return <Icon component={DataSvg} />;
};

export default DataIcon;
