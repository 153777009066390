import React from "react";
import { initialUrl } from "shared/constants/AppConst";

import { adminPage } from "./admin";
import { authRouteConfig } from "./auth";
import { errorPagesConfigs } from "./errorPages";
import Error403 from "./errorPages/Error403";
import { homePagesConfigs } from "./home";
import { productPagesConfigs } from "./products";
import { profilePage } from "./profile";
import { samplePagesConfigs } from "./sample";
import { supportPagesConfigs } from "./support";
import { workspacePage } from "./workspace";

const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...homePagesConfigs,
    ...samplePagesConfigs,
    ...profilePage,
    ...workspacePage,
    ...adminPage,
    ...supportPagesConfigs,
    ...productPagesConfigs,
  ], //,
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs,
};

export { anonymousStructure, authorizedStructure, unAuthorizedStructure };
