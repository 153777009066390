import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "components/CustomTable";
import { useEffect, useState } from "react";
import { Column } from "types/models/Common";

const Container = styled(Box)(({ theme }) => ({
  ".no-data-box": {
    border: "1px solid rgba(0,0,0,0.12)",
    borderTop: "0",
    height: "45px",
  },
}));

const initColumns: Column[] = [
  {
    id: "taskName",
    labelText: "Derived Products",
  },
];
const initRows = [
  {
    id: 1,
    taskName: "SDDDB Main(R1.0)",
  },
];

const DerivedTable = () => {
  const [columns, setColumns] = useState<Column[]>(initColumns);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(initRows);
  }, []);
  return (
    <Container>
      <CustomTable columns={columns} rows={rows} showPage={false} />
    </Container>
  );
};
export default DerivedTable;
