import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Grid } from "@mui/material";
import MySelectField from "components/MySelectField";
import MyTextField from "components/MyTextField";
import SimplePopup from "components/SimplePopup";
import { Form, Formik } from "formik";
// import { VersionList } from "mock/VersionList";
import * as React from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";

interface AddProductProps {
  handleClose: () => void;
  open: boolean;
  template: any;
}

export default function AddProduct(props: AddProductProps) {
  const { open, handleClose, template } = props;
  const { messages } = useIntl();

  const validationSchema = yup.object({
    // id: yup.string().max(5, "5435").required(String(messages["validation.emailRequired"])),
    name: yup.string().max(5, "5435").required(String(messages["validation.nameRequired"])),
    code: yup.string().max(5, "5435").required(String(messages["validation.codeRequired"])),
    product: yup.string().required(String(messages["validation.productRequired"])),
    variant: yup.string().required(String(messages["validation.variantRequired"])),
    release: yup.string().required(String(messages["validation.releaseRequired"])),
  });

  const save = () => {
    const submitDom = document.getElementById("submit");
    submitDom && submitDom.click();
  };

  return (
    <SimplePopup handleClose={handleClose} open={open} titleId="products.create.product" save={save}>
      <Formik
        initialValues={{
          id: "",
          name: "",
          code: "",
          description: "",
          owner: "",
          product: template?.name,
          variant: template?.variant,
          release: template?.release,
          // templateVersion: VersionList[0]?.id,
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          console.log(data);
          handleClose();
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <MyTextField name="name" fullWidth label="workspace.form.name" required={true} />
              </Grid>
              <Grid item xs={12} md={12}>
                <MyTextField name="code" fullWidth label="common.code" required={true} />
              </Grid>
              <Grid item xs={12} md={12}>
                <MyTextField name="owner" fullWidth label="workspace.column.owner" />
              </Grid>
              <Grid item xs={12} md={12}>
                <MyTextField name="description" fullWidth multiline rows="3" label="workspace.form.description" />
              </Grid>
              <Grid item xs={12} md={12}>
                <MySelectField
                  label="common.from.product"
                  onChange={(e) => {
                    setFieldValue("product", e.target.value);
                  }}
                  name="product"
                  fullWidth
                  required
                  readOnly
                  list={[{ id: values.product, label: values.product }]}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <MySelectField
                  label="common.from.variant"
                  onChange={(e) => {
                    setFieldValue("variant", e.target.value);
                  }}
                  name="variant"
                  fullWidth
                  required
                  readOnly
                  list={[{ id: values.variant, label: values.variant }]}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <MySelectField
                  label="common.from.release"
                  onChange={(e) => {
                    setFieldValue("release", e.target.value);
                  }}
                  name="release"
                  fullWidth
                  required
                  readOnly
                  list={[{ id: values.release, label: values.release }]}
                />
              </Grid>
              {/* <Grid item xs={12} md={12}>
                <MySelectField
                  label="common.template.version"
                  onChange={(e) => {
                    setFieldValue("templateVersion", e.target.value);
                  }}
                  name="templateVersion"
                  fullWidth
                  required
                  readOnly
                  list={VersionList}
                />
              </Grid> */}
              <Grid sx={{ display: "none" }} item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    id="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <IntlMessages id="common.save" />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SimplePopup>
  );
}
