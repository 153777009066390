import IntlMessages from "@crema/utility/IntlMessages";
import { Badge, Box, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "components/CustomTable";
import { ListModel } from "components/CustomTable/CustomTableModel";
import FillTaskIcon from "components/Icons/FillTaskIcon";
import React from "react";
import { textColor } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

import RichText from "../../Details/RichText";
import AddFeed from "./AddFeed";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const ImpactSummaryContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  minWidth: "600px",
}));

const columns: Column[] = [
  {
    id: "source",
    label: "workspace.column.source",
    minWidth: 200,
    align: "left",
    format: (value, record) => {
      return (
        <Box className="flex items-center">
          <Box sx={{ width: "16px" }}>
            <FillTaskIcon />
          </Box>
          <Link sx={{ marginLeft: 2, textAlign: "left" }} underline="none" href="/">
            {value}
          </Link>
        </Box>
      );
    },
  },
  {
    id: "id",
    label: "workspace.column.id",
    minWidth: 200,
    align: "left",
    format: (value, record) => {
      return (
        <Box className="flex items-center">
          <Link sx={{ marginLeft: 2 }} underline="none" href="/">
            {value}
          </Link>
        </Box>
      );
    },
  },
  {
    id: "content",
    label: "workspace.column.content",
    minWidth: 200,
    align: "left",
  },
  {
    id: "status",
    label: "workspace.column.status",
    minWidth: 200,
    align: "left",
    format: (value, record) => {
      return (
        <Box className="flex items-center">
          <Badge color="info" variant="dot" />
          <Box sx={{ marginLeft: 2 }}>{value}</Box>
        </Box>
      );
    },
  },
  {
    id: "scope",
    label: "workspace.column.scope",
    minWidth: 200,
    align: "left",
    format: (value, record) => {
      if (value) {
        return (
          <Box className="flex items-center">
            <Box sx={{ width: "16px" }}>
              <FillTaskIcon />
            </Box>
            <Link sx={{ marginLeft: 2, textAlign: "left" }} underline="none" href="/">
              {value}
            </Link>
          </Box>
        );
      }
      return "";
    },
  },
  {
    id: "time",
    label: "workspace.column.creation.time",
    minWidth: 200,
    align: "left",
  },
];
const initRows = [
  {
    id: "FD#001",
    content: "Fault Tree Analysis should be modified according to SHA.",
    status: "Open",
    source: "DDD-TK#001 System Hazard Analysis (V1.0)",
    scope: "DDD-TK#032 Fault mode and effect analysis (V1.0)",
    time: "09:12:21 3/13/2022",
  },
  {
    id: "FD#002",
    content: "SHA in SDDDB and SDDDC should be updated.",
    status: "Open",
    source: "DDD-TK#001 System Hazard Analysis (V1.0)",
    scope: "DDD-TK#031 System Hazard Analysis (V1.2)",
    time: "09:12:21 3/13/2022",
  },
  {
    id: "FD#003",
    content: "Need create a system requirement",
    status: "In Process",
    source: "DDD-TK#001 System Hazard Analysis (V1.0)",
    scope: "",
    time: "09:12:21 3/13/2022",
  },
];

const ImpactSummary = () => {
  const [richText, setRichText] = React.useState("test");
  const [openFeedModal, setOpenFeedModal] = React.useState<boolean>(false);

  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState<any[]>(initRows);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = initRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  return (
    <ImpactSummaryContainer>
      <Box>
        <RichText value={richText} handleValue={setRichText} />
      </Box>
      <Box
        className="w-full flex items-center"
        sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
      >
        <h3>
          <IntlMessages id="common.feeds" />
        </h3>
      </Box>
      <>
        <CustomTable
          toolBar={{
            showSearch: true,
            searchText: searchText,
            requestSearch: requestSearch,
            buttons: [
              {
                id: "common.customize",
                icon: TableHeaderButtonsIconType.SETTING,
                onChange: () => {},
              },
              {
                id: "workspace.button.feed",
                icon: TableHeaderButtonsIconType.ADD,
                type: "text",
                color: "#2e6ccb",
                isBlue: true,
                onChange: () => setOpenFeedModal(true),
              },
            ],
          }}
          columns={columns}
          rows={rows}
        />
        {<AddFeed open={openFeedModal} handleClose={() => setOpenFeedModal(false)} />}
      </>
    </ImpactSummaryContainer>
  );
};

export default ImpactSummary;
