import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { bgColor, tabStyle } from "shared/constants/AppConst";

const Container = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 106px)",
  // overflowY: "auto",
  background: theme.palette.background.paper,
}));

interface LifeCycleProps {
  [x: string]: any;
}

const Lifecycle: React.FC<LifeCycleProps> = ({}) => {
  const location = useLocation();

  React.useEffect(() => {
    if (location?.pathname.indexOf("project") !== -1) {
      setIndex(0);
    } else if (location?.pathname.indexOf("feeds") !== -1) {
      setIndex(1);
    } else {
      setIndex(2);
    }
  }, [location]);

  const navigate = useNavigate();

  const [index, setIndex] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  return (
    <Container>
      <Box
        sx={{
          px: (theme) => theme.spacing(5),
        }}
      >
        <Tabs
          value={index}
          onChange={handleChangeTab}
          sx={{
            ...tabStyle,
            "& .MuiButtonBase-root": {
              minHeight: "48px",
              textTransform: "none",
            },
            borderBottom: 1,
            borderColor: "#e6e6e6",
          }}
        >
          <Tab
            onClick={() => {
              navigate("project");
            }}
            label={<IntlMessages id="common.project" />}
          />
          <Tab
            onClick={() => {
              navigate("feeds/analysis");
            }}
            label={<IntlMessages id="common.feeds" />}
          />
          <Tab
            onClick={() => {
              navigate("activities/myActivities");
            }}
            label={<IntlMessages id="common.activities" />}
          />
        </Tabs>
      </Box>
      <Box sx={{ height: "calc(100vh - 155px)", background: bgColor.main }}>
        <Outlet />
      </Box>
    </Container>
  );
};

export default Lifecycle;
