import Icon from "@mui/material/Icon";

const TaskTabSelectedSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zM7 5H5v14h14V5h-2v1a3 3 0 0 1-3 3h-4a3 3 0 0 1-3-3V5zm2 10v2H7v-2h2zm8 0v2h-6v-2h6zm-8-4v2H7v-2h2zm8 0v2h-6v-2h6zm-2-6H9v1a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5z"
      fill="#2e6ccb"
      fillRule="evenodd"
    />
  </svg>
);

const TaskTabSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zM7 5H5v14h14V5h-2v1a3 3 0 0 1-3 3h-4a3 3 0 0 1-3-3V5zm2 10v2H7v-2h2zm8 0v2h-6v-2h6zm-8-4v2H7v-2h2zm8 0v2h-6v-2h6zm-2-6H9v1a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5z"
      fill="#000"
      fillRule="evenodd"
      opacity=".6"
    />
  </svg>
);

interface TaskTabIconProps {
  selected?: boolean;
}

const TaskTabIcon = (props: TaskTabIconProps) => {
  if (props.selected) {
    return <Icon component={TaskTabSelectedSvg} />;
  } else {
    return <Icon component={TaskTabSvg} />;
  }
};

export default TaskTabIcon;
