import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Grid } from "@mui/material";
import MySelectField from "components/MySelectField";
import MyTextField from "components/MyTextField";
import SimplePopup from "components/SimplePopup";
import { Form, Formik } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";

interface AddFeedProps {
  handleClose: () => void;
  open: boolean;
}

export default function AddFeed(props: AddFeedProps) {
  const { open, handleClose } = props;
  const { messages } = useIntl();
  const [statusList] = React.useState([
    {
      id: 1,
      name: "Open",
    },
    {
      id: 2,
      name: "In Process",
    },
    {
      id: 3,
      name: "Finished",
    },
    {
      id: 4,
      name: "Coding",
    },
    {
      id: 5,
      name: "Review",
    },
  ]);
  const [productList] = React.useState([
    {
      id: 1,
      name: "None",
    },
    {
      id: 2,
      name: "Drug Delivery Device",
    },
    {
      id: 3,
      name: "Drug Delivery Device",
    },
  ]);
  const [taskList] = React.useState([
    {
      id: 1,
      name: "None",
    },
    {
      id: 2,
      name: "Project A",
    },
    {
      id: 3,
      name: "Project B",
    },
  ]);

  const validationSchema = yup.object({
    id: yup.string().max(5, "5435").required(String(messages["validation.emailRequired"])),
    status: yup.string().required(String(messages["validation.emailRequired"])),
  });

  const save = () => {
    const submitDom = document.getElementById("submit");
    submitDom && submitDom.click();
  };

  return (
    <SimplePopup handleClose={handleClose} open={open} titleId="workspace.create.feed" save={save}>
      <Formik
        initialValues={{
          id: "",
          content: "",
          status: "",
          product: "",
          task: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          console.log(data);
          handleClose();
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <MyTextField name="id" fullWidth label="workspace.form.id" required={true} />
              </Grid>
              <Grid item xs={12} md={12}>
                <MyTextField name="content" fullWidth multiline rows="3" label="workspace.form.content" />
              </Grid>
              <Grid item xs={12} md={12}>
                <MySelectField
                  label="workspace.form.status"
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                  }}
                  name="status"
                  fullWidth
                  list={statusList}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <MySelectField
                  label="workspace.form.product"
                  onChange={(e) => {
                    setFieldValue("product", e.target.value);
                  }}
                  name="product"
                  fullWidth
                  list={productList}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <MySelectField
                  label="workspace.form.task"
                  onChange={(e) => {
                    setFieldValue("task", e.target.value);
                  }}
                  name="task"
                  fullWidth
                  list={taskList}
                />
              </Grid>
              <Grid sx={{ display: "none" }} item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    id="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <IntlMessages id="common.save" />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SimplePopup>
  );
}
