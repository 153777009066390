import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import CustomizeIcon from "components/Icons/CustomizeIcon";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

const analysisMockData = {
  "System Hazard Analysis": {
    attributesCheckbox: true,
    relationshipsCheckbox: false,
    relatedCheckbox: true,
    faultTreeCheckbox: false,
    assuranceCaseCheckbox: false,
    treeTableCheckbox: true,
  },
  "User Needs": {
    attributesCheckbox: true,
    relationshipsCheckbox: true,
    relatedCheckbox: false,
    faultTreeCheckbox: false,
    assuranceCaseCheckbox: false,
    treeTableCheckbox: true,
  },
  "Design Input Requirements": {
    attributesCheckbox: true,
    relationshipsCheckbox: true,
    relatedCheckbox: false,
    faultTreeCheckbox: false,
    assuranceCaseCheckbox: false,
    treeTableCheckbox: true,
  },
  "Test Cases": {
    attributesCheckbox: true,
    relationshipsCheckbox: true,
    relatedCheckbox: false,
    faultTreeCheckbox: false,
    assuranceCaseCheckbox: false,
    treeTableCheckbox: true,
  },
  "Design Control Traceability Matrix": {
    attributesCheckbox: true,
    relationshipsCheckbox: true,
    relatedCheckbox: false,
    faultTreeCheckbox: false,
    assuranceCaseCheckbox: false,
    treeTableCheckbox: true,
  },
};

const Analysis = () => {
  const params = useParams();
  const { pid } = params;

  const { messages } = useIntl();
  const [attributesCheckbox, setAttributesCheckbox] = React.useState<boolean>(true);
  const [relationshipsCheckbox, setRelationshipsCheckbox] = React.useState<boolean>(false);
  const [relatedCheckbox, setRelatedCheckbox] = React.useState<boolean>(true);
  const [faultTreeCheckbox, setFaultTreeCheckbox] = React.useState<boolean>(false);
  const [assuranceCaseCheckbox, setAssuranceCaseCheckbox] = React.useState<boolean>(false);
  const [treeTableCheckbox, setTreeTableCheckbox] = React.useState<boolean>(true);

  useEffect(() => {
    if (pid) {
      const mockData = analysisMockData[pid];
      if (mockData) {
        setAttributesCheckbox(mockData.attributesCheckbox);
        setRelationshipsCheckbox(mockData.relationshipsCheckbox);
        setRelatedCheckbox(mockData.relatedCheckbox);
        setFaultTreeCheckbox(mockData.faultTreeCheckbox);
        setAssuranceCaseCheckbox(mockData.assuranceCaseCheckbox);
        setTreeTableCheckbox(mockData.treeTableCheckbox);
      }
    }
  }, [pid]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Button
          sx={{
            color: "rgba(0,0,0,0.85)",
          }}
          variant="text"
          onClick={() => {}}
          startIcon={<CustomizeIcon />}
        >
          <IntlMessages id="common.customize" />
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <Box sx={{ color: "rgba(0,0,0,0.85)", fontWeight: "bold" }}>
            <IntlMessages id="common.select.the.analysis.views.for.item" />
          </Box>
          <FormGroup sx={{ ml: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={attributesCheckbox}
                  onChange={() => {
                    setAttributesCheckbox(!attributesCheckbox);
                  }}
                />
              }
              label={String(messages["common.attributes.and.comments"])}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relationshipsCheckbox}
                  onChange={() => {
                    setRelationshipsCheckbox(!relationshipsCheckbox);
                  }}
                />
              }
              label={String(messages["common.relationships"])}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relatedCheckbox}
                  onChange={() => {
                    setRelatedCheckbox(!relatedCheckbox);
                  }}
                />
              }
              label={String(messages["common.related.risks"])}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={faultTreeCheckbox}
                  onChange={() => {
                    setFaultTreeCheckbox(!faultTreeCheckbox);
                  }}
                />
              }
              label={String(messages["common.fault.tree.analysis"])}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={assuranceCaseCheckbox}
                  onChange={() => {
                    setAssuranceCaseCheckbox(!assuranceCaseCheckbox);
                  }}
                />
              }
              label={String(messages["common.assurance.case.analysis"])}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={treeTableCheckbox}
                  onChange={() => {
                    setTreeTableCheckbox(!treeTableCheckbox);
                  }}
                />
              }
              label={String(messages["common.tree.table.analysis"])}
            />
          </FormGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default Analysis;
