import React from "react";

import DiagramView from "./Diagram";
import DockablePage from "./DockablePage";
import DynamicForm from "./DynamicForm";
import Demo from "./Gojs/Index";
import App from "./Gojs/Index";
import HandsontablePage from "./HandsontablePage";
import IconList from "./IconList";
import CKEditorPage from "./RichTextEditor/CkEditor";
import SamplePage from "./Sample";
import TreeView from "./TreeView";

const Page1 = React.lazy(() => import("./Page1"));
const Page2 = React.lazy(() => import("./Page2"));
export const samplePagesConfigs = [
  {
    path: "/sample/page-1",
    element: <Page1 />,
  },
  {
    path: "/sample/page-2",
    element: <Page2 />,
  },
  {
    path: "/sample/sample",
    element: <SamplePage />,
  },
  {
    path: "/sample/handsontable",
    element: <HandsontablePage />,
  },
  {
    path: "/sample/Gojs",
    element: <Demo />,
  },
  {
    path: "/sample/TreeView",
    element: <TreeView />,
  },
  {
    path: "/sample/dockable",
    element: <DockablePage />,
  },
  {
    path: "/sample/Icons",
    element: <IconList />,
  },
  {
    path: "/sample/ckeditor",
    element: <CKEditorPage />,
  },
  {
    path: "/sample/DynamicForm",
    element: <DynamicForm />,
  }

];
