import Icon from "@mui/material/Icon";
import * as React from "react";

const StarFilledSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.673 17.21c1.487.814 2.795-.172 2.51-1.898l-.51-3.1 2.163-2.195c1.203-1.22.706-2.82-.959-3.072l-2.989-.452-1.33-2.806c-.745-1.572-2.363-1.589-3.11-.014l-1.336 2.82-2.989.452c-1.662.252-2.163 1.85-.959 3.072l2.163 2.195-.51 3.1c-.284 1.723 1.02 2.713 2.51 1.898L10 15.747l2.673 1.463z"
      fill="#FFD54D"
      fillRule="evenodd"
    />
  </svg>
);

const StarFilledIcon: React.FunctionComponent = () => {
  return <Icon component={StarFilledSvg} />;
};

export default StarFilledIcon;
