import SimplePopup from "components/SimplePopup";
import React from "react";

import ExcelTable from "../ExcelTable";

interface PreviewModalProps {
  handleClose: () => void;
  open: boolean;
}

const PreviewModal: React.FC<PreviewModalProps> = ({ open, handleClose }) => {
  return (
    <SimplePopup
      handleClose={handleClose}
      open={open}
      titleId="common.preview"
      okTextId="common.ok"
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "1500px",
          width: "1500px",
          minWidth: "1500px",
        },
        "& .MuiDialogContent-root": {
          padding: "20px",
        },
      }}
    >
      <ExcelTable />
    </SimplePopup>
  );
};

export default PreviewModal;
