import AppBadge from "@crema/core/AppBadge";
import AppNavLink from "@crema/core/AppNavLink";
import { useAuthUser } from "@crema/utility/AuthHooks";
import { checkPermission } from "@crema/utility/helper/RouteHelper";
import IntlMessages from "@crema/utility/IntlMessages";
import { Icon, ListItemText, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import clsx from "clsx";
import { RouterConfigData } from "pages/routesConfig";
import React, { useMemo } from "react";

import VerticalNavItem from "./VerticalNavItem";

interface VerticalItemProps {
  item: RouterConfigData;
  level: number;
}

const VerticalItem: React.FC<VerticalItemProps> = ({ level, item }) => {
  const { user } = useAuthUser();
  const hasPermission = useMemo(() => checkPermission(item.permittedRole, user.role), [item.permittedRole, user.role]);
  if (!hasPermission) {
    return null;
  }

  return (
    <VerticalNavItem
      level={level}
      button
      component={AppNavLink}
      to={item.url}
      activeClassName="active"
      exact={item.exact}
    >
      {item.icon && (
        <Box component="span">
          <Icon
            sx={{
              fontSize: 16,
              display: "block",
              mr: 4,
            }}
            className={clsx("nav-item-icon", "material-icons-outlined")}
            color="action"
          >
            {item.icon}
          </Icon>
        </Box>
      )}
      <Tooltip title={<IntlMessages id={item.messageId} />} placement="top">
        <ListItemText
          className="nav-item-content"
          primary={<IntlMessages id={item.messageId} />}
          classes={{ primary: "nav-item-text" }}
          sx={{
            maxWidth: "150px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            span: {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          }}
        />
      </Tooltip>
      {item.count && (
        <Box sx={{ mr: 3.5 }} className="menu-badge">
          <AppBadge count={item.count} color={item.color} />
        </Box>
      )}
    </VerticalNavItem>
  );
};

export default React.memo(VerticalItem);
