import { Box } from "@mui/material";

import MyTable from "./MyTable";

const Feeds = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <MyTable />
    </Box>
  );
};

export default Feeds;
