import IntlMessages from "@crema/utility/IntlMessages";
import { Box, TextField } from "@mui/material";
import { useField } from "formik";

const MyTextField = (props: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <>
      <Box
        sx={
          props?.label_style
            ? {
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                letterSpacing: "0.15px",
                color: "rgba(0,0,0,0.85)",
                mb: 1,
                ...props?.label_style,
              }
            : {
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                letterSpacing: "0.15px",
                color: "rgba(0,0,0,0.85)",
                mb: 1,
              }
        }
      >
        <IntlMessages id={props.label} />
        <sup style={{ color: "#de350b" }}>{props.required ? "*" : ""}</sup>
      </Box>
      <TextField {...field} {...props} label="" helperText={errorText} error={!!errorText} />
    </>
  );
};

export default MyTextField;
