import IntlMessages from "@crema/utility/IntlMessages";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useParams } from "react-router-dom";
import { tabStyle } from "shared/constants/AppConst";

import BarChart from "./BarChart";
import CycleTime from "./CycleTime";
import HeatMap from "./HeatMap";
import PieChart from "./PieChart";

const MetricsContainer = styled(Box)(({ theme }) => ({
  marginTop: "8px",
  minWidth: "1200px",
}));

export default function Metrics() {
  const [index, setIndex] = React.useState<string>("0");
  const routeParam = useParams();
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setIndex(newValue);
  };

  React.useEffect(() => {
    const initMetricsIndex = sessionStorage.getItem("metricsIndex");
    initMetricsIndex && setIndex(initMetricsIndex);
    return () => {
      sessionStorage.setItem("metricsIndex", "0");
    };
  }, []);

  return (
    <MetricsContainer>
      <Box className="page-content">
        {routeParam.type === "sha" || routeParam.type === "df" || routeParam.type === "ura" ? (
          <TabContext value={index}>
            <Tabs
              value={index}
              onChange={handleChangeTab}
              sx={{
                ...tabStyle,
                "& .MuiButtonBase-root": {
                  minHeight: "48px",
                  textTransform: "none",
                },
              }}
            >
              <Tab value="0" label={<IntlMessages id="common.risk.heat.map" />} />
              <Tab value="1" label={<IntlMessages id="common.cycle.time" />} />
            </Tabs>
            <TabPanel value="0">
              <HeatMap />
            </TabPanel>
            <TabPanel value="1">
              <CycleTime />
            </TabPanel>
          </TabContext>
        ) : (
          <TabContext value={index}>
            <Tabs
              value={index}
              onChange={handleChangeTab}
              sx={{
                ...tabStyle,
                "& .MuiButtonBase-root": {
                  minHeight: "48px",
                  textTransform: "none",
                },
              }}
            >
              <Tab value="0" label={<IntlMessages id="common.cycle.time" />} />
              <Tab value="1" label={<IntlMessages id="common.pie.chart" />} />
              <Tab value="2" label={<IntlMessages id="common.bar.chart" />} />
            </Tabs>
            <TabPanel value="0">
              <CycleTime />
            </TabPanel>
            <TabPanel value="1">
              <PieChart />
            </TabPanel>
            <TabPanel value="2">
              <BarChart />
            </TabPanel>
          </TabContext>
        )}
      </Box>
    </MetricsContainer>
  );
}
