import AppScrollbar from "@crema/core/AppScrollbar";
import { Box } from "@mui/material";
import MyTree from "components/MyTree";
// import { ModuleMenuList } from "mock/ModuleData";
import { WorkItems } from "mock/ProductWorkitems";
import React from "react";
import { boxShadow } from "shared/constants/AppConst";

interface WorkitemsProps {
  selectedModule: string;
}

const Workitems: React.FC<WorkitemsProps> = (props) => {
  const [moduleDataSet, setModuleDataSet] = React.useState(WorkItems[props.selectedModule]);
  const [selected, setSelected] = React.useState<any>(moduleDataSet[0].id);

  React.useEffect(() => {
    setModuleDataSet(WorkItems[props.selectedModule]);
  }, [props.selectedModule]);

  const onNodeSelect = (event: object, value: string) => {
    setSelected(value);
  };

  // const onModuleNodeSelect = (event: object, value: string) => {
  //   setSelectedModule(value);
  //   setModuleDataSet(ProductWorkItems[value]);
  // };

  return (
    <Box>
      <Box>
        {/* <Box
          sx={{
            width: "16%",
            borderRight: `1px solid ${borderColor.sidebarBorderRightColor}`,
          }}
        >
          <MyTree
            selected={selectedModule}
            onNodeSelect={onModuleNodeSelect}
            data={ModuleMenuList}
            useStyledTree={true}
            showFolder={true}
          />
        </Box> */}
        <AppScrollbar
          sx={{
            height: "calc(100vh - 224px)",
            width: "100%",
          }}
        >
          <MyTree
            selected={selected}
            onNodeSelect={onNodeSelect}
            data={moduleDataSet}
            useStyledTree={true}
            showFolder={true}
          />
        </AppScrollbar>
      </Box>
      {/* <Box
        sx={{
          height: 56,
          padding: "12px 24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          borderTop: "1px solid  #f0f0f0",
        }}
      >
        <Button variant="contained" onClick={() => {}} color="primary" startIcon={<EditIcon />}>
          <IntlMessages id="common.edit" />
        </Button>
      </Box> */}
    </Box>
  );
};

export default Workitems;
