import IntlMessages from "@crema/utility/IntlMessages";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "components/CustomTable";
import React from "react";
import { boxShadow } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

import ContentHeader from "../components/ContentHeader";

const GroupsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  ".table-container": {
    background: theme.palette.background.paper,
    width: "calc(100% - 40px)",
    height: "calc(100vh - 144px)",
    margin: theme.spacing(5),
    boxShadow: boxShadow.cardBoxShadow,
    padding: "20px",
  },
}));

const columns: Column[] = [
  {
    id: "level",
    label: "common.level",
    minWidth: 150,
    align: "left",
  },
  {
    id: "group_name",
    label: "common.group.name",
    minWidth: 200,
    align: "left",
  },
  {
    id: "count_of_users",
    label: "common.count.of.users",
    minWidth: 100,
    align: "left",
  },
  {
    id: "product",
    label: "common.product",
    minWidth: 300,
    align: "left",
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    minWidth: 120,
    format: (value, record) => {
      // Show more
      return (
        <Box sx={{ color: "#2e6ccb" }} className="flex cursor-pointer">
          <Box>
            <IntlMessages id="common.members" />
          </Box>
          <Box sx={{ mx: 2 }}>|</Box>
          <Box>
            <IntlMessages id="common.edit" />
          </Box>
          <Box sx={{ mx: 2 }}>|</Box>
          <Box>
            <IntlMessages id="dashboard.subscriptions" />
          </Box>
          <Box sx={{ mx: 2 }}>|</Box>
          <Box>
            <IntlMessages id="common.delete" />
          </Box>
        </Box>
      );
    },
  },
];

const initRows = [
  {
    id: 1,
    level: "Company",
    group_name: "Company Admin",
    count_of_users: "3",
    product: "",
  },
  {
    id: 2,
    level: "Company",
    group_name: "Analysis",
    count_of_users: "6",
    product: "",
  },
  {
    id: 3,
    level: "Company",
    group_name: "Development",
    count_of_users: "5",
    product: "",
  },
  {
    id: 4,
    level: "Company",
    group_name: "QA",
    count_of_users: "3",
    product: "",
  },
  {
    id: 5,
    level: "Product",
    group_name: "Product Managers",
    count_of_users: "1",
    product: "Thermometers",
  },
];

const Groups = () => {
  const [rows, setRows] = React.useState<any[]>(initRows);

  return (
    <GroupsContainer>
      <ContentHeader titleId="admin.nav.item.groups" />
      <Box className="table-container">
        <CustomTable
          toolBar={{
            buttons: [
              {
                id: "common.create.new.group",
                icon: TableHeaderButtonsIconType.ADD,
                type: "text",
                color: "#2e6ccb",
                isBlue: true,
                onChange: () => {},
              },
            ],
          }}
          columns={columns}
          rows={rows}
          maxHeight="calc(100vh - 255px)"
          showPage={false}
        />
      </Box>
    </GroupsContainer>
  );
};

export default Groups;
