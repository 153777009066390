import $ from "components/Gojs";
import * as go from "gojs";
import { ReactDiagram } from "gojs-react";
// import analysisPng from "assets/imgs/analysis.png";
import * as React from "react";
import { textColor } from "shared/constants/AppConst";

interface DiagramProps {}

const Diagram: React.FC<DiagramProps> = (props: DiagramProps) => {
  const [nodeDataArray, setNodeDataArray] = React.useState<Array<go.ObjectData>>([
    { key: 1, text: "Hazard", code: "(S#254321)", content: "Therapeutic", figure: "OrGate", choice: "OR" },
    { key: 2, text: "Hazard", code: "(S#254327)", content: "Over-delivery", parent: 1, figure: "OrGate", choice: "OR" },
    {
      key: 3,
      text: "Hazard",
      code: "(S#254297)",
      content: "Delay in therapy",
      parent: 1,
      figure: "OrGate",
      choice: "OR",
    },
    {
      key: 4,
      text: "Hazard",
      code: "(S#254326)",
      content: "Under-delivery",
      parent: 1,
      figure: "OrGate",
      choice: "OR",
    },
    {
      key: 5,
      text: "H. Situation",
      code: "($#2543281)",
      content: "Incorrectly controlled delivery",
      parent: 2,
      figure: "OrGate",
      choice: "OR",
    },
    {
      key: 6,
      text: "H. Situation",
      code: "($#5110131)",
      content: " My test",
      parent: 2,
      figure: "None",
    },
    {
      key: 7,
      text: "H. Situation",
      code: "($#254298)",
      content: "Not able to timely start solution delivery",
      parent: 3,
      figure: "OrGate",
      choice: "OR",
    },
    {
      key: 8,
      text: "H. Situation",
      code: "($#254306)",
      content: "Less than intended drug solution is delivered",
      parent: 4,
      figure: "OrGate",
      // fill: "#fff",
      choice: "OR",
    },
    {
      key: 9,
      text: "Cause",
      code: "($#676982)",
      content: "(1)Infusion rate is faster than programmed",
      parent: 5,
      figure: "None",
    },
    {
      key: 10,
      text: "Cause",
      code: "($#256620)",
      content: "User/operator not able to setup the pump system timely",
      parent: 7,
      figure: "None",
    },
    {
      key: 11,
      text: "Cause",
      code: "($#256620)",
      content: "User/operator not able to setup the pump system timely",
      parent: 8,
      figure: "None",
    },
  ]);
  const [linkDataArray, setLinkDataArray] = React.useState<Array<go.ObjectData>>([
    { key: 1, from: 1, to: 2 },
    { key: 2, from: 1, to: 3 },
    { key: 3, from: 1, to: 4 },
    { key: 4, from: 2, to: 5 },
    { key: 5, from: 2, to: 6 },
    { key: 6, from: 3, to: 7 },
    { key: 7, from: 4, to: 8 },
    { key: 8, from: 5, to: 9 },
    { key: 9, from: 7, to: 10 },
    { key: 10, from: 8, to: 11 },
  ]);
  const ref = React.useRef();

  const textStyle = (field) => {
    return [
      {
        font: "14px Roboto, sans-serif",
        stroke: textColor[85],
        visible: false, // only show textblocks when there is corresponding data for them
      },
      new go.Binding("visible", field, (val) => val !== undefined),
    ];
  };

  const initDiagram = (): go.Diagram => {
    const KAPPA = 4 * ((Math.sqrt(2) - 1) / 3);

    go.Shape.defineFigureGenerator("OrGate", function (shape, w, h) {
      const geo = new go.Geometry();
      const radius = 0.5;
      const cpOffset = KAPPA * radius;
      const centerx = 0;
      const centery = 0.5;
      const fig = new go.PathFigure(0, 0, true);
      geo.add(fig);

      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          w,
          0.5 * h,
          (centerx + cpOffset + cpOffset) * w,
          (centery - radius) * h,
          0.8 * w,
          (centery - cpOffset) * h
        )
      );
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          h,
          0.8 * w,
          (centery + cpOffset) * h,
          (centerx + cpOffset + cpOffset) * w,
          (centery + radius) * h
        )
      );
      fig.add(new go.PathSegment(go.PathSegment.Bezier, 0, 0, 0.25 * w, 0.75 * h, 0.25 * w, 0.25 * h).close());
      geo.spot1 = new go.Spot(0.2, 0.25);
      geo.spot2 = new go.Spot(0.75, 0.75);
      return geo;
    });

    const diagram = $(go.Diagram, {
      padding: 10,
      allowCopy: false,
      allowDelete: false,
      "draggingTool.dragsTree": true,
      layout: $(go.TreeLayout, { angle: 90, layerSpacing: 30 }),
      "undoManager.isEnabled": true,
    });

    diagram.nodeTemplate = $(
      go.Node,
      "Spot",
      {
        locationSpot: go.Spot.Top,
        isShadowed: true,
        shadowBlur: 1,
        shadowOffset: new go.Point(0, 1),
        shadowColor: "rgba(0, 0, 0, .14)",
        // selection adornment to match shape of nodes
        selectionAdornmentTemplate: $(
          go.Adornment,
          "Auto",
          $(
            go.Shape,
            "RoundedRectangle",
            {
              parameter1: 2, // set the rounded corner
              spot1: go.Spot.TopLeft,
              spot2: go.Spot.BottomRight, // make content go all the way to inside edges of rounded corners
            },
            { fill: null, stroke: "#7986cb", strokeWidth: 3 }
          ),
          $(go.Placeholder)
        ), // end Adornment
      },
      // $(
      //   go.Shape,
      //   "RoundedRectangle",
      //   {
      //     parameter1: 2, // set the rounded corner
      //     spot1: go.Spot.TopLeft,
      //     spot2: go.Spot.BottomRight, // make content go all the way to inside edges of rounded corners
      //     width: 200,
      //   },

      //   { name: "SHAPE", fill: "#ffffff", strokeWidth: 0 },
      //   // gold if highlighted, white otherwise
      //   new go.Binding("fill", "isHighlighted", (h) => (h ? "gold" : "#FFBE71")).ofObject()
      // ),
      $(
        go.Panel,
        "Table",
        {
          width: 200,
          background: "#FFBE71",
        },
        $(
          go.TextBlock,
          {
            row: 0,
            columnSpan: 2,
            alignment: go.Spot.Center,
            font: "14px Roboto, sans-serif",
            stroke: textColor[85],
            maxSize: new go.Size(160, NaN),
          },
          new go.Binding("text", "text")
        ),
        $(
          go.TextBlock,
          textStyle("code"),
          {
            row: 1,
            columnSpan: 2,
            alignment: go.Spot.Center,
            font: "14px Roboto, sans-serif",
            stroke: "#2e6ccb",
            click: (a, b) => {
              console.log(123, a, b);
            },
            cursor: "pointer",
            maxSize: new go.Size(160, NaN),
          },
          new go.Binding("text", "code")
        ),
        $(
          go.TextBlock,
          textStyle("content"),
          {
            row: 2,
            alignment: go.Spot.Left,
            columnSpan: 2,
            width: 200,
            font: "14px Roboto, sans-serif",
            stroke: textColor[85],
            margin: new go.Margin(4, 0, 0, 0),
            maxSize: new go.Size(160, NaN),
          },
          new go.Binding("text", "content")
        ),
        $("TreeExpanderButton", { row: 3, columnSpan: 99, alignment: go.Spot.Center })
      ),
      $(go.Shape, "LineV", new go.Binding("visible", "figure", (f) => f !== "None"), {
        strokeWidth: 1.5,
        height: 10,
        margin: 0,
        alignment: new go.Spot(0.5, 1, 0, -1),
        alignmentFocus: go.Spot.Top,
      }),
      $(
        go.Shape,
        new go.Binding("visible", "figure", (f) => f !== "None"),
        {
          alignment: new go.Spot(0.5, 1, 0, 10),
          alignmentFocus: go.Spot.Top,
          width: 30,
          height: 30,
          stroke: textColor[85],
          angle: -90,
          fill: "#fff",
          figure: "OrGate",
        }
        // new go.Binding("figure", "figure")
        // new go.Binding("fill", "figure", nodeFillConverter),
        // new go.Binding("angle", "figure", (f) => (f === "OrGate" || f === "AndGate" ? -90 : 0))
      ), // ORs and ANDs should point upwards
      $(
        go.TextBlock,
        new go.Binding("visible", "figure", (f) => f !== "None"), // if we don't have a figure, don't display any choice text
        {
          alignment: new go.Spot(0.5, 1, 0, 27),
          alignmentFocus: go.Spot.Center,
          stroke: textColor[85],
          font: "8pt Segoe UI, sans-serif",
        },
        new go.Binding("text", "choice")
      )
    );

    // diagram.linkTemplate = $(
    //   go.Link,
    //   go.Link.Orthogonal,
    //   { layerName: "Background", curviness: 20, corner: 5 },
    //   $(go.Shape, { strokeWidth: 1.5 })
    // );
    console.log(go.Shape.getFigureGenerators().toArray());
    return diagram;
  };

  return (
    <>
      <ReactDiagram
        ref={ref}
        style={{ width: "100%", height: "800px" }}
        divClassName="diagram-component"
        initDiagram={initDiagram}
        nodeDataArray={nodeDataArray}
        linkDataArray={linkDataArray}
      />
      {/* <img alt="" src={analysisPng} /> */}
    </>
  );
};

export default Diagram;
