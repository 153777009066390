import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Checkbox, Grid, IconButton, ListItemText, MenuItem, TextField } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import React from "react";
import { textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import Comments from "../Comments";
import RichText from "../RichText";

const columns: Column[] = [
  {
    id: "order",
    label: "common.step.order",
    minWidth: 100,
    align: "left",
  },
  {
    id: "content",
    label: "workspace.column.content",
    minWidth: 300,
    align: "left",
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    minWidth: 60,
    align: "left",
    format: (value, record) => {
      return (
        <Box>
          <DeleteIcon sx={{ cursor: "pointer", fontSize: "1.25rem" }} />
        </Box>
      );
    },
  },
];

const rows: any[] = [
  {
    id: "1",
    content: "Set the fever threshold to 38oC",
    order: "1",
  },
  {
    id: "2",
    content: "Set the temperature simulator to 38.5oC",
    order: "2",
  },
  {
    id: "3",
    content: "Wait 20 seconds +/- 2 seconds",
    order: "3",
  },
];

const DetailsContainer = styled(Box)(({ theme }) => ({
  padding: "12px 0",
  minWidth: "1000px",
  ".details-content": {
    marginTop: "12px",
    color: textColor[85],
  },
  ".details-content-title": {
    padding: "4px 0",
    color: textColor[85],
    fontWeight: "bold",
  },
}));

interface RequireDetailsProps {
  type: string;
}

const RequireDetails: React.FC<RequireDetailsProps> = (props: RequireDetailsProps) => {
  const { type = "und" } = props;
  console.log(props);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [richText, setRichText] = React.useState("test");
  const [globalId, setGlobalId] = React.useState("GID#1234");
  const [priority, setPriority] = React.useState("High");
  const [status, setStatus] = React.useState("Draft");
  const [criticality, setCriticality] = React.useState("");
  const [rationale, setRationale] = React.useState("");
  const [result, setResult] = React.useState("");
  const [supplies, setSupplies] = React.useState("Temperature Simulator");
  const [userTypes, setUserTypes] = React.useState<string[]>([]);

  const handleSave = () => {
    console.log(richText, edit, priority);
    setEdit(false);
  };

  const handleCancel = () => {
    setEdit(false);
  };

  return (
    <DetailsContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: edit ? "none" : "inline-block" }}>
          <Button
            onClick={() => {
              setEdit(true);
            }}
            variant="outlined"
            color="inherit"
            startIcon={<AppSvgIcon size={16}>{"material-solid:edit"}</AppSvgIcon>}
          >
            <IntlMessages id="common.edit" />
          </Button>
        </Box>
        <Box sx={{ display: edit ? "inline-block" : "none" }}>
          <Button
            className="text-color-08"
            sx={{
              mr: 3,
              borderRadius: "4px",
              border: "solid 1px rgba(0, 0, 0, 0.15)",
              color: textColor["85"],
            }}
            onClick={handleCancel}
            startIcon={
              <AppSvgIcon size={16} color={textColor["65"]}>
                {"material-solid:cancel"}
              </AppSvgIcon>
            }
            variant="outlined"
          >
            <IntlMessages id="common.cancel" />
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            startIcon={
              <AppSvgIcon size={16} color="#FFF">
                {"material-solid:save"}
              </AppSvgIcon>
            }
            variant="contained"
          >
            <IntlMessages id="common.save" />
          </Button>
        </Box>
      </Box>
      <Box className="details-content">
        <Grid container spacing={4}>
          <Grid item xs={4} md={4}>
            <Box>
              <Box className="details-content-title">
                <IntlMessages id="common.global.id" />
              </Box>
              <Box>
                <TextField
                  variant={edit ? "outlined" : "standard"}
                  value={globalId}
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setGlobalId(event.target.value);
                  }}
                  disabled={!edit}
                  InputProps={
                    edit
                      ? {
                          endAdornment: (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: globalId ? "visible" : "hidden" }}
                                onClick={() => {
                                  setGlobalId("");
                                }}
                              >
                                <AppSvgIcon size={16} color="action">
                                  {"material-outline:clear"}
                                </AppSvgIcon>
                              </IconButton>
                            </Box>
                          ),
                        }
                      : {}
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box>
              <Box className="details-content-title">
                <IntlMessages id="common.priority" />
              </Box>
              <Box>
                <Select
                  value={priority}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPriority(event.target.value);
                  }}
                  variant={edit ? "outlined" : "standard"}
                  disabled={!edit}
                  fullWidth
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Med">Med</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                </Select>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box>
              <Box className="details-content-title">
                <IntlMessages id="common.status" />
              </Box>
              <Box>
                <Select
                  value={status}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setStatus(event.target.value);
                  }}
                  variant={edit ? "outlined" : "standard"}
                  disabled={!edit}
                  fullWidth
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="Draft">Draft</MenuItem>
                  <MenuItem value="In Review">In Review</MenuItem>
                  <MenuItem value="Approved">Approved</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                </Select>
              </Box>
            </Box>
          </Grid>
          {(type === "dir" || type === "TM") && (
            <Grid item xs={4} md={4}>
              <Box>
                <Box className="details-content-title">
                  <IntlMessages id="common.requirement.criticality" />
                </Box>
                <Box>
                  <Select
                    value={criticality}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCriticality(event.target.value);
                    }}
                    variant={edit ? "outlined" : "standard"}
                    disabled={!edit}
                    fullWidth
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="Normal">Normal</MenuItem>
                    <MenuItem value="CTQ">CTQ</MenuItem>
                    <MenuItem value="CTS">CTS</MenuItem>
                    <MenuItem value="KPI">KPI</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Box sx={{ mt: 2 }} className="details-content-title">
          <IntlMessages id="common.description" />
        </Box>
        <Box sx={{ width: "100%" }}>
          {edit ? (
            <RichText value={richText} handleValue={setRichText} />
          ) : (
            <AppScrollbar
              sx={{
                height: "100px",
                width: "100%",
                border: "1px solid rgba(1,1,1,0.12)",
              }}
            >
              <Box dangerouslySetInnerHTML={{ __html: richText }}></Box>
            </AppScrollbar>
          )}
        </Box>
        {type === "und" && (
          <Box sx={{ mt: 2 }}>
            <Box className="details-content-title">
              <IntlMessages id="common.user.types" />
            </Box>
            <Box>
              <Select
                value={userTypes}
                onChange={(event: SelectChangeEvent<typeof userTypes>) => {
                  const {
                    target: { value },
                  } = event;
                  setUserTypes(typeof value === "string" ? value.split(",") : value);
                }}
                variant={edit ? "outlined" : "standard"}
                disabled={!edit}
                fullWidth
                multiple
                renderValue={(selected) => selected.join(", ")}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Patient">
                  <Checkbox checked={userTypes.indexOf("Patient") > -1} />
                  <ListItemText primary="Patient" />
                </MenuItem>
                <MenuItem value="Healthcare">
                  <Checkbox checked={userTypes.indexOf("Healthcare") > -1} />
                  <ListItemText primary="Healthcare" />
                </MenuItem>
                <MenuItem value="Provider">
                  <Checkbox checked={userTypes.indexOf("Provider") > -1} />
                  <ListItemText primary="Provider" />
                </MenuItem>
                <MenuItem value="Caregiver">
                  <Checkbox checked={userTypes.indexOf("Caregiver") > -1} />
                  <ListItemText primary="Caregiver" />
                </MenuItem>
                <MenuItem value="Healthcare Provider">
                  <Checkbox checked={userTypes.indexOf("Healthcare Provider") > -1} />
                  <ListItemText primary="Healthcare Provider" />
                </MenuItem>
                <MenuItem value="Business">
                  <Checkbox checked={userTypes.indexOf("Business") > -1} />
                  <ListItemText primary="Business" />
                </MenuItem>
              </Select>
            </Box>
          </Box>
        )}
        {type !== "tc" && (
          <Box sx={{ mt: 2 }}>
            <Box className="details-content-title">
              <IntlMessages id="common.rationale" />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                value={rationale}
                fullWidth
                multiline
                rows="3"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setRationale(event.target.value);
                }}
                disabled={!edit}
                InputProps={
                  edit
                    ? {
                        endAdornment: (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              title="Clear"
                              aria-label="Clear"
                              size="small"
                              style={{ visibility: rationale ? "visible" : "hidden" }}
                              onClick={() => {
                                setRationale("");
                              }}
                            >
                              <AppSvgIcon size={16} color="action">
                                {"material-outline:clear"}
                              </AppSvgIcon>
                            </IconButton>
                          </Box>
                        ),
                      }
                    : {}
                }
              />
            </Box>
          </Box>
        )}
        {type === "tc" && (
          <Box sx={{ mt: 2 }}>
            <Box className="details-content-title">
              <IntlMessages id="common.test.steps" />
            </Box>
            <Box
              sx={{
                ".my_table_container": {
                  marginTop: "0px",
                },
              }}
            >
              <CustomTable
                columns={columns}
                rows={rows}
                showPage={false}
                maxHeight="auto"
                addRow={() => {}}
                addRowTextId="common.add"
              />
            </Box>
          </Box>
        )}
        {type === "tc" && (
          <Box sx={{ mt: 2 }}>
            <Box className="details-content-title">
              <IntlMessages id="common.equipment.supplies.needed" />
            </Box>
            <Box>
              <Select
                value={supplies}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSupplies(event.target.value);
                }}
                variant={edit ? "outlined" : "standard"}
                disabled={!edit}
                fullWidth
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Temperature Simulator">Temperature Simulator</MenuItem>
                <MenuItem value="Alcohol Wipes">Alcohol Wipes</MenuItem>
              </Select>
            </Box>
          </Box>
        )}
        {type === "tc" && (
          <Box sx={{ mt: 2 }}>
            <Box className="details-content-title">
              <IntlMessages id="common.result" />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                value={result}
                fullWidth
                multiline
                rows="3"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setResult(event.target.value);
                }}
                disabled={!edit}
                InputProps={
                  edit
                    ? {
                        endAdornment: (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              title="Clear"
                              aria-label="Clear"
                              size="small"
                              style={{ visibility: result ? "visible" : "hidden" }}
                              onClick={() => {
                                setResult("");
                              }}
                            >
                              <AppSvgIcon size={16} color="action">
                                {"material-outline:clear"}
                              </AppSvgIcon>
                            </IconButton>
                          </Box>
                        ),
                      }
                    : {}
                }
              />
            </Box>
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          <Box className="details-content-title">
            <IntlMessages id="common.comments" />
          </Box>
          <Comments />
        </Box>
      </Box>
    </DetailsContainer>
  );
};

export default RequireDetails;
