import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import CircularLoading from "components/CircularLoading";
import AddIcon from "components/Icons/AddIcon";
import MyCollapseTable from "components/MyCollapseTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import { P2Model, RuleEnum } from "../RiskPolicy/models";
import CheckboxRadioGroup, { CheckboxGroupOutputModel, GroupItem } from "./CheckboxRadioGroup";

const Container = styled(Box)(({ theme }) => ({
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell": {
    border: `1px solid  ${borderColor.dividerColor}`,
  },
  ".table-toolbar": {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(0),
    ".table-toolbar-actions": {
      ".refresh-btn": {
        color: textColor[85],
      },
      ".add-button": {
        marginLeft: theme.spacing(2),
      },
    },
  },
}));

const initColumns: Column[] = [
  {
    id: "description",
    label: "common.description",
  },
  {
    id: "abbreviation",
    label: "common.abbreviation",
  },
  {
    id: "value",
    label: "common.value",
  },
  {
    id: "probability",
    label: "common.probability",
  },
  {
    id: "displayOrder",
    label: "setting.product.column.displayOrder",
  },
  {
    id: "summary",
    label: "common.summary",
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    format: (value, record) => {
      return <></>;
    },
  },
];

const createData = async (rowId) => {
  const p = new Promise<P2Model[]>((resolve, reject) => {
    setTimeout(() => {
      resolve([
        {
          id: `${rowId}01`,
          description: "1",
          abbreviation: "1",
          value: "1",
          probability: "0.000000",
          displayOrder: 1,
          summary: "",
        },
        {
          id: `${rowId}02`,
          description: "2",
          abbreviation: "2",
          value: "2",
          probability: "0.000000",
          displayOrder: 2,
          summary: "",
        },
        {
          id: `${rowId}03`,
          description: "3",
          abbreviation: "3",
          value: "3",
          probability: "0.000000",
          displayOrder: 3,
          summary: "",
        },
        {
          id: `${rowId}04`,
          description: "4",
          abbreviation: "4",
          value: "4",
          probability: "0.000000",
          displayOrder: 4,
          summary: "",
        },
        {
          id: `${rowId}05`,
          description: "5",
          abbreviation: "5",
          value: "5",
          probability: "0.000000",
          displayOrder: 5,
          summary: "",
        },
      ]);
    }, 200);
  });
  const r = await p;
  return r;
};
interface P2TabData {
  rowsData?: P2Model[];
  groupItems?: GroupItem[];
  p2Checkbox?: CheckboxGroupOutputModel;
}
interface P2Props {
  rowId?: number | string;
  editable?: boolean;
  tabData?: P2TabData;
  onServerComplete?: (data: P2TabData) => void;
}
const P2 = (props: P2Props) => {
  const { rowId, editable, tabData, onServerComplete } = props;

  const [groupItems, setGroupItems] = useState<GroupItem[]>(tabData && tabData.groupItems ? tabData.groupItems : []);
  const [p2Checkbox, setP2Checkbox] = useState<CheckboxGroupOutputModel>(
    tabData && tabData.p2Checkbox ? tabData.p2Checkbox : {}
  );

  const [columns, setColumns] = useState([...initColumns]);
  const [rows, setRows] = useState<P2Model[]>(tabData && tabData.rowsData ? tabData.rowsData : []);
  const [loading, setLoading] = useState(true);

  const createDataFn = async (rowId) => {
    setLoading(true);
    const a = await createData(rowId);
    setRows(a);

    const g: GroupItem[] = [
      {
        key: RuleEnum.AutomaticPassDownstream,
        defaultValue: true,
        type: "checkbox",
      },
    ];
    const cg = {
      [RuleEnum.AutomaticPassDownstream]: true,
    };
    setGroupItems(g);
    // setP2Checkbox(cg);
    onServerComplete &&
      onServerComplete({
        rowsData: a,
        groupItems: g,
        p2Checkbox: cg,
      });
    setLoading(false);
  };

  useEffect(() => {
    if (!tabData || !tabData.rowsData || tabData.rowsData.length === 0) createDataFn(rowId);
    else setLoading(false);
  }, []);

  useEffect(() => {
    // console.log(p2Checkbox);
  }, [p2Checkbox]);

  const handleP2CheckboxChange = useCallback(
    (output: CheckboxGroupOutputModel) => {
      setP2Checkbox(output);
      onServerComplete &&
        onServerComplete({
          rowsData: rows,
          groupItems: groupItems,
          p2Checkbox: output,
        });
    },
    [rows, onServerComplete, groupItems]
  );

  const handleEditClick = (row: P2Model) => {
    console.log("P2 handleEditClick: ", row);
  };

  const handleDeleteClick = (row: P2Model) => {
    console.log("P2 handleDeleteClick: ", row);
  };

  useEffect(() => {
    const newColumns: Column[] = [];
    initColumns.forEach((c) => {
      if (c.id === "actions" && editable) {
        newColumns.push({
          id: "actions",
          label: "setting.product.column.actions",
          format: (value, record) => {
            return (
              <Box>
                <IconButton
                  sx={{ padding: "2px" }}
                  onClick={() => {
                    handleEditClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:edit"}
                  </AppSvgIcon>
                </IconButton>
                <IconButton
                  sx={{ padding: "2px" }}
                  onClick={() => {
                    handleDeleteClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:delete"}
                  </AppSvgIcon>
                </IconButton>
              </Box>
            );
          },
        });
      } else {
        newColumns.push(c);
      }
    });
    setColumns([...newColumns]);
  }, [editable]);

  const handleAddClick = () => {
    console.log("P2 handleAddClick");
  };
  const toolbar = useMemo(() => {
    if (loading) {
      return <></>;
    } else {
      return (
        <Box className="table-toolbar flex justify-between">
          <Box sx={{ padding: "0 8px" }}>
            <CheckboxRadioGroup
              selected={tabData ? tabData.p2Checkbox : undefined}
              groups={groupItems}
              onGroupChange={handleP2CheckboxChange}
            />
          </Box>
          {editable ? (
            <Box className="table-toolbar-actions flex justify-center items-center">
              <Button
                className="refresh-btn"
                startIcon={<AppSvgIcon color={textColor["65"]}>{"material-outline:refresh"}</AppSvgIcon>}
              >
                <IntlMessages id="common.refresh" />
              </Button>
              <Button
                variant="text"
                className="add-button"
                color="primary"
                startIcon={<AddIcon isBlue={true} />}
                onClick={handleAddClick}
              >
                <IntlMessages id="common.add" />
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      );
    }
  }, [loading, tabData, groupItems, handleP2CheckboxChange, editable]);
  return (
    <>
      <CircularLoading loading={loading}>
        <Container>
          {!loading ? (
            <MyCollapseTable
              toolbar={toolbar}
              columns={columns}
              rows={rows}
              showPage={true}
              collapse={false}
            ></MyCollapseTable>
          ) : (
            <></>
          )}
        </Container>
      </CircularLoading>
    </>
  );
};

export default P2;
