export const UPDATE_MODULE_LIST = "UPDATE_MODULE_LIST";
export const UPDATE_FEEDS_TYPE = "UPDATE_FEEDS_TYPE";
export const UPDATE_ACTIVITIES_TYPE = "UPDATE_ACTIVITIES_TYPE";
export const UPDATE_DETAIL_MENU = "UPDATE_DETAIL_MENU";


export interface ModuleListChangeAction {
  type: typeof UPDATE_MODULE_LIST;
  moduleList: any[];
}

export interface FeedsTypeChangeAction {
  type: typeof UPDATE_FEEDS_TYPE;
  feedsType: number;
}

export interface ACTIVITIESTypeChangeAction {
  type: typeof UPDATE_ACTIVITIES_TYPE;
  activitiesType: number;
}

export interface WorkspaceDetailMenuChangeAction {
  type: typeof UPDATE_DETAIL_MENU;
  workspacesDetailMenu: string;
}


export type WorkspacesActionTypes =
  | FeedsTypeChangeAction
  | ACTIVITIESTypeChangeAction
  | WorkspaceDetailMenuChangeAction
  | ModuleListChangeAction;
