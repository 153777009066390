import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Card, IconButton, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useState } from "react";
import { bgColor, boxShadow, textColor } from "shared/constants/AppConst";

import { RuleTabEnum } from "../RiskPolicy/models";
import Acceptance from "./Acceptance";
import ControlOption from "./ControlOption";
import P1 from "./P1";
import P2 from "./P2";
import Ph from "./Ph";
import Rename from "./Rename";
import Severity from "./Severity";

const CardContainer = styled(Card)(({ theme }) => ({
  "&.MuiCard-root": {
    backgroundColor: "transparent",
    padding: theme.spacing(0),
    boxShadow: "none",
    borderRadius: 0,
    ".MuiCardHeader-root": {
      padding: theme.spacing(4, 0),
    },
    ".MuiTabs-root": {
      backgroundColor: "#dce0e6",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
      minHeight: "auto",
      marginBottom: theme.spacing(3),
      ".MuiTabs-scroller": {
        width: "auto",
      },
      ".MuiTab-root": {
        padding: "1px 8px",
        minWidth: "auto",
        minHeight: "24px",
        textTransform: "capitalize",
        color: textColor[65],
        fontWeight: "400",
      },
      ".Mui-selected": {
        color: textColor[85],
        borderRadius: "2px",
        boxShadow: boxShadow.cardBoxShadow,
        backgroundColor: theme.palette.background.paper,
        fontWeight: "500",
      },
      ".MuiTabs-indicator": {
        display: "none",
      },
    },
  },
  ".tab-sub-table": {
    ".table_header_cell, .table_header": {
      background: bgColor.tableHeaderColor,
      color: textColor[85],
      fontWeight: "bold",
      letterSpacing: "0.15px",
      fontSize: "14px",
      border: 0,
    },
    ".table_body_cell": {
      background: theme.palette.background.paper,
      color: textColor[85],
      fontWeight: "normal",
      fontSize: "14px",
    },
    ".table_cell": {
      borderLeft: "none",
      borderRight: "none",
    },
  },
}));

interface TabTableProps {
  rowId?: number | string;
  editable?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number | string) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabTable = (props: TabTableProps) => {
  const { rowId, editable } = props;
  const [value, setValue] = useState(RuleTabEnum.Severity);

  const [tabDatas, setTabDatas] = useState<{ [key: string]: any | any[] }>({});

  const handleTabChange = (event: React.SyntheticEvent, newValue: RuleTabEnum) => {
    setValue(newValue);
  };

  const handleTabTableDataComplete = useCallback(
    (key: number | string, rows: any) => {
      setTabDatas({
        ...tabDatas,
        [key]: rows,
      });
    },
    [tabDatas]
  );

  return (
    <>
      <CardContainer>
        <Box className="flex">
          <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab
              value={RuleTabEnum.Severity}
              label={<IntlMessages id="setting.rule.tab.severity" />}
              {...a11yProps(RuleTabEnum.Severity)}
            />
            <Tab
              value={RuleTabEnum.P1}
              label={<IntlMessages id="setting.rule.tab.P1" />}
              {...a11yProps(RuleTabEnum.P1)}
            />
            <Tab
              value={RuleTabEnum.P2}
              label={<IntlMessages id="setting.rule.tab.P2" />}
              {...a11yProps(RuleTabEnum.P2)}
            />
            <Tab
              value={RuleTabEnum.Ph}
              label={<IntlMessages id="setting.rule.tab.Ph" />}
              {...a11yProps(RuleTabEnum.Ph)}
            />
            <Tab
              value={RuleTabEnum.Acceptance}
              label={<IntlMessages id="setting.rule.tab.acceptance" />}
              {...a11yProps(RuleTabEnum.Acceptance)}
            />
            <Tab
              value={RuleTabEnum.ControlOption}
              label={<IntlMessages id="setting.rule.tab.controlOption" />}
              {...a11yProps(RuleTabEnum.ControlOption)}
            />
            <Tab
              value={RuleTabEnum.Rename}
              label={<IntlMessages id="setting.rule.tab.rename" />}
              {...a11yProps(RuleTabEnum.Rename)}
            />
          </Tabs>
        </Box>
        <Box className="tab-sub-table">
          <TabPanel value={value} index={RuleTabEnum.Severity}>
            <Severity
              rowId={rowId}
              editable={editable}
              rowsData={tabDatas[RuleTabEnum.Severity]}
              onServerComplete={(rows) => handleTabTableDataComplete(RuleTabEnum.Severity, rows)}
            />
          </TabPanel>
          <TabPanel value={value} index={RuleTabEnum.P1}>
            <P1
              rowId={rowId}
              editable={editable}
              rowsData={tabDatas[RuleTabEnum.P1]}
              onServerComplete={(rows) => handleTabTableDataComplete(RuleTabEnum.P1, rows)}
            />
          </TabPanel>
          <TabPanel value={value} index={RuleTabEnum.P2}>
            <P2
              rowId={rowId}
              editable={editable}
              tabData={tabDatas[RuleTabEnum.P2]}
              onServerComplete={(d) => handleTabTableDataComplete(RuleTabEnum.P2, d)}
            />
          </TabPanel>
          <TabPanel value={value} index={RuleTabEnum.Ph}>
            <Ph
              rowId={rowId}
              editable={editable}
              tabData={tabDatas[RuleTabEnum.Ph]}
              onServerComplete={(d) => handleTabTableDataComplete(RuleTabEnum.Ph, d)}
            />
          </TabPanel>
          <TabPanel value={value} index={RuleTabEnum.Acceptance}>
            <Acceptance
              rowId={rowId}
              editable={editable}
              tabData={tabDatas[RuleTabEnum.Acceptance]}
              onServerComplete={(d) => handleTabTableDataComplete(RuleTabEnum.Acceptance, d)}
            />
          </TabPanel>
          <TabPanel value={value} index={RuleTabEnum.ControlOption}>
            <ControlOption
              rowId={rowId}
              editable={editable}
              rowsData={tabDatas[RuleTabEnum.ControlOption]}
              onServerComplete={(rows) => handleTabTableDataComplete(RuleTabEnum.ControlOption, rows)}
            />
          </TabPanel>
          <TabPanel value={value} index={RuleTabEnum.Rename}>
            <Rename
              rowId={rowId}
              editable={editable}
              rowsData={tabDatas[RuleTabEnum.Rename]}
              onServerComplete={(rows) => handleTabTableDataComplete(RuleTabEnum.Rename, rows)}
            />
          </TabPanel>
        </Box>
      </CardContainer>
    </>
  );
};

export default TabTable;
