import $ from "components/Gojs";
import * as go from "gojs";
import { ReactDiagram } from "gojs-react";
// import analysisPng from "assets/imgs/analysis.png";
import * as React from "react";
import { textColor } from "shared/constants/AppConst";

interface DiagramProps {}

const Diagram: React.FC<DiagramProps> = (props: DiagramProps) => {
  const [nodeDataArray, setNodeDataArray] = React.useState<Array<go.ObjectData>>([
    { key: 1, text: "Calm:Following risk mitigated", code: "(S#1375)", content: "underinfusion", figure: "OrGate" },
    {
      key: 2,
      text: "Calm:Following risk mitigated",
      code: "(S#1388`)",
      content: "Under-delivery",
      parent: 1,
      figure: "OrGate",
    },
    {
      key: 3,
      text: "Calm:Following risk mitigated",
      code: "(S#1670)",
      content: "Infusion rate not accurate",
      parent: 2,
      figure: "OrGate",
    },
    {
      key: 4,
      text: "Calm:Following risk mitigated",
      code: "(S#1620)",
      content: "Occlusion",
      parent: 2,
      figure: "OrGate",
    },
    {
      key: 5,
      text: "Calm:Following risk mitigated",
      code: "($#1621)",
      content: "inaccurate flow rate",
      parent: 2,
      figure: "OrGate",
    },
    {
      key: 6,
      text: "Calm:Following risk mitigated",
      code: "($#1389)",
      content: "Another Cause",
      parent: 2,
      figure: "OrGate",
    },
  ]);
  const [linkDataArray, setLinkDataArray] = React.useState<Array<go.ObjectData>>([
    { key: 1, from: 1, to: 2 },
    { key: 2, from: 2, to: 3 },
    { key: 3, from: 2, to: 4 },
    { key: 4, from: 2, to: 5 },
    { key: 5, from: 2, to: 6 },
  ]);
  const ref = React.useRef();

  const textStyle = (field) => {
    return [
      {
        font: "14px Roboto, sans-serif",
        stroke: textColor[85],
        visible: false, // only show textblocks when there is corresponding data for them
      },
      new go.Binding("visible", field, (val) => val !== undefined),
    ];
  };

  const initDiagram = (): go.Diagram => {
    const diagram = $(go.Diagram, {
      padding: 10,
      allowCopy: false,
      allowDelete: false,
      "draggingTool.dragsTree": true,
      layout: $(go.TreeLayout, { angle: 90, layerSpacing: 30 }),
      "undoManager.isEnabled": true,
    });

    diagram.nodeTemplate = $(
      go.Node,
      "Spot",
      {
        locationSpot: go.Spot.Top,
        isShadowed: true,
        shadowBlur: 1,
        shadowOffset: new go.Point(0, 1),
        shadowColor: "rgba(0, 0, 0, .14)",
        // selection adornment to match shape of nodes
        selectionAdornmentTemplate: $(
          go.Adornment,
          "Auto",
          $(
            go.Shape,
            "RoundedRectangle",
            {
              parameter1: 2, // set the rounded corner
              spot1: go.Spot.TopLeft,
              spot2: go.Spot.BottomRight, // make content go all the way to inside edges of rounded corners
            },
            { fill: null, stroke: "#7986cb", strokeWidth: 3 }
          ),
          $(go.Placeholder)
        ), // end Adornment
      },
      // $(
      //   go.Shape,
      //   "RoundedRectangle",
      //   {
      //     parameter1: 2, // set the rounded corner
      //     spot1: go.Spot.TopLeft,
      //     spot2: go.Spot.BottomRight, // make content go all the way to inside edges of rounded corners
      //     width: 300,
      //   },

      //   { name: "SHAPE", fill: "#ffffff", strokeWidth: 0 },
      //   // gold if highlighted, white otherwise
      //   new go.Binding("fill", "isHighlighted", (h) => (h ? "gold" : "#FFBE71")).ofObject()
      // ),
      $(
        go.Panel,
        "Table",
        {
          width: 300,
          background: "#FFBE71",
        },
        $(
          go.TextBlock,
          {
            row: 0,
            columnSpan: 2,
            width: 300,
            alignment: go.Spot.Center,
            font: "14px Roboto, sans-serif",
            stroke: textColor[85],
            maxSize: new go.Size(160, NaN),
          },
          new go.Binding("text", "text")
        ),
        $(
          go.TextBlock,
          textStyle("code"),
          {
            row: 1,
            columnSpan: 2,
            alignment: go.Spot.Center,
            font: "14px Roboto, sans-serif",
            stroke: "#2e6ccb",
            click: (a, b) => {
              console.log(123, a, b);
            },
            cursor: "pointer",
            maxSize: new go.Size(160, NaN),
          },
          new go.Binding("text", "code")
        ),
        $(
          go.TextBlock,
          textStyle("content"),
          {
            row: 2,
            alignment: go.Spot.Left,
            columnSpan: 2,
            width: 300,
            font: "14px Roboto, sans-serif",
            stroke: textColor[85],
            margin: new go.Margin(4, 0, 0, 0),
            maxSize: new go.Size(160, NaN),
          },
          new go.Binding("text", "content")
        ),
        $(
          go.TextBlock,
          {
            row: 3,
            alignment: go.Spot.Left,
            columnSpan: 2,
            width: 300,
            font: "14px Roboto, sans-serif",
            stroke: "blue",
            maxSize: new go.Size(160, NaN),
          },
          new go.Binding("text", "", (text) => {
            return "Content(Ct):";
          })
        ),
        $(
          go.TextBlock,
          {
            row: 4,
            alignment: go.Spot.Left,
            columnSpan: 2,
            font: "14px Roboto, sans-serif",
            stroke: "blue",
            maxSize: new go.Size(160, NaN),
          },
          new go.Binding("text", "", (text) => {
            return "Assumption(As):";
          })
        )
      ),
      $(go.Shape, "LineV", new go.Binding("visible", "figure", (f) => f !== "None"), {
        strokeWidth: 1.5,
        height: 10,
        margin: 4,
        alignment: new go.Spot(0.5, 1, 0, -1),
        alignmentFocus: go.Spot.Top,
      }),
      $(
        go.Shape,
        new go.Binding("visible", "figure", (f) => f !== "None"),
        {
          alignment: new go.Spot(0.5, 1, 0, 10),
          alignmentFocus: go.Spot.Top,
          width: 30,
          height: 30,
          stroke: textColor[85],
          angle: -90,
          fill: "#fff",
        }
        // new go.Binding("figure", "figure")
        // new go.Binding("fill", "figure", nodeFillConverter),
        // new go.Binding("angle", "figure", (f) => (f === "OrGate" || f === "AndGate" ? -90 : 0))
      ), // ORs and ANDs should point upwards
      $(
        "TreeExpanderButton",
        new go.Binding("visible", "figure", (f) => f !== "None"), // if we don't have a figure, don't display any choice text
        {
          alignment: new go.Spot(0.5, 1, 0, 24),
          alignmentFocus: go.Spot.Center,
        }
      )
    );

    // diagram.linkTemplate = $(
    //   go.Link,
    //   go.Link.Orthogonal,
    //   { layerName: "Background", curviness: 20, corner: 5 },
    //   $(go.Shape, { strokeWidth: 1.5 })
    // );

    return diagram;
  };

  return (
    <>
      <ReactDiagram
        ref={ref}
        style={{ width: "100%", height: "600px" }}
        divClassName="diagram-component"
        initDiagram={initDiagram}
        nodeDataArray={nodeDataArray}
        linkDataArray={linkDataArray}
      />
      {/* <img alt="" src={analysisPng} /> */}
    </>
  );
};

export default Diagram;
