import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, FormControl, IconButton, MenuItem, Select, TextField } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import AddIcon from "components/Icons/AddIcon";
import CustomizeIcon from "components/Icons/CustomizeIcon";
import ExportIcon from "components/Icons/ExportIcon";
import React from "react";
import { useIntl } from "react-intl";
import { textColor } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";

import { ButtonsModel, FilterModel, ListModel, TableToolBarModel } from "../CustomTableModel";

const TableToolBar: React.FC<TableToolBarModel> = (props: TableToolBarModel) => {
  const { showSearch, searchText, requestSearch, saveFilter, filters, children, buttons, showSaveFilterButton } = props;

  const { messages } = useIntl();

  const getStartIcon = (type: TableHeaderButtonsIconType, isBlue: boolean) => {
    switch (type) {
      case TableHeaderButtonsIconType.ADD:
        return <AddIcon isBlue={isBlue} />;
      case TableHeaderButtonsIconType.SETTING:
        return <CustomizeIcon />;
      case TableHeaderButtonsIconType.EXPORT:
        return <ExportIcon />;
      default:
        return <Box></Box>;
    }
  };

  return (
    <Box
      sx={{
        p: 0,
        flexWrap: "wrap",
      }}
      className="flex items-center justify-between"
    >
      <Box className="flex items-center">
        {showSearch && (
          <TextField
            variant="outlined"
            value={searchText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value)}
            placeholder={String(messages["common.search"])}
            InputProps={{
              startAdornment: (
                <AppSvgIcon size={16} color="action">
                  {"material-outline:search"}
                </AppSvgIcon>
              ),
              endAdornment: (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: searchText ? "visible" : "hidden" }}
                    onClick={() => {
                      requestSearch("");
                    }}
                  >
                    <AppSvgIcon size={16} color="action">
                      {"material-outline:clear"}
                    </AppSvgIcon>
                  </IconButton>
                </Box>
              ),
            }}
            sx={{
              width: {
                xs: 1,
                sm: "auto",
              },
              m: (theme) => theme.spacing(0),
              "& .MuiSvgIcon-root": {
                mr: 0.5,
              },
              "& .MuiInputBase-root": {
                padding: "0px 14px",
                height: "32px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "0px",
              },
              "& .MuiInput-underline:before": {
                borderBottom: 1,
                borderColor: "divider",
              },
            }}
          />
        )}
        <Box>
          {filters &&
            filters.map((item: FilterModel, index: number) => (
              <FormControl key={index.toString()} sx={{ ml: 2, minWidth: 120, color: textColor[85] }}>
                <Select
                  value={item.value}
                  onChange={(e) => {
                    item.setValue(e.target.value);
                  }}
                  displayEmpty
                  sx={{ color: textColor[85], height: "32px" }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {!item.disabledNone && (
                    <MenuItem sx={{ color: textColor[85], "&:hover": { background: textColor[5] } }} value="">
                      None
                    </MenuItem>
                  )}
                  {item.list.map((row: ListModel) => (
                    <MenuItem
                      sx={{ color: textColor[85], "&:hover": { background: textColor[5] } }}
                      key={row.id.toString()}
                      value={row.id}
                    >
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}
        </Box>
        {showSaveFilterButton && (
          <Button
            sx={{
              ml: 2,
              color: "#2e6ccb",
              display: filters && filters.length > 0 ? "" : "none",
            }}
            onClick={saveFilter}
          >
            <IntlMessages id="workspace.save.filter" />
          </Button>
        )}
      </Box>
      <Box
        sx={{
          justifyContent: "space-around",
        }}
        className="flex items-center"
      >
        {children
          ? children
          : buttons &&
            buttons.map((item: ButtonsModel, index: number) => (
              <Button
                key={index.toString()}
                sx={{
                  marginLeft: 2,
                  color: item.color || "rgba(0,0,0,0.85)",
                }}
                variant={item.type || "text"}
                onClick={item.onChange}
                startIcon={<>{getStartIcon(item.icon, item?.isBlue)}</>}
              >
                <IntlMessages id={item.id} />
              </Button>
            ))}
      </Box>
    </Box>
  );
};

export default TableToolBar;
