import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import AppsIcon from "@mui/icons-material/Apps";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Checkbox, Chip, ClickAwayListener, List, ListItem, Popover, Stack, styled } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import NoDataIcon from "components/Icons/NoDataIcon";
import * as go from "gojs";
import React, { useEffect } from "react";
import { borderColor, borderRadius, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

const Container = styled(Box)(({ theme }) => ({}));

const relationshipMockData = {
  "Risk Management": {
    module: "Risk Management",
    relationshipTypeRows: [
      {
        id: "relates",
        name: "relates to",
        opposite_name: "is related to",
        description: "",
        rules: "Config",
        default: true,
      },
      {
        id: "leads",
        name: "leads to",
        opposite_name: "is caused by",
        description: "",
        rules: "Config",
        default: "",
      },
      {
        id: "verifies",
        name: "verifies",
        opposite_name: "is verified by",
        description: "",
        rules: "Config",
        default: "",
      },
      {
        id: "duplicates",
        name: "duplicates",
        opposite_name: "is duplicated by",
        description: "",
        rules: "Config",
        default: "",
      },
      {
        id: "effects",
        name: "effects",
        opposite_name: "is effected by",
        description: "",
        rules: "Config",
        default: "",
      },
      {
        id: "assesses",
        name: "assesses",
        opposite_name: "is assessed by",
        description: "",
        rules: "Config",
        default: "",
      },
    ],
    diagramData: {
      nodeData: [
        { key: 0, text: "Cause", group: "group_1", loc: "-47.85693359375 17.356085205078124" },
        { key: 1, text: "Hazardous Situation", group: "group_1", loc: "145.6025390625 16.7560791015625" },
        { key: 2, text: "Safety Harm", group: "group_1", loc: "358.7509765625 16.756079101562506" },
        { key: 3, text: "Hazard", group: "group_1", loc: "145.95849609375 -75.2439208984375" },
      ],
      linkData: [
        { key: 2, from: 0, to: 1, text: "leads to" },
        { key: 3, from: 1, to: 2, text: "leads to" },
        { key: 1, from: 1, to: 3, dash: [6, 3], text: "assesses" },
      ],
    },
    diagramConfigRows: [
      {
        id: "0",
        upstream: "Risk Control",
        downstream: "Cause",
        relationship: "verifies",
        for_risk: "",
        for_coverage: "",
      },
      {
        id: "1",
        upstream: "Cause",
        downstream: "Hazardous Situation",
        relationship: "leads to",
        for_risk: true,
        for_coverage: "",
      },
      {
        id: "2",
        upstream: "Hazardous Situation",
        downstream: "Safety Harm",
        relationship: "leads to",
        for_risk: true,
        for_coverage: "",
      },
      {
        id: "3",
        upstream: "Hazardous Situation",
        downstream: "Hazard",
        relationship: "assesses",
        for_risk: "",
        for_coverage: "",
      },
    ],
  },
  // "Design Controls": {
  //   relationshipTypeRows: [
  //     {
  //       id: "relates",
  //       name: "relates to",
  //       opposite_name: "is related to",
  //       description: "",
  //       rules: "Config",
  //       default: true,
  //     },
  //     {
  //       id: "leads",
  //       name: "leads to",
  //       opposite_name: "is caused by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "verifies",
  //       name: "verifies",
  //       opposite_name: "is verified by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "duplicates",
  //       name: "duplicates",
  //       opposite_name: "is duplicated by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "effects",
  //       name: "effects",
  //       opposite_name: "is effected by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "assesses",
  //       name: "assesses",
  //       opposite_name: "is assessed by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //   ],
  //   diagramData: {
  //     nodeData: [
  //       { key: 0, text: "Cause", group: "group_1", loc: "-47.85693359375 17.356085205078124" },
  //       { key: 1, text: "Hazardous Situation", group: "group_1", loc: "145.6025390625 16.7560791015625" },
  //       { key: 2, text: "Safety Harm", group: "group_1", loc: "358.7509765625 16.756079101562506" },
  //       { key: 3, text: "Hazard", group: "group_1", loc: "145.95849609375 -75.2439208984375" },
  //     ],
  //     linkData: [
  //       { key: 2, from: 0, to: 1, text: "leads to" },
  //       { key: 3, from: 1, to: 2, text: "leads to" },
  //       { key: 1, from: 1, to: 3, dash: [6, 3], text: "assesses" },
  //     ],
  //   },
  //   diagramConfigRows: [
  //     {
  //       id: "1",
  //       upstream: "Cause",
  //       downstream: "Hazardous Situation",
  //       relationship: "leads to",
  //       for_risk: true,
  //       for_coverage: "",
  //     },
  //     {
  //       id: "2",
  //       upstream: "Hazardous Situation",
  //       downstream: "Safety Harm",
  //       relationship: "leads to",
  //       for_risk: true,
  //       for_coverage: "",
  //     },
  //     {
  //       id: "3",
  //       upstream: "Hazardous Situation",
  //       downstream: "Hazard",
  //       relationship: "assesses",
  //       for_risk: "",
  //       for_coverage: "",
  //     },
  //   ],
  // },
  // "Cybersecurity Management": {
  //   relationshipTypeRows: [
  //     {
  //       id: "relates",
  //       name: "relates to",
  //       opposite_name: "is related to",
  //       description: "",
  //       rules: "Config",
  //       default: true,
  //     },
  //     {
  //       id: "leads",
  //       name: "leads to",
  //       opposite_name: "is caused by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "verifies",
  //       name: "verifies",
  //       opposite_name: "is verified by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "duplicates",
  //       name: "duplicates",
  //       opposite_name: "is duplicated by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "effects",
  //       name: "effects",
  //       opposite_name: "is effected by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "assesses",
  //       name: "assesses",
  //       opposite_name: "is assessed by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //   ],
  //   diagramData: {
  //     nodeData: [
  //       { key: 0, text: "Cause", group: "group_1", loc: "-47.85693359375 17.356085205078124" },
  //       { key: 1, text: "Hazardous Situation", group: "group_1", loc: "145.6025390625 16.7560791015625" },
  //       { key: 2, text: "Safety Harm", group: "group_1", loc: "358.7509765625 16.756079101562506" },
  //       { key: 3, text: "Hazard", group: "group_1", loc: "145.95849609375 -75.2439208984375" },
  //     ],
  //     linkData: [
  //       { key: 2, from: 0, to: 1, text: "leads to" },
  //       { key: 3, from: 1, to: 2, text: "leads to" },
  //       { key: 1, from: 1, to: 3, dash: [6, 3], text: "assesses" },
  //     ],
  //   },
  //   diagramConfigRows: [
  //     {
  //       id: "1",
  //       upstream: "Cause",
  //       downstream: "Hazardous Situation",
  //       relationship: "leads to",
  //       for_risk: true,
  //       for_coverage: "",
  //     },
  //     {
  //       id: "2",
  //       upstream: "Hazardous Situation",
  //       downstream: "Safety Harm",
  //       relationship: "leads to",
  //       for_risk: true,
  //       for_coverage: "",
  //     },
  //     {
  //       id: "3",
  //       upstream: "Hazardous Situation",
  //       downstream: "Hazard",
  //       relationship: "assesses",
  //       for_risk: "",
  //       for_coverage: "",
  //     },
  //   ],
  // },
  // "Assurance Case Management": {
  //   relationshipTypeRows: [
  //     {
  //       id: "relates",
  //       name: "relates to",
  //       opposite_name: "is related to",
  //       description: "",
  //       rules: "Config",
  //       default: true,
  //     },
  //     {
  //       id: "leads",
  //       name: "leads to",
  //       opposite_name: "is caused by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "verifies",
  //       name: "verifies",
  //       opposite_name: "is verified by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "duplicates",
  //       name: "duplicates",
  //       opposite_name: "is duplicated by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "effects",
  //       name: "effects",
  //       opposite_name: "is effected by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "assesses",
  //       name: "assesses",
  //       opposite_name: "is assessed by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //   ],
  //   diagramData: {
  //     nodeData: [
  //       { key: 0, text: "Cause", group: "group_1", loc: "-47.85693359375 17.356085205078124" },
  //       { key: 1, text: "Hazardous Situation", group: "group_1", loc: "145.6025390625 16.7560791015625" },
  //       { key: 2, text: "Safety Harm", group: "group_1", loc: "358.7509765625 16.756079101562506" },
  //       { key: 3, text: "Hazard", group: "group_1", loc: "145.95849609375 -75.2439208984375" },
  //     ],
  //     linkData: [
  //       { key: 2, from: 0, to: 1, text: "leads to" },
  //       { key: 3, from: 1, to: 2, text: "leads to" },
  //       { key: 1, from: 1, to: 3, dash: [6, 3], text: "assesses" },
  //     ],
  //   },
  //   diagramConfigRows: [
  //     {
  //       id: "1",
  //       upstream: "Cause",
  //       downstream: "Hazardous Situation",
  //       relationship: "leads to",
  //       for_risk: true,
  //       for_coverage: "",
  //     },
  //     {
  //       id: "2",
  //       upstream: "Hazardous Situation",
  //       downstream: "Safety Harm",
  //       relationship: "leads to",
  //       for_risk: true,
  //       for_coverage: "",
  //     },
  //     {
  //       id: "3",
  //       upstream: "Hazardous Situation",
  //       downstream: "Hazard",
  //       relationship: "assesses",
  //       for_risk: "",
  //       for_coverage: "",
  //     },
  //   ],
  // },
  // "Production Management": {
  //   relationshipTypeRows: [
  //     {
  //       id: "relates",
  //       name: "relates to",
  //       opposite_name: "is related to",
  //       description: "",
  //       rules: "Config",
  //       default: true,
  //     },
  //     {
  //       id: "leads",
  //       name: "leads to",
  //       opposite_name: "is caused by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "verifies",
  //       name: "verifies",
  //       opposite_name: "is verified by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "duplicates",
  //       name: "duplicates",
  //       opposite_name: "is duplicated by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "effects",
  //       name: "effects",
  //       opposite_name: "is effected by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "assesses",
  //       name: "assesses",
  //       opposite_name: "is assessed by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //   ],
  //   diagramData: {
  //     nodeData: [
  //       { key: 0, text: "Cause", group: "group_1", loc: "-47.85693359375 17.356085205078124" },
  //       { key: 1, text: "Hazardous Situation", group: "group_1", loc: "145.6025390625 16.7560791015625" },
  //       { key: 2, text: "Safety Harm", group: "group_1", loc: "358.7509765625 16.756079101562506" },
  //       { key: 3, text: "Hazard", group: "group_1", loc: "145.95849609375 -75.2439208984375" },
  //     ],
  //     linkData: [
  //       { key: 2, from: 0, to: 1, text: "leads to" },
  //       { key: 3, from: 1, to: 2, text: "leads to" },
  //       { key: 1, from: 1, to: 3, dash: [6, 3], text: "assesses" },
  //     ],
  //   },
  //   diagramConfigRows: [
  //     {
  //       id: "1",
  //       upstream: "Cause",
  //       downstream: "Hazardous Situation",
  //       relationship: "leads to",
  //       for_risk: true,
  //       for_coverage: "",
  //     },
  //     {
  //       id: "2",
  //       upstream: "Hazardous Situation",
  //       downstream: "Safety Harm",
  //       relationship: "leads to",
  //       for_risk: true,
  //       for_coverage: "",
  //     },
  //     {
  //       id: "3",
  //       upstream: "Hazardous Situation",
  //       downstream: "Hazard",
  //       relationship: "assesses",
  //       for_risk: "",
  //       for_coverage: "",
  //     },
  //   ],
  // },
  // "Index File Management": {
  //   relationshipTypeRows: [
  //     {
  //       id: "relates",
  //       name: "relates to",
  //       opposite_name: "is related to",
  //       description: "",
  //       rules: "Config",
  //       default: true,
  //     },
  //     {
  //       id: "leads",
  //       name: "leads to",
  //       opposite_name: "is caused by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "verifies",
  //       name: "verifies",
  //       opposite_name: "is verified by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "duplicates",
  //       name: "duplicates",
  //       opposite_name: "is duplicated by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "effects",
  //       name: "effects",
  //       opposite_name: "is effected by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //     {
  //       id: "assesses",
  //       name: "assesses",
  //       opposite_name: "is assessed by",
  //       description: "",
  //       rules: "Config",
  //       default: "",
  //     },
  //   ],
  //   diagramData: {
  //     nodeData: [
  //       { key: 0, text: "Cause", group: "group_1", loc: "-47.85693359375 17.356085205078124" },
  //       { key: 1, text: "Hazardous Situation", group: "group_1", loc: "145.6025390625 16.7560791015625" },
  //       { key: 2, text: "Safety Harm", group: "group_1", loc: "358.7509765625 16.756079101562506" },
  //       { key: 3, text: "Hazard", group: "group_1", loc: "145.95849609375 -75.2439208984375" },
  //     ],
  //     linkData: [
  //       { key: 2, from: 0, to: 1, text: "leads to" },
  //       { key: 3, from: 1, to: 2, text: "leads to" },
  //       { key: 1, from: 1, to: 3, dash: [6, 3], text: "assesses" },
  //     ],
  //   },
  //   diagramConfigRows: [
  //     {
  //       id: "1",
  //       upstream: "Cause",
  //       downstream: "Hazardous Situation",
  //       relationship: "leads to",
  //       for_risk: true,
  //       for_coverage: "",
  //     },
  //     {
  //       id: "2",
  //       upstream: "Hazardous Situation",
  //       downstream: "Safety Harm",
  //       relationship: "leads to",
  //       for_risk: true,
  //       for_coverage: "",
  //     },
  //     {
  //       id: "3",
  //       upstream: "Hazardous Situation",
  //       downstream: "Hazard",
  //       relationship: "assesses",
  //       for_risk: "",
  //       for_coverage: "",
  //     },
  //   ],
  // },
  "Design Controls": {
    module: "Design Controls",
    relationshipTypeRows: [
      {
        id: "relates",
        name: "relates to",
        opposite_name: "is related to",
        description: "",
        rules: "Config",
        default: true,
      },
      {
        id: "leads",
        name: "leads to",
        opposite_name: "is caused by",
        description: "",
        rules: "Config",
        default: false,
      },
      {
        id: "verifies",
        name: "verifies",
        opposite_name: "is verified by",
        description: "",
        rules: "Config",
        default: false,
      },
      {
        id: "duplicates",
        name: "duplicates",
        opposite_name: "is duplicated by",
        description: "",
        rules: "Config",
        default: false,
      },
      {
        id: "depends_on",
        name: "depends on",
        opposite_name: "blocks",
        description: "",
        rules: "Config",
        default: false,
      },
      {
        id: "derived_from",
        name: "is derived from",
        opposite_name: "is derived by",
        description: "",
        rules: "Config",
        default: false,
      },
      {
        id: "validates",
        name: "validates",
        opposite_name: "is validated by",
        description: "",
        rules: "Config",
        default: false,
      },
    ],
    diagramData: {
      nodeData: [
        { key: "User_Need", text: "User Need", group: "group_1", loc: "-139.72705078125 32.7560791015625" },
        { key: "Requirement", text: "Requirement", group: "group_1", loc: "52.956787109375 33.7560791015625" },
        { key: "Verification", text: "Verification", group: "group_1", loc: "302.846435546875 -48.2439208984375" },
        { key: "Validation", text: "Validation", group: "group_1", loc: "302.48291015625 32.756079101562506" },
        { key: "User_Story", text: "User Story", group: "group_1", loc: "300.651611328125 110.7560791015625" },
        { key: "Defect", text: "Defect", group: "group_1", loc: "506.09912109375 -7.243920898437494" },
      ],
      linkData: [
        { key: "User_Need_Requirement", from: "User_Need", to: "Requirement", dash: [6, 3], text: "derives" },
        {
          key: "Requirement_Verification",
          from: "Requirement",
          to: "Verification",
          text: "is verified by",
          routing: go.Link.Normal,
        },
        {
          key: "Requirement_Validation",
          from: "Requirement",
          to: "Validation",
          dash: [6, 3],
          text: "is validated by",
          routing: go.Link.Normal,
        },
        {
          key: "Requirement_User_Story",
          from: "Requirement",
          to: "User_Story",
          dash: [6, 3],
          text: "derives",
          routing: go.Link.Normal,
        },
        {
          key: "Verification_Defect",
          from: "Verification",
          to: "Defect",
          dash: [6, 3],
          text: "is caused by",
          routing: go.Link.Normal,
        },
        {
          key: "Validation_Defect",
          from: "Validation",
          to: "Defect",
          dash: [6, 3],
          text: "is caused by",
          routing: go.Link.Normal,
        },
      ],
    },
    diagramConfigRows: [
      {
        id: "1",
        upstream: "Verification",
        downstream: "Defect",
        relationship: "is caused by",
        for_risk: false,
        for_coverage: false,
      },
      {
        id: "2",
        upstream: "Validation",
        downstream: "Defect",
        relationship: "is caused by",
        for_risk: false,
        for_coverage: false,
      },
      {
        id: "3",
        upstream: "Requirement",
        downstream: "Verification",
        relationship: "is verified by",
        for_risk: false,
        for_coverage: true,
      },
      {
        id: "4",
        upstream: "Requirement",
        downstream: "Validation",
        relationship: "is validated by",
        for_risk: false,
        for_coverage: false,
      },
      {
        id: "5",
        upstream: "User Need",
        downstream: "Requirement",
        relationship: "derives",
        for_risk: false,
        for_coverage: false,
      },
      {
        id: "6",
        upstream: "Requirement",
        downstream: "User Story",
        relationship: "derives",
        for_risk: false,
        for_coverage: false,
      },
      {
        id: "7",
        upstream: "Requirement",
        downstream: "Risk",
        relationship: "assesses",
        for_risk: false,
        for_coverage: false,
      },
    ],
  },
};

const TableView = () => {
  const columns: Column[] = [
    {
      id: "upstream",
      label: "common.columns.upstream",
      minWidth: 200,
      align: "left",
    },
    {
      id: "legend",
      label: "common.columns.legend",
      minWidth: 150,
      align: "left",
      format: (_, record) => {
        if (record.for_risk || record.for_coverage) {
          return (
            <Box className="flex">
              <AppSvgIcon>material-outline:east</AppSvgIcon>
            </Box>
          );
        }
        return "dotted line";
      },
    },
    {
      id: "downstream",
      label: "common.columns.downstream",
      minWidth: 200,
      align: "left",
    },
    {
      id: "relationship",
      label: "common.columns.relationship",
      minWidth: 150,
      align: "left",
    },
    {
      id: "for_risk",
      label: "common.columns.for.risk",
      minWidth: 150,
      align: "left",
      format: (value, record) => {
        return <Box>{value ? "Yes" : ""}</Box>;
      },
    },
    {
      id: "for_coverage",
      label: "common.columns.for.coverage",
      minWidth: 150,
      align: "left",
      format: (value, record) => {
        return <Box>{value ? "Yes" : ""}</Box>;
      },
    },
    {
      id: "actions",
      label: "setting.product.column.actions",
      align: "left",
      format: (value, record) => {
        return (
          <Box>
            <EditIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />{" "}
            <DeleteIcon sx={{ cursor: "pointer", fontSize: "16px" }} />
          </Box>
        );
      },
    },
  ];

  const [moduleList, setModuleList] = React.useState([
    {
      id: 5,
      checked: false,
      label: "Design Controls",
      icon: "/assets/images/file.svg",
    },
    {
      id: 2,
      checked: false,
      label: "Risk Management",
      icon: "/assets/images/file.svg",
    },
    {
      id: 3,
      checked: false,
      label: "Cybersecurity Management",
      icon: "/assets/images/file.svg",
    },
    {
      id: 4,
      checked: false,
      label: "Assurance Case Management",
      icon: "/assets/images/data.svg",
    },
    {
      id: 6,
      checked: false,
      label: "Production Management",
      icon: "/assets/images/data.svg",
    },
    {
      id: 7,
      checked: false,
      label: "Index File Management",
      icon: "/assets/images/data.svg",
    },
  ]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = () => {
    const element = document.getElementById("target");
    setAnchorEl(element);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeList = (item) => {
    const newList = [...moduleList];
    const currentItem = newList.filter((x) => x.id === item.id)[0];
    if (currentItem) {
      currentItem.checked = !currentItem.checked;
    }
    setModuleList(newList);
  };

  const [data, setData] = React.useState<any[]>([]);

  useEffect(() => {
    getData();
  }, [moduleList]);

  const getData = () => {
    const newData = [];
    if (moduleList.filter((x) => x.checked).length > 0) {
      moduleList
        .filter((x) => x.checked)
        .map((item) => {
          const mockData = relationshipMockData[item.label];
          if (mockData) {
            newData.push(mockData);
          }
        });
    } else {
      moduleList.map((item) => {
        const mockData = relationshipMockData[item.label];
        if (mockData) {
          newData.push(mockData);
        }
      });
    }
    setData(newData);
  };

  return (
    <Container>
      <AppScrollbar
        sx={{
          maxHeight: "calc(100vh - 236px)",
          mt: (theme) => theme.spacing(2),
        }}
      >
        <Box>
          <Box className="flex items-center">
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box className="flex items-center flex-wrap">
                  <Button
                    sx={{ color: textColor[85], border: `solid 1px ${borderColor.dividerColor}` }}
                    id="target"
                    onClick={handleClick}
                    startIcon={<AppsIcon />}
                    variant="outlined"
                  >
                    <IntlMessages id="workspace.modules" />
                  </Button>
                  <Box sx={{ marginLeft: "10px" }}>
                    <Stack direction="row" spacing={1}>
                      {moduleList
                        .filter((x) => x.checked)
                        .map((item) => (
                          <Chip
                            sx={{
                              textAlign: "center",
                              fontSize: "14px",
                              borderRadius: borderRadius.main,
                              "& .MuiChip-label": {
                                padding: "7px 12px 7px 12px",
                                color: textColor[85],
                              },
                            }}
                            className="text-color-08"
                            onDelete={() => {
                              handleChangeList(item);
                            }}
                            onClick={handleClick}
                            key={item.id}
                            label={item.label}
                          />
                        ))}
                      {moduleList.filter((x) => x.checked).length > 0 && (
                        <Button
                          onClick={() => {
                            const newModuleList = moduleList.map((item) => {
                              item.checked = false;
                              return item;
                            });
                            setModuleList(newModuleList);
                          }}
                          variant="text"
                          sx={{ color: textColor[65], fontSize: "14px" }}
                        >
                          <IntlMessages id="common.clear" />
                        </Button>
                      )}
                    </Stack>
                  </Box>
                </Box>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  sx={{ marginTop: "10px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <React.Fragment>
                    <List
                      sx={{
                        "& .MuiListItem-root:hover": {
                          background: "rgba(0, 0, 0, 0.05)",
                        },
                      }}
                    >
                      {moduleList.map((item) => (
                        <ListItem
                          key={item.id}
                          sx={{
                            padding: "2px 10px",
                          }}
                          className="text-color-08"
                        >
                          <Box
                            sx={{
                              cursor: "pointer",
                              color: textColor[85],
                            }}
                            className="flex items-center"
                            onClick={() => {
                              handleChangeList(item);
                            }}
                          >
                            <Checkbox checked={item.checked} sx={{ padding: "0 12px" }} />
                            <Box>{item.label}</Box>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  </React.Fragment>
                </Popover>
              </Box>
            </ClickAwayListener>
          </Box>
          {data.map((item, index) => (
            <Box sx={{ mt: 2 }} key={index}>
              <Box
                sx={{
                  height: "auto",
                  width: "100%",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    className="w-full flex items-center"
                    sx={{
                      height: "48px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: 1.5,
                      color: textColor[85],
                    }}
                  >
                    {item.module}
                  </Box>
                </Box>
              </Box>
              <Box>
                <CustomTable
                  columns={columns}
                  rows={item.diagramConfigRows}
                  maxHeight="auto"
                  showPage={false}
                  addRow={() => {
                    console.log("add row");
                  }}
                  addRowTextId="common.add"
                />
              </Box>
            </Box>
          ))}
          <Box
            className="no-data-box"
            sx={{
              display: data.length > 0 ? "none" : "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 350,
              width: "100%",
            }}
          >
            <NoDataIcon />
          </Box>
        </Box>
      </AppScrollbar>
    </Container>
  );
};

export default TableView;
