import IntlMessages from "@crema/utility/IntlMessages";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { Box, Breadcrumbs } from "@mui/material";
import Link from "@mui/material/Link";
import ButtonMenu from "components/ButtonMenu";
import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import ForwardAndBackward from "components/ForwardAndBackward";
import SearchStarredAndRecently from "components/SearchStarredAndRecently";
import { taskData } from "mock/index";
import React from "react";
import { Outlet } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { bgColor, borderColor, boxShadow, textColor } from "shared/constants/AppConst";

const DetailLayout = () => {
  const routeParam = useParams();
  const location = useLocation();

  const menuList: ButtonMenuListItemModel[] = React.useMemo(() => {
    if (routeParam.type === "sha" || routeParam.type === "df" || routeParam.type === "ura") {
      return [
        {
          id: "5",
          labelId: "common.worksheet",
          icon: <AddLinkIcon />,
          url: `/products/123/${routeParam.type}/worksheet`,
        },
        {
          id: "1",
          labelId: "common.scope",
          icon: <AddLinkIcon />,
          url: `/products/123/${routeParam.type}/scope`,
        },
        {
          id: "2",
          labelId: "common.collaboration",
          icon: <AddLinkIcon />,
          url: `/products/123/${routeParam.type}/collaboration`,
        },
        // {
        //   id: "3",
        //   labelId: "common.rules",
        //   icon: <AddLinkIcon />,
        //   url: `/products/123/${routeParam.type}/rules`,
        // },
        {
          id: "4",
          labelId: "common.input",
          icon: <AddLinkIcon />,
          url: `/products/123/${routeParam.type}/input`,
        },
        {
          id: "6",
          labelId: "common.impact.summary",
          icon: <AddLinkIcon />,
          url: `/products/123/${routeParam.type}/impactSummary`,
        },
        {
          id: "7",
          labelId: "common.checklist",
          icon: <AddLinkIcon />,
          url: `/products/123/${routeParam.type}/checklist`,
        },
        {
          id: "8",
          labelId: "common.report",
          icon: <AddLinkIcon />,
          url: `/products/123/${routeParam.type}/report`,
        },
      ];
    } else {
      return [
        {
          id: "5",
          labelId: "common.worksheet",
          icon: <AddLinkIcon />,
          url: `/products/123/${routeParam.type}/worksheet`,
        },
        {
          id: "1",
          labelId: "common.scope",
          icon: <AddLinkIcon />,
          url: `/products/123/${routeParam.type}/scope`,
        },
        {
          id: "2",
          labelId: "common.collaboration",
          icon: <AddLinkIcon />,
          url: `/products/123/${routeParam.type}/collaboration`,
        },
        // {
        //   id: "3",
        //   labelId: "common.rules",
        //   icon: <AddLinkIcon />,
        //   url: `/products/123/${routeParam.type}/rules`,
        // },
        {
          id: "4",
          labelId: "common.input",
          icon: <AddLinkIcon />,
          url: `/products/123/${routeParam.type}/input`,
        },
        {
          id: "6",
          labelId: "common.impact.summary",
          icon: <AddLinkIcon />,
          url: `/products/123/${routeParam.type}/impactSummary`,
        },
        // {
        //   id: "7",
        //   labelId: "common.checklist",
        //   icon: <AddLinkIcon />,
        //   url: `/products/123/${routeParam.type}/checklist`,
        // },
        {
          id: "8",
          labelId: "common.report",
          icon: <AddLinkIcon />,
          url: `/products/123/${routeParam.type}/report`,
        },
      ];
    }
  }, [routeParam?.type]);
  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = React.useState<ButtonMenuListItemModel>();
  const [mockData, setMockData] = React.useState(null);

  React.useEffect(() => {
    routeParam?.type && setMockData(taskData[routeParam.type]);
  }, [routeParam]);

  React.useEffect(() => {
    const current = menuList.filter((item) => item.url === location.pathname)[0];
    const newCurrent = current ? { ...current } : null;
    if (newCurrent) {
      delete newCurrent.url;
      current && setSelectedValue(newCurrent);
    }
  }, [location, menuList]);

  const handleSelectedValue = (item: ButtonMenuListItemModel) => {
    setSelectedValue(item);
  };

  return (
    <Box className="workspace-detail-layout">
      <Box
        className="workspace-detail-info-bar"
        sx={{
          position: "relative",
          px: (theme) => theme.spacing(5),
          height: 48,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // marginBottom: "24px",
          borderRadius: 0,
          background: (theme) => theme.palette.background.paper,
          boxShadow: boxShadow.cardBoxShadow,
          borderBottom: `1px solid  ${borderColor.dividerColor}`,
          zIndex: 100,
          // boxShadow: "0 2px 8px 0 #0000001f",
          "& .left-wrapper": {
            flex: 1,
          },
        }}
      >
        <ForwardAndBackward />
        <Box className="breadcrumb-navigation-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color={textColor[65]} href="/products">
              <IntlMessages id="menu.product" />
            </Link>
            <Link
              underline="hover"
              color={textColor[65]}
              onClick={() => {
                navigate("/products/123/deliverables/Design Controls/index");
              }}
            >
              {mockData?.product ? mockData.product : "Drug Delivery Device (EU 2.0)"}
            </Link>
            <SearchStarredAndRecently
              current={mockData?.task ? mockData.task : "TK#101 User Needs V1.1"}
              placeholderId="common.search.task"
              starredList={[
                {
                  id: "1",
                  name: "TK#001 System Hazard Analysis V1.0",
                  url: "/products/123/sha/worksheet",
                },
                {
                  id: "2",
                  name: "TK#014 Design FMEA V1.1",
                  url: "/products/123/df/worksheet",
                },
                {
                  id: "3",
                  name: "TK#101 User Needs V1.1",
                  url: "/products/123/und/worksheet",
                },
              ]}
              recentlyList={[
                {
                  id: "1",
                  name: "TK#102 Design Input Requirements V1.1",
                  url: "/products/123/dir/worksheet",
                },
                {
                  id: "2",
                  name: "TK#025 Test Cases V1.1",
                  url: "/products/123/tc/worksheet",
                },
              ]}
              starredTitleId="workspaces.starred.task"
              recentlyTitleId="products.recently.viewed"
              // viewButtonTextId="common.view.all"
              handleViewButton={() => {
                navigate("/workspace");
              }}
            />
          </Breadcrumbs>
        </Box>
        <Box className="left-wrapper"></Box>
        <Box className="right-wrapper">
          <ButtonMenu selectedValue={selectedValue} setSelectedValue={handleSelectedValue} list={menuList} />
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
};

export default DetailLayout;
