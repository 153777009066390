// import AppSidebar from "./AppSidebar";
import AppContentView from "@crema/core/AppContentView";
import { useLayoutContext } from "@crema/utility/AppContextProvider/LayoutContextProvider";
import Box from "@mui/material/Box";
import clsx from "clsx";
import { useState } from "react";

import AppFixedFooter from "./AppFixedFooter";
// import AppThemeSetting from "@crema/core/AppThemeSetting";
import AppHeader from "./AppHeader";
import DefaultLayoutContainer from "./DefaultLayoutContainer";
import DefaultLayoutWrapper from "./DefaultLayoutWrapper";

const DefaultLayout = () => {
  // const [isCollapsed, setCollapsed] = useState(true);
  const { footer, footerType } = useLayoutContext();


  return (
    <DefaultLayoutContainer className="main-container">
      <DefaultLayoutWrapper
        className={clsx("default-layout-wrapper", {
          appMainFooter: footer && footerType === "fluid",
          appMainFixedFooter: footer && footerType === "fixed",
          // "mini-sidebar-collapsed": isCollapsed,
        })}
      >
        <AppHeader />
        <Box className="main-content">
          <AppContentView
            sxStyle={{
              width: "100%"
            }}
          />
          <AppFixedFooter />
        </Box>
        {/* <AppThemeSetting /> */}
      </DefaultLayoutWrapper>
    </DefaultLayoutContainer>
  );
};

export default DefaultLayout;
