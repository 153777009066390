import AppScrollbar from "@crema/core/AppScrollbar";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import ButtonMenu from "components/ButtonMenu";
import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import ModuleIcon from "components/Icons/ModuleIcon";
import MyTree from "components/MyTree";
import { ModuleMenuList, ProcessData } from "mock/ModuleData";
import React from "react";
import { borderColor, textColor, treeStyle } from "shared/constants/AppConst";

const TreeContainer = styled(Box)(({ theme }) => ({}));

interface TreeProps {
  handleSelectProcess: React.Dispatch<any>;
}

const deepFind =
  (pred) =>
  ([x, ...xs] = []) =>
    x && (pred(x) ? x : deepFind(pred)(x.items) || deepFind(pred)(xs));

const findById = (id) => (dataSet) => deepFind((o) => o.id === id)(dataSet);

const Tree: React.FC<TreeProps> = ({ handleSelectProcess }) => {
  const [selectedModule, setSelectedModule] = React.useState<ButtonMenuListItemModel>(ModuleMenuList[0]);
  const [processDataSet, setProcessDataSet] = React.useState(ProcessData[selectedModule.id]);
  const [selected, setSelected] = React.useState<any>("");

  React.useEffect(() => {
    const initSelectedModuleLabelId = sessionStorage.getItem("selectedModuleLabelId");
    const currentSelectedModule = ModuleMenuList.filter((item) => item.labelId === initSelectedModuleLabelId)[0];
    if (currentSelectedModule) {
      setSelectedModule(currentSelectedModule);
      setProcessDataSet(ProcessData[currentSelectedModule.id]);
    }
    const initSelected = sessionStorage.getItem("selected");
    initSelected && setSelected(initSelected);
    return () => {
      sessionStorage.setItem("selectedModuleLabelId", "");
      sessionStorage.setItem("selected", "");
    };
  }, []);

  const handleSelectedModule = (item: ButtonMenuListItemModel) => {
    setSelectedModule(item);
    const processDataSet = ProcessData[item.id];
    setProcessDataSet(processDataSet);
    setSelected("");
    handleSelectProcess(null);
  };

  const onNodeSelect = (event: object, value: string) => {
    const currentItem = getTreeItem(processDataSet, value);
    if (!currentItem?.items) {
      setSelected(value);
      const selectedProcess = findById(value)(processDataSet);
      handleSelectProcess(selectedProcess);
    }
  };

  const getTreeItem = (tree: any, id) => {
    let res;
    let state = false;
    function readTree(tree, id) {
      if (state) return;
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].id === id) {
          state = true;
          res = tree[i];
        } else {
          if (tree[i].items && tree[i].items.length > 0) {
            readTree(tree[i].items, id);
          }
        }
      }
    }
    readTree(tree, id);
    return res;
  };

  return (
    <TreeContainer>
      <Box
        sx={{
          my: (theme) => theme.spacing(3),
          mx: (theme) => theme.spacing(5),
        }}
      >
        <ButtonMenu
          selectedValue={selectedModule}
          setSelectedValue={handleSelectedModule}
          list={ModuleMenuList}
          moduleIcon={<ModuleIcon />}
          fullWidth={true}
          sxStyle={{
            variant: "outlined",
            sx: {
              color: textColor[85],
              border: `solid 1px ${borderColor.dividerColor}`,
              display: "flex",
              height: "32px",
              fontSize: "15px",
              justifyContent: "flex-start",
              "& .MuiButton-endIcon": {
                position: "absolute",
                right: "0px",
              },
            },
          }}
        />
      </Box>
      <Box
        sx={{
          borderTop: `solid 1px ${borderColor.dividerColor}`,
        }}
      >
        <AppScrollbar
          sx={{
            height: "calc(72vh - 70px)",
          }}
        >
          <MyTree
            selected={selected}
            onNodeSelect={onNodeSelect}
            data={processDataSet}
            sx={{
              ...treeStyle,
              border: "none",
              ".not-last-child>.MuiTreeItem-content>.MuiTreeItem-label": {
                fontWeight: "bold",
              },
            }}
          />
        </AppScrollbar>
      </Box>
    </TreeContainer>
  );
};

export default Tree;
