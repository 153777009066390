import AppLngSwitcher from "@crema/core/AppLngSwitcher";
import AppMessages from "@crema/core/AppMessages";
import AppNotifications from "@crema/core/AppNotifications";
import AppSearchBar from "@crema/core/AppSearchBar";
import AppsStarredIcon from "@crema/core/AppsStarredIcon";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import HelpIcon from "@mui/icons-material/Help";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import AppSvgIcon from "components/AppSvgIcon";
import AppIconMenu from "layout/components/AppIconMenu";
// import HorizontalNav from "../../components/HorizontalNav";
import AppTopMenu from "layout/components/AppTopMenu";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import AppLogo from "../../components/AppLogo";
import UserInfo from "../../components/UserInfo";

const AppHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isFullScreen, setIsFullScreen] = useState(false);

  useEnhancedEffect(() => {
    document.onfullscreenchange = () => setIsFullScreen(document[getBrowserFullscreenElementProp()] != null);

    return () => {
      document.onfullscreenchange = undefined;
    };
  });

  const documentRef: any = document;

  function getBrowserFullscreenElementProp() {
    if (typeof documentRef.fullscreenElement !== "undefined") {
      return "fullscreenElement";
    }
    if (typeof documentRef.mozFullScreenElement !== "undefined") {
      return "mozFullScreenElement";
    }
    if (typeof documentRef.msFullscreenElement !== "undefined") {
      return "msFullscreenElement";
    }
    if (typeof documentRef.webkitFullscreenElement !== "undefined") {
      return "webkitFullscreenElement";
    }
    throw new Error("fullscreenElement is not supported by this browser");
  }

  /* View in fullscreen */
  function openFullscreen() {
    const elem: any = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (documentRef.mozCancelFullScreen) {
      /* Firefox */
      documentRef.mozCancelFullScreen();
    } else if (documentRef.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      documentRef.webkitExitFullscreen();
    } else if (documentRef.msExitFullscreen) {
      /* IE/Edge */
      documentRef.msExitFullscreen();
    }
  }

  function toggleFullScreen() {
    if (document.fullscreenElement || documentRef.webkitFullscreenElement || documentRef.mozFullScreenElement) {
      closeFullscreen();
    } else {
      openFullscreen();
    }
  }

  return (
    <AppBar
      color="inherit"
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        backgroundColor: "#0A233F", //background.paper",
        transition: "width 0.5s ease",
        width: "100%",
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)",
      }}
      className="app-bar"
    >
      <Toolbar
        sx={{
          boxSizing: "border-box",
          minHeight: { xs: 40, sm: 56 },
          paddingLeft: { xs: 5 },
          // paddingRight: { xs: 5, md: 7.5, xl: 12.5 },
        }}
      >
        <Box
          sx={{
            "& .logo-text": {
              display: { xs: "none", sm: "block" },
            },
          }}
        >
          <AppLogo />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          {/* <HorizontalNav /> */}
          <AppTopMenu />
        </Box>

        <Box
          sx={{
            minHeight: 32,
            position: "relative",
            display: { xs: "none", sm: "block" },
            "& .searchRoot": {
              position: { xs: "absolute", sm: "relative" },
              right: { xs: 0, sm: "auto" },
              top: { xs: 0, sm: "auto" },
            },
          }}
        >
          <AppSearchBar iconPosition="right" placeholder="Search…" />
        </Box>

        <Box
          sx={{
            ml: 4,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              marginLeft: -2,
              marginRight: -2,
              "& .MuiIconButton-root": {
                mx: 1.2,
              },
            }}
          >
            <AppNotifications />
            <AppsStarredIcon />
            {/* <AppIconMenu title="Starred" onClick={() => navigate("/sample/sample")}>
              <StarBorderIcon />
            </AppIconMenu> */}
            <AppIconMenu title="sample-apps" onClick={() => navigate("/sample/sample")}>
              <AppSvgIcon>material-solid:apps</AppSvgIcon>
            </AppIconMenu>
            <AppIconMenu title="Fullscreen Toggle" onClick={toggleFullScreen}>
              <FullscreenIcon />
            </AppIconMenu>
            <AppMessages />
            <AppLngSwitcher iconOnly={true} tooltipPosition="bottom" />
            <AppIconMenu title="Help" onClick={() => navigate("/support/help")}>
              <HelpIcon />
            </AppIconMenu>
            {/* <AppIconMenu
              title="Settings"
              onClick={() => navigate("/admin/general")}
            >
              <SettingsIcon />
            </AppIconMenu> */}
          </Box>
          <Box
            sx={{
              ml: { sm: 4 },
              mr: { xs: 4, sm: 0 },
              minWidth: { md: 150 },
              "& .user-info-view": {
                p: 0,
              },
              "& .user-info": {
                display: { xs: "none", md: "block" },
              },
            }}
          >
            <UserInfo />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;
