import { Box, IconButton, Link } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import { TableToolBarModel } from "components/CustomTable/CustomTableModel";
import FillTaskIcon from "components/Icons/FillTaskIcon";
import React, { useState } from "react";
import { textColor } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

const rows: any[] = [
  {
    id: "1",
    name: "Intended Use and Context of Use",
    version: "V1.0",
    status: "Effective",
    taskId: "TK#006",
    taskUrl: "/products/123/deliverables/Risk Management/System Hazard Analysis/sha/100/worksheet",
    template: "System Default (V1.0)",
  },
];

interface RequiredProps {
  [x: string]: any;
}

const Required: React.FC<RequiredProps> = () => {
  const handleDeleteClick = (value) => {
    console.log(value);
  };

  const [columns, setColumns] = useState<Column[]>([
    {
      id: "taskId",
      label: "workspace.input.columns.taskId",
      minWidth: 100,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex items-center">
            <Box sx={{ width: "16px" }}>
              <FillTaskIcon />
            </Box>
            <Link sx={{ marginLeft: 2 }} underline="none" href={record.taskUrl}>
              {value}
            </Link>
          </Box>
        );
      },
    },
    {
      id: "name",
      label: "common.deliverable",
      minWidth: 200,
      align: "left",
    },
    {
      id: "version",
      label: "workspace.input.columns.version",
      minWidth: 50,
      align: "left",
    },
    {
      id: "status",
      label: "common.status",
      minWidth: 80,
      align: "left",
    },
    {
      id: "template",
      label: "workspace.input.columns.template",
      minWidth: 80,
      align: "left",
    },
    {
      id: "actions",
      label: "setting.product.column.actions",
      minWidth: 60,
      align: "left",
      format: (value, record) => {
        return (
          <Box>
            <IconButton
              sx={{ padding: "2px" }}
              onClick={() => {
                handleDeleteClick(record);
              }}
            >
              <AppSvgIcon size={16} color={textColor["65"]}>
                {"material-solid:delete"}
              </AppSvgIcon>
            </IconButton>
          </Box>
        );
      },
    },
  ]);

  const handleRequiredAddClick = () => {
    console.log("handleRequiredAddClick");
  };

  const toolbar: TableToolBarModel = {
    showSearch: true,
    buttons: [
      {
        id: "common.add",
        icon: TableHeaderButtonsIconType.ADD,
        type: "text",
        color: "#2e6ccb",
        isBlue: true,
        onChange: handleRequiredAddClick,
      },
      {
        id: "common.customize",
        icon: TableHeaderButtonsIconType.SETTING,
        type: "outlined",
        onChange: () => {},
      },
    ],
  };

  return (
    <Box>
      <CustomTable toolBar={toolbar} columns={columns} rows={rows} showPage={false} maxHeight={"calc(100vh - 280px)"} />
    </Box>
  );
};

export default Required;
