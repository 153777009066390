import AddBoxIcon from "./AddBoxIcon";
import AddIcon from "./AddIcon";
import ArrowBackIcon from "./ArrowBackIcon";
import AssuranceCaseIcon from "./AssuranceCaseIcon";
import ClosedTasks from "./ClosedTasks";
import CloseIcon from "./CloseIcon";
import CustomizeIcon from "./CustomizeIcon";
import CybersecurityIcon from "./CybersecurityIcon";
import DataIcon from "./DataIcon";
import DCDIcon from "./DCDIcon";
import ExportIcon from "./ExportIcon";
import FeedsTabIcon from "./FeedsTabIcon";
import FillDetailIcon from "./FillDetailIcon";
import FillProduct40Icon from "./FillProduct40Icon";
import FillProductIcon from "./FillProductIcon";
import FillProductTask40Icon from "./FillProductTask40Icon";
import FillTaskIcon from "./FillTaskIcon";
import FolderIcon from "./FolderIcon";
import HomeIcon from "./HomeIcon";
import ModuleIcon from "./ModuleIcon";
import ModulesIcon from "./ModulesIcon";
import NoDataIcon from "./NoDataIcon";
import OpenFeeds from "./OpenFeeds";
import OpenSubtasks from "./OpenSubtasks";
import OpenTasks from "./OpenTasks";
import OtherTimeIcon from "./OtherTimeIcon";
import ProductionIcon from "./ProductionIcon";
import Products from "./Products";
import RemoveBoxIcon from "./RemoveBoxIcon";
import RequirementIcon from "./RequirementIcon";
import RiskIcon from "./RiskIcon";
import SendIcon from "./SendIcon";
import StarFilledIcon from "./StarFilledIcon";
import TaskItemIcon from "./TaskItemIcon";
import TaskTabIcon from "./TaskTabIcon";
import TechnicalFileIcon from "./TechnicalFileIcon";
import Users from "./Users";
import WordIcon from "./WordIcon";

const Icons = {
  ArrowBackIcon: ArrowBackIcon,
  AddIcon: AddIcon,
  CloseIcon: CloseIcon,
  CustomizeIcon: CustomizeIcon,
  FillTaskIcon: FillTaskIcon,
  OtherTimeIcon: OtherTimeIcon,
  FeedsTabIcon: FeedsTabIcon,
  ExportIcon: ExportIcon,
  WordIcon: WordIcon,
  DataIcon: DataIcon,
  SendIcon: SendIcon,
  TaskTabIcon: TaskTabIcon,
  HomeIcon: HomeIcon,
  ModuleIcon: ModuleIcon,
  FillProduct40Icon: FillProduct40Icon,
  FillProductIcon: FillProductIcon,
  FillProductTask40Icon: FillProductTask40Icon,
  StarFilledIcon: StarFilledIcon,
  AddBoxIcon: AddBoxIcon,
  RemoveBoxIcon: RemoveBoxIcon,
  FolderIcon: FolderIcon,
  TaskItemIcon: TaskItemIcon,
  ModulesIcon: ModulesIcon,
  RequirementIcon: RequirementIcon,
  RiskIcon: RiskIcon,
  CybersecurityIcon: CybersecurityIcon,
  AssuranceCaseIcon: AssuranceCaseIcon,
  DCDIcon: DCDIcon,
  ProductionIcon: ProductionIcon,
  TechnicalFileIcon: TechnicalFileIcon,
  FillDetailIcon: FillDetailIcon,
  OpenFeeds: OpenFeeds,
  Products: Products,
  OpenTasks: OpenTasks,
  NoDataIcon: NoDataIcon,
  OpenSubtasks: OpenSubtasks,
  ClosedTasks: ClosedTasks,
  Users: Users,
};

export default Icons;
