import { Box } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import { TableToolBarModel } from "components/CustomTable/CustomTableModel";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

const intReferenceMockData = {
  "System Hazard Analysis": {
    rows: [
      {
        id: "1",
        name: "RMF Plan",
        status: "Approved",
      },
    ],
  },
  "User Needs": { rows: [] },
  "Design Input Requirements": { rows: [] },
  "Test Cases": { rows: [] },
  "Design Control Traceability Matrix": { rows: [] },
};

const columns: Column[] = [
  {
    id: "name",
    label: "process.input.columns.processName",
    minWidth: 200,
    align: "left",
  },
  {
    id: "status",
    label: "process.input.columns.processStatus",
    minWidth: 80,
    align: "left",
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    minWidth: 60,
    align: "left",
    format: (value, record) => {
      return (
        <Box className="flex">
          <AppSvgIcon size={16} sxStyle={{ cursor: "pointer" }}>
            material-solid:delete
          </AppSvgIcon>
        </Box>
      );
    },
  },
];

const toolbar: TableToolBarModel = {
  buttons: [
    {
      id: "common.customize",
      icon: TableHeaderButtonsIconType.SETTING,
      type: "outlined",
      onChange: () => {},
    },
  ],
};

interface InternalReferenceProps {
  [x: string]: any;
}

const InternalReference: React.FC<InternalReferenceProps> = () => {
  const params = useParams();
  const { pid } = params;

  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    if (pid) {
      const mockData = intReferenceMockData[pid];
      console.log(mockData);
      if (mockData) {
        setRows(mockData.rows);
      }
    }
  }, [pid]);

  return (
    <Box>
      <CustomTable
        toolBar={toolbar}
        columns={columns}
        rows={rows}
        showPage={false}
        maxHeight="auto"
        addRow={() => {}}
        addRowTextId="common.add"
      />
    </Box>
  );
};

export default InternalReference;
