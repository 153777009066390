import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Avatar, Box, Button, Grid, IconButton, InputAdornment, styled } from "@mui/material";
import { propsToClassKey } from "@mui/styles";
import AppSvgIcon from "components/AppSvgIcon";
import MyTextField from "components/MyTextField";
import { Form, Formik } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { bgColor, boxShadow, textColor } from "shared/constants/AppConst";
import * as yup from "yup";

import ChangeScope from "./ChangeScope";

const Container = styled(Box)(({ theme }) => ({}));

interface ScopeProps {
  [x: string]: any;
  isPadding?: boolean;
}

const Scope: React.FC<ScopeProps> = (props) => {
  const [edit, setEdit] = React.useState(false);
  const [desc, setDesc] = React.useState("A brief instruction about this deliverable.");
  const { messages } = useIntl();
  const validationSchema = yup.object({
    name: yup.string().required(messages["validation.nameRequired"] as string),
  });
  const handleSave = () => {
    console.log(document.getElementById("submit"));
    document.getElementById("submit").click();
  };

  const handleCancel = () => {
    document.getElementById("cancel").click();
  };

  const cancel = (resetForm) => {
    resetForm();
    setEdit(false);
  };

  return (
    <Container>
      <AppScrollbar
        sx={{
          maxHeight: "calc(100vh - 105px)",
          background: bgColor.main,
          padding: props?.isPadding ? (theme) => theme.spacing(5) : 0,
        }}
      >
        <AppScrollbar
          sx={{
            padding: (theme) => theme.spacing(5),
            background: (theme) => theme.palette.background.paper,
            boxShadow: boxShadow.cardBoxShadow,
            height: "100%",
          }}
        >
          <Box className="w-full flex justify-between items-center">
            <Box></Box>
            <Box>
              <Box sx={{ display: edit ? "none" : "inline-block" }}>
                <Button
                  sx={{ height: "32px", borderRadius: "4px" }}
                  variant="outlined"
                  color="inherit"
                  startIcon={<AppSvgIcon size={16}>{"material-solid:edit"}</AppSvgIcon>}
                  onClick={() => {
                    setEdit(true);
                  }}
                >
                  <IntlMessages id="common.edit" />
                </Button>
              </Box>
              <Box sx={{ display: edit ? "inline-block" : "none" }}>
                <Button
                  className="text-color-08"
                  sx={{
                    height: "32px",
                    mr: 3,
                    borderRadius: "4px",
                    border: "solid 1px rgba(0, 0, 0, 0.15)",
                    color: textColor["85"],
                  }}
                  onClick={handleCancel}
                  startIcon={
                    <AppSvgIcon size={16} color={textColor["65"]}>
                      {"material-solid:cancel"}
                    </AppSvgIcon>
                  }
                  variant="outlined"
                >
                  <IntlMessages id="common.cancel" />
                </Button>
                <Button
                  onClick={handleSave}
                  color="primary"
                  startIcon={<AppSvgIcon size={16}>{"material-solid:save"}</AppSvgIcon>}
                  variant="contained"
                >
                  <IntlMessages id="common.save" />
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className="w-full flex justify-between flex-wrap" sx={{ height: "calc(100% - 32px)" }}>
            <Box className="w-full">
              <Box
                className="w-full flex items-center"
                sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
              >
                <IntlMessages id="process.scope.basicInfo" />
              </Box>
              <Formik
                initialValues={{
                  name: "System Hazard Analysis",
                  id: "TK#",
                  status: "Draft",
                  version: "",
                  owner: "",
                  // description: "",
                  // changeScope: [],
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting, setValues }) => {
                  console.log(data);
                  setValues({
                    ...data,
                  });
                  setSubmitting(false);
                  setEdit(false);
                }}
              >
                {({ isSubmitting, values, setFieldValue, resetForm }) => (
                  <Form className="w-full flex-col" noValidate autoComplete="off">
                    <Box className="w-full h-auto p-24 border">
                      <Grid container spacing={6}>
                        <Grid item xs={6} md={6}>
                          <MyTextField
                            name="name"
                            fullWidth
                            label="common.name"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    title="Clear"
                                    aria-label="Clear"
                                    size="small"
                                    style={{ visibility: edit && values.name ? "visible" : "hidden" }}
                                    onClick={() => {
                                      setFieldValue("name", "");
                                    }}
                                  >
                                    <AppSvgIcon size="10px">{"material-outline:clear"}</AppSvgIcon>
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            variant={edit ? "outlined" : "standard"}
                            disabled={!edit}
                            required={edit ? true : false}
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <MyTextField
                            name="id"
                            fullWidth
                            label="workspace.form.id"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    title="Clear"
                                    aria-label="Clear"
                                    size="small"
                                    style={{ visibility: edit && values.id ? "visible" : "hidden" }}
                                    onClick={() => {
                                      setFieldValue("id", "");
                                    }}
                                  >
                                    <AppSvgIcon size="10px">{"material-outline:clear"}</AppSvgIcon>
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            variant={edit ? "outlined" : "standard"}
                            disabled={!edit}
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <MyTextField name="status" fullWidth label="common.status" variant="standard" disabled />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <MyTextField
                            name="version"
                            fullWidth
                            label="workspace.form.version"
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <MyTextField
                            name="owner"
                            fullWidth
                            label="workspace.column.owner"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {/* <AppSvgIcon size="24px">{"material-outline:clear"}</AppSvgIcon> */}
                                  <Avatar sx={{ width: "24px", height: "24px" }} />
                                </InputAdornment>
                              ),
                            }}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Button
                        sx={{
                          position: "relative",
                          display: "none",
                          minWidth: 100,
                        }}
                        id="submit"
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        <IntlMessages id="common.save" />
                      </Button>
                      <Button
                        sx={{
                          position: "relative",
                          display: "none",
                          minWidth: 100,
                        }}
                        id="cancel"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          cancel(resetForm);
                        }}
                        disabled={isSubmitting}
                      >
                        <IntlMessages id="common.cancel" />
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
              <Box
                className="w-full flex items-center"
                sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
              >
                <IntlMessages id="common.description" />
              </Box>
              <Box
                sx={{
                  "& .ck.ck-content": {
                    width: "100%",
                    height: "250px",
                  },
                }}
              >
                <CKEditor
                  editor={ClassicEditor}
                  data={desc}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDesc(data);
                    // console.log({ event, editor, data });
                  }}
                />
              </Box>
            </Box>
            <Box className="w-full">
              <Box
                className="w-full flex items-center"
                sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
              >
                <IntlMessages id="process.scope.changeScope" />
              </Box>
              <Box className="w-full flex-col border" sx={{ height: "calc(100% - 26px)" }}>
                <ChangeScope edit={edit} width="99%"></ChangeScope>
              </Box>
            </Box>
          </Box>
        </AppScrollbar>
      </AppScrollbar>
    </Container>
  );
};
export default Scope;
