import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import IntlMessages from "@crema/utility/IntlMessages";
import { Avatar, Box, Button, Grid, IconButton, InputAdornment } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import MyTextField from "components/MyTextField";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { textColor } from "shared/constants/AppConst";
import * as yup from "yup";

import ChangeScope from "./ChangeScope";

const scopeMockData = {
  "System Hazard Analysis": {
    formData: {
      name: "System Hazard Analysis",
      id: "TK#",
      status: "Draft",
      version: "",
      owner: "",
    },
    descriptionRichHtml: "<p>A brief instruction about this deliverable.</p>",
    changeScopeTree: [
      {
        id: "Risk Management",
        value: "Risk Management",
        checked: false,
        items: [
          {
            id: "Safety Risks",
            value: "Safety Risks",
            checked: false,
            items: [
              {
                id: "Safety Harms",
                value: "Safety Harms",
                checked: false,
              },
              {
                id: "Hazards",
                value: "Hazards",
                checked: false,
                items: [
                  {
                    id: "Hazard Category",
                    value: "Hazard Category",
                    checked: false,
                  },
                  {
                    id: "System Hazards",
                    value: "System Hazards",
                    checked: false,
                  },
                  {
                    id: "Hazardous Situations",
                    value: "Hazardous Situations",
                    checked: false,
                  },
                  {
                    id: "Causes",
                    value: "Causes",
                    checked: false,
                  },
                  {
                    id: "Basic Events",
                    value: "Basic Events",
                    checked: false,
                  },
                  {
                    id: "Risk Controls",
                    value: "Risk Controls",
                    checked: false,
                  },
                ],
              },
            ],
          },
          {
            id: "Security Risks",
            value: "Security Risks",
            checked: false,
            items: [
              {
                id: "Security Harms",
                value: "Security Harms",
                checked: false,
              },
              {
                id: "Threats",
                value: "Threats",
                checked: false,
                items: [
                  {
                    id: "Sub Threats",
                    value: "Threats",
                    checked: false,
                  },
                  {
                    id: "Vulnerabilities",
                    value: "Vulnerabilities",
                    checked: false,
                  },
                  {
                    id: "Threat Attacks",
                    value: "Threat Attacks",
                    checked: false,
                  },
                ],
              },
            ],
          },
          {
            id: "FMEAs",
            value: "FMEAs",
            checked: false,
            items: [
              {
                id: "Design FMEA",
                value: "Design FMEA",
                checked: false,
              },
              {
                id: "Process FMEA",
                value: "Process FMEA",
                checked: false,
              },
              {
                id: "Product Error Codes",
                value: "Product Error Codes",
                checked: false,
              },
              {
                id: "Code Lib",
                value: "Code Lib",
                checked: false,
              },
            ],
          },
          {
            id: "Evidences",
            value: "Evidences",
            checked: false,
            items: [
              {
                id: "Risk Benefit Analysis Ref",
                value: "Risk Benefit Analysis Ref.",
                checked: false,
              },
            ],
          },
        ],
      },
    ],
  },
  "User Needs": {
    formData: {
      name: "User Needs",
      id: "TK#",
      status: "Draft",
      version: "",
      owner: "",
    },
    descriptionRichHtml: "<p>A brief instruction about this deliverable.</p>",
    changeScopeTree: [
      {
        id: "Design Inputs",
        value: "Design Inputs",
        checked: false,
        items: [
          {
            id: "User Needs",
            value: "User Needs",
            checked: false,
          },
        ],
      },
    ],
  },
};

interface ScopeFormProps {
  formData: any;
  formik: FormikProps<any>;
  edit?: boolean;
  onCancel?: () => void;
}

const ScopeForm: React.FC<ScopeFormProps> = (props: ScopeFormProps) => {
  const { formData, formik, edit, onCancel } = props;

  useEffect(() => {
    if (formData && formik) {
      formik.setValues(formData);
    }
  }, [formData]);

  const { messages } = useIntl();

  const cancel = (resetForm) => {
    formik.resetForm();
    onCancel && onCancel();
  };

  return (
    <>
      <Form className="w-full flex-col" noValidate autoComplete="off">
        <Box className="w-full h-auto p-24 border">
          <Grid container spacing={6}>
            <Grid item xs={6} md={6}>
              <MyTextField
                name="name"
                fullWidth
                label="common.name"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{ visibility: edit && formik.values.name ? "visible" : "hidden" }}
                        onClick={() => {
                          formik.setFieldValue("name", "");
                        }}
                      >
                        <AppSvgIcon size="10px">{"material-outline:clear"}</AppSvgIcon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant={edit ? "outlined" : "standard"}
                disabled={!edit}
                required={edit ? true : false}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <MyTextField
                name="id"
                fullWidth
                label="workspace.form.id"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{ visibility: edit && formik.values.id ? "visible" : "hidden" }}
                        onClick={() => {
                          formik.setFieldValue("id", "");
                        }}
                      >
                        <AppSvgIcon size="10px">{"material-outline:clear"}</AppSvgIcon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant={edit ? "outlined" : "standard"}
                disabled={!edit}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <MyTextField name="status" fullWidth label="common.status" variant="standard" disabled />
            </Grid>
            <Grid item xs={6} md={6}>
              <MyTextField name="version" fullWidth label="workspace.form.version" variant="standard" disabled />
            </Grid>
            <Grid item xs={6} md={6}>
              <MyTextField
                name="owner"
                fullWidth
                label="workspace.column.owner"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <AppSvgIcon size="24px">{"material-outline:clear"}</AppSvgIcon> */}
                      <Avatar sx={{ width: "24px", height: "24px" }} />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                disabled
              />
            </Grid>
          </Grid>
          <Button
            sx={{
              position: "relative",
              display: "none",
              minWidth: 100,
            }}
            id="submit"
            color="primary"
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >
            <IntlMessages id="common.save" />
          </Button>
          <Button
            sx={{
              position: "relative",
              display: "none",
              minWidth: 100,
            }}
            id="cancel"
            color="primary"
            variant="contained"
            onClick={() => {
              cancel(formik.resetForm);
            }}
            disabled={formik.isSubmitting}
          >
            <IntlMessages id="common.cancel" />
          </Button>
        </Box>
      </Form>
    </>
  );
};
export default ScopeForm;
