import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import { bgColor } from "shared/constants/AppConst";

interface DefaultLayoutContainerProps {
  children: ReactNode;

  [x: string]: any;
}

const DefaultLayoutContainer: React.FC<DefaultLayoutContainerProps> = ({ children, ...rest }) => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backgroundColor: bgColor.main,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default DefaultLayoutContainer;
