import IntlMessages from "@crema/utility/IntlMessages";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import $ from "components/Gojs";
import MySelectField from "components/MySelectField";
import * as go from "gojs";
import { ReactDiagram } from "gojs-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LinkData, ModelData, NodeData } from "types/models/GoJs";

import { flowData } from "./d";

const Container = styled(Box)(({ theme }) => ({
  ".impact-diagram": {
    width: "100%",
    minHeight: "600px",
    ".diagram-box": {
      width: "100%",
      height: "inherit",
      minHeight: "600px",
    },
  },
}));

let diagram;
const ImpactSummary = () => {
  const [impactedDeliverable, setImpactedDeliverable] = useState([
    {
      id: 1,
      name: "Design FMEA",
    },
  ]);
  const [impactedDeliverableValue, setImpactedDeliverableValue] = useState(1);

  const [nodeData, setNodeData] = useState<NodeData[]>(flowData.nodeData);
  const [linkData, setLinkData] = useState<LinkData[]>(flowData.linkData);

  const [anchorElRight, setAnchorElRight] = useState<null | HTMLElement>(null);

  const handleOpenRightClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRight(event.currentTarget);
  };

  const handleOpenRightClose = () => {
    setAnchorElRight(null);
  };

  const diagramRef = useRef(null);

  const handleDiagramChange = (diagram) => {
    const a = diagram.model.toJson();
    // console.log(a);
  };

  const initDiagram = () => {
    diagram = $(go.Diagram, {
      padding: 20,
      "undoManager.isEnabled": false, // must be set to allow for model change listening, now use false,
      // $(go.TreeLayout, { layerSpacing: 150 }),
      allowTextEdit: false,
      allowDrop: false,
      // allowMove: false,
      // layout: $(go.LayeredDigraphLayout, { setsPortSpots: false }),
    });

    let graphLinksModel = new go.GraphLinksModel();
    graphLinksModel.linkKeyProperty = "key";
    diagram.model = graphLinksModel;

    diagram.nodeTemplate = $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
      },
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Shape,
        {
          name: "SHAPE",
          fill: "white",
          portId: "",
          fromSpot: go.Spot.AllSides,
          toSpot: go.Spot.AllSides,
        },
        new go.Binding("fill", "color"),
        new go.Binding("figure", "figure"),
        new go.Binding("width", "width"),
        new go.Binding("height", "height"),
        new go.Binding("stroke", "stroke"),
        new go.Binding("fontSize", "fontSize")
      ),
      $(go.TextBlock, { margin: 8, editable: true }, new go.Binding("text").makeTwoWay())
    );

    diagram.groupTemplate = $(
      go.Group,
      "Spot",
      {
        layerName: "Background",
        ungroupable: true,
        locationSpot: go.Spot.Center,
        selectionObjectName: "BODY",
        computesBoundsAfterDrag: true, // allow dragging out of a Group that uses a Placeholder
        handlesDragDropForMembers: true, // don't need to define handlers on Nodes and Links
        avoidable: false,
        // layout: $(go.LayeredDigraphLayout),
      },
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Panel,
        "Auto",
        { name: "BODY" },
        $(
          go.Shape,
          {
            parameter1: 10,
            fill: "white",
            strokeWidth: 2,
            stroke: "transparent",
            portId: "",
            cursor: "pointer",
            fromLinkable: true,
            toLinkable: true,
            fromLinkableDuplicates: true,
            toLinkableDuplicates: true,
            fromSpot: go.Spot.AllSides,
            toSpot: go.Spot.AllSides,
          },
          new go.Binding("fill"),
          new go.Binding("stroke", "color"),
          new go.Binding("strokeWidth", "thickness"),
          new go.Binding("strokeDashArray", "dash")
        ),
        $(go.Placeholder, { background: "transparent", margin: 10 })
      ),
      $(
        go.TextBlock,
        {
          alignment: go.Spot.Top,
          alignmentFocus: go.Spot.Bottom,
          font: "bold 12pt sans-serif",
          editable: true,
        },
        new go.Binding("text"),
        new go.Binding("stroke", "color")
      )
    );

    // go.Shape.defineArrowheadGeometry(
    //   "Database",
    //   "F1 m 0,0 l 8,4 -8,4 2,-4 z"
    // );
    diagram.linkTemplate = $(
      go.Link,
      {
        routing: go.Link.AvoidsNodes,
        // corner: 10,
      },
      new go.Binding("routing", "routing"),
      new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
      new go.Binding("toSpot", "toSpot", go.Spot.parse),
      $(
        go.Shape,
        { strokeWidth: 2, stroke: "#000" },
        new go.Binding("stroke", "color"),
        new go.Binding("strokeDashArray", "dash"),
        new go.Binding("strokeWidth", "thickness")
      ),
      $(
        go.Shape,
        { fromArrow: "Backward", visible: false },
        new go.Binding("fromArrow", "fromArrow"),
        new go.Binding("visible", "dir", (dir) => dir === 2),
        new go.Binding("fill", "arrowColor"),
        new go.Binding("scale", "scale", (t) => (2 + t) / 3)
      ),
      $(
        go.Shape,
        { toArrow: "Standard" },
        new go.Binding("toArrow", "toArrow"),
        new go.Binding("visible", "dir", (dir) => dir >= 1),
        new go.Binding("fill", "arrowColor"),
        new go.Binding("scale", "scale", (t) => (2 + t) / 3)
      ),
      $(
        go.TextBlock,
        { alignmentFocus: new go.Spot(0, 1, -4, 0), editable: false },
        new go.Binding("text").makeTwoWay(), // TwoWay due to user editing with TextEditingTool
        new go.Binding("stroke", "textColor")
      )
    );

    diagram.addModelChangedListener((e) => {
      console.log(e);
      handleDiagramChange(diagram);
    });
    return diagram;
  };

  const diagramDom = useMemo(() => {
    return (
      <ReactDiagram
        ref={diagramRef}
        initDiagram={initDiagram}
        divClassName="diagram-box"
        nodeDataArray={nodeData}
        linkDataArray={linkData}
      />
    );
  }, [diagramRef, nodeData, linkData]);

  return (
    <>
      <Container>
        <Box className="impact-top flex justify-between items-center">
          <Box className="left-menu">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Typography>
                <IntlMessages id="setting.impactSummary.impactedDeliverable" />
              </Typography>
              <Select
                value={(impactedDeliverableValue | 0).toString()}
                onChange={(event: SelectChangeEvent) => {
                  setImpactedDeliverableValue(Number(event.target.value));
                }}
                displayEmpty
              >
                {impactedDeliverable.map((x) => {
                  return (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box className="right-menu">
            <Box className="legends-list">
              <Button
                className="text-color-08"
                sx={{
                  mr: 3,
                  borderRadius: "4px",
                  border: "solid 1px rgba(0, 0, 0, 0.2)",
                }}
                variant="outlined"
                endIcon={
                  Boolean(anchorElRight) ? (
                    <AppSvgIcon color="action">{"material-outline:expand_less"}</AppSvgIcon>
                  ) : (
                    <AppSvgIcon color="action">{"material-outline:expand_more"}</AppSvgIcon>
                  )
                }
                onClick={handleOpenRightClick}
                id="report_cancel"
              >
                <IntlMessages id="common.legends" />
              </Button>
            </Box>
            <Menu
              id="legends-menus"
              anchorEl={anchorElRight}
              keepMounted
              open={Boolean(anchorElRight)}
              onClose={handleOpenRightClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  marginRight: 2,
                },
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem>
                <IntlMessages id="setting.impactSummary.ACauseOfB" />
              </MenuItem>
              <MenuItem>
                <IntlMessages id="setting.impactSummary.ASubItemOfB" />
              </MenuItem>
              <MenuItem>
                <IntlMessages id="setting.impactSummary.BOfAAttribute" />
              </MenuItem>
              <MenuItem>
                <IntlMessages id="setting.impactSummary.BOfADataSource" />
              </MenuItem>
              <MenuItem>
                <IntlMessages id="setting.impactSummary.ABShared" />
              </MenuItem>
              <MenuItem>
                <IntlMessages id="setting.impactSummary.PrimaryColumn" />
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box className="impact-bottom impact-diagram flex justify-center">{diagramDom}</Box>
      </Container>
    </>
  );
};

export default ImpactSummary;
