import { WorkItemStatus } from "shared/constants/AppEnums";

export const NextStatus: any = {
  [WorkItemStatus.Draft]: [WorkItemStatus.Draft, WorkItemStatus.InReview],
  [WorkItemStatus.InReview]: [
    WorkItemStatus.InReview,
    WorkItemStatus.Draft,
    WorkItemStatus.Approved,
    WorkItemStatus.Rejected,
  ],
  [WorkItemStatus.Approved]: [WorkItemStatus.Approved, WorkItemStatus.Draft, WorkItemStatus.Effective],
  [WorkItemStatus.Rejected]: [WorkItemStatus.Rejected, WorkItemStatus.Draft],
  [WorkItemStatus.Effective]: [WorkItemStatus.Effective],
};
