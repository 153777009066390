import AppTooltip from "@crema/core/AppTooltip";
import { IconButton, Theme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import React, { ReactNode } from "react";
interface AppIconMenuProps {
  title: string;
  tooltipPosition?:
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const AppIconMenu: React.FC<AppIconMenuProps> = ({
  children,
  onClick,
  title,
  tooltipPosition = "bottom",
  ...res
}) => {
  return (
    <>
      <AppTooltip title={title} placement={tooltipPosition}>
        <IconButton
          className="icon-btn"
          sx={{
            borderRadius: "50%",
            width: 30,
            height: 30,
            color: (theme) => theme.palette.text.secondary,
            // backgroundColor: (theme) => theme.palette.background.default,
            // border: 1,
            borderColor: "transparent",
            "&:hover, &:focus": {
              color: (theme) => theme.palette.text.primary,
              backgroundColor: (theme) =>
                alpha(theme.palette.background.default, 0.9),
              borderColor: (theme) =>
                alpha(theme.palette.text.secondary, 0.25),
            },
          }}
          onClick={onClick}
          size="large"
        >
          {children}
        </IconButton>
      </AppTooltip>
    </>
  );
}

export default AppIconMenu;
