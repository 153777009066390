import { Navigate } from "react-router-dom";

import { adminPage } from "./Admin";
import AccessControl from "./Admin/AccessControl";
import Groups from "./Admin/Groups";
import Profile from "./Admin/Profile";
import Users from "./Admin/Users";
import Dashboard from "./Dashboard";
import Deliverables from "./Deliverables";
import Checklist from "./Deliverables/Checklist";
import Collaboration from "./Deliverables/Collaboration";
import IndexTreeTable from "./Deliverables/IndexTreeTable";
import Input from "./Deliverables/Input";
import RelationshipsAndImpact from "./Deliverables/RelationshipsAndImpact";
import Report from "./Deliverables/Report";
// import Rules from "./Deliverables/Rules";
import Scope from "./Deliverables/Scope";
import Worksheet from "./Deliverables/Worksheet";
import DetailLayout from "./DetailLayout";
// import GanttChart from "./GanttChart";
import ProductLayout from "./Layout";
import Lifecycle from "./Lifecycle";
import Activities from "./Lifecycle/Activities";
import AllActivities from "./Lifecycle/Activities/components/AllActivities";
import MyActivities from "./Lifecycle/Activities/components/MyActivities";
import Feeds from "./Lifecycle/Feeds";
import Capa from "./Lifecycle/Feeds/Capa";
import Complaint from "./Lifecycle/Feeds/Complaint";
import ImpactAnalysis from "./Lifecycle/Feeds/ImpactAnalysis";
import Manual from "./Lifecycle/Feeds/Manual";
import ProductLifeCycle from "./Lifecycle/ProductLifeCycle";
import Products from "./Products";
import ProductSettingsLayout from "./ProductSettingsLayout";
import Relationships from "./Relationships";
import Rules from "./Rules";
import Cvss from "./Rules/Cvss";
import Cwss from "./Rules/Cwss";
import RiskPolicy from "./Rules/RiskPolicy";
// import Tasks from "./Tasks";
// import Workitems from "./Workitems";
import DetailsWorksheet from "./Worksheet";

// const Products = React.lazy(() => import("./Products"));
export const productPagesConfigs = [
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/products/:code",
    element: <ProductLayout />,
    children: [
      {
        path: "deliverables",
        element: <Deliverables />,
        children: [
          {
            path: ":module/index",
            element: <IndexTreeTable />,
          },
          {
            path: ":module/:processId/:type/:version/scope",
            element: <Scope />,
          },
          {
            path: ":module/:processId/:type/:version/collaboration",
            element: <Collaboration />,
          },
          {
            path: ":module/:processId/:type/:version/input",
            element: <Input />,
          },
          {
            path: ":module/:processId/:type/:version/worksheet",
            element: <Worksheet />,
          },
          {
            path: ":module/:processId/:type/:version/impactSummary",
            element: <RelationshipsAndImpact />,
          },
          {
            path: ":module/:processId/:type/:version/checklist",
            element: <Checklist />,
          },
          {
            path: ":module/:processId/:type/:version/report",
            element: <Report />,
          },
        ],
      },
      {
        path: "lifecycle",
        element: <Lifecycle />,
        children: [
          {
            path: "project",
            element: <ProductLifeCycle />,
          },
          {
            path: "feeds",
            element: <Feeds />,
            children: [
              {
                path: "analysis",
                element: <ImpactAnalysis />,
              },
              {
                path: "manual",
                element: <Manual />,
              },
              {
                path: "complaint",
                element: <Complaint />,
              },
              {
                path: "capa",
                element: <Capa />,
              },
            ],
          },
          {
            path: "activities",
            element: <Activities />,
            children: [
              {
                path: "myActivities",
                element: <MyActivities />,
              },
              {
                path: "allActivities",
                element: <AllActivities />,
              },
            ],
          },
        ],
      },
      {
        path: "relationships",
        element: <Relationships />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      // {
      //   path: "workitems",
      //   element: <Workitems />,
      // },
      ...adminPage,
    ],
  },
  {
    path: "/products/:id",
    element: <ProductSettingsLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "rules",
        element: <Navigate to="riskPolicy" />,
      },
      {
        path: "rules",
        element: <Rules />,
        children: [
          {
            path: "riskPolicy",
            element: <RiskPolicy />,
          },
          {
            path: "cwss",
            element: <Cwss />,
          },
          {
            path: "cvss",
            element: <Cvss />,
          },
        ],
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "groups",
        element: <Groups />,
      },
      {
        path: "accessControl",
        element: <AccessControl />,
      },
    ],
  },
  {
    path: "/products/:code/:type",
    element: <DetailLayout />,
    children: [
      {
        path: "scope",
        element: <Scope isPadding={true} />,
      },
      {
        path: "collaboration",
        element: <Collaboration isPadding={true} />,
      },
      // {
      //   path: "rules",
      //   element: <Rules />,
      // },
      {
        path: "input",
        element: <Input isPadding={true} />,
      },
      {
        path: "worksheet",
        element: <DetailsWorksheet />,
      },
      {
        path: "impactSummary",
        element: <RelationshipsAndImpact isPadding={true} />,
      },
      {
        path: "checklist",
        element: <Checklist isPadding={true} />,
      },
      {
        path: "report",
        element: <Report isPadding={true} />,
      },
    ],
  },
];
