import IntlMessages from "@crema/utility/IntlMessages";
import AppsIcon from "@mui/icons-material/Apps";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Checkbox, Chip, ClickAwayListener, List, ListItem, Popover, Stack } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import AddIcon from "components/Icons/AddIcon";
import React, { useEffect } from "react";
import { borderColor, borderRadius, boxShadow, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import ConfigModal from "./ConfigModal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const typesMockData = [
  {
    id: "HZ",
    name: "Hazard",
    icon: "",
    description: "",
    module: "Risk Management",
    system_default: "Yes",
    fields: "Config",
  },
  {
    id: "HS",
    name: "Hazardous Situation",
    icon: "",
    description: "",
    module: "Risk Management",
    system_default: "Yes",
    fields: "Config",
  },
  {
    id: "Harm",
    name: "Harm",
    icon: "",
    description: "",
    module: "Risk Management",
    system_default: "Yes",
    fields: "Config",
  },
  {
    id: "SOE",
    name: "Sequence Of Events",
    icon: "",
    description: "",
    module: "Risk Management",
    system_default: "Yes",
    fields: "Config",
  },
  {
    id: "FM",
    name: "Failure Mode",
    icon: "",
    description: "",
    module: "Risk Management",
    system_default: "Yes",
    fields: "Config",
  },
  {
    id: "CS",
    name: "Cause",
    icon: "",
    description: "",
    module: "Risk Management",
    system_default: "Yes",
    fields: "Config",
  },
  {
    id: "IRC",
    name: "Initial Risk Control",
    icon: "",
    description: "",
    module: "Risk Management",
    system_default: "",
    fields: "Config",
  },
  {
    id: "RRC",
    name: "Residual Risk Control",
    icon: "",
    description: "",
    module: "Risk Management",
    system_default: "",
    fields: "Config",
  },
  {
    id: "UND",
    name: "User Need",
    icon: "",
    description: "",
    module: "Design Controls",
    system_default: "Yes",
    fields: "Config",
  },
  {
    id: "UST",
    name: "User Story",
    icon: "",
    description: "",
    module: "Design Controls",
    system_default: "No",
    fields: "Config",
  },
  {
    id: "SYS",
    name: "System Requirement",
    icon: "",
    description: "",
    module: "Design Controls",
    system_default: "No",
    fields: "Config",
  },
  {
    id: "SW",
    name: "Software Requirement",
    icon: "",
    description: "",
    module: "Design Controls",
    system_default: "No",
    fields: "Config",
  },
  {
    id: "HW",
    name: "Hardware Requirement",
    icon: "",
    description: "",
    module: "Design Controls",
    system_default: "No",
    fields: "Config",
  },
  {
    id: "DS",
    name: "Design Specification",
    icon: "",
    description: "",
    module: "Design Controls",
    system_default: "No",
    fields: "Config",
  },
  {
    id: "DO",
    name: "Design Output",
    icon: "",
    description: "",
    module: "Design Controls",
    system_default: "No",
    fields: "Config",
  },
  {
    id: "TC",
    name: "Test Case",
    icon: "",
    description: "",
    module: "Design Controls",
    system_default: "Yes",
    fields: "Config",
  },
  {
    id: "VAL",
    name: "Validation",
    icon: "",
    description: "",
    module: "Design Controls",
    system_default: "Yes",
    fields: "Config",
  },
  {
    id: "VER",
    name: "Verification",
    icon: "",
    description: "",
    module: "Design Controls",
    system_default: "Yes",
    fields: "Config",
  },
  {
    id: "BUG",
    name: "Defect",
    icon: "",
    description: "",
    module: "Design Controls",
    system_default: "No",
    fields: "Config",
  },
  {
    id: "TSTPL",
    name: "Test Plan",
    icon: "",
    description: "",
    module: "Design Controls",
    system_default: "Yes",
    fields: "Config",
  },
  {
    id: "TSTRN",
    name: "Test Run",
    icon: "",
    description: "",
    module: "Design Controls",
    system_default: "Yes",
    fields: "Config",
  },
];

const WorkitemAllTypes = () => {
  const [editRow, setEditRow] = React.useState<any>(null);
  const [showConfigModal, setShowConfigModal] = React.useState<boolean>(false);

  const columns: Column[] = [
    {
      id: "id",
      label: "common.code",
      minWidth: 100,
      align: "left",
    },
    {
      id: "name",
      label: "setting.product.column.name",
      minWidth: 200,
      align: "left",
    },
    {
      id: "icon",
      label: "common.columns.icon",
      minWidth: 100,
      align: "left",
    },
    {
      id: "description",
      label: "common.description",
      minWidth: 400,
      align: "left",
    },
    {
      id: "system_default",
      label: "common.columns.system.default",
      minWidth: 150,
      align: "left",
    },
    {
      id: "module",
      label: "common.module",
      minWidth: 150,
      align: "left",
    },
    {
      id: "fields",
      label: "common.attributes",
      minWidth: 150,
      align: "left",
      format: (value, record) => {
        return (
          <Box
            sx={{ color: "#2e6ccb", cursor: "pointer" }}
            onClick={() => {
              setEditRow(record);
              setShowConfigModal(true);
            }}
          >
            {value}
          </Box>
        );
      },
    },
    {
      id: "actions",
      label: "setting.product.column.actions",
      align: "left",
      format: (value, record) => {
        if (record.system_default) {
          return (
            <Box>
              <EditIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
            </Box>
          );
        } else {
          return (
            <Box>
              <EditIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />{" "}
              <DeleteIcon sx={{ cursor: "pointer", fontSize: "16px" }} />
            </Box>
          );
        }
      },
    },
  ];

  const [rows, setRows] = React.useState([]);

  const [moduleList, setModuleList] = React.useState([
    {
      id: 5,
      checked: false,
      label: "Design Controls",
      icon: "/assets/images/file.svg",
    },
    {
      id: 2,
      checked: false,
      label: "Risk Management",
      icon: "/assets/images/file.svg",
    },
    {
      id: 3,
      checked: false,
      label: "Cybersecurity Management",
      icon: "/assets/images/file.svg",
    },
    {
      id: 4,
      checked: false,
      label: "Assurance Case Management",
      icon: "/assets/images/data.svg",
    },
    {
      id: 6,
      checked: false,
      label: "Production Management",
      icon: "/assets/images/data.svg",
    },
    {
      id: 7,
      checked: false,
      label: "Index File Management",
      icon: "/assets/images/data.svg",
    },
  ]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = () => {
    const element = document.getElementById("target");
    setAnchorEl(element);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeList = (item) => {
    const newList = [...moduleList];
    const currentItem = newList.filter((x) => x.id === item.id)[0];
    if (currentItem) {
      currentItem.checked = !currentItem.checked;
    }
    setModuleList(newList);
  };

  useEffect(() => {
    getData();
  }, [moduleList]);

  const getData = () => {
    const newRows = [];
    if (moduleList.filter((x) => x.checked).length > 0) {
      typesMockData.map((item) => {
        if (moduleList.some((x) => x.checked && item.module === x.label)) {
          newRows.push(item);
        }
      });
    } else {
      newRows.splice(0, 0, ...typesMockData);
    }
    setRows(newRows);
  };

  return (
    <>
      {/* <ContentHeader titleId="common.workitem.types" /> */}
      <Box
        sx={{
          padding: (theme) => theme.spacing(5),
          background: (theme) => theme.palette.background.paper,
          boxShadow: boxShadow.cardBoxShadow,
        }}
      >
        <Box className="flex items-center flex-wrap" sx={{ mb: 2 }}>
          <Box className="flex items-center flex-wrap">
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box className="flex items-center flex-wrap">
                  <Button
                    sx={{ color: textColor[85], border: `solid 1px ${borderColor.dividerColor}` }}
                    id="target"
                    onClick={handleClick}
                    startIcon={<AppsIcon />}
                    variant="outlined"
                  >
                    <IntlMessages id="workspace.modules" />
                  </Button>
                  <Box sx={{ marginLeft: "10px" }}>
                    <Stack direction="row" spacing={1}>
                      {moduleList
                        .filter((x) => x.checked)
                        .map((item) => (
                          <Chip
                            sx={{
                              textAlign: "center",
                              fontSize: "14px",
                              borderRadius: borderRadius.main,
                              "& .MuiChip-label": {
                                padding: "7px 12px 7px 12px",
                                color: textColor[85],
                              },
                            }}
                            className="text-color-08"
                            onDelete={() => {
                              handleChangeList(item);
                            }}
                            onClick={handleClick}
                            key={item.id}
                            label={item.label}
                          />
                        ))}
                      {moduleList.filter((x) => x.checked).length > 0 && (
                        <Button
                          onClick={() => {
                            const newModuleList = moduleList.map((item) => {
                              item.checked = false;
                              return item;
                            });
                            setModuleList(newModuleList);
                          }}
                          variant="text"
                          sx={{ color: textColor[65], fontSize: "14px" }}
                        >
                          <IntlMessages id="common.clear" />
                        </Button>
                      )}
                    </Stack>
                  </Box>
                </Box>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  sx={{ marginTop: "10px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <React.Fragment>
                    <List
                      sx={{
                        "& .MuiListItem-root:hover": {
                          background: "rgba(0, 0, 0, 0.05)",
                        },
                      }}
                    >
                      {moduleList.map((item) => (
                        <ListItem
                          key={item.id}
                          sx={{
                            padding: "2px 10px",
                          }}
                          className="text-color-08"
                        >
                          <Box
                            sx={{
                              cursor: "pointer",
                              color: textColor[85],
                            }}
                            className="flex items-center"
                            onClick={() => {
                              handleChangeList(item);
                            }}
                          >
                            <Checkbox checked={item.checked} sx={{ padding: "0 12px" }} />
                            <Box>{item.label}</Box>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  </React.Fragment>
                </Popover>
              </Box>
            </ClickAwayListener>
          </Box>
        </Box>
        <Box className="flex items-center justify-end" sx={{ mb: 2 }}>
          <Button
            variant="text"
            className="add-button"
            color="primary"
            startIcon={<AddIcon isBlue={true} />}
            onClick={() => {}}
          >
            <IntlMessages id="common.new.type" />
          </Button>
        </Box>
        <CustomTable
          columns={columns}
          rows={rows}
          showPage={false}
          maxHeight="auto"
          // addRow={() => {
          //   console.log("add row");
          // }}
          // addRowTextId="common.new.type"
        />
      </Box>
      {showConfigModal && (
        <ConfigModal
          open={showConfigModal}
          editRow={editRow}
          handleClose={() => {
            setShowConfigModal(false);
          }}
        />
      )}
    </>
  );
};

export default WorkitemAllTypes;
