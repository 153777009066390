import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { bgColor, borderColor, boxShadow, tabStyle } from "shared/constants/AppConst";

import Feeds from "./Feeds";
import InternalReference from "./InternalReference";
import Required from "./Required";

const Container = styled(Box)(({ theme }) => ({
  ".tab-sub-table": {
    padding: theme.spacing(3, 0, 0, 0),
    height: "calc(100% - 50px)",
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number | string) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

interface InputProps {
  isPadding?: boolean;
}

const Input: React.FC<InputProps> = (props) => {
  const navigate = useNavigate();
  const [index, setIndex] = useState("feeds");

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setIndex(newValue);
  };

  return (
    <Container>
      <AppScrollbar
        sx={{
          maxHeight: "calc(100vh - 105px)",
          background: bgColor.main,
          padding: props?.isPadding ? (theme) => theme.spacing(5) : 0,
        }}
      >
        <Box
          sx={{
            padding: (theme) => theme.spacing(5),
            background: (theme) => theme.palette.background.paper,
            boxShadow: boxShadow.cardBoxShadow,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: borderColor.dividerColor,
            }}
          >
            <Tabs
              value={index}
              onChange={handleChangeTab}
              sx={{
                ...tabStyle,
                "& .MuiButtonBase-root": {
                  minHeight: "48px",
                  textTransform: "none",
                },
              }}
            >
              <Tab value={"feeds"} label={<IntlMessages id="workspace.input.feeds" />} />
              <Tab value={"required"} label={<IntlMessages id="workspace.input.required" />} />
              <Tab value={"internalReference"} label={<IntlMessages id="workspace.input.internalReference" />} />
            </Tabs>
          </Box>
          <Box className="tab-sub-table">
            <TabPanel value={index} index="feeds">
              <Feeds />
            </TabPanel>
            <TabPanel value={index} index="required">
              <Required />
            </TabPanel>
            <TabPanel value={index} index="internalReference">
              <InternalReference />
            </TabPanel>
          </Box>
        </Box>
      </AppScrollbar>
    </Container>
  );
};

export default Input;
