import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { borderColor, tabStyle } from "shared/constants/AppConst";

const Input = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState("required");

  useEffect(() => {
    navigate(index);
  }, [index]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setIndex(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs
          value={index}
          onChange={handleChangeTab}
          sx={{ ...tabStyle, borderBottom: `1px solid ${borderColor.dividerColor}` }}
        >
          <Tab value="required" label={<IntlMessages id="process.input.required" />} />
          <Tab value="internalReference" label={<IntlMessages id="process.input.internalReference" />} />
          {/* <Tab onClick={() => { navigate("externalReference") }} label={<IntlMessages id="process.input.externalReference" />} /> */}
        </Tabs>
      </Box>
      <Box sx={{ paddingTop: 2 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Input;
