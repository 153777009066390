import IntlMessages from "@crema/utility/IntlMessages";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { Menu, MenuItem } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import React from "react";
import { borderColor, textColor } from "shared/constants/AppConst";

import BranchModal from "../BranchModal";
import ReleaseModal from "../ReleaseModal";
import ReuseModal from "../ReuseModal";
import SaveAsNewModal from "../SaveAsNew";

interface MoreActionProps {}

const MoreAction: React.FC<MoreActionProps> = () => {
  const [openBranchModal, setOpenBranchModal] = React.useState<boolean>(false);
  const [openReleaseModal, setOpenReleaseModal] = React.useState<boolean>(false);
  const [openReuseModal, setOpenReuseModal] = React.useState<boolean>(false);
  const [openSaveAsNewModal, setOpenSaveAsNewModal] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppSvgIcon
        size="32px"
        color={textColor[85]}
        sxStyle={{
          display: "inline-block",
          cursor: "pointer",
          my: 2,
          ml: 2,
          p: "5px",
          border: `solid 1px ${borderColor.dividerColor}`,
          borderRadius: "4px",
        }}
        onClick={handleClick}
      >
        material-outline:more_horiz
      </AppSvgIcon>
      <Menu
        id="more-menus"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiSvgIcon-root": {
            marginRight: 2,
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenBranchModal(true);
            handleClose();
          }}
        >
          <AddLinkIcon />
          <IntlMessages id="products.branch" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenReuseModal(true);
            handleClose();
          }}
        >
          <AddLinkIcon />
          <IntlMessages id="products.reuse.from" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenSaveAsNewModal(true);
            handleClose();
          }}
        >
          <AddLinkIcon />
          <IntlMessages id="products.saveAsNew" />
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            setOpenReleaseModal(true);
            handleClose();
          }}
        >
          <AddLinkIcon />
          <IntlMessages id="common.upgrade.stage" />
        </MenuItem> */}
      </Menu>
      {openBranchModal && (
        <BranchModal
          open={openBranchModal}
          handleClose={() => {
            setOpenBranchModal(false);
          }}
        />
      )}
      {openReuseModal && (
        <ReuseModal
          open={openReuseModal}
          handleClose={() => {
            setOpenReuseModal(false);
          }}
        />
      )}
      {openReleaseModal && (
        <ReleaseModal
          open={openReleaseModal}
          handleClose={() => {
            setOpenReleaseModal(false);
          }}
        />
      )}
      {openSaveAsNewModal && (
        <SaveAsNewModal
          productName="Drug Delivery Device"
          releaseVersion="R2.0"
          variant="Main"
          open={openSaveAsNewModal}
          handleClose={() => {
            setOpenSaveAsNewModal(false);
          }}
        />
      )}
    </>
  );
};

export default MoreAction;
