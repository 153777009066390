import Icon from "@mui/material/Icon";
import * as React from "react";

const ModulesSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="rnttyh043a">
        <stop stopColor="#5BA5E7" offset="0%" />
        <stop stopColor="#2E6CCB" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect fill="url(#rnttyh043a)" width="16" height="16" rx="2.72" />
      <path
        d="M5.333 4a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0zm0 4a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0zM4 13.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666zM9.333 4a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0zM8 9.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666zM9.333 12a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0zM12 5.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666zM13.333 8a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0zM12 13.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666z"
        fill="#FFF"
      />
    </g>
  </svg>
);

const ModulesIcon: React.FunctionComponent = () => {
  return <Icon component={ModulesSvg} />;
};

export default ModulesIcon;
