import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import standardsPng from "../../../../assets/imgs/standards.png";

const StandardsContainer = styled(Box)(({ theme }) => ({
  marginTop: "8px",
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
}));

const DataContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  minWidth: "600px",
  marginRight: "12px",
  border: "1px solid rgba(1,1,1,0.12)",
  ".data-item": {
    display: "flex",
    alignItems: "center",
    background: "#DCE0E6",
    color: "rgba(0,0,0,0.85)",
  },
  ".data-item-label": {
    width: "20%",
    padding: "24px",
    textAlign: "center",
    color: "rgba(0,0,0,0.85)",
    fontWeight: "bold",
  },
  ".data-item-value": {
    width: "80%",
    padding: "24px",
    borderLeft: "1px solid rgba(1,1,1,0.12)",
    background: "#FFF",
    wordBreak: "break-all",
  },
  ".data-item-bottom-border": {
    borderBottom: "1px solid rgba(1,1,1,0.12)",
  },
}));

export default function Standards() {
  return (
    <StandardsContainer>
      <DataContainer>
        <Box className="data-item data-item-bottom-border">
          <Box className="data-item-label">No.1</Box>
          <Box sx={{ background: "#D7D7D7 !important" }} className="data-item-value">
            Infusion Pumps Total Product Life Cycle --- Guidance for Industry and FDA Staff
          </Box>
        </Box>
        <Box className="data-item data-item-bottom-border">
          <Box className="data-item-label">Datetime</Box>
          <Box className="data-item-value">December 2, 2014</Box>
        </Box>
        <Box className="data-item">
          <Box className="data-item-label">Link</Box>
          <Box sx={{ color: "#169BD5" }} className="data-item-value">
            https://www.fda.gov/files/medical%20devices/published/Infusion-Pumps-Total-Product-Life-Cycle---Guidance-for-Industry-and-FDA-Staff.pdf
          </Box>
        </Box>
      </DataContainer>
      <Box className="w-1/2">
        <img alt="" src={standardsPng} width="100%" />
      </Box>
    </StandardsContainer>
  );
}
