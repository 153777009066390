import AppScrollbar from "@crema/core/AppScrollbar";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { Box, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import {
  ACProcessData,
  CyberProcessData,
  DCDProcessData,
  ProcessData,
  ProductionProcessData,
  RequirementProcessData,
  RiskProcessData,
  TechnicalFileProcessData,
} from "mock/ModuleData";
import { VersionList } from "mock/VersionList";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { handleLeftTreeOpen, handleLeftTreeSelected } from "redux/actions";
import { AppState } from "redux/store";
import { bgColor } from "shared/constants/AppConst";

import LeftModuleTree from "./components/LeftModuleTree";
import ToolBar from "./components/ToolBar";

const DeliverablesContainer = styled(Box)(({ theme }) => ({
  ".content-layout": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "calc(100vh - 104px)",
  },
  ".left-arrow": {
    position: "fixed",
    background: "#dfe1e6",
    width: "16px",
    height: "48px",
    left: 0,
    top: "50%",
    transform: "translateX(-50%), translateY(-50%)",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
  },
  ".left-arrow-down": {
    position: "absolute",
    background: "#dfe1e6",
    width: "16px",
    height: "48px",
    left: "calc(20%)",
    top: "50%",
    transform: "translateX(-50%), translateY(-50%)",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
  },
  ".right-container": {
    padding: theme.spacing(5),
    background: bgColor.main,
  },
}));

interface DeliverablesProps {}

const deepFind =
  (pred) =>
  ([x, ...xs] = []) =>
    x && (pred(x) ? x : deepFind(pred)(x.items) || deepFind(pred)(xs));

const findById = (id) => (dataSet) => deepFind((o) => o.id === id)(dataSet);

const Deliverables = (props: DeliverablesProps) => {
  const navigate = useNavigate();
  const routeParam = useParams();
  const location = useLocation();
  const [processData, setProcessData] = useState(RequirementProcessData[0]);
  const [initModule, setInitModule] = useState(RequirementProcessData[0]);
  const [moduleId, setModuleId] = useState(RequirementProcessData[0].id);
  const [docId, setDocId] = useState(processData?.docId || "DOC-SHA-001");
  const [taskId, setTaskId] = useState(processData?.taskId || "TK#001");
  const [taskType, setTaskType] = useState("");

  const [taskVersion, setTaskVersion] = useState(VersionList[0]?.id);
  const [deliverableMenuList, setDeliverableMenuList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState<ButtonMenuListItemModel>();
  const [init, setInit] = useState<boolean>(false);
  const [paramInit, setParamInit] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { leftTreeOpen, selectProcess } = useSelector<AppState, AppState["products"]>(({ products }) => products);

  React.useEffect(() => {
    routeParam?.module && setModuleId(routeParam?.module);
    routeParam?.type && setTaskType(routeParam?.type);
    routeParam?.version && setTaskVersion(routeParam?.version);
    if (routeParam?.module) {
      if (routeParam.module === "Requirement Management") {
        setInitModule(RequirementProcessData[0]);
        if (routeParam?.processId) {
          const item = findById(routeParam.processId)(RequirementProcessData);
          item && setProcessData(item);
          setInit(false);
        } else {
          setProcessData(RequirementProcessData[0]);
        }
      } else if (routeParam.module === "Risk Management") {
        setInitModule(RiskProcessData[0]);
        if (routeParam?.processId) {
          const item = findById(routeParam.processId)(RiskProcessData);
          item && setProcessData(item);
          setInit(false);
        } else {
          setProcessData(RiskProcessData[0]);
        }
      } else if (routeParam.module === "Cybersecurity Risk Management") {
        setInitModule(CyberProcessData[0]);
        if (routeParam?.processId) {
          const item = findById(routeParam.processId)(CyberProcessData);
          item && setProcessData(item);
          setInit(false);
        } else {
          setProcessData(CyberProcessData[0]);
        }
      } else if (routeParam.module === "Assurance Case Management") {
        setInitModule(ACProcessData[0]);
        if (routeParam?.processId) {
          const item = findById(routeParam.processId)(ACProcessData);
          item && setProcessData(item);
          setInit(false);
        } else {
          setProcessData(ACProcessData[0]);
        }
      } else if (routeParam.module === "Design Controls") {
        setInitModule(DCDProcessData[0]);
        if (routeParam?.processId) {
          const item = findById(routeParam.processId)(DCDProcessData);
          item && setProcessData(item);
          setInit(false);
        } else {
          setProcessData(DCDProcessData[0]);
        }
      } else if (routeParam.module === "Production Management") {
        setInitModule(ProductionProcessData[0]);
        if (routeParam?.processId) {
          const item = findById(routeParam.processId)(ProductionProcessData);
          item && setProcessData(item);
          setInit(false);
        } else {
          setProcessData(ProductionProcessData[0]);
        }
      } else if (routeParam.module === "Index File Management") {
        setInitModule(TechnicalFileProcessData[0]);
        if (routeParam?.processId) {
          const item = findById(routeParam.processId)(TechnicalFileProcessData);
          item && setProcessData(item);
          setInit(false);
        } else {
          setProcessData(TechnicalFileProcessData[0]);
        }
      }
    }
    setParamInit(true);
  }, [routeParam]);

  React.useEffect(() => {
    return () => {
      dispatch(handleLeftTreeOpen(true));
      dispatch(handleLeftTreeSelected(null));
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (paramInit) {
      if (taskVersion && taskType && moduleId && processData?.id) {
        const newMenuList =
          moduleId !== "Risk Management"
            ? [
                {
                  id: "5",
                  labelId: "common.worksheet",
                  icon: <AddLinkIcon />,
                  url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/worksheet`,
                },
                {
                  id: "1",
                  labelId: "common.scope",
                  icon: <AddLinkIcon />,
                  url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/scope`,
                },
                {
                  id: "2",
                  labelId: "common.collaboration",
                  icon: <AddLinkIcon />,
                  url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/collaboration`,
                },
                // {
                //   id: "3",
                //   labelId: "common.rules",
                //   icon: <AddLinkIcon />,
                //   url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/rules`,
                // },
                {
                  id: "4",
                  labelId: "common.input",
                  icon: <AddLinkIcon />,
                  url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/input`,
                },
                {
                  id: "6",
                  labelId: "common.impact.summary",
                  icon: <AddLinkIcon />,
                  url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/impactSummary`,
                },
                // {
                //   id: "7",
                //   labelId: "common.checklist",
                //   icon: <AddLinkIcon />,
                //   url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/checklist`,
                // },
                {
                  id: "8",
                  labelId: "common.report",
                  icon: <AddLinkIcon />,
                  url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/report`,
                },
                // {
                //   id: "9",
                //   labelId: "common.impact.summary",
                //   icon: <AddLinkIcon />,
                //   url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/impactSummary`,
                // },
              ]
            : [
                {
                  id: "5",
                  labelId: "common.worksheet",
                  icon: <AddLinkIcon />,
                  url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/worksheet`,
                },
                {
                  id: "1",
                  labelId: "common.scope",
                  icon: <AddLinkIcon />,
                  url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/scope`,
                },
                {
                  id: "2",
                  labelId: "common.collaboration",
                  icon: <AddLinkIcon />,
                  url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/collaboration`,
                },
                // {
                //   id: "3",
                //   labelId: "common.rules",
                //   icon: <AddLinkIcon />,
                //   url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/rules`,
                // },
                {
                  id: "4",
                  labelId: "common.input",
                  icon: <AddLinkIcon />,
                  url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/input`,
                },
                {
                  id: "6",
                  labelId: "common.impact.summary",
                  icon: <AddLinkIcon />,
                  url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/impactSummary`,
                },
                {
                  id: "7",
                  labelId: "common.checklist",
                  icon: <AddLinkIcon />,
                  url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/checklist`,
                },
                {
                  id: "8",
                  labelId: "common.report",
                  icon: <AddLinkIcon />,
                  url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/report`,
                },
                // {
                //   id: "9",
                //   labelId: "common.impact.summary",
                //   icon: <AddLinkIcon />,
                //   url: `/products/123/deliverables/${moduleId}/${processData.id}/${taskType}/${taskVersion}/impactSummary`,
                // },
              ];
        if (newMenuList) {
          setDeliverableMenuList(newMenuList);
          if (init) {
            setSelectedMenu(newMenuList[0]);
            navigate(newMenuList[0].url);
          } else {
            const currentItem = newMenuList.filter((item) => item.url === location.pathname.replaceAll("%20", " "))[0];
            const newObject = currentItem ? { ...currentItem } : null;
            if (newObject) {
              delete newObject.url;
              setSelectedMenu(newObject);
            }
          }
        }
      } else {
        setDeliverableMenuList([]);
        setSelectedMenu(null);
        // moduleId && navigate(`/products/123/deliverables/${moduleId}/index`);
      }
    }
  }, [taskVersion, taskType, processData, moduleId, init, paramInit, location?.pathname]);

  React.useEffect(() => {
    if (selectProcess) {
      if (selectProcess.moduleId) {
        const module = ProcessData[selectProcess.moduleId];
        if (module) {
          const item = findById(selectProcess.processId)(module);
          if (item) {
            setModuleId(module[0].id);
            setProcessData(item);
            setDocId(selectProcess.docId);
            setTaskId(item?.taskId);
            setTaskType(item?.taskType);
            setInit(true);
          }
        }
      }
    }
  }, [selectProcess]);

  const handleVersionChange = (version) => {
    setTaskVersion(version);
    setInit(true);
  };

  const handleProcessData = (value: any) => {
    setProcessData(value);
    setDocId(value?.docId);
    setTaskId(value?.taskId);
    setTaskType(value?.taskType);
    setInit(true);
    if (value.isModule) {
      setModuleId(value.id);
      navigate(`/products/123/deliverables/${value.id}/index`);
    }
  };

  const toolbar = useMemo(() => {
    if (!deliverableMenuList || deliverableMenuList.length === 0) {
      return <></>;
    }
    if (!taskVersion) {
      return <></>;
    }
    return (
      <ToolBar
        versionId={taskVersion}
        versionLists={VersionList}
        menuList={deliverableMenuList}
        docId={docId}
        taskId={taskId}
        taskType={taskType}
        selectedValue={selectedMenu}
        setSelectedValue={setSelectedMenu}
        processData={processData}
        onVersionChange={handleVersionChange}
      />
    );
  }, [taskVersion, deliverableMenuList, docId, taskId, taskType, processData, selectedMenu]);

  return (
    <DeliverablesContainer>
      <Box className="content-layout">
        <Box className={leftTreeOpen ? "left-arrow-down" : "left-arrow"}>
          <AppSvgIcon
            onClick={() => {
              dispatch(handleLeftTreeOpen(!leftTreeOpen));
            }}
            color="action"
            size={16}
          >
            {leftTreeOpen ? "material-solid:keyboard_arrow_left" : "material-solid:keyboard_arrow_right"}
          </AppSvgIcon>
        </Box>
        <Drawer
          sx={{
            width: leftTreeOpen ? "20%" : "0%",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "20%",
              height: "calc(100vh - 104px)",
              overflowY: "hidden",
              marginTop: "104px",
              backgroundColor: (theme) => theme.palette.background.paper,
            },
          }}
          variant="persistent"
          anchor="left"
          open={leftTreeOpen}
        >
          <AppScrollbar
            sx={{
              height: "calc(100vh - 104px)",
            }}
          >
            <LeftModuleTree handleSelectProcess={handleProcessData} initModule={initModule} processData={processData} />
          </AppScrollbar>
        </Drawer>
        <Box sx={{ width: leftTreeOpen ? "80%" : "100%" }} className="right-container">
          <>
            {!processData?.isModule && toolbar}
            <AppScrollbar
              sx={{
                maxHeight: processData?.isModule ? "100%" : "calc(100% - 40px)",
              }}
            >
              <Outlet />
            </AppScrollbar>
          </>
        </Box>
      </Box>
    </DeliverablesContainer>
  );
};

export default Deliverables;
