import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomizeIcon from "components/Icons/CustomizeIcon";
import ContentHeader from "pages/admin/components/ContentHeader";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { boxShadow } from "shared/constants/AppConst";

const resourcesMockData = {
  "System Hazard Analysis": {
    jamaCheckbox: true,
    polarionCheckbox: true,
    doorsNextCheckbox: true,
    standardData: {
      no: "Infusion Pumps Total Product Life Cycle --- Guidance for Industry and FDA Staff",
      datetime: "December 2, 2014",
      link: "https://www.fda.gov/files/medical%20devices/published/Infusion-Pumps-Total-Product-Life-Cycle---Guidance-for-Industry-and-FDA-Staff.pdf",
    },
  },
  "User Needs": {
    jamaCheckbox: true,
    polarionCheckbox: true,
    doorsNextCheckbox: true,
    standardData: {
      no: "Infusion Pumps Total Product Life Cycle --- Guidance for Industry and FDA Staff",
      datetime: "December 2, 2014",
      link: "https://www.fda.gov/files/medical%20devices/published/Infusion-Pumps-Total-Product-Life-Cycle---Guidance-for-Industry-and-FDA-Staff.pdf",
    },
  },
  "Design Input Requirements": {
    jamaCheckbox: true,
    polarionCheckbox: true,
    doorsNextCheckbox: true,
    standardData: {
      no: "Infusion Pumps Total Product Life Cycle --- Guidance for Industry and FDA Staff",
      datetime: "December 2, 2014",
      link: "https://www.fda.gov/files/medical%20devices/published/Infusion-Pumps-Total-Product-Life-Cycle---Guidance-for-Industry-and-FDA-Staff.pdf",
    },
  },
  "Test Cases": {
    jamaCheckbox: true,
    polarionCheckbox: true,
    doorsNextCheckbox: true,
    standardData: {
      no: "Infusion Pumps Total Product Life Cycle --- Guidance for Industry and FDA Staff",
      datetime: "December 2, 2014",
      link: "https://www.fda.gov/files/medical%20devices/published/Infusion-Pumps-Total-Product-Life-Cycle---Guidance-for-Industry-and-FDA-Staff.pdf",
    },
  },
  "Design Control Traceability Matrix": {
    jamaCheckbox: true,
    polarionCheckbox: true,
    doorsNextCheckbox: true,
    standardData: {
      no: "Infusion Pumps Total Product Life Cycle --- Guidance for Industry and FDA Staff",
      datetime: "December 2, 2014",
      link: "https://www.fda.gov/files/medical%20devices/published/Infusion-Pumps-Total-Product-Life-Cycle---Guidance-for-Industry-and-FDA-Staff.pdf",
    },
  },
};

const DataContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  border: "1px solid rgba(1,1,1,0.12)",
  ".data-item": {
    display: "flex",
    alignItems: "center",
    background: "#DCE0E6",
    color: "rgba(0,0,0,0.85)",
  },
  ".data-item-label": {
    width: "20%",
    padding: "24px",
    textAlign: "center",
    color: "rgba(0,0,0,0.85)",
    fontWeight: "bold",
  },
  ".data-item-value": {
    width: "80%",
    padding: "24px",
    borderLeft: "1px solid rgba(1,1,1,0.12)",
    background: "#FFF",
    wordBreak: "break-all",
  },
  ".data-item-bottom-border": {
    borderBottom: "1px solid rgba(1,1,1,0.12)",
  },
}));

const Resources = () => {
  const params = useParams();
  const { pid } = params;

  const { messages } = useIntl();
  const [jamaCheckbox, setJamaCheckbox] = useState<boolean>(true);
  const [polarionCheckbox, setPolarionCheckbox] = useState<boolean>(false);
  const [doorsNextCheckbox, setDoorsNextCheckbox] = useState<boolean>(false);

  const [standardData, setStandardData] = useState({
    no: "Infusion Pumps Total Product Life Cycle --- Guidance for Industry and FDA Staff",
    datetime: "December 2, 2014",
    link: "https://www.fda.gov/files/medical%20devices/published/Infusion-Pumps-Total-Product-Life-Cycle---Guidance-for-Industry-and-FDA-Staff.pdf",
  });

  useEffect(() => {
    if (pid) {
      const mockData = resourcesMockData[pid];
      if (mockData) {
        setJamaCheckbox(mockData.jamaCheckbox);
        setPolarionCheckbox(mockData.polarionCheckbox);
        setDoorsNextCheckbox(mockData.doorsNextCheckbox);
        setStandardData(mockData.standardData);
      }
    }
  }, [pid]);

  return (
    <Box>
      <ContentHeader titleId="common.resources" />
      <Box
        sx={{
          background: (theme) => theme.palette.background.paper,
          width: "calc(100% - 40px)",
          height: "calc(100vh - 144px)",
          margin: (theme) => theme.spacing(5),
          boxShadow: boxShadow.cardBoxShadow,
          padding: "20px",
        }}
      >
        <Box
          sx={{
            pb: 14,
            borderBottom: "1px solid rgba(1,1,1,0.12)",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box sx={{ color: "rgba(0,0,0,0.85)", fontWeight: "bold" }}>
              <IntlMessages id="common.select.external.interfaces" />
            </Box>
            <Box>
              <FormGroup sx={{ mt: 3 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={jamaCheckbox}
                      onChange={() => {
                        setJamaCheckbox(!jamaCheckbox);
                      }}
                    />
                  }
                  label={String(messages["common.jama"])}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={polarionCheckbox}
                      onChange={() => {
                        setPolarionCheckbox(!polarionCheckbox);
                      }}
                    />
                  }
                  label={String(messages["common.polarion"])}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={doorsNextCheckbox}
                      onChange={() => {
                        setDoorsNextCheckbox(!doorsNextCheckbox);
                      }}
                    />
                  }
                  label={String(messages["common.doors.next"])}
                />
              </FormGroup>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 6,
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
                color: "rgba(0,0,0,0.85)",
                fontSize: "16px",
              }}
            >
              <IntlMessages id="common.standards" />
            </Box>
            <Button
              sx={{
                color: "rgba(0,0,0,0.85)",
              }}
              variant="text"
              onClick={() => {}}
              startIcon={<CustomizeIcon />}
            >
              <IntlMessages id="common.customize" />
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <DataContainer>
              <Box className="data-item data-item-bottom-border">
                <Box className="data-item-label">No.1</Box>
                <Box sx={{ background: "#D7D7D7 !important" }} className="data-item-value">
                  {standardData && standardData.no}
                </Box>
              </Box>
              <Box className="data-item data-item-bottom-border">
                <Box className="data-item-label">Datetime</Box>
                <Box className="data-item-value">{standardData && standardData.datetime}</Box>
              </Box>
              <Box className="data-item">
                <Box className="data-item-label">Link</Box>
                <Box sx={{ color: "#169BD5" }} className="data-item-value">
                  {standardData && standardData.link}
                </Box>
              </Box>
            </DataContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Resources;
