import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Button, Divider, List, ListItem, ListItemText, Menu, MenuItem, Popover, Typography } from "@mui/material";
import clsx from "clsx";
import AppSvgIcon from "components/AppSvgIcon";
import React, { useMemo } from "react";

interface ColumnProps {
  changeColumnSetting?: (settings) => void;
  sxStyle?: any;
}

class ColumnSetting {
  name: String;
  label: String
  displayOrder?: Number;
  isHidden?: Boolean;
  isFreezed?: Boolean;
}

const ColumnAction: React.FC<ColumnProps> = (props) => {
  const { changeColumnSetting, sxStyle } = props;

  const defaultColumns: ColumnSetting[] = [
    { name: "rowid", label: "RowId", isFreezed: true },
    { name: "hazardCategroy", label: "Hazard Category", isFreezed: true },
    { name: "hazard", label: "Hazard", isHidden: true },
    { name: "harms", label: "Harms" },
    { name: "hazardSituation", label: "Hazard Situation" },
    { name: "sequenceofevents", label: "Sequence of Events(SoE)" },
    { name: "analysisoffailuremodes", label: "Analysis of Failure Modes" },
    { name: "severity", label: "Severity" },
    { name: "p1", label: "P1 SOE(U)" },
    { name: "p1u", label: "P1(U)" },
    { name: "p2u", label: "P2(U)" },
    { name: "poh", label: "POH(U)" },
    { name: "risk", label: "Risk(U)" },
    { name: "riskcontrols", label: "Risk Controls" },
    { name: "riskcontrolintroducedrisks", label: "Risk Controls Introduced Risk" },
    { name: "p1seom", label: "P1 SOE(M)" },
    { name: "p1m", label: "P1(M)" },
    { name: "p2m", label: "P2(M)" },
    { name: "pohm", label: "POH(M)" },
    { name: "riskm", label: "Risk Region(M)" },
    { name: "vnv", label: "V & V" },
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [columnList, setColumnList] = React.useState<ColumnSetting[]>(defaultColumns);

  const openList = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const list = useMemo(() => {
    if (!columnList) {
      return <></>;
    }
    const listDom = [];

    const addListem = (x: ColumnSetting) => {
      return <React.Fragment key={`column-${x.name}`}>
        <ListItem alignItems="flex-start" className="column-list-item flex-wrap flex flex-row items-center">
          <AppSvgIcon className={clsx({ "cursor-grab": x.name !== "rowid" })} size={16}>{x.name === "rowid" ? "material-outline:lock" : "material-outline:drag_indicator"}</AppSvgIcon>
          <ListItemText className={clsx("mx-xs", "truncate", { disabled: x.isHidden })}>{x.label}</ListItemText>
          <AppSvgIcon className={clsx("column-visible", { "hidden": x.name === "rowid" })} size={16}>{x.isHidden ? "material-outline:visibility_off" : "material-outline:visibility"}</AppSvgIcon>
        </ListItem>
      </React.Fragment>
    };

    columnList.filter(d => d.isFreezed === true).forEach((x, index) => {
      listDom.push(addListem(x));
    });
    listDom.push(<Divider light />);
    columnList.filter(d => !d.isFreezed).forEach((x, index) => {
      listDom.push(addListem(x));
    });
    return listDom;
  }, [columnList]);

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        startIcon={<AppSvgIcon size={16}>material-outline:view_column</AppSvgIcon>}
        endIcon={openList ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        {...sxStyle}
      >
        Columns
      </Button>
      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{
          width: "250px",
          ".column-list-item": {
            padding: "0 16px",
            height: "36px",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.background.default
            },
            ".disabled": {
              color: "gray.500"
            },
            ".column-visible": {
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#FAFAFA"
              }
            },
            ".MuiListItemText-root": {
              maxWidth: "178px"
            }
          }
        }}>
          <List className="column-list">{list}</List>

        </Box>
      </Popover>
    </>
  );
};

export default ColumnAction;
