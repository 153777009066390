import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { borderColor, tabStyle } from "shared/constants/AppConst";

const Input = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs
          value={index}
          onChange={handleChangeTab}
          sx={{ ...tabStyle, borderBottom: `1px solid ${borderColor.dividerColor}` }}
        >
          <Tab
            onClick={() => {
              navigate("required");
            }}
            label={<IntlMessages id="process.input.required" />}
          />
          <Tab
            onClick={() => {
              navigate("internalReference");
            }}
            label={<IntlMessages id="process.input.internalReference" />}
          />
          {/* <Tab onClick={() => { navigate("externalReference") }} label={<IntlMessages id="process.input.externalReference" />} /> */}
        </Tabs>
      </Box>
      <Box sx={{ pt: 3 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Input;
