import AppScrollbar from "@crema/core/AppScrollbar";
import { Box, IconButton, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import ButtonMenu from "components/ButtonMenu";
import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import ModuleIcon from "components/Icons/ModuleIcon";
import MyTree from "components/MyTree";
import { ModuleMenuList, ProcessData } from "mock/ModuleData";
import React from "react";
import { useIntl } from "react-intl";
import { borderColor, textColor, treeStyle } from "shared/constants/AppConst";

const SearchTreeContainer = styled(Box)(({ theme }) => ({}));

interface LeftModuleTreeProps {
  handleSelectProcess: (value: any) => void;
  initModule: any;
  processData: any;
}

const deepFind =
  (pred) =>
  ([x, ...xs] = []) =>
    x && (pred(x) ? x : deepFind(pred)(x.items) || deepFind(pred)(xs));

const findById = (id) => (dataSet) => deepFind((o) => o.id === id)(dataSet);

const LeftModuleTree: React.FC<LeftModuleTreeProps> = ({ handleSelectProcess, initModule, processData }) => {
  const [selectedModule, setSelectedModule] = React.useState<ButtonMenuListItemModel>(ModuleMenuList[0]);
  const [processDataSet, setProcessDataSet] = React.useState(ProcessData[selectedModule.id]);
  const [searchText, setSearchText] = React.useState<string>("");
  const { messages } = useIntl();

  React.useEffect(() => {
    if (initModule.id === "Design Controls") {
      setSelectedModule(ModuleMenuList[0]);
      const processDataSet = ProcessData[ModuleMenuList[0].id];
      setProcessDataSet(processDataSet);
      // } else if (initModule.id === "Requirement Management") {
      //   setSelectedModule(ModuleMenuList[1]);
      //   const processDataSet = ProcessData[ModuleMenuList[1].id];
      //   setProcessDataSet(processDataSet);
    } else if (initModule.id === "Risk Management") {
      setSelectedModule(ModuleMenuList[1]);
      const processDataSet = ProcessData[ModuleMenuList[1].id];
      setProcessDataSet(processDataSet);
    } else if (initModule.id === "Cybersecurity Risk Management") {
      setSelectedModule(ModuleMenuList[2]);
      const processDataSet = ProcessData[ModuleMenuList[2].id];
      setProcessDataSet(processDataSet);
    } else if (initModule.id === "Assurance Case Management") {
      setSelectedModule(ModuleMenuList[3]);
      const processDataSet = ProcessData[ModuleMenuList[3].id];
      setProcessDataSet(processDataSet);
    } else if (initModule.id === "Production Management") {
      setSelectedModule(ModuleMenuList[4]);
      const processDataSet = ProcessData[ModuleMenuList[4].id];
      setProcessDataSet(processDataSet);
    } else if (initModule.id === "Index File Management") {
      setSelectedModule(ModuleMenuList[5]);
      const processDataSet = ProcessData[ModuleMenuList[5].id];
      setProcessDataSet(processDataSet);
    }
  }, [initModule]);

  const handleSelectedModule = (item: ButtonMenuListItemModel) => {
    setSelectedModule(item);
    const processDataSet = ProcessData[item.id];
    setProcessDataSet(processDataSet);
    const focusId = processDataSet[0].id;

    const process = findById(focusId)(processDataSet);
    handleSelectProcess(process);
  };

  const requestSearch = (value: string) => {
    setSearchText(value);
  };

  const onNodeSelect = (event: object, value: string) => {
    const selectedProcess = findById(value)(processDataSet);
    if (selectedProcess.isModule || !selectedProcess.isFolder) {
      handleSelectProcess(selectedProcess);
    }
  };

  return (
    <SearchTreeContainer>
      <Box
        sx={{
          my: (theme) => theme.spacing(3),
          mx: (theme) => theme.spacing(5),
        }}
      >
        <ButtonMenu
          selectedValue={selectedModule}
          setSelectedValue={handleSelectedModule}
          list={ModuleMenuList}
          moduleIcon={<ModuleIcon />}
          fullWidth={true}
          sxStyle={{
            variant: "outlined",
            sx: {
              color: textColor[85],
              border: `solid 1px ${borderColor.dividerColor}`,
              display: "flex",
              height: "32px",
              fontSize: "15px",
              justifyContent: "flex-start",
              "& .MuiButton-endIcon": {
                position: "absolute",
                right: "0px",
              },
            },
          }}
        />
      </Box>
      <Box
        sx={{
          pb: (theme) => theme.spacing(2),
          mx: (theme) => theme.spacing(5),
        }}
      >
        <TextField
          variant="standard"
          value={searchText}
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value)}
          placeholder={String(messages["common.search"])}
          InputProps={{
            startAdornment: (
              <AppSvgIcon size={16} color="action">
                {"material-outline:search"}
              </AppSvgIcon>
            ),
            endAdornment: (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: searchText ? "visible" : "hidden" }}
                  onClick={() => {
                    requestSearch("");
                  }}
                >
                  <AppSvgIcon size={16} color="action">
                    {"material-outline:clear"}
                  </AppSvgIcon>
                </IconButton>
              </Box>
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: 1,
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            "& .MuiSvgIcon-root": {
              mr: 0.5,
            },
            "& .MuiInputBase-root": {
              // padding: "0px 14px",
              height: "32px",
            },
            "& .MuiOutlinedInput-input": {
              padding: "0px",
            },
            "& .MuiInput-underline:before": {
              borderBottom: 1,
              borderColor: "divider",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          ml: (theme) => theme.spacing(5),
        }}
      >
        <AppScrollbar
          sx={{
            height: "calc(100vh - 210px)",
          }}
        >
          <MyTree
            selected={processData?.id}
            onNodeSelect={onNodeSelect}
            data={processDataSet}
            useStyledTree={true}
            showMoreIcon={false}
            // showNodeStatus={true}
            showFolder={true}
            sx={{
              ...treeStyle,
              padding: 0,
            }}
          />
        </AppScrollbar>
      </Box>
    </SearchTreeContainer>
  );
};

export default LeftModuleTree;
