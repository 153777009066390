import Icon from "@mui/material/Icon";
import * as React from "react";

const AddBoxSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <rect stroke="#DBDBDB" fill="#FFF" x="2.5" y="2.5" width="11" height="11" rx="2" />
      <path fillOpacity=".85" fill="#000" d="M5 7.5h6v1H5z" />
      <path fillOpacity=".85" fill="#000" d="M8.5 5v6h-1V5z" />
    </g>
  </svg>
);

const AddBoxIcon: React.FunctionComponent = () => {
  return <Icon component={AddBoxSvg} />;
};

export default AddBoxIcon;
