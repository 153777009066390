import Icon from "@mui/material/Icon";
import * as React from "react";

const ClosedTasksSvg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="ii9fy8d7la">
        <stop stopColor="#EB6050" offset="0%" />
        <stop stopColor="#FF8F76" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect fill="#E63825" opacity=".1" width="40" height="40" rx="4" />
      <path d="M5 5h30v30H5z" />
      <path d="M5 5h30v30H5z" />
      <path
        d="M7.571 5.25A2.299 2.299 0 0 0 5.25 7.571c0 1.3 1.021 2.322 2.321 2.322 1.3 0 2.322-1.022 2.322-2.322S8.87 5.25 7.57 5.25zm0 7.429A2.299 2.299 0 0 0 5.25 15c0 1.3 1.021 2.321 2.321 2.321 1.3 0 2.322-1.021 2.322-2.321 0-1.3-1.022-2.321-2.322-2.321zm0 7.428A2.299 2.299 0 0 0 5.25 22.43c0 1.3 1.021 2.321 2.321 2.321 1.3 0 2.322-1.021 2.322-2.321 0-1.3-1.022-2.322-2.322-2.322zM24.286 6.18H13.143c-.279 0-.464.185-.464.464V8.5c0 .279.185.464.464.464h11.143c.278 0 .464-.185.464-.464V6.643c0-.279-.186-.464-.464-.464zm0 7.428H13.143c-.279 0-.464.186-.464.464v1.858c0 .278.185.464.464.464h11.143c.278 0 .464-.186.464-.464V14.07c0-.278-.186-.464-.464-.464zm-7.638 10.215h-3.505c-.279 0-.464-.186-.464-.465V21.5c0-.279.185-.464.464-.464h2.822c-.061.957.167 1.929.683 2.786zm6.96-5.242c.38.38.38.998 0 1.379l-1.38 1.377 1.38 1.38a.975.975 0 1 1-1.38 1.38l-1.378-1.381-1.379 1.38a.975.975 0 1 1-1.379-1.379l1.38-1.38-1.38-1.377a.975.975 0 1 1 1.38-1.38l1.378 1.378 1.379-1.377a.975.975 0 0 1 1.379 0z"
        fill="url(#ii9fy8d7la)"
        transform="translate(5 5)"
      />
    </g>
  </svg>
);

const ClosedTasksIcon: React.FunctionComponent = () => {
  return <Icon component={ClosedTasksSvg} />;
};

export default ClosedTasksIcon;
