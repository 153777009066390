import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import FolderIcon from "components/Icons/FolderIcon";
import TaskItemIcon from "components/Icons/TaskItemIcon";
import MySelectField from "components/MySelectField";
import MyTextField from "components/MyTextField";
import MyTree from "components/MyTree";
import SimplePopup from "components/SimplePopup";
import { Form, Formik } from "formik";
import {
  ACProcessData,
  CyberProcessData,
  DCDProcessData,
  ProductionProcessData,
  RequirementProcessData,
  RiskProcessData,
  TechnicalFileProcessData,
} from "mock/ModuleData";
import { VersionList } from "mock/VersionList";
import * as React from "react";
import { useIntl } from "react-intl";
import { borderColor, textColor, treeStyle } from "shared/constants/AppConst";
import * as yup from "yup";

interface NewProductProps {
  handleClose: () => void;
  open: boolean;
}

const initDataset: any[] = [
  {
    value: "Pre-Production",
    id: "Pre-Production",
    opened: true,
    isBold: true,
    labelIcon: FolderIcon,
    disabledClick: true,
    items: [
      {
        value: "1. Concept & Requirements",
        id: "1. Concept & Requirements",
        opened: true,
        labelIcon: FolderIcon,
      },
      {
        value: "2. Design & Development",
        id: "2. Design & Development",
        labelIcon: FolderIcon,
      },
      {
        value: "3. Verification & Validation",
        id: "3. Verification & Validation",
        labelIcon: FolderIcon,
      },
      {
        value: "4. Regulatory Approval",
        id: "4. Regulatory Approval",
        labelIcon: FolderIcon,
      },
    ],
  },
  {
    value: "Production",
    id: "Production",
    isBold: true,
    labelIcon: FolderIcon,
  },
  {
    value: "Post-Production",
    id: "Post-Production",
    isBold: true,
    labelIcon: FolderIcon,
  },
];
const firstDataset: any[] = [
  {
    value: "Pre-Production",
    id: "Pre-Production",
    opened: true,
    isBold: true,
    labelIcon: FolderIcon,
    disabledClick: true,
    items: [
      {
        value: "1. Concept & Requirements",
        id: "1. Concept & Requirements",
        opened: true,
        labelIcon: FolderIcon,
      },
      {
        value: "2. Design & Development",
        id: "2. Design & Development",
        labelIcon: FolderIcon,
      },
      {
        value: "3. Verification & Validation",
        id: "3. Verification & Validation",
        labelIcon: FolderIcon,
      },
      {
        value: "4. Regulatory Approval",
        id: "4. Regulatory Approval",
        labelIcon: FolderIcon,
      },
    ],
  },
  {
    value: "Production",
    id: "Production",
    isBold: true,
    labelIcon: FolderIcon,
  },
  {
    value: "Post-Production",
    id: "Post-Production",
    isBold: true,
    labelIcon: FolderIcon,
  },
];

const processdata: any[] = [
  ...RequirementProcessData,
  ...RiskProcessData,
  ...CyberProcessData,
  ...ACProcessData,
  ...DCDProcessData,
  ...ProductionProcessData,
  ...TechnicalFileProcessData,
];

const deepFind =
  (pred) =>
  ([x, ...xs] = []) =>
    x && (pred(x) ? x : deepFind(pred)(x.items) || deepFind(pred)(xs));
const findById = (id) => (dataSet) => deepFind((o) => o.id === id)(dataSet);

export default function NewProduct(props: NewProductProps) {
  const { open, handleClose } = props;
  const { messages } = useIntl();
  const [templateList] = React.useState([
    {
      id: "template001",
      label: "System Default V1.0",
    },
    {
      id: "template002",
      label: "Template 1 V1.0",
    },
    {
      id: "template003",
      label: "Template V1.0",
    },
    {
      id: "template004",
      label: "Template V1.0",
    },
  ]);
  const [lifecycleList] = React.useState([
    {
      id: 1,
      name: "General Product Life Cycle",
    },
    {
      id: 2,
      name: "Process Product Life Cycle",
    },
  ]);

  const [step, setStep] = React.useState(1);
  const [batchTasks, setBatchTasks] = React.useState<string>("0");
  const [selected, setSelected] = React.useState<any>("1. Concept & Requirements");
  const [rightSelected, setRightSelected] = React.useState<any>([]);
  const [dataset, setDataSet] = React.useState(firstDataset);
  const [relationList, setRelationList] = React.useState([]);
  const [baseInfo, setBaseInfo] = React.useState({
    id: "",
    name: "",
    code: "",
    owner: "",
    description: "",
    template: "template001",
    // templateVersion: VersionList[0]?.id,
    lifecycle: "",
  });

  const validationSchema = yup.object({
    // id: yup.string().max(5, "5435").required(String(messages["validation.emailRequired"])),
    name: yup.string().max(5, "5435").required(String(messages["validation.nameRequired"])),
    code: yup.string().max(5, "5435").required(String(messages["validation.codeRequired"])),
    template: yup.string().required(String(messages["validation.templateRequired"])),
    // templateVersion: yup.string().required(String(messages["validation.templateVersionRequired"])),
    lifecycle: yup.string().required(String(messages["validation.lifecycleRequired"])),
  });

  const next = () => {
    console.log("next");
    const submitDom = document.getElementById("submit");
    submitDom && submitDom.click();
  };

  const save = () => {
    console.log("save");
  };

  const back = () => {
    setStep(1);
  };

  const close = () => {
    setBaseInfo({
      id: "",
      name: "",
      code: "",
      owner: "",
      description: "",
      template: "template001",
      // templateVersion: VersionList[0]?.id,
      lifecycle: "",
    });
    setStep(1);
    setBatchTasks("0");
    setRightSelected([]);
    setSelected("1. Concept & Requirements");
    setRelationList([]);
    setDataSet(initDataset);
    handleClose && handleClose();
  };

  const onNodeSelect = (event: object, value: string) => {
    const stage = findById(value)(initDataset);
    if (!stage?.disabledClick) {
      setSelected(value);
      setRightSelected([]);
    }
  };

  const onNodeRightSelect = (event: object, value: any) => {
    console.log(value);
    const newRightSelected = [...rightSelected];
    const index = newRightSelected.findIndex((item) => item === value);
    if (index > -1) {
      newRightSelected.splice(index, 1);
    } else {
      newRightSelected.push(value);
    }
    setRightSelected(newRightSelected);
  };

  const handleAddToStage = () => {
    let newRelationList = [...relationList];
    newRelationList = newRelationList.filter((item) => item.stageId !== selected);
    rightSelected.map((item) => {
      const process = findById(item)(processdata);
      if (!process.isBold) {
        newRelationList.push({ stageId: selected, processId: item });
      }
    });
    const newDataset = [...dataset];
    const stage = findById(selected)(newDataset);
    if (stage.items) {
      stage.items = stage.items.filter((item) => !item.disabled);
    } else {
      stage.items = [];
    }
    rightSelected.map((item) => {
      const process = findById(item)(processdata);
      if (!process.isBold) {
        stage.items.push({ id: item, value: process.value, disabled: true, labelIcon: TaskItemIcon });
      }
    });
    setDataSet([...newDataset]);
  };

  return (
    <SimplePopup
      handleClose={close}
      cancel={step === 1 ? close : back}
      cancelTextId={step === 1 ? "common.cancel" : "common.back"}
      okTextId={step === 1 && batchTasks === "1" ? "common.next" : "common.create"}
      open={open}
      titleId="products.create.product"
      save={step === 1 && batchTasks === "1" ? next : save}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "1000px",
          minWidth: "1000px",
          height: "800px",
        },
      }}
    >
      <>
        {step === 1 ? (
          <Box>
            <Formik
              initialValues={baseInfo}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting, resetForm }) => {
                console.log(data);
                // handleClose();
                // resetForm();
                setBaseInfo(data);
                setStep(2);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                      <MyTextField name="name" fullWidth label="workspace.form.name" required={true} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MyTextField name="code" fullWidth label="common.code" required={true} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MyTextField name="owner" fullWidth label="workspace.column.owner" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MyTextField name="description" fullWidth multiline rows="3" label="workspace.form.description" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MySelectField
                        label="common.template"
                        onChange={(e) => {
                          setFieldValue("template", e.target.value);
                        }}
                        name="template"
                        fullWidth
                        required
                        list={templateList}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                      <MySelectField
                        label="common.template.version"
                        onChange={(e) => {
                          setFieldValue("templateVersion", e.target.value);
                        }}
                        name="templateVersion"
                        fullWidth
                        required
                        list={VersionList}
                      />
                    </Grid> */}
                    <Grid item xs={12} md={12}>
                      <MySelectField
                        label="common.lifecycle"
                        onChange={(e) => {
                          setFieldValue("lifecycle", e.target.value);
                        }}
                        name="lifecycle"
                        fullWidth
                        required
                        list={lifecycleList}
                      />
                    </Grid>
                    <Grid sx={{ display: "none" }} item xs={12} md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          sx={{
                            position: "relative",
                            minWidth: 100,
                          }}
                          id="submit"
                          color="primary"
                          variant="contained"
                          disabled={isSubmitting}
                          type="submit"
                        >
                          <IntlMessages id="common.next" />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
            <Box
              sx={{
                "& .Mui-focused": {
                  color: textColor[85] + " !important",
                },
              }}
            >
              <FormControl className="flex font-bold items-center" sx={{ color: textColor[85], flexDirection: "row" }}>
                <Box>
                  <FormLabel
                    sx={{ color: textColor[85] }}
                    className="font-bold"
                    id="demo-row-radio-buttons-group-label"
                  >
                    <IntlMessages id="common.create.tasks.in.batch" />
                  </FormLabel>
                </Box>
                <Box sx={{ ml: 3 }}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue="0"
                    value={batchTasks}
                    onChange={(e) => {
                      setBatchTasks(e.target.value);
                    }}
                  >
                    <FormControlLabel value="0" control={<Radio />} label={<IntlMessages id="common.no" />} />
                    <FormControlLabel value="1" control={<Radio />} label={<IntlMessages id="common.yes" />} />
                  </RadioGroup>
                </Box>
              </FormControl>
            </Box>
          </Box>
        ) : (
          <Box>
            {batchTasks === "1" && (
              <Box
                sx={{
                  width: "100%",
                  height: "630px",
                  border: `1px solid ${borderColor.dividerColor}`,
                  display: "flex",
                }}
              >
                <AppScrollbar
                  sx={{
                    height: "628px",
                    borderRight: `1px solid ${borderColor.dividerColor}`,
                  }}
                >
                  <MyTree
                    selected={selected}
                    onNodeSelect={onNodeSelect}
                    data={dataset}
                    useStyledTree={true}
                    showFolder={true}
                    sx={{
                      height: "100%",
                      ...treeStyle,
                      padding: "0px 20px 0px 20px",
                    }}
                  />
                </AppScrollbar>
                <Box sx={{ width: "100%", position: "relative" }}>
                  <AppScrollbar
                    sx={{
                      height: "578px",
                    }}
                  >
                    <MyTree
                      // selected={rightSelected}
                      onNodeSelect={onNodeRightSelect}
                      showCheckbox={true}
                      checkedNodeIds={rightSelected}
                      data={processdata}
                      useStyledTree={true}
                      showFolder={true}
                      sx={{
                        height: "100%",
                        ...treeStyle,
                        padding: "0px 20px 0px 20px",
                      }}
                    />
                  </AppScrollbar>
                  <Button
                    sx={{ mt: 3, position: "absolute", right: "12px", bottom: "12px" }}
                    variant="contained"
                    color="primary"
                    onClick={handleAddToStage}
                  >
                    <IntlMessages id="common.add.to.stage" />
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </>
    </SimplePopup>
  );
}
