import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, IconButton, Menu, MenuItem, Tab, Tabs, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { bgColor, borderColor, boxShadow, tabStyle } from "shared/constants/AppConst";

import AllActivities from "./components/AllActivities";
import MyActivities from "./components/MyActivities";

const filters: any[] = [
  {
    id: "1",
    label: "Filter1",
    labelId: "products.activities.filter1",
  },
  {
    id: "2",
    label: "Filter2",
    labelId: "products.activities.filter2",
  },
  {
    id: "3",
    label: "Filter3",
    labelId: "products.activities.filter3",
  },
];

const Container = styled(Box)(({ theme }) => ({
  minWidth: "1000px",
  marginTop: "8px",
  ".tabs-container": {
    background: theme.palette.background.paper,
    boxShadow: boxShadow.cardBoxShadow,
  },
}));

const Activities = () => {
  const [index, setIndex] = useState("0");

  const { messages } = useIntl();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [selectedFilter, setSelectedFilter] = useState(filters[0]);

  const [searchText, setSearchText] = useState<string>();

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setIndex(newValue);
  };

  const handleRequestSearch = (value: string) => {
    setSearchText(value);
  };

  const handleButtonOpenClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleButtonOpenClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <AppScrollbar
        sx={{
          width: "100%",
          height: "100%",
          background: bgColor.main,
        }}
      >
        <TabContext value={index}>
          <Box className="tabs-container">
            <Box
              className="flex justify-between items-center"
              sx={{
                borderBottom: 1,
                borderColor: borderColor.dividerColor,
              }}
            >
              <Box className="flex justify-start items-center">
                <Tabs
                  value={index}
                  onChange={handleChangeTab}
                  sx={{
                    ...tabStyle,
                    "& .MuiButtonBase-root": {
                      minHeight: "48px",
                      textTransform: "none",
                    },
                  }}
                >
                  <Tab value="0" label={<IntlMessages id="product.activities.myActivities" />} />
                  <Tab value="1" label={<IntlMessages id="product.activities.allActivities" />} />
                </Tabs>
                <Box className="flex">
                  <Button
                    className="text-color-08"
                    sx={{
                      mr: 3,
                      borderRadius: "4px",
                      border: `solid 1px ${borderColor.dividerColor}`,
                    }}
                    variant="outlined"
                    endIcon={
                      Boolean(anchorEl) ? (
                        <AppSvgIcon color="action">{"material-outline:arrow_drop_up"}</AppSvgIcon>
                      ) : (
                        <AppSvgIcon color="action">{"material-outline:arrow_drop_down"}</AppSvgIcon>
                      )
                    }
                    onClick={handleButtonOpenClick}
                    id="report_cancel"
                  >
                    {selectedFilter.label}
                    {/* <IntlMessages id="common.filters" /> */}
                  </Button>
                  <Menu
                    id="filters-menus"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleButtonOpenClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        marginRight: 2,
                      },
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {filters.map((x) => (
                      <MenuItem
                        key={x.id}
                        onClick={() => {
                          setSelectedFilter(x);
                          handleButtonOpenClose();
                        }}
                      >
                        {x.label}
                        {/* <IntlMessages id="setting.impactSummary.ACauseOfB" /> */}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Box>
              <Box className="search-box">
                <TextField
                  variant="outlined"
                  value={searchText}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRequestSearch(event.target.value)}
                  placeholder={String(messages["common.search"])}
                  InputProps={{
                    startAdornment: (
                      <AppSvgIcon size={16} color="action">
                        {"material-outline:search"}
                      </AppSvgIcon>
                    ),
                    endAdornment: (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          title="Clear"
                          aria-label="Clear"
                          size="small"
                          style={{ visibility: searchText ? "visible" : "hidden" }}
                          onClick={() => {
                            handleRequestSearch("");
                          }}
                        >
                          <AppSvgIcon size={16} color="action">
                            {"material-outline:clear"}
                          </AppSvgIcon>
                        </IconButton>
                      </Box>
                    ),
                  }}
                  sx={{
                    width: {
                      xs: 1,
                      sm: "auto",
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    "& .MuiSvgIcon-root": {
                      mr: 0.5,
                    },
                    "& .MuiInputBase-root": {
                      padding: "0px 14px",
                      height: "32px",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "0px",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: 1,
                      borderColor: "divider",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                pt: (theme) => theme.spacing(3),
              }}
            >
              <TabPanel value="0">
                <MyActivities />
              </TabPanel>
              <TabPanel value="1">
                <AllActivities />
              </TabPanel>
            </Box>
          </Box>
        </TabContext>
      </AppScrollbar>
    </Container>
  );
};

export default Activities;
