import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { Box, InputAdornment, MenuItem, Select, styled } from "@mui/material";
import CustomTable from "components/CustomTable";
import React from "react";
import { boxShadow, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import { itemWorkflowDat as diagramDataSet } from "../../ProcessSettings/ItemWorkflow/components/dummyData";
import FlowDiagram from "../../ProcessSettings/ItemWorkflow/components/FlowDiagram";
import Transitions from "../components/Transitions";

const FeedContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(5),
  background: theme.palette.background.paper,
  boxShadow: boxShadow.cardBoxShadow,
}));

const DiagramContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  ".workflow-diagram-box": {
    width: "100%",
    minHeight: "300px",
    ".diagram-box": {
      width: "100%",
      height: "inherit",
      minHeight: "300px",
    },
  },
}));

const statusColumns: Column[] = [
  {
    id: "code",
    label: "common.code",
    minWidth: 50,
    align: "left",
  },
  {
    id: "name",
    label: "common.name",
    minWidth: 200,
    align: "left",
  },
  {
    id: "initial",
    label: "setting.product.column.initial",
    minWidth: 50,
    align: "left",
    format: (value, record) => {
      return value ? "Yes" : "";
    },
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    align: "left",
    format: (value, record) => {
      return (
        <Box>
          <EditIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
          <DeleteIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
          <NorthIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
          <SouthIcon sx={{ cursor: "pointer", fontSize: "16px" }} />
        </Box>
      );
    },
  },
];

const statusRows: any[] = [
  {
    code: "open",
    name: "Open",
    initial: true,
  },
  {
    code: "In_process",
    name: "In Process",
  },
  {
    code: "closed",
    name: "Closed",
  },
];

const transitionColumns = [
  {
    index: "currentStatus",
    name: "setting.process.item.workflow.edit.table.currentStatus",
  },
  {
    index: "action",
    name: "setting.process.item.workflow.edit.table.action",
  },
  {
    index: "newStatus",
    name: "setting.process.item.workflow.edit.table.newStatus",
  },
  {
    index: "notifications",
    name: "setting.process.item.workflow.edit.table.notifications",
  },
  {
    index: "transitionPermissions",
    name: "setting.process.item.workflow.edit.table.transitionPermissions",
  },
];

const transitionData = [
  {
    id: 1,
    currentStatus: "Item Created",
    children: [
      {
        id: 101,
        action: "Initialize",
        newStatus: "Open",
        notifications: "None",
        showAdd: false,
        showDelete: false,
        transitionPermissions: "",
      },
    ],
  },
  {
    id: 2,
    currentStatus: "Open",
    children: [
      {
        id: 201,
        action: "Handle",
        newStatus: "In Process",
        notifications: "None",
        showAdd: true,
        showDelete: false,
        transitionPermissions: "Everyone",
      },
    ],
  },
  {
    id: 3,
    currentStatus: "In Process",
    children: [
      {
        id: 301,
        action: "Finish",
        newStatus: "Closed",
        notifications: "None",
        showAdd: true,
        showDelete: false,
        transitionPermissions: "Everyone",
      },
      {
        id: 302,
        action: "Rework",
        newStatus: "Open",
        notifications: "None",
        showAdd: true,
        showDelete: true,
        transitionPermissions: "Everyone",
      },
    ],
  },
  {
    id: 4,
    currentStatus: "Closed",
    children: [
      {
        id: 401,
        action: "Rework",
        newStatus: "Open",
        notifications: "None",
        transitionPermissions: "Everyone",
        showAdd: true,
        showDelete: false,
      },
    ],
  },
];

const flowData = {
  nodeData: [
    {
      key: 1,
      text: "●",
      color: "#FFF",
      figure: "Ellipse",
      width: 40,
      height: 40,
      stroke: "#000",
    },
    { key: 2, text: "Open", color: "#FFF", width: 40, height: 40, stroke: "#63A103" },
    { key: 3, text: "In Process", color: "#FFF", stroke: "#016FA0" },
    { key: 4, text: "Closed", color: "#FFFF", stroke: "#F59A23" },
  ],
  linkData: [
    {
      key: "1_2",
      from: 1,
      to: 2,
      fromSpot: "Right",
      toSpot: "Left",
      text: "Initialize",
      textColor: "#63A103",
    },
    {
      key: "2_3",
      from: 2,
      to: 3,
      fromSpot: "Right",
      toSpot: "Left",
      text: "Handle",
      textColor: "#00AAAA",
    },
    {
      key: "3_2",
      from: 3,
      to: 2,
      fromSpot: "Left",
      toSpot: "Right",
      text: "Rework",
      textColor: "#F59A23",
    },
    {
      key: "3_4",
      from: 3,
      to: 4,
      fromSpot: "Right",
      toSpot: "Left",
      text: "Finish",
      textColor: "#000",
    },
    {
      key: "4_2",
      from: 4,
      to: 2,
      fromSpot: "Bottom",
      toSpot: "Bottom",
      text: "Rework",
      textColor: "#F59A23",
    },
  ],
};

const dataJson = {
  class: "GraphLinksModel",
  linkKeyProperty: "key",
  nodeDataArray: [
    {
      key: 1,
      text: "●",
      color: "#FFF",
      figure: "Ellipse",
      width: 40,
      height: 40,
      stroke: "#000",
      loc: "-56.5 67.7560791015625",
    },
    { key: 2, text: "Open", color: "#FFF", width: 40, height: 40, stroke: "#63A103", loc: "69.5 67.7560791015625" },
    { key: 3, text: "In Process", color: "#FFF", stroke: "#016FA0", loc: "259.03564453125 67.7560791015625" },
    { key: 4, text: "Closed", color: "#FFF", stroke: "#F59A23", loc: "466.18603515625 67.7560791015625" },
  ],
  linkDataArray: [
    { key: "1_2", from: 1, to: 2, fromSpot: "Right", toSpot: "Left", text: "Initialize", textColor: "#63A103" },
    { key: "2_3", from: 2, to: 3, fromSpot: "Right", toSpot: "Left", text: "Handle", textColor: "#00AAAA" },
    { key: "3_2", from: 3, to: 2, text: "Rework", textColor: "#F59A23" },
    { key: "3_4", from: 3, to: 4, fromSpot: "Right", toSpot: "Left", text: "Finish", textColor: "#000" },
    { key: "4_2", from: 4, to: 2, fromSpot: "Bottom", toSpot: "Bottom", text: "Rework", textColor: "#F59A23" },
  ],
};

const Feed = () => {
  const [workflowDiagram, setDiagramData] = React.useState(diagramDataSet);
  const [statusPickList, setStatusPickList] = React.useState("1");

  React.useEffect(() => {
    if (workflowDiagram.length > 0) {
    }
  }, [workflowDiagram]);

  return (
    <FeedContainer>
      <Box sx={{ mb: (theme) => theme.spacing(2) }}>
        <Select
          sx={{
            minWidth: 60,
            background: "#fff",
            "& .MuiInputAdornment-root": {
              color: textColor[85],
            },
            "& .MuiTypography-body1": {
              color: textColor[85],
            },
            "& .MuiInputBase-input": {
              padding: "6px 0px",
              color: textColor[85],
            },
          }}
          id="status-pick-list-select"
          value={statusPickList}
          onChange={(e) => {
            setStatusPickList(e.target.value);
          }}
          startAdornment={<InputAdornment position="start">Status Pick List:</InputAdornment>}
        >
          <MenuItem value="1">Common Status</MenuItem>
        </Select>
      </Box>
      <Box>
        <CustomTable
          columns={statusColumns}
          rows={statusRows}
          showPage={false}
          maxHeight="auto"
          addRow={() => {
            console.log("add row");
          }}
          addRowTextId="common.add"
          groupTextId="common.common.status"
        />
      </Box>
      <Box
        sx={{
          my: 6,
          width: "100%",
        }}
      >
        <DiagramContainer>
          <FlowDiagram {...flowData} dataJson={dataJson} className="workflow-diagram-box" />
        </DiagramContainer>
      </Box>
      <Box>
        <Transitions
          columnsData={transitionColumns}
          initData={transitionData}
          groupId="common.common.status.transitions"
        />
      </Box>
    </FeedContainer>
  );
};

export default Feed;
