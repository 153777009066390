import IntlMessages from "@crema/utility/IntlMessages";
import { Box, MenuItem, Select } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { useField } from "formik";

const MySelectField = (props: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <>
      <Box
        sx={{
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: "bold",
          fontStretch: "normal",
          fontStyle: "normal",
          letterSpacing: "0.15px",
          color: "rgba(0,0,0,0.85)",
          mb: 1,
        }}
      >
        <IntlMessages id={props.label} />
        <sup style={{ color: "#de350b" }}>{props.required ? "*" : ""}</sup>
      </Box>
      <Select {...field} {...props} label="" error={!!errorText}>
        {props.list.map((item) => {
          return (
            <MenuItem
              value={item.id}
              key={item.id}
              sx={{
                cursor: "pointer",
              }}
            >
              {item.name || item.label}
            </MenuItem>
          );
        })}
      </Select>
      {!props.disabled && (
        <FormHelperText style={{ color: "#D32F2F", margin: "3px 14px 0 14px" }}>{errorText}</FormHelperText>
      )}
    </>
  );
};

export default MySelectField;
