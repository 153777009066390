//https://www.jianshu.com/p/bdf51ae6142e

import "react-quill/dist/quill.snow.css";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Box } from "@mui/material";
import React, { useState } from "react";
import ReactQuill from "react-quill";

const CKEditorPage = () => {
  const [value, setValue] = useState("Quill Editor");
  const [data, setData] = useState("<p>Hello from CKEditor 5!</p>");
  return (
    <>
      <div className="App">
        <h2>Using CKEditor 5 build in React</h2>
        <CKEditor
          editor={ClassicEditor}
          data={data}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setData(data);
            console.log({ event, editor, data });
          }}
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}
        />
      </div>
      <h2 className="mt-16">Quill Editor</h2>
      <Box sx={{ width: "100%", height: "200px" }}>
        <ReactQuill theme="snow" value={value} onChange={setValue} />
      </Box>
    </>
  );
};

export default CKEditorPage;
