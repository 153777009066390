import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "components/CustomTable";
import React from "react";
import { bgColor } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

import ContentHeader from "../components/ContentHeader";

const UsersContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  ".table-container": {
    width: "100%",
    padding: "20px",
    background: "#fff",
    boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)",
  },
}));
const columns: Column[] = [
  {
    id: "userName",
    label: "common.userName",
    minWidth: 150,
    align: "left",
  },
  {
    id: "firstName",
    label: "common.first.name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "lastName",
    label: "common.last.name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "email",
    label: "common.email",
    minWidth: 200,
    align: "left",
  },
  {
    id: "groups",
    label: "common.groups",
    minWidth: 200,
    align: "left",
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    minWidth: 120,
    format: (value, record) => {
      // Show more
      return (
        <Box sx={{ color: "#2e6ccb" }} className="flex cursor-pointer">
          <Box>
            <IntlMessages id="dashboard.subscriptions" />
          </Box>
        </Box>
      );
    },
  },
];

const initRows = [
  {
    id: 2,
    userName: "aaron2022",
    firstName: "Aaron",
    lastName: "Evan",
    email: "aaron.evan@gessnet.com",
    groups: "Product Managers",
    // userType: "Normal User",
    // status: "Active",
    // lockoutStatus: "Unlocked",
    last_password_change: "16:34:56 02/19/2021",
  },
];

const Users = () => {
  const [rows, setRows] = React.useState<any[]>(initRows);

  return (
    <UsersContainer>
      <ContentHeader titleId="admin.nav.item.users" />
      <AppScrollbar
        sx={{
          height: "calc(100vh - 154px)",
          padding: (theme) => theme.spacing(5),
        }}
      >
        <Box className="table-container">
          <CustomTable
            toolBar={{
              buttons: [
                // {
                //   id: "common.add.user.to.product",
                //   icon: TableHeaderButtonsIconType.ADD,
                //   type: "text",
                //   color: "#2e6ccb",
                //   isBlue: true,
                //   onChange: () => {},
                // },
                // {
                //   id: "common.create.new.user",
                //   icon: TableHeaderButtonsIconType.ADD,
                //   type: "contained",
                //   color: "#fff",
                //   onChange: () => {},
                // },
              ],
            }}
            columns={columns}
            rows={rows}
            maxHeight="calc(100vh - 200px)"
            showPage={false}
          />
        </Box>
      </AppScrollbar>
    </UsersContainer>
  );
};

export default Users;
