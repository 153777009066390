import { Box, Link } from "@mui/material";
import FillProductIcon from "components/Icons/FillProductIcon";
import FillTaskIcon from "components/Icons/FillTaskIcon";
import { statusBgColor } from "shared/constants/AppConst";

export const getImpactAnalysisColumns = (navigate) => {
  return [
    {
      id: "id",
      label: "workspace.column.id",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex items-center">
            <Box sx={{ ml: 1.5, color: "#2E6CCB" }}>{value}</Box>
          </Box>
        );
      },
    },
    {
      id: "source",
      label: "common.source.of.impact",
      minWidth: 200,
      align: "left",
    },
    {
      id: "scope",
      label: "workspace.column.suspects",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        if (value) {
          return (
            <Box className="flex items-start">
              <Box sx={{ width: "16px", marginTop: "2px" }}>
                <FillTaskIcon />
              </Box>
              <Link
                sx={{ ml: 1.5, cursor: "pointer" }}
                underline="none"
                onClick={() => {
                  navigate(
                    `/products/123/deliverables/${record.module}/${record.processId}/${record.type}/${record.version}/worksheet`
                  );
                }}
              >
                {value}
              </Link>
            </Box>
          );
        }
        return;
      },
    },
    {
      id: "content",
      label: "common.description",
      minWidth: 220,
      align: "left",
    },
    {
      id: "time",
      label: "workspace.column.creation.time",
      minWidth: 200,
      align: "left",
    },
    {
      id: "status",
      label: "workspace.column.status",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex items-center">
            <Box
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                background: statusBgColor[value.toLowerCase()],
              }}
            />
            <Box sx={{ ml: 1.5 }}>{value}</Box>
          </Box>
        );
      },
    },
  ];
};

export const getOtherTypeColumns = (navigate) => {
  return [
    {
      id: "id",
      label: "workspace.column.id",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex items-center">
            <Box sx={{ ml: 1.5, color: "#2E6CCB" }}>{value}</Box>
          </Box>
        );
      },
    },
    {
      id: "content",
      label: "common.description",
      minWidth: 300,
      align: "left",
    },
    {
      id: "status",
      label: "workspace.column.status",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex items-center">
            <Box
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                background: statusBgColor[value.toLowerCase()],
              }}
            />
            <Box sx={{ ml: 1.5 }}>{value}</Box>
          </Box>
        );
      },
    },
    {
      id: "productID",
      label: "workspace.column.product.id",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        if (value) {
          return (
            <Box className="flex items-start">
              <Box sx={{ width: "16px", marginTop: "2px" }}>
                <FillProductIcon />
              </Box>
              <Link
                sx={{ ml: 1.5, cursor: "pointer" }}
                underline="none"
                onClick={() => {
                  navigate("/products/123/deliverables/Design Controls/index");
                }}
              >
                {value}
              </Link>
            </Box>
          );
        }
        return;
      },
    },
    {
      id: "tasks",
      label: "workspace.column.tasks",
      minWidth: 300,
      align: "left",
      format: (value, record) => {
        if (value) {
          return (
            <Box className="flex items-start">
              <Box sx={{ width: "16px", marginTop: "2px" }}>
                <FillTaskIcon />
              </Box>
              <Link
                sx={{ ml: 1.5, cursor: "pointer" }}
                underline="none"
                onClick={() => {
                  navigate("/products/123/deliverables/Risk Management/System Hazard Analysis/sha/100/worksheet");
                }}
              >
                {value}
              </Link>
            </Box>
          );
        }
        return;
      },
    },
    {
      id: "createdBy",
      label: "workspace.column.created.by",
      minWidth: 200,
      align: "left",
    },
    {
      id: "source",
      label: "common.from",
      minWidth: 200,
      align: "left",
    },
    {
      id: "creationTime",
      label: "workspace.column.creation.time",
      minWidth: 200,
      align: "left",
    },
  ];
};
