import "handsontable/dist/handsontable.full.css";

import { HotTable } from "@handsontable/react";
import { Box, Button, Grid, IconButton, Input, InputAdornment, Slider } from "@mui/material";
import clsx from "clsx";
import AppSvgIcon from "components/AppSvgIcon";
import ExportDropdown from "components/ExportDropdown";
import { registerAllModules } from "handsontable/registry";
import DrawerHelper from "libs/Drawer/DrawerHelper";
import React, { useEffect } from "react";

import Page2 from "../Page2";
import ZoomController from "./ExcelFooter/ZoomController";
import ColumnAction from "./ExcelToolbar/Actions/ColumnAction";
import GroupAction from "./ExcelToolbar/Actions/GroupAction";
import RowHeightAction from "./ExcelToolbar/Actions/RowHeightAction";
import QuickSearch from "./ExcelToolbar/QuickSearch";
import { createSpreadsheetData } from "./helpers.js";

const HandsontablePage = () => {
  const tableRef = React.useRef(null);

  const [hotSettings, setHotSettings] = React.useState<any>({
    // data: createSpreadsheetData(100, 26),
    data: createSpreadsheetData(42, 21),
    // autoColumnSize: { syncLimit: 5 },
    rowHeaders: true,
    dropdownMenu: false,
    collapsibleColumns: true,
    filters: false,
    // colWidths: 120,
    // readOnly: true,
    manualColumnResize: true,
    manualRowResize: true,
    colWidths: 120,
    // rowHeights: 32,
    mergeCells: [
      { row: 3, rowspan: 3, col: 0, colspan: 1 },
      { row: 3, rowspan: 3, col: 1, colspan: 1 },
      { row: 3, rowspan: 3, col: 2, colspan: 1 },
      { row: 3, rowspan: 3, col: 3, colspan: 1 },
    ],
    width: "100%",
    // height: "calc(100vh - 140px)",
    height: "100%",
    search: {
      searchResultClass: "bg-amber-50",
    },
    // stretchH: "'all'", // 'none' is default
    manualColumnMove: false,
    manualRowMove: true,
    fixedRowsTop: 1,
    fixedColumnsStart: 1,
    colHeaders: true,
    // colHeaders: ["Product name", "Price in Japan", "Price in Turkey"],
    // columns: [
    //   {
    //     editor: CustomEditor
    //   }
    // ],
    cells: (row) => {
      if (row === 0) {
        return {
          readOnly: true,
          className: "header-row",
        };
      }
    },
    contextMenu: {
      items: {
        row_above: {
          name: "Insert row above this one (custom name)",
        },
        row_below: {},
        clear_custom: {
          name: "Clear all cells (custom)",
          callback: function (a1, b1, c1) {
            console.log(a1, b1, c1);
            this.clear();
          },
        },
        details: {
          name: "Details",
          callback: (key, options) => {
            console.log(key, options);
          },
        },
      },
    },
    licenseKey: "non-commercial-and-evaluation",
  });
  const [zoom, setZoom] = React.useState<Number>(100);
  const [isFullScreen, setIsFullScreen] = React.useState(false);

  const doQuickSearch = (q) => {
    const table = tableRef.current;
    const search = table.getPlugin("search");
    // use the `Search` plugin's `query()` method
    const queryResult = search.query(q);

    console.log(queryResult);

    table.render();
  };

  registerAllModules();

  const beforeOnCellMouseDown = (mouseEvent, coords, td) => {
    if (mouseEvent.button === 2) {
      var cellCoords = { row: coords.row, col: coords.col };
      if (coords.row >= -1) {
        //if (coords.col >-1) {
        setMenuItemsVisible(cellCoords);
      }
    }
  };

  const setMenuItemsVisible = (cellCoords) => {
    console.log(cellCoords);
    if (cellCoords.row === -1) {
      setHotSettings({
        ...hotSettings,
        contextMenu: false,
      });
    } else {
      setHotSettings({
        ...hotSettings,
        contextMenu: {
          items: {
            row_above: {
              name: "Insert row above this one (custom name)",
            },
            row_below: {},
            clear_custom: {
              name: "Clear all cells (custom)",
              callback: function (a1, b1, c1) {
                console.log(a1, b1, c1);
                this.clear();
              },
            },
            details: {
              name: "Details",
              callback: (key, options) => {
                console.log(key, options);
              },
            },
          },
        },
      });
    }
  };

  // useMount(() => {
  //   GetWebEditApiJs().then((res) => {
  //     const scriptEle = document.createElement('script');
  //     scriptEle.src = res;
  //     scriptEle.onload = () => {
  //       setDocEditorState((draft) => ({ ...draft, isReady: true }));
  //     };
  //     document.body.appendChild(scriptEle);
  //   });
  // });

  useEffect(() => {
    // const table = tableRef.current;
    // table.addHook("afterScrollVertically", function () {
    //   var last = table.getPlugin("AutoRowSize").getLastVisibleRow();
    //   console.log(last);
    //   // check loaded page
    //   // trigger load new pages' data
    // });
    // 1. call api to fetch the excel table settings
    // 2. fetching the page data
    // 3. according to the total size create the empty rows
    // 4. when scroll to the page, and lazy loading these data
  });

  return (
    <Box
      id="worksheet-excel"
      className={clsx({ fullscreen: isFullScreen })}
      sx={{
        width: "100%",
        height: "100%",
        ".handsontable .htDimmed": {
          background: "#FFF",
          color: "rgba(0,0,0,0.85)",
        },
        "&.fullscreen": {
          position: "absolute",
          top: 0,
          zIndex: 1200,
          background: "#f2f4f7",
          ".body": {
            height: "calc(100vh - 84px)",
          },
        },
        ".header": {
          height: "52px",
          backgroundColor: "#FFF",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
          ".excel-toolbar": {
            padding: "0 8px",
            flex: 1,
            height: "26px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            ".toolbar-group": {
              marginRight: "8px",
              borderRight: (theme) => `solid 1px ${theme.palette.divider}`,
              "&:last-child": {
                borderRight: 0,
                maring: 0,
              },
              button: {
                color: (theme) => theme.palette.text.secondary,
                fontWeight: "500",
                "& .MuiButton-startIcon": {
                  marginRight: "6px",
                },
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.background.default,
                },
                marginRight: "8px",
              },
            },
          },
        },
        ".body": {
          height: "calc(100vh - 140px)",
          width: "100%",
          // overflow: "hidden",
          position: "relative",
          ".header-row": {
            fontWeight: "bold",
            background: "#E6F0FA!important",
          },
        },
        ".footer": {
          height: "32px",
          overflow: "hidden",
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
          display: "flex",
          justifyContent: "flex-end",
          ".zoom-control-wrapper": {
            width: "280px",
          },
        },
      }}
    >
      <Box className="header">
        <Box className="excel-toolbar">
          <Box className="toolbar-group">
            <QuickSearch
              doSearch={(q) => {
                doQuickSearch(q);
              }}
            ></QuickSearch>
          </Box>
          <Box className="toolbar-group flex-1"></Box>
          <Box className="toolbar-group">
            <IconButton>
              <AppSvgIcon size={16}>material-outline:undo</AppSvgIcon>
            </IconButton>
            <IconButton>
              <AppSvgIcon size={16}>material-outline:redo</AppSvgIcon>
            </IconButton>
            <IconButton>
              <AppSvgIcon size={16} onClick={() => setIsFullScreen(!isFullScreen)}>
                {isFullScreen ? "material-outline:close_fullscreen" : "material-outline:open_in_full"}
              </AppSvgIcon>
            </IconButton>
          </Box>
          <Box className="toolbar-group">
            <Button
              variant="text"
              onClick={() =>
                DrawerHelper.show("static Drawer", <Page2 />, {
                  size: "xl",
                  drawerProps: { hideBackdrop: true },
                })
              }
              startIcon={<AppSvgIcon size={16}>material-outline:settings</AppSvgIcon>}
            >
              Settings
            </Button>
            <ColumnAction></ColumnAction>
            <Button variant="text" startIcon={<AppSvgIcon size={16}>material-outline:filter_alt</AppSvgIcon>}>
              Filter
            </Button>
            <GroupAction></GroupAction>
            <Button variant="text" startIcon={<AppSvgIcon size={16}>material-outline:filter_alt</AppSvgIcon>}>
              Sort
            </Button>
            <RowHeightAction
              changeRowHeight={(height) => {
                setHotSettings(Object.assign(hotSettings, { rowHeights: height }));
              }}
            ></RowHeightAction>
          </Box>
          <Box className="toolbar-group">
            <ExportDropdown exportToWord={() => {}} exportToExcel={() => {}} exportToPdf={() => {}}></ExportDropdown>
          </Box>
          <Box className="toolbar-group">
            <Button variant="text" startIcon={<AppSvgIcon size={16}>material-outline:comment</AppSvgIcon>}>
              Comments
            </Button>
          </Box>
          <Box className="toolbar-group">
            <Button variant="text" endIcon={<AppSvgIcon size={16}>material-outline:more_horiz</AppSvgIcon>}>
              More
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className="body">
        <HotTable
          ref={tableRef}
          style={{ zoom: Number(zoom) / 100 }}
          settings={hotSettings}
          beforeOnCellMouseDown={beforeOnCellMouseDown}
        />
      </Box>
      <Box className="footer">
        <ZoomController onchange={(event) => setZoom(event)}></ZoomController>
      </Box>
    </Box>
  );
};

export default HandsontablePage;
