import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Drawer, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import MyTree from "components/MyTree";
import React from "react";

const dataset: any[] = [
  {
    id: "Risk Management",
    value: "Risk Management",
    opened: true,
    isBold: true,
    items: [
      {
        id: "Safety Risks",
        value: "Safety Risks",
        opened: true,
        isBold: true,
        items: [
          {
            id: "Safety Harms",
            value: "Safety Harms",
          },
          {
            id: "Hazards",
            value: "Hazards",
            isBold: true,
            items: [
              {
                id: "Hazard Category",
                value: "Hazard Category",
              },
              {
                id: "System Hazards",
                value: "System Hazards",
              },
              {
                id: "Hazardous Situations",
                value: "Hazardous Situations",
              },
              {
                id: "Causes",
                value: "Causes",
              },
              {
                id: "Basic Events",
                value: "Basic Events",
              },
              {
                id: "Risk Controls",
                value: "Risk Controls",
              },
            ],
          },
        ],
      },
      {
        id: "Security Risks",
        value: "Security Risks",
        opened: true,
        isBold: true,
        items: [
          {
            id: "Security Harms",
            value: "Security Harms",
          },
          {
            id: "Threats",
            value: "Threats",
            isBold: true,
            items: [
              {
                id: "Sub Threats",
                value: "Threats",
              },
              {
                id: "Vulnerabilities",
                value: "Vulnerabilities",
              },
              {
                id: "Threat Attacks",
                value: "Threat Attacks",
              },
            ],
          },
        ],
      },
      {
        id: "FMEAs",
        value: "FMEAs",
        opened: true,
        isBold: true,
        items: [
          {
            id: "Design FMEA",
            value: "Design FMEA",
          },
          {
            id: "Process FMEA",
            value: "Process FMEA",
          },
          {
            id: "Product Error Codes",
            value: "Product Error Codes",
          },
          {
            id: "Code Lib",
            value: "Code Lib",
          },
        ],
      },
      {
        id: "Evidences",
        value: "Evidences",
        opened: true,
        isBold: true,
        items: [
          {
            id: "Risk Benefit Analysis Ref",
            value: "Risk Benefit Analysis Ref.",
          },
        ],
      },
    ],
  },
  {
    isBold: true,
    id: "Cybersecurity Management",
    value: "Cybersecurity Management",
  },
  {
    isBold: true,
    id: "Assurance Case Management",
    value: "Assurance Case Management",
  },
  {
    id: "Requirement Management",
    value: "Requirement Management",
    opened: true,
    isBold: true,
    items: [
      {
        id: "Design Inputs",
        value: "Design Inputs",
        opened: true,
        items: [
          {
            id: "User Needs",
            value: "User Needs",
          },
          {
            id: "Design Input Requirements",
            value: "Design Input Requirements",
          },
          {
            id: "HW Subsystem Requirements",
            value: "HW Subsystem Requirements",
          },
          {
            id: "SW Subsystem Requirements",
            value: "SW Subsystem Requirements",
          },
        ],
      },
      {
        id: "Design Outputs",
        value: "Design Outputs",
        isBold: true,
        items: [
          {
            id: "Design Specifications",
            value: "Design Specifications",
          },
          {
            id: "DMR Design Outputs",
            value: "DMR Design Outputs",
          },
        ],
      },
      {
        id: "Design Verifications",
        value: "Design Verifications",
        opened: true,
        isBold: true,
        items: [
          {
            id: "System Verifications",
            value: "System Verifications",
          },
          {
            id: "SW Verifications",
            value: "SW Verifications",
          },
          {
            id: "HW Verifications",
            value: "HW Verifications",
          },
          {
            id: "Test Cases",
            value: "Test Cases",
          },
        ],
      },
      {
        id: "Design Validations",
        value: "Design Validations",
        isBold: true,
        items: [
          {
            id: "Validations",
            value: "Validations",
          },
        ],
      },
      {
        id: "Design Transfer",
        value: "Design Transfer",
        isBold: true,
        items: [
          {
            id: "IQ_OQ_PQ",
            value: "IQ/OQ/PQ",
          },
        ],
      },
    ],
  },
  {
    isBold: true,
    id: "Design Controls",
    value: "Design Controls",
  },
  {
    isBold: true,
    id: "Production Management",
    value: "Production Management",
  },
  {
    isBold: true,
    id: "Index File Management",
    value: "Index File Management",
  },
];

interface DataSourceModalProps {
  handleClose: () => void;
  open: boolean;
  sxStyle?: SxProps<Theme>;
}

const DataSourceModal: React.FC<DataSourceModalProps> = ({ open, handleClose, sxStyle = {} }) => {
  const [selected, setSelected] = React.useState<any>("Risk Management");

  React.useEffect(() => {}, [open]);

  const onNodeSelect = (event: object, value: string) => {
    setSelected(value);
  };

  return (
    <Drawer anchor="right" open={open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 360,
          height: "100%",
          ...sxStyle,
        }}
      >
        <Box
          sx={{
            height: 56,
            borderBottom: "1px solid  #f0f0f0",
            position: "relative",
            display: "flex",
            alignItems: "center",
            padding: "12px 24px",
            "& .MuiSvgIcon-root": {
              marginRight: "8px",
              cursor: "pointer",
            },
          }}
        >
          <CloseIcon onClick={handleClose} />
          <h3>
            <IntlMessages id="setting.process.dataSources" />
          </h3>
        </Box>

        <AppScrollbar
          sx={{
            height: "calc(100vh - 113px)",
            width: "100%",
            padding: "12px",
          }}
        >
          <MyTree
            selected={selected}
            onNodeSelect={onNodeSelect}
            data={dataset}
            useStyledTree={true}
            showFolder={true}
          />
        </AppScrollbar>
        <Box
          sx={{
            height: 56,
            padding: "12px 24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            borderTop: "1px solid  #f0f0f0",
          }}
        >
          <Button variant="contained" onClick={() => {}} color="primary" startIcon={<EditIcon />}>
            <IntlMessages id="common.edit" />
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DataSourceModal;
