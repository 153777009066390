import Icon from "@mui/material/Icon";
import * as React from "react";

const UsersSvg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="0%" y1="29.752%" x2="100%" y2="70.248%" id="15gl0pjuia">
        <stop stopColor="#FEC976" offset="0%" />
        <stop stopColor="#FF8158" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect fill="#FF991F" opacity=".099" width="40" height="40" rx="4" />
      <path
        d="M19.636 13.955a3.463 3.463 0 0 0 3.466-3.478A3.463 3.463 0 0 0 19.636 7a3.473 3.473 0 0 0-3.477 3.477 3.473 3.473 0 0 0 3.477 3.478zm-9.272 0a3.463 3.463 0 0 0 3.465-3.478A3.463 3.463 0 0 0 10.364 7a3.473 3.473 0 0 0-3.478 3.477 3.473 3.473 0 0 0 3.478 3.478zm0 2.318c-2.701 0-8.114 1.356-8.114 4.057v2.897h16.227V20.33c0-2.701-5.413-4.057-8.113-4.057zm9.272 0c-.336 0-.718.023-1.124.058 1.345.973 2.283 2.283 2.283 3.999v2.897h6.955V20.33c0-2.701-5.413-4.057-8.114-4.057z"
        fill="url(#15gl0pjuia)"
        transform="translate(5 5)"
      />
    </g>
  </svg>
);

const UsersIcon: React.FunctionComponent = () => {
  return <Icon component={UsersSvg} />;
};

export default UsersIcon;
