import Icon from "@mui/material/Icon";
import * as React from "react";

const FillTaskSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="ymrbl45g5a">
        <stop stopColor="#6656C2" offset="0%" />
        <stop stopColor="#9E8AED" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <g transform="translate(2 2)">
        <rect fill="url(#ymrbl45g5a)" width="12" height="12" rx="1" />
        <rect fill="#FFF" x="2" y="3" width="5" height="1" rx=".5" />
        <rect fill="#FFF" x="2" y="6" width="3" height="1" rx=".5" />
        <g transform="translate(7 6)">
          <circle stroke="#FFF" fill="#FFF" cx="3" cy="3" r="3.5" />
          <path d="M3 0C1.338 0 0 1.338 0 3s1.354 3 3 3c1.662 0 3-1.338 3-3S4.646 0 3 0z" fill="#7261CB" />
          <path
            d="M4.484 3.994a.208.208 0 0 1-.193.124c-.027 0-.055 0-.082-.014l-1.585-.716a.216.216 0 0 1-.124-.193V1.707c0-.11.096-.207.207-.207.11 0 .206.096.206.207V3.07l1.461.661c.11.042.152.152.11.262z"
            stroke="#FFF"
            strokeWidth=".5"
            fill="#FFF"
          />
        </g>
      </g>
    </g>
  </svg>
);

const FillTaskIcon: React.FunctionComponent = () => {
  return <Icon component={FillTaskSvg} />;
};

export default FillTaskIcon;
