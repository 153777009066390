import { Box, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import MyTreeTable from "components/MyTreeTable";
import React from "react";
import { Column } from "types/models/Common";

const TreeGridContainer = styled(Box)(({ theme }) => ({
  marginTop: "0px",
  ".table-tree": {
    height: "300px",
  },
}));

interface MyTreeGridProps {}

const MyTreeGrid: React.FC<MyTreeGridProps> = (props: MyTreeGridProps) => {
  const [columns, setColumns] = React.useState<Column[]>([
    {
      id: "workItem",
      label: "common.work.item",
      minWidth: 200,
      align: "left",
    },
    {
      id: "worksheet",
      label: "common.worksheet.column",
      minWidth: 100,
      align: "left",
    },
    {
      id: "id",
      label: "common.id",
      minWidth: 200,
      align: "left",
    },
    {
      id: "attribute",
      label: "common.attribute.columns",
      minWidth: 200,
      align: "left",
    },
  ]);

  const [rows, setRows] = React.useState<any[]>([
    {
      id: "S#254321",
      workItem: "Therapeutic",
      worksheet: "Hazard Category",
      attribute: "",
      level: 1,
      children: [
        {
          id: "S#254327",
          workItem: "Over-delivery",
          worksheet: "System Hazard",
          attribute: "",
          level: 2,
          children: [
            {
              id: "S#254328",
              workItem: "Incorrectly controlled delivery",
              worksheet: "Hazardous Situation ",
              attribute: "",
              level: 3,
              children: [
                {
                  id: "S#254367",
                  workItem: "(1) Infusion rate is faster ...",
                  worksheet: "Sequence of Events/Causes",
                  attribute: "",
                  level: 4,
                },
              ],
            },
            {
              id: "S#254338",
              workItem: "My test",
              level: 3,
              worksheet: "Hazardous Situation",
              attribute: "",
            },
            {
              id: "S#452177",
              workItem: "Not able to timely start solut.",
              worksheet: "Hazardous Situation",
              attribute: "",
              level: 3,
              children: [
                {
                  id: "S#544309",
                  workItem: "Not able to timely start so...",
                  worksheet: "Sequence of Events/Causes ",
                  attribute: "",
                  level: 4,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return (
    <TreeGridContainer>
      <Box className="table-tree">
        <MyTreeTable columns={columns} rows={rows} maxHeight="300px" />
      </Box>
    </TreeGridContainer>
  );
};

export default MyTreeGrid;
