import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Grid, IconButton, InputAdornment, Link, styled } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import MySelectField from "components/MySelectField";
import MyTextField from "components/MyTextField";
import ProductTable from "components/ProductTable";
import { Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { accordionStyle, bgColor, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";
import { LinkData, NodeData } from "types/models/GoJs";
import * as yup from "yup";

import ContentHeader from "../components/ContentHeader";
import Diagram from "./Diagram";

const ProfileContainer = styled(Box)(({ theme }) => ({
  ".diagram-container": {
    width: "100%",
    height: "400px",
    ".diagram-box": {
      width: "100%",
      height: "400px",
    },
  },
}));

const Profile = () => {
  const nodeData: NodeData[] = [
    {
      key: "1_Main",
      text: "R1.0",
      color: "#3e50b4",
      textColor: "#FFF",
      group: "group_1",
      loc: "-222.588134765625 500.1451416015625",
    },
    {
      key: "1_R2.0",
      text: "R2.0",
      color: "#3e50b4",
      textColor: "#FFF",
      group: "group_1",
      loc: "88.436279296875 500.1451416015625",
    },
    {
      key: "1_R3.0",
      text: "R3.0",
      color: "#3e50b4",
      textColor: "#FFF",
      group: "group_1",
      loc: "495.436279296875 500.1451416015625",
    },
    {
      key: "1_R4.0",
      text: "R4.0",
      color: "#3e50b4",
      textColor: "#FFF",
      group: "group_1",
      loc: "863.436279296875 500.1451416015625",
    },
    {
      key: "1_EU",
      text: "R2.0",
      color: "#9b26af",
      textColor: "#FFF",
      group: "group_2",
      loc: "114.436279296875 618.1451416015625",
    },
    {
      key: "1_EU2.1",
      text: "R2.1",
      color: "#9b26af",
      textColor: "#FFF",
      group: "group_2",
      loc: "297.436279296875 618.1451416015625",
    },
    {
      key: "1_EU2.2",
      text: "R2.2",
      color: "#9b26af",
      textColor: "#FFF",
      group: "group_2",
      loc: "478.436279296875 618.1451416015625",
    },
    {
      key: "1_China",
      text: "R3.0",
      color: "#fe3b00",
      textColor: "#FFF",
      group: "group_3",
      loc: "500.436279296875 357.1451416015625",
    },
    {
      key: "1_China3.1",
      text: "R3.1",
      color: "#fe3b00",
      textColor: "#FFF",
      group: "group_3",
      loc: "627.436279296875 357.1451416015625",
    },
    {
      key: "1_China3.2",
      text: "R3.2",
      color: "#fe3b00",
      textColor: "#FFF",
      group: "group_3",
      loc: "742.436279296875 357.1451416015625",
    },
    {
      key: "1_China3.3",
      text: "R3.3",
      color: "#fe3b00",
      textColor: "#FFF",
      group: "group_3",
      loc: "865.436279296875 357.1451416015625",
    },
    {
      key: "1_NextGen",
      text: "R4.0",
      color: "#37d066",
      textColor: "#FFF",
      group: "group_4",
      loc: "903.436279296875 614.1451416015625",
    },
    {
      key: "1_NextGen4.1",
      text: "R4.1",
      color: "#37d066",
      textColor: "#FFF",
      group: "group_4",
      loc: "1074.436279296875 614.1451416015625",
    },
    {
      key: "1_NextGen4.2",
      text: "R4.2",
      color: "#37d066",
      textColor: "#FFF",
      group: "group_4",
      loc: "1186.436279296875 614.1451416015625",
    },
    { key: "group_1", isGroup: true, text: "Main", fill: "#FFF" },
    { key: "group_2", isGroup: true, text: "EU", fill: "#FFF" },
    { key: "group_3", isGroup: true, text: "China", fill: "#FFF" },
    { key: "group_4", isGroup: true, text: "US", fill: "#FFF" },
  ];
  const linkData: LinkData[] = [
    { key: 1, from: "1_Main", to: "1_R2.0", text: "" },
    { key: 2, from: "1_R2.0", to: "1_R3.0", text: "" },
    { key: 3, from: "1_R3.0", to: "1_R4.0", text: "" },
    {
      key: 4,
      from: "1_EU",
      to: "1_R2.0",
      toArrow: "CircleFork",
      color: "#02a7f0",
      arrowColor: "#02a7f0",
      dash: [12, 4, 2, 4],
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      // text: "data source of",
      // textColor: "#016fa0",
    },
    { key: 5, from: "1_EU", to: "1_EU2.1", text: "" },
    { key: 6, from: "1_EU2.1", to: "1_EU2.2", text: "" },
    {
      key: 7,
      from: "1_China",
      to: "1_R3.0",
      toArrow: "CircleFork",
      color: "#02a7f0",
      arrowColor: "#02a7f0",
      dash: [12, 4, 2, 4],
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      // text: "data source of",
      // textColor: "#016fa0",
    },
    { key: 8, from: "1_China", to: "1_China3.1", text: "" },
    { key: 9, from: "1_China3.1", to: "1_China3.2", text: "" },
    { key: 10, from: "1_China3.2", to: "1_China3.3", text: "" },
    {
      key: 11,
      from: "1_NextGen",
      to: "1_R4.0",
      toArrow: "CircleFork",
      color: "#02a7f0",
      arrowColor: "#02a7f0",
      dash: [12, 4, 2, 4],
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      // text: "data source of",
      // textColor: "#016fa0",
    },
    { key: 12, from: "1_NextGen", to: "1_NextGen4.1", text: "" },
    { key: 13, from: "1_NextGen4.1", to: "1_NextGen4.2", text: "" },
  ];

  const [edit, setEdit] = useState<boolean>(false);
  const { messages } = useIntl();
  const validationSchema = yup.object({
    name: yup.string().required(String(messages["validation.nameRequired"])),
    code: yup.string().required(String(messages["validation.codeRequired"])),
    manager: yup.string().required(String(messages["validation.managerRequired"])),
  });

  const handleSave = () => {
    console.log(document.getElementById("submit"));
    document.getElementById("submit").click();
  };

  const handleCancel = () => {
    document.getElementById("cancel").click();
  };

  const cancel = (resetForm) => {
    resetForm();
    setEdit(false);
  };

  return (
    <ProfileContainer>
      <ContentHeader titleId="admin.profile">
        <Box sx={{ display: edit ? "none" : "inline-block" }}>
          <Button
            onClick={() => {
              setEdit(true);
            }}
            variant="outlined"
            color="inherit"
            startIcon={<AppSvgIcon size={16}>{"material-solid:edit"}</AppSvgIcon>}
          >
            <IntlMessages id="common.edit" />
          </Button>
        </Box>
        <Box sx={{ display: edit ? "inline-block" : "none" }}>
          <Button
            className="text-color-08"
            sx={{
              mr: 3,
              borderRadius: "4px",
              border: "solid 1px rgba(0, 0, 0, 0.15)",
              color: textColor["85"],
            }}
            onClick={handleCancel}
            startIcon={
              <AppSvgIcon size={16} color={textColor["65"]}>
                {"material-solid:cancel"}
              </AppSvgIcon>
            }
            variant="outlined"
          >
            <IntlMessages id="common.cancel" />
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            startIcon={
              <AppSvgIcon size={16} color="#FFF">
                {"material-solid:save"}
              </AppSvgIcon>
            }
            variant="contained"
          >
            <IntlMessages id="common.save" />
          </Button>
        </Box>
      </ContentHeader>
      <AppScrollbar
        sx={{
          height: "calc(100vh - 152px)",
          padding: (theme) => theme.spacing(5),
        }}
      >
        <Box>
          <Formik
            initialValues={{
              name: "Thermometers",
              code: "THE",
              manager: "James",
              type: "",
              icon: "",
              themeColor: "",
              original_Product: "",
              description: "",
              releases: "R1.2",
              branchName: "Med ABC+",
              stage: "Pre-Production",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, setValues }) => {
              console.log(data);
              setValues({
                ...data,
              });
              setSubmitting(false);
              setEdit(false);
            }}
          >
            {({ isSubmitting, values, setFieldValue, resetForm }) => (
              <Form
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                noValidate
                autoComplete="off"
              >
                <Box sx={accordionStyle}>
                  <Grid container spacing={9}>
                    <Grid item xs={3} md={3}>
                      <MyTextField
                        name="name"
                        fullWidth
                        label="workspace.form.name"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: edit && values.name ? "visible" : "hidden" }}
                                onClick={() => {
                                  setFieldValue("name", "");
                                }}
                              >
                                <AppSvgIcon size={16} color="action">
                                  {"material-outline:clear"}
                                </AppSvgIcon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        variant={edit ? "outlined" : "standard"}
                        disabled={!edit}
                        required={edit ? true : false}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <MyTextField
                        name="code"
                        fullWidth
                        label="common.code"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: edit && values.code ? "visible" : "hidden" }}
                                onClick={() => {
                                  setFieldValue("code", "");
                                }}
                              >
                                <AppSvgIcon size={16} color="action">
                                  {"material-outline:clear"}
                                </AppSvgIcon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        variant={edit ? "outlined" : "standard"}
                        disabled={!edit}
                        required={edit ? true : false}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <MySelectField
                        label="common.manager"
                        onChange={(e) => {
                          setFieldValue("template", e.target.value);
                        }}
                        name="manager"
                        fullWidth
                        variant={edit ? "outlined" : "standard"}
                        disabled={!edit}
                        required={edit ? true : false}
                        list={[{ id: "aaron2022", label: "aaron2022" }]}
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 3 }} spacing={9}>
                    <Grid item xs={3} md={3}>
                      <MyTextField
                        name="icon"
                        fullWidth
                        label="common.icon.and.image"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: edit && values.icon ? "visible" : "hidden" }}
                                onClick={() => {
                                  setFieldValue("icon", "");
                                }}
                              >
                                <AppSvgIcon size={16} color="action">
                                  {"material-outline:clear"}
                                </AppSvgIcon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        variant={edit ? "outlined" : "standard"}
                        disabled={!edit}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <MyTextField
                        name="themeColor"
                        fullWidth
                        label="common.color.theme"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: edit && values.themeColor ? "visible" : "hidden" }}
                                onClick={() => {
                                  setFieldValue("themeColor", "");
                                }}
                              >
                                <AppSvgIcon size={16} color="action">
                                  {"material-outline:clear"}
                                </AppSvgIcon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        variant={edit ? "outlined" : "standard"}
                        disabled={!edit}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <MyTextField
                        name="original_Product"
                        fullWidth
                        label="common.original.product"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: edit && values.original_Product ? "visible" : "hidden" }}
                                onClick={() => {
                                  setFieldValue("original_Product", "");
                                }}
                              >
                                <AppSvgIcon size={16} color="action">
                                  {"material-outline:clear"}
                                </AppSvgIcon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        variant={edit ? "outlined" : "standard"}
                        disabled={!edit}
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 3 }} spacing={9}>
                    <Grid item xs={9} md={9}>
                      <MyTextField
                        name="description"
                        fullWidth
                        label="common.description"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: edit && values.description ? "visible" : "hidden" }}
                                onClick={() => {
                                  setFieldValue("description", "");
                                }}
                              >
                                <AppSvgIcon size={16} color="action">
                                  {"material-outline:clear"}
                                </AppSvgIcon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        multiline
                        rows="3"
                        variant={edit ? "outlined" : "standard"}
                        disabled={!edit}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    sx={{
                      position: "relative",
                      display: "none",
                      minWidth: 100,
                    }}
                    id="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <IntlMessages id="common.save" />
                  </Button>
                  <Button
                    sx={{
                      position: "relative",
                      display: "none",
                      minWidth: 100,
                    }}
                    id="cancel"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      cancel(resetForm);
                    }}
                    disabled={isSubmitting}
                  >
                    <IntlMessages id="common.cancel" />
                  </Button>
                  {/* <ProductTable rows={rows} columns={columns} showPage={false} /> */}
                  <Box className="diagram-container" sx={{ mt: 2 }}>
                    <Diagram nodeData={nodeData} linkData={linkData} />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </AppScrollbar>
    </ProfileContainer>
  );
};

export default Profile;
