import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, IconButton, Menu, MenuItem, Tab, Tabs, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { bgColor, borderColor, boxShadow, tabStyle } from "shared/constants/AppConst";

const filters: any[] = [
  {
    id: "1",
    label: "Filter1",
    labelId: "products.activities.filter1",
  },
  {
    id: "2",
    label: "Filter2",
    labelId: "products.activities.filter2",
  },
  {
    id: "3",
    label: "Filter3",
    labelId: "products.activities.filter3",
  },
];

const Container = styled(Box)(({ theme }) => ({
  ".tabs-container": {
    background: theme.palette.background.paper,
    padding: theme.spacing(5),
    boxShadow: boxShadow.cardBoxShadow,
  },
}));

const Activities = () => {
  const location = useLocation();
  React.useEffect(() => {
    if (location?.pathname.indexOf("myActivities") !== -1) {
      setIndex(0);
    } else if (location?.pathname.indexOf("allActivities") !== -1) {
      setIndex(1);
    }
  }, [location]);
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  const { messages } = useIntl();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [selectedFilter, setSelectedFilter] = useState(filters[0]);

  const [searchText, setSearchText] = useState<string>();

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  const handleRequestSearch = (value: string) => {
    setSearchText(value);
  };

  const handleButtonOpenClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleButtonOpenClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <AppScrollbar
        sx={{
          width: "100%",
          height: "100%",
          padding: (theme) => theme.spacing(5),
          background: bgColor.main,
        }}
      >
        <Box className="tabs-container">
          <Box
            className="flex justify-between items-center"
            sx={{
              borderBottom: 1,
              borderColor: borderColor.dividerColor,
            }}
          >
            <Box className="flex justify-start items-center">
              <Tabs
                value={index}
                onChange={handleChangeTab}
                sx={{
                  ...tabStyle,
                  "& .MuiButtonBase-root": {
                    minHeight: "48px",
                    textTransform: "none",
                  },
                }}
              >
                <Tab
                  onClick={() => {
                    navigate("myActivities");
                  }}
                  label={<IntlMessages id="product.activities.myActivities" />}
                />
                <Tab
                  onClick={() => {
                    navigate("allActivities");
                  }}
                  label={<IntlMessages id="product.activities.allActivities" />}
                />
              </Tabs>
              <Box className="flex">
                <Button
                  className="text-color-08"
                  sx={{
                    mr: 3,
                    borderRadius: "4px",
                    border: `solid 1px ${borderColor.dividerColor}`,
                  }}
                  variant="outlined"
                  endIcon={
                    Boolean(anchorEl) ? (
                      <AppSvgIcon color="action">{"material-outline:arrow_drop_up"}</AppSvgIcon>
                    ) : (
                      <AppSvgIcon color="action">{"material-outline:arrow_drop_down"}</AppSvgIcon>
                    )
                  }
                  onClick={handleButtonOpenClick}
                  id="report_cancel"
                >
                  {selectedFilter.label}
                  {/* <IntlMessages id="common.filters" /> */}
                </Button>
                <Menu
                  id="filters-menus"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleButtonOpenClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      marginRight: 2,
                    },
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {filters.map((x) => (
                    <MenuItem
                      key={x.id}
                      onClick={() => {
                        setSelectedFilter(x);
                        handleButtonOpenClose();
                      }}
                    >
                      {x.label}
                      {/* <IntlMessages id="setting.impactSummary.ACauseOfB" /> */}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
            <Box className="search-box">
              <TextField
                variant="outlined"
                value={searchText}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRequestSearch(event.target.value)}
                placeholder={String(messages["common.search"])}
                InputProps={{
                  startAdornment: (
                    <AppSvgIcon size={16} color="action">
                      {"material-outline:search"}
                    </AppSvgIcon>
                  ),
                  endAdornment: (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{ visibility: searchText ? "visible" : "hidden" }}
                        onClick={() => {
                          handleRequestSearch("");
                        }}
                      >
                        <AppSvgIcon size={16} color="action">
                          {"material-outline:clear"}
                        </AppSvgIcon>
                      </IconButton>
                    </Box>
                  ),
                }}
                sx={{
                  width: {
                    xs: 1,
                    sm: "auto",
                  },
                  m: (theme) => theme.spacing(1, 0.5, 1.5),
                  "& .MuiSvgIcon-root": {
                    mr: 0.5,
                  },
                  "& .MuiInputBase-root": {
                    padding: "0px 14px",
                    height: "32px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "0px",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              pt: (theme) => theme.spacing(3),
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </AppScrollbar>
    </Container>
  );
};

export default Activities;
