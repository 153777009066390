import { Badge, Box, Link } from "@mui/material";
import CustomTable from "components/CustomTable";
import { ListModel } from "components/CustomTable/CustomTableModel";
import FillProductIcon from "components/Icons/FillProductIcon";
import FillTaskIcon from "components/Icons/FillTaskIcon";
import { getImpactAnalysisColumns, getOtherTypeColumns } from "mock/FeedsData";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppState } from "redux/store";
import { statusBgColor } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

import AddFeed from "../AddFeed";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

interface MyTableProps {}

export default function MyTable(props: MyTableProps) {
  const navigate = useNavigate();
  const { moduleList } = useSelector<AppState, AppState["workspaces"]>(({ workspaces }) => workspaces);

  const allColumn: any[][] = React.useMemo(
    () => [getImpactAnalysisColumns(navigate), getOtherTypeColumns(navigate)],
    []
  );

  const allRows: any = React.useMemo(
    () => [
      [
        {
          id: "IF#001",
          content: "Fault Tree Analysis should be modified according to SHA",
          status: "Open",
          source: "PRD#001-TK#001 System Hazard Analysis (V1.0)",
          scope: "",
          time: "09:12:21 3/13/2022",
        },
      ],
      [
        {
          id: "FD#001",
          content: "Fault Tree Analysis should be modified according to SHA",
          status: "Open",
          productID: "PRD#001",
          tasks: "TK#001 System Hazard Analysis (V1.0)",
          createdBy: "Aaron",
          source: "Manual",
          creationTime: "14:25:34 1/15/2022",
        },
      ],
      [
        {
          id: "FD#001",
          content: "Fault Tree Analysis should be modified according to SHA",
          status: "Open",
          productID: "PRD#001",
          tasks: "TK#001 System Hazard Analysis (V1.0)",
          createdBy: "Aaron",
          source: "Complaint",
          creationTime: "14:25:34 1/15/2022",
        },
      ],
      [
        {
          id: "FD#001",
          content: "Fault Tree Analysis should be modified according to SHA",
          status: "Open",
          productID: "PRD#001",
          tasks: "TK#001 System Hazard Analysis (V1.0)",
          createdBy: "Aaron",
          source: "CAPA",
          creationTime: "14:25:34 1/15/2022",
        },
      ],
    ],
    []
  );
  const [column, setColumn] = React.useState<Column[]>(allColumn[0]);
  const [rows, setRows] = React.useState<any[]>(allRows[0]);
  const [showAdd, setShowAdd] = React.useState<boolean>(true);

  const [searchText, setSearchText] = React.useState("");
  const [filter1, setFilter1] = React.useState<any>(1);
  const [filterList1] = React.useState<ListModel[]>([
    {
      id: 1,
      name: "Impact Analysis",
    },
    {
      id: 2,
      name: "Manual",
    },
    {
      id: 3,
      name: "Complaint",
    },
    {
      id: 4,
      name: "CAPA System",
    },
  ]);
  // const [filter2, setFilter2] = React.useState<any>("");
  // const [filterList2] = React.useState<ListModel[]>([
  //   {
  //     id: "1",
  //     name: "Filter2",
  //   },
  // ]);
  const [openFeedModal, setOpenFeedModal] = React.useState<boolean>(false);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  React.useEffect(() => {
    if (filter1 > 1) {
      setColumn(allColumn[1]);
      setRows(allRows[filter1 - 1]);
    } else {
      setColumn(allColumn[0]);
      setRows(allRows[0]);
    }
    if (filter1 === 1 || filter1 === 2) {
      setShowAdd(true);
    } else {
      setShowAdd(false);
    }
  }, [filter1, allRows, allColumn]);

  return (
    <>
      <CustomTable
        toolBar={{
          showSearch: true,
          searchText: searchText,
          requestSearch: requestSearch,
          saveFilter: () => {
            console.log("save filter");
          },
          filters: [
            {
              value: filter1,
              list: filterList1,
              disabledNone: true,
              setValue: setFilter1,
            },
            // {
            //   value: filter2,
            //   list: filterList2,
            //   setValue: setFilter2,
            // },
          ],
          buttons: showAdd
            ? [
                {
                  id: "common.customize",
                  icon: TableHeaderButtonsIconType.SETTING,
                  onChange: () => {},
                },
                {
                  id: "workspace.button.feed",
                  icon: TableHeaderButtonsIconType.ADD,
                  type: "text",
                  color: "#2e6ccb",
                  isBlue: true,
                  onChange: () => setOpenFeedModal(true),
                },
              ]
            : [
                {
                  id: "common.customize",
                  icon: TableHeaderButtonsIconType.SETTING,
                  onChange: () => {},
                },
              ],
        }}
        columns={column}
        rows={rows}
      />
      {<AddFeed open={openFeedModal} handleClose={() => setOpenFeedModal(false)} />}
    </>
  );
}
