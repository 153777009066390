import { Box, styled } from "@mui/material";
import * as go from "gojs";
import { LinkData, NodeData } from "types/models/GoJs";

import Diagram from "../Diagram";

const Container = styled(Box)(({ theme }) => ({
  ".diagram-container": {
    width: "100%",
    minHeight: "600px",
    maxHeight: "calc(100vh - 244px)",
    ".diagram-box": {
      width: "100%",
      height: "inherit",
      minHeight: "600px",
      maxHeight: "calc(100vh - 244px)",
    },
  },
}));

const GraphicView = () => {
  const nodeData: NodeData[] = [
    { key: "1_Hazard", text: "Hazard", color: "#FFF", group: "group_1", loc: "383.588134765625 457.1451416015625" },
    {
      key: "1_Hazardous_Situation",
      text: "Hazardous Situation",
      color: "#FFF",
      stroke: "#000",
      group: "group_1",
      loc: "383.436279296875 567.1451416015625",
    },
    {
      key: "1_Cause",
      text: "Cause",
      color: "#FFF",
      stroke: "#000",
      group: "group_1",
      loc: "183.384033203125 567.1451416015625",
    },
    {
      key: "1_Risk_Control",
      text: "Risk Control",
      color: "#FFF",
      stroke: "#000",
      group: "group_1",
      loc: "-0.384033203125 567.1451416015625",
    },
    {
      key: "1_Safety_Harm",
      text: "Safety Harm",
      color: "#FFF",
      stroke: "#000",
      group: "group_1",
      loc: "583.596923828125 567.1451416015625",
    },
    {
      key: "2_User_Need",
      text: "User Need",
      color: "#FFF",
      group: "group_2",
      loc: "92.741455078125 234.69390055338545",
    },
    {
      key: "2_Requirement",
      text: "Requirement",
      color: "#FFF",
      group: "group_2",
      loc: "292.741455078125 234.69390055338545",
    },
    {
      key: "2_Risk_Control",
      text: "Risk Control",
      color: "#FFF",
      stroke: "#000",
      group: "group_2",
      loc: "-0.384033203125 334.69390055338545",
    },
    // {
    //   key: "_shared_box_",
    //   color: "#f59a23",
    //   figure: "Database",
    //   width: 24,
    //   height: 30,
    //   loc: "-0.384033203125 434.69390055338545",
    // },
    {
      key: "2_Verification",
      text: "Verification",
      color: "#FFF",
      group: "group_2",
      loc: "492.741455078125 134.69390055338545",
    },
    {
      key: "2_Validation",
      text: "Validatioln",
      color: "#FFF",
      group: "group_2",
      loc: "492.741455078125 234.69390055338545",
    },
    {
      key: "2_User_Story",
      text: "User Story",
      color: "#FFF",
      group: "group_2",
      loc: "492.741455078125 334.69390055338545",
    },
    { key: "2_Defect", text: "Defect", color: "#FFF", group: "group_2", loc: "692.741455078125 194.69390055338545" },
    {
      key: "group_1",
      isGroup: true,
      text: "Risk Management",
      fill: "#FFF",
      loc: "392.1566162109375 512.1451416015625",
    },
    { key: "group_2", isGroup: true, text: "Design Controls", fill: "#FFF", loc: "386.60009765625 234.69390055338545" },
  ];
  const linkData: LinkData[] = [
    { key: 1, from: "1_Hazardous_Situation", to: "1_Hazard", dash: [6, 3], text: "assesses" },
    { key: 2, from: "1_Hazardous_Situation", to: "1_Safety_Harm", text: "leads to" },
    { key: 3, from: "1_Cause", to: "1_Hazardous_Situation", text: "leads to" },
    { key: 4, from: "1_Risk_Control", to: "1_Cause", dash: [6, 3], text: "is verified by" },
    { key: 5, from: "2_User_Need", to: "2_Requirement", dash: [6, 3], text: "derives" },
    { key: 6, from: "2_Requirement", to: "2_Validation", dash: [6, 3], text: "is validated by" },
    { key: 7, from: "2_Requirement", to: "2_Verification", text: "is validated by" },
    { key: 8, from: "2_Requirement", to: "2_User_Story", dash: [6, 3], text: "derives" },
    { key: 9, from: "2_Requirement", to: "2_Risk_Control", dash: [6, 3], text: "is assessed by" },
    { key: 10, from: "2_Verification", to: "2_Defect", dash: [6, 3], text: "is caused by" },
    { key: 11, from: "2_Validation", to: "2_Defect", dash: [6, 3], text: "is caused by" },
    {
      key: 12,
      from: "1_Risk_Control",
      to: "2_Risk_Control",
      dir: 0,
      color: "#f59a23",
      dash: [12, 4, 2, 4],
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
    },
    // {
    //   key: 13,
    //   from: "_shared_box_",
    //   to: "2_Risk_Control",
    //   dir: 0,
    //   color: "#f59a23",
    //   dash: [12, 4, 2, 4],
    //   routing: {
    //     class: "go.EnumValue",
    //     classType: "Link",
    //     name: "Normal",
    //   },
    // },
  ];

  return (
    <Container>
      <Box className="diagram-container" sx={{ mt: 5 }}>
        <Diagram nodeData={nodeData} linkData={linkData} />
      </Box>
    </Container>
  );
};

export default GraphicView;
