import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import Diagram from "./Diagram";

const RelationshipsContainer = styled(Box)(({ theme }) => ({
  padding: "8px 0",
  minWidth: "1000px",
  ".details-content": {
    marginTop: "8px",
  },
}));

const Relationships = () => {
  return (
    <RelationshipsContainer>
      <Box className="details-content">
        <Box>
          <Diagram />
        </Box>
      </Box>
    </RelationshipsContainer>
  );
};

export default Relationships;
