import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Breadcrumbs, Divider, List, styled } from "@mui/material";
import Link from "@mui/material/Link";
import ForwardAndBackward from "components/ForwardAndBackward";
import VerticalCollapse from "layout/components/VerticalNav/VerticalCollapse";
import VerticalItem from "layout/components/VerticalNav/VerticalItem";
import NavVerticalGroup from "layout/components/VerticalNav/VerticalNavGroup";
import { taskData } from "mock/index";
import React from "react";
import { Outlet } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { bgColor, borderColor, boxShadow, sidebarStyle, textColor } from "shared/constants/AppConst";

import adminConfigData, { AdminRouterConfigData } from "../Admin/adminConfigData";

const CustomList = styled(List)(({ theme }) => ({}));

const ProductSettingsLayout = () => {
  const routeParam = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  const [mockData, setMockData] = React.useState(null);

  React.useEffect(() => {
    routeParam?.type && setMockData(taskData[routeParam.type]);
  }, [routeParam]);

  return (
    <Box className="workspace-detail-layout">
      <Box
        className="workspace-detail-info-bar"
        sx={{
          position: "relative",
          px: (theme) => theme.spacing(5),
          height: 48,
          display: "flex",
          alignItems: "center",
          // marginBottom: "24px",
          borderRadius: 0,
          background: (theme) => theme.palette.background.paper,
          boxShadow: boxShadow.cardBoxShadow,
          borderBottom: `1px solid  ${borderColor.dividerColor}`,
          zIndex: 100,
          // boxShadow: "0 2px 8px 0 #0000001f",
          "& .left-wrapper": {
            flex: 1,
          },
        }}
      >
        <ForwardAndBackward />
        <Box className="breadcrumb-navigation-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color={textColor[65]} href="/products">
              <IntlMessages id="menu.product" />
            </Link>
            <Link underline="hover" color={textColor[65]} href="/products/123/profile">
              {mockData?.product ? mockData.product : "Thermometers"}
            </Link>
          </Breadcrumbs>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          height: "calc(100vh - 104px)",
          background: "#fff",
        }}
      >
        <Box
          sx={{
            width: 270,
            height: "100%",
            backgroundColor: (theme) => theme.palette.background.default,
            minHeight: "calc(100vh - 104px)",
            overflow: "hidden",
            borderRight: `1px solid ${borderColor.sidebarBorderRightColor}`,
            // boxShadow: "1px 0 0 0 #dadfe6",
            // boxShadow: '8px 0 12px 4px rgba(0, 0, 0, 0.02), 6px 0 12px -8px rgba(0, 0, 0, 0.04)',
          }}
        >
          <CustomList
            sx={{
              ...sidebarStyle,
              borderRight: 0,
            }}
          >
            {adminConfigData.map((item: AdminRouterConfigData) => (
              <React.Fragment key={item.id}>
                {item.type === "group" && <NavVerticalGroup item={item} level={item.level} />}

                {item.type === "title" && (
                  <Box className="slider-title">
                    <h4>
                      <IntlMessages id={item!.messageId} />
                    </h4>
                  </Box>
                )}

                {item.type === "collapse" && <VerticalCollapse item={item} level={item.level} />}

                {item.type === "divider" && (
                  <Divider
                    sx={{
                      width: "calc(100% - 28px)",
                      margin: "30px 0 16px 16px",
                    }}
                  />
                )}

                {item.type === "item" && <VerticalItem item={item} level={item.level} />}
              </React.Fragment>
            ))}
          </CustomList>
        </Box>
        <Box
          sx={{
            width: "calc(100% - 270px)",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AppScrollbar
            sx={{
              width: "100%",
              margin: "0",
              backgroundColor: "transparent",
              maxHeight: "calc(100vh - 104px)",
              background: bgColor.main,
            }}
          >
            <Outlet />
          </AppScrollbar>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductSettingsLayout;
