import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Button, Divider, FormControl, FormControlLabel, InputLabel, List, ListItem, MenuItem, Popover, Select, Switch } from "@mui/material";
import clsx from "clsx";
import AppSvgIcon from "components/AppSvgIcon";
import React from "react";

interface GroupProps {
  changeColumnGroupSetting?: (settings) => void;
  sxStyle?: any;
}

class ColumnLookupDto {
  name: String;
  label: String;
}

interface ColumnGroupSetting {
  name: String;
  sorting?: String;
}

const GroupAction: React.FC<GroupProps> = (props) => {

  const { changeColumnGroupSetting, sxStyle } = props;

  const defaultColumns: ColumnLookupDto[] = [
    { name: "rowid", label: "RowId" },
    { name: "hazardCategroy", label: "Hazard Category" },
    { name: "hazard", label: "Hazard" },
    { name: "harms", label: "Harms" },
    { name: "hazardSituation", label: "Hazard Situation" },
    { name: "sequenceofevents", label: "Sequence of Events(SoE)" },
    { name: "analysisoffailuremodes", label: "Analysis of Failure Modes" },
    { name: "severity", label: "Severity" },
    { name: "p1", label: "P1 SOE(U)" },
    { name: "p1u", label: "P1(U)" },
    { name: "p2u", label: "P2(U)" },
    { name: "poh", label: "POH(U)" },
    { name: "risk", label: "Risk(U)" },
    { name: "riskcontrols", label: "Risk Controls" },
    { name: "riskcontrolintroducedrisks", label: "Risk Controls Introduced Risk" },
    { name: "p1seom", label: "P1 SOE(M)" },
    { name: "p1m", label: "P1(M)" },
    { name: "p2m", label: "P2(M)" },
    { name: "pohm", label: "POH(M)" },
    { name: "riskm", label: "Risk Region(M)" },
    { name: "vnv", label: "V & V" },
  ];

  const defaultGroupList: ColumnGroupSetting[] = [
    { name: "hazard", sorting: "asc" },
    { name: "hazardCategroy", sorting: "asc" },
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [columnGroupList, setColumnGroupList] = React.useState<ColumnGroupSetting[]>(defaultGroupList);

  const openList = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = () => { };

  return (<><Button
    variant="text"
    onClick={handleClick}
    startIcon={<AppSvgIcon size={16}>material-outline:format_indent_increase</AppSvgIcon>}
    endIcon={openList ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    {...sxStyle}
  >
    Group
  </Button>
    <Popover
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box sx={{
        width: "500px"
      }}>
        <List className="column-list">
          {
            columnGroupList.map(x => {
              return (
                <React.Fragment key={`column-${x.name}`}>
                  <ListItem alignItems="flex-start" sx={{ heigth: "36px", padding: "0 16px" }} className="flex-wrap flex flex-row items-center">
                    <AppSvgIcon className="cursor-grab">material-outline:drag_indicator</AppSvgIcon>
                    <FormControl size="small" className="flex-1">
                      <Select
                        value={x.name}
                        onChange={handleChange}
                      >
                        {defaultColumns.map((column, index) => {
                          return (
                            <MenuItem>
                              {column.label}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    <FormControlLabel className="width-150" control={<Switch checked={x.sorting === "asc"} />} label={x.sorting === "desc" ? "Z ->A" : "A -> Z"} />
                    <AppSvgIcon size={16}>material-outline:close</AppSvgIcon>
                  </ListItem>
                </React.Fragment>
              )
            })
          }
          <Divider variant="middle" />
          <ListItem alignItems="flex-start" className="group-list-item flex-wrap flex flex-row items-center">
            <FormControl size="small" className="w-xs">
              <Select onChange={handleChange} placeholder="Choose Column..." >
                {defaultColumns.map((column, index) => {
                  return (
                    <MenuItem>
                      {column.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </ListItem>
        </List>
      </Box>
    </Popover></>);
};

export default GroupAction;
