import IntlMessages from "@crema/utility/IntlMessages";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import Paper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import * as React from "react";
import Draggable from "react-draggable";

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(12.5),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
    maxHeight: 800,
  },
  "& .MuiDialogActions-root": {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  "& .MuiIconButton-root": {
    top: theme.spacing(4),
    right: theme.spacing(5),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle component={"div"} sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface SimplePopupProps extends DialogProps {
  handleClose: () => void;
  open: boolean;
  children?: React.ReactNode;
  titleId: string;
  save?: () => void;
  okIcon?: any;
  cancelIcon?: any;
  cancel?: () => void;
  okTextId?: string;
  cancelTextId?: string;
  sx?: any;
  showStep?: boolean;
  step?: number;
  totalStep?: number;
  handleStep?: (value: number) => void;
}

export default function SimplePopup(props: SimplePopupProps) {
  const {
    open,
    handleClose,
    children,
    titleId,
    save,
    cancel,
    cancelIcon,
    okIcon,
    okTextId,
    cancelTextId,
    sx,
    className,
    maxWidth,
    fullWidth,
    step,
    handleStep,
    totalStep,
    showStep,
  } = props;

  return (
    <BootstrapDialog
      className={className}
      open={open}
      aria-labelledby="draggable-dialog-title"
      maxWidth={maxWidth ? maxWidth : "sm"}
      fullWidth={fullWidth}
      PaperComponent={PaperComponent}
      sx={sx ? sx : {}}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography id="modal-modal-title" variant="h2" component="h2">
          <IntlMessages id={titleId} />
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {save && (
        <DialogActions
          sx={{
            pb: 5,
            px: 5,
          }}
        >
          <Button
            variant="outlined"
            onClick={cancel ? cancel : handleClose}
            className="text-color-08"
            sx={{
              borderRadius: "4px",
              border: "solid 1px rgba(0, 0, 0, 0.2)",
            }}
            startIcon={cancelIcon ? cancelIcon : <CancelIcon />}
          >
            <IntlMessages id={cancelTextId ? cancelTextId : "common.cancel"} />
          </Button>
          <Button variant="contained" onClick={save} color="primary" startIcon={okIcon ? okIcon : <SaveIcon />}>
            <IntlMessages id={okTextId ? okTextId : "common.save"} />
          </Button>
        </DialogActions>
      )}
      {showStep && (
        <DialogActions
          sx={{
            pb: 5,
            px: 5,
            justifyContent: "center",
          }}
        >
          <AppSvgIcon
            onClick={() => {
              step > 0 && handleStep(step - 1);
            }}
            className="cursor-pointer"
            color={step > 0 ? "action" : "disabled"}
          >
            material-solid:arrow_left
          </AppSvgIcon>
          <Box>
            {step + 1} of {totalStep}
          </Box>
          <AppSvgIcon
            onClick={() => {
              totalStep - 1 > step && handleStep(step + 1);
            }}
            className="cursor-pointer"
            color={step < totalStep - 1 ? "action" : "disabled"}
          >
            material-solid:arrow_right
          </AppSvgIcon>
        </DialogActions>
      )}
    </BootstrapDialog>
  );
}
