import Icon from "@mui/material/Icon";
import * as React from "react";

const AddBlueSvg = () => (
  <svg version="1.1" width="16px" height="16px" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(1 0 0 1 -1205 -15 )">
      <path
        d="M 16 8  C 15.995003455056015 3.583793293802063  12.416206706197935 0.004996544943980534  8 0  C 3.5817220013536524 0  0 3.581722001353653  0 8  C 0 12.418277998646348  3.5817220013536524 15.999999999999996  8 15.999999999999996  C 12.418277998646344 15.999999999999996  16 12.418277998646348  16 8  Z M 11.733333333333333 8.533333333333333  L 8.533333333333333 8.533333333333333  L 8.533333333333333 11.733333333333333  C 8.533333333333333 12.027885199909756  8.294551866576425 12.266666666666667  8 12.266666666666667  C 7.705448133423577 12.266666666666667  7.466666666666667 12.027885199909756  7.466666666666667 11.733333333333333  L 7.466666666666667 8.533333333333333  L 4.266666666666667 8.533333333333333  C 3.9721148000902438 8.533333333333333  3.7333333333333334 8.294551866576423  3.7333333333333334 8  C 3.7333333333333334 7.705448133423577  3.9721148000902438 7.466666666666667  4.266666666666667 7.466666666666667  L 7.466666666666667 7.466666666666667  L 7.466666666666667 4.266666666666667  C 7.466666666666667 3.972114800090244  7.705448133423577 3.733333333333334  8 3.733333333333334  C 8.294551866576425 3.733333333333334  8.533333333333333 3.972114800090244  8.533333333333333 4.266666666666667  L 8.533333333333333 7.466666666666667  L 11.733333333333333 7.466666666666667  C 12.027885199909758 7.466666666666667  12.266666666666667 7.705448133423577  12.266666666666667 8  C 12.266666666666667 8.294551866576423  12.027885199909758 8.533333333333333  11.733333333333333 8.533333333333333  Z "
        fillRule="nonzero"
        fill="#2e6ccb"
        stroke="#fff"
        transform="matrix(1 0 0 1 1205 15 )"
      />
    </g>
  </svg>
);

const AddSvg = () => (
  <svg version="1.1" width="16px" height="16px" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(1 0 0 1 -1205 -15 )">
      <path
        d="M 16 8  C 15.995003455056015 3.583793293802063  12.416206706197935 0.004996544943980534  8 0  C 3.5817220013536524 0  0 3.581722001353653  0 8  C 0 12.418277998646348  3.5817220013536524 15.999999999999996  8 15.999999999999996  C 12.418277998646344 15.999999999999996  16 12.418277998646348  16 8  Z M 11.733333333333333 8.533333333333333  L 8.533333333333333 8.533333333333333  L 8.533333333333333 11.733333333333333  C 8.533333333333333 12.027885199909756  8.294551866576425 12.266666666666667  8 12.266666666666667  C 7.705448133423577 12.266666666666667  7.466666666666667 12.027885199909756  7.466666666666667 11.733333333333333  L 7.466666666666667 8.533333333333333  L 4.266666666666667 8.533333333333333  C 3.9721148000902438 8.533333333333333  3.7333333333333334 8.294551866576423  3.7333333333333334 8  C 3.7333333333333334 7.705448133423577  3.9721148000902438 7.466666666666667  4.266666666666667 7.466666666666667  L 7.466666666666667 7.466666666666667  L 7.466666666666667 4.266666666666667  C 7.466666666666667 3.972114800090244  7.705448133423577 3.733333333333334  8 3.733333333333334  C 8.294551866576425 3.733333333333334  8.533333333333333 3.972114800090244  8.533333333333333 4.266666666666667  L 8.533333333333333 7.466666666666667  L 11.733333333333333 7.466666666666667  C 12.027885199909758 7.466666666666667  12.266666666666667 7.705448133423577  12.266666666666667 8  C 12.266666666666667 8.294551866576423  12.027885199909758 8.533333333333333  11.733333333333333 8.533333333333333  Z "
        fillRule="nonzero"
        fill="#fff"
        stroke="none"
        transform="matrix(1 0 0 1 1205 15 )"
      />
    </g>
  </svg>
);

interface AddIconIconProps {
  isBlue?: boolean;
}

const AddIcon = (props: AddIconIconProps) => {
  console.log(props.isBlue);
  if (props.isBlue) {
    return <Icon component={AddBlueSvg} />;
  } else {
    return <Icon component={AddSvg} />;
  }
};

export default AddIcon;
