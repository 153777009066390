import { Box, Collapse, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import AddBoxIcon from "components/Icons/AddBoxIcon";
import RemoveBoxIcon from "components/Icons/RemoveBoxIcon";
import React, { useMemo, useState } from "react";
import { CollapseColumn } from "types/models/Common";

interface MyTableRowProps<T> {
  row: T;
  columns: CollapseColumn[];
  renderChildren?: (row: T) => React.ReactNode;
  onOpen?: (row: T) => void;
  collapse?: boolean;
  defaultOpen?: boolean;
}

const MyTableRow = <T extends any>(props: MyTableRowProps<T>) => {
  const { row, columns, renderChildren, onOpen, collapse, defaultOpen } = props;

  const [open, setOpen] = useState((collapse && defaultOpen) || false);

  const parentData = useMemo(() => {
    if (!row) return <></>;
    const cells = [];
    columns.forEach((c) => {
      if (c.format) {
        if (c.collapse) {
          if (collapse) {
            cells.push(
              <TableCell
                key={`${row["id"]}_${c.id}`}
                className={`table_cell table_body_cell ${open ? "collapse_open" : ""}`}
                align={c.align ? c.align : "left"}
              >
                <Box className="flex items-center">
                  <IconButton
                    aria-label="expand row"
                    sx={{ padding: "2px" }}
                    size="small"
                    onClick={() => handleChildrenOpen(row)}
                  >
                    {open ? (
                      <AppSvgIcon size={16} color="action">
                        {"material-outline:keyboard_arrow_up"}
                      </AppSvgIcon>
                    ) : (
                      <AppSvgIcon size={16} color="action">
                        {"material-outline:keyboard_arrow_down"}
                      </AppSvgIcon>
                    )}
                  </IconButton>
                  <Box sx={{ lineHeight: "20px" }} className="font-bold">
                    {c.format && c.format(row[c.id], row)}
                  </Box>
                </Box>
              </TableCell>
            );
          } else {
            cells.push(
              <TableCell
                key={`${row["id"]}_${c.id}`}
                className="table_cell table_body_cell"
                align={c.align ? c.align : "left"}
              >
                {c.format && c.format(row[c.id], row)}
              </TableCell>
            );
          }
        } else {
          cells.push(
            <TableCell
              key={`${row["id"]}_${c.id}`}
              className={"table_cell table_body_cell"}
              align={c.align ? c.align : "left"}
            >
              {c.format && c.format(row[c.id], row)}
            </TableCell>
          );
        }
      } else if (c.collapse) {
        if (collapse) {
          cells.push(
            <TableCell
              key={`${row["id"]}_${c.id}`}
              className={`table_cell table_body_cell ${open ? "collapse_open" : ""}`}
              align={c.align ? c.align : "left"}
            >
              <Box className="flex items-center">
                <IconButton
                  aria-label="expand row"
                  sx={{ padding: "2px" }}
                  size="small"
                  onClick={() => handleChildrenOpen(row)}
                >
                  {open ? (
                    // <AppSvgIcon size={16} color="action">
                    //   {"material-outline:keyboard_arrow_up"}
                    // </AppSvgIcon>
                    <RemoveBoxIcon />
                  ) : (
                    // <AppSvgIcon size={16} color="action">
                    //   {"material-outline:keyboard_arrow_down"}
                    // </AppSvgIcon>
                    <AddBoxIcon />
                  )}
                </IconButton>
                <Box sx={{ lineHeight: "20px" }} className="font-bold">
                  {row[c.id]}
                </Box>
              </Box>
            </TableCell>
          );
        } else {
          cells.push(
            <TableCell
              key={`${row["id"]}_${c.id}`}
              className="table_cell table_body_cell"
              align={c.align ? c.align : "left"}
            >
              {row[c.id]}
            </TableCell>
          );
        }
      } else {
        cells.push(
          <TableCell
            key={`${row["id"]}_${c.id}`}
            className="table_cell table_body_cell"
            align={c.align ? c.align : "left"}
          >
            {row[c.id]}
          </TableCell>
        );
      }
    });
    return cells;
  }, [row, columns, open, collapse]);

  const handleChildrenOpen = async (row: T) => {
    if (collapse) {
      setOpen(!open);
      onOpen && onOpen(row);
    }
  };

  return (
    <React.Fragment>
      <TableRow
        className={`${open ? "parent-open" : ""}`}
        key={`row_${row["id"]}`}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        {parentData}
      </TableRow>
      <TableRow className={`${open ? "child-open" : ""}`}>
        <TableCell
          key={`collapse_${row["id"]}`}
          style={{
            display: open ? "table-cell" : "none",
          }}
          className="table_cell table_body_cell collapse_td"
          colSpan={columns.length}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {open && <Box>{renderChildren && renderChildren(row)}</Box>}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default MyTableRow;
