import IntlMessages from "@crema/utility/IntlMessages";
import { Box } from "@mui/material";
import Icon from "@mui/material/Icon";
import * as React from "react";
import { textColor } from "shared/constants/AppConst";

const NoDataSvg = () => (
  <Box>
    <svg width="200" height="186" viewBox="0 0 200 186" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="50%" y1="100%" x2="50%" y2=".001%" id="vqvgczzh2a">
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#F1F1F1" offset="100%" />
        </linearGradient>
        <linearGradient x1="50.001%" y1="59.349%" x2="50.001%" y2="-.001%" id="ma3ljw11ib">
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#F4F4F4" offset="100%" />
        </linearGradient>
        <linearGradient x1="50.002%" y1=".002%" x2="50.002%" y2="56.583%" id="7lawr3q3lc">
          <stop stopColor="#E0E0E0" offset="0%" />
          <stop stopColor="#C5C5C5" offset="100%" />
        </linearGradient>
        <linearGradient x1="50.002%" y1=".002%" x2="50.002%" y2="56.583%" id="u7co3zy4sd">
          <stop stopColor="#E0E0E0" offset="0%" />
          <stop stopColor="#C5C5C5" offset="100%" />
        </linearGradient>
        <linearGradient x1="50.002%" y1=".002%" x2="50.002%" y2="56.583%" id="4d2v05wv2e">
          <stop stopColor="#E0E0E0" offset="0%" />
          <stop stopColor="#C5C5C5" offset="100%" />
        </linearGradient>
        <linearGradient x1="50.002%" y1=".002%" x2="50.002%" y2="56.583%" id="wvrpz42o1f">
          <stop stopColor="#E0E0E0" offset="0%" />
          <stop stopColor="#C5C5C5" offset="100%" />
        </linearGradient>
        <linearGradient x1="50.002%" y1=".002%" x2="50.002%" y2="56.583%" id="grma10of5g">
          <stop stopColor="#E0E0E0" offset="0%" />
          <stop stopColor="#C5C5C5" offset="100%" />
        </linearGradient>
        <linearGradient x1="50.002%" y1=".002%" x2="50.002%" y2="56.583%" id="aa4oljhueh">
          <stop stopColor="#E0E0E0" offset="0%" />
          <stop stopColor="#C5C5C5" offset="100%" />
        </linearGradient>
        <linearGradient x1="50.002%" y1=".002%" x2="50.002%" y2="56.583%" id="cnqfmytmpi">
          <stop stopColor="#E0E0E0" offset="0%" />
          <stop stopColor="#C5C5C5" offset="100%" />
        </linearGradient>
        <linearGradient x1="50.002%" y1=".002%" x2="50.002%" y2="56.583%" id="rzsavieicj">
          <stop stopColor="#E0E0E0" offset="0%" />
          <stop stopColor="#C5C5C5" offset="100%" />
        </linearGradient>
        <linearGradient x1="50.002%" y1=".002%" x2="50.002%" y2="56.583%" id="lfisn5gt2k">
          <stop stopColor="#E0E0E0" offset="0%" />
          <stop stopColor="#C5C5C5" offset="100%" />
        </linearGradient>
        <linearGradient x1="50.01%" y1="-.004%" x2="50.01%" y2="56.569%" id="83b9gy5mvn">
          <stop stopColor="#F7F7F7" offset="0%" />
          <stop stopColor="#C6C6C6" offset="100%" />
        </linearGradient>
        <filter x="-27.3%" y="-25%" width="154.5%" height="150%" filterUnits="objectBoundingBox" id="saq9g7suul">
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.116737632 0" in="shadowBlurOuter1" />
        </filter>
        <path d="M49.999 60h-36a4 4 0 0 1-4-4V16a4 4 0 0 1 4-4h36a4 4 0 0 1 4 4v40a4 4 0 0 1-4 4z" id="orhoozk8km" />
      </defs>
      <g transform="translate(0 .73)" fill="none" fillRule="evenodd">
        <path
          d="M158.367 15.967v89.81H41.636c.194-44.662.374-51.53.571-96.209h26.144c.049 7.967.049 11.428 0 11.558v.016s.033-.016.049-.032v-.049a4.231 4.231 0 0 1 4.237-4.244 4.231 4.231 0 0 1 4.238 4.244v2.514h18.677V2.27h16.756v30.707c-.016.065-.016.113-.016.18v10.333a2.736 2.736 0 0 0 2.738 2.743 2.733 2.733 0 0 0 2.723-2.743v-8.048c5.264-.016 9.079-.033 11.604-.033 2.526 0 3.766.017 3.88.033.406-.049 0-6.237 0-6.237h7.97v7.33a2.007 2.007 0 1 0 4.011 0v-20.57h13.149z"
          fill="url(#vqvgczzh2a)"
          fillRule="nonzero"
        />
        <ellipse fill="url(#ma3ljw11ib)" fillRule="nonzero" cx="100" cy="134.738" rx="100" ry="47.532" />
        <g transform="translate(20.831)" fillRule="nonzero">
          <path
            d="M39.19 6.29a3.146 3.146 0 0 1 0-6.29 3.146 3.146 0 0 1 0 6.29zm0-4.538a1.397 1.397 0 0 0 0 2.792 1.397 1.397 0 0 0 0-2.792z"
            fill="url(#7lawr3q3lc)"
          />
          <ellipse fill="url(#u7co3zy4sd)" cx="39.624" cy="56.095" rx="2.267" ry="2.271" />
          <ellipse fill="url(#4d2v05wv2e)" cx="119.834" cy="73.625" rx="1.873" ry="1.876" />
          <ellipse fill="url(#wvrpz42o1f)" cx="140.838" cy="24.766" rx="1.615" ry="1.617" />
          <ellipse fill="url(#grma10of5g)" cx="156.108" cy="58.523" rx="2.232" ry="2.235" />
          <path
            d="M99.56 20.561a3.146 3.146 0 0 1 0-6.29 3.146 3.146 0 0 1 0 6.29zm0-4.538a1.397 1.397 0 0 0 0 2.791 1.397 1.397 0 0 0 0-2.791z"
            fill="url(#7lawr3q3lc)"
          />
          <path
            d="M7.017 4.951H.957a.958.958 0 0 1 0-1.917h6.06a.958.958 0 0 1 0 1.917z"
            fill="url(#aa4oljhueh)"
            transform="translate(0 80.138)"
          />
          <path
            d="M2.992 6.99V.996a.995.995 0 1 1 1.99 0V6.99a.995.995 0 1 1-1.99 0z"
            fill="url(#cnqfmytmpi)"
            transform="translate(0 80.138)"
          />
          <path d="M7.038 44.276H3.103a.62.62 0 0 1 0-1.242h3.935a.62.62 0 0 1 0 1.242z" fill="url(#rzsavieicj)" />
          <path
            d="M4.423 45.6v-3.891a.648.648 0 1 1 1.294 0v3.89a.648.648 0 0 1-.647.648.65.65 0 0 1-.647-.648z"
            fill="url(#lfisn5gt2k)"
          />
        </g>
        <path
          d="M68.001 85.176c0 11.398 6.1 21.93 16 27.63 9.901 5.7 22.1 5.7 32 0 9.9-5.7 16-16.232 16-27.63 0-17.621-14.327-31.905-32-31.905-17.673 0-32 14.284-32 31.905z"
          fill="#EEE"
          fillRule="nonzero"
        />
        <g fillRule="nonzero" transform="translate(68.001 39.27)">
          <use fill="#000" filter="url(#saq9g7suul)" />
          <use fill="#FDFDFD" />
        </g>
        <path
          stroke="#D8D8D8"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M86 64.27h28M86 74.27h28M86 84.27h6"
        />
        <path
          d="M43.077 7.69h-3.921v-.554C39.156 3.196 35.967 0 32.03 0c-3.934 0-7.124 3.194-7.124 7.136v.554H20.92a.923.923 0 0 0-.922.924v6.4c0 .508.412.923.922.923h22.156a.923.923 0 0 0 .922-.923v-6.4a.922.922 0 0 0-.922-.924zM32 9.47a2.521 2.521 0 0 1-2.52-2.524 2.52 2.52 0 1 1 5.04 0A2.521 2.521 0 0 1 32 9.469z"
          fill="url(#83b9gy5mvn)"
          fillRule="nonzero"
          transform="translate(68.001 39.27)"
        />
        <g fillRule="nonzero">
          <path
            d="M98.995 95.451c0 6.06 4.927 10.972 11.004 10.972 6.078 0 11.004-4.912 11.004-10.972S116.077 84.48 110 84.48c-6.077 0-11.004 4.912-11.004 10.971z"
            fill="#EEE"
          />
          <path
            d="M124.386 95.27c0-7.856-6.388-14.226-14.274-14.226-7.872-.006-14.26 6.364-14.26 14.225 0 7.856 6.388 14.226 14.267 14.226 7.885 0 14.267-6.37 14.267-14.226zm-14.267 10.67c-5.911 0-10.696-4.777-10.696-10.67 0-5.894 4.791-10.671 10.696-10.671 5.917 0 10.702 4.777 10.702 10.67 0 5.894-4.791 10.671-10.702 10.671z"
            fill="#D8D8D8"
          />
          <path
            d="M103.479 96.01h1.962c0-3.236 2.64-5.869 5.879-5.869v-1.956c-4.32 0-7.841 3.51-7.841 7.824z"
            fill="#D8D8D8"
          />
        </g>
        <path
          d="m124.657 105.514 6.313 6.294a3.188 3.188 0 0 1 0 4.527 3.221 3.221 0 0 1-4.54 0l-6.34-6.323a31.916 31.916 0 0 0 4.567-4.498z"
          fill="#D8D8D8"
          fillRule="nonzero"
        />
      </g>
    </svg>
    <Box
      sx={{
        color: textColor[45],
        textAlign: "center",
      }}
    >
      <IntlMessages id="common.no.dada" />
    </Box>
  </Box>
);

const NoDataIcon: React.FunctionComponent = () => {
  return <Icon component={NoDataSvg} />;
};

export default NoDataIcon;
