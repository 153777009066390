import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import { borderColor, borderRadius, textColor } from "shared/constants/AppConst";

import { AcademicStats } from "./GeneralStatsModel";

interface GeneralStatsProps {
  stats: AcademicStats;
}

const GeneralStats: React.FC<GeneralStatsProps> = ({ stats }) => {
  const navigate = useNavigate();

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: stats.badgeColor,
    background: stats.badgeBgColor,
    boxShadow: "none",
  }));

  const titleLinkTo = (type) => {
    if (type) {
      navigate(`/workspace/123/${type}/worksheet`);
    } else {
      navigate("/products/123/deliverables/Design Controls/index");
    }
  };

  const subTitleLinkTo = () => {};

  return (
    <Box
      sx={{
        position: "relative",
        width: 459,
        height: "88px",
        border: `1px solid  ${borderColor.dividerColor}`,
        borderRadius: borderRadius.main,
        paddingLeft: 5,
        marginRight: 3,
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0 3px 20px 0 #e5e5e5;",
        },
      }}
      className="flex items-center"
    >
      <Box
        sx={{
          mr: 3,
        }}
      >
        {stats.icon}
      </Box>
      <Box
        sx={{
          width: {
            xs: "calc(100% - 56px)",
            lg: "calc(100% - 70px)",
            xl: "calc(100% - 76px)",
          },
        }}
        className="flex items-center justify-between"
      >
        <Box sx={{ overflow: "hidden" }}>
          <Box
            component="h3"
            onClick={() => {
              titleLinkTo(stats.taskType);
            }}
            className="text-not-wrap text-color-08"
            sx={{
              fontSize: 14,
              fontWeight: "bold",
              height: "22px",
              color: textColor[85],
              lineHeight: "22px",
              verticalAlign: "center",
              cursor: "pointer",
            }}
          >
            {stats.title}
          </Box>
          <Box
            component="p"
            onClick={() => {
              subTitleLinkTo();
            }}
            className="text-not-wrap text-color-04"
            sx={{
              fontSize: 12,
              color: textColor[45],
              cursor: "pointer",
              height: "20px",
              lineHeight: "20px",
              verticalAlign: "center",
              display: stats.badgeColor ? "none" : "inline-block",
            }}
          >
            {stats.subTitle}
          </Box>
          {/* <Box
            sx={{
              display: stats.badgeColor ? "flex" : "none",
              alignItems: "center",
            }}
          >
            <Box
              component="p"
              onClick={() => {
                subTitleLinkTo();
              }}
              className="text-color-04"
              sx={{
                fontSize: 12,
                color: textColor[45],
                paddingRight: "5px",
              }}
            >
              Stage:
            </Box>
            <Item>{stats.subTitle}</Item>
          </Box> */}
        </Box>
      </Box>
      <Box sx={{ position: "absolute", right: "16px", top: "16px", display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "15px", display: "inline-block", marginRight: "8px" }}>{stats.startIcon}</Box>
        <MoreHorizIcon sx={{ color: textColor[65] }} />
      </Box>
    </Box>
  );
};

export default GeneralStats;
