import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import TreeItem, { TreeItemContentProps, TreeItemProps, useTreeItem } from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { Box, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import Icons from "components/Icons";
import TaskItemIcon from "components/Icons/TaskItemIcon";
import * as React from "react";
import { bgColor, textColor } from "shared/constants/AppConst";

import StyledTreeItem from "./StyledTreeItem";

const { RemoveBoxIcon, AddBoxIcon, FolderIcon } = Icons;

const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

  const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
    useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Box onClick={handleSelectionClick} sx={{ width: "16px", height: "16px", mt: "2px" }}>
        {icon ? <FolderIcon /> : <TaskItemIcon />}
      </Box>
      <Tooltip title={label} placement="top">
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
          sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
          {label}
        </Typography>
      </Tooltip>
    </div>
  );
});

const CustomTreeItem = (props: TreeItemProps) => <TreeItem ContentComponent={CustomContent} {...props} />;

interface MyTreeProps {
  data: any;
  sx?: any;
  defaultExpanded?: Array<string>;
  onNodeSelect: (event: object, nodeIds: any) => void;
  selected?: any;
  useStyledTree?: boolean;
  showMoreIcon?: boolean;
  // onMoreIconClick?: (event: React.MouseEvent<HTMLElement>) => void;
  showFolder?: boolean;
  showCheckbox?: boolean;
  multiSelect?: boolean;
  checkedNodeIds?: Array<string>;
  showToolTip?: boolean;
  showNodeStatus?: boolean;
}

export default function MyTree(props: MyTreeProps) {
  const [expanded, setExpanded] = React.useState<any>([]);
  const [checkedNodeIds, setCheckedNodeIds] = React.useState<string[]>(
    props?.showCheckbox ? props?.checkedNodeIds : []
  );

  React.useEffect(() => {
    let keyArr: any[] = [];
    if (props?.defaultExpanded?.length > 0) {
      keyArr = props?.defaultExpanded;
    } else if (props?.data.length > 0) {
      const getAllKey = (tree: any) => {
        for (let item of tree) {
          if (item.items && item.items.length > 0) {
            keyArr.push(item.id);
            getAllKey(item.items);
          }
        }
      };
      getAllKey(props.data);
    }
    setExpanded([...keyArr]);
  }, [props.data, props.defaultExpanded]);

  React.useEffect(() => {
    if (props?.checkedNodeIds) {
      setCheckedNodeIds(props?.checkedNodeIds);
    }
  }, [props?.checkedNodeIds]);

  const renderTree = (nodes: any) => (
    <CustomTreeItem
      nodeId={nodes.id}
      label={nodes.value}
      disabled={nodes.disabled}
      key={nodes.id}
      collapseIcon={<RemoveBoxIcon />}
      expandIcon={<AddBoxIcon />}
      sx={{
        ".MuiTreeItem-label": {
          color: nodes?.color ? nodes.color : textColor[85],
        },
      }}
      className={nodes.items ? "not-last-child" : "last-child"}
    >
      {Array.isArray(nodes.items) ? nodes.items.map((node) => renderTree(node)) : null}
    </CustomTreeItem>
  );

  const renderStyledTree = (
    node: any,
    showMoreIcon: boolean,
    // onMoreIconClick: (event: React.MouseEvent<HTMLElement>) => void,
    showFolder: boolean,
    expanded,
    showCheckbox: boolean,
    checkedNodeIds,
    showNodeStatus
  ) => (
    <StyledTreeItem
      expanded={expanded}
      showFolder={showFolder}
      isBold={node.isBold}
      key={node?.id || ""}
      nodeId={node?.id || ""}
      labelText={node?.value || ""}
      node={node}
      disabled={node.disabled}
      showMoreIcon={showMoreIcon}
      labelIcon={node.labelIcon}
      // onMoreIconClick={onMoreIconClick}
      showCheckbox={showCheckbox && !node.items}
      checkedNodeIds={checkedNodeIds}
      showNodeStatus={showNodeStatus}
      sx={{
        ".MuiTreeItem-label span": {
          color: node?.color ? node.color : textColor[85],
        },
      }}
    >
      {Array.isArray(node.items)
        ? node.items.map((child) =>
            renderStyledTree(
              child,
              showMoreIcon,
              // onMoreIconClick,
              showFolder,
              expanded,
              showCheckbox,
              checkedNodeIds,
              showNodeStatus
            )
          )
        : null}
    </StyledTreeItem>
  );

  const onNodeToggle = (event: object, nodeIds: any) => {
    setExpanded(nodeIds);
  };

  return (
    <TreeView
      aria-label="rich object"
      defaultCollapseIcon={<ArrowDropDownIcon />}
      expanded={expanded}
      disabledItemsFocusable={true}
      onNodeSelect={props.onNodeSelect}
      onNodeToggle={onNodeToggle}
      defaultExpandIcon={<ArrowRightIcon />}
      selected={props.selected}
      multiSelect={props.multiSelect}
      sx={{
        "& .MuiTreeItem-label": {
          padding: props?.showCheckbox ? "0px" : "5px 4px",
          color: textColor[85],
        },
        "& .MuiTreeItem-content.Mui-selected .MuiTreeItem-label": {
          color: "#2e6ccb",
        },
        "& .MuiTreeItem-content.Mui-selected .MuiTreeItem-label span": {
          color: "#2e6ccb",
        },
        "& .MuiTreeItem-content.Mui-focused": {
          background: (theme) => theme.palette.background.paper,
          borderRadius: "4px",
        },
        "& .MuiTreeItem-content.Mui-selected, .MuiTreeItem-content.Mui-selected.Mui-focused": {
          background: bgColor.main,
          borderRadius: "4px",
          borderRight: "none !important",
        },
        marginRight: 5,
        ...props.sx,
      }}
    >
      {props.data.map((item) => {
        if (props.useStyledTree) {
          return renderStyledTree(
            item,
            props?.showMoreIcon,
            // props?.onMoreIconClick,
            props?.showFolder,
            expanded,
            props?.showCheckbox,
            checkedNodeIds,
            props?.showNodeStatus
          );
        } else {
          return renderTree(item);
        }
      })}
    </TreeView>
  );
}
