import { Box, IconButton, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import Icons from "components/Icons";
import * as React from "react";

import { VariantSelectItemModel } from "../VariantSelectModel";

const { RemoveBoxIcon, AddBoxIcon } = Icons;

interface TreeOptionProps {
  dataItem: VariantSelectItemModel;
  selected?: string;
  sx?: any;
  defaultExpanded?: boolean;
  renderChildren?: (dataItem: VariantSelectItemModel, selected?: string) => void;
  onClick?: (dataItem: VariantSelectItemModel) => void;
}

export default function TreeOption(props: TreeOptionProps) {
  const { dataItem, selected, sx, defaultExpanded, renderChildren, onClick } = props;
  const [open, setOpen] = React.useState<boolean>(defaultExpanded);

  const listItemStyle = {
    paddingLeft:
      dataItem.children?.length > 0
        ? (dataItem?.level ? 4 * dataItem.level : 0) * 4 + "px !important"
        : (dataItem?.level ? 4 * dataItem.level : 4) * 4 + 20 + "px !important",
    ml: "",
    p: 0,
    backgroundColor: selected === dataItem.id ? "rgba(46, 108, 203, 0.08)" : "none",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    cursor: "pointer",
    "& .MuiListItemIcon-root": {
      minWidth: "auto",
    },
  };
  return (
    <>
      <ListItem
        sx={
          sx
            ? {
                ...sx,
                ...listItemStyle,
              }
            : listItemStyle
        }
        key={`TreeOption_MenuItem_${dataItem.id}`}
        value={dataItem.id}
      >
        {dataItem.children?.length > 0 ? (
          <ListItemIcon>
            <IconButton
              aria-label="expand row"
              size="small"
              hidden={dataItem.children?.length > 0 ? false : true}
              sx={{ mr: 1, p: 0, mt: "2px" }}
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? <RemoveBoxIcon /> : <AddBoxIcon />}
            </IconButton>
          </ListItemIcon>
        ) : (
          <></>
        )}

        <ListItemText
          onClick={() => {
            onClick && onClick(dataItem);
          }}
        >
          {dataItem.label}
        </ListItemText>
      </ListItem>
      {open && dataItem.children && dataItem.children.map((x) => renderChildren(x, selected))}
    </>
  );
}
