import IntlMessages from "@crema/utility/IntlMessages";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SimplePopup from "components/SimplePopup";
import * as React from "react";
import { textColor } from "shared/constants/AppConst";

const Container = styled(Box)(({ theme }) => ({
  ".tip_container": {
    margin: "16px 0",
    color: textColor[45],
    fontSize: "14px",
    backgroundColor: "#f2f4f7",
    padding: "16px 20px",
  },
}));

interface BranchModalProps {
  handleClose: () => void;
  open: boolean;
}

export default function BranchModal(props: BranchModalProps) {
  const { open, handleClose } = props;

  const [newRelease, setNewRelease] = React.useState<string>("");

  const save = () => {};

  return (
    <SimplePopup
      handleClose={handleClose}
      open={open}
      titleId="common.create.variant"
      okTextId="common.ok"
      save={save}
      sx={{
        "& .MuiDialogContent-root": {
          padding: 0,
        },
      }}
    >
      <Container>
        <Box sx={{ display: "flex", alignItems: "center", my: 4, px: 5 }}>
          <Box sx={{ color: textColor[85] }}>
            <IntlMessages id="products.form.base.variant" />:
          </Box>
          <Box sx={{ color: textColor[85], ml: 2, fontWeight: "600" }}>Main</Box>
          <Box sx={{ color: textColor[85], ml: 4 }}>
            <IntlMessages id="product.saveAsNew.baseRelease" />:
          </Box>
          <Box sx={{ color: textColor[85], ml: 2, fontWeight: "600" }}>R4.0 (Head)</Box>
        </Box>
        <Box className="tip_container flex">
          <ErrorOutlineOutlinedIcon
            sx={{
              fontSize: "1.25rem",
              verticalAlign: "sub",
              marginRight: 1,
            }}
          />
          <Typography>
            <IntlMessages id="products.branch.release.tip" />
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", my: 4, px: 5 }}>
          <Box sx={{ color: textColor[85] }}>
            <IntlMessages id="product.saveAsNew.new.variant" />:
          </Box>
          <TextField
            value={newRelease}
            onChange={(e) => {
              setNewRelease(e.target.value);
            }}
            sx={{ ml: 2 }}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", my: 4, px: 5 }}>
          <Box sx={{ color: textColor[85] }}>
            <IntlMessages id="common.keep.relationship.with.base.release" />:
          </Box>
          <Box sx={{ color: textColor[85], ml: 2, fontWeight: "600" }}>Branch from</Box>
        </Box>
      </Container>
    </SimplePopup>
  );
}
