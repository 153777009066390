import { Avatar, Box, Link } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import { ListModel } from "components/CustomTable/CustomTableModel";
import FillProductIcon from "components/Icons/FillProductIcon";
import FillTaskIcon from "components/Icons/FillTaskIcon";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppState } from "redux/store";
import { statusBgColor } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

import AddTask from "./AddTask";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export default function Task() {
  const navigate = useNavigate();

  const moduleList = useSelector<AppState, AppState["workspaces"]>(({ workspaces }) => workspaces).moduleList;

  console.log(moduleList);

  const columns: Column[] = [
    {
      id: "task",
      label: "workspace.column.tasks.version",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        console.log(record);
        return (
          <Box className="flex items-start">
            <Box sx={{ width: "16px", marginTop: "2px" }}>
              <FillTaskIcon />
            </Box>
            <Link
              sx={{ ml: 1.5, cursor: "pointer" }}
              underline="none"
              onClick={() => {
                navigate(
                  `/products/123/deliverables/${record.module}/${record.processId}/${record.type}/${record.version}/worksheet`
                );
              }}
            >
              {value}
            </Link>
          </Box>
        );
      },
    },
    {
      id: "product",
      label: "workspace.column.product",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex items-start">
            <Box sx={{ width: "16px", marginTop: "2px" }}>
              <FillProductIcon />
            </Box>
            <Link
              sx={{ ml: 1.5, cursor: "pointer" }}
              underline="none"
              onClick={() => {
                navigate("/products/123/deliverables/Design Controls/index");
              }}
            >
              {value}
            </Link>
          </Box>
        );
      },
    },
    {
      id: "status",
      label: "workspace.column.status",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex items-center">
            <Box
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                background: statusBgColor[value.toLowerCase()],
              }}
            />
            <Box sx={{ ml: 1.5 }}>{value}</Box>
          </Box>
        );
      },
    },
    {
      id: "owner",
      label: "workspace.column.owner",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex items-center">
            {/* <Avatar
              sx={{ width: "20px", height: "20px" }}
              className="flex items-center justify-center"
              src="/assets/images/message.png"
            /> */}
            <AppSvgIcon size={16} color="action">
              {"material-solid:account_circle"}
            </AppSvgIcon>
            <Box sx={{ ml: 1.5 }}>{value}</Box>
          </Box>
        );
      },
    },
  ];

  const initRows = [
    {
      id: 1,
      task: "TK#001 System Hazard Analysis (V1.0)",
      product: "Thermometers R1.0",
      status: "Open",
      owner: "Aaron",
      type: "sha",
      module: "Risk Management",
      processId: "System Hazard Analysis",
      version: "100",
    },
    {
      id: 7,
      task: "TK#002 Use Risk Analysis (V1.0)",
      product: "Thermometers R1.0",
      status: "Open",
      owner: "Aaron",
      type: "ura",
      module: "Risk Management",
      processId: "Use Risk Analysis",
      version: "100",
    },
    {
      id: 5,
      task: "TK#014 Design FMEA (V1.1)",
      product: "Thermometers R2.0",
      status: "Develop",
      owner: "Tom",
      type: "df",
      module: "Risk Management",
      processId: "Design FMEA",
      version: "101",
    },
    {
      id: 3,
      task: "TK#101 User Needs (V1.1)",
      product: "Thermometers R1.0",
      status: "Draft",
      owner: "Fred",
      type: "und",
      module: "Design Controls",
      processId: "User Needs",
      version: "101",
    },
    {
      id: 4,
      task: "TK#102 Design Input Requirements (V1.1)",
      product: "Thermometers R1.0",
      status: "Draft",
      owner: "Mike",
      type: "dir",
      module: "Design Controls",
      processId: "Design Input Requirements",
      version: "101",
    },
    {
      id: 2,
      task: "TK#025 Test Cases (V1.1)",
      product: "Thermometers R1.0",
      status: "Draft",
      owner: "Fred",
      type: "tc",
      module: "Design Controls",
      processId: "Test Cases",
      version: "101",
    },
    {
      id: 6,
      task: "TK#023 Design Control Traceability Matrix(V1.1)",
      product: "Thermometers R1.0",
      status: "Draft",
      owner: "Fred",
      type: "tm",
      module: "Design Controls",
      processId: "Design Control Traceability Matrix",
      version: "101",
    },
  ];

  const [searchText, setSearchText] = React.useState("");

  const [filter1, setFilter1] = React.useState<any>("");
  const [filterList1] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter1",
    },
  ]);
  const [filter2, setFilter2] = React.useState<any>("");
  const [filterList2] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter2",
    },
  ]);
  const [rows, setRows] = React.useState<any[]>(initRows);
  const [openTaskModal, setOpenTaskModal] = React.useState<boolean>(false);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = initRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  return (
    <>
      <CustomTable
        toolBar={{
          showSearch: true,
          searchText: searchText,
          requestSearch: requestSearch,
          saveFilter: () => {
            console.log("save filter");
          },
          filters: [
            {
              value: filter1,
              list: filterList1,
              setValue: setFilter1,
            },
            {
              value: filter2,
              list: filterList2,
              setValue: setFilter2,
            },
          ],
          buttons: [
            {
              id: "common.customize",
              icon: TableHeaderButtonsIconType.SETTING,
              onChange: () => {},
            },
            {
              id: "workspace.button.task",
              icon: TableHeaderButtonsIconType.ADD,
              type: "text",
              isBlue: true,
              color: "#2e6ccb",
              onChange: () => setOpenTaskModal(true),
            },
          ],
        }}
        columns={columns}
        rows={rows}
      />
      {<AddTask open={openTaskModal} handleClose={() => setOpenTaskModal(false)} />}
    </>
  );
}
