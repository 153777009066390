import IntlMessages from "@crema/utility/IntlMessages";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import React, { useState } from "react";
import { textColor } from "shared/constants/AppConst";

import RichEditorTemplate from "../../Report/RichEditorTemplate";
import { CheckItemModel, ReferenceItem } from "../model";

const referenceAvailableList: ReferenceItem[] = [
  {
    id: 1,
    key: "TK#001 System Hazard Analysis (V1.0)",
    name: "TK#001 System Hazard Analysis (V1.0)",
    url: "/products/123/deliverables/Risk Management/System Hazard Analysis/sha/100/worksheet",
  },
  {
    id: 2,
    key: "TK#023 Design Control Traceability Matrix V1.1",
    name: "TK#023 Design Control Traceability Matrix V1.1",
    url: "/products/123/deliverables/Design Controls/Design Control Traceability Matrix/tm/101/worksheet",
  },
];

const Container = styled(Card)(({ theme }) => ({}));

interface CheckItemProps {
  row: CheckItemModel;
}
const CheckItem = (props: CheckItemProps) => {
  const { row } = props;

  const [addReference, setAddReference] = useState<boolean>(false);

  const [checked, setChecked] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");

  const [referenceSelectList, setReferenceSelectList] = useState<ReferenceItem[]>([...referenceAvailableList]);

  const [referenceSelect, setReferenceSelect] = useState<ReferenceItem>(null);

  const [referenceList, setReferenceList] = useState<ReferenceItem[]>([]);

  const handleReferenceAdd = (value: number) => {
    const a = referenceAvailableList.find((x) => x.id === value);
    if (a) {
      setReferenceSelect(a);
    }
  };

  const handleRemoveReference = (value: number) => {
    const list = referenceList.filter((x) => x.id !== value);
    setReferenceList(list);

    const nList = referenceAvailableList.filter((x) => list.findIndex((l) => l.id === x.id) === -1);
    setReferenceSelectList(nList);
  };

  // const handleCommentChange = (event) => {
  //   setComment(event.target.value);
  // };

  const handleSave = () => {
    let newReferenceList = [...referenceList];
    if (referenceSelect) {
      newReferenceList.push(referenceSelect);
      setReferenceList(newReferenceList);

      const nList = referenceSelectList.filter((x) => x.id !== referenceSelect.id);
      setReferenceSelectList(nList);
    }

    setAddReference(false);
  };

  const handleCancel = () => {
    setReferenceSelect(null);
    setAddReference(false);
  };

  return (
    <TableRow key={`check_row_${row.id}`} className={"check_row"}>
      <TableCell sx={{ width: "5%" }} className="table_cell table_body_cell table_cell_id" align={"center"}>
        {row.id}
      </TableCell>
      <TableCell
        sx={{ width: "30%", textAlign: "left" }}
        className="table_cell table_body_cell table_cell_questions"
        align={"center"}
      >
        {row.questions}
      </TableCell>
      <TableCell sx={{ width: "10%" }} className="table_cell table_body_cell table_cell_checkbox" align={"center"}>
        <FormGroup>
          <FormControlLabel
            className="justify-center"
            control={
              <Checkbox
                sx={{
                  "&.MuiCheckbox-root": {
                    padding: "2px",
                  },
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                }}
                name={`rowChecked_${row.id}`}
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
                checked={checked}
              />
            }
            label=""
          />
        </FormGroup>
      </TableCell>

      <TableCell sx={{ width: "55%" }} className="table_cell table_body_cell table_cell_comments" align={"center"}>
        <Box className="comments_box">
          {/* <TextField multiline fullWidth maxRows={3} value={comment} onChange={handleCommentChange} /> */}
          <RichEditorTemplate
            data={comment}
            inEdit={true}
            onChange={(data) => {
              setComment(data);
            }}
          />
        </Box>
      </TableCell>
      {/*
      <TableCell sx={{ width: "25%" }} className="table_cell table_body_cell table_cell_references" align={"center"}>
        <Box className="references">
          <Box className="reference-list">
            {referenceList.map((x) => {
              return (
                <Box key={`reference_box_${x.id}`} className="flex items-center justify-between">
                  <Link href={x.url} underline="none">
                    {x.name}
                  </Link>
                  <IconButton
                    onClick={() => {
                      handleRemoveReference(Number(x.id));
                    }}
                  >
                    <AppSvgIcon color="action">{"material-solid:delete"}</AppSvgIcon>
                  </IconButton>
                </Box>
              );
            })}
          </Box>
          <Box className="references-line flex justify-between items-center flex-wrap">
            <Box className="reference-add-btn flex justify-end" sx={{ width: "100%" }}>
              <Box sx={{ display: addReference ? "none" : "inline-block" }}>
                <Button
                  onClick={() => {
                    setAddReference(true);
                  }}
                  color="primary"
                  startIcon={
                    <AppSvgIcon size={16} color="#FFF">
                      {"material-outline:add_circle"}
                    </AppSvgIcon>
                  }
                  variant="contained"
                >
                  <IntlMessages id="common.add" />
                </Button>
              </Box>
              <Box sx={{ display: addReference ? "inline-block" : "none" }}>
                <Button
                  className="text-color-08"
                  sx={{
                    mr: 3,
                    borderRadius: "4px",
                    border: "solid 1px rgba(0, 0, 0, 0.15)",
                    color: textColor["85"],
                  }}
                  onClick={() => {
                    handleCancel();
                  }}
                  startIcon={
                    <AppSvgIcon size={16} color={textColor["65"]}>
                      {"material-solid:cancel"}
                    </AppSvgIcon>
                  }
                  variant="outlined"
                >
                  <IntlMessages id="common.cancel" />
                </Button>
                <Button
                  onClick={() => {
                    handleSave();
                  }}
                  color="primary"
                  startIcon={
                    <AppSvgIcon size={16} color="#FFF">
                      {"material-solid:save"}
                    </AppSvgIcon>
                  }
                  variant="contained"
                >
                  <IntlMessages id="common.save" />
                </Button>
              </Box>
            </Box>
            <Box
              sx={{ display: addReference ? "flex" : "none", mt: 2, width: "100%" }}
              className="select-list justify-end"
            >
              <Select
                onChange={(event: SelectChangeEvent) => {
                  if (event.target.value !== "0") {
                    handleReferenceAdd(Number(event.target.value));
                  }
                }}
                value={(referenceSelect && referenceSelect.id) || ""}
                sx={{ width: "100%" }}
                displayEmpty
              >
                {referenceSelectList.map((x) => {
                  return (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
        </Box>
      </TableCell> */}
    </TableRow>
  );
};

export default CheckItem;
