import SimplePopup from "components/SimplePopup";
import React from "react";

import HistoryTable from "./HistoryTable";

interface HistoryModalProps {
  handleClose: () => void;
  open: boolean;
  actionLevel: number;
}

const HistoryModal: React.FC<HistoryModalProps> = ({ open, actionLevel, handleClose }) => {
  return (
    <SimplePopup
      handleClose={handleClose}
      open={open}
      titleId="setting.process.history"
      okTextId="common.ok"
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "1200px",
          width: "1200px",
          minWidth: "1200px",
        },
        "& .MuiDialogContent-root": {
          padding: "20px",
        },
      }}
    >
      <HistoryTable level={actionLevel} />
    </SimplePopup>
  );
};

export default HistoryModal;
