export const relationshipData = {
  nodeData: [
    {
      key: "SMS",
      text: "SMS Main(R1.0)",
      color: "rgba(170, 170, 170, 1)",
      borderWidth: 0,
      loc: "351.4646911693369 -274.9824971492835",
    },
    {
      key: "group_1",
      isGroup: true,
      text: "",
      fill: "transparent",
      color: "#016fa0",
      groupFigure: "ellipse",
      loc: "353.9720519487147 23.496796079190972",
    },
    {
      key: "DDD",
      text: "DDD Main(R1.0)",
      color: "rgba(250, 205, 145, 1)",
      group: "group_1",
      textColor: "#016fa0",
      borderWidth: 0,
      loc: "390.0113890996903 -102.50320392080904",
    },
    {
      key: "Subsys_1",
      text: "Subsys 1 Main(R1.0)",
      color: "#016fa0",
      group: "group_1",
      textColor: "#FFF",
      borderWidth: 0,
      loc: "38.69976394174819 35.49679607919097",
    },
    {
      key: "Subsys_2",
      text: "Subsys 2 Main(R1.0)",
      color: "#016fa0",
      group: "group_1",
      textColor: "#FFF",
      borderWidth: 0,
      loc: "230.4252481588062 44.49679607919097",
    },
    {
      key: "Subsys_3",
      text: "Subsys 3 Main(R1.0)",
      color: "#016fa0",
      group: "group_1",
      textColor: "#FFF",
      borderWidth: 0,
      loc: "402.0484984746903 48.49679607919097",
    },
    {
      key: "HW",
      text: "HW Main(R1.0)",
      color: "#016fa0",
      group: "group_1",
      textColor: "#FFF",
      borderWidth: 0,
      loc: "-121.6857341051267 144.49679607919097",
    },
    {
      key: "SW",
      text: "SW Main(R1.0)",
      color: "#016fa0",
      group: "group_1",
      textColor: "#FFF",
      borderWidth: 0,
      loc: "19.0383869886233 144.49679607919097",
    },
    {
      key: "Mech",
      text: "Mech Main(R1.0)",
      color: "#016fa0",
      group: "group_1",
      textColor: "#FFF",
      borderWidth: 0,
      loc: "170.2477172246903 149.49679607919097",
    },
    {
      key: "Risk_Sys_A",
      text: "Risk Sys A Main(R1.0)",
      color: "rgba(254, 80, 0, 1)",
      group: "group_1",
      textColor: "#FFF",
      borderWidth: 0,
      loc: "620.0508381604982 50.49679607919097",
    },
    {
      key: "Risk_Sys_B",
      text: "Risk Sys B Main(R1.0)",
      color: "rgba(254, 80, 0, 1)",
      group: "group_1",
      textColor: "#FFF",
      borderWidth: 0,
      loc: "809.0380411275562 49.49679607919097",
    },
    {
      key: "SDDDB",
      text: "SDDDB Main(R1.0)",
      color: "rgba(129, 211, 248, 1)",
      textColor: "#016fa0",
      borderWidth: 1,
      loc: "1276.944773358529 23.457793348237146",
    },
  ],
  linkData: [
    {
      key: 1,
      from: "group_1",
      to: "SMS",
      color: "#016fa0",
      toArrow: "Triangle",
      arrowColor: "#016fa0",
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      text: "Component",
      textColor: "#016fa0",
    },
    {
      key: 3,
      from: "HW",
      to: "Subsys_1",
      color: "#016fa0",
      toArrow: "Triangle",
      arrowColor: "#016fa0",
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      text: "Component",
      textColor: "#016fa0",
    },
    {
      key: 4,
      from: "SW",
      to: "Subsys_1",
      color: "#016fa0",
      toArrow: "Triangle",
      arrowColor: "#016fa0",
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      text: "Component",
      textColor: "#016fa0",
    },
    {
      key: 5,
      from: "Mech",
      to: "Subsys_1",
      toArrow: "Triangle",
      color: "#016fa0",
      arrowColor: "#016fa0",
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      text: "Component",
      textColor: "#016fa0",
    },
    {
      key: 6,
      from: "Subsys_1",
      to: "DDD",
      toArrow: "Triangle",
      color: "#016fa0",
      arrowColor: "#016fa0",
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      text: "Component",
      textColor: "#016fa0",
    },
    {
      key: 7,
      from: "Subsys_2",
      to: "DDD",
      toArrow: "Triangle",
      color: "#016fa0",
      arrowColor: "#016fa0",
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      text: "Component",
      textColor: "#016fa0",
    },
    {
      key: 8,
      from: "Subsys_3",
      to: "DDD",
      color: "#016fa0",
      toArrow: "Triangle",
      arrowColor: "#016fa0",
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      text: "Component",
      textColor: "#016fa0",
    },
    {
      key: 9,
      from: "Risk_Sys_A",
      to: "DDD",
      color: "#016fa0",
      toArrow: "Triangle",
      arrowColor: "#016fa0",
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      text: "Component",
      textColor: "#016fa0",
    },
    {
      key: 10,
      from: "Risk_Sys_B",
      to: "DDD",
      color: "#016fa0",
      toArrow: "Triangle",
      arrowColor: "#016fa0",
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      text: "Component",
      textColor: "#016fa0",
    },
    {
      key: 11,
      from: "group_1",
      to: "SDDDB",
      thickness: 3,
      toArrow: "DoubleTriangle",
      color: "rgba(129, 211, 248, 1)",
      arrowColor: "rgba(129, 211, 248, 1)",
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
      text: "Derives",
      textColor: "#016fa0",
    },
  ],
};
