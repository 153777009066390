import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { Box } from "@mui/material";
import CustomTable from "components/CustomTable";
import SimplePopup from "components/SimplePopup";
import React from "react";
import { Column } from "types/models/Common";

interface ConfigModalProps {
  handleClose: () => void;
  open: boolean;
  editRow: any;
}

const ConfigModal: React.FC<ConfigModalProps> = ({ open, editRow, handleClose }) => {
  const columns: Column[] = [
    {
      id: "order",
      label: "setting.product.column.order",
      minWidth: 100,
      align: "left",
    },
    {
      id: "id",
      label: "workspace.column.id",
      minWidth: 100,
      align: "left",
    },
    {
      id: "name",
      label: "setting.product.column.name",
      minWidth: 200,
      align: "left",
    },
    {
      id: "fieldType",
      label: "common.attribute.type",
      minWidth: 100,
      align: "left",
    },
    {
      id: "pickList",
      label: "common.pick.list",
      minWidth: 100,
      align: "left",
    },
    {
      id: "calculation",
      label: "common.calculation",
      minWidth: 100,
      align: "left",
    },
    {
      id: "read_only",
      label: "common.read.only",
      minWidth: 100,
      align: "left",
    },
    {
      id: "required",
      label: "process.input.required",
      minWidth: 100,
      align: "left",
    },
    {
      id: "suspect",
      label: "common.suspect",
      minWidth: 100,
      align: "left",
    },
    {
      id: "actions",
      label: "setting.product.column.actions",
      align: "left",
      format: (value, record) => {
        return (
          <Box>
            <EditIcon sx={{ mr: 2, cursor: "pointer", fontSize: "1.25rem" }} />
            <DeleteIcon sx={{ mr: 2, cursor: "pointer", fontSize: "1.25rem" }} />
            <NorthIcon sx={{ mr: 2, cursor: "pointer", fontSize: "1.25rem" }} />
            <SouthIcon sx={{ cursor: "pointer", fontSize: "1.25rem" }} />
          </Box>
        );
      },
    },
  ];

  const [rows, setRows] = React.useState([
    {
      order: 1,
      id: "Id",
      name: "Display ID",
      fieldType: "Text",
      pickList: "",
      calculation: "",
      read_only: "Yes",
      required: "Yes",
      suspect: "",
    },
    {
      order: 2,
      id: "globalId",
      name: "Global ID",
      fieldType: "Text",
      pickList: "",
      calculation: "",
      read_only: "Yes",
      required: "Yes",
      suspect: "",
    },
    {
      order: 3,
      id: "name",
      name: "Name",
      fieldType: "Text",
      pickList: "",
      calculation: "",
      read_only: "",
      required: "Yes",
      suspect: "Yes",
    },
    {
      order: 4,
      id: "desc",
      name: "Description",
      fieldType: "Rich Text",
      pickList: "",
      calculation: "",
      read_only: "",
      required: "",
      suspect: "Yes",
    },
    {
      order: 5,
      id: "status",
      name: "Status",
      fieldType: "Pick List",
      pickList: "Item-Status",
      calculation: "",
      read_only: "",
      required: "",
      suspect: "",
    },
    {
      order: 6,
      id: "sev",
      name: "Severity",
      fieldType: "Integer",
      pickList: "",
      calculation: "",
      read_only: "",
      required: "",
      suspect: "Yes",
    },
    {
      order: 7,
      id: "p1",
      name: "P1",
      fieldType: "Integer",
      pickList: "",
      calculation: "Yes",
      read_only: "",
      required: "",
      suspect: "Yes",
    },
    {
      order: 8,
      id: "creator",
      name: "Created By",
      fieldType: "User",
      pickList: "",
      calculation: "",
      read_only: "Yes",
      required: "",
      suspect: "",
    },
    {
      order: 9,
      id: "update",
      name: "Updated Datetime",
      fieldType: "Datetime",
      pickList: "",
      calculation: "",
      read_only: "",
      required: "",
      suspect: "",
    },
  ]);

  return (
    <SimplePopup
      handleClose={handleClose}
      open={open}
      titleId="common.attributes"
      okTextId="common.ok"
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "1200px",
          width: "1200px",
        },
        "& .MuiDialogContent-root": {
          padding: "20px",
        },
      }}
    >
      <CustomTable
        columns={columns}
        rows={rows}
        showPage={false}
        maxHeight="auto"
        addRow={() => {
          console.log("add row");
        }}
        addRowTextId="common.new"
      />
    </SimplePopup>
  );
};

export default ConfigModal;
