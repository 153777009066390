import SimplePopup from "components/SimplePopup";
import React from "react";

import SettingTable from "../SettingTable";

interface SettingModalProps {
  handleClose: () => void;
  open: boolean;
}

const SettingModal: React.FC<SettingModalProps> = ({ open, handleClose }) => {
  return (
    <SimplePopup
      handleClose={handleClose}
      open={open}
      titleId="common.setting.table"
      okTextId="common.ok"
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "1500px",
          width: "1500px",
        },
        "& .MuiDialogContent-root": {
          padding: "20px",
        },
      }}
    >
      <SettingTable />
    </SimplePopup>
  );
};

export default SettingModal;
