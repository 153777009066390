export const VersionList: any[] = [
  {
    id: "102",
    label: "V1.2",
    status: "Draft",
  },
  {
    id: "101",
    label: "V1.1",
    status: "Approved",
  },
  {
    id: "100",
    label: "V1.0",
    status: "Approved",
  },
];
