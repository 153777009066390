import AppScrollbar from "@crema/core/AppScrollbar";
import { Box, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import FolderIcon from "components/Icons/FolderIcon";
import TaskItemIcon from "components/Icons/TaskItemIcon";
import MyTreeTable from "components/MyTreeTable";
import React from "react";
import { borderColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import MyTree from "../MyTree";

const TreeGridContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  width: "100%",
  marginTop: "0px",
  display: "flex",
  ".tree-container": {
    width: "20%",
    height: "500px",
    minWidth: "200px",
    backgroundColor: theme.palette.background.paper,
  },
  ".table-tree": {
    width: "80%",
  },
}));

interface TreeGridProps {
  id: string;
}

const TreeGrid: React.FC<TreeGridProps> = (props: TreeGridProps) => {
  const [selectedRow] = React.useState(null);

  const [columns, setColumns] = React.useState<Column[]>([
    {
      id: "id",
      label: "invoice.id",
      minWidth: 100,
      align: "left",
    },
    {
      id: "name",
      label: "common.test.case.title",
      minWidth: 200,
      align: "left",
    },
    {
      id: "steps",
      label: "common.test.description",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        if (value && value.length > 0) {
          return value.map((item) => (
            <Box key={item} id={record.id + "_" + item}>
              {item}
            </Box>
          ));
        }
        return "";
      },
    },
    {
      id: "criteria",
      label: "common.acceptance.criteria",
      minWidth: 200,
      align: "left",
    },
    {
      id: "equipment",
      label: "common.equipment.and.supplies.needed",
      minWidth: 200,
      align: "left",
    },
    {
      id: "tested",
      label: "common.requirement.tested",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        return (
          <Box id={record.id + "_tested"} sx={{ color: "#2e6ccb" }}>
            {value}
          </Box>
        );
      },
    },
    {
      id: "result",
      label: "common.result",
      minWidth: 200,
      align: "left",
    },
  ]);

  const [rows, setRows] = React.useState<any[]>([
    {
      id: "TC#100",
      name: "Test Cases",
      steps: "",
      criteria: "",
      equipment: "",
      tested: "",
      result: "",
      startIcon: (
        <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
          <FolderIcon />
        </Box>
      ),
      children: [
        {
          id: "TC#001",
          name: "Fever Detection",
          steps: [
            "1.  Set the fever threshold to 38oC",
            "2.  Set the temperature simulator to 38.5oC",
            "3.  Wait 20 seconds +/- 2 seconds",
            "4.  Record results",
          ],
          criteria:
            "Within 20 seconds of the temperature simulator being set to 38.5oC, the system activates a visual  and an audible indicator.",
          equipment: "Temperature Simulator",
          tested: "SYS#010",
          result: "",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
              <TaskItemIcon />
            </Box>
          ),
        },
        {
          id: "TC#002",
          name: "Cleaning",
          steps: [
            "1.  Visually inspect the thermometer to ensure that there is no visible damage",
            "2.  Wipe the entire surface of the thermometer with an alcohol wipe.",
            "3.  Wait 10 seconds and repeat X 25 times",
            "4.  Visually inspec the thermometer and record observations.",
            "5.  Test the thermometer as per Final Test Specification and Record Results.",
          ],
          criteria:
            "The thermometer will have no visible signs of physical damage and will operate within specification after exposure to worst case simulated cleaning.",
          equipment: "Alcohol wipes",
          tested: "SYS#011",
          result: "",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
              <TaskItemIcon />
            </Box>
          ),
        },
        {
          id: "TC#003",
          name: "Cleaning",
          steps: [
            "1.  Visually inspect the thermometer to ensure that there is no visible damage",
            "2.  Wipe the entire surface of the thermometer with an alcohol wipe.",
            "3.  Wait 10 seconds and repeat X 25 times",
            "4.  Visually inspec the thermometer and record observations.",
            "5.  Test the thermometer as per Final Test Specification and Record Results.",
          ],
          criteria:
            "The thermometer will have no visible signs of physical damage and will operate within specification after exposure to worst case simulated cleaning.",
          equipment: "Alcohol wipes",
          tested: "SYS#011",
          result: "",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
              <TaskItemIcon />
            </Box>
          ),
        },
        {
          id: "TC#004",
          name: "Cleaning",
          steps: [
            "1.  Visually inspect the thermometer to ensure that there is no visible damage",
            "2.  Wipe the entire surface of the thermometer with an alcohol wipe.",
            "3.  Wait 10 seconds and repeat X 25 times",
            "4.  Visually inspec the thermometer and record observations.",
            "5.  Test the thermometer as per Final Test Specification and Record Results.",
          ],
          criteria:
            "The thermometer will have no visible signs of physical damage and will operate within specification after exposure to worst case simulated cleaning.",
          equipment: "Alcohol wipes",
          tested: "SYS#011",
          result: "",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
  ]);

  React.useEffect(() => {
    if (props.id === "User Needs") {
      setColumns([
        {
          id: "name",
          label: "common.name",
          minWidth: 200,
          align: "left",
        },
        {
          id: "id",
          label: "common.display.id",
          minWidth: 200,
          align: "left",
        },
        {
          id: "user_types",
          label: "common.user.types",
          minWidth: 200,
          align: "left",
          format: (value, record) => {
            const list = value ? value.split("|") : [];
            if (list && list.length > 0) {
              return list.map((item) => <Box key={item}>{item}</Box>);
            }
            return "";
          },
        },
        {
          id: "priority",
          label: "common.priority",
          minWidth: 200,
          align: "left",
        },
        {
          id: "rationale",
          label: "common.rationale",
          minWidth: 200,
          align: "left",
        },
      ]);
      setRows([
        {
          name: "User Needs",
          id: "UND#100",
          user_types: "",
          priority: "",
          rationale: "",
          level: 1,
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
              <FolderIcon />
            </Box>
          ),
          children: [
            {
              name: "The user needs a device that can measure temperature accurately and quickly.",
              id: "UND#001",
              user_types: "Patient|Healthcare|Provider|Caregiver",
              priority: "High",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs to able to accurately track the temperature of multiple patients when using the device",
              id: "UND#002",
              user_types: "Healthcare Provider|Caregiver",
              priority: "High",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs the device to read body temperature without pain or discomfort.",
              id: "UND#003",
              user_types: "Patient",
              priority: "Med",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs a device that is easy to use.",
              id: "UND#004",
              user_types: "Patient|Healthcare|Provider|Caregiver",
              priority: "Med",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs to use the device using only one hand, either the right or left had.",
              id: "UND#005",
              user_types: "Patient|Healthcare|Provider|Caregiver",
              priority: "High",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs a device that will indicate a fever without the user needing to read the numbers.",
              id: "UND#006",
              user_types: "Patient|Healthcare|Provider|Caregiver",
              priority: "High",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs a device that is easy to clean.",
              id: "UND#007",
              user_types: "Patient|Healthcare|Provider|Caregiver",
              priority: "High",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs the device to track readings over a period of 6 months",
              id: "UND#008",
              user_types: "Patient|Healthcare|Provider|Caregiver",
              priority: "High",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs the device to provide a method of taking readings on different patients without needing disinfection.",
              id: "UND#009",
              user_types: "Healthcare|Provider|Caregiver",
              priority: "Med",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs the device to accurately measure temperature on any part of the body.",
              id: "UND#010",
              user_types: "Patient|Healthcare|Provider|Caregiver",
              priority: "Med",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs the device to facilitate updating the patient's chart with temperature recorded.",
              id: "UND#011",
              user_types: "Healthcare|Provider",
              priority: "CTO",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs to be able to easily dispose of the product.",
              id: "UND#016",
              user_types: "Patient|Healthcare|Provider|Caregiver",
              priority: "High",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs to be able to make results available to a physician.",
              id: "UND#017",
              user_types: "Patient|Healthcare|Provider|Caregiver",
              priority: "Med",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs to easily store the product.",
              id: "UND#018",
              user_types: "Patient|Healthcare|Provider|Caregiver",
              priority: "High",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs to be notified when temperature measurements are outside the specified range",
              id: "UND#019",
              user_types: "Patient|Healthcare|Provider|Caregiver",
              priority: "Med",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs the device to last for two years without needing replacement of any parts.",
              id: "UND#012",
              user_types: "Business",
              priority: "Med",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs the device to units and temperatures that they are accustomed to.",
              id: "UND#013",
              user_types: "Business",
              priority: "Low",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs the device to meet regulatory requirements in the countries for which it is intended to be sold.",
              id: "UND#014",
              user_types: "Business",
              priority: "High",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              name: "The user needs the device to work on all patient populations.",
              id: "UND#015",
              user_types: "Business",
              priority: "High",
              rationale: "",
              level: 2,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ]);
    } else if (props.id === "Test Cases") {
      setColumns([
        {
          id: "id",
          label: "invoice.id",
          minWidth: 100,
          align: "left",
        },
        {
          id: "name",
          label: "common.test.case.title",
          minWidth: 200,
          align: "left",
        },
        {
          id: "steps",
          label: "common.test.description",
          minWidth: 200,
          align: "left",
          format: (value, record) => {
            if (value && value.length > 0) {
              return value.map((item) => (
                <Box key={item} id={record.id + "_" + item}>
                  {item}
                </Box>
              ));
            }
            return "";
          },
        },
        {
          id: "criteria",
          label: "common.acceptance.criteria",
          minWidth: 200,
          align: "left",
        },
        {
          id: "equipment",
          label: "common.equipment.and.supplies.needed",
          minWidth: 200,
          align: "left",
        },
        {
          id: "tested",
          label: "common.requirement.tested",
          minWidth: 200,
          align: "left",
          format: (value, record) => {
            return (
              <Box id={record.id + "_tested"} sx={{ color: "#2e6ccb" }}>
                {value}
              </Box>
            );
          },
        },
        {
          id: "result",
          label: "common.result",
          minWidth: 200,
          align: "left",
        },
      ]);
      setRows([
        {
          id: "TC#100",
          name: "Test Cases",
          steps: "",
          criteria: "",
          equipment: "",
          tested: "",
          result: "",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
              <FolderIcon />
            </Box>
          ),
          children: [
            {
              id: "TC#001",
              name: "Fever Detection",
              steps: [
                "1.  Set the fever threshold to 38oC",
                "2.  Set the temperature simulator to 38.5oC",
                "3.  Wait 20 seconds +/- 2 seconds",
                "4.  Record results",
              ],
              criteria:
                "Within 20 seconds of the temperature simulator being set to 38.5oC, the system activates a visual  and an audible indicator.",
              equipment: "Temperature Simulator",
              tested: "SYS#010",
              result: "",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              id: "TC#002",
              name: "Cleaning",
              steps: [
                "1.  Visually inspect the thermometer to ensure that there is no visible damage",
                "2.  Wipe the entire surface of the thermometer with an alcohol wipe.",
                "3.  Wait 10 seconds and repeat X 25 times",
                "4.  Visually inspec the thermometer and record observations.",
                "5.  Test the thermometer as per Final Test Specification and Record Results.",
              ],
              criteria:
                "The thermometer will have no visible signs of physical damage and will operate within specification after exposure to worst case simulated cleaning.",
              equipment: "Alcohol wipes",
              tested: "SYS#011",
              result: "",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              id: "TC#003",
              name: "Cleaning",
              steps: [
                "1.  Visually inspect the thermometer to ensure that there is no visible damage",
                "2.  Wipe the entire surface of the thermometer with an alcohol wipe.",
                "3.  Wait 10 seconds and repeat X 25 times",
                "4.  Visually inspec the thermometer and record observations.",
                "5.  Test the thermometer as per Final Test Specification and Record Results.",
              ],
              criteria:
                "The thermometer will have no visible signs of physical damage and will operate within specification after exposure to worst case simulated cleaning.",
              equipment: "Alcohol wipes",
              tested: "SYS#011",
              result: "",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ]);
    } else {
      setColumns([]);
      setRows([]);
    }
  }, [props.id]);

  return (
    <TreeGridContainer>
      <AppScrollbar className="tree-container">
        <MyTree id={props.id} />
      </AppScrollbar>
      <Box className="table-tree">
        <MyTreeTable columns={columns} rows={rows} maxHeight="500px" selectedRow={selectedRow} />
      </Box>
    </TreeGridContainer>
  );
};

export default TreeGrid;
