import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, IconButton, InputAdornment, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import AddIcon from "components/Icons/AddIcon";
import MyCollapseTable from "components/MyCollapseTable";
import React, { useEffect, useState } from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";
import { CollapseColumn } from "types/models/Common";

import TabTable from "../components/TabTable";
import { RiskPolicyDataModel } from "./models";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "20px",
  background: "#fff",
  boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)",
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell": {
    border: `1px solid  ${borderColor.dividerColor}`,
  },
  ".table-toolbar": {
    padding: theme.spacing(2, 0),
    ".refresh-btn": {
      color: textColor[85],
    },
    ".add-button": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const initColumns: CollapseColumn[] = [
  {
    id: "name",
    label: "common.name",
    collapse: true,
  },
  {
    id: "type",
    label: "common.type",
  },
  {
    id: "description",
    label: "common.description",
  },
  {
    id: "displayOrder",
    label: "setting.product.column.displayOrder",
  },
  {
    id: "isBased",
    label: "common.is.based",
    align: "left",
    format: (value, record) => {
      return (
        <Box sx={{ display: "flex" }}>
          {value ? (
            <AppSvgIcon size={16} color="#6dc347">
              material-solid:done
            </AppSvgIcon>
          ) : (
            <AppSvgIcon size={16} color="action">
              material-solid:close
            </AppSvgIcon>
          )}
        </Box>
      );
    },
  },
];

const initRows: RiskPolicyDataModel[] = [
  {
    id: 1,
    name: "Safety Risk Policy",
    type: "Safety Risk Policy",
    description: "Safety Risk Ranking",
    displayOrder: 1,
    isBased: true,
  },
  {
    id: 2,
    name: "Security Risk Policy",
    type: "Security Risk Policy",
    description: "Security Risk Ranking",
    displayOrder: 2,
    isBased: true,
  },
  {
    id: 3,
    name: "FMEA",
    type: "FMEA",
    description: "FMEA Configuration",
    displayOrder: 3,
    isBased: false,
  },
  {
    id: 4,
    name: "dFMEA",
    type: "FMEA",
    description: "test",
    displayOrder: 4,
    isBased: false,
  },
];

interface RiskPolicyProps {}
const RiskPolicy = (props: RiskPolicyProps) => {
  const [columns, setColumns] = useState([...initColumns]);
  const [rows1, setRows1] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [rows3, setRows3] = useState([]);

  const [safetyRiksPolicy, setSafetyRiksPolicy] = React.useState("");
  const [securityRiskPolicy, setSecurityRiskPolicy] = React.useState("");
  const [fmea, setFmea] = React.useState("");

  useEffect(() => {
    if (safetyRiksPolicy) {
      let newRows = [];
      initRows.map((item) => {
        if (item.name === safetyRiksPolicy) {
          newRows.push(item);
        }
      });
      setRows1(newRows);
    } else {
      setRows1([]);
    }
  }, [safetyRiksPolicy]);

  useEffect(() => {
    if (securityRiskPolicy) {
      let newRows = [];
      initRows.map((item) => {
        if (item.name === securityRiskPolicy) {
          newRows.push(item);
        }
      });
      setRows2(newRows);
    } else {
      setRows2([]);
    }
  }, [securityRiskPolicy]);

  useEffect(() => {
    if (fmea) {
      let newRows = [];
      initRows.map((item) => {
        if (item.name === fmea) {
          newRows.push(item);
        }
      });
      setRows3(newRows);
    } else {
      setRows3([]);
    }
  }, [fmea]);

  const handleAddClick = () => {
    console.log("handleAddClick");
  };

  const toolbar1 = (
    <Box className="table-toolbar flex justify-between">
      <Box className="flex items-center">
        <Select
          sx={{
            minWidth: 60,
            background: "#fff",
            "& .MuiInputAdornment-root": {
              color: textColor[85],
            },
            "& .MuiTypography-body1": {
              color: textColor[85],
            },
            "& .MuiInputBase-input": {
              padding: "6px 0px",
              color: textColor[85],
            },
          }}
          id="status-pick-list-select"
          value={safetyRiksPolicy}
          onChange={(e) => {
            setSafetyRiksPolicy(e.target.value);
          }}
          startAdornment={<InputAdornment position="start">Select Safety Risk Policy:</InputAdornment>}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value="Safety Risk Policy">Safety Risk Policy</MenuItem>
        </Select>
      </Box>
      <Box className="table-toolbar-actions flex justify-center items-center">
        <Button
          className="refresh-btn"
          startIcon={<AppSvgIcon color={textColor["65"]}>{"material-outline:refresh"}</AppSvgIcon>}
        >
          <IntlMessages id="common.refresh" />
        </Button>
      </Box>
    </Box>
  );
  const toolbar2 = (
    <Box className="table-toolbar flex justify-between">
      <Box className="flex items-center">
        <Select
          sx={{
            minWidth: 60,
            background: "#fff",
            mx: 2,
            "& .MuiInputAdornment-root": {
              color: textColor[85],
            },
            "& .MuiTypography-body1": {
              color: textColor[85],
            },
            "& .MuiInputBase-input": {
              padding: "6px 0px",
              color: textColor[85],
            },
          }}
          id="status-pick-list-select"
          value={securityRiskPolicy}
          onChange={(e) => {
            setSecurityRiskPolicy(e.target.value);
          }}
          startAdornment={<InputAdornment position="start">Select Security Risk Policy:</InputAdornment>}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value="Security Risk Policy">Security Risk Policy</MenuItem>
        </Select>
      </Box>
      <Box className="table-toolbar-actions flex justify-center items-center">
        <Button
          className="refresh-btn"
          startIcon={<AppSvgIcon color={textColor["65"]}>{"material-outline:refresh"}</AppSvgIcon>}
        >
          <IntlMessages id="common.refresh" />
        </Button>
      </Box>
    </Box>
  );
  const toolbar3 = (
    <Box className="table-toolbar flex justify-between">
      <Box className="flex items-center">
        <Select
          sx={{
            minWidth: 60,
            background: "#fff",
            "& .MuiInputAdornment-root": {
              color: textColor[85],
            },
            "& .MuiTypography-body1": {
              color: textColor[85],
            },
            "& .MuiInputBase-input": {
              padding: "6px 0px",
              color: textColor[85],
            },
          }}
          id="status-pick-list-select"
          value={fmea}
          onChange={(e) => {
            setFmea(e.target.value);
          }}
          startAdornment={<InputAdornment position="start">Select FMEA:</InputAdornment>}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value="FMEA">FMEA</MenuItem>
          <MenuItem value="dFMEA">dFMEA</MenuItem>
        </Select>
      </Box>
      <Box className="table-toolbar-actions flex justify-center items-center">
        <Button
          className="refresh-btn"
          startIcon={<AppSvgIcon color={textColor["65"]}>{"material-outline:refresh"}</AppSvgIcon>}
        >
          <IntlMessages id="common.refresh" />
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <Container className="module-level-box">
        <MyCollapseTable
          toolbar={toolbar1}
          columns={columns}
          rows={rows1}
          showPage={false}
          collapse={true}
          renderChildren={(x) => <TabTable rowId={x.id} editable={true} />}
        />
      </Container>
      <Container className="module-level-box" sx={{ mt: 5 }}>
        <MyCollapseTable
          toolbar={toolbar2}
          columns={columns}
          rows={rows2}
          showPage={false}
          collapse={true}
          renderChildren={(x) => <TabTable rowId={x.id} editable={true} />}
        />
      </Container>
      <Container className="module-level-box" sx={{ mt: 5 }}>
        <MyCollapseTable
          toolbar={toolbar3}
          columns={columns}
          rows={rows3}
          showPage={false}
          collapse={true}
          renderChildren={(x) => <TabTable rowId={x.id} editable={true} />}
        />
      </Container>
    </>
  );
};

export default RiskPolicy;
