import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import React from "react";
import { borderColor, textColor } from "shared/constants/AppConst";

import Comments from "./Comments";
import RichText from "./RichText";

const DetailsContainer = styled(Box)(({ theme }) => ({
  padding: "8px 0",
  minWidth: "1000px",
  ".details-content": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
    color: textColor[85],
  },
  ".details-content-left": {
    marginTop: "22px",
    width: "55%",
  },
  ".details-content-title": {
    padding: "4px 0",
    color: textColor[85],
    fontWeight: "bold",
  },
  ".details-content-right": {
    width: "44%",
  },
  ".details-field-container": {
    width: "100%",
    marginTop: theme.spacing(5),
    border: `1px solid ${borderColor.dividerColor}`,
  },
  ".details-field-item": {
    width: "100%",
    display: "flex",
    borderBottom: `1px solid ${borderColor.dividerColor}`,
  },
  ".details-field-label": {
    width: "30%",
    display: "flex",
    alignItems: "center",
    borderRight: `1px solid ${borderColor.dividerColor}`,
    padding: "8px",
    background: "#F7F7F7",
    color: textColor[85],
    fontWeight: "bold",
  },
  ".details-field-value": {
    width: "70%",
    padding: "8px",
    background: "#FFFFFF",
    color: textColor[85],
    display: "flex",
    alignItems: "center",
  },
}));

const Details = () => {
  const [edit, setEdit] = React.useState<boolean>(false);
  const [richText, setRichText] = React.useState("test");
  const [baseInfo, setBaseInfo] = React.useState([
    {
      id: "1",
      label: "Context",
      value: "user/operator not able to setup the pump system timely",
    },
    {
      id: "2",
      label: "Assumption",
      value:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin",
    },
    {
      id: "3",
      label: "Strategy",
      value:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin",
    },
    {
      id: "4",
      label: "Argument",
      value:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin",
    },
  ]);
  const [categories, setCategories] = React.useState([
    {
      id: "1",
      label: "HM: Harms",
      checkboxes: [
        {
          id: "11",
          label: "Overdoes",
          checked: false,
        },
        {
          id: "12",
          label: "Underdoes",
          checked: false,
        },
        {
          id: "13",
          label: "Infection",
          checked: false,
        },
      ],
    },
    {
      id: "2",
      label: "ToC: Type of Cause",
      checkboxes: [
        {
          id: "21",
          label: "Hardware",
          checked: false,
        },
        {
          id: "22",
          label: "Software",
          checked: false,
        },
        {
          id: "23",
          label: "Human Factor",
          checked: false,
        },
      ],
    },
    {
      id: "3",
      label: "HZ: Hazards",
      checkboxes: [
        {
          id: "31",
          label: "Under Delivery",
          checked: false,
        },
        {
          id: "32",
          label: "Over Delivery  ",
          checked: false,
        },
      ],
    },
    {
      id: "4",
      label: "RRN: Regulatory Review Need",
      checkboxes: [
        {
          id: "41",
          label: "Yes",
          checked: false,
        },
        {
          id: "42",
          label: "No",
          checked: false,
        },
      ],
    },
  ]);

  const handleSave = () => {
    console.log(categories, richText, baseInfo);
    setEdit(false);
  };

  const handleCancel = () => {
    setEdit(false);
  };

  const handleChangeCheckBox = (parentId, checkboxId) => {
    const newCategories = [...categories];
    const targetItem = newCategories.filter((item) => item.id === parentId)[0];
    if (targetItem && targetItem.checkboxes.length > 0) {
      const targetCheckbox = targetItem.checkboxes.filter((item) => item.id === checkboxId)[0];
      targetCheckbox.checked = !targetCheckbox.checked;
    }
    setCategories(newCategories);
  };

  const handleBaseInfo = (value, id) => {
    const newBaseInfo = [...baseInfo];
    const targetItem = newBaseInfo.filter((item) => item.id === id)[0];
    if (targetItem) {
      targetItem.value = value;
    }
    setBaseInfo(newBaseInfo);
  };

  return (
    <DetailsContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: edit ? "none" : "inline-block" }}>
          <Button
            onClick={() => {
              setEdit(true);
            }}
            variant="outlined"
            color="inherit"
            startIcon={<AppSvgIcon size={16}>{"material-solid:edit"}</AppSvgIcon>}
          >
            <IntlMessages id="common.edit" />
          </Button>
        </Box>
        <Box sx={{ display: edit ? "inline-block" : "none" }}>
          <Button
            className="text-color-08"
            sx={{
              mr: 3,
              borderRadius: "4px",
              border: "solid 1px rgba(0, 0, 0, 0.15)",
              color: textColor["85"],
            }}
            onClick={handleCancel}
            startIcon={
              <AppSvgIcon size={16} color={textColor["65"]}>
                {"material-solid:cancel"}
              </AppSvgIcon>
            }
            variant="outlined"
          >
            <IntlMessages id="common.cancel" />
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            startIcon={
              <AppSvgIcon size={16} color="#FFF">
                {"material-solid:save"}
              </AppSvgIcon>
            }
            variant="contained"
          >
            <IntlMessages id="common.save" />
          </Button>
        </Box>
      </Box>
      <Box className="details-content">
        <Box className="details-content-right">
          <Box className="details-content-title">
            <IntlMessages id="common.description" />
          </Box>
          <Box sx={{ width: "100%" }}>
            {edit ? (
              <RichText value={richText} handleValue={setRichText} />
            ) : (
              <AppScrollbar
                sx={{
                  height: "100px",
                  width: "100%",
                  border: `1px solid ${borderColor.dividerColor}`,
                }}
              >
                <Box dangerouslySetInnerHTML={{ __html: richText }}></Box>
              </AppScrollbar>
            )}
          </Box>
          <Box className="details-field-container">
            {baseInfo.map((item) => {
              return (
                <Box key={item.id} className="details-field-item">
                  <Box className="details-field-label">{item.label}</Box>
                  <Box className="details-field-value">
                    {edit ? (
                      <TextField
                        variant="outlined"
                        value={item.value}
                        fullWidth
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleBaseInfo(event.target.value, item.id)
                        }
                        InputProps={{
                          endAdornment: (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: item.value ? "visible" : "hidden" }}
                                onClick={() => {
                                  handleBaseInfo("", item.id);
                                }}
                              >
                                <AppSvgIcon size={16} color="action">
                                  {"material-outline:clear"}
                                </AppSvgIcon>
                              </IconButton>
                            </Box>
                          ),
                        }}
                      />
                    ) : (
                      item.value
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box className="details-field-container">
            {categories.map((item) => {
              return (
                <Box key={item.id} className="details-field-item">
                  <Box className="details-field-label">{item.label}</Box>
                  <Box className="details-field-value">
                    <FormGroup row={true}>
                      {item.checkboxes?.map((checkbox) => {
                        return (
                          <FormControlLabel
                            key={checkbox.id}
                            control={
                              <Checkbox
                                onChange={() => {
                                  handleChangeCheckBox(item.id, checkbox.id);
                                }}
                                checked={checkbox.checked}
                              />
                            }
                            disabled={!edit}
                            label={checkbox.label}
                            sx={{ mr: 4 }}
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box className="details-content-left">
          <Comments />
        </Box>
      </Box>
    </DetailsContainer>
  );
};

export default Details;
