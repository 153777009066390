import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import MyTree from "components/MyTree";
import { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { borderColor, boxShadow, tabStyle, textColor } from "shared/constants/AppConst";

import Approval from "./Approval";
import RichEditorTemplate from "./RichEditorTemplate";

const deepFind =
  (pred) =>
  ([x, ...xs] = []) =>
    x && (pred(x) ? x : deepFind(pred)(x.items) || deepFind(pred)(xs));

const findById = (id) => (dataSet) => deepFind((o) => o.id === id)(dataSet);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export interface TreeData {
  id: string | number;
  value?: string;
  isBold?: boolean;
  items?: TreeData[];
}
const indexTree: TreeData[] = [
  {
    id: "1 Scope",
    value: "1 Scope",
    isBold: true,
    items: [
      {
        id: "1.1 Description",
        value: "1.1 Description",
      },
      {
        id: "1.2 Objective",
        value: "1.2 Objective",
      },
    ],
  },
  {
    id: "2 Rules",
    value: "2 Rules",
  },
  {
    id: "3 Potential Risks",
    value: "3 Potential Risks",
  },
  {
    id: "4 Risks Traceability",
    value: "4 Risks Traceability",
  },
];
const mockData = {
  richEditorHtml: {
    html: "<h2><strong>1 Scope</strong></h2><h4><strong>1.1 Description</strong></h4><p>&nbsp; &nbsp; Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus</p><h4><strong>1.2 Objective</strong></h4><p>&nbsp; &nbsp; Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus</p><h2><strong>2 Rules</strong></h2><h2><strong>3 Potential Risks</strong></h2><h2><strong>4 Risks Traceability</strong></h2>",
  },
  attachments: [
    {
      id: "1",
      fileName: "attachment-1",
      fileUrl: "http://localhost:3000/",
    },
    {
      id: "2",
      fileName: "attachment-2",
      fileUrl: "http://localhost:3000/",
    },
  ],
};

const ReportContainer = styled(Box)(({ theme }) => ({
  ".tab-sub-table": {
    padding: theme.spacing(3, 0, 0, 0),
    height: "calc(100% - 50px)",
  },
  ".index-tree": {
    position: "relative",
    height: "calc(100vh - 290px)",
    overflowY: "auto",
  },
  ".rich_editor": {
    display: "block",
    width: "calc(70%)",
    height: "calc(100vh - 290px)",
    borderBottom: "1px solid #c4c4c4",
    borderRight: "1px solid #c4c4c4",
  },
  ".ck.ck-content": {
    width: "100%",
    height: "calc(100vh - 331px)",
    borderBottom: 0,
    borderRight: 0,
  },
  ".attachments-box": {
    position: "absolute",
    width: "calc(100% - 24px)",
    bottom: "12px",
  },
  ".attachment-item-box": {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    maxWidth: "600px",
    margin: theme.spacing(2, 0),
  },
}));
const CardContainer = styled(Card)(({ theme }) => ({
  "&.MuiCard-root": {
    padding: theme.spacing(0),
    boxShadow: "none",
    borderRadius: 0,
    ".MuiCardHeader-root": {
      padding: theme.spacing(4, 0),
    },
  },
}));

interface ReportProps {
  isPadding?: boolean;
}
const Report = (props: ReportProps) => {
  // const params = useParams();
  // console.log(params);
  const [richEditorHtml, setRichEditorHtml] = useState(mockData.richEditorHtml);
  const [attachments, setAttachments] = useState(mockData.attachments);
  const [index, setIndex] = useState("report");
  const [treeData, setTreeData] = useState(indexTree);

  const [selectedTreeData, setSelectedTreeData] = useState<TreeData>(treeData ? treeData[0] : null);

  const [selectedValue, setSelectedValue] = useState<string | number>(treeData ? treeData[0].id.toString() : "");

  const onNodeSelect = (event: object, value: string | number) => {
    setSelectedValue(value);
    if (treeData) {
      const selected = findById(value)(treeData);
      setSelectedTreeData(selected);
    }
  };

  const handleAddAttachments = () => {
    console.log("add attachment");
  };
  const handleDeleteAttachment = (value: { id: number | string; fileName: string; fileUrl: string }) => {
    console.log(value);
  };
  const attachmentDoms = useMemo(() => {
    if (!attachments || attachments.length === 0) {
      return <></>;
    }
    return (
      <List>
        {attachments.map((x) => (
          <ListItem key={x.id} className="attachment-item-box" disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AppSvgIcon color={textColor["65"]}>{"material-outline:attach_file"}</AppSvgIcon>
              </ListItemIcon>
              <ListItemText>
                <Link href={x.fileUrl} target="_blank" rel="noopener" underline="none">
                  {x.fileName}
                </Link>
              </ListItemText>
              <IconButton
                onClick={() => {
                  handleDeleteAttachment(x);
                }}
              >
                <AppSvgIcon color={textColor["65"]}>{"material-outline:delete"}</AppSvgIcon>
              </IconButton>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  }, [attachments]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setIndex(newValue);
  };

  return (
    <ReportContainer>
      <AppScrollbar
        sx={{
          width: "100%",
          maxHeight: "calc(100vh - 104px)",
          padding: props?.isPadding ? (theme) => theme.spacing(5) : 0,
        }}
      >
        <Box
          sx={{
            padding: (theme) => theme.spacing(5),
            background: (theme) => theme.palette.background.paper,
            boxShadow: boxShadow.cardBoxShadow,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: borderColor.dividerColor,
            }}
          >
            <Tabs
              value={index}
              onChange={handleChangeTab}
              sx={{
                ...tabStyle,
                "& .MuiButtonBase-root": {
                  minHeight: "48px",
                  textTransform: "none",
                },
              }}
            >
              <Tab value={"report"} label={<IntlMessages id="common.report" />} />
              <Tab value={"approval"} label={<IntlMessages id="common.approval" />} />
            </Tabs>
          </Box>
          <Box className="tab-sub-table">
            <TabPanel value={index} index="report">
              <Box className="flex justify-between report-content">
                <Box
                  className="index-tree"
                  sx={{ width: "calc(30%)", border: "1px solid #c4c4c4", borderRight: 0, p: "12px" }}
                >
                  {treeData && (
                    <MyTree
                      selected={selectedValue.toString()}
                      onNodeSelect={onNodeSelect}
                      data={treeData}
                      useStyledTree={true}
                      showMoreIcon={false}
                    />
                  )}
                  <Box className="attachments-box">
                    <CardContainer
                      sx={{
                        "&.MuiCard-root": {
                          paddingTop: 0,
                        },
                      }}
                    >
                      <CardHeader title={<IntlMessages id="product.deliverables.report.attachments" />} />
                      <CardContent>
                        <Box>
                          <Button
                            variant="outlined"
                            className="add-button"
                            startIcon={<AppSvgIcon color={textColor["65"]}>{"material-outline:add_circle"}</AppSvgIcon>}
                          >
                            <IntlMessages id="product.deliverables.report.attachments.add" />
                          </Button>
                        </Box>
                        <Box>{attachmentDoms}</Box>
                      </CardContent>
                    </CardContainer>
                  </Box>
                </Box>
                <AppScrollbar className="rich_editor">
                  <CardContainer>
                    <CardContent>
                      <RichEditorTemplate
                        data={richEditorHtml.html}
                        inEdit={true}
                        onChange={(data) => {
                          setRichEditorHtml({
                            html: data,
                          });
                        }}
                      />
                    </CardContent>
                  </CardContainer>
                </AppScrollbar>
              </Box>
            </TabPanel>
            <TabPanel value={index} index="approval">
              <Approval />
            </TabPanel>
          </Box>
        </Box>
      </AppScrollbar>
    </ReportContainer>
  );
};

export default Report;
