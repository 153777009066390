import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import {
  Box,
  Button,
  Card,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import $ from "components/Gojs";
import * as go from "gojs";
import { ReactDiagram } from "gojs-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { bgColor, textColor } from "shared/constants/AppConst";
import { LinkData, ModelData, NodeData } from "types/models/GoJs";

import ImpactSummary from "./components/ImpactSummary";
import { flowData, flowDataShared } from "./d";

const KAPPA = 4 * ((Math.sqrt(2) - 1) / 3);
const Container = styled(Box)(({ theme }) => ({
  ".relationship-impact-title": {
    // borderBottom: "1px solid rgba(0, 0, 0, 0.09)",
    ".title": {
      fontSize: "20px",
      fontWeight: "500",
      color: textColor[85],
    },
  },
  ".relationship-box": {
    marginTop: theme.spacing(5),
  },
  ".impact-top": {
    width: "100%",
    padding: theme.spacing(3, 0),
  },
  ".impact-diagram": {
    width: "100%",
    minHeight: "600px",
    ".diagram-box": {
      width: "100%",
      height: "inherit",
      minHeight: "600px",
    },
  },
}));

let diagram;
interface RelationshipsAndImpactProps {
  isPadding?: boolean;
}
const RelationshipsAndImpact = (props: RelationshipsAndImpactProps) => {
  const [impactedDeliverable, setImpactedDeliverable] = useState([
    {
      id: 1,
      name: "PRD#001-TK#002 Design FMEA(V1.0)",
    },
    {
      id: 2,
      name: "PRD#002-TK@001 System Hazard Analysis(V1.1)",
    },
  ]);
  const [impactedDeliverableValue, setImpactedDeliverableValue] = useState(1);

  const [nodeData, setNodeData] = useState<NodeData[]>(flowDataShared.nodeData);
  const [linkData, setLinkData] = useState<LinkData[]>(flowDataShared.linkData);

  const [anchorElRight, setAnchorElRight] = useState<null | HTMLElement>(null);

  const handleOpenRightClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRight(event.currentTarget);
  };

  const handleOpenRightClose = () => {
    setAnchorElRight(null);
  };

  const [relationshipRichOpen, setRelationshipRichOpen] = useState(false);
  const handleOpenRelationshipClick = (value) => {
    setRelationshipRichOpen(value);
  };

  const diagramRef = useRef(null);

  const handleDiagramChange = (diagram) => {
    const a = diagram.model.toJson();
    console.log(a);
  };

  const initDiagram = () => {
    diagram = $(go.Diagram, {
      padding: 20,
      "undoManager.isEnabled": false, // must be set to allow for model change listening, now use false,
      // $(go.TreeLayout, { layerSpacing: 150 }),
      allowTextEdit: false,
      allowDrop: false,
      // allowMove: false,
      // layout: $(go.GridLayout, { spacing: new go.Size(80, 80) }),
    });

    let graphLinksModel = new go.GraphLinksModel();
    graphLinksModel.linkKeyProperty = "key";
    diagram.model = graphLinksModel;

    go.Shape.defineFigureGenerator("Database", function (shape, w, h) {
      var geo = new go.Geometry();
      var cpxOffset = KAPPA * 0.5;
      var cpyOffset = KAPPA * 0.1;
      var fig = new go.PathFigure(w, 0.1 * h, true);
      geo.add(fig);

      // Body
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.9 * h));
      fig.add(
        new go.PathSegment(go.PathSegment.Bezier, 0.5 * w, h, w, (0.9 + cpyOffset) * h, (0.5 + cpxOffset) * w, h)
      );
      fig.add(
        new go.PathSegment(go.PathSegment.Bezier, 0, 0.9 * h, (0.5 - cpxOffset) * w, h, 0, (0.9 + cpyOffset) * h)
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.1 * h));
      fig.add(
        new go.PathSegment(go.PathSegment.Bezier, 0.5 * w, 0, 0, (0.1 - cpyOffset) * h, (0.5 - cpxOffset) * w, 0)
      );
      fig.add(
        new go.PathSegment(go.PathSegment.Bezier, w, 0.1 * h, (0.5 + cpxOffset) * w, 0, w, (0.1 - cpyOffset) * h)
      );
      var fig2 = new go.PathFigure(w, 0.1 * h, false);
      geo.add(fig2);
      // Rings
      fig2.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          0.2 * h,
          w,
          (0.1 + cpyOffset) * h,
          (0.5 + cpxOffset) * w,
          0.2 * h
        )
      );
      fig2.add(
        new go.PathSegment(go.PathSegment.Bezier, 0, 0.1 * h, (0.5 - cpxOffset) * w, 0.2 * h, 0, (0.1 + cpyOffset) * h)
      );
      fig2.add(new go.PathSegment(go.PathSegment.Move, w, 0.2 * h));
      fig2.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          0.3 * h,
          w,
          (0.2 + cpyOffset) * h,
          (0.5 + cpxOffset) * w,
          0.3 * h
        )
      );
      fig2.add(
        new go.PathSegment(go.PathSegment.Bezier, 0, 0.2 * h, (0.5 - cpxOffset) * w, 0.3 * h, 0, (0.2 + cpyOffset) * h)
      );
      fig2.add(new go.PathSegment(go.PathSegment.Move, w, 0.3 * h));
      fig2.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          0.4 * h,
          w,
          (0.3 + cpyOffset) * h,
          (0.5 + cpxOffset) * w,
          0.4 * h
        )
      );
      fig2.add(
        new go.PathSegment(go.PathSegment.Bezier, 0, 0.3 * h, (0.5 - cpxOffset) * w, 0.4 * h, 0, (0.3 + cpyOffset) * h)
      );
      geo.spot1 = new go.Spot(0, 0.4);
      geo.spot2 = new go.Spot(1, 0.9);
      return geo;
    });

    diagram.nodeTemplate = $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
      },
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Shape,
        {
          name: "SHAPE",
          fill: "white",
          portId: "",
          fromSpot: go.Spot.AllSides,
          toSpot: go.Spot.AllSides,
        },
        new go.Binding("fill", "color"),
        new go.Binding("figure", "figure"),
        new go.Binding("width", "width"),
        new go.Binding("height", "height"),
        new go.Binding("stroke", "stroke"),
        new go.Binding("fontSize", "fontSize")
      ),
      $(go.TextBlock, { margin: 8, editable: true }, new go.Binding("text").makeTwoWay())
    );

    diagram.groupTemplate = $(
      go.Group,
      "Spot",
      {
        layerName: "Background",
        ungroupable: true,
        locationSpot: go.Spot.Center,
        selectionObjectName: "BODY",
        computesBoundsAfterDrag: true, // allow dragging out of a Group that uses a Placeholder
        handlesDragDropForMembers: true, // don't need to define handlers on Nodes and Links
        avoidable: false,
        // layout: $(go.LayeredDigraphLayout),
      },
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
      $(
        go.Panel,
        "Auto",
        { name: "BODY" },
        $(
          go.Shape,
          {
            parameter1: 10,
            fill: "white",
            strokeWidth: 2,
            stroke: "transparent",
            portId: "",
            cursor: "pointer",
            fromLinkable: true,
            toLinkable: true,
            fromLinkableDuplicates: true,
            toLinkableDuplicates: true,
            fromSpot: go.Spot.AllSides,
            toSpot: go.Spot.AllSides,
          },
          new go.Binding("fill"),
          new go.Binding("stroke", "color"),
          new go.Binding("strokeWidth", "thickness"),
          new go.Binding("strokeDashArray", "dash")
        ),
        $(go.Placeholder, { background: "transparent", margin: 10 })
      ),
      $(
        go.TextBlock,
        {
          alignment: go.Spot.Top,
          alignmentFocus: go.Spot.Bottom,
          font: "bold 12pt sans-serif",
          editable: true,
        },
        new go.Binding("text"),
        new go.Binding("stroke", "color")
      )
    );

    // go.Shape.defineArrowheadGeometry(
    //   "Database",
    //   "F1 m 0,0 l 8,4 -8,4 2,-4 z"
    // );
    diagram.linkTemplate = $(
      go.Link,
      {
        routing: go.Link.AvoidsNodes,
        // corner: 10,
      },
      new go.Binding("routing", "routing"),
      new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
      new go.Binding("toSpot", "toSpot", go.Spot.parse),
      $(
        go.Shape,
        { strokeWidth: 2, stroke: "#000" },
        new go.Binding("stroke", "color"),
        new go.Binding("strokeDashArray", "dash"),
        new go.Binding("strokeWidth", "thickness")
      ),
      $(
        go.Shape,
        { fromArrow: "Backward", visible: false },
        new go.Binding("fromArrow", "fromArrow"),
        new go.Binding("visible", "dir", (dir) => dir === 2),
        new go.Binding("fill", "arrowColor"),
        new go.Binding("scale", "scale", (t) => (2 + t) / 3)
      ),
      $(
        go.Shape,
        { toArrow: "Standard" },
        new go.Binding("toArrow", "toArrow"),
        new go.Binding("visible", "dir", (dir) => dir >= 1),
        new go.Binding("fill", "arrowColor"),
        new go.Binding("scale", "scale", (t) => (2 + t) / 3)
      ),
      $(
        go.TextBlock,
        { alignmentFocus: new go.Spot(0, 1, -4, 0), editable: false },
        new go.Binding("text").makeTwoWay(), // TwoWay due to user editing with TextEditingTool
        new go.Binding("stroke", "textColor")
      )
    );

    diagram.addModelChangedListener((e) => {
      console.log(e);
      handleDiagramChange(diagram);
    });
    // const f = go.Shape.getFigureGenerators().toArray();
    // debugger;
    // console.log(f);
    return diagram;
  };

  useEffect(() => {
    let newd: NodeData[] = [
      ...flowData.nodeData,
      {
        key: "group_1",
        isGroup: true,
        text: "System Hazard Analysis",
        fill: "#FFF",
        loc: "502.28857421875 -62.8548583984375",
      },
      {
        key: "group_2",
        isGroup: true,
        text: "Design FMEA",
        fill: "#FFF",
        loc: "649.674560546875 428.1939005533854",
      },
    ];
    let newLink: LinkData[] = [...flowData.linkData];
    if (impactedDeliverableValue === 1) {
      const idItem = impactedDeliverable.find((x) => x.id === impactedDeliverableValue);
      newd = [
        ...flowData.nodeData,
        {
          key: "group_1",
          isGroup: true,
          text: "TK#001 System Hazard Analysis (V1.0)",
          fill: "#FFF",
          loc: "739.352294921875 184.1451416015625",
        },
        {
          key: "group_2",
          isGroup: true,
          text: idItem.name,
          fill: "#FFF",
          loc: "649.674560546875 472.69390055338545",
        },
      ];
      newLink = [...flowData.linkData];
    } else {
      const idItem = impactedDeliverable.find((x) => x.id === impactedDeliverableValue);
      newd = [
        ...flowDataShared.nodeData,
        {
          key: "group_1",
          isGroup: true,
          text: "TK#001 System Hazard Analysis (V1.0)",
          fill: "#FFF",
          loc: "536.352294921875 -56.09452187677665",
        },
        {
          key: "group_2",
          isGroup: true,
          text: idItem.name,
          fill: "#FFF",
          loc: "532.9438197387526 218.37605012165128",
        },
      ];
      newLink = [...flowDataShared.linkData];
    }

    setNodeData(newd);
    setLinkData(newLink);
  }, [impactedDeliverableValue, impactedDeliverable]);
  const diagramDom = useMemo(() => {
    return (
      <ReactDiagram
        ref={diagramRef}
        initDiagram={initDiagram}
        divClassName="diagram-box"
        nodeDataArray={nodeData}
        linkDataArray={linkData}
      />
    );
  }, [diagramRef, nodeData, linkData]);

  return (
    <Container>
      <AppScrollbar
        sx={{
          maxHeight: "calc(100vh - 105px)",
          background: bgColor.main,
          padding: props?.isPadding ? (theme) => theme.spacing(5) : 0,
        }}
      >
        <Box
          sx={{
            padding: "20px",
            background: "#fff",
            boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)",
          }}
        >
          <Box className="relationship-impact-title flex justify-start items-center">
            <Button
              variant="outlined"
              onClick={() => {
                handleOpenRelationshipClick(!relationshipRichOpen);
              }}
              endIcon={
                relationshipRichOpen ? (
                  <AppSvgIcon color="action">{"material-outline:arrow_drop_up"}</AppSvgIcon>
                ) : (
                  <AppSvgIcon color="action">{"material-outline:arrow_drop_down"}</AppSvgIcon>
                )
              }
            >
              <Box className="title">
                <IntlMessages id="workspaces.relationshipAndImpact" />
              </Box>
            </Button>
          </Box>
          {relationshipRichOpen && (
            <Box className="relationship-box flex justify-center items-center">
              <ImpactSummary />
            </Box>
          )}

          <Box className="impact-box flex justify-between items-center flex-wrap">
            <Box className="impact-top flex justify-between items-center">
              <Box className="left-menu">
                <FormControl sx={{ m: 0, minWidth: 120 }}>
                  <Typography>
                    <IntlMessages id="setting.impactSummary.impactedDeliverable" />
                  </Typography>
                  <Select
                    value={(impactedDeliverableValue | 0).toString()}
                    onChange={(event: SelectChangeEvent) => {
                      setImpactedDeliverableValue(Number(event.target.value));
                    }}
                    displayEmpty
                  >
                    {impactedDeliverable.map((x) => {
                      return (
                        <MenuItem key={x.id} value={x.id}>
                          {x.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box className="right-menu">
                <Box className="legends-list">
                  <Button
                    className="text-color-08"
                    sx={{
                      mr: 3,
                      borderRadius: "4px",
                      border: "solid 1px rgba(0, 0, 0, 0.2)",
                    }}
                    variant="outlined"
                    endIcon={
                      Boolean(anchorElRight) ? (
                        <AppSvgIcon color="action">{"material-outline:expand_less"}</AppSvgIcon>
                      ) : (
                        <AppSvgIcon color="action">{"material-outline:expand_more"}</AppSvgIcon>
                      )
                    }
                    onClick={handleOpenRightClick}
                    id="report_cancel"
                  >
                    <IntlMessages id="common.legends" />
                  </Button>
                </Box>
                <Menu
                  id="more-menus"
                  anchorEl={anchorElRight}
                  keepMounted
                  open={Boolean(anchorElRight)}
                  onClose={handleOpenRightClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      marginRight: 2,
                    },
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem>
                    <IntlMessages id="setting.impactSummary.ACauseOfB" />
                  </MenuItem>
                  <MenuItem>
                    <IntlMessages id="setting.impactSummary.ASubItemOfB" />
                  </MenuItem>
                  <MenuItem>
                    <IntlMessages id="setting.impactSummary.BOfAAttribute" />
                  </MenuItem>
                  <MenuItem>
                    <IntlMessages id="setting.impactSummary.BOfADataSource" />
                  </MenuItem>
                  <MenuItem>
                    <IntlMessages id="setting.impactSummary.ABShared" />
                  </MenuItem>
                  <MenuItem>
                    <IntlMessages id="setting.impactSummary.PrimaryColumn" />
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
            <Box className="impact-bottom impact-diagram flex justify-center">{diagramDom}</Box>
          </Box>
        </Box>
      </AppScrollbar>
    </Container>
  );
};

export default RelationshipsAndImpact;
