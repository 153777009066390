import IntlMessages from "@crema/utility/IntlMessages";
import Box from "@mui/material/Box";
import React, { ReactNode } from "react";
import { boxShadow } from "shared/constants/AppConst";

interface ContentHeaderProps {
  titleId?: String;
  children?: ReactNode;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({ titleId, children }) => {
  return (
    <Box
      sx={{
        px: (theme) => theme.spacing(5),
        height: "48px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: boxShadow.cardBoxShadow,
        position: "relative",
        background: (theme) => theme.palette.background.paper,
      }}
      className="flex justify-between items-center"
    >
      {titleId && (
        <h2>
          <IntlMessages id={titleId} />
        </h2>
      )}
      {children && children}
    </Box>
  );
};

export default ContentHeader;
