import Icon from "@mui/material/Icon";
import * as React from "react";

const CybersecuritySvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <rect fill="#00875A" opacity=".1" width="20" height="20" rx="4" />
      <g transform="translate(3.525 2.5)" fill="#00875A" fillRule="nonzero">
        <path d="m6.477 0 .074.008 6.137 1.364a.34.34 0 0 1 .266.333v6.818A6.477 6.477 0 0 1 0 8.523V1.705c0-.16.11-.299.267-.333L6.403.008a.35.35 0 0 1 .148 0zm0 3.41c-.941 0-1.704.762-1.704 1.704v1.704H3.75a.34.34 0 0 0-.34.341v3.41c0 .187.152.34.34.34h5.454a.34.34 0 0 0 .341-.34v-3.41a.34.34 0 0 0-.34-.34H8.181V5.113c0-.942-.763-1.705-1.705-1.705z" />
        <path d="M6.477 4.091c-.565 0-1.022.458-1.022 1.023v1.704H7.5V5.114c0-.565-.458-1.023-1.023-1.023z" />
        <circle cx="6.477" cy="8.864" r="1" />
      </g>
    </g>
  </svg>
);

const CybersecurityIcon: React.FunctionComponent = () => {
  return <Icon component={CybersecuritySvg} />;
};

export default CybersecurityIcon;
