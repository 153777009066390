import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, IconButton, InputAdornment, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import AddIcon from "components/Icons/AddIcon";
import MyCollapseTable from "components/MyCollapseTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";
import { CollapseColumn } from "types/models/Common";

import TabTable from "../components/TabTable";
import { RiskPolicyDataModel } from "./models";

const Container = styled(Box)(({ theme }) => ({
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell": {
    border: `1px solid  ${borderColor.dividerColor}`,
  },
  ".table-toolbar": {
    padding: theme.spacing(2, 0),
    ".refresh-btn": {
      color: textColor[85],
    },
    ".add-button": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const initColumns: CollapseColumn[] = [
  {
    id: "name",
    label: "common.name",
    collapse: true,
  },
  {
    id: "type",
    label: "common.type",
  },
  {
    id: "description",
    label: "common.description",
  },
  {
    id: "displayOrder",
    label: "setting.product.column.displayOrder",
  },
  {
    id: "isBased",
    label: "common.is.based",
    align: "left",
    format: (value, record) => {
      return (
        <Box sx={{ display: "flex" }}>
          {value ? (
            <AppSvgIcon size={16} color="#6dc347">
              material-solid:done
            </AppSvgIcon>
          ) : (
            <AppSvgIcon size={16} color="action">
              material-solid:close
            </AppSvgIcon>
          )}
        </Box>
      );
    },
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    format: (value, record) => {
      return <></>;
    },
  },
];

const initRows: RiskPolicyDataModel[] = [
  {
    id: 1,
    name: "Safety Risk Policy",
    type: "Safety Risk Policy",
    description: "Safety Risk Ranking",
    displayOrder: 1,
    isBased: true,
  },
  {
    id: 2,
    name: "Security Risk Policy",
    type: "Security Risk Policy",
    description: "Security Risk Ranking",
    displayOrder: 2,
    isBased: true,
  },
  {
    id: 3,
    name: "FMEA",
    type: "FMEA",
    description: "FMEA Configuration",
    displayOrder: 3,
    isBased: false,
  },
  {
    id: 4,
    name: "dFMEA",
    type: "FMEA",
    description: "test",
    displayOrder: 4,
    isBased: false,
  },
];

interface RiskPolicyProps {}
const RiskPolicy = (props: RiskPolicyProps) => {
  const [columns, setColumns] = useState([...initColumns]);
  const [rows, setRows] = useState([...initRows]);

  const handleEditClick = (record: RiskPolicyDataModel) => {
    console.log("Edit: ", record);
  };
  const handleDeleteClick = (record: RiskPolicyDataModel) => {
    console.log("Delete:", record);
  };

  useEffect(() => {
    const newColumns: CollapseColumn[] = [];
    initColumns.forEach((c) => {
      if (c.id === "actions") {
        newColumns.push({
          id: "actions",
          label: "setting.product.column.actions",
          format: (value, record) => {
            return (
              <Box>
                <IconButton
                  sx={{ padding: "2px" }}
                  onClick={() => {
                    handleEditClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:edit"}
                  </AppSvgIcon>
                </IconButton>
                <IconButton
                  sx={{ padding: "2px" }}
                  onClick={() => {
                    handleDeleteClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:delete"}
                  </AppSvgIcon>
                </IconButton>
              </Box>
            );
          },
        });
      } else {
        newColumns.push(c);
      }
    });
    setColumns([...newColumns]);
  }, []);

  const handleAddClick = () => {
    console.log("handleAddClick");
  };

  const toolbar = (
    <Box className="table-toolbar flex justify-between">
      <Box></Box>
      <Box className="table-toolbar-actions flex justify-center items-center">
        <Button
          className="refresh-btn"
          startIcon={<AppSvgIcon color={textColor["65"]}>{"material-outline:refresh"}</AppSvgIcon>}
        >
          <IntlMessages id="common.refresh" />
        </Button>
        <Button
          variant="text"
          className="add-button"
          color="primary"
          startIcon={<AddIcon isBlue={true} />}
          onClick={handleAddClick}
        >
          <IntlMessages id="common.add" />
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <Container className="module-level-box">
        <MyCollapseTable
          toolbar={toolbar}
          columns={columns}
          rows={rows}
          showPage={true}
          collapse={true}
          renderChildren={(x) => <TabTable rowId={x.id} editable={true} />}
        />
      </Container>
    </>
  );
};

export default RiskPolicy;
