import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Card, CardContent, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import CircularLoading from "components/CircularLoading";
import AddIcon from "components/Icons/AddIcon";
import MyCollapseTable from "components/MyCollapseTable";
import { useEffect, useState } from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";
import { CollapseColumn } from "types/models/Common";

import ContentHeader from "../components/ContentHeader";

const Container = styled(Box)(({ theme }) => ({
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell": {
    border: `1px solid  ${borderColor.dividerColor}`,
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  ".table-toolbar": {
    padding: theme.spacing(2, 0),
  },
}));

const SecondContainer = styled(Box)(({ theme }) => ({
  "&.sub-table": {
    padding: theme.spacing(0),
    ".MuiTableContainer-root": {
      padding: theme.spacing(0),
    },
  },
}));

const createSecondData = async (id: number | string) => {
  const sd: CategoriesData[] = [];
  const p = new Promise<CategoriesData[]>((resolve, reject) => {
    setTimeout(() => {
      switch (id) {
        case 1:
          sd.push({
            id: Number(`${id}01`),
            description: "Hardware",
            abbreviation: "Hardware",
            displayOrder: 0,
          });
          sd.push({
            id: Number(`${id}02`),
            description: "Human Factor",
            abbreviation: "Human Factor",
            displayOrder: 1,
          });
          sd.push({
            id: Number(`${id}03`),
            description: "Software",
            abbreviation: "Software",
            displayOrder: 2,
          });
          break;
        case 2:
          sd.push({
            id: Number(`${id}01`),
            description: "Overdose",
            abbreviation: "OV",
            displayOrder: 0,
          });
          sd.push({
            id: Number(`${id}02`),
            description: "Underdoes",
            abbreviation: "UD",
            displayOrder: 1,
          });
          sd.push({
            id: Number(`${id}03`),
            description: "Delay of Therapy",
            abbreviation: "DLT",
            displayOrder: 2,
          });
          sd.push({
            id: Number(`${id}04`),
            description: "Incorrect therapy",
            abbreviation: "IT",
            displayOrder: 3,
          });
          sd.push({
            id: Number(`${id}05`),
            description: "Air embolism",
            abbreviation: "AIR",
            displayOrder: 4,
          });
          sd.push({
            id: Number(`${id}06`),
            description: "Trauma",
            abbreviation: "TR",
            displayOrder: 5,
          });
          sd.push({
            id: Number(`${id}07`),
            description: "Electric shock",
            abbreviation: "ELE",
            displayOrder: 6,
          });
          sd.push({
            id: Number(`${id}08`),
            description: "Infection",
            abbreviation: "IFE",
            displayOrder: 7,
          });
          sd.push({
            id: Number(`${id}09`),
            description: "Allergic response",
            abbreviation: "ALR",
            displayOrder: 8,
          });
          sd.push({
            id: Number(`${id}10`),
            description: "Exanguanation",
            abbreviation: "EX",
            displayOrder: 9,
          });
          break;
        case 3:
          sd.push({
            id: Number(`${id}01`),
            description: "Physical Hazard",
            abbreviation: "PHY",
            displayOrder: 0,
          });
          sd.push({
            id: Number(`${id}02`),
            description: "Moral Hazard",
            abbreviation: "MOR",
            displayOrder: 1,
          });
          sd.push({
            id: Number(`${id}03`),
            description: "Psychological Hazard",
            abbreviation: "PSY",
            displayOrder: 2,
          });
          sd.push({
            id: Number(`${id}04`),
            description: "Legal Hazard",
            abbreviation: "LEG",
            displayOrder: 4,
          });
          break;
        case 4:
          sd.push({
            id: Number(`${id}01`),
            description: "Yes",
            abbreviation: "Y",
            displayOrder: 0,
          });
          sd.push({
            id: Number(`${id}02`),
            description: "No",
            abbreviation: "N",
            displayOrder: 1,
          });
          break;

        default:
          break;
      }

      resolve(sd);
    }, 200);
  });
  const r = await p;
  return r;
};

const SecondTable = (props: { rowId: number | string; initColumns: CollapseColumn[] }) => {
  const { rowId, initColumns } = props;
  const [columns, setColumns] = useState(initColumns);

  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState([]);

  const handleEditClick = (value) => {
    console.log("second:::", value);
  };
  const handleDeleteClick = (value) => {
    console.log("second:::", value);
  };
  const createSecondDataFn = async (rowId) => {
    setLoading(true);
    const res = await createSecondData(rowId);
    setRows(res);
    setLoading(false);
  };
  useEffect(() => {
    const newColumns: CollapseColumn[] = [];
    initColumns.forEach((c) => {
      if (c.id === "actions") {
        newColumns.push({
          ...c,
          format: (value, record) => {
            return (
              <Box sx={{ height: "20px" }}>
                <IconButton
                  sx={{
                    padding: "2px",
                  }}
                  onClick={() => {
                    handleEditClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:edit"}
                  </AppSvgIcon>
                </IconButton>
                <IconButton
                  sx={{
                    padding: "2px",
                  }}
                  onClick={() => {
                    handleDeleteClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:delete"}
                  </AppSvgIcon>
                </IconButton>
              </Box>
            );
          },
        });
      } else {
        newColumns.push(c);
      }
    });
    setColumns([...newColumns]);
    createSecondDataFn(rowId);
  }, []);

  const handleAddClick = () => {
    console.log("handle second add click");
  };

  const toolbar = (
    <Box className="table-toolbar flex justify-between">
      <Box></Box>
      <Box className="table-toolbar-actions flex justify-center items-center">
        <Button
          className="refresh-btn"
          startIcon={<AppSvgIcon color={textColor["65"]}>{"material-outline:refresh"}</AppSvgIcon>}
        >
          <IntlMessages id="common.refresh" />
        </Button>
        <Button
          variant="text"
          className="add-button"
          color="primary"
          startIcon={<AddIcon isBlue={true} />}
          onClick={handleAddClick}
        >
          <IntlMessages id="common.add" />
        </Button>
      </Box>
    </Box>
  );

  return (
    <SecondContainer className="sub-table">
      <CircularLoading loading={loading}>
        <MyCollapseTable toolbar={toolbar} columns={columns} rows={rows} showPage={false} collapse={false} />
      </CircularLoading>
    </SecondContainer>
  );
};

interface CategoriesData {
  id: string | number;
  description: string;
  abbreviation: string;
  displayOrder: string | number;
}

const initColumns: CollapseColumn[] = [
  {
    id: "description",
    label: "Description",
    collapse: true,
  },
  {
    id: "abbreviation",
    label: "Abbreviation",
  },
  {
    id: "displayOrder",
    label: "Display Order",
  },
  {
    id: "actions",
    label: "",
  },
];

const initParentData: CategoriesData[] = [
  {
    id: 1,
    description: "Types of Cause",
    abbreviation: "Types of Cause",
    displayOrder: 0,
  },
  {
    id: 2,
    description: "Harms",
    abbreviation: "HM",
    displayOrder: 1,
  },
  {
    id: 3,
    description: "Hazard",
    abbreviation: "HZ",
    displayOrder: 2,
  },
  {
    id: 4,
    description: "FMEA",
    abbreviation: "FM",
    displayOrder: 3,
  },
];

const createNewData = (id: number | string, oldArray: CategoriesData[], newData: CategoriesData) => {
  const sd: CategoriesData[] = [...oldArray];

  const oldMaxIndex = oldArray[0] ? Number(oldArray[0].id) : 0;

  sd.push({
    ...newData,
    id: oldMaxIndex + 1,
  });
};

interface CategoriesProps {}

const Categories = (props: CategoriesProps) => {
  const [columns, setColumns] = useState(initColumns);

  const [data, setData] = useState(initParentData);

  const handleEditClick = (value) => {
    console.log(value);
  };
  const handleDeleteClick = (value) => {
    console.log(value);
  };
  useEffect(() => {
    const newColumns: CollapseColumn[] = [];
    initColumns.forEach((c) => {
      if (c.id === "actions") {
        newColumns.push({
          ...c,
          format: (value, record) => {
            return (
              <Box sx={{ height: "20px" }}>
                <IconButton
                  sx={{
                    padding: "2px",
                  }}
                  onClick={() => {
                    handleEditClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:edit"}
                  </AppSvgIcon>
                </IconButton>
                <IconButton
                  sx={{
                    padding: "2px",
                  }}
                  onClick={() => {
                    handleDeleteClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:delete"}
                  </AppSvgIcon>
                </IconButton>
              </Box>
            );
          },
        });
      } else {
        newColumns.push(c);
      }
    });
    setColumns([...newColumns]);
  }, []);

  const handleParentAddClick = () => {
    console.log("handleParentAddClick");
  };

  const toolbar = (
    <Box className="table-toolbar flex justify-between">
      <Box></Box>
      <Box className="table-toolbar-actions flex justify-center items-center">
        <Button
          className="refresh-btn"
          startIcon={<AppSvgIcon color={textColor["65"]}>{"material-outline:refresh"}</AppSvgIcon>}
        >
          <IntlMessages id="common.refresh" />
        </Button>
        <Button
          variant="text"
          className="add-button"
          color="primary"
          startIcon={<AddIcon isBlue={true} />}
          onClick={handleParentAddClick}
        >
          <IntlMessages id="common.add" />
        </Button>
      </Box>
    </Box>
  );

  return (
    <Container>
      <ContentHeader titleId="admin.nav.item.categories" />
      <BoxContainer>
        <AppScrollbar
          sx={{
            maxHeight: "calc(100vh - 104px)",
            padding: (theme) => theme.spacing(5),
          }}
        >
          <Box
            sx={{
              padding: "20px",
              background: "#fff",
              boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)",
            }}
          >
            <MyCollapseTable
              toolbar={toolbar}
              columns={columns}
              rows={data}
              showPage={true}
              collapse={true}
              renderChildren={(row) => <SecondTable rowId={row.id} initColumns={initColumns} />}
            />
          </Box>
        </AppScrollbar>
      </BoxContainer>
    </Container>
  );
};

export default Categories;
