import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Grid, MenuItem, Select } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import SimplePopup from "components/SimplePopup";
import * as React from "react";
import { useState } from "react";

const IntegrationComponentProducts = [
  {
    id: 1,
    name: "SubSys4 Main(R1.0)",
  },
  {
    id: 2,
    name: "SubSys5 Main(R1.0)",
  },
  {
    id: 3,
    name: "SubSys6 Main(R1.0)",
  },
  {
    id: 4,
    name: "SubSys7 Main(R1.0)",
  },
];

interface AddIntegrationComponentModalProps {
  open: boolean;
  currentProductId: number | string;
  handleClose: () => void;
}

const AddIntegrationComponentModal = (props: AddIntegrationComponentModalProps) => {
  const { open, currentProductId, handleClose } = props;

  const [integratedId, setIntegratedId] = useState<string>("");

  const handleSave = () => {
    console.log();
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <SimplePopup
      handleClose={handleCancel}
      open={open}
      save={handleSave}
      titleId="products.relationships.addIntegrationComponent"
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "800px",
          minWidth: "800px",
        },
        "& .MuiDialogContent-root": {
          padding: 0,
        },
        "& .add-form": {
          width: "100%",
          padding: "12px 24px",
        },
      }}
    >
      <Box>
        <Box className="flex items-center add-form">
          <Grid container spacing={2} className="items-center">
            <Grid item xs={5} md={5}>
              <Box>
                <IntlMessages id="products.relationships.addIntegrationComponent.new.release" />:
              </Box>
            </Grid>
            <Grid item xs={7} md={7}>
              <Select
                labelId="select-header"
                label=""
                value={integratedId}
                // labelWidth={100}
                onChange={(e) => setIntegratedId(e.target.value)}
                fullWidth
              >
                {IntegrationComponentProducts.map((x) => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </SimplePopup>
  );
};

export default AddIntegrationComponentModal;
