import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import MyTree from "components/MyTree";
import MyTreeTable from "components/MyTreeTable";
import { allScopeFolders } from "mock/ScopeData";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppState } from "redux/store";
import { Column } from "types/models/Common";
import { ScopeFolderModel } from "types/models/Scope";

import EditModal from "./EditModal";

const TreeContainer = styled(Box)(({ theme }) => ({
  height: "calc(100% - 56px)",
  width: "auto",
  pt: "12px",
  pl: "20px",
}));

export interface DataWithCheckbox {
  id: string;
  checked: boolean;
  items?: DataWithCheckbox[];
  [x: string]: any;
}

const getCheckedIds = (data: DataWithCheckbox[]) => {
  let checkedIds: string[] = [];
  for (let item of data) {
    if (item.checked) {
      checkedIds.push(item.id);
    }
    if (item.items && item.items.length > 0) {
      checkedIds.push(...getCheckedIds(item.items));
    }
  }
  return checkedIds;
};

interface ChangeScopeProps {
  edit: boolean;
  width?: number | string;
  dataSet?: ScopeFolderModel[];
}

const deepFind = (allScopes: any[], thisScopes: any[]) => {
  let newAll = [];
  for (let a of allScopes) {
    let na = { ...a };

    let thisSIndex = -1;
    for (let i = 0; i < thisScopes.length; i += 1) {
      const d = thisScopes[i];
      if (d.id === a.id) {
        thisSIndex = i;
        na.inScope = true;
        break;
      }
    }
    let thisScopeChildren = [...thisScopes];
    if (thisSIndex >= 0) {
      thisScopeChildren = thisScopes[thisSIndex].children;
    }

    if (na.children && thisScopeChildren) {
      const newChildren = deepFind(na.children, thisScopeChildren);
      na.children = [...newChildren];
    }
    newAll.push(na);
  }
  return newAll;
};
const ChangeScope = (props: ChangeScopeProps) => {
  const { edit, width, dataSet } = props;

  const settings = useSelector<AppState, AppState["settings"]>(({ settings }) => settings);

  const [editModalOpen, setEditModalOpen] = useState(false);

  const [editId, setEditId] = useState<string | undefined>();

  const [allScopes, setAllScopes] = useState<ScopeFolderModel[]>(allScopeFolders["Risk Management"].dataSourceDataSet);
  useEffect(() => {
    console.log(settings);
    if (settings.adminSelectedModuleId) {
      const allScopeData = allScopeFolders[settings.adminSelectedModuleId];
      console.log(allScopeData);
      if (allScopeData) {
        setAllScopes(allScopeData.dataSourceDataSet);
      }
    }
  }, [settings]);

  const handleCheckChange = (record, checked, name) => {
    if (name === "allowToEdit") {
      // change edit checkbox
      // setEditModalOpen(true);
      setEditId(record.id);
    }
    console.log(`record: ${record.id}, checked: ${checked}, name: ${name}`);
  };
  const [columns, setColumns] = useState<Column[]>([
    {
      id: "name",
      label: "common.name",
      minWidth: 300,
      align: "left",
    },
    {
      id: "inScope",
      label: "change.scope.in",
      minWidth: 80,
      align: "left",
      format: (value, record) => {
        return (
          <Box>
            <FormGroup>
              <FormControlLabel
                // className="justify-center"
                sx={{ padding: 0 }}
                control={
                  <Checkbox
                    sx={{
                      "&.MuiCheckbox-root": {
                        padding: "2px",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                    disabled={!edit}
                    name={`inScope_${record.id}`}
                    onChange={(e) => {
                      handleCheckChange(record, e.target.checked, "inScope");
                    }}
                    // defaultChecked={value}
                    checked={value}
                  />
                }
                label=""
              />
            </FormGroup>
          </Box>
        );
      },
    },
    {
      id: "allowToEdit",
      label: "change.scope.allow.edit",
      minWidth: 80,
      align: "left",
      format: (value, record) => {
        return (
          <Box>
            <FormGroup>
              <FormControlLabel
                // className="justify-center"
                sx={{ padding: 0 }}
                control={
                  <Checkbox
                    sx={{
                      "&.MuiCheckbox-root": {
                        padding: "2px",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                    disabled={!edit}
                    name={`allowToEdit_${record.id}`}
                    onChange={(e) => {
                      handleCheckChange(record, e.target.checked, "allowToEdit");
                      // setChecked(e.target.checked);
                    }}
                  />
                }
                label=""
              />
            </FormGroup>
          </Box>
        );
      },
    },
    {
      id: "allowToLink",
      label: "change.scope.allow.link",
      minWidth: 80,
      align: "left",
      format: (value, record) => {
        return (
          <Box>
            <FormGroup>
              <FormControlLabel
                // className="justify-center"
                sx={{ padding: 0 }}
                control={
                  <Checkbox
                    sx={{
                      "&.MuiCheckbox-root": {
                        padding: "2px",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                    disabled={!edit}
                    name={`allowToLink_${record.id}`}
                    onChange={(e) => {
                      handleCheckChange(record, e.target.checked, "allowToLink");
                      // setChecked(e.target.checked);
                    }}
                  />
                }
                label=""
              />
            </FormGroup>
          </Box>
        );
      },
    },
    {
      id: "customize",
      label: "change.scope.customize",
      minWidth: 80,
      align: "left",
      format: (value, record) => {
        return (
          <Box>
            <FormGroup>
              <FormControlLabel
                // className="justify-center"
                sx={{ padding: 0 }}
                control={
                  <Checkbox
                    sx={{
                      "&.MuiCheckbox-root": {
                        padding: "2px",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                    disabled={!edit}
                    name={`customize_${record.id}`}
                    onChange={(e) => {
                      handleCheckChange(record, e.target.checked, "customize");
                    }}
                  />
                }
                label=""
              />
            </FormGroup>
          </Box>
        );
      },
    },
  ]);

  useEffect(() => {
    const cList: Column[] = [
      {
        id: "name",
        label: "common.name",
        minWidth: 300,
        align: "left",
      },
      {
        id: "inScope",
        label: "change.scope.in",
        minWidth: 80,
        align: "left",
        format: (value, record) => {
          return (
            <Box>
              <FormGroup>
                <FormControlLabel
                  // className="justify-center"
                  sx={{ padding: 0 }}
                  control={
                    <Checkbox
                      sx={{
                        "&.MuiCheckbox-root": {
                          padding: "2px",
                        },
                        "& .MuiSvgIcon-root": { fontSize: 16 },
                      }}
                      disabled={!edit}
                      name={`inScope_${record.id}`}
                      onChange={(e) => {
                        handleCheckChange(record, e.target.checked, "inScope");
                      }}
                      checked={value}
                      // defaultChecked={value}
                    />
                  }
                  label=""
                />
              </FormGroup>
            </Box>
          );
        },
      },

      {
        id: "allowToEdit",
        label: "change.scope.allow.edit",
        minWidth: 80,
        align: "left",
        format: (value, record) => {
          return (
            <Box>
              <FormGroup>
                <FormControlLabel
                  // className="justify-center"
                  sx={{ padding: 0 }}
                  control={
                    <Checkbox
                      sx={{
                        "&.MuiCheckbox-root": {
                          padding: "2px",
                        },
                        "& .MuiSvgIcon-root": { fontSize: 16 },
                      }}
                      disabled={!edit}
                      name={`allowToEdit_${record.id}`}
                      // indeterminate={true}
                      onChange={(e) => {
                        handleCheckChange(record, e.target.checked, "allowToEdit");
                        // setChecked(e.target.checked);
                      }}
                    />
                  }
                  label=""
                />
              </FormGroup>
            </Box>
          );
        },
      },
      {
        id: "allowToLink",
        label: "change.scope.allow.link",
        minWidth: 80,
        align: "left",
        format: (value, record) => {
          return (
            <Box>
              <FormGroup>
                <FormControlLabel
                  // className="justify-center"
                  sx={{ padding: 0 }}
                  control={
                    <Checkbox
                      sx={{
                        "&.MuiCheckbox-root": {
                          padding: "2px",
                        },
                        "& .MuiSvgIcon-root": { fontSize: 16 },
                      }}
                      disabled={!edit}
                      name={`allowToLink_${record.id}`}
                      onChange={(e) => {
                        handleCheckChange(record, e.target.checked, "allowToLink");
                        // setChecked(e.target.checked);
                      }}
                    />
                  }
                  label=""
                />
              </FormGroup>
            </Box>
          );
        },
      },
      {
        id: "customize",
        label: "change.scope.customize",
        minWidth: 80,
        align: "left",
        format: (value, record) => {
          return (
            <Box>
              <FormGroup>
                <FormControlLabel
                  // className="justify-center"
                  sx={{ padding: 0 }}
                  control={
                    <Checkbox
                      sx={{
                        "&.MuiCheckbox-root": {
                          padding: "2px",
                        },
                        "& .MuiSvgIcon-root": { fontSize: 16 },
                      }}
                      disabled={!edit}
                      name={`customize_${record.id}`}
                      onChange={(e) => {
                        handleCheckChange(record, e.target.checked, "customize");
                      }}
                    />
                  }
                  label=""
                />
              </FormGroup>
            </Box>
          );
        },
      },
    ];
    setColumns(cList);
  }, [edit]);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (edit) {
      if (allScopes) {
        let newScopeList = deepFind(allScopes, dataSet);
        setRows(newScopeList);
      }
    } else {
      setRows(dataSet);
    }
  }, [edit, dataSet, allScopes]);

  // const [selected, setSelected] = React.useState<any>("Risk Management");
  // const [checkedIds, setCheckedIds] = React.useState<string[]>(getCheckedIds(dataSet));

  // const onNodeSelect = (event: object, value: string) => {
  //   setSelected(value);

  //   let checkedNodeIds = [...checkedIds];
  //   if (checkedNodeIds.includes(value)) {
  //     const index = checkedNodeIds.indexOf(value);
  //     checkedNodeIds.splice(index, 1);
  //     //todo: check items and uncheck items
  //   } else {
  //     checkedNodeIds.push(value);
  //     //todo: check items and check items too.
  //   }
  //   setCheckedIds(checkedNodeIds);
  // };

  const handleEditOnSave = () => {
    console.log("handleEditOnSave");
    setEditModalOpen(false);
  };
  return (
    <>
      <TreeContainer>
        <MyTreeTable maxHeight={"100%"} columns={columns} rows={rows} setReloadClickCell={() => {}} />
        <EditModal
          editId={editId}
          sourceData={dataSet}
          open={editModalOpen}
          onSave={handleEditOnSave}
          handleClose={() => {
            setEditModalOpen(false);
          }}
        />
        {/* <MyTree
          selected={selected}
          onNodeSelect={onNodeSelect}
          data={dataSet}
          useStyledTree={true}
          isBold={false}
          showCheckbox={true}
          showFolder={true}
          checkedNodeIds={checkedIds}
          sx={{ width: width }}
        /> */}
      </TreeContainer>
    </>
  );
};

export default ChangeScope;
