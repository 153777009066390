import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { bgColor, borderColor, boxShadow, tabStyle } from "shared/constants/AppConst";

const Container = styled(Box)(({ theme }) => ({
  ".tabs-container": {
    background: theme.palette.background.paper,
    padding: theme.spacing(5),
    boxShadow: boxShadow.cardBoxShadow,
  },
}));

const Feeds = () => {
  const location = useLocation();
  React.useEffect(() => {
    if (location?.pathname.indexOf("analysis") !== -1) {
      setIndex(0);
    } else if (location?.pathname.indexOf("manual") !== -1) {
      setIndex(1);
    } else if (location?.pathname.indexOf("complaint") !== -1) {
      setIndex(2);
    } else if (location?.pathname.indexOf("capa") !== -1) {
      setIndex(3);
    }
  }, [location]);

  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  return (
    <Container>
      <AppScrollbar
        sx={{
          width: "100%",
          height: "100%",
          padding: (theme) => theme.spacing(5),
          background: bgColor.main,
        }}
      >
        <Box className="tabs-container">
          <Box
            className="flex justify-between items-center"
            sx={{
              borderBottom: 1,
              borderColor: borderColor.dividerColor,
            }}
          >
            <Tabs
              value={index}
              onChange={handleChangeTab}
              sx={{
                ...tabStyle,
                "& .MuiButtonBase-root": {
                  minHeight: "48px",
                  textTransform: "none",
                },
              }}
            >
              <Tab
                onClick={() => {
                  navigate("analysis");
                }}
                label={<IntlMessages id="workspace.impact.analysis" />}
              />
              <Tab
                onClick={() => {
                  navigate("manual");
                }}
                label={<IntlMessages id="workspace.manual" />}
              />
              <Tab
                onClick={() => {
                  navigate("complaint");
                }}
                label={<IntlMessages id="workspace.complaint.system" />}
              />
              <Tab
                onClick={() => {
                  navigate("capa");
                }}
                label={<IntlMessages id="workspace.capa.system" />}
              />
            </Tabs>
          </Box>
          <Box sx={{ pt: (theme) => theme.spacing(2) }}>
            <Outlet />
          </Box>
        </Box>
      </AppScrollbar>
    </Container>
  );
};

export default Feeds;
