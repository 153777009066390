import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "components/CustomTable";
import React from "react";
import { Column } from "types/models/Common";

const RisksContainer = styled(Box)(({ theme }) => ({
  padding: "8px 0",
  minWidth: "1200px",
  ".details-content": {
    marginTop: "12px",
  },
}));

const initRows1: any[] = [
  {
    causal_chain: [
      "The pole is designed to support a downward force of at least 5 times the weight of the pump.",
      "(1) Infusion rate is faster than programmed",
    ],
    worstCase: false,
    hazardous_situation: "Incorrectly controlled delivery",
    harm: "Cardiac Arrhythmia",
    riskId: "R#160891",
    comment_1: "has some risks",
    comment_2: "reduce risks",
    severity_1: "3",
    severity_2: "3",
    p1_1: "4",
    p1_2: "4",
    p2_1: "5",
    p2_2: "2",
    ph_1: "PR",
    ph_2: "OC",
    acceptance_1: "High",
    acceptance_2: "MED",
  },
  {
    causal_chain: ["Clamp fails to hold the pump on the pole", "(1) Infusion rate is faster than programmed"],
    worstCase: false,
    hazardous_situation: "Incorrectly controlled delivery",
    harm: "Death",
    riskId: "R#134583",
    comment_1: "has some risks",
    comment_2: "reduce risks",
    severity_1: "2",
    severity_2: "2",
    p1_1: "3",
    p1_2: "3",
    p2_1: "5",
    p2_2: "2",
    ph_1: "PR",
    ph_2: "RE",
    acceptance_1: "High",
    acceptance_2: "MED",
  },
  {
    causal_chain: ["User interface message changed and improved to be clear", "cassette door is broken"],
    worstCase: true,
    hazardous_situation: "Incorrectly controlled delivery",
    harm: "Delay of treatment",
    riskId: "R#495234",
    comment_1: "Worst Case",
    comment_2: "Worst Case",
    severity_1: "4",
    severity_2: "4",
    p1_1: "5",
    p1_2: "5",
    p2_1: "5",
    p2_2: "2",
    ph_1: "FR",
    ph_2: "FR",
    acceptance_1: "High",
    acceptance_2: "High",
  },
  {
    causal_chain: ["periodically check the healthiness of pump"],
    worstCase: false,
    hazardous_situation: "Incorrectly controlled delivery",
    harm: "Loss of mobility",
    riskId: "R#4626670",
    comment_1: "",
    comment_2: "",
    severity_1: "1",
    severity_2: "1",
    p1_1: "2",
    p1_2: "2",
    p2_1: "5",
    p2_2: "2",
    ph_1: "OC",
    ph_2: "RE",
    acceptance_1: "MED",
    acceptance_2: "LOW",
  },
];

const Risks = () => {
  const [columns1, setColumns1] = React.useState<Column[]>([
    {
      id: "causal_chain",
      label: "common.causal.chain",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        if (value.length < 2) {
          return (
            <Box className="inline-block">
              <Box className="inline-block" sx={{ color: "red" }}>
                {record?.worstCase ? "*" : ""}
              </Box>
              <Box className="inline-block">{value}</Box>
            </Box>
          );
        } else {
          return (
            <Box className="inline-block">
              {value.map((item, index) => {
                return (
                  <Box key={`${item}`} className="inline-block">
                    <Box className="inline-block" sx={{ color: "red", mx: index > 0 ? 2 : 0 }}>
                      {index > 0 ? ">" : ""}
                    </Box>
                    <Box className="inline-block" sx={{ color: "red" }}>
                      {record?.worstCase ? "*" : ""}
                    </Box>
                    <Box className="inline-block">{item}</Box>
                  </Box>
                );
              })}
            </Box>
          );
        }
      },
    },
    {
      id: "hazardous_situation",
      label: "common.hazardous.situation",
      minWidth: 220,
      align: "left",
      format: (value, record) => {
        if (value) {
          return (
            <Box className="inline-block">
              <Box className="inline-block" sx={{ color: "red" }}>
                {record?.worstCase ? "*" : ""}
              </Box>
              <Box className="inline-block">{value}</Box>
            </Box>
          );
        }
        return;
      },
    },
    {
      id: "harm",
      label: "common.harm",
      minWidth: 220,
      align: "left",
      format: (value, record) => {
        if (value) {
          return (
            <Box className="inline-block">
              <Box className="inline-block" sx={{ color: "red" }}>
                {record?.worstCase ? "*" : ""}
              </Box>
              <Box className="inline-block">{value}</Box>
            </Box>
          );
        }
        return;
      },
    },
    {
      id: "riskId",
      label: "common.risk.id",
      minWidth: 200,
      align: "left",
    },
    {
      id: "comments",
      label: "common.risk.comments",
      minWidth: 200,
      align: "left",
      format: (_, record) => {
        return (
          <Box>
            <Box>{record.comment_1}</Box>
            <Box sx={{ fontWeight: "bold" }}>{record.comment_2}</Box>
          </Box>
        );
      },
    },
    {
      id: "severity",
      label: "common.severity",
      minWidth: 200,
      align: "left",
      format: (_, record) => {
        return (
          <Box>
            <Box>{record.severity_1}</Box>
            <Box sx={{ fontWeight: "bold" }}>{record.severity_2}</Box>
          </Box>
        );
      },
    },
    {
      id: "p1",
      label: "common.p1",
      minWidth: 100,
      align: "left",
      format: (_, record) => {
        return (
          <Box>
            <Box>{record.p1_1}</Box>
            <Box sx={{ fontWeight: "bold" }}>{record.p1_2}</Box>
          </Box>
        );
      },
    },
    {
      id: "p2",
      label: "common.p2",
      minWidth: 100,
      align: "left",
      format: (_, record) => {
        return (
          <Box>
            <Box>{record.p2_1}</Box>
            <Box sx={{ fontWeight: "bold" }}>{record.p2_2}</Box>
          </Box>
        );
      },
    },
    {
      id: "ph",
      label: "common.ph",
      minWidth: 100,
      align: "left",
      format: (_, record) => {
        return (
          <Box>
            <Box>{record.ph_1}</Box>
            <Box sx={{ fontWeight: "bold" }}>{record.ph_2}</Box>
          </Box>
        );
      },
    },
    {
      id: "acceptance",
      label: "common.acceptance",
      minWidth: 100,
      align: "left",
      format: (_, record) => {
        return (
          <Box>
            <Box>{record.acceptance_1}</Box>
            <Box sx={{ fontWeight: "bold" }}>{record.acceptance_2}</Box>
          </Box>
        );
      },
    },
  ]);
  const [rows1, setRows1] = React.useState<any[]>(initRows1);

  return (
    <RisksContainer>
      <Box className="details-content">
        <Box
          sx={{
            "& tbody tr td:nth-of-type(2)": {
              background: "#f5f7ff",
            },
          }}
        >
          <CustomTable columns={columns1} rows={rows1} />
        </Box>
      </Box>
    </RisksContainer>
  );
};

export default Risks;
