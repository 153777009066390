import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { fetchError, fetchStart, fetchSuccess } from "../../../../redux/actions";
import { AuthUser } from "../../../../types/models/AuthUser";
import jwtAxios, { setAuthToken } from "./index";

interface JWTAuthContextProps {
  user: AuthUser | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => {},
  logout: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthProviderProps {
  children: ReactNode;
}

const JWTAuthProvider: React.FC<JWTAuthProviderProps> = ({ children }) => {
  const [jwtAuthData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem("token");

      if (!token || token !== "1751462351") {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      } else {
        setJWTAuthData({
          user: {},
          isLoading: false,
          isAuthenticated: true,
        });
      }
      setAuthToken(token);
      // jwtAxios
      //   .get("/auth")
      //   .then(({ data }) =>
      //     setJWTAuthData({
      //       user: data,
      //       isLoading: false,
      //       isAuthenticated: true,
      //     })
      //   )
      //   .catch(() =>
      //     setJWTAuthData({
      //       user: undefined,
      //       isLoading: false,
      //       isAuthenticated: false,
      //     })
      //   );
    };

    getAuthUser();
  }, []);

  const signInUser = async ({ email, password }: { email: string; password: string }) => {
    dispatch(fetchStart());
    try {
      // const { data } = await jwtAxios.post("auth", { email, password });
      // localStorage.setItem("token", data.token);
      // setAuthToken(data.token);
      // const res = await jwtAxios.get("/auth");
      // setJWTAuthData({
      //   user: res.data,
      //   isAuthenticated: true,
      //   isLoading: false,
      // });

      if (email === "nextgen@gessnet.com" && password === "0104qmspace!") {
        const data = { token: "1751462351" };
        localStorage.setItem("token", data.token);
        setAuthToken(data.token);
        // const res = await jwtAxios.get("/auth");
        setJWTAuthData({
          user: {},
          isAuthenticated: true,
          isLoading: false,
        });
        dispatch(fetchSuccess());
      } else {
        dispatch(fetchError("Something went wrong"));
      }
    } catch (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError("Something went wrong"));
    }
  };

  const signUpUser = async ({ name, email, password }: { name: string; email: string; password: string }) => {
    dispatch(fetchStart());
    try {
      const { data } = await jwtAxios.post("users", { name, email, password });
      localStorage.setItem("token", data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get("/auth");
      setJWTAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError("Something went wrong"));
    }
  };

  const logout = async () => {
    localStorage.removeItem("token");
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...jwtAuthData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthProvider;
