import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import { ListModel } from "components/CustomTable/CustomTableModel";
import TableToolBar from "components/CustomTable/TableToolBar";
import ExportDropdown from "components/ExportDropdown";
import CustomizeIcon from "components/Icons/CustomizeIcon";
import FolderIcon from "components/Icons/FolderIcon";
import TaskItemIcon from "components/Icons/TaskItemIcon";
import MyTreeTable from "components/MyTreeTable";
import React from "react";
import { useNavigate } from "react-router-dom";
import { textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

const TasksContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  ".table-tree": {
    width: "100%",
    height: "calc(100vh - 275px)",
    paddingTop: "8px",
  },
  ".hight_light_color": {
    color: "#2e6ccb",
  },
  ".cursor": {
    cursor: "pointer",
  },
  ".bold_font": {
    fontWeight: "bold",
  },
  ".normal_font": {
    fontWeight: "normal",
  },
  ".padding_right": {
    paddingRight: "8px",
  },
  ".margin_right": {
    marginRight: "8px",
  },
  ".title_color": {
    color: "rgba(0,0,0,0.85)",
  },
  ".sub_title_color": {
    color: "rgba(0,0,0,0.85)",
  },
}));

const Tasks = () => {
  const navigate = useNavigate();
  const [columns, setColumns] = React.useState<Column[]>([
    {
      id: "stage",
      label: "common.stage",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        switch (record.level) {
          case 1:
            return (
              <Box sx={{ display: "inline-block" }} className="bold_font title_color">
                {value}
              </Box>
            );
          case 2:
            return (
              <Box sx={{ display: "inline-block" }} className="bold_font title_color">
                {value}
              </Box>
            );
          case 3:
            return (
              <Box sx={{ display: "inline-block" }} className="sub_title_color">
                <Box
                  onClick={() => {
                    linkToTask(record);
                  }}
                  className="hight_light_color cursor padding_right"
                  sx={{ display: "inline-block" }}
                >
                  {record.taskNo}
                </Box>
                <Box sx={{ display: "inline-block" }}>{value}</Box>
              </Box>
            );
          case 4:
            return (
              <Box sx={{ display: "inline-block" }} className="sub_title_color normal_font">
                <Box
                  onClick={() => {
                    linkToTask(record);
                  }}
                  sx={{ display: "inline-block" }}
                  className="hight_light_color cursor padding_right normal_font"
                >
                  {record.taskNo}
                </Box>
                <Box sx={{ display: "inline-block" }}>{value}</Box>
              </Box>
            );
          default:
            return (
              <Box sx={{ display: "inline-block" }} className="sub_title title_color">
                {value}
              </Box>
            );
        }
      },
    },
    {
      id: "version",
      label: "workspace.form.version",
      minWidth: 150,
      align: "left",
    },
    {
      id: "status",
      label: "workspace.form.status",
      minWidth: 100,
      align: "left",
      format: (value, record) => {
        if (value) {
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AppSvgIcon
                onClick={() => {
                  deleteClick(record);
                }}
                color="action"
                size="8px"
                className="margin_right"
              >
                material-solid:fiber_manual_record
              </AppSvgIcon>
              <Box sx={{ display: "inline-block" }}>{value}</Box>
            </Box>
          );
        }
        return null;
      },
    },
    {
      id: "duration",
      label: "common.duration",
      minWidth: 100,
      align: "left",
    },
    // {
    //   id: "assigned_date",
    //   label: "common.assigned.date",
    //   minWidth: 150,
    //   align: "left",
    // },
    // {
    //   id: "due_date",
    //   label: "common.due.date",
    //   minWidth: 200,
    //   align: "left",
    // },
    {
      id: "predecessors",
      label: "common.columns.predecessors",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        return (
          <Box
            onClick={() => {
              linkToWorkspaces(record);
            }}
            className="hight_light_color cursor"
          >
            {value}
          </Box>
        );
      },
    },
    {
      id: "feeds",
      label: "common.feeds",
      minWidth: 100,
      align: "left",
    },
    {
      id: "actions",
      label: "setting.product.column.actions",
      minWidth: 120,
      format: (value, record) => {
        if (record.level === 1) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", height: "20px" }}>
              <AppSvgIcon
                onClick={() => {
                  addClick(record);
                }}
                color={textColor["65"]}
                size="16px"
                className="margin_right cursor"
              >
                material-solid:add_circle
              </AppSvgIcon>
            </Box>
          );
        } else if (record.level === 2 || record.level === 3) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", height: "20px" }}>
              <AppSvgIcon
                onClick={() => {
                  addClick(record);
                }}
                color={textColor["65"]}
                size="16px"
                className="margin_right cursor"
              >
                material-solid:add_circle
              </AppSvgIcon>
              <AppSvgIcon
                onClick={() => {
                  editClick(record);
                }}
                color={textColor["65"]}
                size="16px"
                className="margin_right cursor"
              >
                material-solid:edit
              </AppSvgIcon>
              <AppSvgIcon
                onClick={() => {
                  deleteClick(record);
                }}
                color={textColor["65"]}
                size="16px"
                className="margin_right cursor"
              >
                material-solid:delete
              </AppSvgIcon>
            </Box>
          );
        } else if (record.level === 4) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", height: "20px" }}>
              <Box sx={{ marginRight: "24px" }}></Box>
              <AppSvgIcon
                onClick={() => {
                  editClick(record);
                }}
                color={textColor["65"]}
                size="16px"
                className="margin_right cursor"
              >
                material-solid:edit
              </AppSvgIcon>
              <AppSvgIcon
                onClick={() => {
                  deleteClick(record);
                }}
                color={textColor["65"]}
                size="16px"
                className="margin_right cursor"
              >
                material-solid:delete
              </AppSvgIcon>
            </Box>
          );
        }
        return (
          <Box sx={{ display: "flex", alignItems: "center", height: "20px" }}>
            <AppSvgIcon
              onClick={() => {
                addClick(record);
              }}
              color={textColor["65"]}
              size="16px"
              className="margin_right cursor"
            >
              material-solid:add_circle
            </AppSvgIcon>
            <AppSvgIcon
              onClick={() => {
                editClick(record);
              }}
              color={textColor["65"]}
              size="16px"
              className="margin_right cursor"
            >
              material-solid:edit
            </AppSvgIcon>
            <AppSvgIcon
              onClick={() => {
                deleteClick(record);
              }}
              color={textColor["65"]}
              size="16px"
              className="margin_right cursor"
            >
              material-solid:delete
            </AppSvgIcon>
          </Box>
        );
      },
    },
  ]);

  const [rows, setRows] = React.useState<any[]>([
    {
      id: "1",
      stage: "Pre-Production",
      taskNo: "",
      version: "",
      status: "",
      duration: "",
      assigned_date: "",
      due_date: "",
      predecessors: "",
      level: 1,
      feeds: "",
      startIcon: (
        <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
          <FolderIcon />
        </Box>
      ),
      children: [
        {
          id: "1-1",
          taskNo: "",
          stage: "1.Concept & Requirements",
          version: "",
          status: "",
          duration: "",
          assigned_date: "",
          due_date: "",
          predecessors: "",
          level: 2,
          feeds: "",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
              <FolderIcon />
            </Box>
          ),
          children: [
            {
              id: "1-1-1",
              taskNo: "TK#101",
              stage: "User Needs",
              version: "V1.0",
              status: "Draft",
              duration: "1wk",
              assigned_date: "01/15/2022",
              due_date: "01/15/2022",
              predecessors: "",
              level: 3,
              feeds: "",
              type: "und",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
              module: "Design Controls",
              processId: "User Needs",
              v: "100",
              children: [
                {
                  id: "1-1-1-1",
                  taskNo: "STK#111",
                  stage: "Sub Task 001",
                  version: "V1.0",
                  status: "Draft",
                  duration: "3days",
                  assigned_date: "01/15/2022",
                  due_date: "01/15/2022",
                  predecessors: "",
                  level: 4,
                  type: "und",
                  module: "Design Controls",
                  processId: "User Needs",
                  v: "100",
                  feeds: "",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
                {
                  id: "1-1-1-2",
                  taskNo: "STK#112",
                  stage: "Sub Task 002",
                  version: "V1.0",
                  status: "Draft",
                  duration: "2days",
                  assigned_date: "01/15/2022",
                  due_date: "01/15/2022",
                  predecessors: "",
                  level: 4,
                  type: "und",
                  module: "Design Controls",
                  processId: "User Needs",
                  v: "100",
                  feeds: "",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
            {
              id: "1-1-2",
              taskNo: "TK#001",
              stage: "System Hazard Analysis",
              version: "V1.0",
              status: "Draft",
              duration: "7days",
              assigned_date: "01/15/2022",
              due_date: "01/15/2022",
              predecessors: "TK#101 User Needs",
              level: 3,
              feeds: "",
              type: "sha",
              module: "Risk Management",
              processId: "System Hazard Analysis",
              v: "100",
              type1: "und",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
              module1: "Design Controls",
              processId1: "User Needs",
              v1: "100",
              children: [
                {
                  id: "1-1-2-1",
                  taskNo: "STK#015",
                  stage: "Sub Task 023",
                  version: "V1.0",
                  status: "Draft",
                  duration: "4days",
                  assigned_date: "01/15/2022",
                  due_date: "01/15/2022",
                  predecessors: "",
                  level: 4,
                  type: "sha",
                  module: "Risk Management",
                  processId: "System Hazard Analysis",
                  v: "100",
                  feeds: "",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
            {
              id: "1-1-3",
              taskNo: "TK#203",
              stage: "Design Input Requirements",
              version: "V1.0",
              status: "Draft",
              duration: "6days",
              assigned_date: "01/15/2022",
              due_date: "01/15/2022",
              predecessors: "TK#001 System Hazard Analysis",
              level: 3,
              type: "dir",
              module: "Design Controls",
              processId: "Design Input Requirements",
              v: "100",
              type1: "sha",
              module1: "Risk Management",
              processId1: "System Hazard Analysis",
              v1: "100",
              feeds: "",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "1-2",
          taskNo: "",
          stage: "2. Design & Development",
          version: "",
          status: "",
          duration: "",
          assigned_date: "",
          due_date: "",
          predecessors: "",
          level: 2,
          feeds: "",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
              <FolderIcon />
            </Box>
          ),
          children: [
            {
              id: "1-2-1",
              taskNo: "TK#002",
              stage: "Design FMEA",
              version: "V1.0",
              status: "Draft",
              duration: "1wk",
              assigned_date: "01/15/2022",
              due_date: "01/15/2022",
              predecessors: "TTK#001 System Hazard Analysis",
              level: 3,
              feeds: "",
              type: "df",
              module: "Risk Management",
              processId: "Design FMEA",
              v: "100",
              type1: "sha",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
              module1: "Risk Management",
              processId1: "System Hazard Analysis",
              v1: "100",
            },
            {
              id: "1-2-2",
              taskNo: "TK#003",
              stage: "Process FMEA",
              version: "V1.0",
              status: "Draft",
              duration: "1wk",
              assigned_date: "01/15/2022",
              due_date: "01/15/2022",
              predecessors: "TK#001 System Hazard Analysis",
              level: 3,
              feeds: "",
              type: "sha",
              module: "Risk Management",
              processId: "System Hazard Analysis",
              v: "100",
              type1: "sha",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
              module1: "Risk Management",
              processId1: "System Hazard Analysis",
              v1: "100",
            },
          ],
        },
        {
          id: "1-3",
          taskNo: "",
          stage: "3. Verification & Validation",
          version: "",
          status: "",
          duration: "",
          assigned_date: "",
          due_date: "",
          predecessors: "",
          level: 2,
          feeds: "",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
              <FolderIcon />
            </Box>
          ),
          children: [
            {
              id: "1-3-1",
              taskNo: "TK#301",
              stage: "Verification Products",
              version: "V1.0",
              status: "Draft",
              duration: "1wk",
              assigned_date: "01/15/2022",
              due_date: "01/15/2022",
              predecessors: "TK#203 Design Input Requirements",
              level: 3,
              type: "sha",
              module: "Risk Management",
              processId: "System Hazard Analysis",
              v: "100",
              type1: "dir",
              module1: "Design Controls",
              processId1: "Design Input Requirements",
              v1: "100",
              feeds: "",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              id: "1-3-2",
              taskNo: "TK#302",
              stage: "Validation Products",
              version: "V1.0",
              status: "Draft",
              duration: "1wk",
              assigned_date: "01/15/2022",
              due_date: "01/15/2022",
              predecessors: "TK#203 Design Input Requirements",
              level: 3,
              type: "sha",
              module: "Risk Management",
              processId: "System Hazard Analysis",
              v: "100",
              type1: "dir",
              module1: "Design Controls",
              processId1: "Design Input Requirements",
              v1: "100",
              feeds: "",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "1-4",
          taskNo: "",
          stage: "4. Regulatory Approval",
          version: "",
          status: "",
          duration: "",
          assigned_date: "",
          due_date: "",
          predecessors: "",
          level: 2,
          feeds: "",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
              <FolderIcon />
            </Box>
          ),
        },
      ],
    },
  ]);

  const addClick = (record) => {
    console.log("add:", record);
  };

  const editClick = (record) => {
    console.log("edit:", record);
  };

  const deleteClick = (record) => {
    console.log("delete:", record);
  };

  const linkToWorkspaces = (record) => {
    navigate(
      `/products/123/deliverables/${record.module1}/${record.processId1}/${record.type1}/${record.v1}/worksheet`
    );
  };

  const linkToTask = (record) => {
    if (record.level === 3) {
      navigate(`/products/123/deliverables/${record.module}/${record.processId}/${record.type}/${record.v}/worksheet`);
    } else {
      navigate(
        `/products/123/deliverables/${record.module}/${record.processId}/${record.type}/${record.v}/collaboration`
      );
    }
  };

  const [searchText, setSearchText] = React.useState("");
  const [filter1, setFilter1] = React.useState<any>("");
  const [filterList1] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter1",
    },
  ]);
  const [filter2, setFilter2] = React.useState<any>("");
  const [filterList2] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter2",
    },
  ]);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
  };

  return (
    <TasksContainer>
      {/* <Box className="table-tool-bar">
        {
          <TableToolBar
            showSearch={true}
            searchText={searchText}
            requestSearch={requestSearch}
            saveFilter={() => {
              console.log("save filter");
            }}
            filters={[
              {
                value: filter1,
                list: filterList1,
                setValue: setFilter1,
              },
              {
                value: filter2,
                list: filterList2,
                setValue: setFilter2,
              },
            ]}
          >
            {
              <>
                <ExportDropdown exportToWord={() => {}} exportToExcel={() => {}} exportToPdf={() => {}} />
                <Button
                  sx={{
                    marginLeft: 2,
                    color: textColor[85],
                  }}
                  variant="text"
                  onClick={() => {}}
                  startIcon={<CustomizeIcon />}
                >
                  <IntlMessages id="common.customize" />
                </Button>
              </>
            }
          </TableToolBar>
        }
      </Box> */}
      <AppScrollbar className="table-tree">
        <MyTreeTable columns={columns} rows={rows} maxHeight="calc(100vh - 342px) " />
      </AppScrollbar>
    </TasksContainer>
  );
};

export default Tasks;
