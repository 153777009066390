import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Menu, MenuItem, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import ButtonMenu from "components/ButtonMenu";
import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import ModuleIcon from "components/Icons/ModuleIcon";
import MyTree from "components/MyTree";
import { ModuleMenuList, ProcessData } from "mock/ModuleData";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { borderColor, textColor } from "shared/constants/AppConst";

const SearchTreeContainer = styled(Box)(({ theme }) => ({}));

interface SearchTreeProps {
  handleSelectProcess: React.Dispatch<any>;
}

const deepFind =
  (pred) =>
  ([x, ...xs] = []) =>
    x && (pred(x) ? x : deepFind(pred)(x.items) || deepFind(pred)(xs));

const findById = (id) => (dataSet) => deepFind((o) => o.id === id)(dataSet);

const SearchTree: React.FC<SearchTreeProps> = ({ handleSelectProcess }) => {
  const [selectedModule, setSelectedModule] = React.useState<ButtonMenuListItemModel>(ModuleMenuList[0]);
  const [processDataSet, setProcessDataSet] = React.useState(ProcessData[selectedModule.id]);
  const [selected, setSelected] = React.useState<any>(processDataSet[0].id);
  const [searchText, setSearchText] = React.useState<string>("");
  const [actionList, setActionList] = React.useState<ButtonMenuListItemModel[]>([
    {
      id: "1",
      labelId: "common.add",
      icon: <AddCircleIcon sx={{ fontSize: "16px !important" }} />,
    },
    {
      id: "2",
      labelId: "common.edit",
      icon: <EditIcon sx={{ fontSize: "16px !important" }} />,
    },
    {
      id: "3",
      labelId: "common.delete",
      icon: <DeleteIcon sx={{ fontSize: "16px !important" }} />,
    },
  ]);
  const { messages } = useIntl();

  React.useEffect(() => {
    window.addEventListener("click", handleClickDom);
    return () => {
      window.removeEventListener("click", handleClickDom);
    };
  }, []);

  const handleClickDom = (e) => {
    console.log(e);
    if (e.target.id.includes("more_button_")) {
      handleClick(e.target);
    }
  };

  React.useEffect(() => {}, [selectedModule]);

  const handleSelectedModule = (item: ButtonMenuListItemModel) => {
    setSelectedModule(item);
    const processDataSet = ProcessData[item.id];
    setProcessDataSet(processDataSet);
    const focusId = processDataSet[0].id;
    setSelected(focusId);

    const process = findById(focusId)(processDataSet);
    handleSelectProcess(process);
  };

  const requestSearch = (value: string) => {
    setSearchText(value);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSelected = (item: ButtonMenuListItemModel) => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    console.log(event);
    setAnchorEl(event);
    // setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onNodeSelect = (event: object, value: string) => {
    const selectedProcess = findById(value)(processDataSet);
    if (selectedProcess.isModule || !selectedProcess.isFolder) {
      setSelected(value);
      handleSelectProcess(selectedProcess);
    }
  };

  const actionMenu = useMemo(() => {
    return (
      <Menu
        id="button-menus"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& .MuiSvgIcon-root": {
            marginRight: 2,
          },
          "& .MuiMenuItem-root": {
            color: textColor[85],
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {actionList.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => {
              handleSelected(item);
            }}
          >
            {item.icon}
            <IntlMessages id={item.labelId} />
          </MenuItem>
        ))}
      </Menu>
    );
  }, [anchorEl]);

  return (
    <SearchTreeContainer>
      <Box
        sx={{
          my: (theme) => theme.spacing(3),
          mx: (theme) => theme.spacing(5),
        }}
      >
        <ButtonMenu
          selectedValue={selectedModule}
          setSelectedValue={handleSelectedModule}
          list={ModuleMenuList}
          moduleIcon={<ModuleIcon />}
          fullWidth={true}
          sxStyle={{
            variant: "outlined",
            sx: {
              color: textColor[85],
              border: `solid 1px ${borderColor.dividerColor}`,
              display: "flex",
              height: "32px",
              fontSize: "15px",
              justifyContent: "flex-start",
              "& .MuiButton-endIcon": {
                position: "absolute",
                right: "0px",
              },
            },
          }}
        />
      </Box>
      <Box
        sx={{
          pb: (theme) => theme.spacing(2),
          mx: (theme) => theme.spacing(5),
        }}
      >
        <TextField
          variant="standard"
          value={searchText}
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value)}
          placeholder={String(messages["common.search"])}
          InputProps={{
            startAdornment: (
              <AppSvgIcon size={16} color="action">
                {"material-outline:search"}
              </AppSvgIcon>
            ),
            endAdornment: (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: searchText ? "visible" : "hidden" }}
                  onClick={() => {
                    requestSearch("");
                  }}
                >
                  <AppSvgIcon size={16} color="action">
                    {"material-outline:clear"}
                  </AppSvgIcon>
                </IconButton>
              </Box>
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: 1,
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            "& .MuiSvgIcon-root": {
              mr: 0.5,
            },
            "& .MuiInputBase-root": {
              padding: "0px 14px",
              height: "32px",
            },
            "& .MuiOutlinedInput-input": {
              padding: "0px",
            },
            "& .MuiInput-underline:before": {
              borderBottom: 1,
              borderColor: "divider",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          ml: (theme) => theme.spacing(5),
        }}
      >
        <AppScrollbar
          sx={{
            height: "calc(100vh - 260px)",
          }}
        >
          <MyTree
            selected={selected}
            onNodeSelect={onNodeSelect}
            data={processDataSet}
            useStyledTree={true}
            showMoreIcon={true}
            showFolder={true}
            // onMoreIconClick={handleClick}
            showNodeStatus={true}
          />
        </AppScrollbar>
      </Box>
      {actionMenu}
    </SearchTreeContainer>
  );
};

export default SearchTree;
