import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import NoDataIcon from "components/Icons/NoDataIcon";
import React from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import { TableToolBarModel } from "./CustomTableModel";
import TableToolBar from "./TableToolBar";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  ".my_table_container": {
    width: "100%",
  },
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_body_row_2 .table_body_cell": {
    background: bgColor.tableBodyRow2,
  },
  ".table_cell:first-of-type": {
    borderLeft: `1px solid  ${borderColor.dividerColor}`,
    // position: "sticky",
    // left: 0,
    // zIndex: 3,
  },
  "thead tr:first-of-type .table_header_cell": {
    borderTop: `1px solid  ${borderColor.dividerColor}`,
  },
  ".table_cell": {
    padding: "10px 12px",
    lineHeight: "20px",
    borderRight: `1px solid  ${borderColor.dividerColor}`,
    borderBottom: `1px solid  ${borderColor.dividerColor}`,
  },
  ".table_body_cell_add_row": {
    padding: "9px 12px",
  },
  ".table_header_cell": {
    padding: "9px 12px",
  },
  ".table_actions": {
    // position: "sticky",
    // right: 0,
    // zIndex: 3,
  },
}));

interface CustomTableProps {
  toolBar?: TableToolBarModel;
  columns: Column[];
  rows: any[];
  showPage?: boolean;
  maxHeight?: number | string;
  addRow?: () => void;
  addRowTextId?: string;
  groupTextId?: string;
}

const CustomTable: React.FC<CustomTableProps> = (props: CustomTableProps) => {
  const { showPage = true, maxHeight, addRow, addRowTextId, groupTextId } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { toolBar, columns, rows } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      {toolBar && <TableToolBar {...toolBar} />}
      <Container>
        <TableContainer className="my_table_container">
          <AppScrollbar sx={{ maxHeight: maxHeight ? maxHeight : 350, marginTop: toolBar ? "8px" : "0px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ background: "#F0F0F0", color: "rgba(0,0,0,0.85)", fontSize: "14px" }}>
                {groupTextId && (
                  <TableRow className="table_header">
                    <TableCell colSpan={columns.length} className="table_cell table_header_cell" align="center">
                      <IntlMessages id={groupTextId} />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow className="table_header">
                  {columns.map((column) => (
                    <TableCell
                      sx={
                        column.width
                          ? {
                              width: column.width,
                              minWidth: column.minWidth,
                              top: groupTextId ? 40 : 0,
                            }
                          : {
                              minWidth: column.minWidth,
                              top: groupTextId ? 40 : 0,
                            }
                      }
                      className={
                        column.id === "actions"
                          ? "table_cell table_header_cell table_actions"
                          : "table_cell table_header_cell"
                      }
                      key={column.id}
                      align={column.align}
                    >
                      {column.label ? <IntlMessages id={column.label} /> : column.labelText}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="table_body">
                {rows && rows.length > 0 && (
                  <>
                    {showPage
                      ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                          return (
                            <TableRow
                              hover
                              sx={{
                                borderBottom: "1px solid  rgba(0, 0, 0, 0.09)",
                              }}
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                              className={index % 2 === 0 ? "table_body_row_1" : "table_body_row_2"}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                if (column.id === "actions") {
                                  return (
                                    <TableCell
                                      key={index.toString() + column.id}
                                      className="table_cell table_body_cell table_actions"
                                      align={column.align}
                                    >
                                      {column.format ? column.format("", row) : ""}
                                    </TableCell>
                                  );
                                } else {
                                  return (
                                    <TableCell
                                      key={index.toString() + column.id}
                                      className="table_cell table_body_cell"
                                      align={column.align}
                                    >
                                      {column.format ? column.format(value, row) : value}
                                    </TableCell>
                                  );
                                }
                              })}
                            </TableRow>
                          );
                        })
                      : rows.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              sx={{
                                borderBottom: "1px solid  rgba(0, 0, 0, 0.09)",
                              }}
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                              className={index % 2 === 0 ? "table_body_row_1" : "table_body_row_2"}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                if (column.id === "actions") {
                                  return (
                                    <TableCell
                                      key={index.toString() + column.id}
                                      className="table_cell table_body_cell table_actions"
                                      align={column.align}
                                    >
                                      {column.format ? column.format("", row) : ""}
                                    </TableCell>
                                  );
                                } else {
                                  return (
                                    <TableCell
                                      key={index.toString() + column.id}
                                      className="table_cell table_body_cell"
                                      align={column.align}
                                    >
                                      {column.format ? column.format(value, row) : value}
                                    </TableCell>
                                  );
                                }
                              })}
                            </TableRow>
                          );
                        })}
                  </>
                )}
                {addRowTextId && (
                  <TableRow hover sx={{ borderBottom: "1px solid  rgba(0, 0, 0, 0.09)" }} role="checkbox">
                    {columns.map((column) => {
                      if (column.id === "actions") {
                        return (
                          <TableCell
                            sx={{ minWidth: column.minWidth }}
                            className="table_cell table_body_cell table_body_cell_add_row table_actions"
                            key={column.id}
                            align={column.align}
                          >
                            <Button
                              sx={{
                                height: "20px",
                                paddingTop: "1px",
                                paddingBottom: "1px",
                                "& .MuiButton-startIcon": {
                                  marginRight: "6px",
                                },
                              }}
                              variant="text"
                              startIcon={
                                <AppSvgIcon color="primary" size={16}>
                                  material-solid:add_circle
                                </AppSvgIcon>
                              }
                              onClick={addRow}
                            >
                              {addRowTextId ? <IntlMessages id={addRowTextId} /> : <></>}
                            </Button>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            sx={{ minWidth: column.minWidth }}
                            className="table_cell table_body_cell table_body_cell_add_row"
                            key={column.id}
                            align={column.align}
                          ></TableCell>
                        );
                      }
                    })}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </AppScrollbar>
        </TableContainer>
      </Container>
      <Box
        className="no-data-box"
        sx={{
          display: rows.length > 0 || addRowTextId ? "none" : "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 350,
          width: "100%",
        }}
      >
        <NoDataIcon />
      </Box>
      {showPage && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

export default CustomTable;
