import { Box } from "@mui/material";
import SimplePopup from "components/SimplePopup";
import React from "react";
import { ScopeFolderModel } from "types/models/Scope";

interface EditModalProps {
  open: boolean;
  editId: string | number;
  sourceData: ScopeFolderModel[];
  handleClose: () => void;
  onSave?: () => void;
}

const EditModal: React.FC<EditModalProps> = ({ open, editId, sourceData, handleClose, onSave }) => {
  const save = () => {
    onSave && onSave();
  };

  return (
    <SimplePopup
      handleClose={handleClose}
      open={open}
      titleId="change.scope.edit.attributes"
      okTextId="common.ok"
      save={save}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "1000px",
          width: "1000px",
        },
        "& .MuiDialogContent-root": {
          padding: "20px",
        },
        paddingRight: "50px",
      }}
    >
      <Box sx={{ p: 5 }}>Edit popup with attributes.</Box>
    </SimplePopup>
  );
};

export default EditModal;
