import IntlMessages from "@crema/utility/IntlMessages";
import Paper from "@mui/material/Paper";
import React from "react";

interface PageHeaderProps {
  pageId: String;
}

const PageHeader: React.FC<PageHeaderProps> = ({ pageId }) => {


  return (
    <Paper
      className="page-header"
      sx={{
        display: "flex",
        alignItems: "flex-end",
        marginBottom: "24px",
        borderRadius: 0,
        height: 48,
        boxShadow: "0 2px 8px 0 #0000001f",
        padding: "12px 0px 12px 24px",
      }}
    >


      <h2>
        <IntlMessages id={pageId} />
      </h2>
    </Paper>
  );
};

export default PageHeader;
