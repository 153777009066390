import { Navigate } from "react-router-dom";

import AccessControl from "./AccessControl";
import AdminWrapper from "./AdminWrapper";
import Categories from "./Categories";
import ExportTemplates from "./ExportTemplates";
import General from "./General";
import Groups from "./Groups";
import Licenses from "./Licenses";
import LoginSecurity from "./LoginSecurity";
import Palette from "./Palette";
import Process from "./Process";
import IndustryStandards from "./Process/IndustryStandards";
import Metrics from "./Process/Metrics";
import ProcessFlow from "./Process/ProcessFlow";
import Sops from "./Process/Sops";
import WorkitemLayout from "./Process/WorkitemLayout";
import WorkitemRelationships from "./Process/WorkitemRelationships";
import WorkitemTypes from "./Process/WorkitemTypes";
import ProcessSettings from "./ProcessSettings";
import Checklist from "./ProcessSettings/Checklist";
import Collaboration from "./ProcessSettings/Collaboration";
import DataSources from "./ProcessSettings/DataSources";
import DeliverableWorkflow from "./ProcessSettings/DeliverableWorkflow";
import ImpactSummary from "./ProcessSettings/ImpactSummary";
import Input from "./ProcessSettings/Input";
import ExternalReference from "./ProcessSettings/Input/ExternalReference";
import InternalReference from "./ProcessSettings/Input/InternalReference";
import Required from "./ProcessSettings/Input/Required";
import ItemRelationships from "./ProcessSettings/ItemRelationships";
import Report from "./ProcessSettings/Report";
import Scope from "./ProcessSettings/Scope";
import Worksheet from "./ProcessSettings/Worksheet";
import Analysis from "./ProcessSettings/Worksheet/Analysis";
import Format from "./ProcessSettings/Worksheet/Format";
import Resources from "./ProcessSettings/Worksheet/Resources";
import ProductLifecycles from "./ProductLifecycles";
import ProductRelationships from "./ProductRelationships";
import ProductTypes from "./ProductTypes";
import Rules from "./Rules";
import Cvss from "./Rules/Cvss";
import Cwss from "./Rules/Cwss";
import RiskPolicy from "./Rules/RiskPolicy";
import Upgrade from "./Upgrade";
import Users from "./Users";
import Workflow from "./Workflow";
import Comment from "./Workflow/Comment";
import Deliverable from "./Workflow/Deliverable";
import Feed from "./Workflow/Feed";
import WorkItem from "./Workflow/workItem";
import WorkItemRelationshipTypes from "./WorkitemRelationshipTypes";
import WorkitemAllTypes from "./WorkitemTypes";

export const adminPage = [
  {
    path: "/admin",
    element: <AdminWrapper />,
    children: [
      {
        path: "accessControl",
        element: <AccessControl />,
      },
      {
        path: "palette",
        element: <Palette />,
      },
      {
        path: "exportTemplates",
        element: <ExportTemplates />,
      },
      {
        path: "categories",
        element: <Categories />,
      },
      {
        path: "rules",
        element: <Navigate to="riskPolicy" />,
      },
      {
        path: "rules",
        element: <Rules />,
        children: [
          {
            path: "riskPolicy",
            element: <RiskPolicy />,
          },
          {
            path: "cwss",
            element: <Cwss />,
          },
          {
            path: "cvss",
            element: <Cvss />,
          },
        ],
      },
      {
        path: "workitem",
        element: <Navigate to="types" />,
      },
      {
        path: "workitem",
        element: <WorkitemLayout />,
        children: [
          {
            path: "types",
            element: <WorkitemAllTypes />,
          },
          {
            path: "relationship/types",
            element: <WorkItemRelationshipTypes />,
          },
          {
            path: "relationships",
            element: <ItemRelationships />,
          },
        ],
      },
      {
        path: "process/:type",
        element: <Navigate to="workitem/types" />,
      },
      {
        path: "process/:type",
        element: <Process />,
        children: [
          {
            path: "workitem/types",
            element: <WorkitemTypes />,
          },
          {
            path: "workitem/relationships",
            element: <WorkitemRelationships />,
          },
          {
            path: "industry/standards",
            element: <IndustryStandards />,
          },
          {
            path: "process/flow",
            element: <ProcessFlow />,
          },
          {
            path: "sops",
            element: <Sops />,
          },
          {
            path: "metrics",
            element: <Metrics />,
          },
        ],
      },
      {
        path: "groups",
        element: <Groups />,
      },
      {
        path: "general",
        element: <General />,
      },
      {
        path: "licenses",
        element: <Licenses />,
      },
      {
        path: "resources",
        element: <Resources />,
      },
      {
        path: "processSettings/label",
        element: <ProcessSettings />,
        children: [
          {
            path: "dataSources/:pid",
            element: <DataSources />,
          },
          {
            path: "deliverableWorkflow/:pid",
            element: <DeliverableWorkflow />,
          },
          {
            path: "scope/:pid",
            element: <Scope />,
          },
          {
            path: "input/tab/:pid",
            element: <Input />,
            children: [
              {
                path: "required",
                element: <Required />,
              },
              {
                path: "internalReference",
                element: <InternalReference />,
              },
              {
                path: "externalReference",
                element: <ExternalReference />,
              },
            ],
          },
          {
            path: "collaboration/:pid",
            element: <Collaboration />,
          },
          {
            path: "worksheet/tab/:pid",
            element: <Worksheet />,
            children: [
              {
                path: "format",
                element: <Format />,
              },
              {
                path: "analysis",
                element: <Analysis />,
              },
              // {
              //   path: "perspectives",
              //   element: <Perspectives />,
              // },
              // {
              //   path: "resources",
              //   element: <Resources />,
              // },
            ],
          },
          {
            path: "impactSummary/:pid",
            element: <ImpactSummary />,
          },
          {
            path: "checklist/:pid",
            element: <Checklist />,
          },
          {
            path: "report/:pid",
            element: <Report />,
          },
        ],
      },
      {
        path: "productRelationships",
        element: <ProductRelationships />,
      },
      {
        path: "productLifecycles",
        element: <ProductLifecycles />,
      },
      {
        path: "productTypes",
        element: <ProductTypes />,
      },
      {
        path: "loginSecurity",
        element: <LoginSecurity />,
      },
      {
        path: "upgrade",
        element: <Upgrade />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "workflow/tab",
        element: <Workflow />,
        children: [
          {
            path: "workItem",
            element: <WorkItem />,
          },
          {
            path: "deliverable",
            element: <Deliverable />,
          },
          {
            path: "feed",
            element: <Feed />,
          },
          {
            path: "comment",
            element: <Comment />,
          },
        ],
      },
      {
        path: "workflow",
        element: <Navigate to="tab/workItem" />,
      },
    ],
  },
];
