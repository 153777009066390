import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "components/CustomTable";
import React from "react";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

import ContentHeader from "../components/ContentHeader";

const GroupsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  ".table-container": {
    width: "100%",
    padding: "20px",
    background: "#fff",
    boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)",
  },
}));

const columns: Column[] = [
  {
    id: "level",
    label: "common.level",
    minWidth: 150,
    align: "left",
  },
  {
    id: "group_name",
    label: "common.group.name",
    minWidth: 200,
    align: "left",
  },
  {
    id: "count_of_users",
    label: "common.count.of.users",
    minWidth: 100,
    align: "left",
  },
  {
    id: "product",
    label: "common.product",
    minWidth: 300,
    align: "left",
  },
  {
    id: "releases",
    label: "table.column.releases",
    minWidth: 100,
    align: "left",
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    minWidth: 120,
    format: (value, record) => {
      // Show more
      return (
        <Box sx={{ color: "#2e6ccb" }} className="flex cursor-pointer">
          <Box>
            <IntlMessages id="common.members" />
          </Box>
          <Box sx={{ mx: 2 }}>|</Box>
          <Box>
            <IntlMessages id="common.edit" />
          </Box>
          <Box sx={{ mx: 2 }}>|</Box>
          <Box>
            <IntlMessages id="dashboard.subscriptions" />
          </Box>
          <Box sx={{ mx: 2 }}>|</Box>
          <Box>
            <IntlMessages id="common.delete" />
          </Box>
        </Box>
      );
    },
  },
];

const initRows = [
  {
    id: 5,
    level: "Product",
    group_name: "Product Managers",
    count_of_users: "1",
    product: "Thermometers",
    releases: "R1.1, R2.1",
  },
];

const Groups = () => {
  const [rows, setRows] = React.useState<any[]>(initRows);

  return (
    <GroupsContainer>
      <ContentHeader titleId="admin.nav.item.groups" />
      <AppScrollbar
        sx={{
          height: "calc(100vh - 154px)",
          padding: (theme) => theme.spacing(5),
        }}
      >
        <Box className="table-container">
          <CustomTable
            toolBar={{
              buttons: [
                {
                  id: "common.create.new.group",
                  icon: TableHeaderButtonsIconType.ADD,
                  type: "text",
                  color: "#2e6ccb",
                  isBlue: true,
                  onChange: () => {},
                },
              ],
            }}
            columns={columns}
            rows={rows}
            maxHeight="calc(100vh - 200px)"
            showPage={false}
          />
        </Box>
      </AppScrollbar>
    </GroupsContainer>
  );
};

export default Groups;
