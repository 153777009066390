import AppScrollbar from "@crema/core/AppScrollbar";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import ButtonMenu from "components/ButtonMenu";
import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import ModuleIcon from "components/Icons/ModuleIcon";
import MyTree from "components/MyTree";
import { ModuleMenuList, ProcessData } from "mock/ModuleData";
import React from "react";
import { borderColor, textColor, treeStyle } from "shared/constants/AppConst";

const TreeContainer = styled(Box)(({ theme }) => ({}));

interface TreeProps {
  handleSelectProcess: React.Dispatch<any>;
}

const deepFind =
  (pred) =>
  ([x, ...xs] = []) =>
    x && (pred(x) ? x : deepFind(pred)(x.items) || deepFind(pred)(xs));

const findById = (id) => (dataSet) => deepFind((o) => o.id === id)(dataSet);

const Tree: React.FC<TreeProps> = ({ handleSelectProcess }) => {
  const [selectedModule, setSelectedModule] = React.useState<ButtonMenuListItemModel>(ModuleMenuList[0]);
  const [processDataSet, setProcessDataSet] = React.useState(ProcessData[selectedModule.id]);
  const [selected, setSelected] = React.useState<any>("");

  React.useEffect(() => {}, [selectedModule]);

  const handleSelectedModule = (item: ButtonMenuListItemModel) => {
    setSelectedModule(item);
    const processDataSet = ProcessData[item.id];
    setProcessDataSet(processDataSet);
    setSelected("");
    handleSelectProcess(null);
  };

  const onNodeSelect = (event: object, value: string) => {
    const currentItem = getTreeItem(processDataSet, value);
    if (!currentItem?.items) {
      setSelected(value);
      const selectedProcess = findById(value)(processDataSet);
      handleSelectProcess(selectedProcess);
    }
  };

  const getTreeItem = (tree: any, id) => {
    let res;
    let state = false;
    function readTree(tree, id) {
      if (state) return;
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].id === id) {
          state = true;
          res = tree[i];
        } else {
          if (tree[i].items && tree[i].items.length > 0) {
            readTree(tree[i].items, id);
          }
        }
      }
    }
    readTree(tree, id);
    return res;
  };

  return (
    <TreeContainer>
      <Box
        sx={{
          my: 3,
          mx: 2,
        }}
      >
        <ButtonMenu
          selectedValue={selectedModule}
          setSelectedValue={handleSelectedModule}
          list={ModuleMenuList}
          moduleIcon={<ModuleIcon />}
          fullWidth={true}
          sxStyle={{
            variant: "outlined",
            sx: {
              color: textColor[85],
              border: `solid 1px ${borderColor.dividerColor}`,
              display: "flex",
              fontSize: "15px",
              justifyContent: "flex-start",
              "& .MuiButton-endIcon": {
                position: "absolute",
                right: "0px",
              },
            },
          }}
        />
      </Box>
      <Box
        sx={{
          borderTop: `1px solid ${borderColor.dividerColor}`,
        }}
      >
        <AppScrollbar
          sx={{
            height: "480px",
          }}
        >
          <MyTree
            selected={selected}
            onNodeSelect={onNodeSelect}
            data={processDataSet}
            sx={{
              ...treeStyle,
              background: "#FFF",
              padding: "12px 0px 0px 8px",
              ".not-last-child>.MuiTreeItem-content>.MuiTreeItem-label": {
                fontWeight: "bold",
              },
            }}
          />
        </AppScrollbar>
      </Box>
    </TreeContainer>
  );
};

export default Tree;
