import { Box, List, ListItemButton, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useMemo } from "react";

const LeftMenuContainer = styled(Box)(({ theme }) => ({
  ".MuiListItemButton-root.Mui-selected": {
    margin: "0 12px",
    width: "calc(100% - 24px)",
    backgroundColor: "#2e6ccb",
    borderRadius: "4px",
    color: "#fff",
    ":hover": {
      backgroundColor: "#2d5dee",
    },
  },
}));

export interface MenuData {
  index: number | string;
  type?: string;
  text?: string;
}

interface LeftMenuProps {
  menuList: MenuData[];
  onMenuChange: (menu: MenuData) => void;
}

const LeftMenu = (props: LeftMenuProps) => {
  const { menuList, onMenuChange } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(menuList[0].index);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, menu: MenuData) => {
    setSelectedIndex(menu.index);
    onMenuChange(menu);
  };

  const leftMenuList = useMemo(() => {
    const mList = menuList.map((x) => {
      return (
        <ListItemButton key={x.index} selected={selectedIndex === x.index} onClick={(event) => handleClick(event, x)}>
          <ListItemText primary={x.text} />
        </ListItemButton>
      );
    });
    return mList;
  }, [menuList, selectedIndex]);

  return (
    <LeftMenuContainer>
      <List component={"nav"} aria-label="main mailbox folders">
        {leftMenuList}
      </List>
    </LeftMenuContainer>
  );
};

export default LeftMenu;
