import DeleteIcon from "@mui/icons-material/Delete";
import EastIcon from "@mui/icons-material/East";
import EditIcon from "@mui/icons-material/Edit";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { Box, Checkbox, FormControlLabel, styled } from "@mui/material";
import CustomTable from "components/CustomTable";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Column } from "types/models/Common";

import { itemWorkflowDat as diagramDataSet } from "../ItemWorkflow/components/dummyData";
import FlowDiagram from "../ItemWorkflow/components/FlowDiagram";
import WorkflowTable from "../ItemWorkflow/components/WorkflowTable";

const DiagramContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  ".workflow-diagram-box": {
    width: "100%",
    minHeight: "300px",
    ".diagram-box": {
      width: "100%",
      height: "inherit",
      minHeight: "300px",
    },
  },
}));
const deliverableWorkflowMockData = {
  "Risk Management": {
    statusRows: [
      {
        id: "draft",
        name: "Draft",
        initial: true,
      },
      {
        id: "in_review",
        name: "In Review",
      },
      {
        id: "approved",
        name: "Approved",
      },
      {
        id: "effective",
        name: "Effective",
      },
      {
        id: "rejected",
        name: "Rejected",
      },
    ],
    flowData: {
      nodeData: [
        {
          key: 1,
          text: "●",
          color: "#FFF",
          figure: "Ellipse",
          width: 40,
          height: 40,
          stroke: "#000",
        },
        { key: 2, text: "Draft", color: "#FFF", width: 40, height: 40, stroke: "#000" },
        { key: 3, text: "In Review", color: "#FFF", stroke: "#00FFFF" },
        { key: 4, text: "Approved", color: "#FFF", stroke: "#016FA0" },
        { key: 5, text: "Rejected", color: "#FFF", stroke: "#D9001B" },
      ],
      linkData: [
        {
          key: "1_2",
          from: 1,
          to: 2,
          fromSpot: "Right",
          toSpot: "Left",
          text: "Initialize",
          textColor: "#63A103",
        },
        {
          key: "2_3",
          from: 2,
          to: 3,
          fromSpot: "Right",
          toSpot: "Left",
          text: "Review",
          textColor: "#00AAAA",
        },
        {
          key: "3_2",
          from: 3,
          to: 2,
          fromSpot: "Left",
          toSpot: "Right",
          text: "Rework",
          textColor: "#F59A23",
        },
        {
          key: "3_4",
          from: 3,
          to: 4,
          fromSpot: "Right",
          toSpot: "Left",
          text: "Approve",
          textColor: "#0000FF",
        },
        {
          key: "4_2",
          from: 4,
          to: 2,
          fromSpot: "Top",
          toSpot: "Top",
          text: "Rework",
          textColor: "#F59A23",
        },
        {
          key: "3_5",
          from: 3,
          to: 5,
          fromSpot: "Bottom",
          toSpot: "Top",
          text: "Reject",
          textColor: "#D9001B",
        },
        {
          key: "5_2",
          from: 5,
          to: 2,
          fromSpot: "Left",
          toSpot: "Bottom",
          text: "Rework",
          textColor: "#F59A23",
        },
      ],
    },
    dataJson: {
      class: "GraphLinksModel",
      linkKeyProperty: "key",
      nodeDataArray: [
        {
          key: 1,
          text: "●",
          color: "#FFF",
          figure: "Ellipse",
          width: 40,
          height: 40,
          stroke: "#000",
          loc: "-56.5 67.7560791015625",
        },
        { key: 2, text: "Draft", color: "#FFF", width: 40, height: 40, stroke: "#000", loc: "69.5 67.7560791015625" },
        { key: 3, text: "In Review", color: "#FFF", stroke: "#00FFFF", loc: "259.03564453125 67.7560791015625" },
        { key: 4, text: "Approved", color: "#FFF", stroke: "#016FA0", loc: "466.18603515625 67.7560791015625" },
        { key: 5, text: "Rejected", color: "#FFF", stroke: "#D9001B", loc: "259.18603515625 140.7560791015625" },
      ],
      linkDataArray: [
        { key: "1_2", from: 1, to: 2, fromSpot: "Right", toSpot: "Left", text: "Initialize", textColor: "#63A103" },
        { key: "2_3", from: 2, to: 3, fromSpot: "Right", toSpot: "Left", text: "Review", textColor: "#00AAAA" },
        { key: "3_2", from: 3, to: 2, text: "Rework", textColor: "#F59A23" },
        { key: "3_4", from: 3, to: 4, fromSpot: "Right", toSpot: "Left", text: "Approve", textColor: "#0000FF" },
        { key: "4_2", from: 4, to: 2, fromSpot: "Top", toSpot: "Top", text: "Rework", textColor: "#F59A23" },
        { key: "3_5", from: 3, to: 5, fromSpot: "Bottom", toSpot: "Top", text: "Reject", textColor: "#D9001B" },
        { key: "5_2", from: 5, to: 2, fromSpot: "Left", toSpot: "Bottom", text: "Rework", textColor: "#F59A23" },
      ],
    },
  },
  "Requirement Management": {
    statusRows: [
      {
        id: "draft",
        name: "Draft",
        initial: true,
      },
      {
        id: "in_review",
        name: "In Review",
      },
      {
        id: "approved",
        name: "Approved",
      },
      {
        id: "effective",
        name: "Effective",
      },
      {
        id: "rejected",
        name: "Rejected",
      },
    ],
    flowData: {
      nodeData: [
        {
          key: 1,
          text: "●",
          color: "#FFF",
          figure: "Ellipse",
          width: 40,
          height: 40,
          stroke: "#000",
        },
        { key: 2, text: "Draft", color: "#FFF", width: 40, height: 40, stroke: "#000" },
        { key: 3, text: "In Review", color: "#FFF", stroke: "#00FFFF" },
        { key: 4, text: "Approved", color: "#FFF", stroke: "#016FA0" },
        { key: 5, text: "Rejected", color: "#FFF", stroke: "#D9001B" },
      ],
      linkData: [
        {
          key: "1_2",
          from: 1,
          to: 2,
          fromSpot: "Right",
          toSpot: "Left",
          text: "Initialize",
          textColor: "#63A103",
        },
        {
          key: "2_3",
          from: 2,
          to: 3,
          fromSpot: "Right",
          toSpot: "Left",
          text: "Review",
          textColor: "#00AAAA",
        },
        {
          key: "3_2",
          from: 3,
          to: 2,
          fromSpot: "Left",
          toSpot: "Right",
          text: "Rework",
          textColor: "#F59A23",
        },
        {
          key: "3_4",
          from: 3,
          to: 4,
          fromSpot: "Right",
          toSpot: "Left",
          text: "Approve",
          textColor: "#0000FF",
        },
        {
          key: "4_2",
          from: 4,
          to: 2,
          fromSpot: "Top",
          toSpot: "Top",
          text: "Rework",
          textColor: "#F59A23",
        },
        {
          key: "3_5",
          from: 3,
          to: 5,
          fromSpot: "Bottom",
          toSpot: "Top",
          text: "Reject",
          textColor: "#D9001B",
        },
        {
          key: "5_2",
          from: 5,
          to: 2,
          fromSpot: "Left",
          toSpot: "Bottom",
          text: "Rework",
          textColor: "#F59A23",
        },
      ],
    },
    dataJson: {
      class: "GraphLinksModel",
      linkKeyProperty: "key",
      nodeDataArray: [
        {
          key: 1,
          text: "●",
          color: "#FFF",
          figure: "Ellipse",
          width: 40,
          height: 40,
          stroke: "#000",
          loc: "-56.5 67.7560791015625",
        },
        { key: 2, text: "Draft", color: "#FFF", width: 40, height: 40, stroke: "#000", loc: "69.5 67.7560791015625" },
        { key: 3, text: "In Review", color: "#FFF", stroke: "#00FFFF", loc: "259.03564453125 67.7560791015625" },
        { key: 4, text: "Approved", color: "#FFF", stroke: "#016FA0", loc: "466.18603515625 67.7560791015625" },
        { key: 5, text: "Rejected", color: "#FFF", stroke: "#D9001B", loc: "259.18603515625 140.7560791015625" },
      ],
      linkDataArray: [
        { key: "1_2", from: 1, to: 2, fromSpot: "Right", toSpot: "Left", text: "Initialize", textColor: "#63A103" },
        { key: "2_3", from: 2, to: 3, fromSpot: "Right", toSpot: "Left", text: "Review", textColor: "#00AAAA" },
        { key: "3_2", from: 3, to: 2, text: "Rework", textColor: "#F59A23" },
        { key: "3_4", from: 3, to: 4, fromSpot: "Right", toSpot: "Left", text: "Approve", textColor: "#0000FF" },
        { key: "4_2", from: 4, to: 2, fromSpot: "Top", toSpot: "Top", text: "Rework", textColor: "#F59A23" },
        { key: "3_5", from: 3, to: 5, fromSpot: "Bottom", toSpot: "Top", text: "Reject", textColor: "#D9001B" },
        { key: "5_2", from: 5, to: 2, fromSpot: "Left", toSpot: "Bottom", text: "Rework", textColor: "#F59A23" },
      ],
    },
  },
};

const statusColumns: Column[] = [
  {
    id: "id",
    label: "workspace.column.id",
    minWidth: 50,
    align: "left",
  },
  {
    id: "name",
    label: "common.name",
    minWidth: 200,
    align: "left",
  },
  {
    id: "initial",
    label: "setting.product.column.initial",
    minWidth: 50,
    align: "left",
    format: (value, record) => {
      return value ? "Yes" : "";
    },
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    align: "left",
    format: (value, record) => {
      return (
        <Box>
          <EditIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
          <DeleteIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
          <NorthIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
          <SouthIcon sx={{ cursor: "pointer", fontSize: "16px" }} />
        </Box>
      );
    },
  },
];

const transitionColumns: Column[] = [
  {
    id: "currentStatus",
    label: "setting.process.item.workflow.edit.table.currentStatus",
    minWidth: 200,
    align: "left",
  },
  {
    id: "action",
    label: "setting.process.item.workflow.edit.table.action",
    minWidth: 100,
    align: "left",
    format: (title) => {
      return (
        <Box>
          <Box>{title}</Box>
          <EastIcon />
        </Box>
      );
    },
  },
  {
    id: "newStatus",
    label: "setting.process.item.workflow.edit.table.newStatus",
    minWidth: 200,
    align: "left",
  },
  {
    id: "approval",
    label: "setting.process.item.workflow.edit.table.approval",
    minWidth: 70,
    align: "left",
    format: (value, record) => {
      return <Box sx={{ color: "red" }}>{value ? "Yes" : ""}</Box>;
    },
  },
  {
    id: "notification",
    label: "setting.process.item.workflow.edit.table.notifications",
    minWidth: 90,
    align: "left",
  },
  {
    id: "lock",
    label: "setting.process.item.workflow.edit.table.lock",
    minWidth: 70,
    align: "left",
  },
  {
    id: "transitionPermissions",
    label: "setting.process.item.workflow.edit.table.transitionPermissions",
    minWidth: 160,
    align: "left",
    format: (value, record) => {
      return (
        <Box>
          <EditIcon sx={{ mr: 2, cursor: "pointer", fontSize: "1.25rem" }} />{" "}
          <DeleteIcon sx={{ cursor: "pointer", fontSize: "1.25rem" }} />
        </Box>
      );
    },
  },
];

const DeliverableWorkflow = () => {
  const params = useParams();
  const { pid } = params;

  const [statusRows, setStatusRows] = useState([]);
  const [flowData, setFlowData] = useState(undefined);
  const [dataJson, setDataJson] = useState(undefined);
  useEffect(() => {
    if (pid) {
      const mockData = deliverableWorkflowMockData[pid];
      if (mockData) {
        setStatusRows(mockData.statusRows);
        setFlowData(mockData.flowData);
        setDataJson(mockData.dataJson);
      }
    }
  }, [pid]);

  const { messages } = useIntl();
  const [isAllow, setIsAllow] = React.useState<boolean>(true);
  const [workflowDiagram, setDiagramData] = React.useState(diagramDataSet);

  React.useEffect(() => {
    if (workflowDiagram.length > 0) {
    }
  }, [workflowDiagram]);

  return (
    <>
      <Box>
        <CustomTable
          columns={statusColumns}
          rows={statusRows}
          showPage={false}
          maxHeight="auto"
          addRow={() => {
            console.log("add row");
          }}
          addRowTextId="common.add"
          groupTextId="common.status"
        />
      </Box>
      <Box
        sx={{
          my: 6,
          width: "100%",
        }}
      >
        <DiagramContainer>
          {flowData && dataJson && <FlowDiagram {...flowData} dataJson={dataJson} className="workflow-diagram-box" />}
        </DiagramContainer>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          "& .MuiFormControlLabel-label": {
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "0.15px",
            color: "rgba(0,0,0,0.85)",
          },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isAllow}
              onChange={(e) => {
                setIsAllow(e.target.checked);
              }}
            />
          }
          label={messages["setting.process.workflow.enable.approval"] as string}
        />
      </Box>
      <Box>
        <WorkflowTable />
      </Box>
    </>
  );
};

export default DeliverableWorkflow;
