/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import MyTreeTable from "components/MyTreeTable";
import { taskData } from "mock/index";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Column } from "types/models/Common";

const TreeGridContainer = styled(Box)(({ theme }) => ({
  marginTop: "0px",
}));

interface MyTreeGridProps {
  bottomOpen: boolean;
  setSelectedValue: (value: any) => void;
  setBottomOpen: (value: any) => void;
  setOpenSettingsModal: (value: any) => void;
  setLayout: (value: any) => void;
  layout: string;
}

const MyTreeGrid: React.FC<MyTreeGridProps> = (props: MyTreeGridProps) => {
  const routeParam = useParams();
  const [mockData, setMockData] = React.useState(null);

  React.useEffect(() => {
    routeParam?.type && setMockData(taskData[routeParam.type]);
    if (routeParam?.type === "und") {
      setColumns([
        {
          id: "name",
          label: "common.name",
          minWidth: 200,
          align: "left",
        },
        {
          id: "id",
          label: "common.display.id",
          minWidth: 200,
          align: "left",
        },
        {
          id: "user_types",
          label: "common.user.types",
          minWidth: 200,
          align: "left",
          format: (value, record) => {
            const list = value ? value.split("|") : [];
            if (list && list.length > 0) {
              return list.map((item) => <Box key={item}>{item}</Box>);
            }
            return "";
          },
        },
        {
          id: "priority",
          label: "common.priority",
          minWidth: 200,
          align: "left",
        },
        {
          id: "rationale",
          label: "common.rationale",
          minWidth: 200,
          align: "left",
        },
      ]);
    } else {
      setColumns([
        {
          id: "id",
          label: "invoice.id",
          minWidth: 100,
          align: "left",
        },
        {
          id: "name",
          label: "common.test.case.title",
          minWidth: 200,
          align: "left",
        },
        {
          id: "steps",
          label: "common.test.description",
          minWidth: 200,
          align: "left",
          format: (value, record) => {
            if (value && value.length > 0) {
              return value.map((item) => (
                <Box key={item} id={record.id + "_" + item}>
                  {item}
                </Box>
              ));
            }
            return "";
          },
        },
        {
          id: "criteria",
          label: "common.acceptance.criteria",
          minWidth: 200,
          align: "left",
        },
        {
          id: "equipment",
          label: "common.equipment.and.supplies.needed",
          minWidth: 200,
          align: "left",
        },
        {
          id: "tested",
          label: "common.requirement.tested",
          minWidth: 200,
          align: "left",
          format: (value, record) => {
            return (
              <Box id={record.id + "_tested"} sx={{ color: "#2e6ccb" }}>
                {value}
              </Box>
            );
          },
        },
        {
          id: "result",
          label: "common.result",
          minWidth: 200,
          align: "left",
        },
      ]);
    }
  }, [routeParam]);

  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [reloadClickCell, setReloadClickCell] = React.useState(false);

  const contextMenu = (e) => {
    e.preventDefault();
    const currentRowId = e.target.id.split("_")[0];
    const currentRow = getSelectedRow(rows, currentRowId);
    setSelectedRow(currentRow);
    setAnchorEl(e.target);
  };

  const getSelectedRow = (tree: any, id) => {
    for (let item of tree) {
      if (item.id === id) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        return getSelectedRow(item.children, id);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const [columns, setColumns] = React.useState<Column[]>([]);

  const [rows, setRows] = React.useState<any[]>([]);

  React.useEffect(() => {
    let tabDom = null;
    if (rows.length > 0) {
      tabDom = document.getElementsByClassName("table_body_cell");
      for (let i = 0; i < tabDom.length; i++) {
        tabDom[i].addEventListener("contextmenu", contextMenu);
      }
    }
    return () => {
      for (let i = 0; i < tabDom?.length; i++) {
        tabDom[i].removeEventListener("contextmenu", contextMenu);
      }
    };
  }, [contextMenu, reloadClickCell, rows]);

  useEffect(() => {
    if (mockData) {
      setRows(mockData.treeTableRows);
    }
  }, [mockData]);

  return (
    <TreeGridContainer className="tree-grid-box">
      <Box
        className="tree-grid-layout-box"
        sx={{
          height: props.bottomOpen ? "calc(60vh - 200px)" : "calc(100vh - 200px)",
        }}
      >
        <MyTreeTable
          columns={columns}
          rows={rows}
          maxHeight={props.bottomOpen ? "300px" : "700px"}
          selectedRow={selectedRow}
          setReloadClickCell={() => {
            setReloadClickCell(!reloadClickCell);
          }}
        />
      </Box>
      <Menu
        id="export-menus"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            props.setSelectedValue({ id: "11", label: "Details" });
            props.setBottomOpen(true);
            props.setOpenSettingsModal(false);
            if (props.layout === "up_down") {
              props.setLayout("");
            }
          }}
        >
          Details
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            props.setSelectedValue({ id: "12", label: "Impact Analysis  " });
            props.setBottomOpen(true);
            props.setOpenSettingsModal(false);
            if (props.layout === "up_down") {
              props.setLayout("");
            }
          }}
        >
          Impact Analysis
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
          }}
        >
          Send to Review
        </MenuItem>
      </Menu>
    </TreeGridContainer>
  );
};

export default MyTreeGrid;
