import IntlMessages from "@crema/utility/IntlMessages";
import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useIntl } from "react-intl";
import { textColor } from "shared/constants/AppConst";

interface ReuseOptionsProps {
  isSync: boolean;
  handleIsSync: (value: boolean) => void;
  prefix: string;
  handlePrefix: (value: any) => void;
  relation: any;
  handleRelation: (value: any) => void;
  type: any;
  handleType: (value: any) => void;
}

const ReuseOptionsContainer = styled(Box)(({ theme }) => ({
  paddingLeft: "12px",
  paddingBottom: "12px",
  background: "#FAFAFA",
}));

const relationList = [
  {
    id: 1,
    name: "Relates to",
  },
  {
    id: 2,
    name: "Derived from",
  },
  {
    id: 3,
    name: "Duplicates",
  },
  {
    id: 0,
    name: "None",
  },
];

export default function ReuseOptions(props: ReuseOptionsProps) {
  const { isSync, handleIsSync, prefix, handlePrefix, relation, handleRelation, type, handleType } = props;
  const { messages } = useIntl();
  return (
    <ReuseOptionsContainer>
      <Box
        className="w-full flex items-center"
        sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
      >
        <h3>
          <IntlMessages id="common.reuse.options" />
        </h3>
      </Box>
      <Grid container alignItems="flex-start" spacing={4}>
        <Grid container alignItems="center" item xs={6} md={6}>
          <Grid item xs={6} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSync}
                  onChange={(e) => {
                    handleIsSync(e.target.checked);
                  }}
                />
              }
              label={<IntlMessages id="products.sync.items.and.share.global.ids" />}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ color: "rgba(0,0,0,0.85)" }}>
                <IntlMessages id="products.form.append.a.prefix" />:
              </Box>
              <TextField
                variant="outlined"
                placeholder={String(messages["common.pleaseEnter"])}
                value={prefix}
                disabled={isSync}
                sx={{ width: "120px", marginLeft: "8px" }}
                onChange={(e) => {
                  handlePrefix(e.target.value);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ color: "rgba(0,0,0,0.85)" }}>
                <IntlMessages id="common.keep.relationship.with.base.release" />:
              </Box>
              <Select
                label=""
                sx={{ width: "200px", marginLeft: "8px" }}
                value={relation}
                onChange={(e) => {
                  handleRelation(e.target.value);
                }}
                name="relation"
                placeholder="select"
                fullWidth
              >
                {relationList.map((item) => {
                  return (
                    <MenuItem
                      value={item.id}
                      key={item.id}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Grid>
          <Grid
            sx={{
              padding: "0 0 0 48px !important",
              "& .MuiFormControlLabel-label": {
                color: textColor[85],
                fontWeight: "normal",
              },
            }}
            item
            xs={12}
            md={12}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="1"
                value={type}
                onChange={(e) => {
                  handleType(e.target.value);
                }}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={<IntlMessages id="products.reuse.radio.one" />}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label={<IntlMessages id="products.reuse.radio.two" />}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label={<IntlMessages id="products.reuse.radio.three" />}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={6} md={6}>
          <Avatar
            src={
              type === "1"
                ? "/assets/images/relationships-01.svg"
                : type === "2"
                ? "/assets/images/relationships-02.svg"
                : "/assets/images/relationships-03.svg"
            }
            sx={{
              width: "100%",
              height: "auto",
              padding: (theme) => theme.spacing(5),
              background: " #FAFAFA",
            }}
            variant="square"
          />
        </Grid>
      </Grid>
    </ReuseOptionsContainer>
  );
}
