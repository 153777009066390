import { SelectProcessData } from "types/models/Products";

export const UPDATE_VERSION = "UPDATE_VERSION";
export const UPDATE_STAGE = "UPDATE_STAGE";
export const UPDATE_VARIANT = "UPDATE_VARIANT";
export const UPDATE_productsCategory = "UPDATE_productsCategory";
export const UPDATE_productsDetailMenu = "UPDATE_productsDetailMenu";
export const UPDATE_LEFT_TREE_OPEN = "UPDATE_LEFT_TREE_OPEN";
export const UPDATE_LEFT_TREE_SELECTED = "UPDATE_LEFT_TREE_SELECTED";

export interface VersionChangeAction {
  type: typeof UPDATE_VERSION;
  version: string;
}

export interface StageChangeAction {
  type: typeof UPDATE_STAGE;
  stage: string;
}

export interface VariantChangeAction {
  type: typeof UPDATE_VARIANT;
  variant: string;
}

export interface CategoryChangeAction {
  type: typeof UPDATE_productsCategory;
  productsCategory: string;
}
export interface MenuChangeAction {
  type: typeof UPDATE_productsDetailMenu;
  productsDetailMenu: string;
}

export interface LeftTreeOpenChangeAction {
  type: typeof UPDATE_LEFT_TREE_OPEN;
  leftTreeOpen: boolean;
}

export interface LeftTreeSelectedAction {
  type: typeof UPDATE_LEFT_TREE_SELECTED;
  selectProcess: SelectProcessData;
}

export type ProductsActionTypes =
  | MenuChangeAction
  | CategoryChangeAction
  | LeftTreeOpenChangeAction
  | VersionChangeAction
  | StageChangeAction
  | VariantChangeAction
  | LeftTreeSelectedAction;
