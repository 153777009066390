import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TreeItem, { treeItemClasses, TreeItemContentProps, TreeItemProps, useTreeItem } from "@mui/lab/TreeItem";
import { Checkbox, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { SvgIconProps } from "@mui/material/SvgIcon";
import clsx from "clsx";
import Icons from "components/Icons";
import * as React from "react";
import { useState } from "react";
import { bgColor, textColor } from "shared/constants/AppConst";
const { RemoveBoxIcon, AddBoxIcon, FolderIcon } = Icons;
declare module "react" {
  interface CSSProperties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

  const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
    useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <Box
      className={clsx(
        className,
        classes.root,
        {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        },
        "items-center"
      )}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <Box onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </Box>
      <div onClick={handleSelectionClick} className={classes.label}>
        {label}
      </div>
    </Box>
  );
});

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  nodeId: string;
  isBold: boolean;
  expanded: Array<string>;
  showFolder: boolean;
  labelIcon?: React.ElementType<SvgIconProps>;
  moreIcon?: React.ElementType<SvgIconProps>;
  labelText: string;
  showMoreIcon: boolean;
  // onMoreIconClick: (event: React.MouseEvent<HTMLElement>) => void;
  node: any;
  showCheckbox?: boolean;
  checkedNodeIds?: string[];
  showNodeStatus?: boolean;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    alignItems: "baseline",
    // borderTopRightRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    // paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    ".more-icon-btn": {
      display: "none",
    },
    "&:hover .more-icon-btn": {
      display: "flex",
    },
    // "&.Mui-selected .more-icon-btn ": {
    //   display: "flex",
    // },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      background: bgColor.main,
      // borderRight: "2px solid #2e6ccb",
      color: "#2e6ccb",
      borderRadius: "4px",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: textColor[85],
      paddingLeft: 0,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: "20px",
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

export default function StyledTreeItem(props: StyledTreeItemProps) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    moreIcon,
    labelText,
    showMoreIcon,
    // onMoreIconClick,
    showNodeStatus = false,
    isBold,
    showFolder,
    showCheckbox,
    checkedNodeIds,
    expanded,
    nodeId,
    node,
    ...other
  } = props;

  const [hovered, setHovered] = useState(false);

  const getNodeStatus = (node) => {
    if (node.added) {
      return "New";
    } else if (node.changed) {
      return "Changed";
    } else if (node.currentStage) {
      return "Current";
    }
    return "";
  };

  const getNodeStatusStyle = (node) => {
    if (node.added) {
      return {
        background: "#de350b",
        borderRadius: "4px",
        borderBottomLeftRadius: "0px",
        color: "#fff",
        fontSize: "10px",
        padding: "0.5px 2px",
        marginLeft: "6px",
      };
    } else if (node.changed) {
      return {
        background: "#ff991f",
        color: "#fff",
        borderRadius: "4px",
        borderBottomLeftRadius: "0px",
        fontSize: "10px",
        padding: "0.5px 2px",
        marginLeft: "6px",
      };
    } else if (node.currentStage) {
      return {
        background: "#61b045",
        color: "#fff",
        borderRadius: "4px",
        borderBottomLeftRadius: "0px",
        fontSize: "10px",
        padding: "0.5px 2px",
        marginLeft: "6px",
      };
    }
    return {};
  };

  const handleHover = (val) => {
    setHovered(val);
  };

  return (
    <StyledTreeItemRoot
      nodeId={nodeId}
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            p: 0,
            pr: 6,
          }}
          onMouseLeave={() => {
            handleHover(false);
          }}
          onMouseMove={() => {
            handleHover(true);
          }}
        >
          {showCheckbox && (
            <Checkbox
              sx={{
                width: 15,
                height: 15,
                p: 0,
                mr: "10px",
                ".MuiSvgIcon-root": {
                  fontSize: "1rem",
                },
              }}
              checked={checkedNodeIds.includes(nodeId)}
            />
          )}
          {showFolder && (
            <Box color="inherit" sx={{ mr: 1, width: "16px", height: "20px", display: "flex", alignItems: "center" }}>
              {LabelIcon ? <LabelIcon /> : <FolderIcon />}
            </Box>
          )}
          <span
            style={{
              fontWeight: isBold ? "bold" : "normal",
              flexGrow: 1,
              fontSize: "14px",
            }}
          >
            {labelText}
            {showNodeStatus && <span style={getNodeStatusStyle(node)}>{getNodeStatus(node)}</span>}
          </span>
          {showMoreIcon && (
            <IconButton
              className="more-icon-btn"
              // onClick={onMoreIconClick}
              sx={{
                // display: hovered ? "flex" : "none",
                width: "24px",
                height: "24px",
                position: "absolute",
                right: 0,
              }}
            >
              <MoreHorizIcon id={"more_button_" + nodeId} className="more-icon" />
            </IconButton>
          )}
        </Box>
      }
      collapseIcon={<RemoveBoxIcon />}
      expandIcon={<AddBoxIcon />}
      ContentComponent={CustomContent}
      {...other}
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
    />
  );
}
