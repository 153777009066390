export const allScopeFolders = {
  "Risk Management": {
    dataSourceDataSet: [
      {
        id: "Risk_Management",
        name: "Risk Management",
        level: 1,
        isFolder: true,
        inScope: false,
        allowToEdit: false,
        allowToLink: false,
        children: [
          {
            id: "Safety_Risks",
            name: "Safety Risks",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
            children: [
              {
                id: "Safety_Harms",
                name: "Safety Harms",
                level: 3,
                isFolder: true,
                inScope: false,
                allowToEdit: false,
                allowToLink: false,
              },
              {
                id: "Hazards",
                name: "Hazards",
                level: 3,
                isFolder: true,
                inScope: false,
                allowToEdit: false,
                allowToLink: false,
                children: [
                  {
                    id: "Hazard_Category",
                    name: "Hazard Category",
                    level: 4,
                    isFolder: true,
                    inScope: false,
                    allowToEdit: false,
                    allowToLink: false,
                  },
                  {
                    id: "System_Hazards",
                    name: "System Hazards",
                    level: 4,
                    isFolder: true,
                    inScope: false,
                    allowToEdit: false,
                    allowToLink: false,
                  },
                  {
                    id: "Hazardous_Situations",
                    name: "Hazardous Situations",
                    level: 4,
                    isFolder: true,
                    inScope: false,
                    allowToEdit: false,
                    allowToLink: false,
                  },
                  {
                    id: "Causes",
                    name: "Causes",
                    level: 4,
                    isFolder: true,
                    inScope: false,
                    allowToEdit: false,
                    allowToLink: false,
                  },
                  {
                    id: "Basic_Events",
                    name: "Basic Events",
                    level: 4,
                    isFolder: true,
                    inScope: false,
                    allowToEdit: false,
                    allowToLink: false,
                  },
                  {
                    id: "Risk_Controls",
                    name: "Risk Controls",
                    level: 4,
                    isFolder: true,
                    inScope: false,
                    allowToEdit: false,
                    allowToLink: false,
                  },
                ],
              },
            ],
          },
          {
            id: "Security_Risks",
            name: "Security Risks",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
            children: [
              {
                id: "Security_Harms",
                name: "Security Harms",
                level: 3,
                isFolder: true,
                inScope: false,
                allowToEdit: false,
                allowToLink: false,
              },
              {
                id: "Threats",
                name: "Threats",
                level: 3,
                isFolder: true,
                inScope: false,
                allowToEdit: false,
                allowToLink: false,
                children: [
                  {
                    id: "Sub_Threats",
                    name: "Threats",
                    level: 4,
                    isFolder: true,
                    inScope: false,
                    allowToEdit: false,
                    allowToLink: false,
                  },
                  {
                    id: "Vulnerabilities",
                    name: "Vulnerabilities",
                    level: 4,
                    isFolder: true,
                    inScope: false,
                    allowToEdit: false,
                    allowToLink: false,
                  },
                  {
                    id: "Threat_Attacks",
                    name: "Threat Attacks",
                    level: 4,
                    isFolder: true,
                    inScope: false,
                    allowToEdit: false,
                    allowToLink: false,
                  },
                ],
              },
            ],
          },
          {
            id: "FMEAs",
            name: "FMEAs",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
            children: [
              {
                id: "Design_FMEA",
                name: "Design FMEA",
                level: 3,
                isFolder: true,
                inScope: false,
                allowToEdit: false,
                allowToLink: false,
              },
              {
                id: "Process_FMEA",
                name: "Process FMEA",
                level: 3,
                isFolder: true,
                inScope: false,
                allowToEdit: false,
                allowToLink: false,
              },
              {
                id: "Product_Error_Codes",
                name: "Product Error Codes",
                level: 3,
                isFolder: true,
                inScope: false,
                allowToEdit: false,
                allowToLink: false,
              },
              {
                id: "Code_Lib",
                name: "Code Lib",
                level: 3,
                isFolder: true,
                inScope: false,
                allowToEdit: false,
                allowToLink: false,
              },
            ],
          },
          {
            id: "Evidences",
            name: "Evidences",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
            children: [
              {
                id: "Risk_Benefit_Analysis_Ref",
                name: "Risk Benefit Analysis Ref.",
                level: 3,
                isFolder: true,
                inScope: false,
                allowToEdit: false,
                allowToLink: false,
              },
            ],
          },
        ],
      },
    ],
  },
  "Requirement Management": {
    dataSourceDataSet: [
      {
        id: "Design_Inputs",
        name: "Design Inputs",
        level: 1,
        isFolder: true,
        inScope: false,
        allowToEdit: false,
        allowToLink: false,
        children: [
          {
            id: "User_Needs",
            name: "User Needs",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
          },
          {
            id: "Design_Input_Requirements",
            name: "Design Input Requirements",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
          },
          {
            id: "HW_Subsystem_Requirements",
            name: "HW Subsystem Requirements",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
          },
          {
            id: "SW_Subsystem_Requirements",
            name: "SW Subsystem Requirements",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
          },
        ],
      },
      {
        id: "Design_Outputs",
        name: "Design Outputs",
        level: 1,
        isFolder: true,
        inScope: false,
        allowToEdit: false,
        allowToLink: false,
        children: [
          {
            id: "Design_Specifications",
            name: "Design Specifications",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
          },
          {
            id: "DMR_Design_Outputs",
            name: "DMR Design Outputs",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
          },
        ],
      },
      {
        id: "Design_Verifications",
        name: "Design Verifications",
        level: 1,
        isFolder: true,
        inScope: false,
        allowToEdit: false,
        allowToLink: false,
        children: [
          {
            id: "System_Verifications",
            name: "System Verifications",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
          },
          {
            id: "SW_Verifications",
            name: "SW Verifications",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
          },
          {
            id: "HW_Verifications",
            name: "HW Verifications",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
          },
          {
            id: "Test_Cases",
            name: "Test Cases",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
          },
        ],
      },
      {
        id: "Design_Validations",
        name: "Design Validations",
        level: 1,
        isFolder: true,
        inScope: false,
        allowToEdit: false,
        allowToLink: false,
        children: [
          {
            id: "Validations",
            name: "Validations",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
          },
        ],
      },
      {
        id: "Design_Transfer",
        name: "Design Transfer",
        level: 1,
        isFolder: true,
        inScope: false,
        allowToEdit: false,
        allowToLink: false,
        children: [
          {
            id: "IQ_OQ_PQ",
            name: "IQ/OQ/PQ",
            level: 2,
            isFolder: true,
            inScope: false,
            allowToEdit: false,
            allowToLink: false,
          },
        ],
      },
    ],
  },
};
