import { Box, IconButton, TextField } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
interface QuickSearchProps {
  doSearch?: (q) => void;
}
const QuickSearch: React.FC<QuickSearchProps> = (props) => {
  const { doSearch } = props;
  let searchText: String = "";

  return (<TextField
    variant="outlined"
    value={searchText}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { searchText = event.target.value; doSearch(event.target.value) }}
    placeholder={String("")}
    InputProps={{
      startAdornment: (
        <AppSvgIcon size={16} color="action"> material-outline:search</AppSvgIcon>
      ),
      endAdornment: (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: searchText ? "visible" : "hidden" }}
            onClick={() => { }}
          >
            <AppSvgIcon size={16} color="action">
              {"material-outline:clear"}
            </AppSvgIcon>
          </IconButton>
        </Box>
      ),
    }}
    sx={{
      width: {
        xs: 1,
        sm: "auto",
      },
      m: (theme) => theme.spacing(0),
      "& .MuiSvgIcon-root": {
        mr: 0.5,
      },
      "& .MuiInputBase-root": {
        padding: "0px 14px",
        height: "32px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "0px",
      },
      "& .MuiInput-underline:before": {
        borderBottom: 1,
        borderColor: "divider",
      },
    }}
  />);
};
export default QuickSearch;
