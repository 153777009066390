import Icon from "@mui/material/Icon";

const FeedsTabSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="#000" fillRule="evenodd">
      <path
        d="M3 4a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm0 8a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zM15.17 9a3.001 3.001 0 1 0 0-2H4a1 1 0 0 0 0 2h11.17zM19 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
        opacity=".6"
      />
    </g>
  </svg>
);
const FeedsTabSelectedSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="#2e6ccb" fillRule="evenodd">
      <path d="M3 4a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm0 8a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zM15.17 9a3.001 3.001 0 1 0 0-2H4a1 1 0 0 0 0 2h11.17zM19 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
    </g>
  </svg>
);

interface FeedsTabIconProps {
  selected?: boolean;
}

const FeedsTabIcon = (props: FeedsTabIconProps) => {
  if (props.selected) {
    return <Icon component={FeedsTabSelectedSvg} />;
  } else {
    return <Icon component={FeedsTabSvg} />;
  }
};

export default FeedsTabIcon;
