import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import NoDataIcon from "components/Icons/NoDataIcon";
import React from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";
import { Fonts } from "shared/constants/AppEnums";
import { Column, TreeTableBaseModel } from "types/models/Common";

import MyTableRow from "./MyTableRow";

const Container = styled(Box)(({ theme }) => ({
  ".my_table_container": {
    width: "100%",
    "& .MuiIconButton-root": {
      top: 0,
      right: 0,
    },
  },
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_body .table_row_task .table_body_cell": {
    background: bgColor.tableBodyRow2,
  },
  ".table_cell:first-of-type": {
    borderLeft: `1px solid  ${borderColor.dividerColor}`,
    // top: 0,
    // position: "sticky",
    // left: 0,
    // zIndex: 3,
  },
  "thead tr:first-of-type .table_header_cell": {
    borderTop: `1px solid  ${borderColor.dividerColor}`,
  },
  // "tbody tr:first-of-type td": {
  //   background: "#f5f7ff",
  // },
  ".table_cell": {
    padding: "10px 12px",
    borderRight: `1px solid  ${borderColor.dividerColor}`,
    borderBottom: `1px solid  ${borderColor.dividerColor}`,
    lineHeight: "20px",
  },
  ".table_header_cell": {
    padding: "9px 12px",
  },
  ".table_selected": {
    background: "#3366ff1a",
  },
  ".MuiTableRow-root.table_row_clickable:hover": {
    color: theme.palette.primary.main,
    cursor: "pointer",
    "& .table_body_cell": {
      backgroundColor: theme.palette.action.hover,
      fontWeight: Fonts.SEMI_BOLD,
    },
    "& *": {
      color: "inherit",
    },
  },
  ".table_actions": {
    // position: "sticky",
    // top: 0,
    // right: 0,
    // zIndex: 3,
  },
}));

interface MyTreeTableProps {
  columns: Column[];
  rows: TreeTableBaseModel[];
  showPage?: boolean;
  enableIsOpen?: boolean;
  maxHeight?: number | string;
  selectedRow?: any;
  setReloadClickCell?: () => void;
  onRowClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>, row: any) => void;
}

const MyTreeTable: React.FC<MyTreeTableProps> = (props: MyTreeTableProps) => {
  const { showPage = true, maxHeight, selectedRow, setReloadClickCell, onRowClick, enableIsOpen = false } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { columns, rows } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const renderRow = (row, columns, selectedRow, setReloadClickCell, onRowClick, enableIsOpen) => {
    if (row.others?.length > 0) {
      return row.others.map((other, i) => {
        if (i === 0) {
          return (
            <MyTableRow
              onRowClick={onRowClick}
              key={other.id}
              row={other}
              columns={columns}
              selectedRow={selectedRow}
              renderRow={renderRow}
              rowSpan={row.others?.length}
              enableIsOpen={enableIsOpen}
              setReloadClickCell={setReloadClickCell}
              isFolder={row?.isFolder}
            />
          );
        }
        return (
          <MyTableRow
            onRowClick={onRowClick}
            key={other.id}
            row={other}
            columns={columns}
            selectedRow={selectedRow}
            renderRow={renderRow}
            enableIsOpen={enableIsOpen}
            rowSpan={0}
            setReloadClickCell={setReloadClickCell}
            isFolder={row?.isFolder}
          />
        );
      });
    }
    return (
      <MyTableRow
        onRowClick={onRowClick}
        key={row.id}
        row={row}
        columns={columns}
        selectedRow={selectedRow}
        renderRow={renderRow}
        enableIsOpen={enableIsOpen}
        rowSpan={1}
        setReloadClickCell={setReloadClickCell}
        isFolder={row?.isFolder}
      />
    );
  };

  return (
    <Box className="my-tree-table-box">
      <Container className="my-tree-table-container">
        <TableContainer className="my_table_container">
          <AppScrollbar sx={{ maxHeight: maxHeight ? maxHeight : 350 }}>
            <Table stickyHeader aria-label="sticky collapsible table">
              <TableHead>
                <TableRow className="table_header">
                  {columns.map((column) => (
                    <TableCell
                      sx={{ minWidth: column.minWidth }}
                      className={
                        column.id === "actions"
                          ? "table_cell table_header_cell table_actions"
                          : "table_cell table_header_cell"
                      }
                      key={column.id}
                      align={column.align}
                    >
                      <IntlMessages id={column.label} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {rows && rows?.length > 0 && (
                <TableBody className="table_body">
                  {showPage
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        return renderRow(row, columns, selectedRow, setReloadClickCell, onRowClick, enableIsOpen);
                      })
                    : rows.map((row, index) => {
                        return renderRow(row, columns, selectedRow, setReloadClickCell, onRowClick, enableIsOpen);
                      })}
                </TableBody>
              )}
            </Table>
          </AppScrollbar>
        </TableContainer>
      </Container>
      <Box
        sx={{
          display: rows?.length > 0 ? "none" : "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 350,
          width: "100%",
        }}
      >
        <NoDataIcon />
      </Box>
      {showPage && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          sx={{
            "& .MuiIconButton-root": {
              top: "0px !important",
              right: "0px !important",
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

export default MyTreeTable;
