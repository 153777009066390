import Icon from "@mui/material/Icon";
import * as React from "react";

const AssuranceCaseSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <rect fill="#01579B" opacity=".1" width="20" height="20" rx="4" />
      <g fill="#01579B" fillRule="nonzero">
        <path d="M10 14.062a3.989 3.989 0 0 1 5.414-3.736L17.42 8.37a.263.263 0 0 0-.146-.45l-4.863-.706-2.175-4.406a.275.275 0 0 0-.472 0L7.59 7.215l-4.863.707a.263.263 0 0 0-.146.45L6.1 11.8l-.832 4.844a.263.263 0 0 0 .382.277l4.35-2.287.05.027a4.008 4.008 0 0 1-.05-.599z" />
        <path d="M14 10.812a3.25 3.25 0 1 1 0 6.5 3.25 3.25 0 0 1 0-6.5zm1.5 4.25h-3a.25.25 0 1 0 0 .5h3a.25.25 0 1 0 0-.5zm0-1.25h-3a.25.25 0 1 0 0 .5h3a.25.25 0 1 0 0-.5zm0-1.25h-3a.25.25 0 1 0 0 .5h3a.25.25 0 1 0 0-.5z" />
      </g>
    </g>
  </svg>
);

const AssuranceCaseIcon: React.FunctionComponent = () => {
  return <Icon component={AssuranceCaseSvg} />;
};

export default AssuranceCaseIcon;
