import Box from "@mui/material/Box";
import React from "react";
import { borderColor, borderRadius, textColor } from "shared/constants/AppConst";

export interface itemType {
  id: number | string;
  title: string;
  icon: string | React.ReactNode;
  count: number;
}

interface QuickStatsticsProps {
  item: itemType;
}

const QuickStatstics: React.FC<QuickStatsticsProps> = ({ item }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: 296,
        height: "60px",
        border: `1px solid  ${borderColor.dividerColor}`,
        borderRadius: borderRadius.main,
        paddingLeft: 6,
        marginRight: 3,
        // cursor: "pointer",
        // "&:hover": {
        //   boxShadow: "0 3px 20px 0 #e5e5e5;",
        // },
      }}
      className="flex items-center"
    >
      <Box
        sx={{
          mr: 4,
        }}
      >
        {item.icon}
      </Box>
      <Box
        sx={{
          width: {
            xs: "calc(100% - 56px)",
            lg: "calc(100% - 70px)",
            xl: "calc(100% - 76px)",
          },
        }}
        className="flex items-center justify-between"
      >
        <Box sx={{ overflow: "hidden" }}>
          <Box
            component="p"
            className="text-not-wrap text-color-08"
            sx={{
              fontSize: 12,
              height: "22px",
              color: textColor[45],
              lineHeight: "22px",
              verticalAlign: "center",
              cursor: "pointer",
            }}
          >
            {item.title}
          </Box>
          <Box
            component="p"
            className="text-not-wrap text-color-04"
            sx={{
              fontWeight: "bold",
              fontSize: 24,
              color: textColor[85],
              cursor: "pointer",
              height: "24px",
              lineHeight: "24px",
              verticalAlign: "center",
            }}
          >
            {item.count}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default QuickStatstics;
