import IntlMessages from "@crema/utility/IntlMessages";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import Chart from "react-apexcharts";
import { textColor } from "shared/constants/AppConst";

const StatusType = [
  {
    color: "#2e6ccb",
    name: "Draf",
  },
  {
    color: "#ff991f",
    name: "In Review",
  },
  {
    color: "#45b812",
    name: "Approved",
  },
  {
    color: "#5243aa",
    name: "Effective",
  },
  {
    color: "#e63825",
    name: "Rejected",
  },
];

const DeliverableTaskStatusContainer = styled(Box)(({ theme }) => ({}));

const DeliverableTaskStatus = () => {
  const [options, setOptions] = React.useState({
    xaxis: {
      categories: ["Draft", " In Review", "Approved", "Effective", "Rejected"],
      labels: {
        show: true,
        style: {
          colors: textColor[45],
          fontSize: "12px",
          fontFamily: "Roboto",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: textColor[45],
          fontSize: "12px",
          fontFamily: "Roboto",
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "28",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      // formatter: function (val) {
      //   return val + "%";
      // },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: [textColor[65]],
      },
    },
    colors: ["#2e6ccb", "#ff991f", "#45b812", "#5243aa", "#e63825"],
  });
  const [series, setSeries] = React.useState([
    {
      name: "",
      data: [
        {
          x: "Draft",
          y: 626,
          fillColor: "#2e6ccb",
          strokeColor: "#2e6ccb",
        },
        {
          x: "In Review",
          y: 402,
          fillColor: "#ff991f",
          strokeColor: "#ff991f",
        },
        {
          x: "Approved",
          y: 389,
          fillColor: "#45b812",
          strokeColor: "#45b812",
        },
        {
          x: "Effective",
          y: 784,
          fillColor: "#5243aa",
          strokeColor: "#5243aa",
        },
        {
          x: "Rejected",
          y: 45,
          fillColor: "#e63825",
          strokeColor: "#e63825",
        },
      ],
    },
  ]);

  return (
    <DeliverableTaskStatusContainer>
      <Box
        className="w-full flex items-center"
        sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
      >
        <h3>
          <IntlMessages id="common.deliverable.task.status" />
        </h3>
      </Box>
      <Box className="flex items-center justify-center">
        <Chart options={options} series={series} type="bar" width={534} height={200} />
      </Box>
      <Box className="flex items-center justify-center">
        {StatusType.map((item) => {
          return (
            <Box key={item.color} className="flex items-center">
              <Box sx={{ width: "8px", height: "8px", background: item.color, mx: "6px", borderRadius: "50%" }}></Box>
              <Box sx={{ color: textColor[65], fontSize: 12 }}>{item.name}</Box>
            </Box>
          );
        })}
      </Box>
    </DeliverableTaskStatusContainer>
  );
};

export default DeliverableTaskStatus;
