import Icon from "@mui/material/Icon";
import * as React from "react";

const OpenFeedsSvg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="100%" y1="89.86%" x2="0%" y2="10.14%" id="f0hvm9i71a">
        <stop stopColor="#2E6CCB" offset="0%" />
        <stop stopColor="#72BCFF" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect fill="#2E6CCB" opacity=".096" width="40" height="40" rx="6" />
      <path d="M6 5h30v30H6z" />
      <path
        d="M16.032 19.4c-.241-.834.211-1.608.78-2.4a594.71 594.71 0 0 0 6.014-8.562c.286-.413.452-.487.856-.153.685.567 1.42 1.077 2.147 1.594.27.19.307.328.099.624-2.252 3.18-4.485 6.375-6.717 9.569a.904.904 0 0 1-.565.402c-.351.081-.695.194-1.042.293-1.553.452-1.553.452-1.572-1.368zm9.333-14.298c.047.034.125.088.201.147.776.572 1.539 1.164 2.333 1.71.307.213.266.346.087.617-.912 1.367-.905 1.373-2.194.416-.194-.143-.396-.277-.58-.432-.358-.306-1.078-.498-1.018-.9.076-.512.583-.965.908-1.44.05-.071.1-.175.263-.118zm-1.735 10.54c-.146.2-.282.384-.413.57-1.031 1.453-2.065 2.904-3.086 4.365a1.38 1.38 0 0 1-.849.6c-1.145.288-2.28.613-3.403.975-.558.18-.741.12-.73-.515.024-1.082-.027-2.168-.087-3.25a1.852 1.852 0 0 1 .369-1.266c2.63-3.684 5.228-7.393 7.869-11.07.343-.477.284-.802-.018-1.214-.597-.813-1.41-1.09-2.393-1.087-5.39.013-10.781.003-16.172.007-1.855.003-2.828.944-2.831 2.795-.015 5.927-.015 11.855 0 17.782.003 1.845 1.034 2.845 2.896 2.848 5.39.007 10.78.007 16.171 0 1.775-.003 2.777-1.01 2.783-2.783.009-2.747.004-5.493-.001-8.24 0-.154.068-.328-.105-.517zm-18.661-5.04c.27-.28.627-.34.997-.34 3.263 0 6.523-.006 9.786.005.854.005 1.385.522 1.366 1.257-.017.705-.516 1.137-1.391 1.144-1.642.015-3.286.005-4.93.005-1.618 0-3.237.002-4.854 0-.569-.003-1.056-.176-1.283-.748-.202-.503-.054-.947.309-1.324v.002zm5.906 9.862c-.818.013-1.638.003-2.457.003-.82 0-1.64.012-2.459-.004-.825-.015-1.382-.494-1.388-1.158-.006-.747.485-1.208 1.382-1.217a209.77 209.77 0 0 1 4.99 0c.893.012 1.361.48 1.336 1.245-.023.686-.545 1.118-1.403 1.131h-.001z"
        fill="url(#f0hvm9i71a)"
        transform="translate(6 5)"
      />
    </g>
  </svg>
);

const OpenFeedsIcon: React.FunctionComponent = () => {
  return <Icon component={OpenFeedsSvg} />;
};

export default OpenFeedsIcon;
