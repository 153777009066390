import { Box } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import Icons from "components/Icons";
import SimplePopup from "components/SimplePopup";
import { ProductStage, StageTransition } from "mock/ProductStageData";
import React from "react";
import { textColor } from "shared/constants/AppConst";

import StageFlow from "./StageFlow";
import StageTable from "./StageTable";
import TransitionTable from "./TransitionTable";

const { FillDetailIcon } = Icons;
export interface DetailProps {
  stages: ProductStage[];
  transitions: StageTransition[];
  nodeDataArray: go.ObjectData[];
  linkDataArray: go.ObjectData[];
}

const Detail: React.FC<DetailProps> = ({ stages, transitions, nodeDataArray, linkDataArray }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className=" inline-block">
      <Box onClick={handleClickOpen} sx={{ mr: "4px", cursor: "pointer" }}>
        <FillDetailIcon sx={{ width: "16px", height: "16px", color: textColor["65"] }} />
      </Box>

      {/* <AppSvgIcon
        onClick={handleClickOpen}
        size={16}
        color={textColor["65"]}
        sxStyle={{ cursor: "pointer", mr: "4px" }}
      >
        material-outline:view_list
      </AppSvgIcon> */}
      <SimplePopup
        handleClose={handleClose}
        open={open}
        titleId="setting.product.stage.detailTitle"
        maxWidth="xl"
        fullWidth={true}
      >
        <StageTable stages={stages}></StageTable>
        {/* <StageFlow nodeDataArray={nodeDataArray} linkDataArray={linkDataArray}></StageFlow> */}
        <TransitionTable transitions={transitions}></TransitionTable>
      </SimplePopup>
    </Box>
  );
};

export default Detail;
