import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Card, CardContent, CardHeader, Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { textColor } from "shared/constants/AppConst";
import * as yup from "yup";

import ExportRichEditorTemplate from "../components/ExportRichEditorTemplate";
import TemplateHeader from "../components/TemplateHeader";

const Container = styled(Box)(({ theme }) => ({
  ".template-content-layout": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "calc(100vh - 104px)",
    ".MuiCard-root": {
      boxShadow: "none",
    },
  },
}));

const html = `
<p>
  <p>
    <span>{{item.Id}}</span>
    <span>{{item.Name}}</span>
  </p>
  <p>
    <p>
      <span>Created: {{item.CreatedDate}}</span>
    </p>
    <p>
      <span>Created: {{item.UpdatedDate}}</span>
    </p>
  </p>
  <p>
    <table>
      <tbody>
        <tr>
          <td><strong>Item ID:</strong></td>
          <td>{{item.Id}}</td>
        </tr>
        <tr>
          <td><strong>Global ID:</strong></td>
          <td>{{item.GlobalId}}</td>
        </tr>
        <tr>
          <td><strong>Description:</strong></td>
          <td>{{item.Description}}</td>
        </tr>
        <tr>
          <td><strong>Priority:</strong></td>
          <td>{{item.Priority}}</td>
        </tr>
        <tr>
          <td><strong>Status:</strong></td>
          <td>{{item.Status}}</td>
        </tr>
        <tr>
          <td><strong>Tag:</strong></td>
          <td>{{item.Tags}}</td>
        </tr>
      </tbody>
    </table>
  </p>
  <p>
    <h4>Relationships</h4>
    <table>
      <tbody>
        <tr>
          <td><strong>Item ID</strong></td>
          <td><strong>Name</strong></td>
          <td><strong>Direction</strong></td>
          <td><strong>Relationship</strong></td>
          <td><strong>Product</strong></td>
        </tr>
        <tr>
          <td>{{item.LinkedItems[0].Id}}</td>
          <td>{{item.LinkedItems[0].Name}}</td>
          <td>{{item.LinkedItems[0].Direction}}</td>
          <td>{{item.LinkedItems[0].LinkType}}</td>
          <td>
            {{item.LinkedItems[0].ProductId}}
            {{item.LinkedItems[0].ProductName}}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </p>
</p>
`;

const validationSchema = yup.object({});

const CardContainer = styled(Card)(({ theme }) => ({
  "&.MuiCard-root": {
    padding: theme.spacing(6, 0),
    boxShadow: "none",
    borderRadius: 0,
    ".MuiCardHeader-root": {
      padding: theme.spacing(4, 0),
    },
  },
}));

interface TemplateItemsProps {
  titleText: string;
}
const AllItemDetails = (props: TemplateItemsProps) => {
  const { titleText } = props;

  const [inEdit, setInEdit] = useState(false);

  const [richEditorTemplateHtml, setRichEditorTemplateHtml] = useState(html);

  const [oldState, setOldState] = useState({
    word: false,
    pdf: false,
    html: false,
    includeRelationships: false,
    includeTags: false,
  });

  const handleEditClick = (formik: FormikProps<any>) => {
    setOldState({
      ...formik.values,
    });
    setInEdit(true);
  };
  const handleSaveClick = (formik: FormikProps<any>) => {
    setInEdit(false);
  };
  const handleCancelClick = (formik: FormikProps<any>) => {
    formik.setFieldValue("word", oldState.word);
    formik.setFieldValue("pdf", oldState.pdf);
    formik.setFieldValue("html", oldState.html);
    formik.setFieldValue("includeRelationships", oldState.includeRelationships);
    formik.setFieldValue("includeTags", oldState.includeTags);
    setInEdit(false);
  };
  const editSaveBtnsFn = useCallback(
    (formik) => {
      if (inEdit) {
        return (
          <Box>
            <Button
              className="text-color-08"
              sx={{
                mr: 3,
                borderRadius: "4px",
                border: "solid 1px rgba(0, 0, 0, 0.15)",
                color: textColor["85"],
              }}
              variant="outlined"
              startIcon={
                <AppSvgIcon size={16} color={textColor["65"]}>
                  {"material-solid:cancel"}
                </AppSvgIcon>
              }
              onClick={() => {
                handleCancelClick(formik);
              }}
              id="report_cancel"
            >
              <IntlMessages id="common.cancel" />
            </Button>
            <Button
              variant="contained"
              startIcon={
                <AppSvgIcon size={16} color="#FFF">
                  {"material-solid:save"}
                </AppSvgIcon>
              }
              onClick={() => {
                handleSaveClick(formik);
              }}
              id="report_save_submit"
            >
              <IntlMessages id="common.save" />
            </Button>
          </Box>
        );
      }
      return (
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<AppSvgIcon size={16}>{"material-solid:edit"}</AppSvgIcon>}
          onClick={() => {
            handleEditClick(formik);
          }}
        >
          <IntlMessages id="common.edit" />
        </Button>
      );
    },
    [inEdit]
  );

  return (
    <>
      <Container>
        <Formik
          initialValues={{
            id: "",
            word: false,
            pdf: false,
            html: false,
            includeRelationships: false,
            includeTags: false,
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting, resetForm }) => {
            console.log(data);
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => (
            <Form
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              noValidate
              autoComplete="off"
            >
              <TemplateHeader
                title={titleText}
                subTitle={<IntlMessages id="setting.export.templates.subTitle.allItemDetails" />}
              >
                {editSaveBtnsFn(formik)}
              </TemplateHeader>
              <CardContainer>
                <CardHeader
                  sx={{}}
                  title={<IntlMessages id="setting.export.templates.subTitle.applicableFormats" />}
                ></CardHeader>
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item xs={4} md={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="word"
                              onChange={(e) => {
                                formik.setFieldValue("word", e.target.checked);
                              }}
                              disabled={!inEdit}
                              checked={formik.values.word}
                            />
                          }
                          label={<IntlMessages id="setting.export.templates.subTitle.applicableFormats.word" />}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="pdf"
                              onChange={(e) => {
                                formik.setFieldValue("pdf", e.target.checked);
                              }}
                              disabled={!inEdit}
                              checked={formik.values.pdf}
                            />
                          }
                          label={<IntlMessages id="setting.export.templates.subTitle.applicableFormats.pdf" />}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="html"
                              onChange={(e) => {
                                formik.setFieldValue("html", e.target.checked);
                              }}
                              disabled={!inEdit}
                              checked={formik.values.html}
                            />
                          }
                          label={<IntlMessages id="setting.export.templates.subTitle.applicableFormats.html" />}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardContainer>
              <CardContainer>
                <CardHeader
                  sx={{}}
                  title={<IntlMessages id="setting.export.templates.subTitle.parameters" />}
                ></CardHeader>
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item xs={4} md={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="includeRelationships"
                              onChange={(e) => {
                                formik.setFieldValue("includeRelationships", e.target.checked);
                              }}
                              disabled={!inEdit}
                              checked={formik.values.includeRelationships}
                            />
                          }
                          label={<IntlMessages id="setting.export.templates.subTitle.parameters.relationships" />}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="includeTags"
                              onChange={(e) => {
                                formik.setFieldValue("includeTags", e.target.checked);
                              }}
                              disabled={!inEdit}
                              checked={formik.values.includeTags}
                            />
                          }
                          label={<IntlMessages id="setting.export.templates.subTitle.parameters.tags" />}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardContainer>
              <CardContainer>
                <CardHeader
                  sx={{}}
                  title={<IntlMessages id="setting.export.templates.subTitle.parameters" />}
                ></CardHeader>
                <CardContent>
                  <ExportRichEditorTemplate
                    data={richEditorTemplateHtml}
                    inEdit={inEdit}
                    onChange={(data) => {
                      setRichEditorTemplateHtml(data);
                    }}
                  />
                </CardContent>
              </CardContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default AllItemDetails;
