import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

function not(a: TransferModel[], b: TransferModel[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: TransferModel[], b: TransferModel[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
interface TransferModel {
  id: number | string;
  name: string;
  displayOrder: number;
}
interface TransferListProps {
  initLeft: TransferModel[];
  initRight: TransferModel[];
}

const TransferList: React.FC<TransferListProps> = (props: TransferListProps) => {
  const { initLeft, initRight } = props;
  const [checked, setChecked] = React.useState<TransferModel[]>([]);
  const [left, setLeft] = React.useState<TransferModel[]>(initLeft);
  const [right, setRight] = React.useState<TransferModel[]>(initRight);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: TransferModel) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const handleUp = () => {
    console.log("handleUp");
  };
  const handleDown = () => {
    console.log("handleDown");
  };

  const customList = (headerTextId: string, items: TransferModel[]) => (
    <Card
      sx={{
        width: 220,
        height: "calc(100vh - 420px)",
        p: 5,
        boxShadow: "0 2px 6px 0 rgb(0 0 0 / 12%)",
      }}
    >
      <AppScrollbar
        sx={{
          width: "100%",
          height: "calc(100vh - 420px)",
        }}
      >
        <CardHeader title={<IntlMessages id={headerTextId} />} />
        <Divider />
        <CardContent>
          <List dense component="div" role="list">
            {items.map((value: TransferModel) => {
              const labelId = `transfer-list-item-${value}-label`;

              return (
                <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.name} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </CardContent>
      </AppScrollbar>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList("process.worksheet.hidden.fields", left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList("process.worksheet.visible.fields", right)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleUp}
            disabled={rightChecked.length === 0}
            aria-label="move all right"
          >
            <IntlMessages id={"common.up"} />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleDown}
            disabled={rightChecked.length === 0}
            aria-label="move selected right"
          >
            <IntlMessages id={"common.down"} />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TransferList;
