import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Chip, InputAdornment, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { styled } from "@mui/material/styles";
import DataIcon from "components/Icons/DataIcon";
import SendIcon from "components/Icons/SendIcon";
import { DCDProcessData } from "mock/ModuleData";
import { VersionList } from "mock/VersionList";
import React from "react";
import { Outlet } from "react-router-dom";
import { borderColor, borderRadius, boxShadow, chipColor, textColor } from "shared/constants/AppConst";

import ContentHeader from "../components/ContentHeader";
import DataSourceModal from "../components/DataSourceModal";
import SearchTree from "../components/SearchTree";
import ToolBar from "../components/ToolBar";
import MoreActions from "./MoreActions";

const templates: any[] = [
  {
    id: "template001",
    label: "System Default",
  },
  {
    id: "template002",
    label: "Template 1",
  },
  {
    id: "template003",
    label: "Template 2",
  },
  {
    id: "template004",
    label: "Template 3",
  },
];

const ProcessSettingsContainer = styled(Box)(({ theme }) => ({
  ".content-layout": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "calc(100vh - 104px)",
  },
  ".left-container": {
    width: "20%",
    height: "100%",
    borderRight: `1px solid ${borderColor.dividerColor}`,
    backgroundColor: theme.palette.background.paper,
  },
  ".right-container": {
    width: "80%",
    padding: theme.spacing(5),
  },
}));

const ProcessSettings = () => {
  const [template, setTemplate] = React.useState(templates[0]?.id);
  const [version, setVersion] = React.useState(VersionList[0]?.id);
  const [openDataSourceModal, setOpenDataSourceModal] = React.useState<boolean>(false);
  const [processData, setProcessData] = React.useState(DCDProcessData[0]);

  const handleTemplateChange = (event: SelectChangeEvent) => {
    setTemplate(event.target.value);
  };

  const handleVersionChange = (event: SelectChangeEvent) => {
    setVersion(event.target.value);
  };

  return (
    <ProcessSettingsContainer>
      <ContentHeader>
        <Box sx={{ display: "inline-block", verticalAlign: "middle" }}>
          <Select
            sx={{
              m: "2 2 2 0",
              minWidth: 300,
              fontWeight: 500,
              background: "#fff",
              "& .MuiInputAdornment-root": {
                color: textColor[85],
              },
              "& .MuiTypography-body1": {
                color: textColor[85],
              },
              "& .MuiInputBase-input": {
                padding: "6px 0px",
                color: textColor[85],
              },
            }}
            id="template-select"
            value={template}
            onChange={handleTemplateChange}
            startAdornment={<InputAdornment position="start">Template:</InputAdornment>}
          >
            {templates.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                {template.label}
              </MenuItem>
            ))}
          </Select>
          <Select
            sx={{
              m: 2,
              minWidth: 50,
              background: "#fff",
              "& .MuiInputAdornment-root": {
                color: textColor[85],
              },
              "& .MuiTypography-body1": {
                color: textColor[85],
              },
              "& .MuiInputBase-input": {
                padding: "6px 0px",
                color: textColor[85],
              },
            }}
            id="template-version-select"
            value={version}
            label=""
            onChange={handleVersionChange}
            startAdornment={<InputAdornment position="start">Version:</InputAdornment>}
          >
            {VersionList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
                <Chip
                  size="small"
                  label={item.status}
                  sx={{
                    textAlign: "center",
                    background: chipColor.versionBgColor,
                    color: textColor[85],
                    m: 2,
                    height: "20px",
                    borderRadius: borderRadius.main,
                    margin: "0px 8px",
                  }}
                />
              </MenuItem>
            ))}
          </Select>
          <Button
            sx={{
              color: textColor[85],
              border: `solid 1px ${borderColor.dividerColor}`,
              fontWeight: "normal",
              m: 2,
            }}
            startIcon={<SendIcon />}
            variant="outlined"
          >
            <IntlMessages id="setting.process.send.for.approval" />
          </Button>
          <Button
            sx={{
              color: textColor[85],
              border: `solid 1px ${borderColor.dividerColor}`,
              fontWeight: "normal",
              m: 2,
            }}
            startIcon={<DataIcon />}
            variant="outlined"
            onClick={() => {
              setOpenDataSourceModal(true);
            }}
          >
            <IntlMessages id="setting.process.dataSources" />
          </Button>
          <MoreActions actionLevel={0} />
        </Box>
      </ContentHeader>
      <Box className="content-layout">
        <AppScrollbar className="left-container">
          <SearchTree handleSelectProcess={setProcessData} />
        </AppScrollbar>
        <Box className="right-container">
          <ToolBar selectedProcess={processData} />
          <AppScrollbar
            sx={{
              height: "calc(100% - 56px)",
              padding: "20px",
              background: "#fff",
              boxShadow: boxShadow.cardBoxShadow,
            }}
          >
            <Outlet />
          </AppScrollbar>
        </Box>
      </Box>
      <DataSourceModal
        open={openDataSourceModal}
        handleClose={() => {
          setOpenDataSourceModal(false);
        }}
      />
    </ProcessSettingsContainer>
  );
};

export default ProcessSettings;
