import { Box } from "@mui/material";
import React, { ReactNode } from "react";

interface DefaultLayoutWrapperProps {
  children: ReactNode;

  [x: string]: any;
}

const DefaultLayoutWrapper: React.FC<DefaultLayoutWrapperProps> = ({
  children,
  ...rest
}) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        // backgroundColor: (theme) => theme.palette.background.default,
        paddingTop: { xs: 12, sm: 14 },
        "& .navbarNav": {
          display: "flex",
          py: 0,
        },
        "& .navItem": {
          width: "auto"
        },
        "& .main-content": {
          display: "flex",
          flexDirection: "column",
          // position: "relative",
          width: "100%",//{ xs: "100%", lg: "calc(100% - 220px)" },
          transition: "all 0.5s ease",
          // ml: { lg: "220px" },
        },
        "&.appMainFixedFooter": {
          pb: { xs: 12, xl: 14.5 },
        },
        // "&.mini-sidebar-collapsed": {
        //   "& .mainContent": {
        //     width: { lg: "calc(100% - 56px)" },
        //     ml: { lg: "56px" },
        //   },
        //   "& .user-header-sidebar": {
        //     width: { lg: 56 },
        //     overflow: "hidden",
        //     "& .nav-item-content,& .nav-item-header": {
        //       display: "none",
        //       transition: "all 0.5s ease",
        //     },
        //     // "& .nav-item-icon": {
        //     //   mr: 0,
        //     //   ml: 0.5,
        //     // },
        //   },
        // },
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default DefaultLayoutWrapper;
