import { Box } from "@mui/material";
import Icon from "@mui/material/Icon";
import * as React from "react";

const ModuleSvg = () => (
  <Box
    sx={{
      width: "32px",
      height: "32px",
      padding: "9px 8px 8px 9px",
      borderRadius: "4px",
      backgroundImage: "linear-gradient(to top, #5ba5e7, #2e6ccb)",
    }}
  >
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(.5 .5)" fill="#FFF" fillRule="evenodd">
        <rect width="3" height="3" rx="1" />
        <rect y="6" width="3" height="3" rx="1" />
        <rect y="12" width="3" height="3" rx="1" />
        <rect x="6" width="3" height="3" rx="1" />
        <rect x="6" y="6" width="3" height="3" rx="1" />
        <rect x="6" y="12" width="3" height="3" rx="1" />
        <rect x="12" width="3" height="3" rx="1" />
        <rect x="12" y="6" width="3" height="3" rx="1" />
        <rect x="12" y="12" width="3" height="3" rx="1" />
      </g>
    </svg>
  </Box>
);

const ModuleIcon: React.FunctionComponent = () => {
  return <Icon component={ModuleSvg} />;
};

export default ModuleIcon;
