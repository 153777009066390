import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { Box, Button, IconButton, Tab, Tabs, TextField } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import SendIcon from "components/Icons/SendIcon";
import SimplePopup from "components/SimplePopup";
import * as React from "react";
import { useIntl } from "react-intl";
import { bgColor, borderColor, tabStyle, textColor } from "shared/constants/AppConst";

const allUsers = {
  "project.team": [
    {
      id: 4,
      name: "Group1",
      type: "group",
      children: [
        {
          id: 41,
          name: "Goup1_User1",
          type: "user",
        },
        {
          id: 42,
          name: "Goup1_User2",
          type: "user",
        },
        {
          id: 43,
          name: "Goup1_User3",
          type: "user",
        },
        {
          id: 44,
          name: "Goup1_User4",
          type: "user",
        },
      ],
    },
    {
      id: 411,
      name: "Group3",
      type: "group",
      children: [
        {
          id: 4111,
          name: "Goup3_User1",
          type: "user",
        },
        {
          id: 4211,
          name: "Goup3_User2",
          type: "user",
        },
        {
          id: 4311,
          name: "Goup3_User3",
          type: "user",
        },
        {
          id: 4411,
          name: "Goup3_User4",
          type: "user",
        },
      ],
    },
    {
      id: 1,
      name: "User1",
      type: "user",
    },
    {
      id: 2,
      name: "User2",
      type: "user",
    },
    {
      id: 3,
      name: "User3",
      type: "user",
    },
    {
      id: 5,
      name: "User5",
      type: "user",
    },
    {
      id: 6,
      name: "User6",
      type: "user",
    },
    {
      id: 7,
      name: "User7",
      type: "user",
    },
    {
      id: 8,
      name: "User8",
      type: "user",
    },
    {
      id: 9,
      name: "User9",
      type: "user",
    },
    {
      id: 10,
      name: "User10",
      type: "user",
    },
    {
      id: 11,
      name: "User11",
      type: "user",
    },
    {
      id: 12,
      name: "User12",
      type: "user",
    },
  ],
  "outside.project.team": [
    {
      id: 16,
      name: "Group16",
      type: "group",
      children: [
        {
          id: 161,
          name: "Goup2_User1",
          type: "user",
        },
        {
          id: 162,
          name: "Goup2_User2",
          type: "user",
        },
        {
          id: 163,
          name: "Goup2_User3",
          type: "user",
        },
        {
          id: 164,
          name: "Goup2_User4",
          type: "user",
        },
      ],
    },
    {
      id: 13,
      name: "User13",
      type: "user",
    },
    {
      id: 14,
      name: "User14",
      type: "user",
    },
    {
      id: 15,
      name: "User15",
      type: "user",
    },
    {
      id: 17,
      name: "User17",
      type: "user",
    },
    {
      id: 18,
      name: "User18",
      type: "user",
    },
    {
      id: 19,
      name: "User19",
      type: "user",
    },
    {
      id: 20,
      name: "User20",
      type: "user",
    },
    {
      id: 21,
      name: "User21",
      type: "user",
    },
    {
      id: 22,
      name: "User22",
      type: "user",
    },
    {
      id: 23,
      name: "User23",
      type: "user",
    },
    {
      id: 24,
      name: "User24",
      type: "user",
    },
  ],
};

interface SelectMenbersModalProps {
  handleClose: () => void;
  open: boolean;
  titleId: string;
}

function a11yProps(index: number | string) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SelectMenbersModal(props: SelectMenbersModalProps) {
  const { messages } = useIntl();
  const { open, handleClose, titleId } = props;

  const [index, setIndex] = React.useState("project.team");
  const [optional, setOptional] = React.useState(allUsers["project.team"]);
  const [participant, setParticipant] = React.useState([]);
  const [organization, setOrganization] = React.useState("");
  const [searchText, setSearchText] = React.useState("");

  const save = () => {
    handleClose && handleClose();
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setIndex(newValue);
    setOptional(allUsers[newValue]);
    setSearchText("");
    setOrganization("");
  };

  const handleItemClick = (value) => {
    if (value.type === "group") {
      setOrganization(value.name);
      setOptional(value.children);
    } else if (!participant.some((item) => item.id === value.id)) {
      setParticipant([...participant, value]);
    }
  };

  const handleBack = () => {
    setOptional(allUsers[index]);
    setOrganization("");
  };

  const addAllUsers = () => {
    const newParticipant = [...participant];
    optional.map((value) => {
      if (!newParticipant.some((item) => item.id === value.id)) {
        newParticipant.push(value);
      }
    });
    setParticipant(newParticipant);
  };

  const delParticipant = (value) => {
    const newParticipant = [...participant];
    for (let i = 0; i < newParticipant.length; i++) {
      if (newParticipant[i].id === value.id) {
        newParticipant.splice(i, 1);
        break;
      }
    }
    setParticipant(newParticipant);
  };

  return (
    <SimplePopup
      okTextId="common.send"
      okIcon={<SendIcon isWhite={true} />}
      handleClose={handleClose}
      open={open}
      titleId={titleId}
      save={save}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "1000px",
          width: "1000px",
        },
        "& .MuiDialogContent-root": {
          height: "600px",
          background: bgColor.main,
        },
      }}
    >
      <Box
        className="flex justify-between"
        sx={{
          border: `solid 1px ${borderColor.dividerColor}`,
          height: "100%",
          background: (theme) => theme.palette.background.paper,
        }}
      >
        <Box sx={{ width: "35%", borderRight: `solid 1px ${borderColor.dividerColor}` }}>
          <Box
            sx={{
              borderBottom: `solid 1px ${borderColor.dividerColor}`,
            }}
          >
            <Tabs value={index} onChange={handleChangeTab} sx={tabStyle}>
              <Tab value={"project.team"} label={<IntlMessages id="common.project.team" />} {...a11yProps(0)} />
              <Tab
                value={"outside.project.team"}
                label={<IntlMessages id="common.outside.project.team" />}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          {organization ? (
            <Box
              className="flex items-center justify-between"
              sx={{ p: 3, borderBottom: `solid 1px ${borderColor.dividerColor}`, height: "56px" }}
            >
              <Box className="font-bold" sx={{ color: textColor[85] }}>
                {organization}
              </Box>
              <Box>
                <Button
                  sx={{
                    color: textColor[85],
                    border: `solid 1px ${borderColor.dividerColor}`,
                    fontWeight: "normal",
                    mr: 2,
                  }}
                  variant="outlined"
                  onClick={addAllUsers}
                >
                  <IntlMessages id="common.add.all.users" />
                </Button>
                <Button
                  sx={{
                    color: textColor[85],
                    border: `solid 1px ${borderColor.dividerColor}`,
                    fontWeight: "normal",
                  }}
                  variant="outlined"
                  onClick={handleBack}
                >
                  <IntlMessages id="common.back" />
                </Button>
              </Box>
            </Box>
          ) : (
            <Box sx={{ p: 3, borderBottom: `solid 1px ${borderColor.dividerColor}`, height: "56px" }}>
              <TextField
                variant="outlined"
                value={searchText}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value)}
                placeholder={String(messages["common.search.by.email.or.name"])}
                InputProps={{
                  startAdornment: (
                    <AppSvgIcon size={16} color="action">
                      {"material-outline:search"}
                    </AppSvgIcon>
                  ),
                  endAdornment: (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{ visibility: searchText ? "visible" : "hidden" }}
                        onClick={() => {
                          setSearchText("");
                        }}
                      >
                        <AppSvgIcon size={16} color="action">
                          {"material-outline:clear"}
                        </AppSvgIcon>
                      </IconButton>
                    </Box>
                  ),
                }}
                sx={{
                  width: {
                    xs: 1,
                  },
                  "& .MuiIconButton-root": {
                    top: 0,
                    right: 0,
                  },
                  "& .MuiInputBase-root": {
                    padding: "0px 12px",
                    height: "32px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "0px",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                  },
                }}
              />
            </Box>
          )}
          <AppScrollbar
            sx={{
              height: "444px",
            }}
          >
            <Box>
              {optional.map((item) => (
                <Box
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    handleItemClick(item);
                  }}
                  sx={{
                    height: 56,
                    px: 3,
                    py: 2,
                    position: "relative",
                    "&:hover": {
                      background: "rgba(0, 0, 0, 0.05)",
                      width: "100%",
                      ".arrow": {
                        display: "block",
                      },
                    },
                  }}
                  key={item.id}
                >
                  <Box>
                    <AppSvgIcon size={40} color="action">
                      {item.type === "group" ? "material-solid:groups" : "material-solid:account_circle"}
                    </AppSvgIcon>
                  </Box>
                  {item.type === "group" ? (
                    <Box
                      sx={{
                        ml: 2,
                        color: textColor[85],
                        flexDirection: "column",
                      }}
                      className="flex"
                    >
                      <Box className="font-bold">{item.name}</Box>
                      <Box sx={{ color: textColor[65] }}>
                        <IntlMessages id="admin.profile.form.organization" />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        ml: 2,
                        color: textColor[85],
                      }}
                      className="font-bold"
                    >
                      {item.name}
                    </Box>
                  )}
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: "12px",
                      display: "none",
                      transform: item.type === "group" ? "translateY(-50%)" : "translateY(-50%) rotate(-90deg)",
                    }}
                    className="arrow"
                  >
                    <ExpandCircleDownOutlinedIcon />
                  </Box>
                </Box>
              ))}
            </Box>
          </AppScrollbar>
        </Box>
        <Box sx={{ width: "65%", borderLeft: `solid 1px ${borderColor.dividerColor}` }}>
          <Box className="flex items-center" sx={{ borderBottom: `solid 1px ${borderColor.dividerColor}`, pl: 3 }}>
            <Box
              className="w-full flex items-center"
              sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
            >
              <h3>
                <IntlMessages id="common.participant" />
              </h3>
            </Box>
          </Box>
          <AppScrollbar
            sx={{
              height: "500px",
            }}
          >
            <Box>
              {participant.map((item) => (
                <Box
                  className="flex items-center"
                  sx={{
                    height: 56,
                    px: 3,
                    py: 2,
                    position: "relative",
                    width: "100%",
                    borderBottom: `solid 1px ${borderColor.dividerColor}`,
                    ":hover": {
                      ".close": {
                        display: "block",
                      },
                    },
                  }}
                  key={item.id}
                >
                  <Box>
                    <AppSvgIcon size={40} color="action">
                      {"material-solid:account_circle"}
                    </AppSvgIcon>
                  </Box>

                  <Box
                    sx={{
                      ml: 2,
                      color: textColor[85],
                    }}
                    className="font-bold"
                  >
                    {item.name}
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: "12px",
                      display: "none",
                      transform: "translateY(-50%)",
                    }}
                    className="cursor-pointer close"
                    onClick={() => {
                      delParticipant(item);
                    }}
                  >
                    <AppSvgIcon size={40} color="action">
                      {"material-outline:close"}
                    </AppSvgIcon>
                  </Box>
                </Box>
              ))}
            </Box>
          </AppScrollbar>
        </Box>
      </Box>
    </SimplePopup>
  );
}
