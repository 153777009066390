import {
  UPDATE_ACTIVITIES_TYPE,
  UPDATE_DETAIL_MENU,
  UPDATE_FEEDS_TYPE,
  UPDATE_MODULE_LIST,
  WorkspacesActionTypes } from "../../types/actions/Workspaces.action";
  
export const handleModuleList = (moduleList: any[]): WorkspacesActionTypes => ({
  type: UPDATE_MODULE_LIST,
  moduleList,
});

export const handleFeedsType = (feedsType: number): WorkspacesActionTypes => ({
  type: UPDATE_FEEDS_TYPE,
  feedsType,
});

export const handleActivitiesType = (activitiesType: number): WorkspacesActionTypes => ({
  type: UPDATE_ACTIVITIES_TYPE,
  activitiesType,
});

export const handleWorkspaceDetailMenu = (workspacesDetailMenu: string): WorkspacesActionTypes => ({
  type: UPDATE_DETAIL_MENU,
  workspacesDetailMenu,
});