import ProcessSettings from "./ProcessSettings";
import Checklist from "./ProcessSettings/Checklist";
import Collaboration from "./ProcessSettings/Collaboration";
import DataSources from "./ProcessSettings/DataSources";
import ImpactSummary from "./ProcessSettings/ImpactSummary";
import Input from "./ProcessSettings/Input";
import ExternalReference from "./ProcessSettings/Input/ExternalReference";
import InternalReference from "./ProcessSettings/Input/InternalReference";
import Required from "./ProcessSettings/Input/Required";
import Report from "./ProcessSettings/Report";
import Scope from "./ProcessSettings/Scope";
import Worksheet from "./ProcessSettings/Worksheet";
import Analysis from "./ProcessSettings/Worksheet/Analysis";
import Format from "./ProcessSettings/Worksheet/Format";

export const adminPage = [
  {
    path: "processSettings",
    element: <ProcessSettings />,
    children: [
      {
        path: ":vid/dataSources/:pid",
        element: <DataSources />,
      },
      {
        path: ":vid/scope/:pid",
        element: <Scope />,
      },
      {
        path: ":vid/input/tab/:pid",
        element: <Input />,
        children: [
          {
            path: "required",
            element: <Required />,
          },
          {
            path: "internalReference",
            element: <InternalReference />,
          },
          {
            path: "externalReference",
            element: <ExternalReference />,
          },
        ],
      },
      // {
      //   path: ":vid/input",
      //   element: <Navigate to="tab/required" />,
      // },
      {
        path: ":vid/collaboration/:pid",
        element: <Collaboration />,
      },
      {
        path: ":vid/worksheet/tab/:pid",
        element: <Worksheet />,
        children: [
          {
            path: "format",
            element: <Format />,
          },
          {
            path: "analysis",
            element: <Analysis />,
          },
          // {
          //   path: "perspectives",
          //   element: <Perspectives />,
          // },
          // {
          //   path: "resources",
          //   element: <Resources />,
          // },
        ],
      },
      // {
      //   path: ":vid/worksheet/:pid",
      //   element: <Navigate to="tab/format" />,
      // },
      {
        path: ":vid/impactSummary/:pid",
        element: <ImpactSummary />,
      },
      {
        path: ":vid/checklist/:pid",
        element: <Checklist />,
      },
      {
        path: ":vid/report/:pid",
        element: <Report />,
      },
    ],
  },
];
