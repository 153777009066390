import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { textColor } from "shared/constants/AppConst";

interface ResolveProps {}

const ResolveContainer = styled(Box)(({ theme }) => ({
  padding: "16px",
  paddingBottom: "12px",
  background: "#FAFAFA",
  height: "100%",
  position: "relative",
  ".action": {
    position: "absolute",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    left: "50%",
    transform: "translateX(-50%), translateY(-50%)",
  },
}));

export default function Resolve(props: ResolveProps) {
  return (
    <ResolveContainer>
      <Box
        className="w-full flex items-center"
        sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
      >
        <h3>
          <IntlMessages id="common.reuse.options" />
        </h3>
      </Box>
      <Box className="action">
        <Button variant="contained">
          <IntlMessages id="products.reuse" />
        </Button>
        <Button sx={{ ml: 4 }} variant="outlined">
          <IntlMessages id="common.cancel" />
        </Button>
      </Box>
    </ResolveContainer>
  );
}
