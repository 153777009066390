export interface ProductStage {
  id: string;
  name: string;
  displayOrder: number;
  approved?: string;
  parentId: string;
  isDefault: boolean;
  level: number;
  children?: ProductStage[];
}

export interface StageTransition {
  id: number;
  linkText: string;
  fromStage: string;
  fromStageId: string;
  toStage: string;
  toStageId: string;
  permissionRoles: string;
  isDefault: boolean;
  lock?: boolean;
  notifications?: string;
}

export interface StageSet {
  id: number;
  name: string;
  order: number;
  description: string;
  system_default: boolean;
  stages?: ProductStage[];
  transitions?: StageTransition[];
  nodeDataArray?: go.ObjectData[];
  linkDataArray?: go.ObjectData[];
  editable?: boolean;
  deletable?: boolean;
}

export const ProductStageData: StageSet[] = [
  {
    id: 1,
    name: "General Product Life Cycle",
    order: 1,
    description: "Pre-Production, Production and Post-Production.",
    system_default: true,
    editable: false,
    deletable: false,
    stages: [
      {
        id: "pre_production",
        parentId: "",
        name: "Pre-Production",
        isDefault: true,
        level: 1,
        displayOrder: 1,
        approved: "",
        children: [
          {
            id: "concept_req",
            parentId: "pre_production",
            name: "1. Concept and Requirements",
            isDefault: true,
            level: 2,
            approved: "",
            displayOrder: 1,
          },
          {
            id: "design_dev",
            parentId: "pre_production",
            name: "2. Design and Development",
            isDefault: true,
            level: 2,
            approved: "",
            displayOrder: 2,
          },
          {
            id: "v_v",
            parentId: "pre_production",
            name: "3. Verification and Validation",
            isDefault: true,
            level: 2,
            approved: "",
            displayOrder: 3,
          },
          {
            id: "approval",
            parentId: "pre_production",
            name: "4. Regulatory Approval",
            isDefault: true,
            level: 2,
            approved: "",
            displayOrder: 4,
          },
        ],
      },
      {
        id: "production",
        parentId: "",
        name: "Production",
        isDefault: true,
        level: 1,
        approved: "",
        displayOrder: 2,
      },
      {
        id: "post_production",
        parentId: "",
        name: "Post-Production",
        isDefault: true,
        level: 1,
        approved: "Yes",
        displayOrder: 3,
      },
    ],
    transitions: [
      {
        id: 1,
        linkText: "Initialize",
        fromStage: "Product Created",
        fromStageId: "created",
        toStage: "Pre-Production",
        toStageId: "pre_production",
        notifications: "None",
        permissionRoles: "Product Manager",
        isDefault: false,
      },
      {
        id: 2,
        linkText: "Publish",
        fromStage: "Pre-Production",
        fromStageId: "pre_production",
        toStage: "Production",
        toStageId: "production",
        notifications: "None",
        permissionRoles: "Product Manager",
        isDefault: false,
      },
      {
        id: 3,
        linkText: "Maintenance",
        fromStage: "Production",
        fromStageId: "production",
        toStage: "Post-Production",
        toStageId: "post_production",
        lock: true,
        notifications: "None",
        permissionRoles: "Product Manager",
        isDefault: false,
      },
      {
        id: 4,
        linkText: "Implement",
        fromStage: "1. Concept and Requirements",
        fromStageId: "concept_req",
        toStage: "2. Design and Development",
        toStageId: "design_dev",
        notifications: "None",
        permissionRoles: "Product Manager",
        isDefault: false,
      },
      {
        id: 5,
        linkText: "Test",
        fromStage: "2. Design and Development",
        fromStageId: "design_dev",
        toStage: "3. Verification and Validation",
        toStageId: "v_v",
        notifications: "None",
        permissionRoles: "Product Manager",
        isDefault: false,
      },
      {
        id: 6,
        linkText: "Approve",
        fromStage: "3. Verification and Validation",
        fromStageId: "v_v",
        toStage: "4. Regulatory Approval",
        toStageId: "approval",
        notifications: "None",
        permissionRoles: "Product Manager",
        isDefault: false,
      },
    ],
    nodeDataArray: [
      {
        key: "stage-flow",
        text: "Stage Flow",
        isGroup: true,
        category: "Pool",
      },
      {
        key: "pre-production",
        text: "Pre-Production",
        isGroup: true,
        group: "stage-flow",
        color: "lightblue",
      },
      {
        key: "production",
        text: "Production",
        isGroup: true,
        group: "stage-flow",
        color: "lightgreen",
      },
      {
        key: "post-production",
        text: "Post-Production",
        isGroup: true,
        group: "stage-flow",
        color: "lightyellow",
      },
      {
        key: "concept_req",
        text: "1. Concept and Requirements",
        group: "pre-production",
      },
      {
        key: "design_dev",
        text: "2. Design and Development",
        group: "pre-production",
      },
      {
        key: "v_v",
        text: "3. Verification and Validation",
        group: "pre-production",
      },
      {
        key: "approval",
        text: "4. Regulatory Approval",
        group: "pre-production",
      },
    ],
    linkDataArray: [
      { from: "concept_req", to: "design_dev" },
      { from: "design_dev", to: "v_v" },
      { from: "v_v", to: "approval" },
    ],
  },
  {
    id: 2,
    name: "Process Product Life Cycle",
    order: 2,
    description: "Requirement, Development, On Market, Maintenance.",
    system_default: false,
    editable: true,
    deletable: true,
    stages: [
      {
        id: "req",
        parentId: "",
        name: "Requirement",
        isDefault: false,
        level: 1,
        displayOrder: 1,
      },
      {
        id: "dev",
        parentId: "",
        name: "Development",
        isDefault: false,
        level: 1,
        displayOrder: 2,
      },
      {
        id: "market",
        parentId: "",
        name: "On Market",
        isDefault: false,
        level: 1,
        displayOrder: 3,
      },
      {
        id: "main",
        parentId: "",
        name: "Maintenance",
        isDefault: false,
        level: 1,
        displayOrder: 4,
      },
    ],
    transitions: [
      {
        id: 1,
        linkText: "",
        fromStage: "Product Created",
        fromStageId: "created",
        toStage: "Requirement",
        toStageId: "req",
        notifications: "None",
        permissionRoles: "Product Manager",
        isDefault: false,
      },
      {
        id: 2,
        linkText: "Design",
        fromStage: "Requirement",
        fromStageId: "req",
        toStage: "Development",
        toStageId: "dev",
        notifications: "None",
        permissionRoles: "Product Manager",
        isDefault: false,
      },
      {
        id: 3,
        linkText: "Publish",
        fromStage: "Development",
        fromStageId: "dev",
        toStage: "On Market",
        toStageId: "market",
        notifications: "None",
        permissionRoles: "Product Manager",
        isDefault: false,
      },
      {
        id: 4,
        linkText: "",
        fromStage: "On Market",
        fromStageId: "market",
        toStage: "Maintenance",
        toStageId: "main",
        lock: true,
        notifications: "None",
        permissionRoles: "Product Manager",
        isDefault: false,
      },
    ],
    nodeDataArray: [
      {
        key: "stage-flow",
        text: "Stage Flow",
        isGroup: true,
        category: "Pool",
      },
      {
        key: "req",
        text: "Requirement",
        isGroup: true,
        group: "stage-flow",
        color: "lightblue",
      },
      {
        key: "dev",
        text: "Development",
        isGroup: true,
        group: "stage-flow",
        color: "lightgreen",
      },
      {
        key: "market",
        text: "On Market",
        isGroup: true,
        group: "stage-flow",
        color: "lightyellow",
      },
      {
        key: "main",
        text: "Maintenance",
        isGroup: true,
        group: "stage-flow",
        color: "lightpink",
      },
    ],
  },
];
