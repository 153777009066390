import IntlMessages from "@crema/utility/IntlMessages";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TabContext from "@mui/lab/TabContext";
import { Box, Button, Chip, InputAdornment, Link, Menu, MenuItem, Select, Tab, Tabs } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import { styled } from "@mui/material/styles";
import ButtonMenu from "components/ButtonMenu";
import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import CustomTable from "components/CustomTable";
import ExportDropdown from "components/ExportDropdown";
import CustomizeIcon from "components/Icons/CustomizeIcon";
import FillTaskIcon from "components/Icons/FillTaskIcon";
import SimplePopup from "components/SimplePopup";
import { VersionList } from "mock/VersionList";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { borderColor, borderRadius, chipColor, tabStyle, textColor } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

import MetricsSettingModal from "../MetricsSettingModal";
import AddFeed from "./AddFeed";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const ToolBarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minWidth: "1000px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  ".right-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: textColor[85],
  },
  ".right-header-left": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: textColor[85],
  },
  ".divider": {
    width: "1px",
    background: borderColor.dividerColor,
    height: "14px",
  },
}));

const rmTypeList: any[] = [
  {
    id: "1",
    label: "Jama",
  },
  {
    id: "2",
    label: "Polarion",
  },
  {
    id: "3",
    label: "DOORS Next",
  },
];

const dataSourceTypeList: ButtonMenuListItemModel[] = [
  {
    id: "1",
    label: "Hazard Category",
  },
  {
    id: "2",
    label: "System Hazards",
  },
  {
    id: "3",
    label: "Hazardous Situations",
  },
  {
    id: "4",
    label: "Sequence of Events/Causes",
  },
  {
    id: "5",
    label: "Safety Harms",
  },
];

const dataSourceTypeList1: ButtonMenuListItemModel[] = [
  {
    id: "6",
    label: "Design Input Requirements",
  },
  {
    id: "7",
    label: "User Needs",
  },
  {
    id: "8",
    label: "Validations",
  },
  {
    id: "9",
    label: "HW Subsystem Requirements",
  },
  {
    id: "10",
    label: "Design Specification",
  },
  {
    id: "11",
    label: "DMR Design Outputs",
  },
  {
    id: "12",
    label: "System Verifications",
  },
  {
    id: "13",
    label: "SW Verifications",
  },
  {
    id: "14",
    label: "HW Verifications",
  },
  {
    id: "15",
    label: "IQ/OQ/PQ",
  },
];

const DeliverablesTypeList: ButtonMenuListItemModel[] = [
  {
    id: "1",
    labelId: "common.worksheet",
  },
  {
    id: "2",
    labelId: "common.impact.summary",
  },
  {
    id: "3",
    labelId: "common.report",
  },
];

const releaseList: any[] = [
  {
    id: "102",
    label: "R1.2",
  },
  {
    id: "101",
    label: "R1.1",
  },
  {
    id: "100",
    label: "R1.0",
  },
];

const productList: any[] = [
  {
    id: "1",
    label: "Drug Delivery Device",
  },
  {
    id: "2",
    label: "Drug Delivery Device",
  },
  {
    id: "3",
    label: "Drug Delivery Device",
  },
  {
    id: "4",
    label: "Smart Med Device",
  },
];

const initRows: any[] = [
  {
    id: "IF#123",
    category: "Requirement Impact Analysis",
    source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
    source2: "S#2345343 Incorrectly controlled delivery",
    scope1: "DDD-TK#002 Design FMEA (V1.0)",
    scope2: "S#2343458 Fail to physically set up pump",
    type: "sha",
    module: "Risk Management",
    processId: "System Hazard Analysis",
    version: "100",
    type1: "df",
    module1: "Risk Management",
    processId1: "Design FMFA",
    version1: "101",
    description: "Fault Tree Analysis should be modified according to SHA.",
  },
  {
    id: "IF#124",
    category: "Requirement Impact Analysis",
    source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
    source2: "S#2345343 Incorrectly controlled delivery",
    scope1: "DDD-TK#002 Design FMEA (V1.0)",
    scope2: "S#2343456 Fail to set up administration set",
    type: "sha",
    module: "Risk Management",
    processId: "System Hazard Analysis",
    version: "100",
    type1: "df",
    module1: "Risk Management",
    processId1: "Design FMFA",
    version1: "101",
    description: "SHA in SDDDB and SDDDC should be updated.",
  },
  {
    id: "IF#125",
    category: "Requirement Impact Analysis",
    source1: "DDD-TK#001 System Hazard Analysis (V1.0)",
    source2: "S#2345343 Incorrectly controlled delivery",
    scope1: "DDD-TK#003 Process FMEA (V1.0)",
    scope2: "S#2312356 difficulty to lift pump",
    type: "sha",
    module: "Risk Management",
    processId: "System Hazard Analysis",
    version: "100",
    type1: "df",
    module1: "Risk Management",
    processId1: "Design FMFA",
    version1: "101",
    description: "Need create a system requirement",
  },
];

interface ToolBarProps {
  selectedValue: any;
  setSelectedValue: (item: any) => void;
  dataSourceType: any;
  setDataSourceType: (item: any) => void;
  rmType: any;
  setRmType: (item: any) => void;
  deliverablesVersion: any;
  setDeliverablesVersion: (value: any) => void;
  deliverablesProduct: any;
  setDeliverablesProduct: (value: any) => void;
  deliverableType: any;
  setDeliverableType: (item: any) => void;
  list: any;
  processData: any;
  fullWidth?: boolean;
  isTab?: boolean;
  sxStyle?: any;
}

const ToolBar: React.FC<ToolBarProps> = (props: ToolBarProps) => {
  const {
    selectedValue,
    setSelectedValue,
    list,
    rmType,
    setRmType,
    dataSourceType,
    setDataSourceType,
    deliverablesVersion,
    setDeliverablesVersion,
    deliverablesProduct,
    setDeliverablesProduct,
    deliverableType,
    setDeliverableType,
    processData,
    isTab,
  } = props;

  const routeParam = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [version, setVersion] = React.useState("102");
  const [showTable, setShowTable] = React.useState<boolean>(false);
  const [columns, setColumns] = React.useState<Column[]>([
    {
      id: "id",
      label: "workspace.column.id",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex items-center">
            <Box sx={{ marginLeft: 2, color: "#2E6CCB" }}>{value}</Box>
          </Box>
        );
      },
    },
    {
      id: "source",
      label: "common.source.of.impact",
      minWidth: 200,
      align: "left",
      format: (_, record) => {
        return (
          <Box>
            <Box>
              <Link
                sx={{ marginLeft: 2, cursor: "pointer" }}
                underline="none"
                onClick={() => {
                  navigate(
                    `/products/123/deliverables/${record.module}/${record.processId}/${record.type}/${record.version}/worksheet`
                  );
                }}
              >
                {record.source1}
              </Link>
            </Box>
            <Box>{record.source2}</Box>
          </Box>
        );
      },
    },
    {
      id: "scope",
      label: "workspace.column.suspects",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        return (
          <Box>
            <Box className="flex items-center">
              <Box sx={{ width: "16px" }}>
                <FillTaskIcon />
              </Box>
              <Link
                sx={{ marginLeft: 2, cursor: "pointer" }}
                underline="none"
                onClick={() => {
                  navigate(
                    `/products/123/deliverables/${record.module1}/${record.processId1}/${record.type1}/${record.version1}/worksheet`
                  );
                }}
              >
                {record.scope1}
              </Link>
            </Box>
            <Box>{record.scope2}</Box>
          </Box>
        );
      },
    },
    {
      id: "description",
      label: "common.description",
      minWidth: 200,
      align: "left",
    },
  ]);
  const [rows, setRows] = React.useState<any[]>(initRows);
  const [openFeedModal, setOpenFeedModal] = React.useState<boolean>(false);
  const [openSetting, setOpenSetting] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    sessionStorage.setItem("version", version);
  }, [version]);

  const handleSelected = (item: any) => {
    setSelectedValue(item);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const [chipData, setChipData] = React.useState<readonly ChipData[]>([{ key: 0, label: "Details", id: "11" }]);

  // const handleDelete = (chipToDelete: ChipData) => () => {
  //   setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  // };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    const target = list[0].children.filter((item) => item.id.toString() === newValue)[0];
    if (target) {
      setSelectedValue(target);
    }
  };

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = initRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  return (
    <ToolBarContainer>
      <Box>
        {isTab ? (
          <TabContext value={selectedValue.id}>
            <Tabs
              value={selectedValue.id}
              onChange={handleChangeTab}
              sx={{
                ...tabStyle,
                "& .MuiButtonBase-root": {
                  minHeight: "48px",
                  textTransform: "none",
                },
              }}
            >
              {list[0].children.map((item) => {
                return <Tab key={item.id} value={item.id} label={item.label} />;
              })}
            </Tabs>
          </TabContext>
        ) : (
          // <Button
          //   onClick={handleClick}
          //   variant="contained"
          //   fullWidth={props.fullWidth}
          //   endIcon={Boolean(anchorEl) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          // >
          //   {selectedValue.label}
          // </Button>
          <Box
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              lineHeight: 1.5,
              color: textColor[85],
              display: "inline-block",
              mr: 2,
            }}
          >
            {selectedValue.label}
          </Box>
        )}
        {/* <Menu
          id="button-menus"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            "& .MuiSvgIcon-root": {
              marginRight: 2,
            },
            maxWidth: "510px",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ display: "flex" }}>
            {list.map((item) => (
              <Box key={item.id} sx={{ mx: 2 }}>
                <ListSubheader
                  sx={{ paddingLeft: "12px", color: textColor[85], fontWeight: "bold", minWidth: "150px" }}
                  key={item.id}
                >
                  {item.label}
                </ListSubheader>
                {item.children.map((child) => (
                  <MenuItem
                    sx={{ paddingLeft: "34px", color: textColor[85] }}
                    key={child.id}
                    onClick={() => {
                      handleSelected(child);
                    }}
                  >
                    {child.label}
                  </MenuItem>
                ))}
              </Box>
            ))}
          </Box>
        </Menu> */}
        {selectedValue.id === "21" && (
          <ButtonMenu
            selectedValue={dataSourceType}
            setSelectedValue={setDataSourceType}
            list={
              routeParam?.type === "sha" || routeParam.type === "df" || routeParam.type === "ura"
                ? dataSourceTypeList
                : routeParam?.type === "dir"
                ? dataSourceTypeList1.splice(0, 3)
                : dataSourceTypeList1
            }
            sxStyle={{
              variant: "outlined",
              sx: {
                color: textColor[85],
                border: `solid 1px ${borderColor.dividerColor}`,
              },
            }}
          />
        )}
        {selectedValue.id === "32" && (
          <Select
            sx={{
              minWidth: 50,
              background: "#fff",
              "& .MuiInputAdornment-root": {
                color: textColor[85],
              },
              "& .MuiTypography-body1": {
                color: textColor[85],
              },
              "& .MuiInputBase-input": {
                padding: "6px 0px",
                color: textColor[85],
              },
            }}
            id="profile-version-select"
            value={rmType}
            label=""
            onChange={(e) => {
              setRmType(e.target.value);
            }}
            startAdornment={<InputAdornment position="start">Requirement Management:</InputAdornment>}
          >
            {rmTypeList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}
        {selectedValue.id === "31" && (
          <>
            <Select
              sx={{
                minWidth: 50,
                background: "#fff",
                "& .MuiInputAdornment-root": {
                  color: textColor[85],
                },
                "& .MuiInputBase-input": {
                  padding: "6px 0px",
                  color: textColor[85],
                },
              }}
              value={deliverablesProduct}
              onChange={(e) => {
                setDeliverablesProduct(e.target.value);
              }}
              startAdornment={<InputAdornment position="start">Product:</InputAdornment>}
            >
              {productList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <Select
              sx={{
                ml: 2,
                minWidth: 50,
                background: "#fff",
                "& .MuiInputAdornment-root": {
                  color: textColor[85],
                },
                "& .MuiInputBase-input": {
                  padding: "6px 0px",
                  color: textColor[85],
                },
              }}
              value={deliverablesVersion}
              onChange={(e) => {
                setDeliverablesVersion(e.target.value);
              }}
              startAdornment={<InputAdornment position="start">Release:</InputAdornment>}
            >
              {releaseList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </Box>
      {selectedValue.id < 20 && (
        <Box sx={{ display: "flex" }}>
          {selectedValue.id > 11 && (
            <Button
              onClick={() => {
                setShowTable(!showTable);
              }}
              variant="outlined"
              sx={{ mr: 2 }}
            >
              <IntlMessages id="common.impact.feeds" />
            </Button>
          )}
          {Number(selectedValue.id) === 11 && (
            <Button sx={{ mr: 2 }} variant="outlined">
              <IntlMessages id="products.reuse" />
            </Button>
          )}
          <ExportDropdown exportToWord={() => {}} exportToExcel={() => {}} exportToPdf={() => {}} />
        </Box>
      )}
      {selectedValue.id === "31" && (
        <Box className="right-header">
          {processData && (
            <Box className="right-header-left">
              <Box sx={{ color: "#2e6ccb" }}>PRD#001-TK#102</Box>
              <Box sx={{ mx: 2 }} className="divider"></Box>
              <Box>{processData?.value}</Box>
              <Select
                sx={{
                  m: 2,
                  minWidth: 50,
                  background: "#fff",
                  "& .MuiInputAdornment-root": {
                    color: textColor[85],
                  },
                  "& .MuiInputBase-input": {
                    padding: "6px 0px",
                    color: textColor[85],
                  },
                }}
                id="profile-version-select"
                value={version}
                label=""
                onChange={(e) => {
                  setVersion(e.target.value);
                }}
                startAdornment={<InputAdornment position="start">Version:</InputAdornment>}
              >
                {VersionList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.label}
                    <Chip
                      size="small"
                      label={item.status}
                      sx={{
                        textAlign: "center",
                        background: chipColor.versionBgColor,
                        color: textColor[85],
                        m: 2,
                        height: "20px",
                        borderRadius: borderRadius.main,
                        margin: "0px 8px",
                      }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          <ButtonMenu
            selectedValue={deliverableType}
            setSelectedValue={setDeliverableType}
            list={DeliverablesTypeList}
          />
        </Box>
      )}
      {selectedValue.id === "22" && (
        <Box className="right-header">
          <Button
            sx={{
              color: "rgba(0,0,0,0.85)",
            }}
            variant="text"
            onClick={() => {
              setOpenSetting(true);
            }}
            startIcon={<CustomizeIcon />}
          >
            <IntlMessages id="common.setting" />
          </Button>
        </Box>
      )}
      {<AddFeed open={openFeedModal} handleClose={() => setOpenFeedModal(false)} />}
      <SimplePopup
        handleClose={() => {
          setShowTable(false);
        }}
        open={showTable}
        titleId="common.impact.feeds"
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "1200px",
            width: "1200px",
          },
          "& .MuiTablePagination-actions .MuiIconButton-root": {
            top: 0,
            right: 0,
          },
        }}
      >
        <CustomTable
          toolBar={{
            showSearch: true,
            searchText: searchText,
            requestSearch: requestSearch,
            buttons: [
              {
                id: "common.customize",
                icon: TableHeaderButtonsIconType.SETTING,
                onChange: () => {},
              },
              {
                id: "workspace.button.feed",
                icon: TableHeaderButtonsIconType.ADD,
                type: "text",
                color: "#2e6ccb",
                isBlue: true,
                onChange: () => setOpenFeedModal(true),
              },
            ],
          }}
          columns={columns}
          rows={rows}
        />
      </SimplePopup>
      {openSetting && (
        <MetricsSettingModal
          handleClose={() => {
            setOpenSetting(false);
          }}
          open={openSetting}
          type={routeParam?.type || "sha"}
        />
      )}
    </ToolBarContainer>
  );
};

export default ToolBar;
