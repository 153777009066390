import IntlMessages from "@crema/utility/IntlMessages";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Button, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ButtonMenuListItemModel } from "./ButtonMenuModel";

interface ButtonMenuProps {
  selectedValue: ButtonMenuListItemModel;
  setSelectedValue: (item: ButtonMenuListItemModel) => void;
  list: ButtonMenuListItemModel[];
  fullWidth?: boolean;
  sxStyle?: any;
  moduleIcon?: any;
  variant?: "text" | "contained" | "outlined";
}

const ButtonMenu: React.FC<ButtonMenuProps> = (props: ButtonMenuProps) => {
  const { sxStyle = {}, variant = "contained", moduleIcon } = props;

  const navigate = useNavigate();

  const { selectedValue, setSelectedValue, list } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSelected = (item: ButtonMenuListItemModel) => {
    setSelectedValue(item);
    // item.url && navigate(item.url);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (selectedValue) {
      selectedValue.url && navigate(selectedValue.url);
    }
  }, [selectedValue]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {selectedValue && selectedValue.icon ? (
        <Button
          onClick={handleClick}
          variant={variant}
          fullWidth={props.fullWidth}
          startIcon={selectedValue.icon}
          endIcon={moduleIcon ? moduleIcon : Boolean(anchorEl) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          {...sxStyle}
        >
          <Tooltip
            title={selectedValue.label ? selectedValue.label : <IntlMessages id={selectedValue.labelId} />}
            placement="top"
          >
            <Box
              sx={{
                maxWidth: "calc(100% - 60px)",
                minWidth: "100px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                textAlign: "left",
              }}
            >
              {selectedValue.label ? selectedValue.label : <IntlMessages id={selectedValue.labelId} />}
            </Box>
          </Tooltip>
        </Button>
      ) : (
        <Button
          onClick={handleClick}
          variant={variant}
          fullWidth={props.fullWidth}
          endIcon={Boolean(anchorEl) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          {...sxStyle}
        >
          {selectedValue ? (
            selectedValue.label ? (
              selectedValue.label
            ) : (
              <IntlMessages id={selectedValue.labelId} />
            )
          ) : (
            "None"
          )}
        </Button>
      )}
      <Menu
        id="button-menus"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& .MuiSvgIcon-root": {
            marginRight: 2,
          },
          "& .MuiMenuItem-root": {
            color: "rgba(0,0,0,0.85)",
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {list.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => {
              handleSelected(item);
            }}
          >
            {item.icon ? item.icon : null}
            <Typography sx={{ ml: 2 }}>{item.label ? item.label : <IntlMessages id={item.labelId} />}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ButtonMenu;
