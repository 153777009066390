import SearchIcon from "@mui/icons-material/Search";
import { Box, Fade, Paper, Popover, Popper, styled, Theme } from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx";
import clsx from "clsx";
import React from "react";

import { SearchIconBox, SearchIconWrapper, SearchInputBase, SearchWrapper } from "./index.style";

const Container = styled(Box)(({ theme }) => ({
  minWidth: "640px",
  minHeight: "300px",
  width: "100%",
}));
interface AppSearchProps {
  iconPosition?: string;
  align?: string;
  placeholder?: string;
  overlap?: boolean;
  borderLight?: boolean;
  className?: string;
  onlyIcon?: boolean;
  disableFocus?: boolean;
  iconStyle?: SxProps<Theme>;
  sx?: SxProps<Theme>;

  [x: string]: any;
}

const AppSearch: React.FC<AppSearchProps> = ({
  placeholder,
  iconPosition = "left",
  align = "left",
  overlap = true,
  onlyIcon = false,
  disableFocus,
  iconStyle = {
    color: "#97989A", //"grey",
  },
  sx,
  ...rest
}) => {
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   console.log(event.target);
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   console.log("handleClose");
  //   setAnchorEl(null);
  // };

  // React.useEffect(() => {
  //   window.addEventListener("click", handleClickCloseDom);
  //   return () => {
  //     window.removeEventListener("click", handleClickCloseDom);
  //   };
  // }, []);

  // const handleClickCloseDom = (e) => {
  //   if (e.target.closest("search-pop-box") === false && e.target.closest("search-input") === false) {
  //     handleClose();
  //   }
  // };

  return (
    <SearchWrapper sx={sx} iconPosition={iconPosition}>
      <SearchIconBox
        align={align}
        className={clsx(
          "searchRoot",
          { "hs-search": overlap },
          { "hs-disableFocus": disableFocus },
          { searchIconBox: onlyIcon }
        )}
        sx={{ "& .MuiInputBase-root .MuiInputBase-input": { backgroundColor: "rgba(255, 255, 255, 0.2)" } }}
      >
        <SearchIconWrapper
          className={clsx({
            right: iconPosition === "left",
          })}
          sx={iconStyle}
        >
          <SearchIcon />
        </SearchIconWrapper>
        <SearchInputBase
          id="search-input"
          // onClick={handleClick}
          {...rest}
          placeholder={placeholder || "Search…"}
          inputProps={{ "aria-label": "search" }}
        />
      </SearchIconBox>
      {/* <Popper
        id="search-pop-box"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={"bottom"}
        transition
        sx={{
          zIndex: "1400",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: "10px !important",
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: "50%",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translate(50%, -50%) rotate(45deg)",
            zIndex: 0,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Container></Container>
            </Paper>
          </Fade>
        )}
      </Popper> */}
    </SearchWrapper>
  );
};

export default AppSearch;
