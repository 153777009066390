import { Box, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "components/CustomTable";
import FillProductIcon from "components/Icons/FillProductIcon";
import { getOtherTypeColumns } from "mock/FeedsData";
import React from "react";
import { useNavigate } from "react-router-dom";
import { statusBgColor } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const CapaContainer = styled(Box)(({ theme }) => ({}));

const initRows = [
  {
    id: "FD#006",
    content: "Need Improve the performance",
    status: "Open",
    productID: "PRD#001",
    module: "",
    processId: "",
    version: "",
    tasks: "",
    createdBy: "Gergory",
    source: "CAPA System",
    creationTime: "09:12:21 3/13/2022",
  },
];

const Capa = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState<any[]>(initRows);
  const columns: any[] = getOtherTypeColumns(navigate);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = initRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  return (
    <CapaContainer>
      <CustomTable
        toolBar={{
          showSearch: true,
          searchText: searchText,
          requestSearch: requestSearch,
          buttons: [
            {
              id: "common.customize",
              icon: TableHeaderButtonsIconType.SETTING,
              onChange: () => {},
            },
          ],
        }}
        columns={columns}
        rows={rows}
      />
    </CapaContainer>
  );
};

export default Capa;
