import Icon from "@mui/material/Icon";
import * as React from "react";

const ExportSvg = () => (
  <svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="rgba(0,0,0,0.85)" fillRule="nonzero">
      <path
        d="M16.512 12.046a.372.372 0 0 0-.372.372v3.721c0 .617-.5 1.117-1.116 1.117H3.86c-.617 0-1.117-.5-1.117-1.117V7.21c0-.616.5-1.116 1.117-1.116h.744a.372.372 0 0 0 0-.744H3.86A1.863 1.863 0 0 0 2 7.209v8.93A1.863 1.863 0 0 0 3.86 18h11.164a1.863 1.863 0 0 0 1.86-1.86v-3.722a.372.372 0 0 0-.372-.372z"
        stroke="rgba(0,0,0,0.85)"
        strokeWidth=".5"
      />
      <path d="m18.245 7.301-5.953-5.21a.372.372 0 0 0-.617.28v2.61c-3.198.07-6.698 1.143-6.698 7.81a.372.372 0 0 0 .666.228c1.301-1.68 2.441-2.796 6.032-2.833v2.605a.372.372 0 0 0 .617.28l5.953-5.21a.372.372 0 0 0 0-.558V7.3z" />
    </g>
  </svg>
);

const ExportIcon: React.FunctionComponent = () => {
  return <Icon component={ExportSvg} />;
};

export default ExportIcon;
