import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Grid, Link, Tab, Tabs, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import { ListModel } from "components/CustomTable/CustomTableModel";
import TableToolBar from "components/CustomTable/TableToolBar";
import GeneralStats from "components/GeneralStats";
import { AcademicStats } from "components/GeneralStats/GeneralStatsModel";
import FillProduct40Icon from "components/Icons/FillProduct40Icon";
import FillProductIcon from "components/Icons/FillProductIcon";
import StarFilledIcon from "components/Icons/StarFilledIcon";
import MyTreeTable from "components/MyTreeTable";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { bgColor, borderColor, boxShadow, chipColor, tabStyle, textColor } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

import AddProduct from "../AddProduct";
import AddTemplate from "../AddTemplate";
import NewProduct from "../NewProduct";

const templateLists = [
  {
    id: 1,
    name: "Glass-bassed",
    variant: "Main",
    release: "R1.0",
    code: "GB",
    description:
      "Glass-bassed is based on XXX product, and XXX product is very representative of similar products. Other similar products can create related products through this template",
  },
  {
    id: 2,
    name: "Polymer-based",
    variant: "Main",
    code: "PB",
    release: "R1.2",
    description:
      "Polymer-based is based on XXX product, and XXX product is very representative of similar products. Other similar products can create related products through this template",
  },
  {
    id: 3,
    name: "Pen Injectors",
    variant: "Main",
    code: "PI",
    release: "R1.0",
    description:
      "Pen Injectors is based on XXX product, and XXX product is very representative of similar products. Other similar products can create related products through this template",
  },
  {
    id: 4,
    name: "Jet Injectors",
    variant: "Main",
    code: "JI",
    release: "R1.2",
    description:
      "Jet Injectors is based on XXX product, and XXX product is very representative of similar products. Other similar products can create related products through this template",
  },
  {
    id: 5,
    name: "Inhalation Devices",
    variant: "Main",
    code: "ID",
    release: "R1.2",
    description:
      "Inhalation Devices is based on XXX product, and XXX product is very representative of similar products. Other similar products can create related products through this template",
  },
  {
    id: 6,
    name: "Transdermal Devices",
    variant: "Main",
    code: "TD",
    release: "R1.1",
    description:
      "Transdermal Devices is based on XXX product, and XXX product is very representative of similar products. Other similar products can create related products through this template",
  },
  {
    id: 7,
    name: "Wearable Injector",
    variant: "Main",
    code: "WI",
    release: "R1.1",
    description:
      "Wearable Injector is based on XXX product, and XXX product is very representative of similar products. Other similar products can create related products through this template",
  },
  {
    id: 8,
    name: "Wearble Pump",
    variant: "Main",
    code: "WP",
    release: "R1.1",
    description:
      "Wearble Pump is based on XXX product, and XXX product is very representative of similar products. Other similar products can create related products through this template",
  },
  {
    id: 9,
    name: "Insulin Pump",
    variant: "Main",
    code: "IP",
    release: "R1.1",
    description:
      "Insulin Pump is based on XXX product, and XXX product is very representative of similar products. Other similar products can create related products through this template",
  },
  {
    id: 10,
    name: "Volumetric",
    variant: "Main",
    code: "VC",
    release: "R1.2",
    description:
      "Volumetric is based on XXX product, and XXX product is very representative of similar products. Other similar products can create related products through this template",
  },
  {
    id: 11,
    name: "Syringe",
    code: "SE",
    variant: "Main",
    release: "R1.2",
    description:
      "Syringe is based on XXX product, and XXX product is very representative of similar products. Other similar products can create related products through this template",
  },
  {
    id: 12,
    name: "PCA",
    code: "PCA",
    variant: "Main",
    release: "R1.2",
    description:
      "Insulin Pump is based on XXX product, and XXX product is very representative of similar products. Other similar products can create related products through this template",
  },
  {
    id: 13,
    name: "Thermometers",
    variant: "Main",
    code: "TS",
    release: "R1.0",
    description:
      "Thermometers is based on XXX product, and XXX product is very representative of similar products. Other similar products can create related products through this template",
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const starredProducts: AcademicStats[] = [
  {
    id: 1,
    title: "PRD#001 Drug Delivery Device",
    subTitle: "Pre-Production",
    icon: <FillProduct40Icon />,
    startIcon: <StarFilledIcon />,
    badgeBgColor: chipColor.stageBgColor,
    badgeColor: chipColor.stage,
  },
  {
    id: 2,
    title: "PRD#002 Pen Injector",
    subTitle: "Production",
    badgeBgColor: chipColor.stageBgColor,
    badgeColor: chipColor.stage,
    icon: <FillProduct40Icon />,
    startIcon: <StarFilledIcon />,
  },
  {
    id: 3,
    title: "PRD#003 Thermometers",
    subTitle: "Pre-Production",
    badgeBgColor: chipColor.stageBgColor,
    badgeColor: chipColor.stage,
    icon: <FillProduct40Icon />,
    startIcon: <StarFilledIcon />,
  },
  {
    id: 4,
    title: "PRD#004 Infusion Pump",
    subTitle: "Post-Production",
    badgeBgColor: chipColor.stageBgColor,
    badgeColor: chipColor.stage,
    icon: <FillProduct40Icon />,
    startIcon: <StarFilledIcon />,
  },
];

const ProductsContainer = styled(Box)(({ theme }) => ({
  ".content-layout": {
    padding: "0",
    height: "calc(100vh - 350px)",
  },
  ".card-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(4),
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  ".table-container": {
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(3),
  },
}));

function a11yProps(index: number | string) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Products() {
  const navigate = useNavigate();

  const [index, setIndex] = useState("products.porfolios.product");
  const [template, setTemplate] = React.useState(null);

  const [rows, setRows] = React.useState<any[]>([
    {
      id: "101",
      name: "Drug Delivery",
      release: "",
      description: "",
      stage: "",
      type: "",
      integrated: "",
      integrates: "",
      derived: "",
      derives: "",
      level: 1,
      isFolder: true,
      isOpen: true,
      children: [
        {
          id: "1013",
          name: "Prefilled Syringes",
          release: "",
          description: "",
          stage: "",
          type: "",
          integrated: "",
          integrates: "",
          derived: "",
          derives: "",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "10130",
              name: "Glass-bassed",
              release: "Glass-bassed R1.0",
              description: "A brief release description",
              stage: "Production",
              type: "",
              integrated: "",
              integrates: "",
              derived: "",
              derives: "",
              level: 3,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <FillProductIcon />
                </Box>
              ),
            },
            {
              id: "10131",
              name: "Polymer-based",
              release: "Polymer-based R1.2",
              description: "A brief release description",
              stage: "Pre-Production",
              type: "",
              integrated: "",
              integrates: "",
              derived: "",
              derives: "",
              level: 3,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <FillProductIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "1012",
          name: "Autoinjectors",
          release: "",
          description: "",
          stage: "",
          type: "",
          integrated: "",
          integrates: "",
          derived: "",
          derives: "",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "10120",
              name: "Pen Injectors",
              release: "Pen Injectors R1.0",
              description: "A brief release description",
              stage: "Production",
              type: "",
              integrated: "",
              integrates: "",
              derived: "",
              derives: "",
              level: 3,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <FillProductIcon />
                </Box>
              ),
            },
            {
              id: "10121",
              name: "Jet Injectors",
              release: "Jet Injectors R1.2",
              description: "A brief release description",
              stage: "Pre-Production",
              type: "",
              integrated: "",
              integrates: "",
              derived: "",
              derives: "",
              level: 3,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <FillProductIcon />
                </Box>
              ),
            },
            {
              id: "10122",
              name: "Inhalation Devices",
              release: "Inhalation Devices R1.2",
              description: "A brief release description",
              stage: "Pre-Production",
              type: "",
              integrated: "",
              integrates: "",
              derived: "",
              derives: "",
              level: 3,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <FillProductIcon />
                </Box>
              ),
            },
            {
              id: "10123",
              name: "Transdermal Devices",
              release: "Transdermal Devices R1.2",
              description: "A brief release description",
              stage: "Pre-Production",
              type: "",
              integrated: "",
              integrates: "",
              derived: "",
              derives: "",
              level: 3,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <FillProductIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "1011",
          name: "On Body Delivery",
          release: "",
          description: "",
          stage: "",
          type: "",
          integrated: "",
          integrates: "",
          derived: "",
          derives: "",
          level: 2,
          isFolder: true,
          children: [
            // {
            //   id: "10110",
            //   name: "Template 3",
            //   release: "Template 3 R1.0",
            //   description: "A brief release description",
            //   stage: "Production",
            //   type: "",
            //   integrated: "",
            //   integrates: "",
            //   derived: "",
            //   derives: "",
            //   level: 3,
            //   startIcon: (
            //     <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
            //       <FillProductIcon />
            //     </Box>
            //   ),
            // },
            {
              id: "10111",
              name: "Wearable Injector",
              release: "Wearable Injector R1.2",
              description: "A brief release description",
              stage: "Pre-Production",
              type: "",
              integrated: "",
              integrates: "",
              derived: "",
              derives: "",
              level: 3,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <FillProductIcon />
                </Box>
              ),
            },
            {
              id: "10112",
              name: "Wearble Pump",
              release: "Wearble Pump R1.2",
              description: "A brief release description",
              stage: "Pre-Production",
              type: "",
              integrated: "",
              integrates: "",
              derived: "",
              derives: "",
              level: 3,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <FillProductIcon />
                </Box>
              ),
            },
            {
              id: "10113",
              name: "Insulin Pump",
              release: "Insulin Pump R1.2",
              description: "A brief release description",
              stage: "Pre-Production",
              type: "",
              integrated: "",
              integrates: "",
              derived: "",
              derives: "",
              level: 3,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <FillProductIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "1010",
          name: "General Infusion",
          release: "",
          description: "",
          stage: "",
          type: "",
          integrated: "",
          integrates: "",
          derived: "",
          derives: "",
          level: 2,
          isFolder: true,
          children: [
            // {
            //   id: "10100",
            //   name: "Template 2",
            //   release: "Template 2 R1.0",
            //   description: "A brief release description",
            //   stage: "Production",
            //   type: "",
            //   integrated: "",
            //   integrates: "",
            //   derived: "",
            //   derives: "",
            //   level: 3,
            //   startIcon: (
            //     <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
            //       <FillProductIcon />
            //     </Box>
            //   ),
            // },
            {
              id: "10101",
              name: "Volumetric",
              release: "Volumetric R1.2",
              description: "A brief release description",
              stage: "Pre-Production",
              type: "",
              integrated: "",
              integrates: "",
              derived: "",
              derives: "",
              level: 3,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <FillProductIcon />
                </Box>
              ),
            },
            {
              id: "10102",
              name: "Syringe",
              release: "Syringe R1.2",
              description: "A brief release description",
              stage: "Pre-Production",
              type: "",
              integrated: "",
              integrates: "",
              derived: "",
              derives: "",
              level: 3,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <FillProductIcon />
                </Box>
              ),
            },
            {
              id: "10103",
              name: "PCA",
              release: "PCA R1.2",
              description: "A brief release description",
              stage: "Pre-Production",
              type: "",
              integrated: "",
              integrates: "",
              derived: "",
              derives: "",
              level: 3,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                  <FillProductIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "100",
      name: "Dlagnostic",
      release: "",
      description: "",
      stage: "",
      type: "",
      integrated: "",
      integrates: "",
      derived: "",
      derives: "",
      level: 1,
      isFolder: true,
      isOpen: true,
      children: [
        {
          id: "1000",
          name: "Vital Signs",
          release: "",
          description: "",
          stage: "",
          type: "",
          integrated: "",
          integrates: "",
          derived: "",
          derives: "",
          level: 2,
          isOpen: true,
          isFolder: true,
          children: [
            // {
            //   id: "10000",
            //   name: "Template 1",
            //   release: "Template 1 R1.0",
            //   description: "A brief release description",
            //   stage: "Production",
            //   type: "",
            //   integrated: "",
            //   integrates: "",
            //   derived: "",
            //   derives: "",
            //   level: 3,
            //   startIcon: (
            //     <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
            //       <FillProductIcon />
            //     </Box>
            //   ),
            // },
            {
              id: "10001",
              name: "Thermometers",
              others: [
                {
                  id: "100001",
                  name: "Thermometers",
                  release: "Main(R4.0)",
                  description: "A brief release description",
                  stage: "Production",
                  type: "",
                  integrated: "",
                  integrates: "",
                  derived: "",
                  derives: "",
                  level: 3,
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px" }}>
                      <FillProductIcon />
                    </Box>
                  ),
                },
                {
                  id: "100002",
                  release: "EU(EU R2.2)",
                  description: "A brief release description",
                  stage: "Production",
                  type: "",
                  integrated: "",
                  integrates: "",
                  derived: "",
                  derives: "",
                },
                {
                  id: "100003",
                  release: "China(CN R3.3)",
                  description: "A brief release description",
                  stage: "Production",
                  type: "",
                  integrated: "",
                  integrates: "",
                  derived: "",
                  derives: "",
                },
                {
                  id: "100004",
                  release: "US(US R4.2)",
                  description: "A brief release description",
                  stage: "Production",
                  type: "",
                  integrated: "",
                  integrates: "",
                  derived: "",
                  derives: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "102",
      name: "Robotic Surgical",
      release: "",
      description: "",
      stage: "",
      type: "",
      integrated: "",
      integrates: "",
      derived: "",
      derives: "",
      level: 1,
      isOpen: true,
      isFolder: true,
    },
    {
      id: "103",
      name: "Cardiovascular",
      release: "",
      description: "",
      stage: "",
      type: "",
      integrated: "",
      integrates: "",
      derived: "",
      derives: "",
      level: 1,
      isOpen: true,
      isFolder: true,
    },
    {
      id: "104",
      name: "SAMD",
      release: "",
      description: "",
      stage: "",
      type: "",
      integrated: "",
      integrates: "",
      derived: "",
      derives: "",
      level: 1,
      isOpen: true,
      isFolder: true,
    },
  ]);
  const [columns, setColumns] = React.useState<Column[]>([
    {
      id: "name",
      label: "common.platform",
      minWidth: 300,
      align: "left",
      format: (value, record) => {
        if (record.isFolder) {
          return value;
        } else {
          return (
            <Box className="flex items-center">
              <Link
                sx={{ ml: 1.5, cursor: "pointer" }}
                underline="none"
                onClick={() => navigate("/products/123/profile")}
              >
                {value}
              </Link>
            </Box>
          );
        }
      },
    },
    {
      id: "release",
      label: "common.variant(head)",
      minWidth: 120,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex items-center">
            <Link
              sx={{ ml: 1.5, cursor: "pointer" }}
              underline="none"
              onClick={() => navigate("/products/123/deliverables/Design Controls/index")}
            >
              {value}
            </Link>
          </Box>
        );
      },
    },
    {
      id: "description",
      label: "common.description",
      minWidth: 150,
      align: "left",
    },
    {
      id: "stage",
      label: "common.stage",
      minWidth: 100,
      align: "left",
    },
    {
      id: "type",
      label: "common.type",
      minWidth: 100,
      align: "left",
    },
    {
      id: "integrated",
      label: "common.is.integrated.by",
      minWidth: 100,
      align: "left",
    },
    {
      id: "integrates",
      label: "common.integrates",
      minWidth: 100,
      align: "left",
    },
    {
      id: "derived",
      label: "common.is.derived.from",
      minWidth: 100,
      align: "left",
    },
    {
      id: "derives",
      label: "common.derives",
      minWidth: 100,
      align: "left",
    },
  ]);
  const [openProductModal, setOpenProductModal] = React.useState<boolean>(false);
  const [openNewProductModal, setOpenNewProductModal] = React.useState<boolean>(false);
  const [openTemplateModal, setOpenTemplateModal] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState("");
  const [filter1, setFilter1] = React.useState<any>("");
  const [filterList1] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter1",
    },
  ]);
  const [filter2, setFilter2] = React.useState<any>("");
  const [filterList2] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter2",
    },
  ]);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setIndex(newValue);
  };

  return (
    <ProductsContainer>
      <AppScrollbar
        sx={{
          padding: (theme) => theme.spacing(5),
          background: bgColor.main,
          height: "calc(100vh - 56px)",
        }}
      >
        <Box className="page-content">
          <Box
            sx={{
              padding: (theme) => theme.spacing(5),
              pt: 0,
              boxShadow: boxShadow.cardBoxShadow,
              background: (theme) => theme.palette.background.paper,
            }}
          >
            <Box className="flex items-center justify-between">
              <Box
                className="flex items-center"
                sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
              >
                <h3>
                  <IntlMessages id="workspace.recently.starred" />
                </h3>
              </Box>
              <Box className="flex items-center">
                <IntlMessages id="workspace.more" />
                <Box sx={{ transform: "scale(0.6)", display: "flex", alignItems: "center" }}>
                  <ArrowForwardIosIcon sx={{ color: textColor[65] }} fontSize="small" />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                "div:last-child": {
                  marginRight: 0,
                },
              }}
              className="flex items-center justify-content-flex-start"
            >
              {starredProducts.map((product: AcademicStats) => (
                <GeneralStats key={product.id} stats={product} />
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              padding: (theme) => theme.spacing(5),
              mt: (theme) => theme.spacing(5),
              boxShadow: boxShadow.cardBoxShadow,
              background: (theme) => theme.palette.background.paper,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Tabs value={index} onChange={handleChangeTab} sx={tabStyle}>
                  <Tab
                    value={"products.porfolios.product"}
                    label={<IntlMessages id="menu.product" />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    value={"products.porfolios.templates"}
                    label={<IntlMessages id="common.template.products" />}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <Box sx={{ pt: 2 }}>
                <TabPanel value={index} index={"products.porfolios.product"}>
                  <Box>
                    <Box sx={{ mb: 2 }} className="table-tool-bar">
                      {
                        <TableToolBar
                          showSearch={true}
                          searchText={searchText}
                          requestSearch={requestSearch}
                          saveFilter={() => {
                            console.log("save filter");
                          }}
                          filters={[
                            {
                              value: filter1,
                              list: filterList1,
                              setValue: setFilter1,
                            },
                            {
                              value: filter2,
                              list: filterList2,
                              setValue: setFilter2,
                            },
                          ]}
                          buttons={[
                            {
                              id: "common.customize",
                              icon: TableHeaderButtonsIconType.SETTING,
                              onChange: () => {},
                            },
                            {
                              id: "admin.nav.collapse.product",
                              icon: TableHeaderButtonsIconType.ADD,
                              type: "text",
                              color: "#2e6ccb",
                              isBlue: true,
                              onChange: () => setOpenNewProductModal(true),
                            },
                          ]}
                        />
                      }
                    </Box>
                    <MyTreeTable enableIsOpen={true} maxHeight={450} columns={columns} rows={rows} />
                  </Box>
                </TabPanel>
                <TabPanel value={index} index={"products.porfolios.templates"}>
                  <Box>
                    <Box sx={{ mb: 2 }} className="table-tool-bar">
                      {
                        <TableToolBar
                          showSearch={true}
                          searchText={searchText}
                          requestSearch={requestSearch}
                          saveFilter={() => {
                            console.log("save filter");
                          }}
                          buttons={[
                            {
                              id: "setting.export.templates.subTitle.template.title",
                              icon: TableHeaderButtonsIconType.ADD,
                              type: "text",
                              color: "#2e6ccb",
                              isBlue: true,
                              onChange: () => setOpenTemplateModal(true),
                            },
                          ]}
                        />
                      }
                    </Box>
                    <AppScrollbar
                      sx={{
                        height: "500px",
                        position: "relative",
                      }}
                    >
                      {templateLists?.length > 0 ? (
                        <Grid container spacing={5}>
                          {templateLists.map((item) => (
                            <Grid className="cursor-pointer" key={item.id} item xs={2}>
                              <Box
                                sx={{
                                  position: "relative",
                                  width: "100%",
                                  height: "110px",
                                  color: textColor[85],
                                  border: `1px solid  ${borderColor.dividerColor}`,
                                  padding: "16px",
                                  "&:hover": {
                                    boxShadow: "0 3px 20px 0 #e5e5e5;",
                                  },
                                  "&:hover .action": {
                                    display: "block",
                                  },
                                }}
                                className="flex items-center"
                              >
                                <Box
                                  sx={{
                                    mr: 3,
                                  }}
                                >
                                  <FillProduct40Icon />
                                </Box>
                                <Box sx={{ flexDirection: "column" }} className="flex">
                                  <Box
                                    onClick={() => navigate("/products/123/deliverables/Design Controls/index")}
                                    sx={{ color: "#2e6ccb", cursor: "pointer" }}
                                  >
                                    {item.name + " " + item.variant + "(" + item.release + ")"}
                                  </Box>
                                  <Tooltip title={item.description} placement="bottom">
                                    <Box
                                      sx={{
                                        overflow: "hidden",
                                        display: "-webkit-box",
                                        "-webkit-box-orient": "vertical",
                                        "-webkit-line-clamp": "2",
                                        color: textColor[65],
                                      }}
                                    >
                                      {item.description}
                                    </Box>
                                  </Tooltip>
                                </Box>
                                <Box
                                  sx={{
                                    position: "absolute",
                                    right: "36px",
                                    top: "8px",
                                    cursor: "pointer",
                                    display: "none",
                                  }}
                                  className="action"
                                  onClick={() => {
                                    setTemplate(item);
                                    setOpenProductModal(true);
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:add"}
                                  </AppSvgIcon>
                                </Box>
                                <Box
                                  sx={{
                                    position: "absolute",
                                    right: "16px",
                                    top: "8px",
                                    cursor: "pointer",
                                    display: "none",
                                  }}
                                  className="action"
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:close"}
                                  </AppSvgIcon>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translateX(-50%) translateY(-50%)",
                          }}
                        >
                          No Template
                        </Box>
                      )}
                    </AppScrollbar>
                  </Box>
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </Box>
        {<AddProduct template={template} open={openProductModal} handleClose={() => setOpenProductModal(false)} />}
        {<NewProduct open={openNewProductModal} handleClose={() => setOpenNewProductModal(false)} />}
        {<AddTemplate open={openTemplateModal} handleClose={() => setOpenTemplateModal(false)} />}
      </AppScrollbar>
    </ProductsContainer>
  );
}
