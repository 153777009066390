import AppScrollbar from "@crema/core/AppScrollbar";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, styled } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import * as go from "gojs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Column } from "types/models/Common";
import { LinkData, NodeData } from "types/models/GoJs";

import Diagram from "./Diagram";

const Container = styled(Box)(({ theme }) => ({}));

const relationshipMockData = {
  risk: {
    diagramData: {
      nodeData: [
        { key: 0, text: "Cause", group: "group_1", loc: "-47.85693359375 17.356085205078124" },
        { key: 1, text: "Hazardous Situation", group: "group_1", loc: "145.6025390625 16.7560791015625" },
        { key: 2, text: "Safety Harm", group: "group_1", loc: "358.7509765625 16.756079101562506" },
        { key: 3, text: "Hazard", group: "group_1", loc: "145.95849609375 -75.2439208984375" },
      ],
      linkData: [
        { key: 2, from: 0, to: 1, text: "leads to" },
        { key: 3, from: 1, to: 2, text: "leads to" },
        { key: 1, from: 1, to: 3, dash: [6, 3], text: "assesses" },
      ],
    },
    diagramConfigRows: [
      {
        id: "1",
        upstream: "Cause",
        downstream: "Hazardous Situation",
        relationship: "leads to",
        for_risk: true,
        for_coverage: "",
      },
      {
        id: "2",
        upstream: "Hazardous Situation",
        downstream: "Safety Harm",
        relationship: "leads to",
        for_risk: true,
        for_coverage: "",
      },
      {
        id: "3",
        upstream: "Hazardous Situation",
        downstream: "Hazard",
        relationship: "assesses",
        for_risk: "",
        for_coverage: "",
      },
    ],
  },
  //   diagramData: {
  //     nodeData: [
  //       { key: 0, text: "Cause", group: "group_1", loc: "-47.85693359375 17.356085205078124" },
  //       { key: 1, text: "Hazardous Situation", group: "group_1", loc: "145.6025390625 16.7560791015625" },
  //       { key: 2, text: "Safety Harm", group: "group_1", loc: "358.7509765625 16.756079101562506" },
  //       { key: 3, text: "Hazard", group: "group_1", loc: "145.95849609375 -75.2439208984375" },
  //     ],
  //     linkData: [
  //       { key: 2, from: 0, to: 1, text: "leads to" },
  //       { key: 3, from: 1, to: 2, text: "leads to" },
  //       { key: 1, from: 1, to: 3, dash: [6, 3], text: "assesses" },
  //     ],
  //   },
  //   diagramConfigRows: [
  //     {
  //       id: "1",
  //       upstream: "Cause",
  //       downstream: "Hazardous Situation",
  //       relationship: "leads to",
  //       for_risk: true,
  //       for_coverage: "",
  //     },
  //     {
  //       id: "2",
  //       upstream: "Hazardous Situation",
  //       downstream: "Safety Harm",
  //       relationship: "leads to",
  //       for_risk: true,
  //       for_coverage: "",
  //     },
  //     {
  //       id: "3",
  //       upstream: "Hazardous Situation",
  //       downstream: "Hazard",
  //       relationship: "assesses",
  //       for_risk: "",
  //       for_coverage: "",
  //     },
  //   ],
  // },
  cyber: {
    diagramData: {
      nodeData: [
        { key: 0, text: "Cause", group: "group_1", loc: "-47.85693359375 17.356085205078124" },
        { key: 1, text: "Hazardous Situation", group: "group_1", loc: "145.6025390625 16.7560791015625" },
        { key: 2, text: "Safety Harm", group: "group_1", loc: "358.7509765625 16.756079101562506" },
        { key: 3, text: "Hazard", group: "group_1", loc: "145.95849609375 -75.2439208984375" },
      ],
      linkData: [
        { key: 2, from: 0, to: 1, text: "leads to" },
        { key: 3, from: 1, to: 2, text: "leads to" },
        { key: 1, from: 1, to: 3, dash: [6, 3], text: "assesses" },
      ],
    },
    diagramConfigRows: [
      {
        id: "1",
        upstream: "Cause",
        downstream: "Hazardous Situation",
        relationship: "leads to",
        for_risk: true,
        for_coverage: "",
      },
      {
        id: "2",
        upstream: "Hazardous Situation",
        downstream: "Safety Harm",
        relationship: "leads to",
        for_risk: true,
        for_coverage: "",
      },
      {
        id: "3",
        upstream: "Hazardous Situation",
        downstream: "Hazard",
        relationship: "assesses",
        for_risk: "",
        for_coverage: "",
      },
    ],
  },
  ac: {
    diagramData: {
      nodeData: [
        { key: 0, text: "Cause", group: "group_1", loc: "-47.85693359375 17.356085205078124" },
        { key: 1, text: "Hazardous Situation", group: "group_1", loc: "145.6025390625 16.7560791015625" },
        { key: 2, text: "Safety Harm", group: "group_1", loc: "358.7509765625 16.756079101562506" },
        { key: 3, text: "Hazard", group: "group_1", loc: "145.95849609375 -75.2439208984375" },
      ],
      linkData: [
        { key: 2, from: 0, to: 1, text: "leads to" },
        { key: 3, from: 1, to: 2, text: "leads to" },
        { key: 1, from: 1, to: 3, dash: [6, 3], text: "assesses" },
      ],
    },
    diagramConfigRows: [
      {
        id: "1",
        upstream: "Cause",
        downstream: "Hazardous Situation",
        relationship: "leads to",
        for_risk: true,
        for_coverage: "",
      },
      {
        id: "2",
        upstream: "Hazardous Situation",
        downstream: "Safety Harm",
        relationship: "leads to",
        for_risk: true,
        for_coverage: "",
      },
      {
        id: "3",
        upstream: "Hazardous Situation",
        downstream: "Hazard",
        relationship: "assesses",
        for_risk: "",
        for_coverage: "",
      },
    ],
  },
  production: {
    diagramData: {
      nodeData: [
        { key: 0, text: "Cause", group: "group_1", loc: "-47.85693359375 17.356085205078124" },
        { key: 1, text: "Hazardous Situation", group: "group_1", loc: "145.6025390625 16.7560791015625" },
        { key: 2, text: "Safety Harm", group: "group_1", loc: "358.7509765625 16.756079101562506" },
        { key: 3, text: "Hazard", group: "group_1", loc: "145.95849609375 -75.2439208984375" },
      ],
      linkData: [
        { key: 2, from: 0, to: 1, text: "leads to" },
        { key: 3, from: 1, to: 2, text: "leads to" },
        { key: 1, from: 1, to: 3, dash: [6, 3], text: "assesses" },
      ],
    },
    diagramConfigRows: [
      {
        id: "1",
        upstream: "Cause",
        downstream: "Hazardous Situation",
        relationship: "leads to",
        for_risk: true,
        for_coverage: "",
      },
      {
        id: "2",
        upstream: "Hazardous Situation",
        downstream: "Safety Harm",
        relationship: "leads to",
        for_risk: true,
        for_coverage: "",
      },
      {
        id: "3",
        upstream: "Hazardous Situation",
        downstream: "Hazard",
        relationship: "assesses",
        for_risk: "",
        for_coverage: "",
      },
    ],
  },
  if: {
    diagramData: {
      nodeData: [
        { key: 0, text: "Cause", group: "group_1", loc: "-47.85693359375 17.356085205078124" },
        { key: 1, text: "Hazardous Situation", group: "group_1", loc: "145.6025390625 16.7560791015625" },
        { key: 2, text: "Safety Harm", group: "group_1", loc: "358.7509765625 16.756079101562506" },
        { key: 3, text: "Hazard", group: "group_1", loc: "145.95849609375 -75.2439208984375" },
      ],
      linkData: [
        { key: 2, from: 0, to: 1, text: "leads to" },
        { key: 3, from: 1, to: 2, text: "leads to" },
        { key: 1, from: 1, to: 3, dash: [6, 3], text: "assesses" },
      ],
    },
    diagramConfigRows: [
      {
        id: "1",
        upstream: "Cause",
        downstream: "Hazardous Situation",
        relationship: "leads to",
        for_risk: true,
        for_coverage: "",
      },
      {
        id: "2",
        upstream: "Hazardous Situation",
        downstream: "Safety Harm",
        relationship: "leads to",
        for_risk: true,
        for_coverage: "",
      },
      {
        id: "3",
        upstream: "Hazardous Situation",
        downstream: "Hazard",
        relationship: "assesses",
        for_risk: "",
        for_coverage: "",
      },
    ],
  },
  dc: {
    diagramData: {
      nodeData: [
        { key: "User_Need", text: "User Need", group: "group_1", loc: "-139.72705078125 32.7560791015625" },
        { key: "Requirement", text: "Requirement", group: "group_1", loc: "52.956787109375 33.7560791015625" },
        { key: "Verification", text: "Verification", group: "group_1", loc: "302.846435546875 -48.2439208984375" },
        { key: "Validation", text: "Validation", group: "group_1", loc: "302.48291015625 32.756079101562506" },
        { key: "User_Story", text: "User Story", group: "group_1", loc: "300.651611328125 110.7560791015625" },
        { key: "Defect", text: "Defect", group: "group_1", loc: "506.09912109375 -7.243920898437494" },
      ],
      linkData: [
        { key: "User_Need_Requirement", from: "User_Need", to: "Requirement", dash: [6, 3], text: "derives" },
        {
          key: "Requirement_Verification",
          from: "Requirement",
          to: "Verification",
          text: "is verified by",
          routing: go.Link.Normal,
        },
        {
          key: "Requirement_Validation",
          from: "Requirement",
          to: "Validation",
          dash: [6, 3],
          text: "is validated by",
          routing: go.Link.Normal,
        },
        {
          key: "Requirement_User_Story",
          from: "Requirement",
          to: "User_Story",
          dash: [6, 3],
          text: "derives",
          routing: go.Link.Normal,
        },
        {
          key: "Verification_Defect",
          from: "Verification",
          to: "Defect",
          dash: [6, 3],
          text: "is caused by",
          routing: go.Link.Normal,
        },
        {
          key: "Validation_Defect",
          from: "Validation",
          to: "Defect",
          dash: [6, 3],
          text: "is caused by",
          routing: go.Link.Normal,
        },
      ],
    },
    diagramConfigRows: [
      {
        id: "1",
        upstream: "Verification",
        downstream: "Defect",
        relationship: "is caused by",
        for_risk: false,
        for_coverage: false,
      },
      {
        id: "2",
        upstream: "Validation",
        downstream: "Defect",
        relationship: "is caused by",
        for_risk: false,
        for_coverage: false,
      },
      {
        id: "3",
        upstream: "Requirement",
        downstream: "Verification",
        relationship: "is verified by",
        for_risk: false,
        for_coverage: true,
      },
      {
        id: "4",
        upstream: "Requirement",
        downstream: "Validation",
        relationship: "is validated by",
        for_risk: false,
        for_coverage: false,
      },
      {
        id: "5",
        upstream: "User Need",
        downstream: "Requirement",
        relationship: "derives",
        for_risk: false,
        for_coverage: false,
      },
      {
        id: "6",
        upstream: "Requirement",
        downstream: "User Story",
        relationship: "derives",
        for_risk: false,
        for_coverage: false,
      },
    ],
  },
};

const WorkitemRelationships = () => {
  const params = useParams();
  const { type } = params;

  const columns: Column[] = [
    {
      id: "upstream",
      label: "common.columns.upstream",
      minWidth: 200,
      align: "left",
    },
    {
      id: "legend",
      label: "common.columns.legend",
      minWidth: 150,
      align: "left",
      format: (_, record) => {
        if (record.for_risk || record.for_coverage) {
          return (
            <Box className="flex">
              <AppSvgIcon>material-outline:east</AppSvgIcon>
            </Box>
          );
        }
        return "dotted line";
      },
    },
    {
      id: "downstream",
      label: "common.columns.downstream",
      minWidth: 200,
      align: "left",
    },
    {
      id: "relationship",
      label: "common.columns.relationship",
      minWidth: 150,
      align: "left",
    },
    {
      id: "for_risk",
      label: "common.columns.for.risk",
      minWidth: 150,
      align: "left",
      format: (value, record) => {
        return <Box>{value ? "Yes" : ""}</Box>;
      },
    },
    {
      id: "for_coverage",
      label: "common.columns.for.coverage",
      minWidth: 150,
      align: "left",
      format: (value, record) => {
        return <Box>{value ? "Yes" : ""}</Box>;
      },
    },
    {
      id: "actions",
      label: "setting.product.column.actions",
      align: "left",
      format: (value, record) => {
        return (
          <Box>
            <EditIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />{" "}
            <DeleteIcon sx={{ cursor: "pointer", fontSize: "16px" }} />
          </Box>
        );
      },
    },
  ];

  const [rows, setRows] = React.useState([]);

  const [nodeData, setNodeData] = useState<NodeData[]>([]);
  const [linkData, setLinkData] = useState<LinkData[]>([]);

  useEffect(() => {
    if (type) {
      const mockData = relationshipMockData[type];
      if (mockData) {
        setRows(mockData.diagramConfigRows);
        setNodeData(mockData.diagramData.nodeData);
        setLinkData(mockData.diagramData.linkData);
      }
    }
  }, [type]);

  return (
    <Container>
      <AppScrollbar
        sx={{
          maxHeight: "calc(100vh - 104px)",
        }}
      >
        <Box
          sx={{
            padding: "20px",
            background: "#fff",
            boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)",
          }}
        >
          <Box
            sx={{
              height: "auto",
              width: "100%",
            }}
          >
            <Box sx={{ width: "100%", height: "300px", mt: 4 }}>
              <Diagram nodeData={nodeData} linkData={linkData} />
            </Box>
          </Box>
          <Box>
            <CustomTable
              columns={columns}
              rows={rows}
              maxHeight="auto"
              showPage={false}
              addRow={() => {
                console.log("add row");
              }}
              addRowTextId="common.add"
            />
          </Box>
        </Box>
      </AppScrollbar>
    </Container>
  );
};

export default WorkitemRelationships;
