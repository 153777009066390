import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import { ListModel } from "components/CustomTable/CustomTableModel";
import TableToolBar from "components/CustomTable/TableToolBar";
import MyTreeTable from "components/MyTreeTable";
import React from "react";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

const TreeTableContainer = styled(Box)(({ theme }) => ({
  width: "calc(80% - 1px)",
  padding: "12px 0px 0px 24px",
  height: "100%",
  ".table-tree": {
    width: "100%",
    height: "calc(100% - 42px)",
    paddingTop: "12px",
  },
  ".hight_light_color": {
    color: "#2e6ccb",
  },
  ".cursor": {
    cursor: "pointer",
  },
  ".bold_font": {
    fontWeight: "bold",
  },
  ".normal_font": {
    fontWeight: "normal",
  },
  ".padding_right": {
    paddingRight: "8px",
  },
  ".margin_right": {
    marginRight: "8px",
  },
  ".title_color": {
    color: "rgba(0,0,0,0.85)",
  },
  ".sub_title_color": {
    color: "rgba(0,0,0,0.85)",
  },
}));

interface TreeTableProps {
  dataSourceType: any;
}

const TreeTable: React.FC<TreeTableProps> = (props: TreeTableProps) => {
  React.useEffect(() => {
    if (props.dataSourceType?.id && Number(props.dataSourceType.id) < 6) {
      setColumns([
        {
          id: "name",
          label: "common.name",
          minWidth: 200,
          align: "left",
        },
        {
          id: "id",
          label: "common.display.id",
          minWidth: 150,
          align: "left",
        },
        {
          id: "s",
          label: "common.s",
          minWidth: 100,
          align: "left",
          format: (_, record) => {
            if (record.s1 || record.s2) {
              return (
                <Box>
                  <Box>{record.s1}</Box>
                  <Box sx={{ fontWeight: "bold" }}>{record.s2}</Box>
                </Box>
              );
            }
            return null;
          },
        },
        {
          id: "p1",
          label: "common.p1",
          minWidth: 100,
          align: "left",
          format: (_, record) => {
            if (record.p1_1 || record.p1_2) {
              return (
                <Box>
                  <Box>{record.p1_1}</Box>
                  <Box sx={{ fontWeight: "bold" }}>{record.p1_2}</Box>
                </Box>
              );
            }
            return null;
          },
        },
        {
          id: "p2",
          label: "common.p2",
          minWidth: 100,
          align: "left",
          format: (_, record) => {
            if (record.p2_1 || record.p2_2) {
              return (
                <Box>
                  <Box>{record.p2_1}</Box>
                  <Box sx={{ fontWeight: "bold" }}>{record.p2_2}</Box>
                </Box>
              );
            }
            return null;
          },
        },
        {
          id: "p",
          label: "common.p",
          minWidth: 100,
          align: "left",
          format: (_, record) => {
            if (record.p_1 || record.p_2) {
              return (
                <Box>
                  <Box>{record.p_1}</Box>
                  <Box sx={{ fontWeight: "bold" }}>{record.p_2}</Box>
                </Box>
              );
            }
            return null;
          },
        },
        {
          id: "risk_level",
          label: "common.risk.level",
          minWidth: 100,
          align: "left",
          format: (_, record) => {
            if (record.risk_level_1 || record.risk_level_2) {
              return (
                <Box>
                  <Box>{record.risk_level_1}</Box>
                  <Box sx={{ fontWeight: "bold" }}>{record.risk_level_2}</Box>
                </Box>
              );
            }
            return null;
          },
        },
      ]);
      setRows([
        {
          id: "HAZ#100",
          name: "Hazard Category",
          level: 1,
          s1: "4",
          s2: "4",
          p1_1: "4",
          p1_2: "5",
          p2_1: "4",
          p2_2: "5",
          p_1: "PR",
          p_2: "FR",
          risk_level_1: "PR",
          risk_level_2: "FR",
          children: [
            {
              id: "HAZ#101",
              name: "Therapeutic",
              level: 2,
              s1: "2",
              s2: "3",
              p1_1: "1",
              p1_2: "1",
              p2_1: "1",
              p2_2: "1",
              p_1: "IM",
              p_2: "IM",
              risk_level_1: "IM",
              risk_level_2: "IM",
            },
            {
              id: "HAZ#102",
              name: "Electrical Energy",
              level: 2,
              s1: "1",
              s2: "1",
              p1_1: "2",
              p1_2: "3",
              p2_1: "2",
              p2_2: "3",
              p_1: "IM",
              p_2: "RE",
              risk_level_1: "IM",
              risk_level_2: "RE",
            },
            {
              id: "HAZ#103",
              name: "Mechanical Energy",
              level: 2,
              s1: "3",
              s2: "3",
              p1_1: "3",
              p1_2: "3",
              p2_1: "3",
              p2_2: "3",
              p_1: "OC",
              p_2: "OC",
              risk_level_1: "OC",
              risk_level_2: "OC",
            },
            {
              id: "HAZ#104",
              name: "BiologicalEnergy",
              level: 2,
              s1: "4",
              s2: "4",
              p1_1: "4",
              p1_2: "5",
              p2_1: "4",
              p2_2: "5",
              p_1: "PR",
              p_2: "FR",
              risk_level_1: "PR",
              risk_level_2: "FR",
            },
          ],
        },
      ]);
    } else if (Number(props.dataSourceType?.id) === 6) {
      setColumns([
        {
          id: "name",
          label: "common.name",
          minWidth: 300,
          align: "left",
        },
        {
          id: "id",
          label: "common.display.id",
          minWidth: 150,
          align: "left",
        },
        {
          id: "priority",
          label: "common.priority",
          minWidth: 150,
          align: "left",
        },
        {
          id: "criticality",
          label: "common.requirement.criticality",
          minWidth: 200,
          align: "left",
        },
        {
          id: "rationale",
          label: "common.rationale",
          minWidth: 180,
          align: "left",
        },
      ]);
      setRows([
        {
          id: "SYS#100",
          name: "Design Input Requirements",
          priority: "",
          criticality: "",
          rationale: "",
          level: 1,
          children: [
            {
              id: "SYS#001",
              name: "The system shall measure temperature in the range of 34oC to 44oC",
              priority: "Med",
              criticality: "None",
              rationale: "The typical environmental temperature for the countries in which the device will be sold",
              level: 2,
            },
            {
              id: "SYS#002",
              name: "The system shall measure temperature with a accuracy of 5% when temperature is measured 3 inches +/- 1 inch from the forehead",
              priority: "High",
              criticality: "CTQ",
              rationale: "Competitive Advantange",
              level: 2,
            },
            {
              id: "SYS#003",
              name: "The system shall weigh no more than 6 ounces",
              priority: "",
              criticality: "",
              rationale: "",
              level: 2,
            },
            {
              id: "SYS#023",
              name: "The system shall weigh no more than 4 ounces",
              priority: "",
              criticality: "",
              rationale: "",
              level: 2,
            },
          ],
        },
      ]);
    } else if (Number(props.dataSourceType?.id) === 7) {
      setColumns([
        {
          id: "name",
          label: "common.name",
          minWidth: 300,
          align: "left",
        },
        {
          id: "id",
          label: "common.display.id",
          minWidth: 150,
          align: "left",
        },
        {
          id: "userTypes",
          label: "common.user.types",
          minWidth: 200,
          align: "left",
          format: (value, record) => {
            const list = value ? value.split("|") : [];
            if (list && list.length > 0) {
              return list.map((item) => <Box key={item}>{item}</Box>);
            }
            return "";
          },
        },
        {
          id: "priority",
          label: "common.priority",
          minWidth: 150,
          align: "left",
        },
        {
          id: "rationale",
          label: "common.rationale",
          minWidth: 180,
          align: "left",
        },
      ]);
      setRows([
        {
          id: "UND#100",
          name: "User Needs",
          priority: "",
          userTypes: "",
          rationale: "",
          level: 1,
          children: [
            {
              id: "UND#001",
              name: "The user needs a device that can measure temperature accurately and quickly.",
              priority: "High",
              userTypes: "Patient|Healthcare|Provider|Caregiver",
              rationale: "",
              level: 2,
            },
            {
              id: "UND#002",
              name: "The user needs to able to accuratly track the temperature of multiple patients when using the device",
              priority: "High",
              userTypes: "Healthcare Provider|Caregiver",
              rationale: "",
              level: 2,
            },
            {
              id: "UND#003",
              name: "The user needs the device to read body temperature without pain or discomfort.",
              priority: "Med",
              userTypes: "Patient",
              rationale: "",
              level: 2,
            },
            {
              id: "SYS#023",
              name: "The user needs a device that is easy to use.",
              priority: "Med",
              userTypes: "Patient|Healthcare|Provider|Caregiver",
              rationale: "",
              level: 2,
            },
          ],
        },
      ]);
    } else {
      setColumns([]);
      setRows([]);
    }
  }, [props.dataSourceType]);

  const [columns, setColumns] = React.useState<Column[]>([
    {
      id: "name",
      label: "common.name",
      minWidth: 200,
      align: "left",
    },
    {
      id: "id",
      label: "common.display.id",
      minWidth: 150,
      align: "left",
    },
    {
      id: "s",
      label: "common.s",
      minWidth: 100,
      align: "left",
      format: (_, record) => {
        if (record.s1 || record.s2) {
          return (
            <Box>
              <Box>{record.s1}</Box>
              <Box sx={{ fontWeight: "bold" }}>{record.s2}</Box>
            </Box>
          );
        }
        return null;
      },
    },
    {
      id: "p1",
      label: "common.p1",
      minWidth: 100,
      align: "left",
      format: (_, record) => {
        if (record.p1_1 || record.p1_2) {
          return (
            <Box>
              <Box>{record.p1_1}</Box>
              <Box sx={{ fontWeight: "bold" }}>{record.p1_2}</Box>
            </Box>
          );
        }
        return null;
      },
    },
    {
      id: "p2",
      label: "common.p2",
      minWidth: 100,
      align: "left",
      format: (_, record) => {
        if (record.p2_1 || record.p2_2) {
          return (
            <Box>
              <Box>{record.p2_1}</Box>
              <Box sx={{ fontWeight: "bold" }}>{record.p2_2}</Box>
            </Box>
          );
        }
        return null;
      },
    },
    {
      id: "p",
      label: "common.p",
      minWidth: 100,
      align: "left",
      format: (_, record) => {
        if (record.p_1 || record.p_2) {
          return (
            <Box>
              <Box>{record.p_1}</Box>
              <Box sx={{ fontWeight: "bold" }}>{record.p_2}</Box>
            </Box>
          );
        }
        return null;
      },
    },
    {
      id: "risk_level",
      label: "common.risk.level",
      minWidth: 100,
      align: "left",
      format: (_, record) => {
        if (record.risk_level_1 || record.risk_level_2) {
          return (
            <Box>
              <Box>{record.risk_level_1}</Box>
              <Box sx={{ fontWeight: "bold" }}>{record.risk_level_2}</Box>
            </Box>
          );
        }
        return null;
      },
    },
  ]);

  const [rows, setRows] = React.useState<any[]>([
    {
      id: "HAZ#100",
      name: "Hazard Category",
      level: 1,
      s1: "4",
      s2: "4",
      p1_1: "4",
      p1_2: "5",
      p2_1: "4",
      p2_2: "5",
      p_1: "PR",
      p_2: "FR",
      risk_level_1: "PR",
      risk_level_2: "FR",
      children: [
        {
          id: "HAZ#101",
          name: "Therapeutic",
          level: 2,
          s1: "2",
          s2: "3",
          p1_1: "1",
          p1_2: "1",
          p2_1: "1",
          p2_2: "1",
          p_1: "IM",
          p_2: "IM",
          risk_level_1: "IM",
          risk_level_2: "IM",
        },
        {
          id: "HAZ#102",
          name: "Electrical Energy",
          level: 2,
          s1: "1",
          s2: "1",
          p1_1: "2",
          p1_2: "3",
          p2_1: "2",
          p2_2: "3",
          p_1: "IM",
          p_2: "RE",
          risk_level_1: "IM",
          risk_level_2: "RE",
        },
        {
          id: "HAZ#103",
          name: "Mechanical Energy",
          level: 2,
          s1: "3",
          s2: "3",
          p1_1: "3",
          p1_2: "3",
          p2_1: "3",
          p2_2: "3",
          p_1: "OC",
          p_2: "OC",
          risk_level_1: "OC",
          risk_level_2: "OC",
        },
        {
          id: "HAZ#104",
          name: "BiologicalEnergy",
          level: 2,
          s1: "4",
          s2: "4",
          p1_1: "4",
          p1_2: "5",
          p2_1: "4",
          p2_2: "5",
          p_1: "PR",
          p_2: "FR",
          risk_level_1: "PR",
          risk_level_2: "FR",
        },
      ],
    },
  ]);

  const [searchText, setSearchText] = React.useState("");

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
  };

  return (
    <TreeTableContainer>
      <Box className="table-tool-bar">
        {
          <TableToolBar
            showSearch={true}
            searchText={searchText}
            requestSearch={requestSearch}
            saveFilter={() => {
              console.log("save filter");
            }}
            buttons={[
              {
                id: "common.customize",
                icon: TableHeaderButtonsIconType.SETTING,
                type: "outlined",
                onChange: () => {},
              },
            ]}
          />
        }
      </Box>
      <Box className="table-tree">
        <MyTreeTable columns={columns} rows={rows} maxHeight="500px" />
      </Box>
    </TreeTableContainer>
  );
};

export default TreeTable;
