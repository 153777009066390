import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import CircularLoading from "components/CircularLoading";
import AddIcon from "components/Icons/AddIcon";
import MyCollapseTable from "components/MyCollapseTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import { PhModel, RuleEnum } from "../RiskPolicy/models";
import CheckboxRadioGroup, { CheckboxGroupOutputModel, GroupItem } from "./CheckboxRadioGroup";
import LookupTable from "./LookupTable";

const Container = styled(Box)(({ theme }) => ({
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell": {
    border: `1px solid  ${borderColor.dividerColor}`,
  },
  ".table-toolbar": {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(0),
    ".table-toolbar-actions": {
      ".refresh-btn": {
        color: textColor[85],
      },
      ".add-button": {
        marginLeft: theme.spacing(2),
      },
    },
  },
}));

const initColumns: Column[] = [
  {
    id: "description",
    label: "common.description",
  },
  {
    id: "abbreviation",
    label: "common.abbreviation",
  },
  {
    id: "value",
    label: "common.value",
  },
  {
    id: "displayOrder",
    label: "setting.product.column.displayOrder",
  },
  {
    id: "summary",
    label: "common.summary",
  },
  // {
  //   id: "actions",
  //   label: "setting.product.column.actions",
  //   format: (value, record) => {
  //     return <></>;
  //   },
  // },
];

const createData = async (rowId) => {
  const p = new Promise<PhModel[]>((resolve, reject) => {
    setTimeout(() => {
      resolve([
        {
          id: `${rowId}01`,
          description: "Improbable",
          abbreviation: "IM",
          value: "1",
          displayOrder: 1,
          summary: "",
          statusColor: "#000",
        },
        {
          id: `${rowId}02`,
          description: "Remote",
          abbreviation: "RE",
          value: "2",
          displayOrder: 2,
          summary: "",
          statusColor: "#000",
        },
        {
          id: `${rowId}03`,
          description: "Occasional",
          abbreviation: "OCC",
          value: "3",
          displayOrder: 3,
          summary: "",
          statusColor: "#000",
        },
        {
          id: `${rowId}04`,
          description: "Probable",
          abbreviation: "PR",
          value: "4",
          displayOrder: 4,
          summary: "",
          statusColor: "#000",
        },
        {
          id: `${rowId}05`,
          description: "Frequent",
          abbreviation: "FR",
          value: "5",
          displayOrder: 5,
          summary: "",
          statusColor: "#000",
        },
      ]);
    }, 200);
  });
  const r = await p;
  return r;
};
interface PhTabData {
  rowsData?: PhModel[];
  groupItems?: GroupItem[];
  phCheckbox?: CheckboxGroupOutputModel;
}
interface PhProps {
  rowId?: number | string;
  editable?: boolean;
  tabData?: PhTabData;
  onServerComplete?: (data: PhTabData) => void;
}
const Ph = (props: PhProps) => {
  const { rowId, editable, tabData, onServerComplete } = props;

  const [groupItems, setGroupItems] = useState<GroupItem[]>(tabData && tabData.groupItems ? tabData.groupItems : []);
  const [phCheckbox, setPhCheckbox] = useState<CheckboxGroupOutputModel>(
    tabData && tabData.phCheckbox ? tabData.phCheckbox : {}
  );

  const [columns, setColumns] = useState([...initColumns]);
  const [rows, setRows] = useState<PhModel[]>(tabData && tabData.rowsData ? tabData.rowsData : []);
  const [loading, setLoading] = useState(true);

  const createDataFn = async (rowId) => {
    setLoading(true);
    const a = await createData(rowId);
    setRows(a);

    const g: GroupItem[] = [
      {
        key: "phRadio",
        defaultValue: RuleEnum.FormulaP1XP2,
        type: "radio",
        radioList: [
          {
            labelKey: RuleEnum.Manual,
            value: RuleEnum.Manual,
          },
          {
            labelKey: RuleEnum.FormulaP1XP2,
            value: RuleEnum.FormulaP1XP2,
          },
          {
            labelKey: RuleEnum.FormulaP1AddP2,
            value: RuleEnum.FormulaP1AddP2,
          },
          {
            labelKey: RuleEnum.LookupTable,
            value: RuleEnum.LookupTable,
          },
        ],
      },
    ];
    const cg = {
      phRadio: RuleEnum.FormulaP1XP2,
    };
    setGroupItems(g);
    // setPhCheckbox(cg);
    onServerComplete &&
      onServerComplete({
        rowsData: a,
        groupItems: g,
        phCheckbox: cg,
      });
    setLoading(false);
  };

  useEffect(() => {
    if (!tabData || !tabData.rowsData || tabData.rowsData.length === 0) createDataFn(rowId);
    else setLoading(false);
  }, []);

  useEffect(() => {
    // console.log(phCheckbox);
  }, [phCheckbox]);

  const handlePhCheckboxChange = useCallback(
    (output: CheckboxGroupOutputModel) => {
      setPhCheckbox(output);
      onServerComplete &&
        onServerComplete({
          rowsData: rows,
          groupItems: groupItems,
          phCheckbox: output,
        });
    },
    [groupItems, onServerComplete, rows]
  );

  // const handleEditClick = (row: PhModel) => {
  //   console.log("Ph handleEditClick: ", row);
  // };

  // const handleDeleteClick = (row: PhModel) => {
  //   console.log("Ph handleDeleteClick: ", row);
  // };

  // useEffect(() => {
  //   const newColumns: Column[] = [];
  //   initColumns.forEach((c) => {
  //     if (c.id === "actions" && editable) {
  //       newColumns.push({
  //         id: "actions",
  //         label: "setting.product.column.actions",
  //         format: (value, record) => {
  //           return (
  //             <Box>
  //               <IconButton
  //                 sx={{ padding: "2px" }}
  //                 onClick={() => {
  //                   handleEditClick(record);
  //                 }}
  //               >
  //                 <AppSvgIcon size={16} color={textColor["65"]}>
  //                   {"material-solid:edit"}
  //                 </AppSvgIcon>
  //               </IconButton>
  //               <IconButton
  //                 sx={{ padding: "2px" }}
  //                 onClick={() => {
  //                   handleDeleteClick(record);
  //                 }}
  //               >
  //                 <AppSvgIcon size={16} color={textColor["65"]}>
  //                   {"material-solid:delete"}
  //                 </AppSvgIcon>
  //               </IconButton>
  //             </Box>
  //           );
  //         },
  //       });
  //     } else {
  //       newColumns.push(c);
  //     }
  //   });
  //   setColumns([...newColumns]);
  // }, [editable]);

  const lookupTable = useMemo(() => {
    if (!phCheckbox) {
      return <></>;
    }
    if (phCheckbox["phRadio"] !== RuleEnum.LookupTable) {
      return <></>;
    }
    return <LookupTable rowId={rowId} />;
  }, [phCheckbox, rowId]);

  // const handleAddClick = () => {
  //   console.log("Ph handleAddClick");
  // };

  const toolbar = useMemo(() => {
    if (loading) {
      return <></>;
    } else {
      return (
        <Box className="table-toolbar flex justify-between">
          <Box sx={{ padding: "0 8px" }}>
            <CheckboxRadioGroup
              selected={tabData ? tabData.phCheckbox : undefined}
              groups={groupItems}
              onGroupChange={handlePhCheckboxChange}
            />
          </Box>
          {/* {editable ? (
            <Box className="table-toolbar-actions flex justify-center items-center">
              <Button
                className="refresh-btn"
                startIcon={<AppSvgIcon color={textColor["65"]}>{"material-outline:refresh"}</AppSvgIcon>}
              >
                <IntlMessages id="common.refresh" />
              </Button>
              <Button
                variant="text"
                className="add-button"
                color="primary"
                startIcon={<AddIcon isBlue={true} />}
                onClick={handleAddClick}
              >
                <IntlMessages id="common.add" />
              </Button>
            </Box>
          ) : (
            <></>
          )} */}
        </Box>
      );
    }
  }, [loading, tabData, groupItems, handlePhCheckboxChange]);

  return (
    <>
      <CircularLoading loading={loading}>
        <Container>
          {!loading ? (
            <MyCollapseTable
              toolbar={toolbar}
              columns={columns}
              rows={rows}
              showPage={true}
              collapse={false}
            ></MyCollapseTable>
          ) : (
            <></>
          )}
          {lookupTable}
        </Container>
      </CircularLoading>
    </>
  );
};

export default Ph;
