import { Box } from "@mui/material";
import React from "react";
export interface SpecProps {}

const Spec: React.FC<SpecProps> = (props) => {
  return (
    <>
      <Box
        className="detail-root"
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          width: "100%",
          "& .spec-detail": {
            flex: 1,
            padding: 2,
          },
          "& .spec-attribute": {
            width: 232,
            padding: 2,
            backgroundColor: "#FAFAFA",
            borderLeft: "1px solid rgba(32,45,64,.04)",
          },
        }}
      >
        <div className="spec-detail">
          <div className="spec-header"></div>
          <div className="spec-detail-wrapper"></div>
        </div>
        <div className="spec-attribute"></div>
      </Box>
    </>
  );
};

export default Spec;
