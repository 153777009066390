import $ from "components/Gojs";
import * as go from "gojs";
import * as React from "react";

interface GanttProps {}

const GridCellHeight = 20; // document units
const GridCellWidth = 70; // document units per day
const TimelineHeight = 24; // document units
let myGantt;
const MsPerDay = 24 * 60 * 60 * 1000;
let StartDate = new Date(); // set from Model.modelData.origin

const Gantt: React.FC<GanttProps> = (props: GanttProps) => {
  const convertDaysToUnits = (n) => {
    return n;
  };

  const convertUnitsToDays = (n) => {
    return n;
  };

  const convertStartToX = (start) => {
    return convertUnitsToDays(start) * GridCellWidth;
  };

  const convertDurationToW = (duration) => {
    return convertUnitsToDays(duration) * GridCellWidth;
  };

  const convertWToDuration = (w) => {
    return convertDaysToUnits(w / GridCellWidth);
  };

  const convertStartToPosition = (start, node) => {
    return new go.Point(convertStartToX(start), node.position.y || 0);
  };

  const convertPositionToStart = (pos) => {
    return convertXToStart(pos.x);
  };

  const convertXToStart = (x, node?) => {
    return convertDaysToUnits(x / GridCellWidth);
  };

  const valueToText = (n) => {
    // N is in days since StartDate
    const startDate = StartDate;
    const startDateMs = startDate.getTime() + startDate.getTimezoneOffset() * 60000;
    const date = new Date(startDateMs + (n * MsPerDay) / GridCellWidth);
    return date.toLocaleDateString();
  };

  const dateToValue = (d) => {
    // D is a Date
    const startDate = StartDate;
    const startDateMs = startDate.getTime() + startDate.getTimezoneOffset() * 60000;
    const dateInMs = d.getTime() + d.getTimezoneOffset() * 60000;
    const msSinceStart = dateInMs - startDateMs;
    return (msSinceStart / MsPerDay) * GridCellWidth;
  };

  React.useEffect(() => {
    class GanttLayout extends go.Layout {
      cellHeight: any;
      constructor() {
        super();
        this.cellHeight = GridCellHeight;
      }

      doLayout(coll) {
        coll = this.collectParts(coll);
        const diagram = this.diagram;
        diagram.startTransaction("Gantt Layout");
        const bars = [];
        this.assignTimes(diagram, bars);
        this.arrangementOrigin = this.initialOrigin(this.arrangementOrigin);
        let y = this.arrangementOrigin.y;
        bars.forEach((node) => {
          const tasknode = myTasks.findNodeForData(node.data);
          node.visible = tasknode.isVisible();
          node.moveTo(convertStartToX(node.data.start), y);
          if (node.visible) y += this.cellHeight;
        });
        diagram.commitTransaction("Gantt Layout");
      }

      // Update node data, to make sure each node has a start and a duration
      assignTimes(diagram, bars) {
        const roots = diagram.findTreeRoots();
        roots.each((root) => this.walkTree(root, 0, bars));
      }

      walkTree(node, start, bars) {
        bars.push(node);
        const model = node.diagram.model;
        if (node.isTreeLeaf) {
          let dur = node.data.duration;
          if (dur === undefined || isNaN(dur)) {
            dur = convertDaysToUnits(1); // default task length?
            model.set(node.data, "duration", dur);
          }
          let st = node.data.start;
          if (st === undefined || isNaN(st)) {
            st = start; // use given START
            model.set(node.data, "start", st);
          }
          return st + dur;
        } else {
          // first recurse to fill in any missing data
          node.findTreeChildrenNodes().each((n) => {
            start = this.walkTree(n, start, bars);
          });
          // now can calculate this non-leaf node's data
          let min = Infinity;
          let max = -Infinity;
          const colors = new go.Set();
          node.findTreeChildrenNodes().each((n) => {
            min = Math.min(min, n.data.start);
            max = Math.max(max, n.data.start + n.data.duration);
            if (n.data.color) colors.add(n.data.color);
          });
          model.set(node.data, "start", min);
          model.set(node.data, "duration", max - min);
          return max;
        }
      }
    }

    go.Shape.defineFigureGenerator("RangeBar", (shape, w, h) => {
      const b = Math.min(5, w);
      const d = Math.min(5, h);
      return new go.Geometry().add(
        new go.PathFigure(0, 0, true)
          .add(new go.PathSegment(go.PathSegment.Line, w, 0))
          .add(new go.PathSegment(go.PathSegment.Line, w, h))
          .add(new go.PathSegment(go.PathSegment.Line, w - b, h - d))
          .add(new go.PathSegment(go.PathSegment.Line, b, h - d))
          .add(new go.PathSegment(go.PathSegment.Line, 0, h).close())
      );
    });

    // the left side of the whole diagram
    const myTasks = $(go.Diagram, "myTasksDiv", {
      padding: new go.Margin(TimelineHeight, 0, 0, 0),
      hasVerticalScrollbar: false,
      allowMove: false,
      allowCopy: false,
      allowTextEdit: false,
      isReadOnly: false,
      "commandHandler.deletesTree": true,
      layout: $(go.TreeLayout, {
        alignment: go.TreeLayout.AlignmentStart,
        compaction: go.TreeLayout.CompactionNone,
        layerSpacing: 16,
        layerSpacingParentOverlap: 1,
        nodeIndentPastParent: 1,
        nodeSpacing: 0,
        portSpot: go.Spot.Bottom,
        childPortSpot: go.Spot.Left,
        arrangementSpacing: new go.Size(0, 0),
      }),
      "animationManager.isInitial": false,
      TreeCollapsed: (e) => myGantt.layoutDiagram(true),
      TreeExpanded: (e) => myGantt.layoutDiagram(true),
    });

    myTasks.nodeTemplate = $(
      go.Node,
      "Horizontal",
      { height: 20 },
      new go.Binding("isTreeExpanded").makeTwoWay(),
      $("TreeExpanderButton", { portId: "", scale: 0.85 }),
      $(go.TextBlock, { editable: true }, new go.Binding("text").makeTwoWay())
    );

    myTasks.linkTemplate = $(
      go.Link,
      {
        routing: go.Link.Orthogonal,
        fromEndSegmentLength: 1,
        toEndSegmentLength: 1,
      },
      $(go.Shape)
    );

    myTasks.linkTemplateMap.add(
      "Dep",
      $(
        go.Link, // ignore these links in the Tasks diagram
        { visible: false, isTreeLink: false }
      )
    );

    // the right side of the whole diagram
    myGantt = $(go.Diagram, "myGanttDiv", {
      initialPosition: new go.Point(-7, -100), // show labels
      padding: new go.Margin(TimelineHeight, 0, 0, 0),
      scrollMargin: new go.Margin(0, GridCellWidth * 7, 0, 0), // show a week beyond
      allowCopy: false,
      isReadOnly: true,
      "commandHandler.deletesTree": true,
      "draggingTool.isGridSnapEnabled": true,
      "draggingTool.gridSnapCellSize": new go.Size(GridCellWidth, GridCellHeight),
      "draggingTool.dragsTree": true,
      "resizingTool.isGridSnapEnabled": true,
      "resizingTool.cellSize": new go.Size(GridCellWidth, GridCellHeight),
      "resizingTool.minSize": new go.Size(GridCellWidth, GridCellHeight),
      layout: $(GanttLayout),
      "animationManager.isInitial": false,
      SelectionMoved: (e) => e.diagram.layoutDiagram(true),
      DocumentBoundsChanged: (e) => {
        // the grid extends to only the area needed
        const b = e.diagram.documentBounds;
        const gridpart = e.diagram.parts.first();
        if (gridpart !== null && gridpart.type === go.Panel.Grid) {
          gridpart.desiredSize = new go.Size(b.right - gridpart.position.x, b.bottom);
        }
        // the timeline only covers the needed area
        myTimeline.findObject("MAIN").width = b.right;
        myTimeline.findObject("TICKS").height = e.diagram.viewportBounds.height;
        myTimeline.graduatedMax = b.right;
      },
    });

    myGantt.add(
      $(
        go.Part,
        "Grid",
        { layerName: "Grid", position: new go.Point(-10, 0), gridCellSize: new go.Size(3000, GridCellHeight) },
        $(go.Shape, "LineH", { strokeWidth: 0.5 })
      )
    );

    myGantt.nodeTemplate = $(
      go.Node,
      "Spot",
      {
        selectionAdorned: false,
        selectionChanged: (node) => {
          node.diagram.commit((diag) => {
            node.findObject("SHAPE").fill = node.isSelected ? "dodgerblue" : (node.data && node.data.color) || "gray";
          }, null);
        },
        minLocation: new go.Point(0, NaN),
        maxLocation: new go.Point(Infinity, NaN),
        toolTip: $(
          "ToolTip",
          $(
            go.Panel,
            "Table",
            { defaultAlignment: go.Spot.Left },
            $(go.RowColumnDefinition, { column: 1, separatorPadding: 3 }),
            $(go.TextBlock, { row: 0, column: 0, columnSpan: 9, font: "bold 12pt sans-serif" }, new go.Binding("text")),
            $(go.TextBlock, { row: 1, column: 0 }, "start:"),
            $(
              go.TextBlock,
              { row: 1, column: 1 },
              new go.Binding("text", "start", (d) => "day " + convertUnitsToDays(d).toFixed(0))
            ),
            $(go.TextBlock, { row: 2, column: 0 }, "length:"),
            $(
              go.TextBlock,
              { row: 2, column: 1 },
              new go.Binding("text", "duration", (d) => convertUnitsToDays(d).toFixed(0) + " days")
            )
          )
        ),
        resizable: true,
        resizeObjectName: "SHAPE",
        resizeAdornmentTemplate: $(
          go.Adornment,
          "Spot",
          $(go.Placeholder),
          $(go.Shape, "Diamond", {
            alignment: go.Spot.Right,
            width: 8,
            height: 8,
            strokeWidth: 0,
            fill: "fuchsia",
            cursor: "e-resize",
          })
        ),
      },
      new go.Binding("position", "start", convertStartToPosition).makeTwoWay(convertPositionToStart),
      new go.Binding("resizable", "isTreeLeaf").ofObject(),
      new go.Binding("isTreeExpanded").makeTwoWay(),
      $(
        go.Shape,
        { name: "SHAPE", height: 18, margin: new go.Margin(1, 0), strokeWidth: 0, fill: "gray" },
        new go.Binding("fill", "color"),
        new go.Binding("width", "duration", convertDurationToW).makeTwoWay(convertWToDuration),
        new go.Binding("figure", "isTreeLeaf", (leaf) => (leaf ? "Rectangle" : "RangeBar")).ofObject()
      ),
      // "RangeBar" is defined above as a custom figure
      $(
        go.TextBlock,
        { font: "8pt sans-serif", alignment: go.Spot.TopLeft, alignmentFocus: new go.Spot(0, 0, 0, -2) },
        new go.Binding("text"),
        new go.Binding("stroke", "color", (c) => (go.Brush.isDark(c) ? "#DDDDDD" : "#333333"))
      )
    );

    myGantt.linkTemplate = $(go.Link, { visible: false });

    myGantt.linkTemplateMap.add(
      "Dep",
      $(
        go.Link,
        {
          routing: go.Link.Orthogonal,
          isTreeLink: false,
          isLayoutPositioned: false,
          fromSpot: new go.Spot(0.999999, 1),
          toSpot: new go.Spot(0.000001, 0),
        },
        $(go.Shape, { stroke: "brown" }),
        $(go.Shape, { toArrow: "Standard", fill: "brown", strokeWidth: 0, scale: 0.75 })
      )
    );

    // the timeline
    const myTimeline = $(
      go.Part,
      "Graduated",
      {
        layerName: "Adornment",
        location: new go.Point(0, 0),
        locationSpot: go.Spot.Left,
        locationObjectName: "MAIN",
        graduatedTickUnit: GridCellWidth,
      },
      $(go.Shape, "LineH", { name: "MAIN", strokeWidth: 0, height: TimelineHeight, background: "lightgray" }),
      $(go.Shape, { name: "TICKS", geometryString: "M0 0 V1000", interval: 7, stroke: "lightgray", strokeWidth: 0.5 }),
      $(go.TextBlock, {
        alignmentFocus: go.Spot.Left,
        interval: 7, // once per week
        graduatedFunction: valueToText,
      })
    );
    myGantt.add(myTimeline);

    // The Model that is shared by both Diagrams
    const nodeDataArray = [
      {
        key: 1,
        text: "1 Concept & Requirements",
      },
      {
        key: 11,
        text: "TK#101 User Needs",
        color: "#FACD91",
      },
      {
        key: 111,
        text: "TK#111 Sub Task 001",
        duration: convertDaysToUnits(3),
        color: "#FFFF80",
      },
      {
        key: 112,
        text: "TK#112 Sub Task 002",
        duration: convertDaysToUnits(2),
        color: "#CAF982",
      },
      {
        key: 12,
        text: "TK#001 System Hazard Analysis",
        duration: convertDaysToUnits(7),
        start: convertDaysToUnits(10),
        color: "#80FFFF",
      },
      {
        key: 121,
        text: "STK#015 Sub Task 023",
        duration: convertDaysToUnits(4),
        start: convertDaysToUnits(11),
        color: "#81D3F8",
      },
      {
        key: 13,
        text: "TK#203 Design Input Requirements",
        duration: convertDaysToUnits(6),
        start: convertDaysToUnits(5),
        color: "#8080FF",
      },
      {
        key: 2,
        text: "2 Design & Developments",
      },
      {
        key: 21,
        text: "Tk#002 Design FMEA",
        duration: convertDaysToUnits(5),
        start: convertDaysToUnits(9),
        color: "#C280FF",
      },
      {
        key: 22,
        text: "TK#003 Proce4ss FMEA",
        duration: convertDaysToUnits(5),
        start: convertDaysToUnits(9),
        color: "#63A103",
      },
      {
        key: 3,
        text: "3 Verification & Validation",
      },
      {
        key: 31,
        text: "TK#301 Verification Protocols",
        duration: convertDaysToUnits(2),
        start: convertDaysToUnits(14),
        color: "#FF4D00",
      },
      {
        key: 32,
        text: "TK#302 Validation Protocols",
        duration: convertDaysToUnits(2),
        start: convertDaysToUnits(14),
        color: "#027DB4",
      },
    ];
    const linkDataArray = [
      { from: 1, to: 11 },
      { from: 11, to: 111 },
      { from: 11, to: 112 },
      { from: 1, to: 12 },
      { from: 12, to: 121 },
      { from: 1, to: 13 },
      { from: 2, to: 21 },
      { from: 2, to: 22 },
      { from: 3, to: 31 },
      { from: 3, to: 32 },
      // { from: 11, to: 2, category: "Dep" },
    ];
    const myModel = new go.GraphLinksModel(nodeDataArray, linkDataArray);
    myModel.modelData.origin = 1531540800000;
    StartDate = new Date(myModel.modelData.origin);

    // share model
    myTasks.model = myModel;
    myGantt.model = myModel;
    myModel.undoManager.isEnabled = true;

    // sync viewports
    var changingView = false; // for preventing recursive updates
    myTasks.addDiagramListener("ViewportBoundsChanged", (e) => {
      if (changingView) return;
      changingView = true;
      myGantt.scale = myTasks.scale;
      myGantt.position = new go.Point(myGantt.position.x, myTasks.position.y);
      myTimeline.position = new go.Point(myTimeline.position.x, myTasks.viewportBounds.position.y);
      changingView = false;
    });
    myGantt.addDiagramListener("ViewportBoundsChanged", (e) => {
      if (changingView) return;
      changingView = true;
      myTasks.scale = myGantt.scale;
      myTasks.position = new go.Point(myTasks.position.x, myGantt.position.y);
      myTimeline.position = new go.Point(myTimeline.position.x, myGantt.viewportBounds.position.y);
      changingView = false;
    });
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100%)",
        display: "flex",
        justifyContent: "space-between",
        border: "solid 1px black",
      }}
    >
      <div
        id="myTasksDiv"
        style={{
          width: "300px",
          height: "100%",
          marginRight: "2px",
          borderRight: "1px solid black",
          position: "relative",
        }}
      >
        <canvas
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: 2,
            userSelect: "none",
            touchAction: "none",
            width: "299px",
            height: "100%",
          }}
        >
          This text is displayed if your browser does not support the Canvas HTML element.
        </canvas>
      </div>
      <div
        id="myGanttDiv"
        style={{
          flexGrow: 1,
          height: "100%",
          width: "calc(100% - 302px)",
          position: "relative",
          cursor: "auto",
        }}
      >
        <canvas
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: 2,
            userSelect: "none",
            touchAction: "none",
            width: "calc(100% - 302px)",
            height: "100%",
            cursor: "auto",
          }}
        >
          This text is displayed if your browser does not support the Canvas HTML element.
        </canvas>
      </div>
    </div>
  );
};

export default Gantt;
