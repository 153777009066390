import IntlMessages from "@crema/utility/IntlMessages";
import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import { useEffect, useState } from "react";
import { textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import EditRelationshipModal from "../EditRelationShipModal";
import AddIntegrationComponentModal from "./AddIntegrationComponentModal";

const Container = styled(Box)(({ theme }) => ({
  ".no-data-box": {
    border: "1px solid rgba(0,0,0,0.12)",
    borderTop: "0",
    height: "45px",
  },
}));

const initColumns: Column[] = [
  {
    id: "taskName",
    labelText: "Component Products",
  },
  {
    id: "actions",
    labelText: "",
  },
];
const initRows: IntegrationComponentTableModel[] = [
  {
    id: 1,
    taskName: "SubSys1 Main(R1.0)",
  },
  {
    id: 2,
    taskName: "SubSys2 Main(R1.0)",
  },
  {
    id: 3,
    taskName: "SubSys3 Main(R1.0)",
  },
  {
    id: 4,
    taskName: "Risk Sys A Main(R1.0)",
  },
  {
    id: 5,
    taskName: "Risk Sys B Main(R1.0)",
  },
];
interface IntegrationComponentTableModel {
  id: number | string;
  taskName: string;
}
interface IntegrationComponentTableProps {
  currentProductName: string;
}

const IntegrationComponentTable = (props: IntegrationComponentTableProps) => {
  const { currentProductName } = props;
  const [columns, setColumns] = useState<Column[]>([]);

  const [open, setOpen] = useState<boolean>(false);

  const [rows, setRows] = useState<IntegrationComponentTableModel[]>([]);

  const [editOpen, setEditOpen] = useState(false);

  const [editData, setEditData] = useState<IntegrationComponentTableModel>();

  const handleAddModalClose = () => {
    setOpen(false);
  };

  const handleEditClick = (value) => {
    setEditData(value);
    setEditOpen(true);
    console.log(value);
  };
  const handleDeleteClick = (value) => {
    console.log(value);
  };
  useEffect(() => {
    const nColumns: Column[] = [];
    initColumns.forEach((x) => {
      if (x.id === "actions") {
        nColumns.push({
          ...x,
          format: (value: any, record: any) => {
            return (
              <Box>
                <IconButton
                  sx={{ padding: "2px" }}
                  onClick={() => {
                    handleEditClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:edit"}
                  </AppSvgIcon>
                </IconButton>
                <IconButton
                  sx={{ padding: "2px" }}
                  onClick={() => {
                    handleDeleteClick(record);
                  }}
                >
                  <AppSvgIcon size={16} color={textColor["65"]}>
                    {"material-solid:delete"}
                  </AppSvgIcon>
                </IconButton>
              </Box>
            );
          },
        });
      } else {
        nColumns.push(x);
      }
    });
    setColumns(nColumns);
    setRows(initRows);
  }, []);
  return (
    <Container>
      <CustomTable
        columns={columns}
        rows={rows}
        showPage={false}
        addRow={() => {
          console.log("add row");
          setOpen(true);
        }}
        addRowTextId="common.new"
      />
      <AddIntegrationComponentModal open={open} currentProductId={1} handleClose={handleAddModalClose} />

      {editOpen && editData && (
        <EditRelationshipModal
          open={editOpen}
          integrationComponentProductId={editData.id}
          integrationComponentProductName={editData.taskName}
          integratedProductId={1}
          integratedProductName={currentProductName}
          handleClose={() => {
            setEditOpen(false);
          }}
        />
      )}
    </Container>
  );
};
export default IntegrationComponentTable;
