import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AppsIcon from "@mui/icons-material/Apps";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Box, IconButton, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import ButtonMenu from "components/ButtonMenu";
import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import MyTree from "components/MyTree";
import { VersionList } from "mock/VersionList";
import React from "react";
import { useIntl } from "react-intl";
import { bgColor, borderColor, boxShadow, textColor } from "shared/constants/AppConst";

import ToolBar from "./ToolBar";
import Tree from "./Tree";
import Worksheet from "./Worksheet";

const menuList: ButtonMenuListItemModel[] = [
  {
    id: "5",
    labelId: "common.templates",
    icon: <AccountBalanceIcon />,
  },
  {
    id: "1",
    labelId: "menu.product",
    icon: <AppSettingsAltIcon />,
  },
];

const templates: any[] = [
  {
    value: "Templates",
    id: "Templates",
    items: [
      {
        id: "Thermometer_parent",
        value: "Thermometers",
        items: [
          {
            id: "Thermometers",
            value: "Thermometers",
            items: [
              {
                id: "t_main",
                value: "Main",
                items: [
                  {
                    id: "t_main_EU",
                    value: "EU",
                    items: [
                      {
                        id: "t_mian_EU_R2.1",
                        value: "R2.1",
                      },
                      {
                        id: "t_mian_EU_R2.2",
                        value: "R2.2",
                      },
                    ],
                  },
                  {
                    id: "t_main_China",
                    value: "China",
                    items: [
                      {
                        id: "t_mian_china_R3.1",
                        value: "R3.1",
                      },
                      {
                        id: "t_mian_china_R3.2",
                        value: "R3.2",
                      },
                      {
                        id: "t_mian_china_R3.3",
                        value: "R3.3",
                      },
                    ],
                  },
                  {
                    id: "t_main_NextGen",
                    value: "US",
                    items: [
                      {
                        id: "t_mian_NextGen_R4.1",
                        value: "R4.1",
                      },
                      {
                        id: "t_mian_NextGen_R4.2",
                        value: "R4.2",
                      },
                    ],
                  },
                  {
                    id: "t_main_R2.0",
                    value: "R2.0",
                  },
                  {
                    id: "t_main_R3.0",
                    value: "R3.0",
                  },
                  {
                    id: "t_main_R4.0",
                    value: "R4.0",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "General",
        value: "General",
        items: [
          {
            id: "Drug Delivery Device",
            value: "Drug Delivery Device",
            items: [
              {
                id: "R1.1",
                value: "R1.1",
              },
              {
                id: "R1.2",
                value: "R1.2",
              },
              {
                id: "R2.0",
                value: "R2.0",
              },
            ],
          },
        ],
      },
      {
        id: "Infusion Pumps",
        value: "Infusion Pumps",
        items: [
          {
            id: "Infusion Pump",
            value: "Infusion Pump",
            items: [
              {
                id: "R1.0",
                value: "R1.0",
              },
              {
                id: "R1.1_p",
                value: "R1.1",
              },
              {
                id: "R1.2_p",
                value: "R1.2",
              },
            ],
          },
        ],
      },
      {
        id: "On Body Injectors",
        value: "On Body Injectors",
        items: [
          {
            id: "On Body Injector",
            value: "On Body Injector",
          },
        ],
      },
      {
        id: "On Body Pumps",
        value: "On Body Pumps",
        items: [
          {
            id: "On Body Pump",
            value: "On Body Pump",
          },
        ],
      },
      {
        id: "Pen Injectors",
        value: "Pen Injectors",
        items: [
          {
            id: "Pen Injector",
            value: "Pen Injector",
          },
        ],
      },
      {
        id: "Prefilled Syringes",
        value: "Prefilled Syringes",
        items: [
          {
            id: "Prefilled Syringe",
            value: "Prefilled Syringe",
          },
        ],
      },
    ],
  },
];

const DeliverableContainer = styled(Box)(({ theme }) => ({
  padding: "16px",
  paddingTop: 0,
  background: "#FAFAFA",
  ".toolbar-container": {
    width: "100%",
    display: "flex",
  },
  ".deliverable-content": {
    display: "flex",
    // marginTop: "12px",
    border: `1px solid ${borderColor.dividerColor}`,
  },
  ".deliverable-content-template-left": {
    width: "20%",
    height: "575px",
    borderRight: `1px solid ${borderColor.dividerColor}`,
    boxShadow: boxShadow.cardBoxShadow,
  },
  ".deliverable-content-template-middle": {
    width: "20%",
    height: "575px",
    background: theme.palette.background.paper,
    borderRight: `1px solid ${borderColor.dividerColor}`,
    boxShadow: boxShadow.cardBoxShadow,
  },
  ".deliverable-content-template-right": {
    width: "60%",
    height: "575px",
    padding: theme.spacing(3),
    background: bgColor.main,
  },
}));

interface DeliverableProps {}

export default function Deliverable(props: DeliverableProps) {
  const { messages } = useIntl();
  const [selectedValue, setSelectedValue] = React.useState<ButtonMenuListItemModel>(menuList[0]);
  const [selected, setSelected] = React.useState<any>("");
  const [selectProcess, setSelectProcess] = React.useState<any>(null);
  const [layoutType, setLayoutType] = React.useState(1); // 1---excelTable   2---treeGrid
  const [taskName, setTaskName] = React.useState("DDD-TK#001");
  const [version, setVersion] = React.useState(VersionList[0]?.id);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    if (selectProcess?.id === "User Needs" || selectProcess?.id === "Test Cases") {
      setLayoutType(2);
    } else {
      setLayoutType(1);
    }
  }, [selectProcess]);

  const handleVersionChange = (version) => {
    setVersion(version);
  };

  const toolbar = React.useMemo(() => {
    if (!version) {
      return <></>;
    }
    return (
      <ToolBar
        versionId={version}
        versionLists={VersionList}
        taskName={taskName}
        processName={selectProcess?.value || ""}
        onVersionChange={handleVersionChange}
      />
    );
  }, [version, taskName, selectProcess]);

  const onNodeSelect = (event: object, value: string) => {
    const currentItem = getTreeItem(templates, value);
    if (!currentItem?.items) {
      setSelected(value);
    }
  };

  const getTreeItem = (tree: any, id) => {
    let res;
    let state = false;
    function readTree(tree, id) {
      if (state) return;
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].id === id) {
          state = true;
          res = tree[i];
        } else {
          if (tree[i].items && tree[i].items.length > 0) {
            readTree(tree[i].items, id);
          }
        }
      }
    }
    readTree(tree, id);
    return res;
  };

  return (
    <DeliverableContainer>
      <Box
        className="w-full flex items-center"
        sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
      >
        <h3>
          <IntlMessages id="common.select.source.deliverable" />
        </h3>
      </Box>
      <Box className="deliverable-content">
        <Box className="deliverable-content-template-left">
          <Box
            sx={{
              my: (theme) => theme.spacing(3),
              mx: (theme) => theme.spacing(2),
            }}
          >
            <ButtonMenu
              selectedValue={selectedValue}
              setSelectedValue={(item) => {
                setSelectedValue(item);
                setSelected("");
              }}
              list={menuList}
              sxStyle={{
                variant: "outlined",
                sx: {
                  color: textColor[85],
                  border: `solid 1px ${borderColor.dividerColor}`,
                  width: "100%",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              pb: (theme) => theme.spacing(3),
              borderBottom: `solid 1px ${borderColor.dividerColor}`,
              mx: (theme) => theme.spacing(2),
            }}
          >
            <TextField
              variant="outlined"
              value={searchText}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value)}
              placeholder={String(messages["common.search"])}
              InputProps={{
                startAdornment: (
                  <AppSvgIcon size={16} color="action">
                    {"material-outline:search"}
                  </AppSvgIcon>
                ),
                endAdornment: (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      style={{ visibility: searchText ? "visible" : "hidden" }}
                      onClick={() => {
                        setSearchText("");
                      }}
                    >
                      <AppSvgIcon size={16} color="action">
                        {"material-outline:clear"}
                      </AppSvgIcon>
                    </IconButton>
                  </Box>
                ),
              }}
              sx={{
                width: {
                  xs: 1,
                },
                "& .MuiIconButton-root": {
                  top: 0,
                  right: 0,
                },
                "& .MuiInputBase-root": {
                  padding: "0px 12px",
                  height: "32px",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "0px",
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 1,
                  borderColor: "divider",
                },
              }}
            />
          </Box>
          {selectedValue.id === "5" && (
            <AppScrollbar
              sx={{
                width: "100%",
                height: "450px",
                padding: "12px 0px 0px 8px",
                background: "#FAFAFA",
              }}
            >
              <MyTree
                selected={selected}
                onNodeSelect={onNodeSelect}
                data={templates}
                sx={{
                  ".not-last-child>.MuiTreeItem-content>.MuiTreeItem-label": {
                    fontWeight: "bold",
                  },
                }}
              />
            </AppScrollbar>
          )}
        </Box>
        <Box className="deliverable-content-template-middle">
          {selected && <Tree handleSelectProcess={setSelectProcess} />}
        </Box>
        <Box className="deliverable-content-template-right">
          {toolbar}
          <AppScrollbar
            sx={{
              maxHeight: "calc(100% - 40px)",
            }}
          >
            <Worksheet id={selectProcess?.id} layoutType={layoutType} />
          </AppScrollbar>
        </Box>
      </Box>
    </DeliverableContainer>
  );
}
