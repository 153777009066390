import AppNavLink from "@crema/core/AppNavLink";
import { useSidebarContext } from "@crema/utility/AppContextProvider/SidebarContextProvider";
import IntlMessages from "@crema/utility/IntlMessages";
import { Icon, ListItem, ListItemText } from "@mui/material";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import clsx from "clsx";
import { RouterConfigData } from "pages/routesConfig";
import React from "react";
import { useLocation } from "react-router-dom";
interface HorizontalItemProps {
  item: RouterConfigData;
  nestedLevel?: number;
  dense?: boolean;
}

const HorizontalItem: React.FC<HorizontalItemProps> = (props) => {
  const { item, dense } = props;

  const location = useLocation();
  const active = isUrlInChildren(item, location.pathname);
  const { sidebarMenuSelectedBgColor, sidebarMenuSelectedTextColor } = useSidebarContext();

  function isUrlInChildren(parent: RouterConfigData, url: string) {
    if (!parent.children) {
      return false;
    }

    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].children) {
        if (isUrlInChildren(parent.children[i], url)) {
          return true;
        }
      }

      if (parent.children[i].url === url || url.includes(parent!.children![i].url!)) {
        return true;
      }
    }

    return false;
  }

  return (
    <ListItem
      component={AppNavLink}
      to={item.url}
      activeClassName="active"
      className={clsx("navItemSubmenu", dense && "dense")}
      exact={item.exact}
      sx={{
        minHeight: 56,
        padding: "4px 16px",
        marginLeft: "16px",
        // margin: "0 12px",
        fontSize: 16,
        fontWeight: 500,
        // color: (theme) => theme.palette.text.primary,
        color: (theme) => alpha("#FFF", 0.45), //theme.palette.text.primary"",
        textDecoration: "none!important",
        width: "auto",
        ".MuiIcon-root": {
          margin: 0,
        },
        "&.active": {
          fontWeight: "bold",
          backgroundColor: "#494B4E",
          sidebarMenuSelectedBgColor,
          // borderBottom: (theme) => `3px solid ${theme.palette.primary.main}`,
          color: "#FFFFFF", //(theme) => theme.palette.primary.main, //"#5c9cff",//
          // pointerEvents: "none",
          "& .list-item-text-primary": {
            color: "inherit",
          },
          "& .list-item-icon": {
            color: "inherit",
          },
        },
        "& .list-item-text": {
          padding: "0 0 0 16px",
        },
        "&.dense": {
          padding: "4px 12px",
          minHeight: 40,
          "& .list-item-text": {
            padding: "0 0 0 8px",
          },
        },
        "& .MuiListItemText-primary": {
          fontSize: 16,
        },
      }}
    >
      {item.icon && (
        <Icon
          sx={{
            color: active ? sidebarMenuSelectedTextColor : "action",
            mr: 3,
            fontSize: { xs: 16, xl: 18 },
          }}
        >
          {item.icon}
        </Icon>
      )}
      <ListItemText
        className="AppNavLinkTextSubmenu uppercase"
        primary={item.messageId && <IntlMessages id={item.messageId} />}
      />
      {item.count && (
        <Box ml={4}>
          <Badge
            badgeContent={item.count}
            sx={{
              color: item.color,
            }}
          />
        </Box>
      )}
    </ListItem>
  );
};

export default HorizontalItem;
