import React from "react";

import Dashboard from "./Dashboard";

// const Workspace = React.lazy(() => import("./Workspace"));
export const workspacePage = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
];
