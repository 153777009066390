import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import NoDataIcon from "components/Icons/NoDataIcon";
import React, { useMemo, useState } from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";
import { CollapseColumn, Column } from "types/models/Common";

import MyTableRow from "./MyTableRow";

const Container = styled(Box)(({ theme }) => ({
  ".my_collapse_table_container": {
    width: "100%",
  },
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell": {
    border: `1px solid  ${borderColor.dividerColor}`,
    padding: "10px 12px",
    lineHeight: "20px",
  },
  ".table_cell.collapse_open": {
    position: "relative",
  },
  ".table_cell.collapse_td": {
    padding: theme.spacing(0),
    ".MuiCollapse-root": {
      padding: theme.spacing(5),
      background: bgColor.collapseBackground,
    },
  },
}));

interface RowProps {
  renderChildren?: () => React.ReactNode;
}

type RowsType<T> = T & RowProps;

interface MyCollapseTableProps<T> {
  columns: CollapseColumn[];
  rows: T[];
  renderChildren?: (row: T) => React.ReactNode;
  showPage?: boolean;
  toolbar?: React.ReactNode;
  onOpen?: (row: RowsType<T>) => void;
  collapse?: boolean;
  className?: string;
}

const genericColumns = (columns: CollapseColumn[]) => {
  return (
    <TableHead sx={{ background: "#F0F0F0", color: "rgba(0,0,0,0.85)", fontSize: "14px" }}>
      <TableRow key={"header_columns"} className="table_header">
        {columns.map((x) => {
          return (
            <TableCell
              className="table_cell table_header_cell"
              key={`${x.id}_in_header`}
              align={x.align ? x.align : "left"}
            >
              {/* {x.label} */}
              {x.label ? <IntlMessages id={x.label} /> : x.labelText}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const MyCollapseTable = <T extends any>(props: MyCollapseTableProps<T>) => {
  const { showPage = true, toolbar, onOpen, collapse, className, renderChildren } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { columns, rows } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const tableHeader = useMemo(() => {
    if (!columns) {
      return <></>;
    }

    return genericColumns(columns);
  }, [columns]);

  const tableRows = useMemo(() => {
    if (!rows || !columns) {
      return <></>;
    }
    return (
      <TableBody>
        {rows.map((row, index) => (
          <MyTableRow
            defaultOpen={index === 0}
            key={`MyTableRow_${row["id"]}`}
            row={row}
            columns={columns}
            renderChildren={renderChildren}
            onOpen={onOpen}
            collapse={collapse}
          />
        ))}
      </TableBody>
    );
  }, [rows, columns, onOpen, collapse, renderChildren]);

  return (
    <Box className={className}>
      <Container>
        <TableContainer className="my_collapse_table_container">
          {toolbar}
          <Table aria-label="collapsible table">
            {tableHeader}
            {tableRows}
          </Table>
          <Box
            sx={{
              display: rows.length > 0 ? "none" : "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 350,
              width: "100%",
            }}
          >
            <NoDataIcon />
          </Box>
          {showPage && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </Container>
    </Box>
  );
};

export default MyCollapseTable;
