import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import {
  Box,
  Button,
  Card,
  Drawer,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import { ListModel } from "components/CustomTable/CustomTableModel";
import TableToolBar from "components/CustomTable/TableToolBar";
import ExportDropdown from "components/ExportDropdown";
import CustomizeIcon from "components/Icons/CustomizeIcon";
import { taskData } from "mock/index";
import { NextStatus } from "mock/NextStatus";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { handleLeftTreeOpen } from "redux/actions";
import { AppState } from "redux/store";
import { bgColor, borderColor, boxShadow, textColor } from "shared/constants/AppConst";
import { WorkItemStatus } from "shared/constants/AppEnums";

import WorkflowModal from "../Report/WorkflowModal";
import Activities from "./Activities";
import Analysis from "./Analysis";
import AssuranceCase from "./AssuranceCase";
import Comments from "./Comments";
import Comparison from "./Comparison";
import DataSources from "./DataSources";
import Deliverables from "./Deliverables";
import Details from "./Details";
import RequireDetails from "./Details/RequireDetails";
import ExcelTable from "./ExcelTable";
import Interfaces from "./Interfaces";
import Metrics from "./Metrics";
import MyTree from "./MyTree";
import MyTreeGrid from "./MyTreeGrid";
import Relationships from "./Relationships";
import Risks from "./Risks";
import SettingsModal from "./SettingsModal";
import Standards from "./Standards";
import ToolBar from "./ToolBar";
import TreeTableView from "./TreeTableView";
import HistoryModal from "./WorkItemToolBar/HistoryModal";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: "8px",
  background: "#eff0f2",
  padding: theme.spacing(1),
  "& .MuiToggleButtonGroup-grouped": {
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    border: "none",
    "&:last-of-type": {
      marginRight: theme.spacing(0),
    },
  },
  "& .MuiToggleButton-root.Mui-selected, & .MuiToggleButton-root:hover": {
    color: textColor[85],
    background: theme.palette.background.paper,
  },
}));

const WorksheetContainer = styled(Card)(({ theme }) => ({
  height: "calc(100vh - 190px)",

  ".worksheet-content": {
    marginTop: "8px",
    display: "flex",
    height: "calc(100% - 62px)",
    overflow: "hidden",
  },
  ".tree-container": {
    height: "100%",
    overflowY: "auto",
    borderRight: `1px solid  ${borderColor.dividerColor}`,
    backgroundColor: "#FFF",
  },
  ".worksheet-content-bottom": {
    minWidth: "1040px",
    padding: theme.spacing(5),
    boxShadow: boxShadow.cardBoxShadow,
    background: theme.palette.background.paper,
  },
  ".worksheet-content-right": {
    minWidth: "1040px",
    padding: theme.spacing(5),
    boxShadow: boxShadow.cardBoxShadow,
    background: theme.palette.background.paper,
  },
  ".bottom-arrow": {
    position: "fixed",
    background: "#dfe1e6",
    width: "48px",
    height: "16px",
    bottom: 0,
    transform: "translateX(-50%)",
    borderTopRightRadius: "50%",
    borderTopLeftRadius: "50%",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
  },
  ".bottom-arrow-down": {
    position: "absolute",
    background: "#dfe1e6",
    width: "48px",
    height: "16px",
    bottom: "calc(50% - 7px)",
    transform: "translateX(-50%)",
    borderTopRightRadius: "50%",
    borderTopLeftRadius: "50%",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
  },
  ".menu_fixed_right": {
    position: "fixed",
    width: "32px",
    height: "calc(100vh - 186px)",
    background: "#DCE0E6",
    right: 0,
    bottom: 20,
    zIndex: 100000,
    padding: "4px",
    ".menu_item": {
      width: "28px",
      writingMode: "vertical-rl",
      fontSize: "14px",
      fontFamily: "Roboto",
      padding: "7px",
      color: textColor[65],
      cursor: "pointer",
    },
    ".selected": {
      background: "#FFF",
      color: textColor[85],
      fontWeight: "bold",
    },
  },
  ".menu_fixed_bottom": {
    position: "fixed",
    height: "32px",
    background: "#DCE0E6",
    right: 20,
    bottom: 0,
    zIndex: 100000,
    padding: "4px",
    ".menu_item": {
      height: "28px",
      display: "inline-block",
      fontSize: "14px",
      fontFamily: "Roboto",
      padding: "7px",
      color: textColor[65],
      cursor: "pointer",
    },
    ".selected": {
      background: "#FFF",
      color: textColor[85],
      fontWeight: "bold",
    },
  },
}));
const Worksheet = () => {
  const routeParam = useParams();
  const navigate = useNavigate();
  const { messages } = useIntl();
  const [itemAnalysisMenu, setItemAnalysisMenu] = React.useState([
    {
      id: "1",
      label: "Work Item Analysis",
      children: [
        {
          id: "11",
          label: "Details",
        },
        {
          id: "12",
          label: "Impact Analysis",
        },
        {
          id: "13",
          label: "Fault Tree Analysis",
        },
        {
          id: "14",
          label: "Related Risks",
        },
        {
          id: "15",
          label: "Assurance Case",
        },
        {
          id: "16",
          label: "Tree Table View",
        },
      ],
    },
  ]);
  const [insightMenu, setInsightMenu] = React.useState([
    {
      id: "2",
      label: "Perspectives",
      children: [
        {
          id: "21",
          label: "Data Sources",
        },
        {
          id: "22",
          label: "Metrics",
        },
        {
          id: "23",
          label: "Comparison",
        },
        {
          id: "24",
          label: "Comments",
        },
        {
          id: "25",
          label: "Activities",
        },
      ],
    },
    {
      id: "3",
      label: "Resources",
      children: [
        {
          id: "31",
          label: "Deliverables",
        },
        {
          id: "32",
          label: "Interfaces",
        },
        {
          id: "33",
          label: "Standards",
        },
      ],
    },
  ]);
  const dispatch = useDispatch();
  const { leftTreeOpen } = useSelector<AppState, AppState["products"]>(({ products }) => products);
  const [layout, setLayout] = React.useState("");
  const [bottomOpen, setBottomOpen] = React.useState(false);
  const [rightOpen, setRightOpen] = React.useState(false);
  const [layoutType, setLayoutType] = React.useState(1); // 1---excelTable   2---treeGrid
  const [selectedValue, setSelectedValue] = React.useState({ id: "11", label: "Details" });
  const [selectedRightValue, setSelectedRightValue] = React.useState({ id: "", label: "" });
  const [deliverableType, setDeliverableType] = React.useState({
    id: "1",
    labelId: "common.worksheet",
  });
  const [deliverablesProduct, setDeliverablesProduct] = React.useState("");
  const [deliverablesVersion, setDeliverablesVersion] = React.useState("");
  const [dataSourceType, setDataSourceType] = React.useState<any>("");
  const [rmType, setRmType] = React.useState("1");
  const [processData, setProcessData] = React.useState<any>(null);
  const [searchText, setSearchText] = React.useState("");
  const [filter1, setFilter1] = React.useState<any>("");
  const [filterList1] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter1",
    },
  ]);
  const [mockData, setMockData] = React.useState(null);
  const [openSettingsModal, setOpenSettingsModal] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState(WorkItemStatus.Draft.toString());
  const [showHistoryModal, setShowHistoryModal] = React.useState<boolean>(false);
  const [showWorkflowModal, setShowWorkflowModal] = React.useState<boolean>(false);
  const [filter2, setFilter2] = React.useState<any>("");
  const [filterList2] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter2",
    },
  ]);
  // const [showMenu, setShowMenu] = React.useState<boolean>(false);

  // React.useEffect(() => {
  //   window.addEventListener("mousemove", (e) => {
  //     const pos = mousePos(e);
  //     if (
  //       (document.body.clientWidth - pos.x <= 32 && layout !== "up_down") ||
  //       (document.body.clientHeight - pos.y <= 32 && layout === "up_down")
  //     ) {
  //       setShowMenu(true);
  //     } else {
  //       setShowMenu(false);
  //     }
  //   });
  //   return () => {
  //     window.removeEventListener("mousemove", (e) => {
  //       const pos = mousePos(e);
  //       if (
  //         (document.body.clientWidth - pos.x <= 32 && layout !== "up_down") ||
  //         (document.body.clientHeight - pos.y <= 32 && layout === "up_down")
  //       ) {
  //         setShowMenu(true);
  //       } else {
  //         setShowMenu(false);
  //       }
  //     });
  //   };
  // }, [layout]);

  // const mousePos = (e) => {
  //   e = e || window.event;
  //   var scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
  //   var scrollY = document.documentElement.scrollTop || document.body.scrollTop;
  //   var x = e.pageX || e.clientX + scrollX;
  //   var y = e.pageY || e.clientY + scrollY;
  //   return { x: x, y: y };
  // };

  React.useEffect(() => {
    sessionStorage.setItem("layout", layout);
    sessionStorage.setItem("openSettingModal", openSettingsModal ? "1" : "0");
    sessionStorage.setItem("dataSourceTypeId", dataSourceType?.id);
    sessionStorage.setItem("deliverablesVersion", deliverablesVersion);
    sessionStorage.setItem("deliverablesProduct", deliverablesProduct);
    sessionStorage.setItem("deliverableTypeId", deliverableType.id);
    sessionStorage.setItem("rmType", rmType);
    sessionStorage.setItem("selectedRightValueId", selectedRightValue.id);
    sessionStorage.setItem("selectedValueId", selectedValue.id);
    sessionStorage.setItem("bottomOpen", bottomOpen ? "1" : "0");
    sessionStorage.setItem("rightOpen", rightOpen ? "1" : "0");
  }, [
    dataSourceType,
    deliverablesVersion,
    deliverablesProduct,
    deliverableType,
    rmType,
    selectedRightValue,
    selectedValue,
    bottomOpen,
    rightOpen,
    layout,
    openSettingsModal,
  ]);

  React.useEffect(() => {
    if (leftTreeOpen && layout === "left_right") {
      setLayout("up_down");
      setRightOpen(false);
      setBottomOpen(true);
    }
  }, [layout, leftTreeOpen]);

  React.useEffect(() => {
    switch (routeParam.type) {
      case "sha":
        setItemAnalysisMenu([
          {
            id: "1",
            label: "Work Item Analysis",
            children: [
              {
                id: "11",
                label: "Details",
              },
              {
                id: "12",
                label: "Impact Analysis",
              },
              {
                id: "13",
                label: "Fault Tree Analysis",
              },
              {
                id: "14",
                label: "Related Risks",
              },
              {
                id: "15",
                label: "Assurance Case",
              },
              {
                id: "16",
                label: "Tree Table View",
              },
            ],
          },
        ]);
        setInsightMenu([
          {
            id: "2",
            label: "Perspectives",
            children: [
              {
                id: "21",
                label: "Data Sources",
              },
              {
                id: "22",
                label: "Metrics",
              },
              {
                id: "23",
                label: "Comparison",
              },
              {
                id: "24",
                label: "Comments",
              },
              {
                id: "25",
                label: "Activities",
              },
            ],
          },
          {
            id: "3",
            label: "Resources",
            children: [
              {
                id: "31",
                label: "Deliverables",
              },
              {
                id: "32",
                label: "Interfaces",
              },
              {
                id: "33",
                label: "Standards",
              },
              // {
              //   id: "34",
              //   label: "Library",
              // },
            ],
          },
        ]);
        setLayoutType(1);
        break;
      case "und":
        setItemAnalysisMenu([
          {
            id: "1",
            label: "Work Item Analysis",
            children: [
              {
                id: "11",
                label: "Details",
              },
              {
                id: "12",
                label: "Impact Analysis",
              },
            ],
          },
        ]);
        setInsightMenu([
          {
            id: "2",
            label: "Perspectives",
            children: [
              {
                id: "22",
                label: "Metrics",
              },
              {
                id: "23",
                label: "Comparison",
              },
              {
                id: "24",
                label: "Comments",
              },
              {
                id: "25",
                label: "Activities",
              },
            ],
          },
          {
            id: "3",
            label: "Resources",
            children: [
              {
                id: "31",
                label: "Deliverables",
              },
              {
                id: "33",
                label: "Standards",
              },
            ],
          },
        ]);
        setLayoutType(2);
        break;
      case "dir":
        setItemAnalysisMenu([
          {
            id: "1",
            label: "Work Item Analysis",
            children: [
              {
                id: "11",
                label: "Details",
              },
              {
                id: "12",
                label: "Impact Analysis",
              },
            ],
          },
        ]);
        setInsightMenu([
          {
            id: "2",
            label: "Perspectives",
            children: [
              {
                id: "21",
                label: "Data Sources",
              },
              {
                id: "22",
                label: "Metrics",
              },
              {
                id: "23",
                label: "Comparison",
              },
              {
                id: "24",
                label: "Comments",
              },
              {
                id: "25",
                label: "Activities",
              },
            ],
          },
          {
            id: "3",
            label: "Resources",
            children: [
              {
                id: "31",
                label: "Deliverables",
              },
              {
                id: "33",
                label: "Standards",
              },
            ],
          },
        ]);
        setLayoutType(1);
        break;
      case "tc":
        setItemAnalysisMenu([
          {
            id: "1",
            label: "Work Item Analysis",
            children: [
              {
                id: "11",
                label: "Details",
              },
              {
                id: "12",
                label: "Impact Analysis",
              },
            ],
          },
        ]);
        setInsightMenu([
          {
            id: "2",
            label: "Perspectives",
            children: [
              {
                id: "22",
                label: "Metrics",
              },
              {
                id: "23",
                label: "Comparison",
              },
              {
                id: "24",
                label: "Comments",
              },
              {
                id: "25",
                label: "Activities",
              },
            ],
          },
          {
            id: "3",
            label: "Resources",
            children: [
              {
                id: "31",
                label: "Deliverables",
              },
              {
                id: "33",
                label: "Standards",
              },
            ],
          },
        ]);
        setLayoutType(2);
        break;
      case "tm":
        setItemAnalysisMenu([
          {
            id: "1",
            label: "Work Item Analysis",
            children: [
              {
                id: "11",
                label: "Details",
              },
              {
                id: "12",
                label: "Impact Analysis",
              },
            ],
          },
        ]);
        setInsightMenu([
          {
            id: "2",
            label: "Perspectives",
            children: [
              {
                id: "21",
                label: "Data Sources",
              },
              {
                id: "22",
                label: "Metrics",
              },
              {
                id: "23",
                label: "Comparison",
              },
              {
                id: "24",
                label: "Comments",
              },
              {
                id: "25",
                label: "Activities",
              },
            ],
          },
          {
            id: "3",
            label: "Resources",
            children: [
              {
                id: "31",
                label: "Deliverables",
              },
              {
                id: "33",
                label: "Standards",
              },
            ],
          },
        ]);
        setLayoutType(1);
        break;
      default:
        setItemAnalysisMenu([
          {
            id: "1",
            label: "Work Item Analysis",
            children: [
              {
                id: "11",
                label: "Details",
              },
              {
                id: "12",
                label: "Impact Analysis",
              },
              {
                id: "13",
                label: "Fault Tree Analysis",
              },
              {
                id: "14",
                label: "Related Risks",
              },
              {
                id: "15",
                label: "Assurance Case",
              },
              {
                id: "16",
                label: "Tree Table View",
              },
            ],
          },
        ]);
        setInsightMenu([
          {
            id: "2",
            label: "Perspectives",
            children: [
              {
                id: "21",
                label: "Data Sources",
              },
              {
                id: "22",
                label: "Metrics",
              },
              {
                id: "23",
                label: "Comparison",
              },
              {
                id: "24",
                label: "Comments",
              },
              {
                id: "25",
                label: "Activities",
              },
            ],
          },
          {
            id: "3",
            label: "Resources",
            children: [
              {
                id: "31",
                label: "Deliverables",
              },
              {
                id: "32",
                label: "Interfaces",
              },
              {
                id: "33",
                label: "Standards",
              },
              // {
              //   id: "34",
              //   label: "Library",
              // },
            ],
          },
        ]);
        setLayoutType(1);
        break;
    }
    if (routeParam?.type === "sha" || routeParam.type === "df" || routeParam.type === "ura") {
      setDataSourceType({
        id: "1",
        label: "Hazard Category",
      });
    } else {
      setDataSourceType({
        id: "6",
        label: "Design Input Requirements",
      });
    }
    routeParam?.type && setMockData(taskData[routeParam.type]);
  }, [routeParam.type]);

  React.useEffect(() => {
    if (insightMenu.length > 0 && itemAnalysisMenu.length > 0) {
      // setSelectedRightValue(insightMenu[0].children[0]);
      setSelectedValue(itemAnalysisMenu[0].children[0]);
    }
  }, [insightMenu, itemAnalysisMenu]);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
  };

  const getSelectedPage = useCallback(() => {
    switch (selectedValue.id) {
      case "11":
        return routeParam.type === "sha" || routeParam.type === "df" || routeParam.type === "ura" ? (
          <Details />
        ) : (
          <RequireDetails type={routeParam.type} />
        );
      case "12":
        return <Relationships />;
      case "13":
        return <Analysis />;
      case "14":
        return <Risks />;
      case "15":
        return <AssuranceCase />;
      case "16":
        return <TreeTableView />;
      default:
        return routeParam.type === "sha" || routeParam.type === "df" || routeParam.type === "ura" ? (
          <Details />
        ) : (
          <RequireDetails type={routeParam.type} />
        );
    }
  }, [selectedValue, routeParam]);

  const getSelectedRightPage = useCallback(() => {
    switch (selectedRightValue.id) {
      case "21":
        return <DataSources dataSourceType={dataSourceType} />;
      case "22":
        return <Metrics />;
      case "23":
        return <Comparison />;
      case "24":
        return <Comments />;
      case "25":
        return <Activities />;
      case "31":
        return (
          <Deliverables
            deliverablesVersion={deliverablesVersion}
            deliverablesProduct={deliverablesProduct}
            deliverableType={deliverableType}
            setProcessData={setProcessData}
            processData={processData}
          />
        );
      case "32":
        return <Interfaces rmType={rmType} />;
      case "33":
        return <Standards />;
      default:
        return <DataSources dataSourceType={dataSourceType} />;
    }
  }, [
    selectedRightValue.id,
    dataSourceType,
    deliverablesVersion,
    deliverablesProduct,
    deliverableType,
    processData,
    rmType,
  ]);

  const getTopContentStyle = () => {
    let sxObj = {};
    if (rightOpen) {
      sxObj = { width: "100%" };
      // return { width: "50%" };
    } else if (bottomOpen) {
      sxObj = { width: "100%", maxHeight: "calc(50vh - 85px)", overflow: "hidden" };
      // return { width: "100%", maxHeight: "calc(50vh - 85px)", overflow: "auto" };
    } else {
      sxObj = { width: "100%", maxHeight: "calc(100vh - 170px)", overflow: "hidden" };
      // return { width: "100%", maxHeight: "calc(100vh - 170px)", overflow: "auto" };
    }
    sxObj["& .spreadsheet-box"] = {
      height: "100%",
      "& #spreadsheet": {
        maxHeight: "calc(100% - 52px)",
      },
    };
    sxObj["& .tree-grid-box"] = {
      height: "100%",
      "& .tree-grid-layout-box": {
        maxHeight: "100%",
        "& .my-tree-table-box": {
          height: "100%",
          "& .my-tree-table-container": {
            height: "calc(100% - 52px)",
            "& .my_table_container": {
              height: "100%",
            },
          },
        },
      },
    };
    return sxObj;
  };

  const getWorksheetContainerStyles = (layoutType) => {
    if (layoutType === 2) {
      return { padding: "0px", background: bgColor.main };
    }
    return {
      background: bgColor.main,
      padding: "0px",
    };
  };

  const handleLayout = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (layout === "up_down" && newAlignment !== "up_down") {
      setBottomOpen(false);
    }
    if (newAlignment === "left_right") {
      setRightOpen(true);
      dispatch(handleLeftTreeOpen(false));
    } else if (newAlignment === "up_down") {
      setBottomOpen(true);
      setOpenSettingsModal(false);
      setRightOpen(false);
    } else {
      setRightOpen(false);
    }
    if (newAlignment && !selectedRightValue?.id) {
      setSelectedRightValue(insightMenu[0].children[0]);
      console.log("test");
    }
    if (!newAlignment) {
      setSelectedRightValue({ id: "", label: "" });
    }
    setLayout(newAlignment || "");
  };

  return (
    <WorksheetContainer sx={getWorksheetContainerStyles(layoutType)}>
      <AppScrollbar
        sx={{
          height: "calc(100vh - 190px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "relative",
            height: "calc(100vh - 190px)",
          }}
        >
          {layoutType === 2 && (
            <Box sx={{ width: rightOpen ? "15%" : "20%" }} className="tree-container">
              <MyTree />
            </Box>
          )}
          <Box
            className={layoutType === 2 ? "my-tree-grid-right" : "excel-table-center"}
            sx={
              layoutType === 2
                ? {
                    width: rightOpen ? "calc(55% - 20px)" : "calc(80% - 20px)",
                    padding: rightOpen ? "20px 20px 0px 20px" : "20px 20px 0 20px",
                    height: bottomOpen ? "calc(50% - 20px)" : "calc(100%)",
                    background: "#FFF",
                    overflow: "hidden",
                    ":hover": {
                      overflow: "auto",
                    },
                    marginLeft: "20px",
                  }
                : {
                    width: rightOpen ? "70%" : "100%",
                    padding: rightOpen ? "20px 20px 0px 20px" : "20px 20px 0 20px",
                    height: bottomOpen ? "calc(50% - 20px)" : "calc(100%)",
                    background: "#FFF",
                    overflow: "hidden",
                    ":hover": {
                      overflow: "auto",
                    },
                  }
            }
          >
            <Box className="tool-bar">
              {
                <TableToolBar
                  showSearch={true}
                  searchText={searchText}
                  requestSearch={requestSearch}
                  saveFilter={() => {
                    console.log("save filter");
                  }}
                  filters={[
                    {
                      value: filter1,
                      list: filterList1,
                      setValue: setFilter1,
                    },
                  ]}
                >
                  {
                    <>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={openSettingsModal}
                              onChange={() => {
                                if (openSettingsModal) {
                                  setBottomOpen(false);
                                } else {
                                  if (layout === "up_down") {
                                    setSelectedRightValue({ id: "", label: "" });
                                    setLayout("");
                                  }
                                  setBottomOpen(true);
                                }
                                setOpenSettingsModal(!openSettingsModal);
                              }}
                            />
                          }
                          label={String(messages["common.show.setting"])}
                        />
                      </FormGroup>
                      {layoutType === 2 && (
                        <Box>
                          <Button
                            sx={{
                              mr: 2,
                              color: "#2e6ccb",
                            }}
                            onClick={() => {}}
                            startIcon={<AddCircleIcon sx={{ fontSize: "16px !important" }} color="primary" />}
                          >
                            <IntlMessages id="common.add" />
                          </Button>
                          <Button
                            sx={{
                              color: "rgba(0,0,0,0.85)",
                            }}
                            onClick={() => {}}
                            startIcon={<CustomizeIcon />}
                          >
                            <IntlMessages id="common.customize" />
                          </Button>
                        </Box>
                      )}
                      <ExportDropdown exportToWord={() => {}} exportToExcel={() => {}} exportToPdf={() => {}} />
                      <StyledToggleButtonGroup
                        size="small"
                        value={layout}
                        exclusive
                        onChange={handleLayout}
                        aria-label="text alignment"
                      >
                        <ToggleButton value="">
                          <AppSvgIcon size="16px">material-outline:check_box_outline_blank</AppSvgIcon>
                        </ToggleButton>
                        <ToggleButton value="up_down">
                          <AppSvgIcon size="16px">material-outline:view_agenda</AppSvgIcon>
                        </ToggleButton>
                        <ToggleButton value="left_right">
                          <AppSvgIcon size="16px" sxStyle={{ transform: "rotate(90deg)" }}>
                            material-outline:view_agenda
                          </AppSvgIcon>
                        </ToggleButton>
                      </StyledToggleButtonGroup>
                    </>
                  }
                </TableToolBar>
              }
            </Box>
            <Box
              className="worksheet-content"
              sx={
                rightOpen
                  ? {
                      flexDirection: "row",
                      justifyContent: "space-between",
                      height: bottomOpen ? "calc(50% - 20px)" : "calc(100% - 62px)",
                    }
                  : { flexDirection: "column", height: bottomOpen ? "calc(50% - 20px)" : "calc(100% - 62px)" }
              }
            >
              <Box sx={getTopContentStyle()}>
                {layoutType === 2 ? (
                  <MyTreeGrid
                    bottomOpen={bottomOpen}
                    setSelectedValue={setSelectedValue}
                    setBottomOpen={setBottomOpen}
                    setOpenSettingsModal={setOpenSettingsModal}
                    setLayout={setLayout}
                    layout={layout}
                  />
                ) : (
                  <ExcelTable
                    bottomOpen={bottomOpen}
                    setSelectedValue={setSelectedValue}
                    setBottomOpen={setBottomOpen}
                    setOpenSettingsModal={setOpenSettingsModal}
                    setLayout={setLayout}
                    layout={layout}
                  />
                )}
              </Box>
            </Box>
            <Drawer
              sx={{
                width: "100%",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: rightOpen
                    ? layoutType === 1
                      ? "70%"
                      : "calc(55%)"
                    : leftTreeOpen
                    ? layoutType === 1
                      ? "80%"
                      : "calc(64% - 10px)"
                    : layoutType === 1
                    ? "100%"
                    : "calc(80% - 10px)",
                  padding: rightOpen && layoutType === 1 ? (theme) => theme.spacing(5, 0) : (theme) => theme.spacing(5),
                  marginLeft: rightOpen
                    ? layoutType === 1
                      ? "20px"
                      : "calc(15% + 20px)"
                    : leftTreeOpen
                    ? layoutType === 1
                      ? "20%"
                      : "calc(36% + 10px)"
                    : layoutType === 1
                    ? "0"
                    : "calc(20% + 10px)",
                  background: bgColor.main,
                },
              }}
              variant="persistent"
              anchor="bottom"
              open={bottomOpen && layout !== "up_down"}
            >
              <AppScrollbar
                sx={{
                  width: "100%",
                  height: "calc(50vh - 120px)",
                }}
              >
                <Box className="worksheet-content-bottom">
                  {openSettingsModal ? (
                    <>
                      <SettingsModal moduleType={routeParam.type} />
                    </>
                  ) : (
                    <>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ fontWeight: "bold" }}>
                          <EmojiPeopleOutlinedIcon sx={{ verticalAlign: "bottom" }} />
                          {mockData?.workItemAnalysis?.title
                            ? mockData.workItemAnalysis.title
                            : "S#254328 Incorrectly controlled delivery"}
                        </Box>
                        <Box sx={{ ml: 8, display: "flex", alignItems: "center" }}>
                          <Select
                            value={status}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setStatus(event.target.value);
                            }}
                            variant="outlined"
                            autoWidth
                            displayEmpty
                            disabled={false}
                          >
                            {NextStatus[status]?.length > 0 &&
                              NextStatus[status].map((statusEnum, index) => (
                                <MenuItem value={statusEnum.toString()} key={statusEnum.toString() + index}>
                                  {statusEnum.toString()}
                                </MenuItem>
                              ))}
                          </Select>
                          <Button
                            onClick={() => {
                              setShowHistoryModal(true);
                            }}
                            variant="outlined"
                            sx={{ mx: 2 }}
                          >
                            <IntlMessages id="common.change.history" />
                          </Button>
                          <Button
                            onClick={() => {
                              setShowWorkflowModal(true);
                            }}
                            variant="outlined"
                          >
                            <IntlMessages id="admin.nav.item.workflow" />
                          </Button>
                        </Box>
                      </Box>
                      <ToolBar
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        list={itemAnalysisMenu}
                        isTab={true}
                        dataSourceType={dataSourceType}
                        rmType={rmType}
                        deliverablesVersion={deliverablesVersion}
                        deliverablesProduct={deliverablesProduct}
                        deliverableType={deliverableType}
                        processData={processData}
                        setDataSourceType={setDataSourceType}
                        setRmType={setRmType}
                        setDeliverablesVersion={setDeliverablesVersion}
                        setDeliverablesProduct={setDeliverablesProduct}
                        setDeliverableType={setDeliverableType}
                      />
                      {getSelectedPage()}
                    </>
                  )}
                </Box>
              </AppScrollbar>
            </Drawer>
            <Drawer
              sx={
                layout === "up_down"
                  ? {
                      width: "100%",
                      flexShrink: 0,
                      "& .MuiDrawer-paper": {
                        width: rightOpen
                          ? layoutType === 1
                            ? "70%"
                            : "calc(55%)"
                          : leftTreeOpen
                          ? layoutType === 1
                            ? "80%"
                            : "calc(64% - 10px)"
                          : layoutType === 1
                          ? "100%"
                          : "calc(80% - 10px)",
                        padding: (theme) => theme.spacing(5),
                        marginLeft: rightOpen
                          ? layoutType === 1
                            ? "0"
                            : "15%"
                          : leftTreeOpen
                          ? layoutType === 1
                            ? "20%"
                            : "calc(36% + 10px)"
                          : layoutType === 1
                          ? "0"
                          : "calc(20% + 10px)",
                        background: bgColor.main,
                      },
                    }
                  : {
                      width: "100%",
                      flexShrink: 0,
                      "& .MuiDrawer-paper": {
                        width: layoutType === 1 ? "30%" : "30%",
                        height: "calc(100vh - 190px)",
                        padding: (theme) => theme.spacing(0, 5),
                        border: "none",
                        background: bgColor.main,
                        marginTop: "169px",
                      },
                    }
              }
              variant="persistent"
              anchor={layout === "up_down" ? "bottom" : "right"}
              open={rightOpen || (layout === "up_down" && bottomOpen)}
            >
              <AppScrollbar
                sx={{
                  width: "100%",
                  height: layout === "up_down" ? "calc(50vh - 120px)" : "calc(100vh - 190px)",
                }}
              >
                <Box className={layout === "up_down" ? "worksheet-content-bottom" : "worksheet-content-right"}>
                  <ToolBar
                    selectedValue={selectedRightValue}
                    setSelectedValue={setSelectedRightValue}
                    list={insightMenu}
                    dataSourceType={dataSourceType}
                    rmType={rmType}
                    deliverablesVersion={deliverablesVersion}
                    deliverablesProduct={deliverablesProduct}
                    deliverableType={deliverableType}
                    processData={processData}
                    setDataSourceType={setDataSourceType}
                    setRmType={setRmType}
                    setDeliverablesVersion={setDeliverablesVersion}
                    setDeliverablesProduct={setDeliverablesProduct}
                    setDeliverableType={setDeliverableType}
                  />
                  {getSelectedRightPage()}
                </Box>
              </AppScrollbar>
            </Drawer>
          </Box>
          <Box
            sx={{
              left: rightOpen
                ? layoutType === 1
                  ? "35%"
                  : "42.5%"
                : leftTreeOpen
                ? layoutType === 1
                  ? bottomOpen
                    ? "calc(50%)"
                    : "calc(60%)"
                  : bottomOpen
                  ? "calc(60%)"
                  : "calc(64% + 60px)"
                : layoutType === 1
                ? "50%"
                : "60%",
            }}
            className={bottomOpen ? "bottom-arrow-down" : "bottom-arrow"}
          >
            <AppSvgIcon
              onClick={() => {
                if (layout === "up_down") {
                  if (!selectedRightValue) {
                    setSelectedRightValue({ id: "21", label: "Data Sources" });
                  }
                } else {
                  if (!selectedValue) {
                    setSelectedValue({ id: "11", label: "Details" });
                  }
                }
                setBottomOpen(!bottomOpen);
                setOpenSettingsModal(false);
              }}
              color="action"
              size={16}
            >
              {bottomOpen ? "material-solid:keyboard_arrow_down" : "material-solid:keyboard_arrow_up"}
            </AppSvgIcon>
          </Box>
          <Box
            sx={{
              width: leftTreeOpen ? "calc(80% - 40px)" : "calc(100% - 40px)",
            }}
            className={layout === "up_down" ? "menu_fixed_bottom" : "menu_fixed_right"}
          >
            <Box>
              {insightMenu.map((item) => {
                if (item?.children && item.children.length > 0) {
                  return item.children.map((child) => {
                    return (
                      <Box
                        className={selectedRightValue.id === child.id ? "menu_item selected" : "menu_item"}
                        key={child.id}
                        onClick={() => {
                          if (layout !== "up_down") {
                            setLayout("left_right");
                            setRightOpen(true);
                            dispatch(handleLeftTreeOpen(false));
                          } else {
                            setBottomOpen(true);
                          }
                          if (selectedRightValue.id === child.id) {
                            setSelectedRightValue({ id: "", label: "" });
                            setRightOpen(false);
                            setBottomOpen(false);
                            setLayout("");
                          } else {
                            setSelectedRightValue(child);
                          }
                          // setShowMenu(false);
                        }}
                      >
                        {child.label}
                      </Box>
                    );
                  });
                }
                return "";
              })}
            </Box>
          </Box>
          {showHistoryModal && (
            <HistoryModal
              handleClose={() => {
                setShowHistoryModal(false);
              }}
              open={showHistoryModal}
            />
          )}
          {showWorkflowModal && (
            <WorkflowModal
              handleClose={() => {
                setShowWorkflowModal(false);
              }}
              open={showWorkflowModal}
            />
          )}
        </Box>
      </AppScrollbar>
    </WorksheetContainer>
  );
};

export default Worksheet;
