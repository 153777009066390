import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Badge, Box, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { bgColor, borderColor, boxShadow, textColor } from "shared/constants/AppConst";

import CheckItem from "./CheckItem";

const initRows = [
  {
    id: 1,
    questions: "Has the data from the CAPA system been reviewed for relevance?",
    checkboxes: false,
  },
  {
    id: 2,
    questions: "Have the FDA safety databases been reviewed for relevance?",
    checkboxes: false,
  },
  {
    id: 3,
    questions: "Have the complaints and field issues of the legacy device been reviewed for applicability?",
    checkboxes: true,
  },
  {
    id: 4,
    questions: "Have the hazards for similar devices been reviewed for applicability?",
    checkboxes: true,
  },
  {
    id: 5,
    questions: "Have the clinical rationales for harm severity and P2 been provided?",
    checkboxes: true,
  },
  {
    id: 6,
    questions:
      "Have the contributing factors due to use / environmental conditions and system interactions been considered for foreseeable scenario enumeration?",
    checkboxes: true,
  },
];

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  background: bgColor.main,
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell": {
    padding: "10px 12px",
    lineHeight: "20px",
    border: `1px solid  ${borderColor.dividerColor}`,
  },
  ".table_header_cell": {
    // padding: "9px 12px",
  },
}));

interface ChecklistProps {
  isPadding?: boolean;
}

const Checklist: React.FC<ChecklistProps> = (props) => {
  const [rows, setRows] = useState(initRows);

  return (
    <Container>
      <AppScrollbar
        sx={{
          width: "100%",
          maxHeight: "calc(100vh - 104px)",
          padding: props?.isPadding ? (theme) => theme.spacing(5) : 0,
        }}
      >
        <Box
          sx={{
            padding: (theme) => theme.spacing(5),
            background: (theme) => theme.palette.background.paper,
            boxShadow: boxShadow.cardBoxShadow,
          }}
        >
          <TableContainer>
            <Table aria-label="table">
              <TableHead>
                <TableRow key={"header_columns"} className="table_header">
                  <TableCell className="table_cell table_header_cell" align={"center"}>
                    <IntlMessages id="workspaces.checklist.item.order" />
                  </TableCell>
                  <TableCell className="table_cell table_header_cell" align={"center"}>
                    <IntlMessages id="workspaces.checklist.item.questions" />
                  </TableCell>
                  <TableCell className="table_cell table_header_cell" align={"center"}>
                    <IntlMessages id="workspaces.checklist.item.checkboxes" />
                  </TableCell>
                  <TableCell className="table_cell table_header_cell" align={"center"}>
                    <IntlMessages id="workspaces.checklist.item.comments" />
                  </TableCell>
                  {/* <TableCell className="table_cell table_header_cell" align={"center"}>
                    <IntlMessages id="workspaces.checklist.item.references" />
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((x) => (
                  <CheckItem key={`CheckItem_${x.id}`} row={x} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </AppScrollbar>
    </Container>
  );
};

export default Checklist;
