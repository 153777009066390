import { Box } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import { TableToolBarModel } from "components/CustomTable/CustomTableModel";
import React from "react";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

const columns: Column[] = [
  {
    id: "name",
    label: "process.input.columns.processName",
    minWidth: 200,
    align: "left",
  },
  {
    id: "status",
    label: "process.input.columns.processStatus",
    minWidth: 80,
    align: "left",
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    minWidth: 60,
    align: "left",
    format: (value, record) => {
      return (
        <Box className="flex">
          <AppSvgIcon size={16} sxStyle={{ cursor: "pointer" }}>
            material-solid:delete
          </AppSvgIcon>
        </Box>
      );
    },
  },
];

const rows: any[] = [
  {
    id: "1",
    name: "Intended Use and Context of Use",
    status: "Approved",
  },
];

const toolbar: TableToolBarModel = {
  buttons: [
    {
      id: "common.customize",
      icon: TableHeaderButtonsIconType.SETTING,
      type: "outlined",
      onChange: () => {},
    },
  ],
};

interface RequiredProps {
  [x: string]: any;
}

const Required: React.FC<RequiredProps> = () => {
  return (
    <Box>
      <CustomTable
        toolBar={toolbar}
        columns={columns}
        rows={rows}
        showPage={false}
        maxHeight="auto"
        addRow={() => {}}
        addRowTextId="common.add"
      />
    </Box>
  );
};

export default Required;
