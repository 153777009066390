import Icon from "@mui/material/Icon";
import * as React from "react";

const RiskSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <rect fill="#835BA3" opacity=".1" width="20" height="20" rx="4" />
      <path
        d="M8 3.25a.25.25 0 0 1 .214.121L9.642 5.75H17a.5.5 0 0 1 .5.5v9a1.5 1.5 0 0 1-1.5 1.5H4a1.5 1.5 0 0 1-1.5-1.5V3.75a.5.5 0 0 1 .5-.5zm2 10.625a.625.625 0 1 0 0 1.25.625.625 0 0 0 0-1.25zm.625-6.25h-1.25l.125 5.5h1l.125-5.5z"
        fill="#835BA3"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

const RiskIcon: React.FunctionComponent = () => {
  return <Icon component={RiskSvg} />;
};

export default RiskIcon;
