import styled from "@emotion/styled";
import { Box } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import { StageTransition } from "mock/ProductStageData";
import React from "react";
import { textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

const TransitionTableContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: "20px",
  "& .MuiTableCell-root": {
    border: "1px solid rgba(0,0,0,0.12)",
  },
}));

interface TransitionProps {
  transitions: StageTransition[];
}

const TransitionContents: React.FC<TransitionProps> = ({ transitions }) => {
  const columns: Column[] = [
    {
      id: "fromStage",
      label: "setting.product.column.fromStage",
      minWidth: 100,
      align: "left",
    },
    {
      id: "linkText",
      label: "setting.product.column.linkText",
      minWidth: 100,
      align: "left",
      format: (value, record) => {
        return (
          <Box>
            <Box>{record.linkText}</Box>
            <AppSvgIcon color="action" size="20px" className="mr-6">
              material-outline:east
            </AppSvgIcon>
          </Box>
        );
      },
    },
    {
      id: "toStage",
      label: "setting.product.column.toStage",
      minWidth: 100,
      align: "left",
    },
    {
      id: "notifications",
      label: "setting.product.column.notifications",
      minWidth: 100,
      align: "left",
    },
    // {
    //   id: "lock",
    //   label: "setting.process.item.workflow.edit.table.lock",
    //   minWidth: 40,
    //   align: "left",
    //   format: (value, record) => {
    //     const iconPath = record.lock ? "material-outline:lock" : "material-outline:lock_open";
    //     return (
    //       <AppSvgIcon color="action" size="20px">
    //         {iconPath}
    //       </AppSvgIcon>
    //     );
    //   },
    // },
    {
      id: "permissionRoles",
      label: "setting.product.column.permissions",
      minWidth: 50,
      align: "left",
    },
    {
      id: "actions",
      label: "setting.product.column.actions",
      minWidth: 100,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex place-content-center">
            <AppSvgIcon color={textColor["65"]} size="20px" className="mr-8">
              material-solid:add_circle
            </AppSvgIcon>
            <AppSvgIcon color={textColor["65"]} size="20px" className="mr-8">
              material-solid:edit
            </AppSvgIcon>
            <AppSvgIcon color={textColor["65"]} size="20px" className="mr-8">
              material-solid:delete
            </AppSvgIcon>
          </Box>
        );
      },
    },
  ];

  return (
    <TransitionTableContainer>
      <CustomTable columns={columns} rows={transitions} maxHeight="600px" showPage={false} />
    </TransitionTableContainer>
  );
};

export default TransitionContents;
