import Icon from "@mui/material/Icon";
import * as React from "react";

const TaskItemSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <path
        d="M9.791 1.5c.074 0 .145.024.203.069l.033.029 3.709 3.707c.052.052.085.12.094.192l.003.043v8.293c0 .352-.272.64-.617.665l-.05.002H2.834a.667.667 0 0 1-.665-.617l-.001-.05V2.167c0-.352.272-.64.617-.665l.05-.002H9.79zm-.291 1H3.333a.167.167 0 0 0-.164.137l-.002.03v10.666c0 .082.059.15.136.164l.03.003h9.334c.081 0 .15-.059.164-.137l.002-.03v-7.5h-2.666a.667.667 0 0 1-.665-.617l-.002-.05V2.5zm1 7.667v1h-5v-1h5zm0-2.667v1h-5v-1h5z"
        fill="#2E6CCB"
      />
    </g>
  </svg>
);

const TaskItemIcon: React.FunctionComponent = () => {
  return <Icon component={TaskItemSvg} />;
};

export default TaskItemIcon;
