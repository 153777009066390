import SimplePopup from "components/SimplePopup";
import React from "react";

interface SaveAsProfileModalProps {
  handleClose: () => void;
  open: boolean;
}

const SaveAsProfileModal: React.FC<SaveAsProfileModalProps> = ({ open, handleClose }) => {
  const save = () => {};

  return (
    <SimplePopup
      handleClose={handleClose}
      open={open}
      titleId="setting.process.saveAsTemplate"
      okTextId="common.ok"
      save={save}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "1000px",
          width: "1000px",
        },
        "& .MuiDialogContent-root": {
          padding: "20px",
        },
      }}
    ></SimplePopup>
  );
};

export default SaveAsProfileModal;
