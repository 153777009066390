import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import ColorPicker from "components/ColorPicker";
import { Form, Formik, FormikProps } from "formik";
import { useState } from "react";
import { bgColor, textColor } from "shared/constants/AppConst";
import * as yup from "yup";

import ContentHeader from "../components/ContentHeader";

const Container = styled(Box)(({ theme }) => ({}));
const BoxContainer = styled(Box)(({ theme }) => ({
  ".MuiGrid-container": {
    ".MuiGrid-item": {
      display: "flex",
      alignItems: "center",
      ".MuiBox-root": {
        flex: "1",
        maxWidth: "280px",
        fontWeight: "normal",
      },
      ".MuiTextField-root": {
        flex: "2",
        maxWidth: "400px",
      },
      ".MuiInputBase-root": {
        flex: "2",
        maxWidth: "400px",
      },
    },
  },
  ".checkboxTitle": {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "0.15px",
    color: "rgba(0,0,0,0.85)",
    mb: 1,
  },
}));

interface SettingsFormModel {
  id: number | string;
  newColor: string;
  changedColor: string;
  excelTableHeaderColor: string;
  errorFontColor: string;
  warnFontColor: string;
  tipFontColor: string;
  treeTableHighlightColor: string;
  treeViewHightlightColor: string;
  excelHightlightColor: string;
  graphicHightlightColor: string;
}

const Palette = () => {
  const [edit, setEdit] = useState<boolean>(false);

  const [formData, setFormData] = useState<SettingsFormModel>({
    id: "",
    newColor: "#ff8376",
    changedColor: "#ffbe71",
    excelTableHeaderColor: "#e6f0fa",
    errorFontColor: "#ff0000",
    warnFontColor: "#ff0000",
    tipFontColor: "#ff0000",
    treeTableHighlightColor: "#ff0000",
    treeViewHightlightColor: "#2e6ccb",
    excelHightlightColor: "#2e6ccb",
    graphicHightlightColor: "#2e6ccb",
  });
  const [defaultData, setdefaultData] = useState<SettingsFormModel>({
    id: "",
    newColor: "#ff8376",
    changedColor: "#ffbe71",
    excelTableHeaderColor: "#e6f0fa",
    errorFontColor: "#ff0000",
    warnFontColor: "#ff0000",
    tipFontColor: "#ff0000",
    treeTableHighlightColor: "#ff0000",
    treeViewHightlightColor: "#2e6ccb",
    excelHightlightColor: "#2e6ccb",
    graphicHightlightColor: "#2e6ccb",
  });
  const validationSchema = yup.object();

  const handleSave = (formik: FormikProps<SettingsFormModel>) => {
    console.log(formik.values);
    setFormData({ ...formik.values });
    setEdit(false);
  };

  const handleCancel = (formik: FormikProps<SettingsFormModel>) => {
    console.log(formik.values);
    formik.setValues({
      ...formData,
    });
    setEdit(false);
  };

  const handleReset = (formik: FormikProps<SettingsFormModel>) => {
    console.log(formik.values);
    formik.setValues({
      ...defaultData,
    });
  };

  return (
    <Container>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          console.log(data);
          resetForm();
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            noValidate
            autoComplete="off"
          >
            <ContentHeader titleId="admin.nav.item.palette">
              <Box sx={{ display: edit ? "none" : "inline-block" }}>
                <Button
                  className="text-color-08"
                  sx={{
                    mr: 3,
                    borderRadius: "4px",
                    border: "solid 1px rgba(0, 0, 0, 0.15)",
                    color: textColor["85"],
                  }}
                  onClick={() => {
                    handleReset(formik);
                  }}
                  startIcon={
                    <AppSvgIcon size={16} color={textColor["65"]}>
                      {"material-solid:format_color_reset"}
                    </AppSvgIcon>
                  }
                  variant="outlined"
                >
                  <IntlMessages id="common.reset" />
                </Button>
                <Button
                  onClick={() => {
                    setEdit(true);
                  }}
                  variant="outlined"
                  color="inherit"
                  startIcon={<AppSvgIcon size={16}>{"material-solid:edit"}</AppSvgIcon>}
                >
                  <IntlMessages id="common.edit" />
                </Button>
              </Box>
              <Box sx={{ display: edit ? "inline-block" : "none" }}>
                <Button
                  className="text-color-08"
                  sx={{
                    mr: 3,
                    borderRadius: "4px",
                    border: "solid 1px rgba(0, 0, 0, 0.15)",
                    color: textColor["85"],
                  }}
                  onClick={() => {
                    handleReset(formik);
                  }}
                  startIcon={
                    <AppSvgIcon size={16} color={textColor["65"]}>
                      {"material-solid:format_color_reset"}
                    </AppSvgIcon>
                  }
                  variant="outlined"
                >
                  <IntlMessages id="common.reset" />
                </Button>
                <Button
                  className="text-color-08"
                  sx={{
                    mr: 3,
                    borderRadius: "4px",
                    border: "solid 1px rgba(0, 0, 0, 0.15)",
                    color: textColor["85"],
                  }}
                  onClick={() => {
                    handleCancel(formik);
                  }}
                  startIcon={
                    <AppSvgIcon size={16} color={textColor["65"]}>
                      {"material-solid:cancel"}
                    </AppSvgIcon>
                  }
                  variant="outlined"
                >
                  <IntlMessages id="common.cancel" />
                </Button>
                <Button
                  onClick={() => {
                    handleSave(formik);
                  }}
                  color="primary"
                  startIcon={
                    <AppSvgIcon size={16} color="#FFF">
                      {"material-solid:save"}
                    </AppSvgIcon>
                  }
                  variant="contained"
                >
                  <IntlMessages id="common.save" />
                </Button>
              </Box>
            </ContentHeader>
            <BoxContainer>
              <AppScrollbar
                sx={{
                  height: "calc(100vh - 104px)",
                  padding: (theme) => theme.spacing(5),
                }}
              >
                <Box sx={{ padding: "20px", background: "#fff", boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)" }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                      <Box className="checkboxTitle">
                        <IntlMessages id="settings.new.color" />
                      </Box>
                      <FormControl className="checkboxList">
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={12}>
                            <ColorPicker
                              color={formik.values.newColor}
                              disabled={!edit}
                              handleColor={(color) => {
                                formik.setFieldValue("newColor", color);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="checkboxTitle">
                        <IntlMessages id="settings.changed.color" />
                      </Box>
                      <FormControl className="checkboxList">
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={12}>
                            <ColorPicker
                              color={formik.values.changedColor}
                              disabled={!edit}
                              handleColor={(color) => {
                                formik.setFieldValue("changedColor", color);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="checkboxTitle">
                        <IntlMessages id="settings.excelTableHeaderColor" />
                      </Box>
                      <FormControl className="checkboxList">
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={12}>
                            <ColorPicker
                              color={formik.values.excelTableHeaderColor}
                              disabled={!edit}
                              handleColor={(color) => {
                                formik.setFieldValue("excelTableHeaderColor", color);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="checkboxTitle">
                        <IntlMessages id="settings.errorFontColor" />
                      </Box>
                      <FormControl className="checkboxList">
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={12}>
                            <ColorPicker
                              color={formik.values.errorFontColor}
                              disabled={!edit}
                              handleColor={(color) => {
                                formik.setFieldValue("errorFontColor", color);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="checkboxTitle">
                        <IntlMessages id="settings.warnFontColor" />
                      </Box>
                      <FormControl className="checkboxList">
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={12}>
                            <ColorPicker
                              color={formik.values.warnFontColor}
                              disabled={!edit}
                              handleColor={(color) => {
                                formik.setFieldValue("warnFontColor", color);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="checkboxTitle">
                        <IntlMessages id="settings.tipFontColor" />
                      </Box>
                      <FormControl className="checkboxList">
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={12}>
                            <ColorPicker
                              color={formik.values.tipFontColor}
                              disabled={!edit}
                              handleColor={(color) => {
                                formik.setFieldValue("tipFontColor", color);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="checkboxTitle">
                        <IntlMessages id="settings.treeTableHighlightColor" />
                      </Box>
                      <FormControl className="checkboxList">
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={12}>
                            <ColorPicker
                              color={formik.values.treeTableHighlightColor}
                              disabled={!edit}
                              handleColor={(color) => {
                                formik.setFieldValue("treeTableHighlightColor", color);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="checkboxTitle">
                        <IntlMessages id="settings.treeViewHightlightColor" />
                      </Box>
                      <FormControl className="checkboxList">
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={12}>
                            <ColorPicker
                              color={formik.values.treeViewHightlightColor}
                              disabled={!edit}
                              handleColor={(color) => {
                                formik.setFieldValue("treeViewHightlightColor", color);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="checkboxTitle">
                        <IntlMessages id="settings.excelHightlightColor" />
                      </Box>
                      <FormControl className="checkboxList">
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={12}>
                            <ColorPicker
                              color={formik.values.excelHightlightColor}
                              disabled={!edit}
                              handleColor={(color) => {
                                formik.setFieldValue("excelHightlightColor", color);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="checkboxTitle">
                        <IntlMessages id="settings.graphicHightlightColor" />
                      </Box>
                      <FormControl className="checkboxList">
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={12}>
                            <ColorPicker
                              color={formik.values.graphicHightlightColor}
                              disabled={!edit}
                              handleColor={(color) => {
                                formik.setFieldValue("graphicHightlightColor", color);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </AppScrollbar>
            </BoxContainer>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Palette;
