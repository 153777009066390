import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

const HomeIcon: React.FunctionComponent<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="m12 5.026 7.5 6V20H14v-5h-4v5H4.5v-8.974l7.5-6zm.39-2.889 9.375 7.5a.625.625 0 1 1-.78.976L12 3.425l-8.984 7.188a.625.625 0 0 1-.781-.976l9.374-7.5a.625.625 0 0 1 .782 0z" />
    </SvgIcon>
  );
};

export default HomeIcon;
