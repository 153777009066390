import AppScrollbar from "@crema/core/AppScrollbar";
import { Box, Grid, Step, StepLabel, Stepper } from "@mui/material";
import SimplePopup from "components/SimplePopup";
import * as React from "react";
import { borderColor, textColor } from "shared/constants/AppConst";

import Deliverable from "./Deliverable";
import Location from "./Location";
import Resolve from "./Resolve";
import ReuseOptions from "./ReuseOptions";

interface ReuseModalProps {
  handleClose: () => void;
  open: boolean;
}

const steps = [
  {
    label: "Step 1",
    content: "Select Deliverable",
  },
  {
    label: "Step 2",
    content: "Set Options",
  },
  {
    label: "Step 3",
    content: "Select Destination & Resolve Issues",
  },
];

export default function ReuseModal(props: ReuseModalProps) {
  const { open, handleClose } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [isSync, setIsSync] = React.useState<boolean>(true);
  const [prefix, setPrefix] = React.useState<string>("");
  const [relation, setRelation] = React.useState<any>(0);
  const [type, setType] = React.useState<any>("1");

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const getStepContent = React.useMemo(() => {
    if (activeStep === 0) {
      return (
        <Box sx={{ padding: "0", borderTop: `1px solid ${borderColor.dividerColor}`, background: "#FAFAFA" }}>
          <Deliverable />
        </Box>
      );
    } else if (activeStep === 1) {
      return (
        <Grid
          sx={{ padding: "0", borderTop: `1px solid ${borderColor.dividerColor}`, background: "#FAFAFA" }}
          container
          spacing={0}
        >
          <Grid item xs={12} md={12}>
            <AppScrollbar
              sx={{
                minHeight: "645px",
                maxHeight: "645px",
                background: "#FAFAFA",
              }}
            >
              <ReuseOptions
                isSync={isSync}
                handleIsSync={setIsSync}
                handlePrefix={setPrefix}
                prefix={prefix}
                handleRelation={setRelation}
                relation={relation}
                handleType={setType}
                type={type}
              />
            </AppScrollbar>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid
          sx={{ padding: "0", borderTop: `1px solid ${borderColor.dividerColor}`, background: "#FAFAFA" }}
          container
          spacing={0}
        >
          <Grid
            item
            xs={6}
            md={6}
            sx={{
              borderRight: `1px solid ${borderColor.dividerColor}`,
              minHeight: "630px",
              background: "#FAFAFA",
            }}
          >
            <Location />
          </Grid>
          <Grid item xs={6} md={6}>
            <AppScrollbar
              sx={{
                borderLeft: `1px solid ${borderColor.dividerColor}`,
                minHeight: "630px",
                maxHeight: "630px",
                background: "#FAFAFA",
              }}
            >
              <Resolve />
            </AppScrollbar>
          </Grid>
        </Grid>
      );
    }
  }, [activeStep, isSync, prefix, relation, type]);

  return (
    <SimplePopup
      handleClose={handleClose}
      open={open}
      titleId="products.reuse.from"
      showStep={true}
      step={activeStep}
      handleStep={setActiveStep}
      totalStep={3}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "1200px",
          minWidth: "1200px",
          height: "820px",
        },
        "& .MuiDialogContent-root": {
          padding: 0,
        },
        "& .MuiStepConnector-root": {
          display: "none",
        },
        "& .MuiStepLabel-label": {
          color: textColor[85],
          fontSize: "14px",
        },
        "& .MuiStepLabel-label.Mui-active": {
          color: "#fff",
          fontSize: "14px",
        },
        "& .MuiStepper-horizontal": {
          justifyContent: "space-around",
        },
        "& .MuiStep-horizontal": {
          width: "50%",
          cursor: "pointer",
          padding: 3,
          paddingRight: 0,
          paddingLeft: 6,
        },
      }}
    >
      <Box>
        <Box className="step">
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((item, index) => (
              <Step
                sx={{ background: activeStep === index ? "#169bd5" : "#FFF" }}
                key={item.label}
                onClick={handleStep(index)}
              >
                <StepLabel>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      // alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold" }}>{item.label}</Box>
                    <Box>{item?.content}</Box>
                  </Box>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {getStepContent}
      </Box>
    </SimplePopup>
  );
}
