import { AuthUser } from "../../types/models/AuthUser";

export const baseSassPrefix = "/api/saas";
export const baseAdminPrefix = "/api/admin";

export const authRole = {
  admin: ["admin"],
  user: ["user", "admin"],
};

export enum RoutePermittedRole {
  Admin = "admin",
  User = "user",
}

export const defaultUser: AuthUser = {
  uid: "john-alex",
  displayName: "John Alex",
  email: "demo@example.com",
  token: "access-token",
  role: "user",
  photoURL: "/assets/images/avatar/A11.jpg",
};
export const initialUrl = "/home"; // this url will open after login

export const tabStyle = {
  "& .MuiButtonBase-root, MuiButtonBase-root-MuiTab-root": {
    textTransform: "none",
    minHeight: "48px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    color: "rgba(0,0,0,0.65)",
  },
  "& .MuiButtonBase-root.Mui-selected": {
    color: "#2e6ccb",
    fontWeight: "500",
  },
  "& .MuiTouchRipple-root": {
    display: "inline-block",
    marginLeft: "12px",
  },
  "& .MuiTabs-indicator": {
    background: "#2e6ccb",
  },
};

export const accordionStyle = {
  padding: "20px",
  background: "#fff",
  boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)",
  "& .MuiAccordion-root": {
    marginBottom: "32px",
    boxShadow: "none",
  },
  "& .MuiAccordion-root::before": {
    height: 0,
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "48px",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },
  "& .MuiAccordion-root.Mui-expanded": {
    minHeight: "48px",
  },
  "& .MuiAccordionSummary-root": {
    padding: 3,
    height: "48px !important",
    background: "#f2f4f7",
  },
  "& .MuiDivider-fullWidth::before": {
    width: "0px",
    // height: "12px",
    // marginRight: "8px",
    // background: "#5c9cff",
    // transform: "translateY(30%)",
  },
  "& .MuiDivider-fullWidth::after": {
    width: "calc(100% - 11px)",
    transform: "translateY(50%)",
    margin: "0 12px",
    display: "none",
  },
  "& .MuiDivider-wrapper": {
    padding: 0,
  },
  "& .MuiAccordionDetails-root": {
    padding: "20px 12px 0px 12px",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    textFillColor: " rgba(0, 0, 0, 0.8)",
  },
};

export const treeStyle = {
  height: "100%",
  padding: "12px 20px 0px 20px",
  // borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  backgroundColor: "#FFF",
};

export const textColor = {
  25: "rgba(0,0,0,0.25)",
  45: "rgba(0,0,0,0.45)",
  65: "rgba(0,0,0,0.65)",
  5: "rgba(0,0,0,0.05)",
  85: "rgba(0,0,0,0.85)",
};

export const bgColor = {
  main: "#f2f4f7",
  tableBodyRow2: "#FAFAFA",
  tableHeaderColor: "#E6F0FA",
  collapseBackground: "#FAFAFA",
};

export const statusBgColor = {
  open: "#2e6ccb",
  develop: "#9133db",
  "in process": "#9133db",
  draft: "#8f92a1",
  done: "#000",
};

export const chipColor = {
  stage: "#2e6ccb",
  stageBgColor: "rgba(46, 108, 203, 0.1)",
  versionBgColor: "rgba(143, 146, 161, 0.1)",
};

export const boxShadow = {
  cardBoxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)",
};

export const borderColor = {
  sidebarBorderRightColor: "#c9ccd1",
  dividerColor: "rgba(0, 0, 0, 0.12)",
};

export const borderRadius = {
  main: "4px",
};

export const sidebarStyle = {
  position: "relative",
  padding: 0,
  "& .MuiListItem-root": {
    height: "32px",
    color: "rgba(0, 0, 0, 0.45)",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
  },
  "& .MuiListItem-root .nav-item-text": {
    color: "rgba(0,0,0,0.85)",
  },
  "& .MuiListItem-root.active .nav-item-text": {
    color: "#fff !important",
  },
  "& .MuiListItem-root.active": {
    margin: "0 12px",
    width: "calc(100% - 24px)",
    backgroundColor: "#2e6ccb",
    borderRadius: "4px",
    color: "#fff",
  },
  "& .MuiListItem-root.active::after": {
    backgroundColor: "#fff",
  },
  "& .MuiListItem-root::after": {
    width: 0,
  },
  "& .MuiCollapse-vertical.collapse-children": {
    paddingLeft: "35px",
    background: "#C5CBD6",
  },
  "& .MuiListItem-button.menu-vertical-collapse": {
    padding: "8px 12px 8px 16px",
  },
  "& .MuiListItem-root.nav-item-header": {
    height: "46px",
    padding: "8px 12px 8px 16px",
  },
  ".slider-title": {
    height: "48px",
    width: "100%",
    padding: "8px 12px 8px 16px",
    display: "flex",
    alignItems: "center",
  },
};
