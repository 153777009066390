import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Grid } from "@mui/material";
import MySelectField from "components/MySelectField";
import SimplePopup from "components/SimplePopup";
import { Form, Formik } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";

interface AddTemplateProps {
  handleClose: () => void;
  open: boolean;
}

export default function AddTemplate(props: AddTemplateProps) {
  const { open, handleClose } = props;
  const { messages } = useIntl();
  const [variantList, setVariantList] = React.useState([]);
  const [releaseList, setReleaseList] = React.useState([]);
  const [productList] = React.useState([
    {
      id: 1,
      name: "Product1",
      variantList: [
        {
          id: 1,
          name: "Main",
          releaseList: [
            {
              id: 1,
              name: "R1",
            },
            {
              id: 2,
              name: "R2",
            },
            {
              id: 3,
              name: "R3",
            },
          ],
        },
        {
          id: 2,
          name: "EU",
          releaseList: [
            {
              id: 1,
              name: "EU R2.2",
            },
            {
              id: 2,
              name: "EU R2.2",
            },
            {
              id: 3,
              name: "EU R2.3",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "Product2",
      variantList: [
        {
          id: 1,
          name: "Main",
          releaseList: [
            {
              id: 1,
              name: "R1",
            },
            {
              id: 2,
              name: "R2",
            },
            {
              id: 3,
              name: "R3",
            },
          ],
        },
        {
          id: 2,
          name: "EU",
          releaseList: [
            {
              id: 1,
              name: "EU R2.2",
            },
            {
              id: 2,
              name: "EU R2.2",
            },
            {
              id: 3,
              name: "EU R2.3",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: "Product3",
      variantList: [
        {
          id: 1,
          name: "Main",
          releaseList: [
            {
              id: 1,
              name: "R1",
            },
            {
              id: 2,
              name: "R2",
            },
            {
              id: 3,
              name: "R3",
            },
          ],
        },
        {
          id: 2,
          name: "EU",
          releaseList: [
            {
              id: 1,
              name: "EU R2.2",
            },
            {
              id: 2,
              name: "EU R2.2",
            },
            {
              id: 3,
              name: "EU R2.3",
            },
          ],
        },
      ],
    },
  ]);

  const validationSchema = yup.object({
    product: yup.string().required(String(messages["validation.productRequired"])),
    variant: yup.string().required(String(messages["validation.variantRequired"])),
    release: yup.string().required(String(messages["validation.releaseRequired"])),
  });

  const save = () => {
    const submitDom = document.getElementById("submit");
    submitDom && submitDom.click();
  };

  return (
    <SimplePopup
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "500px",
          width: "500px",
        },
      }}
      handleClose={handleClose}
      open={open}
      titleId="common.add.template"
      save={save}
    >
      <Formik
        initialValues={{
          id: "",
          product: "",
          variant: "",
          release: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          console.log(data);
          handleClose();
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, setFieldValue, resetForm }) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <MySelectField
                  label="common.product"
                  onChange={(e) => {
                    resetForm();
                    setFieldValue("product", e.target.value);
                    const currentProduct = productList.filter((item) => item.id === e.target.value)[0];
                    console.log(currentProduct);
                    currentProduct && setVariantList(currentProduct.variantList);
                  }}
                  name="product"
                  fullWidth
                  required={true}
                  list={productList}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <MySelectField
                  label="common.variant"
                  onChange={(e) => {
                    const oldValues = { ...values };
                    resetForm();
                    setFieldValue("product", oldValues.product);
                    setFieldValue("variant", e.target.value);
                    const currentVariant = variantList.filter((item) => item.id === e.target.value)[0];
                    currentVariant && setReleaseList(currentVariant.releaseList);
                  }}
                  name="variant"
                  fullWidth
                  required={true}
                  list={variantList}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <MySelectField
                  label="products.release"
                  onChange={(e) => {
                    setFieldValue("release", e.target.value);
                  }}
                  name="release"
                  fullWidth
                  required={true}
                  list={releaseList}
                />
              </Grid>
              <Grid sx={{ display: "none" }} item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    id="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <IntlMessages id="common.save" />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SimplePopup>
  );
}
