import React from "react";
// import Box from "@mui/material/Box";
//  import AuthWrapper from "../AuthWrapper"; 
// import AppLogo from "layout/components/AppNextGenLogo"; 
//  import SigninJwtAuth from "./SigninJwtAuth";

const Signin = React.lazy(() => import("./Signin"));
const ForgotPassword = React.lazy(() => import("./ForgetPassword"));

// const Signin = () => {
//   return (
//     <AuthWrapper>
//       <Box sx={{ width: "100%"}}>
//         <Box sx={{ mb: { xs: 6, xl: 8  } }}>
//           <Box
//             sx={{
//               mb: 5,
//               display: "flex",
//               alignItems: "center",
//             }}
//           > 
//           </Box>
//         </Box>
//         <SigninJwtAuth />
//       </Box>
//     </AuthWrapper>
//   );
// };

export const authRouteConfig = [
  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/forget-password",
    element: <ForgotPassword />,
  }
];

export default Signin;
