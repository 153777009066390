import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import comparisonPng from "assets/imgs/comparison.png";
import React from "react";

const ComparisonContainer = styled(Box)(({ theme }) => ({
  marginTop: "8px",
  minWidth: "1000px",
}));

export default function Comparison() {
  return (
    <ComparisonContainer>
      <img alt="" src={comparisonPng} />
    </ComparisonContainer>
  );
}
