import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import MyTree from "components/MyTree";
import { useEffect, useState } from "react";

const deepFind =
  (pred) =>
  ([x, ...xs] = []) =>
    x && (pred(x) ? x : deepFind(pred)(x.items) || deepFind(pred)(xs));

const findById = (id) => (dataSet) => deepFind((o) => o.id === id)(dataSet);

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  ".rich-box": {
    width: "100%",
    minHeight: "400px",
    ".ck.ck-content": {
      width: "100%",
      height: "400px",
    },
  },
}));

interface TreeData {
  id: string | number;
  value?: string;
  isBold?: boolean;
  items?: TreeData[];
}

interface RichEditorTemplateProps {
  treeData?: TreeData[];
  data?: string;
  onChange?: (data) => void;
  inEdit?: boolean;
}
const RichEditorTemplate = (props: RichEditorTemplateProps) => {
  const { treeData, data, onChange, inEdit } = props;

  const [editor, setEditor] = useState<any>();

  // const [treeData, setTreeData] = useState<TreeData[]>(indexTree);

  const [selectedTreeData, setSelectedTreeData] = useState<TreeData>(treeData ? treeData[0] : null);

  const [selectedValue, setSelectedValue] = useState<string | number>(
    treeData && treeData[0] ? treeData[0].id.toString() : null
  );

  const onNodeSelect = (event: object, value: string | number) => {
    setSelectedValue(value);
    if (treeData && treeData.length > 0) {
      const selected = findById(value)(treeData);
      setSelectedTreeData(selected);
    }
  };

  const handleChange = (event, editor) => {
    const res = editor.getData();
    console.log(res);
    onChange && onChange(res);
  };

  useEffect(() => {
    if (editor) {
      if (!inEdit) {
        editor.enableReadOnlyMode("report-template-editor");
      } else {
        editor.disableReadOnlyMode("report-template-editor");
      }
    }
  }, [editor, inEdit]);

  return (
    <>
      <Container>
        <Box
          className="index-tree"
          sx={{ width: "calc(30% - 12px)", border: "1px solid #c4c4c4", borderRight: 0, p: "12px" }}
        >
          <MyTree
            selected={selectedValue ? selectedValue.toString() : null}
            onNodeSelect={onNodeSelect}
            data={treeData}
            useStyledTree={true}
            showMoreIcon={false}
          />
        </Box>
        <Box className="rich-box">
          <CKEditor
            id="report-template-editor"
            editor={ClassicEditor}
            data={data}
            onReady={(editor) => {
              setEditor(editor);
            }}
            // onChange={handleChange}
            onBlur={handleChange}
          />
        </Box>
      </Container>
    </>
  );
};
export default RichEditorTemplate;
