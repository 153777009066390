import Common from "./Common";
import Products from "./Products";
import Settings from "./Setting";
import Workspaces from "./Workspaces";

const reducers = {
  settings: Settings,
  common: Common,
  workspaces: Workspaces,
  products: Products,
};

export default reducers;
