import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { borderColor, tabStyle } from "shared/constants/AppConst";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Worksheet = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { pid } = params;
  const [index, setIndex] = useState("format");

  useEffect(() => {
    navigate(index);
  }, [index]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setIndex(newValue);
  };
  return (
    <>
      <Box>
        <Tabs
          value={index}
          onChange={handleChangeTab}
          sx={{
            borderBottom: `1px solid ${borderColor.dividerColor}`,
            ...tabStyle,
          }}
        >
          <Tab value="format" label={<IntlMessages id="common.format" />} {...a11yProps(0)} />
          <Tab value="analysis" label={<IntlMessages id="common.item.analysis" />} {...a11yProps(1)} />
          {/* <Tab value="perspectives" label={<IntlMessages id="common.perspectives" />} {...a11yProps(2)} />
          <Tab value="resources" label={<IntlMessages id="common.resources" />} {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <Box sx={{ pt: 2 }}>
        <Outlet />
      </Box>
    </>
  );
};

export default Worksheet;
