import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import comparisonPng from "../../../../assets/imgs/comparison.png";

const ComparisonContainer = styled(Box)(({ theme }) => ({
  marginTop: "8px",
  minWidth: "1200px",
}));

export default function Comparison() {
  return (
    <ComparisonContainer>
      <img width="1000px" alt="" src={comparisonPng} />
    </ComparisonContainer>
  );
}
