import IntlMessages from "@crema/utility/IntlMessages";
import { Box, TextField } from "@mui/material";
import { useField } from "formik";

const MyTextFieldNoLabel = (props: any) => {
  const { formik, name } = props;
  // const [field, meta] = useField(props);
  const errorText = formik.error && formik.touched ? formik.error[name] : "";
  return (
    <Box className="filed_box">
      <Box
        className="filed_label"
        sx={
          props?.label_style
            ? {
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                letterSpacing: "0.15px",
                color: "rgba(0,0,0,0.85)",
                mb: 1,
                height: "24px",
                ...props?.label_style,
              }
            : {
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                letterSpacing: "0.15px",
                color: "rgba(0,0,0,0.85)",
                height: "24px",
                mb: 1,
              }
        }
      >
        <sup style={{ color: "#de350b" }}>{props.required ? "*" : <Box>&nbsp;</Box>}</sup>
      </Box>
      <TextField {...props} label="" helperText={errorText} error={!!errorText} />
    </Box>
  );
};

export default MyTextFieldNoLabel;
