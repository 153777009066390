import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import MySelectField from "components/MySelectField";
import MyTextField from "components/MyTextField";
import { Form, Formik, FormikProps } from "formik";
import { useState } from "react";
import { bgColor, textColor } from "shared/constants/AppConst";
import * as yup from "yup";

import ContentHeader from "../components/ContentHeader";

const Container = styled(Box)(({ theme }) => ({}));
const BoxContainer = styled(Box)(({ theme }) => ({
  ".MuiGrid-container": {
    ".MuiGrid-item": {
      display: "flex",
      alignItems: "center",
      ".MuiBox-root": {
        flex: "1",
        maxWidth: "280px",
        fontWeight: "normal",
      },
      ".MuiTextField-root": {
        flex: "2",
        maxWidth: "400px",
      },
      ".MuiInputBase-root": {
        flex: "2",
        maxWidth: "400px",
      },
    },
  },
  ".checkboxTitle": {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "0.15px",
    color: "rgba(0,0,0,0.85)",
    mb: 1,
  },
}));

const PasswordRestrictionsList = [
  {
    id: 1,
    key: "Complexity",
    name: "Complexity",
  },
  {
    id: 2,
    key: "None",
    name: "None",
  },
];

const MechanismToUnlock = [
  {
    id: 1,
    key: "Manual",
    name: "Manual",
  },
  {
    id: 2,
    key: "Auto",
    name: "Auto",
  },
];
const PasswordExpirationControl = [
  {
    id: 1,
    key: "Never",
    name: "Never",
  },
  {
    id: 2,
    key: "Auto",
    name: "Auto",
  },
];
const AllowUserToSetSessionExpiration = [
  {
    id: 1,
    key: "No",
    name: "No",
  },
  {
    id: 2,
    key: "Yes",
    name: "Yes",
  },
];
const RequireLoginSuccessLog = [
  {
    id: 1,
    key: "No",
    name: "No",
  },
  {
    id: 2,
    key: "Yes",
    name: "Yes",
  },
];
const RequireLoginFailureLog = [
  {
    id: 1,
    key: "No",
    name: "No",
  },
  {
    id: 2,
    key: "Yes",
    name: "Yes",
  },
];

interface SettingsFormModel {
  id: number | string;
  passwordRetryAllowance: number;
  passwordRestrictions: number; // Complexity
  minimumPasswordLength: number;
  passwordComplexityOptionsNumber: boolean; // Number
  passwordComplexityOptionsUpper: boolean; // Upper
  passwordComplexityOptionsLower: boolean; // Lower
  passwordComplexityOptionsSymbol: boolean; // Symbol
  mechanismToUnlock: number; // Manual
  passwordExpirationControl: number; // Never
  minutesOfSessionExpiration: number; // minutes
  allowUserToSetSessionExpiration: boolean; // default No, (No, Yes)
  requireLoginSuccessLog: boolean; // default No, (No, Yes)
  requireLoginFailureLog: boolean; // default Yes, (No, Yes)
}
const LoginSecurity = () => {
  const [edit, setEdit] = useState<boolean>(false);

  const [formData, setFormData] = useState<SettingsFormModel>({
    id: "",
    passwordRetryAllowance: 3,
    passwordRestrictions: 1, // Complexity
    minimumPasswordLength: 6,
    passwordComplexityOptionsNumber: true, // Number
    passwordComplexityOptionsUpper: false, // Upper
    passwordComplexityOptionsLower: false, // Lower
    passwordComplexityOptionsSymbol: false, // Symbol
    mechanismToUnlock: 1, // Manual
    passwordExpirationControl: 1, // Never
    minutesOfSessionExpiration: 100, // minutes
    allowUserToSetSessionExpiration: true, // default No, (No, Yes)
    requireLoginSuccessLog: true, // default No, (No, Yes)
    requireLoginFailureLog: true, // default No, (No, Yes)
  });
  const validationSchema = yup.object();

  const handleSave = (formik: FormikProps<SettingsFormModel>) => {
    console.log(formik.values);
    setFormData({ ...formik.values });
    setEdit(false);
  };

  const handleCancel = (formik: FormikProps<SettingsFormModel>) => {
    console.log(formik.values);
    formik.setValues({
      ...formData,
    });
    setEdit(false);
  };

  return (
    <Container>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          console.log(data);
          resetForm();
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            noValidate
            autoComplete="off"
          >
            <ContentHeader titleId="admin.nav.item.loginSecurity">
              <Box sx={{ display: edit ? "none" : "inline-block" }}>
                <Button
                  onClick={() => {
                    setEdit(true);
                  }}
                  variant="outlined"
                  color="inherit"
                  startIcon={<AppSvgIcon size={16}>{"material-solid:edit"}</AppSvgIcon>}
                >
                  <IntlMessages id="common.edit" />
                </Button>
              </Box>
              <Box sx={{ display: edit ? "inline-block" : "none" }}>
                <Button
                  className="text-color-08"
                  sx={{
                    mr: 3,
                    borderRadius: "4px",
                    border: "solid 1px rgba(0, 0, 0, 0.15)",
                    color: textColor["85"],
                  }}
                  onClick={() => {
                    handleCancel(formik);
                  }}
                  startIcon={
                    <AppSvgIcon size={16} color={textColor["65"]}>
                      {"material-solid:cancel"}
                    </AppSvgIcon>
                  }
                  variant="outlined"
                >
                  <IntlMessages id="common.cancel" />
                </Button>
                <Button
                  onClick={() => {
                    handleSave(formik);
                  }}
                  color="primary"
                  startIcon={
                    <AppSvgIcon size={16} color="#FFF">
                      {"material-solid:save"}
                    </AppSvgIcon>
                  }
                  variant="contained"
                >
                  <IntlMessages id="common.save" />
                </Button>
              </Box>
            </ContentHeader>
            <BoxContainer>
              <AppScrollbar
                sx={{
                  height: "calc(100vh - 104px)",
                  padding: (theme) => theme.spacing(5),
                }}
              >
                <Box sx={{ padding: "20px", background: "#fff", boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.12)" }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                      <MyTextField
                        name="passwordRetryAllowance"
                        fullWidth
                        type="number"
                        label="settings.password.retry.allowance"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IntlMessages id="settings.unit.times" />
                              <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{
                                  visibility: edit && formik.values.passwordRetryAllowance ? "visible" : "hidden",
                                }}
                                onClick={() => {
                                  formik.setFieldValue("passwordRetryAllowance", "");
                                }}
                              >
                                <AppSvgIcon size="10px">{"material-outline:clear"}</AppSvgIcon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        variant={edit ? "outlined" : "standard"}
                        disabled={!edit}
                        required={edit ? true : false}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MySelectField
                        label="settings.password.restrictions"
                        onChange={(event: SelectChangeEvent) => {
                          formik.setFieldValue("passwordRestrictions", Number(event.target.value));
                        }}
                        name="passwordRestrictions"
                        disabled={!edit}
                        value={`${formik.values.passwordRestrictions}`}
                        fullWidth
                        list={PasswordRestrictionsList}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MyTextField
                        name="minimumPasswordLength"
                        fullWidth
                        type="number"
                        label="settings.minimumPasswordLength"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{
                                  visibility: edit && formik.values.minimumPasswordLength ? "visible" : "hidden",
                                }}
                                onClick={() => {
                                  formik.setFieldValue("minimumPasswordLength", "");
                                }}
                              >
                                <AppSvgIcon size="10px">{"material-outline:clear"}</AppSvgIcon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        variant={edit ? "outlined" : "standard"}
                        disabled={!edit}
                        required={edit ? true : false}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="passwordComplexityOptionsTitle">
                        <IntlMessages id="settings.passwordComplexityOptions" />
                      </Box>
                      <FormControl className="passwordComplexityOptionsList">
                        <Grid container spacing={4}>
                          <Grid item xs={3} md={3}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="passwordComplexityOptionsNumber"
                                    onChange={(e) => {
                                      formik.setFieldValue("passwordComplexityOptionsNumber", e.target.checked);
                                    }}
                                    disabled={!edit}
                                    checked={formik.values.passwordComplexityOptionsNumber}
                                  />
                                }
                                label={<IntlMessages id="settings.passwordComplexityOptions.number" />}
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={3} md={3}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="passwordComplexityOptionsUpper"
                                    onChange={(e) => {
                                      formik.setFieldValue("passwordComplexityOptionsUpper", e.target.checked);
                                    }}
                                    disabled={!edit}
                                    checked={formik.values.passwordComplexityOptionsUpper}
                                  />
                                }
                                label={<IntlMessages id="settings.passwordComplexityOptions.upper" />}
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={3} md={3}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="passwordComplexityOptionsLower"
                                    onChange={(e) => {
                                      formik.setFieldValue("passwordComplexityOptionsLower", e.target.checked);
                                    }}
                                    disabled={!edit}
                                    checked={formik.values.passwordComplexityOptionsLower}
                                  />
                                }
                                label={<IntlMessages id="settings.passwordComplexityOptions.lower" />}
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={3} md={3}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="passwordComplexityOptionsSymbol"
                                    onChange={(e) => {
                                      formik.setFieldValue("passwordComplexityOptionsSymbol", e.target.checked);
                                    }}
                                    disabled={!edit}
                                    checked={formik.values.passwordComplexityOptionsSymbol}
                                  />
                                }
                                label={<IntlMessages id="settings.passwordComplexityOptions.symbol" />}
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MySelectField
                        label="settings.mechanismToUnlock"
                        onChange={(event: SelectChangeEvent) => {
                          formik.setFieldValue("mechanismToUnlock", Number(event.target.value));
                        }}
                        name="mechanismToUnlock"
                        disabled={!edit}
                        value={`${formik.values.mechanismToUnlock}`}
                        fullWidth
                        list={MechanismToUnlock}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MySelectField
                        label="settings.passwordExpirationControl"
                        onChange={(event: SelectChangeEvent) => {
                          formik.setFieldValue("passwordExpirationControl", Number(event.target.value));
                        }}
                        name="passwordExpirationControl"
                        disabled={!edit}
                        value={`${formik.values.passwordExpirationControl}`}
                        fullWidth
                        list={PasswordExpirationControl}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MyTextField
                        name="minutesOfSessionExpiration"
                        fullWidth
                        type="number"
                        label="settings.minutesOfSessionExpiration"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IntlMessages id="settings.unit.minutes" />
                              <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{
                                  visibility: edit && formik.values.minutesOfSessionExpiration ? "visible" : "hidden",
                                }}
                                onClick={() => {
                                  formik.setFieldValue("minutesOfSessionExpiration", "");
                                }}
                              >
                                <AppSvgIcon size="10px">{"material-outline:clear"}</AppSvgIcon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        variant={edit ? "outlined" : "standard"}
                        disabled={!edit}
                        required={edit ? true : false}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="checkboxTitle">
                        <IntlMessages id="settings.allowUserToSetSessionExpiration" />
                      </Box>
                      <FormControl className="checkboxList">
                        <Grid container spacing={4}>
                          <Grid item xs={3} md={3}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="allowUserToSetSessionExpiration"
                                    onChange={(e) => {
                                      formik.setFieldValue("allowUserToSetSessionExpiration", e.target.checked);
                                    }}
                                    disabled={!edit}
                                    checked={formik.values.allowUserToSetSessionExpiration}
                                  />
                                }
                                label=""
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="checkboxTitle">
                        <IntlMessages id="settings.requireLoginSuccessLog" />
                      </Box>
                      <FormControl className="checkboxList">
                        <Grid container spacing={4}>
                          <Grid item xs={3} md={3}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="requireLoginSuccessLog"
                                    onChange={(e) => {
                                      formik.setFieldValue("requireLoginSuccessLog", e.target.checked);
                                    }}
                                    disabled={!edit}
                                    checked={formik.values.requireLoginSuccessLog}
                                  />
                                }
                                label=""
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className="checkboxTitle">
                        <IntlMessages id="settings.requireLoginFailureLog" />
                      </Box>
                      <FormControl className="checkboxList">
                        <Grid container spacing={4}>
                          <Grid item xs={3} md={3}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="requireLoginFailureLog"
                                    onChange={(e) => {
                                      formik.setFieldValue("requireLoginFailureLog", e.target.checked);
                                    }}
                                    disabled={!edit}
                                    checked={formik.values.requireLoginFailureLog}
                                  />
                                }
                                label=""
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </AppScrollbar>
            </BoxContainer>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default LoginSecurity;
