import AppSvgIcon from "components/AppSvgIcon";
import { ReactNode } from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export interface AdminRouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider" | "title";
  children?: AdminRouterConfigData[];
  permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
  level: number;
}

const adminConfigData: AdminRouterConfigData[] = [
  {
    id: "profile",
    title: "Profile",
    messageId: "admin.profile",
    type: "item",
    icon: <AppSvgIcon size={18}>material-outline:article</AppSvgIcon>,
    url: "profile",
    level: 0,
  },
  {
    id: "rules",
    title: "Rules",
    messageId: "common.rules",
    type: "item",
    icon: <AppSvgIcon size={18}>material-outline:rule_folder</AppSvgIcon>,
    url: "rules",
    level: 0,
  },
  // {
  //   id: "categories",
  //   title: "Categories",
  //   messageId: "admin.nav.item.categories",
  //   type: "item",
  //   icon: <AppSvgIcon size={18}>material-outline:style</AppSvgIcon>,
  //   url: "productCategories",
  //   level: 0,
  // },
  // {
  //   id: "processSettings",
  //   title: "Process Settings",
  //   messageId: "admin.nav.item.processes",
  //   type: "item",
  //   icon: <AppSvgIcon size={18}>material-outline:account_tree</AppSvgIcon>,
  //   url: "processSettings",
  //   level: 0,
  // },
  {
    id: "users",
    title: "Users",
    messageId: "admin.nav.item.users",
    type: "item",
    icon: <AppSvgIcon size={18}>material-outline:person</AppSvgIcon>,
    url: "users",
    level: 0,
  },
  {
    id: "groups",
    title: "Groups",
    messageId: "admin.nav.item.groups",
    type: "item",
    icon: <AppSvgIcon size={18}>material-outline:groups</AppSvgIcon>,
    url: "groups",
    level: 0,
  },
  {
    id: "accessControl",
    title: "Access Control",
    messageId: "admin.nav.item.accessControl",
    type: "item",
    icon: <AppSvgIcon size={18}>material-outline:rule</AppSvgIcon>,
    url: "accessControl",
    level: 0,
  },
];

export default adminConfigData;
