import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

const FillDetailIcon: React.FunctionComponent<SvgIconProps> = (props: SvgIconProps) => {
  const { sx } = props;
  return (
    <SvgIcon
      {...props}
      sx={
        sx
          ? {
              ...sx,
              display: "flex",
            }
          : {
              display: "flex",
            }
      }
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#000" fillOpacity=".65" fillRule="nonzero">
          <path d="M8 3.001h9.332a.63.63 0 0 1 .667.667v2a.63.63 0 0 1-.667.666H8a.63.63 0 0 1-.666-.667v-2A.63.63 0 0 1 8 3.002zM8 8.334h9.332A.63.63 0 0 1 18 9v2a.63.63 0 0 1-.667.667H8A.63.63 0 0 1 7.334 11V9A.63.63 0 0 1 8 8.334zM8 13.666h9.332a.63.63 0 0 1 .667.667v2a.63.63 0 0 1-.667.666H8a.63.63 0 0 1-.666-.666v-2A.63.63 0 0 1 8 13.666zM2.668 3.001h2a.63.63 0 0 1 .666.667v12.665a.63.63 0 0 1-.667.666h-2a.63.63 0 0 1-.666-.666V3.668A.63.63 0 0 1 2.668 3z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default FillDetailIcon;
