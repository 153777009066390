import { Badge, Box, IconButton, Link } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import { ListModel } from "components/CustomTable/CustomTableModel";
import FillTaskIcon from "components/Icons/FillTaskIcon";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "redux/store";
import { statusBgColor, textColor } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

import AddFeed from "../AddFeed";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

interface MyTableProps {}

export default function MyTable(props: MyTableProps) {
  const { moduleList } = useSelector<AppState, AppState["workspaces"]>(({ workspaces }) => workspaces);

  const [editItem, setEditItem] = useState();

  const [deleteItem, setDeleteItem] = useState();

  const handleEditClick = (value) => {
    console.log(value);
    setEditItem(value);
  };

  const handleDeleteClick = (value) => {
    console.log(value);
    setDeleteItem(value);
  };

  const [column, setColumn] = React.useState<Column[]>([
    {
      id: "id",
      label: "workspace.column.id",
      minWidth: 200,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex items-center">
            <Box sx={{ width: "16px" }}>
              <FillTaskIcon />
            </Box>
            <Link
              sx={{ marginLeft: 2 }}
              underline="none"
              href="/products/123/deliverables/Risk Management/System Hazard Analysis/sha/100/worksheet"
            >
              {value}
            </Link>
          </Box>
        );
      },
    },
    {
      id: "content",
      label: "common.description",
      minWidth: 300,
      align: "left",
    },
    {
      id: "status",
      label: "workspace.column.status",
      minWidth: 80,
      align: "left",
      format: (value, record) => {
        return (
          <Box className="flex items-center">
            <Box
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                background: statusBgColor[value.toLowerCase()],
              }}
            />
            <Box sx={{ marginLeft: 2 }}>{value}</Box>
          </Box>
        );
      },
    },
    {
      id: "createBy",
      label: "workspace.column.create.by",
      minWidth: 80,
      align: "left",
    },
    {
      id: "source",
      label: "common.from",
      minWidth: 80,
      align: "left",
    },
    {
      id: "time",
      label: "workspace.column.creation.time",
      minWidth: 100,
      align: "left",
    },
    {
      id: "actions",
      label: "workspace.column.operation",
      minWidth: 100,
      align: "left",
      format: (value, record) => {
        return (
          <Box>
            <IconButton
              sx={{ padding: "2px" }}
              color="secondary"
              onClick={() => {
                handleEditClick(record);
              }}
            >
              <AppSvgIcon size={16} color={textColor["65"]}>
                {"material-solid:edit"}
              </AppSvgIcon>
            </IconButton>
            <IconButton
              sx={{ padding: "2px" }}
              onClick={() => {
                handleDeleteClick(record);
              }}
            >
              <AppSvgIcon size={16} color={textColor["65"]}>
                {"material-solid:delete"}
              </AppSvgIcon>
            </IconButton>
          </Box>
        );
      },
    },
  ]);
  const [rows, setRows] = useState<any[]>([
    {
      id: "FD#001",
      content: "Import Corp ABC rmz file succeed",
      status: "Open",
      createBy: "James",
      source: "Manual",
      time: "09:12:21 3/13/2022",
    },
    {
      id: "FD#002",
      content: "Able to create a kanban for requirement management",
      status: "In Process",
      createBy: "Jack",
      source: "Manual",
      time: "09:12:21 3/13/2022",
    },
    {
      id: "FD#003",
      content: "Need create a system requirement",
      status: "In Process",
      createBy: "Ellen",
      source: "Manual",
      time: "09:12:21 3/13/2022",
    },
    {
      id: "FD#004",
      content: "Able to create a kanban for requirement management",
      status: "In Process",
      createBy: "Tracy",
      source: "Manual",
      time: "09:12:21 3/13/2022",
    },
    {
      id: "FD#005",
      content: "Need create a system requirement",
      status: "Done",
      createBy: "Lily",
      source: "Manual",
      time: "09:12:21 3/13/2022",
    },
    {
      id: "FD#006",
      content: "Need create a system requirement",
      status: "Done",
      createBy: "Lily",
      source: "Manual",
      time: "09:12:21 3/13/2022",
    },
    {
      id: "FD#007",
      content: "Need create a system requirement",
      status: "Done",
      createBy: "Lily",
      source: "Manual",
      time: "09:12:21 3/13/2022",
    },
    {
      id: "FD#008",
      content: "Need create a system requirement",
      status: "Done",
      createBy: "Lily",
      source: "Manual",
      time: "09:12:21 3/13/2022",
    },
    {
      id: "FD#009",
      content: "Need create a system requirement",
      status: "Done",
      createBy: "Lily",
      source: "Manual",
      time: "09:12:21 3/13/2022",
    },
    {
      id: "FD#0010",
      content: "Need create a system requirement",
      status: "Done",
      createBy: "Lily",
      source: "Manual",
      time: "09:12:21 3/13/2022",
    },
    {
      id: "FD#0011",
      content: "Need create a system requirement",
      status: "Done",
      createBy: "Lily",
      source: "Manual",
      time: "09:12:21 3/13/2022",
    },
    {
      id: "FD#0012",
      content: "Need create a system requirement",
      status: "Done",
      createBy: "Lily",
      source: "Manual",
      time: "09:12:21 3/13/2022",
    },
  ]);
  const [showAdd, setShowAdd] = useState<boolean>(true);

  const [searchText, setSearchText] = useState("");

  const [openFeedModal, setOpenFeedModal] = React.useState<boolean>(false);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  return (
    <>
      <CustomTable
        toolBar={{
          showSearch: true,
          searchText: searchText,
          requestSearch: requestSearch,
          saveFilter: () => {
            console.log("save filter");
          },
          buttons: showAdd
            ? [
                {
                  id: "workspace.button.feed",
                  icon: TableHeaderButtonsIconType.ADD,
                  type: "text",
                  color: "#2e6ccb",
                  isBlue: true,
                  onChange: () => setOpenFeedModal(true),
                },
                {
                  id: "common.customize",
                  icon: TableHeaderButtonsIconType.SETTING,
                  onChange: () => {},
                },
              ]
            : [
                {
                  id: "common.customize",
                  icon: TableHeaderButtonsIconType.SETTING,
                  onChange: () => {},
                },
              ],
        }}
        columns={column}
        rows={rows}
        maxHeight={"calc(100vh - 280px)"}
      />
      {<AddFeed open={openFeedModal} handleClose={() => setOpenFeedModal(false)} />}
    </>
  );
}
