import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/material";
import CustomTable from "components/CustomTable";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Column } from "types/models/Common";

import ConfigModal from "./ConfigModal";

const typesMockData = {
  risk: [
    {
      code: "HZ",
      name: "Hazard",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "HS",
      name: "Hazardous Situation",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "Harm",
      name: "Harm",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "SOE",
      name: "Sequence Of Events",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "FM",
      name: "Failure Mode",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "CS",
      name: "Cause",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "IRC",
      name: "Initial Risk Control",
      icon: "",
      description: "",
      system_default: "",
      fields: "Config",
    },
    {
      code: "RRC",
      name: "Residual Risk Control",
      icon: "",
      description: "",
      system_default: "",
      fields: "Config",
    },
  ],
  cyber: [
    {
      code: "HZ",
      name: "Hazard",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "HS",
      name: "Hazardous Situation",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "Harm",
      name: "Harm",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "SOE",
      name: "Sequence Of Events",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "FM",
      name: "Failure Mode",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "CS",
      name: "Cause",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "IRC",
      name: "Initial Risk Control",
      icon: "",
      description: "",
      system_default: "",
      fields: "Config",
    },
    {
      code: "RRC",
      name: "Residual Risk Control",
      icon: "",
      description: "",
      system_default: "",
      fields: "Config",
    },
  ],
  ac: [
    {
      code: "HZ",
      name: "Hazard",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "HS",
      name: "Hazardous Situation",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "Harm",
      name: "Harm",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "SOE",
      name: "Sequence Of Events",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "FM",
      name: "Failure Mode",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "CS",
      name: "Cause",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "IRC",
      name: "Initial Risk Control",
      icon: "",
      description: "",
      system_default: "",
      fields: "Config",
    },
    {
      code: "RRC",
      name: "Residual Risk Control",
      icon: "",
      description: "",
      system_default: "",
      fields: "Config",
    },
  ],
  production: [
    {
      code: "HZ",
      name: "Hazard",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "HS",
      name: "Hazardous Situation",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "Harm",
      name: "Harm",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "SOE",
      name: "Sequence Of Events",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "FM",
      name: "Failure Mode",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "CS",
      name: "Cause",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "IRC",
      name: "Initial Risk Control",
      icon: "",
      description: "",
      system_default: "",
      fields: "Config",
    },
    {
      code: "RRC",
      name: "Residual Risk Control",
      icon: "",
      description: "",
      system_default: "",
      fields: "Config",
    },
  ],
  if: [
    {
      code: "HZ",
      name: "Hazard",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "HS",
      name: "Hazardous Situation",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "Harm",
      name: "Harm",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "SOE",
      name: "Sequence Of Events",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "FM",
      name: "Failure Mode",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "CS",
      name: "Cause",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "IRC",
      name: "Initial Risk Control",
      icon: "",
      description: "",
      system_default: "",
      fields: "Config",
    },
    {
      code: "RRC",
      name: "Residual Risk Control",
      icon: "",
      description: "",
      system_default: "",
      fields: "Config",
    },
  ],
  dc: [
    {
      code: "UND",
      name: "User Need",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "UST",
      name: "User Story",
      icon: "",
      description: "",
      system_default: "No",
      fields: "Config",
    },
    {
      code: "SYS",
      name: "System Requirement",
      icon: "",
      description: "",
      system_default: "No",
      fields: "Config",
    },
    {
      code: "SW",
      name: "Software Requirement",
      icon: "",
      description: "",
      system_default: "No",
      fields: "Config",
    },
    {
      code: "HW",
      name: "Hardware Requirement",
      icon: "",
      description: "",
      system_default: "No",
      fields: "Config",
    },
    {
      code: "DS",
      name: "Design Specification",
      icon: "",
      description: "",
      system_default: "No",
      fields: "Config",
    },
    {
      code: "DO",
      name: "Design Output",
      icon: "",
      description: "",
      system_default: "No",
      fields: "Config",
    },
    {
      code: "TC",
      name: "Test Case",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "VAL",
      name: "Validation",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "VER",
      name: "Verification",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "BUG",
      name: "Defect",
      icon: "",
      description: "",
      system_default: "No",
      fields: "Config",
    },
    {
      code: "TSTPL",
      name: "Test Plan",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
    {
      code: "TSTRN",
      name: "Test Run",
      icon: "",
      description: "",
      system_default: "Yes",
      fields: "Config",
    },
  ],
};

const WorkitemTypes = () => {
  const params = useParams();
  const { type } = params;

  const [editRow, setEditRow] = React.useState<any>(null);
  const [showConfigModal, setShowConfigModal] = React.useState<boolean>(false);

  const columns: Column[] = [
    {
      id: "code",
      label: "common.code",
      minWidth: 100,
      align: "left",
    },
    {
      id: "name",
      label: "setting.product.column.name",
      minWidth: 200,
      align: "left",
    },
    {
      id: "icon",
      label: "common.columns.icon",
      minWidth: 100,
      align: "left",
    },
    {
      id: "description",
      label: "common.description",
      minWidth: 400,
      align: "left",
    },
    {
      id: "system_default",
      label: "common.columns.system.default",
      minWidth: 150,
      align: "left",
    },
    {
      id: "fields",
      label: "common.attributes",
      minWidth: 150,
      align: "left",
      format: (value, record) => {
        return (
          <Box
            sx={{ color: "#2e6ccb", cursor: "pointer" }}
            onClick={() => {
              setEditRow(record);
              setShowConfigModal(true);
            }}
          >
            {value}
          </Box>
        );
      },
    },
    {
      id: "actions",
      label: "setting.product.column.actions",
      align: "left",
      format: (value, record) => {
        if (record.system_default) {
          return (
            <Box>
              <EditIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
            </Box>
          );
        } else {
          return (
            <Box>
              <EditIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />{" "}
              <DeleteIcon sx={{ cursor: "pointer", fontSize: "16px" }} />
            </Box>
          );
        }
      },
    },
  ];

  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    if (type) {
      const rowData = typesMockData[type];
      if (rowData) {
        setRows(rowData);
      }
    }
    console.log(type);
  }, [type]);

  return (
    <>
      <CustomTable
        columns={columns}
        rows={rows}
        showPage={false}
        maxHeight="auto"
        addRow={() => {
          console.log("add row");
        }}
        addRowTextId="common.new.type"
      />
      {showConfigModal && (
        <ConfigModal
          open={showConfigModal}
          editRow={editRow}
          handleClose={() => {
            setShowConfigModal(false);
          }}
        />
      )}
    </>
  );
};

export default WorkitemTypes;
