
import { Box } from "@mui/material";
import Form from "@rjsf/mui";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import React from "react";

const schema: RJSFSchema = {
  "title": "A registration form",
  "type": "object",
  "required": [
    "firstName",
    "lastName"
  ],
  "properties": {
    "password": {
      "type": "string",
      "title": "Password"
    },
    "lastName": {
      "type": "string",
      "title": "Last name"
    },
    "bio": {
      "type": "string",
      "title": "Bio"
    },
    "firstName": {
      "type": "string",
      "title": "First name"
    },
    "age": {
      "type": "integer",
      "title": "Age"
    }
  }
};

const uiSchema: UiSchema = {
  "ui:order": [
    "firstName",
    "lastName",
    "*",
    "password"
  ],
  "age": {
    "ui:widget": "updown"
  },
  "bio": {
    "ui:widget": "textarea"
  },
  "password": {
    "ui:widget": "password"
  }
};

const formData: any = {
  "firstName": "Chuck",
  "lastName": "Norris",
  "age": 75,
  "bio": "Roundhouse kicking asses since 1940",
  "password": "noneed"
};

const log = (type) => console.log.bind(console, type);

export interface DynamicFormProps {
}
const DynamicForm: React.FC<DynamicFormProps> = (props) => {
  return (
    <>
      <Box
        className="detail-root"
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          width: "800px",
          "& .spec-detail": {
            flex: 1,
            padding: 2,
          },
          // "& .spec-attribute": {
          //   width: 232,
          //   padding: 2,
          //   backgroundColor: "#FAFAFA",
          //   borderLeft: "1px solid rgba(32,45,64,.04)",
          // },
        }}
      >
        <div className="spec-detail">
          <div className="spec-header"></div>
          <div className="spec-detail-wrapper">
            <Form schema={schema} uiSchema={uiSchema} formData={formData}
              validator={validator}
              onChange={log("changed")}
              onSubmit={log("submitted")}
              onError={log("errors")} />
          </div>
        </div>
        <div className="spec-attribute"></div>
      </Box>

    </>
  );
};

export default DynamicForm;
