import Icon from "@mui/material/Icon";
import * as React from "react";

const FillProduct40Svg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="usfy7xeyga">
        <stop stopColor="#088F62" offset="0%" />
        <stop stopColor="#51BCA5" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect fill="#287D8E" opacity=".1" width="40" height="40" rx="4" />
      <g transform="translate(8 8)">
        <rect fill="url(#usfy7xeyga)" width="24" height="24" rx="2" />
        <g transform="translate(4 4)" fill="#FFF">
          <rect width="6" height="6" rx="1" />
          <rect x="10" width="6" height="6" rx="1" />
          <rect opacity=".6" x="10" y="10" width="6" height="6" rx="1" />
          <rect y="10" width="6" height="6" rx="1" />
        </g>
      </g>
    </g>
  </svg>
);

const FillProduct40Icon: React.FunctionComponent = () => {
  return <Icon component={FillProduct40Svg} />;
};

export default FillProduct40Icon;
