import IntlMessages from "@crema/utility/IntlMessages";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useIntl } from "react-intl";
import { textColor } from "shared/constants/AppConst";

import Tree from "./Tree";

const LocationContainer = styled(Box)(({ theme }) => ({
  paddingRight: "16px",
  paddingBottom: "12px",
  paddingLeft: "16px",
  background: "#FAFAFA",
  ".deliverable-content": {
    display: "flex",
    width: "100%",
  },
}));

interface LocationProps {}

export default function Location(props: LocationProps) {
  const [selectProcess, setSelectProcess] = React.useState<any>(null);

  return (
    <LocationContainer>
      <Box
        className="w-full flex items-center"
        sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
      >
        <h3>
          <IntlMessages id="common.select.location.for.reused.deliverable" />
        </h3>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Box sx={{ color: "rgba(0,0,0,0.85)" }}>
          <IntlMessages id="products.form.destination.product" />:
        </Box>
        <Box sx={{ color: "rgba(0,0,0,0.85)", marginLeft: "8px" }}>Drug Delivery Device</Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Box sx={{ color: "rgba(0,0,0,0.85)" }}>
          <IntlMessages id="products.form.destination.variant" />:
        </Box>
        <Box sx={{ color: "rgba(0,0,0,0.85)", marginLeft: "8px" }}>Main</Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Box sx={{ color: "rgba(0,0,0,0.85)" }}>
          <IntlMessages id="products.form.destination.release" />:
        </Box>
        <Box sx={{ color: "rgba(0,0,0,0.85)", marginLeft: "8px" }}>R2.0</Box>
      </Box>
      <Box className="deliverable-content">
        <Tree handleSelectProcess={setSelectProcess} />
      </Box>
    </LocationContainer>
  );
}
