import Icon from "@mui/material/Icon";
import * as React from "react";

const FillProductTask40Svg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="qolonwv4na">
        <stop stopColor="#6656C2" offset="0%" />
        <stop stopColor="#9E8AED" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect fill="#5243AA" opacity=".1" width="40" height="40" rx="4" />
      <g transform="translate(4 4)">
        <path d="M0 0h32v32H0z" />
        <path
          d="M26 4a2 2 0 0 1 2 2v9.655a7.5 7.5 0 0 0-8.5 12.346L6 28a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h20z"
          fill="url(#qolonwv4na)"
        />
        <rect fill="#FFF" x="8" y="10" width="10" height="2" rx="1" />
        <rect fill="#FFF" x="8" y="16" width="6" height="2" rx="1" />
        <path d="M24 16c-3.323 0-6 2.677-6 6s2.708 6 6 6c3.323 0 6-2.677 6-6s-2.708-6-6-6z" fill="#7261CB" />
        <path
          d="M23.413 18.5a.924.924 0 0 0-.913.913v2.977c0 .364.23.717.562.85l3.15 1.424a.73.73 0 0 0 .37.072c.36 0 .669-.194.834-.524l.039-.12c.116-.44-.052-.853-.425-1.048l-2.704-1.225v-2.406a.924.924 0 0 0-.913-.913z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

const FillProductTask40Icon: React.FunctionComponent = () => {
  return <Icon component={FillProductTask40Svg} />;
};

export default FillProductTask40Icon;
