import { Box } from "@mui/material";
import TaskItemIcon from "components/Icons/TaskItemIcon";
import { statusBgColor } from "shared/constants/AppConst";
import { ModuleType } from "shared/constants/AppEnums";
import { Column, TreeTableBaseModel } from "types/models/Common";

export const FileIndexColumns: Column[] = [
  {
    id: "name",
    label: "common.name",
    minWidth: 300,
    align: "left",
  },
  {
    id: "docId",
    label: "common.columns.doc.id",
    minWidth: 120,
    align: "left",
  },
  {
    id: "version",
    label: "common.columns.doc.version",
    minWidth: 30,
    align: "left",
  },
  {
    id: "release",
    label: "table.column.release",
    minWidth: 30,
    align: "left",
  },
  {
    id: "description",
    label: "common.columns.doc.description",
    minWidth: 150,
    align: "left",
  },
  {
    id: "status",
    label: "common.status",
    minWidth: 150,
    align: "left",
    format: (value, record) => {
      if (value) {
        return (
          <Box className="flex items-center">
            <Box
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                background: statusBgColor[value.toLowerCase()],
              }}
            />
            <Box sx={{ ml: 1.5 }}>{value}</Box>
          </Box>
        );
      }
      return;
    },
  },
  // {
  //   id: "effectiveDate",
  //   label: "common.columns.effective.date",
  //   minWidth: 180,
  //   align: "left",
  // },
  // {
  //   id: "comments",
  //   label: "common.comments",
  //   minWidth: 200,
  //   align: "left",
  // },
];

export const FileIndexRows: { [key: string]: TreeTableBaseModel[] } = {
  "Design Controls": [
    {
      id: "000",
      name: "Design and Development Planning",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "010",
          name: "Product Development Plan",
          level: 2,
          isFolder: true,
          // children: [
          //   {
          //     id: "111",
          //     name: "Doc - Product Development Plan",
          //     level: 3,
          //     docId: "DOC-PDP-001",
          //     version: "V1.0",
          //     description: "Product Development Plan",
          //     effectiveDate: "12:23:34 9/12/2022",
          //     comments: "NA",
          //     processId: "",
          //     moduleId: ModuleType.DCD,
          //     rowClickable: true,
          //     status: "Open",
          //     startIcon: (
          //       <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
          //         <TaskItemIcon />
          //       </Box>
          //     ),
          //   },
          // ],
        },
        {
          id: "020",
          name: "Software Development Plan",
          level: 2,
          isFolder: true,
          // children: [
          //   {
          //     id: "121",
          //     name: "Doc - Software Development Plan",
          //     level: 3,
          //     docId: "DOC-SDP-001",
          //     version: "V1.0",
          //     description: "Software Development Plan",
          //     effectiveDate: "12:23:34 9/12/2022",
          //     comments: "NA",
          //     processId: "",
          //     moduleId: ModuleType.DCD,
          //     rowClickable: true,
          //     status: "Open",
          //     startIcon: (
          //       <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
          //         <TaskItemIcon />
          //       </Box>
          //     ),
          //   },
          // ],
        },
      ],
    },
    {
      id: "100",
      name: "Design Inputs",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "110",
          name: "User Needs",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "111",
              name: "Doc - User Needs",
              level: 3,
              docId: "DOC-UND-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "User Needs",
              moduleId: ModuleType.DCD,
              rowClickable: true,
              status: "Done",
              release: "R2.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              id: "112",
              name: "Doc - User Needs",
              level: 3,
              docId: "DOC-UND-002",
              version: "V2.0",
              description: "",
              effectiveDate: "14:23:34 10/28/2022",
              comments: "NA",
              processId: "User Needs",
              moduleId: ModuleType.DCD,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "120",
          name: "Design Input Requirements",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "121",
              name: "Doc - Design Input Requirements",
              level: 3,
              docId: "DOC-DIR-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Design Input Requirements",
              moduleId: ModuleType.DCD,
              status: "Done",
              release: "R3.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "200",
      name: "Design Outputs",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "210",
          name: "Design Specifications",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "211",
              name: "Doc - Design Specifications",
              level: 3,
              docId: "DOC-DS-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Design Specifications",
              moduleId: ModuleType.DCD,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "220",
          name: "DMR Design Outputs",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "221",
              name: "Doc - DMR Design Outputs",
              level: 3,
              docId: "DOC-DMR-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "DMR Design Outputs",
              status: "Draft",
              release: "R4.0",
              moduleId: ModuleType.DCD,
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "700",
      name: "Design Reviews",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "710",
          name: "Design Input Review",
          level: 2,
          isFolder: true,
        },
        {
          id: "720",
          name: "Design Verfication Review",
          level: 2,
          isFolder: true,
        },
        {
          id: "730",
          name: "Design Validation Review",
          level: 2,
          isFolder: true,
        },
      ],
    },
    {
      id: "300",
      name: "Design Verifications",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "310",
          name: "System Verifications",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "311",
              name: "Doc - System Verifications",
              level: 3,
              docId: "DOC-SV-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "System Verifications",
              moduleId: ModuleType.DCD,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "320",
          name: "HW Verifications",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "321",
              name: "Doc - HW Verifications",
              level: 3,
              docId: "DOC-HWV-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "HW Verifications",
              moduleId: ModuleType.DCD,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "330",
          name: "SW Verifications",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "331",
              name: "Doc - SW Verifications",
              level: 3,
              docId: "DOC-SWV-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "SW Verifications",
              moduleId: ModuleType.DCD,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "340",
          name: "Test Cases",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "341",
              name: "Doc - Test Cases",
              level: 3,
              docId: "DOC-TC-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Test Cases",
              moduleId: ModuleType.DCD,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "400",
      name: "Design Validations",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "410",
          name: "Validations",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "411",
              name: "Doc - Validations",
              level: 3,
              docId: "DOC-VAL-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Validations",
              moduleId: ModuleType.DCD,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "500",
      name: "Design Transfer",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "510",
          name: "IQ/OQ/PQ",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "511",
              name: "Doc - IQ/OQ/PQ",
              level: 3,
              docId: "DOC-3Q-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "IQ_OQ_PQ",
              moduleId: ModuleType.DCD,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "600",
      name: "Design Control Traceability",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "610",
          name: "Design Control Traceability Matrix",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "611",
              name: "Doc - Design Control Traceability Matrix",
              level: 3,
              docId: "DOC-DCTM-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Design Control Traceability Matrix",
              moduleId: ModuleType.DCD,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "620",
          name: "Essential Performance Traceability Matrix",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "621",
              name: "Doc - Essential Performance Traceability Matrix",
              level: 3,
              docId: "DOC-EPTM-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Essential Performance Traceability Matrix",
              moduleId: ModuleType.DCD,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "800",
      name: "Design Changes",
      level: 1,
      isFolder: true,
    },
  ],
  "Requirement Management": [
    {
      id: "100",
      name: "Design Inputs",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "110",
          name: "User Needs",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "111",
              name: "Doc - User Needs",
              level: 3,
              docId: "DOC-UND-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "User Needs",
              moduleId: ModuleType.REQ,
              release: "R2.0",
              rowClickable: true,
              status: "Done",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              id: "112",
              name: "Doc - User Needs",
              level: 3,
              docId: "DOC-UND-002",
              version: "V2.0",
              description: "",
              effectiveDate: "14:23:34 10/28/2022",
              comments: "NA",
              processId: "User Needs",
              moduleId: ModuleType.REQ,
              rowClickable: true,
              release: "R4.0",
              status: "Draft",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "120",
          name: "Design Input Requirements",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "121",
              name: "Doc - Design Input Requirements",
              level: 3,
              docId: "DOC-DIR-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Design Input Requirements",
              moduleId: ModuleType.REQ,
              release: "R3.0",
              status: "Done",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "200",
      name: "Design Outputs",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "210",
          name: "Design Specifications",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "211",
              name: "Doc - Design Specifications",
              level: 3,
              docId: "DOC-DS-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Design Specifications",
              moduleId: ModuleType.REQ,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "220",
          name: "DMR Design Outputs",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "221",
              name: "Doc - DMR Design Outputs",
              level: 3,
              docId: "DOC-DMR-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "DMR Design Outputs",
              status: "Draft",
              release: "R4.0",
              moduleId: ModuleType.REQ,
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "300",
      name: "Design Verifications",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "310",
          name: "System Verifications",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "311",
              name: "Doc - System Verifications",
              level: 3,
              docId: "DOC-SV-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "System Verifications",
              moduleId: ModuleType.REQ,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "320",
          name: "HW Verifications",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "321",
              name: "Doc - HW Verifications",
              level: 3,
              docId: "DOC-HWV-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "HW Verifications",
              moduleId: ModuleType.REQ,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "330",
          name: "SW Verifications",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "331",
              name: "Doc - SW Verifications",
              level: 3,
              docId: "DOC-SWV-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "SW Verifications",
              moduleId: ModuleType.REQ,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "340",
          name: "Test Cases",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "341",
              name: "Doc - Test Cases",
              level: 3,
              docId: "DOC-TC-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Test Cases",
              moduleId: ModuleType.REQ,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "400",
      name: "Design Validations",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "410",
          name: "Validations",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "411",
              name: "Doc - Validations",
              level: 3,
              docId: "DOC-VAL-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Validations",
              moduleId: ModuleType.REQ,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "500",
      name: "Design Transfer",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "510",
          name: "IQ/OQ/PQ",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "511",
              name: "Doc - IQ/OQ/PQ",
              level: 3,
              docId: "DOC-3Q-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "IQ_OQ_PQ",
              moduleId: ModuleType.REQ,
              status: "Draft",
              release: "R4.0",
              rowClickable: true,
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "600",
      name: "Design Control Traceability",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "610",
          name: "Design Control Traceability Matrix",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "611",
              name: "Doc - Design Control Traceability Matrix",
              level: 3,
              docId: "DOC-DCTM-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Design Control Traceability Matrix",
              moduleId: ModuleType.REQ,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "620",
          name: "Essential Performance Traceability Matrix",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "621",
              name: "Doc - Essential Performance Traceability Matrix",
              level: 3,
              docId: "DOC-EPTM-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Essential Performance Traceability Matrix",
              moduleId: ModuleType.REQ,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
  ],
  "Risk Management": [
    {
      id: "200",
      name: "Risk Management Plan",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "201",
          name: "Doc - Risk Management Plan",
          level: 2,
          docId: "DOC-Plan-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "Risk Management Plan",
          moduleId: ModuleType.RISK,
          rowClickable: true,
          status: "Draft",
          release: "R4.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
    {
      id: "300",
      name: "Safety Characteristic Analysis",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "301",
          name: "Doc - Safety Characteristic Analysis",
          level: 2,
          docId: "DOC-CharAnalysis-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "Safety Characteristic Analysis",
          moduleId: ModuleType.RISK,
          rowClickable: true,
          status: "Draft",
          release: "R4.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
    {
      id: "400",
      name: "Top Down Analysis",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "410",
          name: "System Hazard Analysis",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "411",
              name: "Doc - System Hazard Analysis",
              level: 3,
              docId: "DOC-SHA-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "System Hazard Analysis",
              moduleId: ModuleType.RISK,
              rowClickable: true,
              status: "Done",
              release: "R3.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
            {
              id: "412",
              name: "Doc - System Hazard Analysis",
              level: 3,
              docId: "DOC-SHA-002",
              version: "V2.0",
              description: "",
              effectiveDate: "14:23:34 10/28/2022",
              comments: "NA",
              processId: "System Hazard Analysis",
              moduleId: ModuleType.RISK,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "500",
      name: "Sub System Analysis",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "510",
          name: "Use Risk Analysis",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "511",
              name: "Doc - Use Risk Analysis",
              level: 3,
              docId: "DOC-URA-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Use Risk Analysis",
              moduleId: ModuleType.RISK,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "600",
      name: "Bottom Up Analysis",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "610",
          name: "Design FMEA",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "611",
              name: "Doc - Design FMEA",
              level: 3,
              docId: "DOC-DFMEA-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Design FMEA",
              moduleId: ModuleType.RISK,
              rowClickable: true,
              status: "Done",
              release: "R2.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "620",
          name: "Process FMEA",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "621",
              name: "Doc - Process FMEA",
              level: 3,
              docId: "DOC-PFMEA-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Process FMEA",
              moduleId: ModuleType.RISK,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "700",
      name: "Risk Assessment Summary",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "710",
          name: "Risk Assessment Summary Matrix",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "711",
              name: "Doc - Risk Assessment Summary Matrix",
              level: 3,
              docId: "DOC-RASM-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Risk Assessment Summary Matrix",
              moduleId: ModuleType.RISK,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "720",
          name: "Benefit vs Risk Analysis",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "721",
              name: "Doc - Benefit vs Risk Analysis",
              level: 3,
              docId: "DOC-BvsRA-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Benefit vs Risk Analysis",
              moduleId: ModuleType.RISK,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "800",
      name: "Risk Management Report",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "801",
          name: "Doc - Risk Management Report",
          level: 2,
          docId: "DOC-Report-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "Risk Management Report",
          moduleId: ModuleType.RISK,
          rowClickable: true,
          status: "Done",
          release: "R3.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
  ],
  "Cybersecurity Risk Management": [
    {
      id: "300",
      name: "Security Risk Management Plan",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "301",
          name: "Doc - Security Risk Management Plan",
          level: 2,
          docId: "DOC-SRMP-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "Security Risk Management Plan",
          moduleId: ModuleType.CYBER,
          rowClickable: true,
          status: "Draft",
          release: "R4.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
    {
      id: "400",
      name: "Top Down Analysis",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "410",
          name: "System Architecture Diagram",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "411",
              name: "Doc - System Architecture Diagram",
              level: 3,
              docId: "DOC-SAD-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "System Architecture Diagram",
              moduleId: ModuleType.CYBER,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "420",
          name: "Security Asset and Characteristic Analysis",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "421",
              name: "Doc - Security Asset and Characteristic Analysis",
              level: 3,
              docId: "DOC-SACA-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Security Asset and Characteristic Analysis",
              moduleId: ModuleType.CYBER,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
  ],
  "Assurance Case Management": [],
  "Production Management": [],
  "Index File Management": [
    {
      id: "700",
      name: "510(k) Submission(s)",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "701",
          name: "Doc - 510(k) Submission(s)",
          level: 2,
          docId: "DOC-510k-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "510(k) Submission(s)",
          moduleId: ModuleType.TF,
          rowClickable: true,
          status: "Draft",
          release: "R4.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
    {
      id: "710",
      name: "PMA Submission(s)",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "711",
          name: "Doc - PMA Submission(s)",
          level: 2,
          docId: "DOC-PMA-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "PMA Submission(s)",
          moduleId: ModuleType.TF,
          status: "Draft",
          release: "R4.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
    {
      id: "770",
      name: "NDA Submission(s)",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "771",
          name: "Doc - NDA Submission(s)",
          level: 2,
          docId: "DOC-NDA-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "NDA Submission(s)",
          moduleId: ModuleType.TF,
          status: "Draft",
          release: "R4.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
    {
      id: "780",
      name: "BLA Submission(s)",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "781",
          name: "Doc - BLA Submission(s)",
          level: 2,
          docId: "DOC-BLA-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "BLA Submission(s)",
          moduleId: ModuleType.TF,
          rowClickable: true,
          status: "Draft",
          release: "R4.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
    {
      id: "720",
      name: "MDR Technical File",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "721",
          name: "Doc - MDR Technical File",
          level: 2,
          docId: "DOC-MDR-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "MDR Technical File",
          moduleId: ModuleType.TF,
          rowClickable: true,
          status: "Draft",
          release: "R4.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
    {
      id: "730",
      name: "Design History File",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "Design History File",
          isModule: true,
          name: "Doc - Design History File",
          level: 2,
          docId: "DOC-DHF-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "Design History File",
          moduleId: ModuleType.TF,
          rowClickable: true,
          status: "Done",
          release: "R2.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
    {
      id: "740",
      name: "Risk Management File",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "741",
          name: "Doc - Risk Management File",
          level: 2,
          docId: "DOC-RMF-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "Risk Management File",
          moduleId: ModuleType.TF,
          rowClickable: true,
          status: "Draft",
          release: "R4.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
    {
      id: "750",
      name: "DMR - Device Master Records",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "751",
          name: "Doc - DMR - Device Master Records",
          level: 2,
          docId: "DOC-DMR-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "DMR - Device Master Records",
          moduleId: ModuleType.TF,
          rowClickable: true,
          status: "Draft",
          release: "R4.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
    {
      id: "760",
      name: "DHR - Device History Records",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "761",
          name: "Doc - DHR - Device History Records",
          level: 2,
          docId: "DOC-DHR-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "DHR - Device History Records",
          moduleId: ModuleType.TF,
          rowClickable: true,
          status: "Draft",
          release: "R4.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
    {
      id: "790",
      name: "MBR Master Batch Records",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "791",
          name: "Doc - MBR Master Batch Records",
          level: 2,
          docId: "DOC-MBR-001",
          version: "V1.0",
          description: "",
          effectiveDate: "12:23:34 9/12/2022",
          comments: "NA",
          processId: "MBR Master Batch Records",
          moduleId: ModuleType.TF,
          status: "Draft",
          release: "R4.0",
          startIcon: (
            <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
              <TaskItemIcon />
            </Box>
          ),
        },
      ],
    },
  ],
  // specially process "Design History File" displays all index files
  "Design History File": [
    {
      id: "1000",
      name: "Requirement Management",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "1100",
          name: "Design Inputs",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "1110",
              name: "User Needs",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "1111",
                  name: "Doc - User Needs",
                  level: 4,
                  docId: "DOC-UND-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "User Needs",
                  moduleId: ModuleType.REQ,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
                {
                  id: "1112",
                  name: "Doc - User Needs",
                  level: 4,
                  docId: "DOC-UND-002",
                  version: "V2.0",
                  description: "",
                  effectiveDate: "14:23:34 10/28/2022",
                  comments: "NA",
                  processId: "User Needs",
                  moduleId: ModuleType.REQ,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
            {
              id: "1120",
              name: "Design Input Requirements",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "1121",
                  name: "Doc - Design Input Requirements",
                  level: 4,
                  docId: "DOC-DIR-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "Design Input Requirements",
                  moduleId: ModuleType.REQ,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
          ],
        },
        {
          id: "1200",
          name: "Design Outputs",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "1210",
              name: "Design Specifications",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "1211",
                  name: "Doc - Design Specifications",
                  level: 4,
                  docId: "DOC-DS-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "Design Specifications",
                  moduleId: ModuleType.REQ,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
            {
              id: "1220",
              name: "DMR Design Outputs",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "1221",
                  name: "Doc - DMR Design Outputs",
                  level: 4,
                  docId: "DOC-DMR-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "DMR Design Outputs",
                  moduleId: ModuleType.REQ,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
          ],
        },
        {
          id: "1300",
          name: "Design Verifications",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "1310",
              name: "System Verifications",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "1311",
                  name: "Doc - System Verifications",
                  level: 4,
                  docId: "DOC-SV-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "System Verifications",
                  moduleId: ModuleType.REQ,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
            {
              id: "1320",
              name: "HW Verifications",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "1321",
                  name: "Doc - HW Verifications",
                  level: 4,
                  docId: "DOC-HWV-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "HW Verifications",
                  moduleId: ModuleType.REQ,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
            {
              id: "1330",
              name: "SW Verifications",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "1331",
                  name: "Doc - SW Verifications",
                  level: 4,
                  docId: "DOC-SWV-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "SW Verifications",
                  moduleId: ModuleType.REQ,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
            {
              id: "1340",
              name: "Test Cases",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "1341",
                  name: "Doc - Test Cases",
                  level: 4,
                  docId: "DOC-TC-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "Test Cases",
                  moduleId: ModuleType.REQ,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
          ],
        },
        {
          id: "1400",
          name: "Design Validations",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "1410",
              name: "Validations",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "1411",
                  name: "Doc - Validations",
                  level: 4,
                  docId: "DOC-VAL-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "Validations",
                  moduleId: ModuleType.REQ,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
          ],
        },
        {
          id: "1500",
          name: "Design Transfer",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "1510",
              name: "IQ/OQ/PQ",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "1511",
                  name: "Doc - IQ/OQ/PQ",
                  level: 4,
                  docId: "DOC-3Q-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "IQ_OQ_PQ",
                  moduleId: ModuleType.REQ,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
          ],
        },
        {
          id: "1600",
          name: "Design Control Traceability",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "1610",
              name: "Design Control Traceability Matrix",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "1611",
                  name: "Doc - Design Control Traceability Matrix",
                  level: 4,
                  docId: "DOC-DCTM-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "Design Control Traceability Matrix",
                  moduleId: ModuleType.REQ,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
            {
              id: "1620",
              name: "Essential Performance Traceability Matrix",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "1621",
                  name: "Doc - Essential Performance Traceability Matrix",
                  level: 4,
                  docId: "DOC-EPTM-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "Essential Performance Traceability Matrix",
                  moduleId: ModuleType.REQ,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "2000",
      name: "Risk Management",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "2200",
          name: "Risk Management Plan",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "2201",
              name: "Doc - Risk Management Plan",
              level: 3,
              docId: "DOC-Plan-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Risk Management Plan",
              moduleId: ModuleType.RISK,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "2300",
          name: "Safety Characteristic Analysis",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "2301",
              name: "Doc - Safety Characteristic Analysis",
              level: 3,
              docId: "DOC-CharAnalysis-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Safety Characteristic Analysis",
              moduleId: ModuleType.RISK,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "2400",
          name: "Top Down Analysis",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "2410",
              name: "System Hazard Analysis",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "2411",
                  name: "Doc - System Hazard Analysis",
                  level: 4,
                  docId: "DOC-SHA-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "System Hazard Analysis",
                  moduleId: ModuleType.RISK,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
                {
                  id: "2412",
                  name: "Doc - System Hazard Analysis",
                  level: 4,
                  docId: "DOC-SHA-002",
                  version: "V2.0",
                  description: "",
                  effectiveDate: "14:23:34 10/28/2022",
                  comments: "NA",
                  processId: "System Hazard Analysis",
                  moduleId: ModuleType.RISK,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
          ],
        },
        {
          id: "2500",
          name: "Sub System Analysis",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "2510",
              name: "Use Risk Analysis",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "2511",
                  name: "Doc - Use Risk Analysis",
                  level: 4,
                  docId: "DOC-URA-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "Use Risk Analysis",
                  moduleId: ModuleType.RISK,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
          ],
        },
        {
          id: "2600",
          name: "Bottom Up Analysis",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "2610",
              name: "Design FMEA",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "2611",
                  name: "Doc - Design FMEA",
                  level: 4,
                  docId: "DOC-DFMEA-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "Design FMEA",
                  moduleId: ModuleType.RISK,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
            {
              id: "2620",
              name: "Process FMEA",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "2621",
                  name: "Doc - Process FMEA",
                  level: 4,
                  docId: "DOC-PFMEA-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "Process FMEA",
                  moduleId: ModuleType.RISK,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
          ],
        },
        {
          id: "2700",
          name: "Risk Assessment Summary",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "2710",
              name: "Risk Assessment Summary Matrix",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "2711",
                  name: "Doc - Risk Assessment Summary Matrix",
                  level: 4,
                  docId: "DOC-RASM-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "Risk Assessment Summary Matrix",
                  moduleId: ModuleType.RISK,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
            {
              id: "2720",
              name: "Benefit vs Risk Analysis",
              level: 3,
              isFolder: true,
              children: [
                {
                  id: "2721",
                  name: "Doc - Benefit vs Risk Analysis",
                  level: 4,
                  docId: "DOC-BvsRA-001",
                  version: "V1.0",
                  description: "",
                  effectiveDate: "12:23:34 9/12/2022",
                  comments: "NA",
                  processId: "Benefit vs Risk Analysis",
                  moduleId: ModuleType.RISK,
                  rowClickable: true,
                  status: "Draft",
                  release: "R4.0",
                  startIcon: (
                    <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                      <TaskItemIcon />
                    </Box>
                  ),
                },
              ],
            },
          ],
        },
        {
          id: "2800",
          name: "Risk Management Report",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "2801",
              name: "Doc - Risk Management Report",
              level: 3,
              docId: "DOC-Report-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Risk Management Report",
              moduleId: ModuleType.RISK,
              rowClickable: true,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "3000",
      name: "Cybersecurity Risk Management",
      level: 1,
      isFolder: true,
      children: [],
    },
    {
      id: "4000",
      name: "Assurance Case Management",
      level: 1,
      isFolder: true,
      children: [],
    },
    {
      id: "5000",
      name: "Design Controls",
      level: 1,
      isFolder: true,
      children: [],
    },
    {
      id: "6000",
      name: "Production Management",
      level: 1,
      isFolder: true,
      children: [],
    },
    {
      id: "7000",
      name: "Index File Management",
      level: 1,
      isFolder: true,
      children: [
        {
          id: "7010",
          name: "510(k) Submission(s)",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "7011",
              name: "Doc - 510(k) Submission(s)",
              level: 3,
              docId: "DOC-510k-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "510(k) Submission(s)",
              moduleId: ModuleType.TF,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "7020",
          name: "PMA Submission(s)",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "7021",
              name: "Doc - PMA Submission(s)",
              level: 3,
              docId: "DOC-PMA-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "PMA Submission(s)",
              moduleId: ModuleType.TF,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "7030",
          name: "NDA Submission(s)",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "7031",
              name: "Doc - NDA Submission(s)",
              level: 3,
              docId: "DOC-NDA-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "NDA Submission(s)",
              moduleId: ModuleType.TF,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "7040",
          name: "BLA Submission(s)",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "7041",
              name: "Doc - BLA Submission(s)",
              level: 3,
              docId: "DOC-BLA-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "BLA Submission(s)",
              moduleId: ModuleType.TF,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "7050",
          name: "MDR Technical File",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "7051",
              name: "Doc - MDR Technical File",
              level: 3,
              docId: "DOC-MDR-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "MDR Technical File",
              moduleId: ModuleType.TF,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "7060",
          name: "Design History File",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "Design History File",
              isModule: true,
              name: "Doc - Design History File",
              level: 3,
              docId: "DOC-DHF-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Design History File",
              moduleId: ModuleType.TF,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "7070",
          name: "Risk Management File",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "7071",
              name: "Doc - Risk Management File",
              level: 3,
              docId: "DOC-RMF-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "Risk Management File",
              moduleId: ModuleType.TF,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "7080",
          name: "DMR - Device Master Records",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "7081",
              name: "Doc - DMR - Device Master Records",
              level: 3,
              docId: "DOC-DMR-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "DMR - Device Master Records",
              moduleId: ModuleType.TF,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "7090",
          name: "DHR - Device History Records",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "7091",
              name: "Doc - DHR - Device History Records",
              level: 3,
              docId: "DOC-DHR-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "DHR - Device History Records",
              moduleId: ModuleType.TF,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
        {
          id: "7100",
          name: "MBR Master Batch Records",
          level: 2,
          isFolder: true,
          children: [
            {
              id: "7101",
              name: "Doc - MBR Master Batch Records",
              level: 3,
              docId: "DOC-MBR-001",
              version: "V1.0",
              description: "",
              effectiveDate: "12:23:34 9/12/2022",
              comments: "NA",
              processId: "MBR Master Batch Records",
              moduleId: ModuleType.TF,
              status: "Draft",
              release: "R4.0",
              startIcon: (
                <Box sx={{ width: "16px", height: "16px", mt: "2px", mr: 1.5 }}>
                  <TaskItemIcon />
                </Box>
              ),
            },
          ],
        },
      ],
    },
  ],
};
