import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { Box, Checkbox } from "@mui/material";
import CustomTable from "components/CustomTable";
import { TableToolBarModel } from "components/CustomTable/CustomTableModel";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

const sopsMockData = {
  risk: {
    rows: [
      {
        id: "1",
        name: "System Hazard Analysis",
        version: "1.2",
        status: "Effective",
      },
      {
        id: "2",
        name: "Design FMEA",
        version: "1.1",
        status: "Effective",
      },
      {
        id: "3",
        name: "Process FMEA",
        version: "1.1",
        status: "Effective",
      },
    ],
  },
  cyber: {
    rows: [
      {
        id: "1",
        name: "System Hazard Analysis",
        version: "1.2",
        status: "Effective",
      },
      {
        id: "2",
        name: "Design FMEA",
        version: "1.1",
        status: "Effective",
      },
      {
        id: "3",
        name: "Process FMEA",
        version: "1.1",
        status: "Effective",
      },
    ],
  },
  ac: {
    rows: [
      {
        id: "1",
        name: "System Hazard Analysis",
        version: "1.2",
        status: "Effective",
      },
      {
        id: "2",
        name: "Design FMEA",
        version: "1.1",
        status: "Effective",
      },
      {
        id: "3",
        name: "Process FMEA",
        version: "1.1",
        status: "Effective",
      },
    ],
  },
  production: {
    rows: [
      {
        id: "1",
        name: "System Hazard Analysis",
        version: "1.2",
        status: "Effective",
      },
      {
        id: "2",
        name: "Design FMEA",
        version: "1.1",
        status: "Effective",
      },
      {
        id: "3",
        name: "Process FMEA",
        version: "1.1",
        status: "Effective",
      },
    ],
  },
  if: {
    rows: [
      {
        id: "1",
        name: "System Hazard Analysis",
        version: "1.2",
        status: "Effective",
      },
      {
        id: "2",
        name: "Design FMEA",
        version: "1.1",
        status: "Effective",
      },
      {
        id: "3",
        name: "Process FMEA",
        version: "1.1",
        status: "Effective",
      },
    ],
  },
  dc: {
    rows: [
      {
        id: "1",
        name: "System Hazard Analysis",
        version: "1.2",
        status: "Effective",
      },
      {
        id: "2",
        name: "Design FMEA",
        version: "1.1",
        status: "Effective",
      },
      {
        id: "3",
        name: "Process FMEA",
        version: "1.1",
        status: "Effective",
      },
    ],
  },
  req: {
    rows: [],
  },
};

const columns: Column[] = [
  {
    id: "name",
    label: "common.name",
    minWidth: 300,
    align: "left",
  },
  {
    id: "version",
    label: "workspace.form.version",
    minWidth: 50,
    align: "left",
  },
  {
    id: "status",
    label: "workspace.form.status",
    minWidth: 80,
    align: "left",
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    minWidth: 100,
    align: "left",
    format: (value, record) => {
      return (
        <Box>
          <EditIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
          <DeleteIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
          <NorthIcon sx={{ mr: 2, cursor: "pointer", fontSize: "16px" }} />
          <SouthIcon sx={{ cursor: "pointer", fontSize: "16px" }} />
        </Box>
      );
    },
  },
];

const toolbar: TableToolBarModel = {
  buttons: [
    {
      id: "common.customize",
      icon: TableHeaderButtonsIconType.SETTING,
      type: "outlined",
      onChange: () => {},
    },
  ],
};

const Sops = () => {
  const params = useParams();
  const { type } = params;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (type) {
      const mockData = sopsMockData[type];
      if (mockData) {
        setRows(mockData.rows);
      }
    }
  }, [type]);

  return (
    <Box>
      <CustomTable
        toolBar={toolbar}
        columns={columns}
        rows={rows}
        showPage={false}
        maxHeight="auto"
        addRow={() => {}}
        addRowTextId="common.add"
      />
    </Box>
  );
};

export default Sops;
