import IntlMessages from "@crema/utility/IntlMessages";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { Box, Breadcrumbs, Chip, InputAdornment, MenuItem, Select } from "@mui/material";
import Link from "@mui/material/Link";
import ButtonMenu from "components/ButtonMenu";
import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import ForwardAndBackward from "components/ForwardAndBackward";
import SearchStarredAndRecently from "components/SearchStarredAndRecently";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { handleMenu, handleStage, handleVariant, handleVersion } from "redux/actions";
import { AppState } from "redux/store";
import { borderColor, borderRadius, chipColor, textColor } from "shared/constants/AppConst";

import MoreAction from "../components/MoreAction";
import VariantSelect from "../components/VariantSelect";
import { VariantSelectItemModel } from "../components/VariantSelect/VariantSelectModel";

const menuList: ButtonMenuListItemModel[] = [
  {
    id: "1",
    labelId: "common.deliverables",
    icon: <AddLinkIcon />,
    url: "/products/123/deliverables/Design Controls/index",
  },
  {
    id: "2",
    labelId: "common.lifecycle",
    icon: <AddLinkIcon />,
    url: "/products/123/lifecycle/project",
  },
  {
    id: "3",
    labelId: "common.relationships",
    icon: <AddLinkIcon />,
    url: "/products/123/relationships",
  },
  {
    id: "4",
    labelId: "common.dashboard",
    icon: <AddLinkIcon />,
    url: "/products/123/dashboard",
  },
  // {
  //   id: "8",
  //   labelId: "common.workitems",
  //   icon: <AddLinkIcon />,
  //   url: "/products/123/workitems",
  // },
  {
    id: "7",
    labelId: "common.deliverable.templates",
    icon: <AddLinkIcon />,
    url: "/products/123/processSettings",
  },
];

const deepFind =
  (pred) =>
  ([x, ...xs] = []) =>
    x && (pred(x) ? x : deepFind(pred)(x.children) || deepFind(pred)(xs));

const findById = (id) => (dataSet) => deepFind((o) => o.id === id)(dataSet);

const ProductLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { version, productsDetailMenu, variant, stage } = useSelector<AppState, AppState["products"]>(
    ({ products }) => products
  );

  const [selectedValue, setSelectedValue] = React.useState<ButtonMenuListItemModel>({
    id: "2",
    labelId: "common.deliverables",
    icon: <AddLinkIcon />,
  });

  const variants: VariantSelectItemModel[] = [
    {
      id: "102",
      label: "Main",
      children: [
        {
          id: "101",
          label: "EU",
          releaseList: [
            {
              id: "101",
              label: "EU R2.2 (Head)",
              status: "Production",
            },
            {
              id: "102",
              label: "EU R2.1",
              status: "Post-Production",
            },
          ],
        },
        {
          id: "100",
          label: "China",
          releaseList: [
            {
              id: "101",
              label: "CN R3.3 (Head)",
              status: "Production",
            },
            {
              id: "102",
              label: "CN R3.2",
              status: "Post-Production",
            },
            {
              id: "103",
              label: "CN R3.1",
              status: "Post-Production",
            },
          ],
        },
        {
          id: "103",
          label: "US",
          releaseList: [
            {
              id: "101",
              label: "US R4.2 (Head)",
              status: "Production",
            },
            {
              id: "102",
              label: "US R4.1",
              status: "Post-Production",
            },
          ],
        },
      ],
      releaseList: [
        {
          id: "102",
          label: "R4.0 (Head)",
          status: "Production",
        },
        {
          id: "101",
          label: "R3.0",
          status: "Post-Production",
        },
        {
          id: "100",
          label: "R2.0",
          status: "Post-Production",
        },
      ],
    },
  ];
  const [releaseList, setReleaseList] = React.useState(variants[0].releaseList);

  React.useEffect(() => {
    if (location?.pathname) {
      console.log(location);
      const currentMenu = menuList.filter((item) => {
        if (item.url === location.pathname) {
          return true;
        } else if (item.url.indexOf("lifecycle") !== -1 && location.pathname.indexOf("lifecycle") !== -1) {
          return true;
        } else if (item.url.indexOf("feeds") !== -1 && location.pathname.indexOf("feeds") !== -1) {
          return true;
        } else if (item.url.indexOf("activities") !== -1 && location.pathname.indexOf("activities") !== -1) {
          return true;
        } else if (item.url.indexOf("admin") !== -1 && location.pathname.indexOf("admin") !== -1) {
          return true;
        } else if (item.url.indexOf("deliverables") !== -1 && location.pathname.indexOf("deliverables") !== -1) {
          return true;
        }
        return false;
      })[0];
      const newCurrentMenu = currentMenu ? { ...currentMenu } : null;
      if (newCurrentMenu) {
        delete newCurrentMenu.url;
        newCurrentMenu && setSelectedValue(newCurrentMenu);
      }
    } else {
      const currentMenu = menuList.filter((item) => item.id === productsDetailMenu)[0];
      currentMenu && setSelectedValue(currentMenu);
    }
  }, [location?.pathname]);

  React.useEffect(() => {
    return () => {
      dispatch(handleMenu(menuList[0].id.toString()));
      dispatch(handleVersion(releaseList[0].id.toString()));
      dispatch(handleStage(releaseList[0].status.toString()));
      dispatch(handleVariant(variants[0].id.toString()));
    };
  }, []);

  const handleSelectedValue = (item: ButtonMenuListItemModel) => {
    setSelectedValue(item);
    dispatch(handleMenu(item.id.toString()));
  };

  const handleChange = (e) => {
    dispatch(handleVersion(e.target.value));
    releaseList.map((item) => {
      if (e.target.value === item.id) {
        dispatch(handleStage(item.status.toString()));
      }
    });
  };

  const handleChangeVariant = (value: string | number) => {
    const currentItem = findById(value)(variants);
    if (currentItem) {
      setReleaseList(currentItem.releaseList);
      dispatch(handleVersion(currentItem.releaseList[0].id.toString()));
      dispatch(handleStage(currentItem.releaseList[0].status.toString()));
    }
    dispatch(handleVariant(value.toString()));
  };

  return (
    <Box
      className="product-layout"
      sx={{
        height: "calc(100vh - 57px)",
        // overflow: "hidden",
      }}
    >
      <Box
        className="product-info-bar"
        sx={{
          px: (theme) => theme.spacing(5),
          height: 48,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 0,
          position: "relative",
          zIndex: 100,
          background: (theme) => theme.palette.background.paper,
          boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.1)",
          borderBottom: `1px solid  ${borderColor.dividerColor}`,
          "& .left-wrapper": {
            flex: 1,
          },
        }}
      >
        <ForwardAndBackward />
        <Box className="breadcrumb-navigation-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color={textColor[65]} href="/products">
              <IntlMessages id="menu.product" />
            </Link>
            <SearchStarredAndRecently
              current="Thermometers"
              placeholderId="common.search.product"
              starredList={[
                {
                  id: "1",
                  name: "Thermometers",
                  url: "/products/123/profile",
                },
                {
                  id: "2",
                  name: "Drug Delivery Device",
                  url: "/products/123/profile",
                },
                {
                  id: "3",
                  name: "Pen Injector",
                  url: "/products/123/profile",
                },
              ]}
              recentlyList={[
                {
                  id: "1",
                  name: "Infusion Pump",
                  url: "/products/123/profile",
                },
                {
                  id: "2",
                  name: "Syringe",
                  url: "/products/123/profile",
                },
              ]}
              starredTitleId="products.starred.products"
              recentlyTitleId="products.recently.viewed"
              viewButtonTextId="common.view.all.products"
              handleViewButton={() => {
                navigate("/products");
              }}
            />
            <VariantSelect value={variant} onChange={handleChangeVariant} variants={variants} />
            {/* <Select
              sx={{
                mx: 0,
                minWidth: 50,
                background: "#fff",
                "& .MuiInputAdornment-root": {
                  color: textColor[85],
                },
                "& .MuiInputBase-input": {
                  padding: "0px",
                  color: textColor[85],
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              id="template-version-select"
              value={variant}
              label=""
              onChange={handleChangeVariant}
              // startAdornment={<InputAdornment position="start">Variant:</InputAdornment>}
            >
              {variants.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </Select> */}
            <Select
              sx={{
                mr: 0,
                minWidth: 50,
                background: "#fff",
                "& .MuiInputAdornment-root": {
                  color: textColor[85],
                },
                "& .MuiTypography-body1": {
                  color: textColor[85],
                },
                "& .MuiInputBase-input": {
                  padding: "0px",
                  color: textColor[85],
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              id="template-version-select"
              value={version}
              label=""
              onChange={handleChange}
              startAdornment={<InputAdornment position="start">Release:</InputAdornment>}
            >
              {releaseList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                  {/* <Chip
                    size="small"
                    label={item.status}
                    sx={{
                      textAlign: "center",
                      background: chipColor.stageBgColor,
                      color: chipColor.stage,
                      m: 2,
                      height: "20px",
                      borderRadius: borderRadius.main,
                      margin: "0px 8px",
                    }}
                  /> */}
                </MenuItem>
              ))}
            </Select>
          </Breadcrumbs>
        </Box>
        <Box
          sx={{
            color: textColor[85],
          }}
        >
          <IntlMessages id="common.stage" />：
          <Chip
            size="small"
            label={stage}
            sx={{
              textAlign: "center",
              background: chipColor.stageBgColor,
              color: chipColor.stage,
              mr: 2,
              height: "20px",
              borderRadius: borderRadius.main,
            }}
          />
        </Box>
        <Box className="left-wrapper">
          <MoreAction />
        </Box>
        <Box className="right-wrapper">
          <ButtonMenu
            selectedValue={selectedValue}
            setSelectedValue={handleSelectedValue}
            list={menuList}
            sxStyle={{
              sx: {
                background: "#576887",
                color: "rgba(255, 255, 255, 0.85)",
                borderRadius: "4px",
              },
            }}
          />
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
};

export default ProductLayout;
