import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import MyTreeTable from "components/MyTreeTable";
import React from "react";
import { boxShadow, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import ContentHeader from "../components/ContentHeader";

const AccessControlContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  ".tree-table-container": {
    background: theme.palette.background.paper,
    width: "calc(100% - 40px)",
    height: "calc(100vh - 192px)",
    margin: theme.spacing(5),
    boxShadow: boxShadow.cardBoxShadow,
    padding: "20px",
  },
}));

const AccessControl = () => {
  const [editRow, setEditRow] = React.useState<any>(null);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);

  const [columns, setColumns] = React.useState<Column[]>([
    {
      id: "title",
      label: "common.module.title",
      minWidth: 300,
      align: "left",
    },
    {
      id: "description",
      label: "common.description",
      minWidth: 300,
      align: "left",
    },
    {
      id: "view_roles",
      label: "common.view.roles",
      minWidth: 150,
      align: "left",
    },
    {
      id: "add_roles",
      label: "common.add.roles",
      minWidth: 150,
      align: "left",
    },
    {
      id: "edit_roles",
      label: "common.edit.roles",
      minWidth: 150,
      align: "left",
    },
    {
      id: "delete_roles",
      label: "common.delete.roles",
      minWidth: 150,
      align: "left",
    },
    {
      id: "customize_roles",
      label: "common.customize.roles",
      minWidth: 150,
      align: "left",
    },
    {
      id: "action",
      label: "setting.product.column.actions",
      minWidth: 100,
      align: "left",
      format: (_, record) => {
        if (record.canEdit) {
          return (
            <AppSvgIcon
              className="cursor-pointer"
              onClick={() => {
                setEditRow(record);
                setShowEditModal(true);
              }}
              size={16}
              color={textColor["65"]}
            >
              {"material-solid:edit"}
            </AppSvgIcon>
          );
        }
        return "";
      },
    },
  ]);

  const [rows, setRows] = React.useState<any[]>([
    {
      id: "1",
      level: 1,
      title: "Company Setup",
      description: "",
      view_roles: "",
      add_roles: "",
      edit_roles: "",
      delete_roles: "",
      customize_roles: "",
      canEdit: false,
    },
    {
      id: "2",
      level: 1,
      title: "RMF Setup and Management",
      description: "",
      view_roles: "",
      add_roles: "",
      edit_roles: "",
      delete_roles: "",
      customize_roles: "",
      canEdit: false,
    },
    {
      id: "3",
      level: 1,
      title: "Task Setup",
      description: "",
      view_roles: "",
      add_roles: "",
      edit_roles: "",
      delete_roles: "",
      customize_roles: "",
      canEdit: false,
      children: [
        {
          id: "3.1",
          level: 2,
          title: "Task Owner Assignment",
          description: "The rights to assign/change a task owner.",
          view_roles: "Admin | Super User | Product Managers | Task Owner | Task Reviewer | Normal User",
          add_roles: "",
          edit_roles: "Admin | Super User | Product Managers",
          delete_roles: "",
          customize_roles: "Admin",
          canEdit: true,
        },
      ],
    },
    {
      id: "4",
      level: 1,
      title: "Risk & AC",
      description: "",
      view_roles: "",
      add_roles: "",
      edit_roles: "",
      delete_roles: "",
      customize_roles: "",
      canEdit: false,
      children: [
        {
          id: "4.1",
          level: 2,
          title: "Top Down And Up Management",
          description: "",
          view_roles: "",
          add_roles: "",
          edit_roles: "",
          delete_roles: "",
          customize_roles: "",
          canEdit: false,
          children: [
            {
              id: "4.1.1",
              level: 3,
              title: "Add Nodes",
              description: "Do these control all the adds for index tree, table view, Ac graphic, and FTA graphic?",
              view_roles: "",
              add_roles: "",
              edit_roles: "",
              delete_roles: "",
              customize_roles: "",
              canEdit: false,
            },
            {
              id: "4.1.2",
              level: 3,
              title: "Top Down Analysis",
              description: "",
              view_roles: "",
              add_roles: "",
              edit_roles: "",
              delete_roles: "",
              customize_roles: "",
              canEdit: false,
              children: [
                {
                  id: "4.1.2.1",
                  level: 4,
                  title: "Top Down, Bottom up and Evidence Tree Panel",
                  description: "",
                  view_roles: "Admin | Super User | Product Managers | Task Owner | Task Reviewer | Normal User",
                  add_roles: "",
                  edit_roles: "Admin | Super User | Product Managers | Task Owner | Task Reviewer | Normal User",
                  delete_roles: "",
                  customize_roles: "Admin",
                  canEdit: true,
                },
                {
                  id: "4.1.2.2",
                  level: 4,
                  title: "AC Table Tab",
                  description: "",
                  view_roles: "Admin | Super User | Product Managers | Task Owner | Task Reviewer | Normal User",
                  add_roles: "",
                  edit_roles: "Admin | Super User | Product Managers | Task Owner | Task Reviewer | Normal User",
                  delete_roles: "",
                  customize_roles: "Admin",
                  canEdit: true,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return (
    <AccessControlContainer>
      <ContentHeader titleId="admin.nav.item.accessControl" />
      <Box className="tree-table-container">
        <MyTreeTable columns={columns} rows={rows} maxHeight="calc(100vh - 255px)" showPage={false} />
      </Box>
    </AccessControlContainer>
  );
};

export default AccessControl;
