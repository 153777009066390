import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Card, CardContent, CardHeader, Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { textColor } from "shared/constants/AppConst";
import * as yup from "yup";

import u100 from "../../../../assets/imgs/u1000.png";
// import u100 from "assert/imgs/u1000.png";

const ProcessFlowContainer = styled(Box)(({ theme }) => ({}));
const CardContainer = styled(Card)(({ theme }) => ({
  "&.MuiCard-root": {
    padding: theme.spacing(6, 0),
    boxShadow: "none",
    borderRadius: 0,
    ".MuiCardHeader-root": {
      padding: theme.spacing(4, 0),
    },
  },
}));

interface ProcessFlowProps {}
const ProcessFlow = (props: ProcessFlowProps) => {
  const [inEdit, setInEdit] = useState(false);

  const handleEditClick = (formik: FormikProps<any>) => {
    setInEdit(true);
  };
  const handleSaveClick = (formik: FormikProps<any>) => {
    setInEdit(false);
  };
  const handleCancelClick = (formik: FormikProps<any>) => {
    setInEdit(false);
  };

  const validationSchema = yup.object({});

  const editSaveBtnsFn = useCallback(
    (formik) => {
      if (inEdit) {
        return (
          <Box>
            <Button
              className="text-color-08"
              sx={{
                mr: 3,
                borderRadius: "4px",
                border: "solid 1px rgba(0, 0, 0, 0.15)",
                color: textColor["85"],
              }}
              variant="outlined"
              startIcon={
                <AppSvgIcon size={16} color={textColor["65"]}>
                  {"material-solid:cancel"}
                </AppSvgIcon>
              }
              onClick={() => {
                handleCancelClick(formik);
              }}
              id="cancel"
            >
              <IntlMessages id="common.cancel" />
            </Button>
            <Button
              variant="contained"
              startIcon={
                <AppSvgIcon size={16} color="#FFF">
                  {"material-solid:save"}
                </AppSvgIcon>
              }
              onClick={() => {
                handleSaveClick(formik);
              }}
              id="save_submit"
            >
              <IntlMessages id="common.save" />
            </Button>
          </Box>
        );
      }
      return (
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<AppSvgIcon size={16}>{"material-solid:edit"}</AppSvgIcon>}
          onClick={() => {
            handleEditClick(formik);
          }}
        >
          <IntlMessages id="common.edit" />
        </Button>
      );
    },
    [inEdit]
  );
  return (
    <ProcessFlowContainer>
      <Formik
        initialValues={{
          id: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          console.log(data);
          resetForm();
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            noValidate
            autoComplete="off"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box></Box>
              {editSaveBtnsFn(formik)}
            </Box>
            <CardContainer>
              <CardContent>
                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <img src={u100} />
                </Box>
              </CardContent>
            </CardContainer>
          </Form>
        )}
      </Formik>
    </ProcessFlowContainer>
  );
};

export default ProcessFlow;
