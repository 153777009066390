import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "components/CustomTable";
import React from "react";
import { boxShadow, textColor } from "shared/constants/AppConst";
import { TableHeaderButtonsIconType } from "shared/constants/AppEnums";
import { Column } from "types/models/Common";

import ContentHeader from "../components/ContentHeader";

const UsersContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  ".table-container": {
    background: theme.palette.background.paper,
    width: "calc(100% - 40px)",
    height: "calc(100vh - 144px)",
    margin: theme.spacing(5),
    boxShadow: boxShadow.cardBoxShadow,
    padding: "20px",
  },
  ".active-item": {
    height: "20px",
    padding: "1px 1px 1px 8px",
    borderRadius: "20px",
    backgroundColor: "rgb(46, 108, 203, 0.2)",
    color: "#2e6ccb",
    ".MuiTypography-root": {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  ".active-item.in-active": {
    backgroundColor: "#e4e6eb",
    color: textColor[45],
  },
  ".locked-item": {
    height: "20px",
    width: "84px",
    padding: "1px 1px 1px 8px",
    borderRadius: "20px",
    backgroundColor: "rgb(46, 108, 203, 0.2)",
    color: "#2e6ccb",
    ".MuiTypography-root": {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  ".locked-item.unlocked": {
    backgroundColor: "#e4e6eb",
    color: textColor[45],
  },
  ".circle": {
    width: "18px",
    height: "18px",
    background: "#FFF",
    borderRadius: "50%",
  },
  ".MuiButtonBase-root.Mui-disabled": {
    background: "#e4e6eb",
    border: "solid 1px rgba(0, 0, 0, 0.15)",
    color: textColor[25],
  },
}));

const columns: Column[] = [
  {
    id: "userName",
    label: "common.userName",
    minWidth: 100,
    align: "left",
  },
  {
    id: "firstName",
    label: "common.first.name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "lastName",
    label: "common.last.name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "email",
    label: "common.email",
    minWidth: 180,
    align: "left",
  },
  {
    id: "groups",
    label: "common.groups",
    minWidth: 150,
    align: "left",
  },
  {
    id: "userType",
    label: "common.user.type",
    minWidth: 120,
    align: "left",
  },
  {
    id: "status",
    label: "common.status",
    minWidth: 100,
    align: "left",
    format: (value, record) => {
      return (
        <Box className={`active-item flex items-center justify-between ${value === "Active" ? "active" : "in-active"}`}>
          <Typography component={"span"}>{value}</Typography>
          <Box className="circle"></Box>
        </Box>
      );
    },
  },
  {
    id: "lockoutStatus",
    label: "common.lockout.status",
    minWidth: 160,
    align: "left",
    format: (value, record) => {
      return (
        <Box className={`locked-item flex items-center justify-between ${value === "Locked" ? "locked" : "unlocked"}`}>
          <Typography component={"span"}>{value}</Typography>
          <Box className="circle"></Box>
        </Box>
      );
    },
  },
  {
    id: "reset_password",
    label: "common.reset.password",
    align: "left",
    minWidth: 140,
    format: (value, record) => {
      if (value) {
        return (
          <Button
            disabled={record.status !== "Active"}
            sx={{
              color: "#de350b",
              border: "solid 1px #de350b",
              fontWeight: "normal",
              height: "20px",
              paddingTop: "1px",
              paddingBottom: "1px",
            }}
            variant="outlined"
          >
            <IntlMessages id="common.reset" />
          </Button>
        );
      }
      return "";
    },
  },
  {
    id: "reset_password_deadline",
    label: "common.reset.password.deadline",
    minWidth: 190,
    align: "left",
  },
  {
    id: "last_password_change",
    label: "common.last.password.change",
    minWidth: 180,
    align: "left",
  },
  {
    id: "actions",
    label: "setting.product.column.actions",
    minWidth: 120,
    format: (value, record) => {
      // Show more
      return (
        <Box sx={{ color: "#2e6ccb" }} className="flex cursor-pointer">
          <Box>
            <IntlMessages id="common.edit" />
          </Box>
          <Box sx={{ mx: 2 }}>|</Box>
          <Box>
            <IntlMessages id="dashboard.subscriptions" />
          </Box>
        </Box>
      );
    },
  },
];

const initRows = [
  {
    id: 1,
    userName: "evaladmin",
    firstName: "Eval",
    lastName: "Admin",
    email: "fubin.wu@gessnet.com",
    groups: "Company Admin",
    userType: "Register",
    status: "Active",
    lockoutStatus: "Unlocked",
    reset_password: false,
    reset_password_deadline: "",
    last_password_change: "14:45:34 01/17/2017",
  },
  {
    id: 2,
    userName: "aaron2022",
    firstName: "Aaron",
    lastName: "Evan",
    email: "aaron.evan@gessnet.com",
    groups: "Product Managers",
    userType: "Normal User",
    status: "Active",
    lockoutStatus: "Unlocked",
    reset_password: true,
    reset_password_deadline: "",
    last_password_change: "16:34:56 02/19/2021",
  },
  {
    id: 3,
    userName: "john_doe",
    firstName: "John",
    lastName: "Doe",
    email: "john_doe@gessnet.com",
    groups: "Analysis",
    userType: "Super User",
    status: "Inactive",
    lockoutStatus: "Locked",
    reset_password: true,
    reset_password_deadline: "",
    last_password_change: "17:35:05 04/06/2020",
  },
];

const Users = () => {
  const [rows, setRows] = React.useState<any[]>(initRows);

  return (
    <UsersContainer>
      <ContentHeader titleId="admin.nav.item.users" />
      <Box className="table-container">
        <CustomTable
          toolBar={{
            buttons: [
              {
                id: "common.create.new.user",
                icon: TableHeaderButtonsIconType.ADD,
                type: "text",
                color: "#2e6ccb",
                isBlue: true,
                onChange: () => {},
              },
            ],
          }}
          columns={columns}
          rows={rows}
          maxHeight="calc(100vh - 255px)"
          showPage={false}
        />
      </Box>
    </UsersContainer>
  );
};

export default Users;
