import Icon from "@mui/material/Icon";
import * as React from "react";

const DCDSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <rect fill="#01579B" opacity=".1" width="20" height="20" rx="4" />
      <path
        d="M10 2.5a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zM5.52 7.807H4.016v4.943h1.48l.259-.014c.337-.033.64-.125.911-.278.344-.193.61-.471.793-.83.18-.352.27-.758.27-1.215v-.284l-.012-.263a2.515 2.515 0 0 0-.261-.938 1.984 1.984 0 0 0-.786-.832 2.268 2.268 0 0 0-1.15-.29zm3.68 0H8.114v4.943H9.2v-2.055h1.74v2.055h1.09V7.807h-1.09v1.91H9.2v-1.91zm6.623 0H12.57v4.943h1.086l-.001-1.963h1.865V9.81l-1.865-.002V8.79h2.168v-.982zm-10.29.982.175.01c.278.034.489.143.645.327.195.23.296.565.296 1.015v.26l-.006.19c-.026.366-.13.645-.307.842-.201.225-.484.338-.868.338l-.367-.001V8.789h.432z"
        fill="#01579B"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

const DCDIcon: React.FunctionComponent = () => {
  return <Icon component={DCDSvg} />;
};

export default DCDIcon;
