import Icon from "@mui/material/Icon";
import * as React from "react";

const ProductionSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <rect fill="#00875A" opacity=".1" width="20" height="20" rx="4" />
      <path
        d="M3 8.964h5.1a.5.5 0 0 0 .5-.5V3.36a.5.5 0 0 0-.5-.5H3a.5.5 0 0 0-.5.5v5.104a.5.5 0 0 0 .5.5zm0 8.54h5.1a.5.5 0 0 0 .5-.5V11.9a.5.5 0 0 0-.5-.5H3a.5.5 0 0 0-.5.5v5.104a.5.5 0 0 0 .5.5zm8.54 0h5.1a.5.5 0 0 0 .5-.5V11.9a.5.5 0 0 0-.5-.5h-5.1a.5.5 0 0 0-.5.5v5.104a.5.5 0 0 0 .5.5zm2.647-8.254 3.183-2.722a.5.5 0 0 0 .055-.706L14.698 2.63a.5.5 0 0 0-.705-.055L10.81 5.297a.5.5 0 0 0-.055.705l2.727 3.193a.5.5 0 0 0 .705.056z"
        fill="#00875A"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

const ProductionIcon: React.FunctionComponent = () => {
  return <Icon component={ProductionSvg} />;
};

export default ProductionIcon;
