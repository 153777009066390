import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import MyTree from "components/MyTree";
import MyTreeTable from "components/MyTreeTable";
import React, { useEffect, useState } from "react";
import { Column } from "types/models/Common";

const TreeContainer = styled(Box)(({ theme }) => ({
  height: "calc(100% - 56px)",
  width: "auto",
  pt: "12px",
  pl: "20px",
}));

interface DataWithCheckbox {
  id: string;
  checked: boolean;
  items?: DataWithCheckbox[];
  [x: string]: any;
}

const dataSet: any[] = [
  {
    id: "Risk Management",
    name: "Risk Management",
    level: 1,
    isFolder: true,
    allowToEdit: false,
    allowToLink: false,
    children: [
      {
        id: "Safety Risks",
        name: "Safety Risks",
        level: 2,
        isFolder: true,
        allowToEdit: true,
        allowToLink: false,
        children: [
          {
            id: "Safety Harms",
            name: "Safety Harms",
            level: 3,
            isFolder: true,
            allowToEdit: false,
            allowToLink: true,
          },
          {
            id: "Hazards",
            name: "Hazards",
            level: 3,
            isFolder: true,
            allowToEdit: false,
            allowToLink: false,
            children: [
              {
                id: "Hazard Category",
                name: "Hazard Category",
                level: 4,
                isFolder: true,
                allowToEdit: true,
                allowToLink: false,
              },
              {
                id: "System Hazards",
                name: "System Hazards",
                level: 4,
                isFolder: true,
                allowToEdit: false,
                allowToLink: true,
              },
              {
                id: "Hazardous Situations",
                name: "Hazardous Situations",
                level: 4,
                isFolder: true,
                allowToEdit: false,
                allowToLink: true,
              },
              {
                id: "Causes",
                name: "Causes",
                level: 4,
                isFolder: true,
                allowToEdit: true,
                allowToLink: false,
              },
              {
                id: "Basic Events",
                name: "Basic Events",
                level: 4,
                isFolder: true,
                allowToEdit: false,
                allowToLink: false,
              },
              {
                id: "Risk Controls",
                name: "Risk Controls",
                level: 4,
                isFolder: true,
                allowToEdit: true,
                allowToLink: false,
              },
            ],
          },
        ],
      },
      {
        id: "Security Risks",
        name: "Security Risks",
        level: 2,
        isFolder: true,
        allowToEdit: true,
        allowToLink: true,
        children: [
          {
            id: "Security Harms",
            name: "Security Harms",
            level: 3,
            isFolder: true,
            allowToEdit: true,
            allowToLink: false,
          },
          {
            id: "Threats",
            name: "Threats",
            level: 3,
            isFolder: true,
            allowToEdit: false,
            allowToLink: true,
            children: [
              {
                id: "Sub Threats",
                name: "Threats",
                level: 4,
                isFolder: true,
                allowToEdit: true,
                allowToLink: true,
              },
              {
                id: "Vulnerabilities",
                name: "Vulnerabilities",
                level: 4,
                isFolder: true,
                allowToEdit: false,
                allowToLink: false,
              },
              {
                id: "Threat Attacks",
                name: "Threat Attacks",
                level: 4,
                isFolder: true,
                allowToEdit: true,
                allowToLink: true,
              },
            ],
          },
        ],
      },
      {
        id: "FMEAs",
        name: "FMEAs",
        level: 2,
        isFolder: true,
        allowToEdit: false,
        allowToLink: false,
        children: [
          {
            id: "Design FMEA",
            name: "Design FMEA",
            level: 3,
            isFolder: true,
            allowToEdit: false,
            allowToLink: false,
          },
          {
            id: "Process FMEA",
            name: "Process FMEA",
            level: 3,
            isFolder: true,
            allowToEdit: false,
            allowToLink: false,
          },
          {
            id: "Product Error Codes",
            name: "Product Error Codes",
            level: 3,
            isFolder: true,
            allowToEdit: false,
            allowToLink: false,
          },
          {
            id: "Code Lib",
            name: "Code Lib",
            level: 3,
            isFolder: true,
            allowToEdit: false,
            allowToLink: false,
          },
        ],
      },
      {
        id: "Evidences",
        name: "Evidences",
        level: 2,
        isFolder: true,
        allowToEdit: true,
        allowToLink: true,
        children: [
          {
            id: "Risk Benefit Analysis Ref",
            name: "Risk Benefit Analysis Ref.",
            level: 3,
            isFolder: true,
            allowToEdit: true,
            allowToLink: false,
          },
        ],
      },
    ],
  },
];

const getCheckedIds = (data: DataWithCheckbox[]) => {
  let checkedIds: string[] = [];
  for (let item of data) {
    if (item.checked) {
      checkedIds.push(item.id);
    }
    if (item.items && item.items.length > 0) {
      checkedIds.push(...getCheckedIds(item.items));
    }
  }
  return checkedIds;
};

interface ChangeScopeProps {
  edit: boolean;
  width?: number | string;
}

const ChangeScope = (props: ChangeScopeProps) => {
  const { edit, width } = props;

  const handleCheckChange = (record, checked, name) => {
    console.log(`record: ${record.id}, checked: ${checked}, name: ${name}`);
  };
  const [columns, setColumns] = useState<Column[]>([
    {
      id: "name",
      label: "common.name",
      minWidth: 300,
      align: "left",
    },
    {
      id: "allowToEdit",
      label: "change.scope.allow.edit",
      minWidth: 100,
      align: "left",
      format: (value, record) => {
        if (!value) {
          return <></>;
        }
        return (
          <Box sx={{ display: "flex" }}>
            {value ? (
              <AppSvgIcon size={16} color="#6dc347">
                material-solid:done
              </AppSvgIcon>
            ) : (
              ""
            )}
          </Box>
        );
      },
    },
    {
      id: "allowToLink",
      label: "change.scope.allow.link",
      minWidth: 100,
      align: "left",
      format: (value, record) => {
        if (!value) {
          return <></>;
        }
        return (
          <Box sx={{ display: "flex" }}>
            {value ? (
              <AppSvgIcon size={16} color="#6dc347">
                material-solid:done
              </AppSvgIcon>
            ) : (
              ""
            )}
          </Box>
        );
      },
    },
  ]);

  const [rows, setRows] = useState([...dataSet]);

  const [selected, setSelected] = React.useState<any>("Risk Management");
  const [checkedIds, setCheckedIds] = React.useState<string[]>(getCheckedIds(dataSet));

  const onNodeSelect = (event: object, value: string) => {
    setSelected(value);

    let checkedNodeIds = [...checkedIds];
    if (checkedNodeIds.includes(value)) {
      const index = checkedNodeIds.indexOf(value);
      checkedNodeIds.splice(index, 1);
      //todo: check items and uncheck items
    } else {
      checkedNodeIds.push(value);
      //todo: check items and check items too.
    }
    setCheckedIds(checkedNodeIds);
  };

  return (
    <>
      <TreeContainer>
        <MyTreeTable maxHeight={"100%"} columns={columns} rows={rows} setReloadClickCell={() => {}} />
        {/* <MyTree
          selected={selected}
          onNodeSelect={onNodeSelect}
          data={dataSet}
          useStyledTree={true}
          isBold={false}
          showCheckbox={true}
          checkedNodeIds={checkedIds}
          sx={{ width: width }}
        /> */}
      </TreeContainer>
    </>
  );
};

export default ChangeScope;
