import IntlMessages from "@crema/utility/IntlMessages";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import AddIcon from "components/Icons/AddIcon";
import MyCollapseTable from "components/MyCollapseTable";
import SimplePopup from "components/SimplePopup";
import * as React from "react";
import { useEffect, useState } from "react";
import { textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

const Container = styled(Box)(({ theme }) => ({
  ".table-toolbar": {
    paddingTop: theme.spacing(0),
  },
}));

const initRows = [
  {
    id: 1,
    configurations1: 1,
    link: 1,
    configurations2: 2,
  },
];

const initSelectedData = {
  configurations1: [
    {
      id: 1,
      name: "Hazard Category Column",
    },
    {
      id: 2,
      name: "Hazardous Situation Column",
    },
    {
      id: 3,
      name: "Safety Harm Column",
    },
    {
      id: 4,
      name: "User Needs Column",
    },
    {
      id: 5,
      name: "User Stories Column",
    },
    {
      id: 6,
      name: "System Requirement Column",
    },
    {
      id: 7,
      name: "Test Case Column",
    },
    {
      id: 8,
      name: "Validation Column",
    },
  ],
  links: [
    {
      id: 1,
      name: "Links to",
    },
    {
      id: 2,
      name: "is Linked by",
    },
    {
      id: 3,
      name: "Shares with",
    },
  ],
  configurations2: [
    {
      id: 1,
      name: "Hazard Category Column",
    },
    {
      id: 2,
      name: "Hazardous Situation Column",
    },
    {
      id: 3,
      name: "Safety Harm Column",
    },
    {
      id: 4,
      name: "User Needs Column",
    },
    {
      id: 5,
      name: "User Stories Column",
    },
    {
      id: 6,
      name: "System Requirement Column",
    },
    {
      id: 7,
      name: "Test Case Column",
    },
    {
      id: 8,
      name: "Validation Column",
    },
  ],
};

const Configurations1Selection = ({ row, formik, initSelectedData }) => {
  const configurations1Name = `configurations1_${row.id}`;

  return (
    <Select
      labelId="select-header"
      label=""
      sx={{
        width: "300px",
      }}
      defaultValue={row.configurations1}
      // value={formik.values[configurations1Name]}
      // labelWidth={100}
      name={configurations1Name}
      onChange={(e) => {
        formik.setFieldValue(configurations1Name, e.target.value);
      }}
      fullWidth
    >
      {initSelectedData.configurations1.map((x) => (
        <MenuItem key={x.id} value={x.id}>
          {x.name}
        </MenuItem>
      ))}
    </Select>
  );
};

const LinkSelection = ({ row, formik, initSelectedData }) => {
  const linkName = `link_${row.id}`;

  return (
    <Select
      labelId="select-header"
      label=""
      sx={{
        width: "200px",
      }}
      defaultValue={row.link}
      // value={formik.values[linkName]}
      // labelWidth={100}
      name={linkName}
      onChange={(e) => {
        formik.setFieldValue(linkName, e.target.value);
      }}
      fullWidth
    >
      {initSelectedData.links.map((x) => (
        <MenuItem key={x.id} value={x.id}>
          {x.name}
        </MenuItem>
      ))}
    </Select>
  );
};

const Configurations2Selection = ({ row, formik, initSelectedData }) => {
  const configurations2Name = `configurations2_${row.id}`;

  return (
    <Select
      labelId="select-header"
      label=""
      sx={{
        width: "300px",
      }}
      defaultValue={row.configurations2}
      // value={formik.values[configurations2Name]}
      // labelWidth={100}
      name={configurations2Name}
      onChange={(e) => {
        formik.setFieldValue(configurations2Name, e.target.value);
      }}
      fullWidth
    >
      {initSelectedData.configurations2.map((x) => (
        <MenuItem key={x.id} value={x.id}>
          {x.name}
        </MenuItem>
      ))}
    </Select>
  );
};

interface RelationshipTableProps {
  rowId: number | string;
  formik: any;
}
const RelationshipTable = (props: RelationshipTableProps) => {
  const { rowId, formik } = props;
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(initRows);
  }, []);

  useEffect(() => {
    rows.forEach((x) => {
      const keys = Object.keys(x);
      keys.forEach((k) => {
        formik.setFieldValue(`${k}_${x.id}`, x[k]);
      });
    });
  }, [rows]);

  const handleAddClick = () => {
    console.log("add");
    const nRows = [...rows];
    const nRowsSort = nRows.sort((a, b) => b.id - a.id);
    const maxId = nRowsSort[0].id;
    const newId = maxId + 1;
    nRows.push({
      id: newId,
      configurations1: 1,
      link: 1,
      configurations2: 2,
    });
    // nRows.forEach((x) => {
    //   const keys = Object.keys(x);
    //   keys.forEach((k) => {
    //     formik.setFieldValue(`${k}_${x.id}`, x[k]);
    //   });
    // });
    setRows(nRows);
  };
  const handleDeleteClick = (record) => {
    console.log("delete", record);
  };
  const toolbar = (
    <Box className="table-toolbar flex justify-between" sx={{ paddingTop: "0" }}>
      <Box></Box>
      <Box className="table-toolbar-actions flex justify-center items-center">
        <Button
          variant="text"
          className="add-button"
          color="primary"
          startIcon={<AddIcon isBlue={true} />}
          onClick={handleAddClick}
        >
          <IntlMessages id="common.add" />
        </Button>
      </Box>
    </Box>
  );
  return (
    <Container>
      <TableContainer className="relation_ship_table">
        {toolbar}
        <Table>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow key={`table_row_${row.id}`}>
                  <TableCell
                    key={`table_cell_configurations1_${row["id"]}`}
                    style={{
                      display: open ? "table-cell" : "none",
                    }}
                    className="table_cell table_body_cell"
                  >
                    <Box>
                      <Configurations1Selection row={row} formik={formik} initSelectedData={initSelectedData} />
                    </Box>
                  </TableCell>
                  <TableCell
                    key={`table_cell_links_${row["id"]}`}
                    style={{
                      display: open ? "table-cell" : "none",
                    }}
                    className="table_cell table_body_cell"
                  >
                    <Box>
                      <LinkSelection row={row} formik={formik} initSelectedData={initSelectedData} />
                    </Box>
                  </TableCell>
                  <TableCell
                    key={`table_cell_configurations2_${row["id"]}`}
                    style={{
                      display: open ? "table-cell" : "none",
                    }}
                    className="table_cell table_body_cell"
                  >
                    <Box>
                      <Configurations2Selection row={row} formik={formik} initSelectedData={initSelectedData} />
                    </Box>
                  </TableCell>
                  <TableCell
                    key={`table_cell_actions_${row["id"]}`}
                    style={{
                      display: open ? "table-cell" : "none",
                    }}
                    className="table_cell table_body_cell table_cell_actions"
                  >
                    <Box>
                      <IconButton
                        sx={{ padding: "2px" }}
                        onClick={() => {
                          handleDeleteClick(row);
                        }}
                      >
                        <AppSvgIcon size={16} color={textColor["65"]}>
                          {"material-solid:delete"}
                        </AppSvgIcon>
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
export default RelationshipTable;
