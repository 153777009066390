import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Grid } from "@mui/material";
import MyTextField from "components/MyTextField";
import SimplePopup from "components/SimplePopup";
import { Form, Formik } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";

interface ApprovalModalProps {
  handleClose: () => void;
  open: boolean;
}

export default function ApprovalModal(props: ApprovalModalProps) {
  const { open, handleClose } = props;
  const { messages } = useIntl();

  const validationSchema = yup.object({
    // id: yup.string().max(5, "5435").required(String(messages["validation.emailRequired"])),
  });

  const [id, setId] = React.useState("123");
  const [status, setStatus] = React.useState("");

  const save = () => {
    setStatus("agree");
    const submitDom = document.getElementById("submit");
    submitDom && submitDom.click();
  };

  const rejected = () => {
    setStatus("rejected");
    const submitDom = document.getElementById("submit");
    submitDom && submitDom.click();
  };

  return (
    <SimplePopup
      okTextId="common.approve"
      cancelTextId="common.reject"
      handleClose={handleClose}
      cancel={rejected}
      open={open}
      titleId="common.approval"
      save={save}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "1200px",
          minWidth: "500px",
        },
      }}
    >
      <Formik
        initialValues={{
          comment: "",
          purpose: 'Electronic approve/reject to close "TK#001 System Hazard Analysis V1.0"',
          login_name: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          console.log(data, id, status);
          handleClose();
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <MyTextField name="purpose" fullWidth disabled label="common.purpose" />
              </Grid>
              <Grid item xs={12} md={12}>
                <MyTextField name="comment" fullWidth multiline rows="3" label="common.comment" />
              </Grid>
              <Grid item xs={12} md={12}>
                <MyTextField name="login_name" fullWidth label="common.login.name" />
              </Grid>
              <Grid item xs={12} md={12}>
                <MyTextField name="password" fullWidth label="common.password" />
              </Grid>
              <Grid sx={{ display: "none" }} item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{
                      position: "relative",
                      minWidth: 100,
                    }}
                    id="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <IntlMessages id="common.save" />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SimplePopup>
  );
}
