import AppSvgIcon from "components/AppSvgIcon";
import { ReactNode } from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export interface AdminRouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider" | "title";
  children?: AdminRouterConfigData[];
  permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
  level: number;
}

const adminConfigData: AdminRouterConfigData[] = [
  {
    id: "systemAdmin",
    title: "System Admin",
    messageId: "admin.nav.group.system",
    type: "group",
    level: 0,
    children: [
      {
        id: "general",
        title: "General",
        messageId: "admin.nav.item.general",
        type: "item",
        icon: <AppSvgIcon size={18}>material-outline:article</AppSvgIcon>,
        url: "/admin/general",
        level: 0,
      },
      {
        id: "loginSecurity",
        title: "Login Security",
        messageId: "admin.nav.item.loginSecurity",
        type: "item",
        icon: <AppSvgIcon size={18}>material-outline:security</AppSvgIcon>,
        url: "/admin/loginSecurity",
        level: 0,
      },
      {
        id: "users",
        title: "Users",
        messageId: "admin.nav.item.users",
        type: "item",
        icon: <AppSvgIcon size={18}>material-outline:person</AppSvgIcon>,
        url: "/admin/users",
        level: 0,
      },
      {
        id: "groups",
        title: "Groups",
        messageId: "admin.nav.item.groups",
        type: "item",
        icon: <AppSvgIcon size={18}>material-outline:groups</AppSvgIcon>,
        url: "/admin/groups",
        level: 0,
      },
      {
        id: "licenses",
        title: "Licenses",
        messageId: "admin.nav.item.licenses",
        type: "item",
        icon: <AppSvgIcon size={18}>material-outline:vpn_key</AppSvgIcon>,
        url: "/admin/licenses",
        level: 0,
      },
      {
        id: "accessControl",
        title: "Access Control",
        messageId: "admin.nav.item.accessControl",
        type: "item",
        icon: <AppSvgIcon size={18}>material-outline:rule</AppSvgIcon>,
        url: "/admin/accessControl",
        level: 0,
      },
      {
        id: "palette",
        title: "Palette",
        messageId: "admin.nav.item.palette",
        type: "item",
        icon: <AppSvgIcon size={18}>material-outline:palette</AppSvgIcon>,
        url: "/admin/palette",
        level: 0,
      },
      {
        id: "upgrade",
        title: "Upgrade",
        messageId: "admin.nav.item.upgrade",
        type: "item",
        icon: <AppSvgIcon size={18}>material-outline:system_update_alt</AppSvgIcon>,
        url: "/admin/upgrade",
        level: 0,
      },
    ],
  },
  {
    id: "processAdmin",
    title: "Process Admin",
    messageId: "admin.nav.group.process",
    type: "group",
    level: 0,
    children: [
      {
        id: "categories",
        title: "Categories",
        messageId: "admin.nav.item.categories",
        type: "item",
        icon: <AppSvgIcon size={18}>material-outline:style</AppSvgIcon>,
        url: "/admin/categories",
        level: 0,
      },
      {
        id: "workflow",
        title: "Workflow",
        messageId: "admin.nav.item.workflow",
        type: "item",
        icon: <AppSvgIcon size={18}>material-outline:share</AppSvgIcon>,
        url: "/admin/workflow",
        level: 0,
      },
      {
        id: "rules",
        title: "Rules",
        messageId: "common.rules",
        type: "item",
        icon: <AppSvgIcon size={18}>material-outline:rule_folder</AppSvgIcon>,
        url: "/admin/rules",
        level: 0,
      },
      // {
      //   id: "workitem",
      //   title: "workitem",
      //   messageId: "common.workitem",
      //   type: "collapse",
      //   icon: <AppSvgIcon size={18}>material-outline:library_books</AppSvgIcon>,
      //   level: 0,
      //   children: [
      //     {
      //       id: "workitemTypes",
      //       title: "Workitem Types",
      //       messageId: "admin.nav.item.productTypes",
      //       type: "item",
      //       icon: <AppSvgIcon size={18}>material-outline:class</AppSvgIcon>,
      //       url: "/admin/workitem/types",
      //       level: 0,
      //     },
      //     {
      //       id: "workitemRelationshipTypes",
      //       title: "Workitem Relationship Types",
      //       messageId: "common.relationship.types",
      //       type: "item",
      //       icon: <AppSvgIcon size={18}>material-outline:class</AppSvgIcon>,
      //       url: "/admin/workitem/relationship/types",
      //       level: 0,
      //     },
      //     {
      //       id: "workitemRelationships",
      //       title: "WorkItem Relationships",
      //       messageId: "admin.nav.item.productRelationships",
      //       type: "item",
      //       icon: <AppSvgIcon size={18}>material-outline:schema</AppSvgIcon>,
      //       url: "/admin/workitem/relationships",
      //       level: 0,
      //     },
      //   ],
      // },
      {
        id: "process",
        title: "Process",
        messageId: "admin.nav.item.process",
        type: "collapse",
        icon: <AppSvgIcon size={18}>material-outline:view_module</AppSvgIcon>,
        level: 0,
        children: [
          {
            id: "workitem",
            title: "Workitem Overview",
            messageId: "common.workitem.overview",
            type: "item",
            icon: <AppSvgIcon size={18}>material-outline:schema</AppSvgIcon>,
            url: "/admin/workitem",
            level: 0,
          },
          {
            id: "dc",
            title: "Design Controls",
            messageId: "common.design.controls",
            type: "item",
            icon: <AppSvgIcon size={18}>material-outline:schema</AppSvgIcon>,
            url: "/admin/process/dc",
            level: 0,
          },
          // {
          //   id: "req",
          //   title: "Requirement Management",
          //   messageId: "common.requirement.management",
          //   type: "item",
          //   icon: <AppSvgIcon size={18}>material-outline:schema</AppSvgIcon>,
          //   url: "/admin/process/req",
          //   level: 0,
          // },
          {
            id: "risk",
            title: "Risk Management",
            messageId: "common.risk.management",
            type: "item",
            icon: <AppSvgIcon size={18}>material-outline:recycling</AppSvgIcon>,
            url: "/admin/process/risk",
            level: 0,
          },
          {
            id: "cyber",
            title: "Cybersecurity Management",
            messageId: "common.cybersecurity.management",
            type: "item",
            icon: <AppSvgIcon size={18}>material-outline:recycling</AppSvgIcon>,
            url: "/admin/process/cyber",
            level: 0,
          },
          {
            id: "ac",
            title: "Assurance Case Management",
            messageId: "common.assurance.case.management",
            type: "item",
            icon: <AppSvgIcon size={18}>material-outline:class</AppSvgIcon>,
            url: "/admin/process/ac",
            level: 0,
          },
          {
            id: "production",
            title: "Production Management",
            messageId: "common.production.management",
            type: "item",
            icon: <AppSvgIcon size={18}>material-outline:recycling</AppSvgIcon>,
            url: "/admin/process/production",
            level: 0,
          },
          {
            id: "if",
            title: "Index File Management",
            messageId: "common.index.file.management",
            type: "item",
            icon: <AppSvgIcon size={18}>material-outline:recycling</AppSvgIcon>,
            url: "/admin/process/if",
            level: 0,
          },
        ],
      },
      {
        id: "product",
        title: "Product",
        messageId: "admin.nav.collapse.product",
        type: "collapse",
        icon: <AppSvgIcon size={18}>material-outline:library_books</AppSvgIcon>,
        level: 0,
        children: [
          {
            id: "productTypes",
            title: "Product Types",
            messageId: "admin.nav.item.productTypes",
            type: "item",
            icon: <AppSvgIcon size={18}>material-outline:class</AppSvgIcon>,
            url: "/admin/productTypes",
            level: 0,
          },
          {
            id: "productRelationships",
            title: "Product Relationships",
            messageId: "admin.nav.item.productRelationships",
            type: "item",
            icon: <AppSvgIcon size={18}>material-outline:schema</AppSvgIcon>,
            url: "/admin/productRelationships",
            level: 0,
          },
          {
            id: "productLifecycles",
            title: "Product Lifecycles",
            messageId: "admin.nav.item.productLifecycles",
            type: "item",
            icon: <AppSvgIcon size={18}>material-outline:recycling</AppSvgIcon>,
            url: "/admin/productLifecycles",
            level: 0,
          },
          {
            id: "processSettings",
            title: "Process Settings",
            messageId: "common.deliverable.templates",
            type: "item",
            icon: <AppSvgIcon size={18}>material-outline:account_tree</AppSvgIcon>,
            url: "/admin/processSettings/label",
            level: 0,
          },
        ],
      },
      {
        id: "exportTemplates",
        title: "Export Templates",
        messageId: "admin.nav.item.exportTemplates",
        type: "item",
        icon: <AppSvgIcon size={18}>material-outline:collections_bookmark</AppSvgIcon>,
        url: "/admin/exportTemplates",
        level: 0,
      },
      {
        id: "resources",
        title: "Resources",
        messageId: "common.resources",
        type: "item",
        icon: <AppSvgIcon size={18}>material-outline:collections_bookmark</AppSvgIcon>,
        url: "/admin/resources",
        level: 0,
      },
    ],
  },
];

export default adminConfigData;
