import IntlMessages from "@crema/utility/IntlMessages";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CachedIcon from "@mui/icons-material/Cached";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import MyComponent from "components";
import CustomizeIcon from "components/Icons/CustomizeIcon";
import React from "react";

const treeDataSet1: any[] = [
  {
    id: "Design Verifications",
    value: "Design Verifications",
    items: [
      {
        id: "Test Cases",
        value: "Test Cases",
        items: [
          {
            id: "Fever Detection",
            value: "Fever Detection",
          },
          {
            id: "Cleaning",
            value: "Cleaning",
          },
        ],
      },
    ],
  },
];

const treeDataSet2: any[] = [
  {
    id: "Design Inputs",
    value: "Design Inputs",
    items: [
      {
        id: "User Needs",
        value: "User Needs",
        items: [
          {
            id: "The user needs a device that can measure temperature accurately and quickly",
            value: "The user needs a device that can measure temperature accurately and quickly",
          },
          {
            id: "The user needs to able to accurately track the temperature of multiple patients when using the device",
            value:
              "The user needs to able to accurately track the temperature of multiple patients when using the device",
          },
          {
            id: "The user needs the device to read body temperature without pain or discomfort.",
            value: "The user needs the device to read body temperature without pain or discomfort.",
          },
          {
            id: "The user needs a device that is easy to use.",
            value: "The user needs a device that is easy to use.",
          },
          {
            id: "The user needs to use the device using only one hand, either the right or left had.",
            value: "The user needs to use the device using only one hand, either the right or left had.",
          },
          {
            id: "The user needs a device that will indicate a fever without the user needing to read the numbers.",
            value: "The user needs a device that will indicate a fever without the user needing to read the numbers.",
          },
          {
            id: "The user needs a device that is easy to clean.",
            value: "The user needs a device that is easy to clean.",
          },
          {
            id: "The user needs the device to track readings over a period of 6 months",
            value: "The user needs the device to track readings over a period of 6 months",
          },
          {
            id: "The user needs the device to provide a method of taking readings on different patients without needing disinfection.",
            value:
              "The user needs the device to provide a method of taking readings on different patients without needing disinfection.",
          },
          {
            id: "The user needs the device to accurately measure temperature on any part of the body.",
            value: "The user needs the device to accurately measure temperature on any part of the body.",
          },
          {
            id: "The user needs the device to facilitate updating the patient's chart with temperature recorded.",
            value: "The user needs the device to facilitate updating the patient's chart with temperature recorded.",
          },
          {
            id: "The user needs to be able to easily dispose of the product.",
            value: "The user needs to be able to easily dispose of the product.",
          },
          {
            id: "The user needs to be able to make results available to a physician.",
            value: "The user needs to be able to make results available to a physician.",
          },
          {
            id: "The user needs to easily store the product.",
            value: "The user needs to easily store the product.",
          },
          {
            id: "The user needs to be notified when temperature measurements are outside the specified range.",
            value: "The user needs to be notified when temperature measurements are outside the specified range.",
          },
          {
            id: "The user needs the device to last for two years without needing replacement of any parts.",
            value: "The user needs the device to last for two years without needing replacement of any parts.",
          },
          {
            id: "The user needs the device to units and temperatures that they are accustomed to.",
            value: "The user needs the device to units and temperatures that they are accustomed to.",
          },
          {
            id: "The user needs the device to meet regulatory requirements in the countries for which it is intended to be sold.",
            value:
              "The user needs the device to meet regulatory requirements in the countries for which it is intended to be sold.",
          },
          {
            id: "The user needs the device to work on all patient populations.",
            value: "The user needs the device to work on all patient populations.",
          },
        ],
      },
    ],
  },
];

const TreeContainer = styled(Box)(({ theme }) => ({}));

interface TreeProps {
  id: string;
}

const MyTree: React.FC<TreeProps> = (props: TreeProps) => {
  const [selected, setSelected] = React.useState<any>("");

  const [treeDataSet, setTreeDataSet] = React.useState([]);

  React.useEffect(() => {
    if (props.id === "User Needs") {
      setSelected("User Needs");
      setTreeDataSet(treeDataSet2);
    } else if (props.id === "Test Cases") {
      setSelected("Test Cases");
      setTreeDataSet(treeDataSet1);
    } else {
      setSelected("");
      setTreeDataSet([]);
    }
  }, [props.id]);

  const onNodeSelect = (event: object, value: string) => {
    setSelected(value);
  };

  return (
    <TreeContainer>
      <Box>
        <MyComponent.MyTree
          selected={selected}
          onNodeSelect={onNodeSelect}
          data={treeDataSet}
          sx={{
            ".not-last-child>.MuiTreeItem-content>.MuiTreeItem-label": {
              fontWeight: "bold",
            },
          }}
        />
      </Box>
    </TreeContainer>
  );
};

export default MyTree;
