import AppConfirmDialog from "@crema/core/AppConfirmDialog";
import { Box } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import React from "react";
import { useIntl } from "react-intl";
import { textColor } from "shared/constants/AppConst";

const Delete = () => {
  const [open, setOpen] = React.useState(false);
  const { messages } = useIntl();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onDeny = (isOpen: boolean) => {
    setOpen(false);
  };

  const onConfirm = () => {
    setOpen(false);
  };

  return (
    <Box className=" inline-block">
      <AppSvgIcon
        onClick={handleClickOpen}
        size={16}
        color={textColor["65"]}
        sxStyle={{ cursor: "pointer", mr: "4px" }}
      >
        material-solid:delete
      </AppSvgIcon>
      <AppConfirmDialog
        open={open}
        dialogTitle={messages["setting.product.stage.delete.dialogTitle"] as string}
        title={messages["setting.product.stage.delete.title"] as string}
        onDeny={onDeny}
        onConfirm={onConfirm}
      />
    </Box>
  );
};

export default Delete;
