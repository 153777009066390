import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Chip, InputAdornment, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { styled } from "@mui/material/styles";
import DataIcon from "components/Icons/DataIcon";
import SendIcon from "components/Icons/SendIcon";
import { DCDProcessData } from "mock/ModuleData";
import { VersionList } from "mock/VersionList";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { borderColor, borderRadius, chipColor, textColor } from "shared/constants/AppConst";

import ContentHeader from "../components/ContentHeader";
import DataSourceModal from "../components/DataSourceModal";
import SearchTree from "../components/SearchTree";
import ToolBar from "../components/ToolBar";
import MoreActions from "./MoreActions";

const ProcessSettingsContainer = styled(Box)(({ theme }) => ({
  ".content-layout": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "calc(100vh - 154px)",
  },
  ".left-container": {
    width: "20%",
    height: "100%",
    borderRight: `1px solid ${borderColor.dividerColor}`,
    backgroundColor: theme.palette.background.paper,
  },
  ".right-container": {
    width: "80%",
    padding: theme.spacing(5),
  },
}));

const ProcessSettings = () => {
  // const [profile, setProfile] = React.useState(profiles[0]?.id);
  const [version, setVersion] = React.useState(VersionList[0]?.id);
  // const [profileStatus, setProfileStatus] = React.useState("Draft");
  const [openDataSourceModal, setOpenDataSourceModal] = React.useState<boolean>(false);
  const [processData, setProcessData] = React.useState(DCDProcessData[0]);

  // const handleProfileChange = (event: SelectChangeEvent) => {
  //   setProfile(event.target.value);
  // };

  const handleVersionChange = (event: SelectChangeEvent) => {
    setVersion(event.target.value);
  };

  const navigate = useNavigate();

  const location = useLocation();

  // useEffect(() => {
  //   console.log(location);
  //   const locationList = location.pathname.split("/");
  //   let latestPathName = locationList[locationList.length - 1];
  //   if (latestPathName === "processSettings") {
  //     latestPathName = "itemTypes";
  //   }
  //   if (version) {
  //     navigate(`${version}/${latestPathName}`);
  //   } else {
  //     navigate(`${VersionList[0]?.id}/${latestPathName}`);
  //   }
  // }, [version]);

  return (
    <ProcessSettingsContainer>
      <ContentHeader>
        <Box sx={{ display: "inline-block", verticalAlign: "middle" }}>
          <Box sx={{ color: textColor[85], mr: 4, display: "inline-block" }}>
            <IntlMessages id="common.derived.from.setting.template" />:
            <Box sx={{ display: "inline-block", fontWeight: "bold", ml: 2 }}>System Default V1.2</Box>
          </Box>

          <Select
            sx={{
              m: 2,
              minWidth: 50,
              background: "#fff",
              "& .MuiInputAdornment-root": {
                color: textColor[85],
              },
              "& .MuiTypography-body1": {
                color: textColor[85],
              },
              "& .MuiInputBase-input": {
                padding: "6px 0px",
                color: textColor[85],
              },
            }}
            id="profile-version-select"
            value={version}
            label=""
            onChange={handleVersionChange}
            startAdornment={<InputAdornment position="start">Version:</InputAdornment>}
          >
            {VersionList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
                <Chip
                  size="small"
                  label={item.status}
                  sx={{
                    textAlign: "center",
                    background: chipColor.versionBgColor,
                    color: textColor[85],
                    m: 2,
                    height: "20px",
                    borderRadius: borderRadius.main,
                    margin: "0px 8px",
                  }}
                />
              </MenuItem>
            ))}
          </Select>

          <Button
            sx={{
              color: textColor[85],
              border: `solid 1px ${borderColor.dividerColor}`,
              fontWeight: "normal",
              m: 2,
            }}
            startIcon={<SendIcon />}
            variant="outlined"
          >
            <IntlMessages id="setting.process.send.for.approval" />
          </Button>
          <Button
            sx={{
              color: textColor[85],
              border: `solid 1px ${borderColor.dividerColor}`,
              fontWeight: "normal",
              m: 2,
            }}
            startIcon={<DataIcon />}
            variant="outlined"
            onClick={() => {
              setOpenDataSourceModal(true);
            }}
          >
            <IntlMessages id="setting.process.dataSources" />
          </Button>
          <Box
            sx={{
              position: "absolute",
              right: "20px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <MoreActions actionLevel={0} />
          </Box>
        </Box>
      </ContentHeader>
      <Box className="content-layout">
        <AppScrollbar className="left-container">
          <SearchTree handleSelectProcess={setProcessData} />
        </AppScrollbar>
        <Box className="right-container">
          <ToolBar versionId={version} selectedProcess={processData} />
          <AppScrollbar
            sx={{
              height: "calc(100% - 56px)",
              padding: (theme) => theme.spacing(5),
              background: "#fff",
              boxShadow: (theme) => theme.palette.background.paper,
            }}
          >
            <Outlet />
          </AppScrollbar>
        </Box>
      </Box>

      <DataSourceModal
        open={openDataSourceModal}
        handleClose={() => {
          setOpenDataSourceModal(false);
        }}
      />
    </ProcessSettingsContainer>
  );
};

export default ProcessSettings;
