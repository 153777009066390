import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Card, IconButton, styled, Tab, Tabs, TextField } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import ExportDropdown from "components/ExportDropdown";
import CustomizeIcon from "components/Icons/CustomizeIcon";
import MyTreeTable from "components/MyTreeTable";
import { FileIndexColumns, FileIndexRows } from "mock/FileIndexData";
import Workitems from "pages/products/Workitems";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { handleLeftTreeSelected } from "redux/actions/Products";
import { boxShadow, textColor } from "shared/constants/AppConst";
import { Fonts } from "shared/constants/AppEnums";

function a11yProps(index: number | string) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const IndexTreeTableContainer = styled(Card)(({ theme }) => ({
  width: "100%",
  padding: 0,
  ".treeTable-content": {
    width: "100%",
    marginTop: "8px",
    ".MuiTableBody-root": {
      ".MuiTableRow-root:hover": {
        color: theme.palette.primary.main,
        "& .table_body_cell": {
          backgroundColor: theme.palette.action.hover,
          fontWeight: Fonts.SEMI_BOLD,
        },
        "& *": {
          color: "inherit",
        },
      },
    },
  },
  ".MuiTabs-root": {
    backgroundColor: "#dce0e6",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    minHeight: "auto",
    marginBottom: theme.spacing(0),
    ".MuiTabs-scroller": {
      width: "auto",
    },
    ".MuiTab-root": {
      padding: "1px 8px",
      minWidth: "auto",
      minHeight: "24px",
      textTransform: "capitalize",
      color: textColor[65],
      fontWeight: "400",
    },
    ".Mui-selected": {
      color: textColor[85],
      borderRadius: "2px",
      boxShadow: boxShadow.cardBoxShadow,
      backgroundColor: theme.palette.background.paper,
      fontWeight: "500",
    },
    ".MuiTabs-indicator": {
      display: "none",
    },
  },
}));

interface IndexTreeTableProps {
  [x: string]: any;
}

const IndexTreeTable: React.FC<IndexTreeTableProps> = () => {
  const routeParam = useParams();
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [index, setIndex] = React.useState("index");
  const [searchText, setSearchText] = useState("");

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
  };

  const handleRowClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, row: any) => {
    if (!row.isFolder) {
      console.log(row);
      if (row.docId) {
        dispatch(
          handleLeftTreeSelected({
            processId: row.processId,
            moduleId: row.moduleId,
            docId: row.docId,
          })
        );
      }
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setIndex(newValue);
  };

  return (
    <IndexTreeTableContainer>
      <AppScrollbar
        sx={{
          width: "100%",
          height: "100%",
          padding: (theme) => theme.spacing(5),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box className="flex items-center justify-between">
            <Box className="flex items-center justify-between">
              <Box className="flex">
                <Tabs value={index} onChange={handleChangeTab} aria-label="basic tabs example">
                  <Tab value={"index"} label={<IntlMessages id="common.index" />} {...a11yProps("index")} />
                  <Tab value={"workitems"} label={<IntlMessages id="common.workitems" />} {...a11yProps("workitems")} />
                </Tabs>
              </Box>
              <Box sx={{ ml: 2 }}>
                <TextField
                  variant="outlined"
                  value={searchText}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value)}
                  placeholder={String(messages["common.search"])}
                  InputProps={{
                    startAdornment: (
                      <AppSvgIcon size={16} color="action">
                        {"material-outline:search"}
                      </AppSvgIcon>
                    ),
                    endAdornment: (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          title="Clear"
                          aria-label="Clear"
                          size="small"
                          style={{ visibility: searchText ? "visible" : "hidden" }}
                          onClick={() => {
                            requestSearch("");
                          }}
                        >
                          <AppSvgIcon size={16} color="action">
                            {"material-outline:clear"}
                          </AppSvgIcon>
                        </IconButton>
                      </Box>
                    ),
                  }}
                  sx={{
                    width: {
                      xs: 1,
                      sm: "auto",
                    },
                    m: 0,
                    "& .MuiSvgIcon-root": {
                      mr: 0.5,
                    },
                    "& .MuiInputBase-root": {
                      padding: "0px 14px",
                      height: "32px",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "0px",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: 1,
                      borderColor: "divider",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box className="flex items-center justify-between">
              <ExportDropdown exportToWord={() => {}} exportToExcel={() => {}} exportToPdf={() => {}} />
              <Button
                sx={{
                  marginLeft: 2,
                  color: textColor[85],
                }}
                variant="text"
                onClick={() => {}}
                startIcon={<CustomizeIcon />}
              >
                <IntlMessages id="common.customize" />
              </Button>
            </Box>
          </Box>
          <AppScrollbar className="treeTable-content">
            <TabPanel index={"index"} value={index}>
              <MyTreeTable
                maxHeight={"calc(100vh - 276px)"}
                columns={FileIndexColumns}
                rows={FileIndexRows[routeParam.module]}
                setReloadClickCell={() => {}}
                onRowClick={handleRowClick}
              ></MyTreeTable>
            </TabPanel>
            <TabPanel index={"workitems"} value={index}>
              <Workitems selectedModule={routeParam.module} />
            </TabPanel>
          </AppScrollbar>
        </Box>
      </AppScrollbar>
    </IndexTreeTableContainer>
  );
};

export default IndexTreeTable;
