import IntlMessages from "@crema/utility/IntlMessages";
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import { useMemo, useState } from "react";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";

export interface WorkflowModel {
  id: number | string;
  currentStatus?: string;
  action?: string;
  newStatus?: string;
  approval?: string;
  notifications?: string;
  lock?: string;
  transitionPermissions?: string;
  children?: WorkflowModel[];
}
interface WorkflowTableProps {
  //   data: WorkflowModel[];
}

const columnsData = [
  {
    index: "currentStatus",
    name: "setting.process.item.workflow.edit.table.currentStatus",
  },
  {
    index: "action",
    name: "setting.process.item.workflow.edit.table.action",
  },
  {
    index: "newStatus",
    name: "setting.process.item.workflow.edit.table.newStatus",
  },
  {
    index: "approval",
    name: "setting.process.item.workflow.edit.table.approval",
  },
  {
    index: "notifications",
    name: "setting.process.item.workflow.edit.table.notifications",
  },
  {
    index: "lock",
    name: "setting.process.item.workflow.edit.table.lock",
  },
  {
    index: "transitionPermissions",
    name: "setting.process.item.workflow.edit.table.transitionPermissions",
  },
];
const Container = styled(Box)(({ theme }) => ({
  ".table_header_cell, .table_header": {
    background: bgColor.tableHeaderColor,
    color: textColor[85],
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
  },
  ".table_body_cell": {
    background: theme.palette.background.paper,
    color: textColor[85],
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell:first-of-type": {
    borderLeft: `1px solid  ${borderColor.dividerColor}`,
  },
  "thead tr:first-of-type .table_header_cell": {
    borderTop: `1px solid  ${borderColor.dividerColor}`,
  },
  ".table_cell": {
    padding: "10px 12px",
    lineHeight: "20px",
    borderRight: `1px solid  ${borderColor.dividerColor}`,
    borderBottom: `1px solid  ${borderColor.dividerColor}`,
  },
  ".table_header_cell": {
    // padding: "9px 12px",
  },
}));

const ActionTableCell = styled(TableCell)(({ theme }) => ({
  ".action_box": {
    ".action_value": {},
    ".action_icons": {
      display: "flex",
      justifyContent: "center",
      "& .MuiIconButton-root": {
        top: 0,
        right: 0,
      },
    },
  },
}));

const NewStatusTableCell = styled(TableCell)(({ theme }) => ({
  ".new_status_box": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ".new_status_value": {},
    ".new_status_icons": {
      display: "flex",
      justifyContent: "center",
      "& .MuiIconButton-root": {
        top: 0,
        right: 0,
      },
    },
  },
}));
const WorkflowTable = (props: WorkflowTableProps) => {
  //   const { data } = props;

  const [data, setData] = useState([
    {
      id: 1,
      currentStatus: "Item Created",
      children: [
        {
          id: 101,
          action: "Initialize",
          newStatus: "Draft",
          approval: "",
          notifications: "None",
          lock: "",
          transitionPermissions: "",
        },
      ],
    },
    {
      id: 2,
      currentStatus: "Draft",
      children: [
        {
          id: 201,
          action: "Review",
          newStatus: "In Review",
          approval: "",
          notifications: "None",
          lock: "Lock",
          transitionPermissions: "Everyone",
        },
      ],
    },
    {
      id: 3,
      currentStatus: "In Review",
      children: [
        {
          id: 301,
          action: "Approve",
          newStatus: "Approved",
          approval: "Yes",
          notifications: "None",
          lock: "Lock",
          transitionPermissions: "Everyone",
        },
        {
          id: 302,
          action: "Reject",
          newStatus: "Rejected",
          approval: "",
          notifications: "None",
          lock: "Unlock",
          transitionPermissions: "Everyone",
        },
        {
          id: 303,
          action: "Rework",
          newStatus: "Draft",
          approval: "",
          notifications: "None",
          lock: "Unlock",
          transitionPermissions: "Everyone",
        },
      ],
    },
    {
      id: 4,
      currentStatus: "Approved",
      children: [
        {
          id: 401,
          action: "Rework",
          newStatus: "Draft",
          approval: "",
          notifications: "None",
          lock: "Unlock",
          transitionPermissions: "Everyone",
        },
      ],
    },
    {
      id: 5,
      currentStatus: "Rejected",
      children: [
        {
          id: 501,
          action: "Rework",
          newStatus: "Draft",
          approval: "",
          notifications: "None",
          lock: "Unlock",
          transitionPermissions: "Everyone",
        },
      ],
    },
  ]);
  const [columns, setColumns] = useState(columnsData);

  const tableRows = useMemo(() => {
    let tableRow = [];
    if (!data || data.length === 0) {
      return tableRow;
    } else {
      data.forEach((row) => {
        const { children } = row;
        children.forEach((o, index) => {
          const cList = columns.map((c) => {
            if (index === 0 && c.index === "currentStatus") {
              return (
                <TableCell
                  key={`${row.id}_${o.id}_${c.index}`}
                  rowSpan={children.length}
                  className="table_cell table_body_cell"
                >
                  {row.currentStatus}
                </TableCell>
              );
            } else if (c.index !== "currentStatus") {
              if (c.index === "action") {
                return (
                  <ActionTableCell key={`${row.id}_${o.id}_${c.index}`} className="table_cell table_body_cell">
                    <Box className="action_box">
                      <Box className="action_value">{o[c.index]}</Box>
                      <Box className="action_icons">
                        <AppSvgIcon size={16} color="action">
                          {"material-outline:trending_flat"}
                        </AppSvgIcon>
                      </Box>
                    </Box>
                  </ActionTableCell>
                );
              } else if (c.index === "newStatus") {
                return (
                  <NewStatusTableCell key={`${row.id}_${o.id}_${c.index}`} className="table_cell table_body_cell">
                    <Box className="new_status_box">
                      <span className="new_status_value">{o[c.index]}</span>
                      <Box className="new_status_icons">
                        <IconButton sx={{ padding: "2px" }}>
                          <AppSvgIcon size={16} color="action">
                            {"material-outline:add"}
                          </AppSvgIcon>
                        </IconButton>
                        <IconButton sx={{ padding: "2px" }}>
                          <AppSvgIcon size={16} color="action">
                            {"material-outline:remove"}
                          </AppSvgIcon>
                        </IconButton>
                      </Box>
                    </Box>
                  </NewStatusTableCell>
                );
              } else if (c.index === "lock") {
                return (
                  <TableCell key={`${row.id}_${o.id}_${c.index}`} className="table_cell table_body_cell">
                    <Box
                      sx={{
                        cursor: "pointer",
                        color: `${o[c.index] === "Lock" ? "#c53f3f" : "rgba(0,0,0,0.85)"}`,
                      }}
                      onClick={() => {
                        o.lock && handleLockChange({ rowId: row.id, cId: o.id });
                      }}
                    >
                      {o[c.index]}
                    </Box>
                  </TableCell>
                );
              } else {
                return (
                  <TableCell key={`${row.id}_${o.id}_${c.index}`} className="table_cell table_body_cell">
                    {o[c.index]}
                  </TableCell>
                );
              }
            }
          });
          const oRow = <TableRow key={`${row.id}_${o.id}`}>{cList}</TableRow>;
          tableRow.push(oRow);
        });
      });
    }
    return tableRow;
  }, [data]);

  const handleLockChange = (value: { rowId; cId }) => {
    const newRows = [...data];
    const rowData = data.find((x) => x.id === value.rowId);
    if (rowData) {
      const rIndex = data.findIndex((x) => x.id === value.rowId);
      const valueInData = rowData.children.find((x) => x.id === value.cId);
      const vIndex = rowData.children.findIndex((x) => x.id === value.cId);
      if (valueInData && vIndex >= 0) {
        rowData.children.splice(vIndex, 1, {
          ...valueInData,
          lock: valueInData.lock === "Lock" ? "Unlock" : "Lock",
        });
      }
      newRows.splice(rIndex, 1, { ...rowData });
      setData(newRows);
    }
  };

  return (
    <>
      <Container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow key={"header_columns"} className="table_header">
                {columns.map((item, index) => {
                  return (
                    <TableCell className="table_cell table_header_cell" key={`${item.index}-${item.name}`}>
                      <IntlMessages id={item.name} />
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default WorkflowTable;
