import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import {
  Box,
  Button,
  Card,
  Drawer,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import { ListModel } from "components/CustomTable/CustomTableModel";
import TableToolBar from "components/CustomTable/TableToolBar";
import ExportDropdown from "components/ExportDropdown";
import CustomizeIcon from "components/Icons/CustomizeIcon";
import { taskData } from "mock/index";
import { NextStatus } from "mock/NextStatus";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { bgColor, borderColor, boxShadow, textColor } from "shared/constants/AppConst";
import { WorkItemStatus } from "shared/constants/AppEnums";

import WorkflowModal from "../Deliverables/Report/WorkflowModal";
import Activities from "../Deliverables/Worksheet/Activities";
import SettingsModal from "../Deliverables/Worksheet/SettingsModal";
import Analysis from "./Analysis";
import AssuranceCase from "./AssuranceCase";
import Comments from "./Comments";
import Comparison from "./Comparison";
import DataSources from "./DataSources";
import Deliverables from "./Deliverables";
import Details from "./Details";
import RequireDetails from "./Details/RequireDetails";
import ExcelTable from "./ExcelTable";
import Interfaces from "./Interfaces";
import Metrics from "./Metrics";
import MyTree from "./MyTree";
import MyTreeGrid from "./MyTreeGrid";
import Relationships from "./Relationships";
import Risks from "./Risks";
import Standards from "./Standards";
import ToolBar from "./ToolBar";
import TreeTableView from "./TreeTableView";
import HistoryModal from "./WorkItemToolBar/HistoryModal";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: "8px",
  background: "#eff0f2",
  padding: theme.spacing(1),
  "& .MuiToggleButtonGroup-grouped": {
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    border: "none",
    "&:last-of-type": {
      marginRight: theme.spacing(0),
    },
  },
  "& .MuiToggleButton-root.Mui-selected, & .MuiToggleButton-root:hover": {
    color: textColor[85],
    background: theme.palette.background.paper,
  },
}));

const WorksheetContainer = styled(Card)(({ theme }) => ({
  height: "calc(100vh - 104px)",

  ".worksheet-content": {
    marginTop: "8px",
    display: "flex",
    height: "calc(100% - 62px)",
    overflow: "hidden",
  },
  ".tree-container": {
    height: "calc(100vh - 104px)",
    borderRight: `1px solid  ${borderColor.dividerColor}`,
    backgroundColor: "#FFF",
  },
  ".worksheet-content-bottom": {
    minWidth: "1240px",
    padding: theme.spacing(5),
    boxShadow: boxShadow.cardBoxShadow,
    background: theme.palette.background.paper,
  },
  ".worksheet-content-right": {
    minWidth: "1240px",
    padding: theme.spacing(5),
    boxShadow: boxShadow.cardBoxShadow,
    background: theme.palette.background.paper,
  },
  ".bottom-arrow": {
    position: "fixed",
    background: "#dfe1e6",
    width: "48px",
    height: "16px",
    bottom: 0,
    transform: "translateX(-50%)",
    borderTopRightRadius: "50%",
    borderTopLeftRadius: "50%",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
  },
  ".bottom-arrow-down": {
    position: "absolute",
    background: "#dfe1e6",
    width: "48px",
    height: "16px",
    transform: "translateX(-50%)",
    borderTopRightRadius: "50%",
    borderTopLeftRadius: "50%",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
  },
  ".menu_fixed_right": {
    position: "fixed",
    width: "32px",
    height: "calc(100vh - 146px)",
    background: "#DCE0E6",
    right: 0,
    bottom: 20,
    zIndex: 100000,
    padding: "4px",
    ".menu_item": {
      width: "28px",
      writingMode: "vertical-rl",
      fontSize: "14px",
      fontFamily: "Roboto",
      padding: "7px",
      color: textColor[65],
      cursor: "pointer",
    },
    ".selected": {
      background: "#FFF",
      color: textColor[85],
      fontWeight: "bold",
    },
  },
  ".menu_fixed_bottom": {
    position: "fixed",
    height: "32px",
    background: "#DCE0E6",
    right: 20,
    bottom: 0,
    zIndex: 100000,
    padding: "4px",
    ".menu_item": {
      height: "28px",
      display: "inline-block",
      fontSize: "14px",
      fontFamily: "Roboto",
      padding: "7px",
      color: textColor[65],
      cursor: "pointer",
    },
    ".selected": {
      background: "#FFF",
      color: textColor[85],
      fontWeight: "bold",
    },
  },
}));
const Worksheet = () => {
  const routeParam = useParams();
  const navigate = useNavigate();
  const { messages } = useIntl();
  const [itemAnalysisMenu, setItemAnalysisMenu] = React.useState([
    {
      id: "1",
      label: "Work Item Analysis",
      children: [
        {
          id: "11",
          label: "Details",
        },
        {
          id: "12",
          label: "Relationships",
        },
        {
          id: "13",
          label: "Fault Tree Analysis",
        },
        {
          id: "14",
          label: "Related Risks",
        },
        {
          id: "15",
          label: "Assurance Case",
        },
        {
          id: "16",
          label: "Tree Table View",
        },
      ],
    },
  ]);
  const [insightMenu, setInsightMenu] = React.useState([
    {
      id: "2",
      label: "Perspectives",
      children: [
        {
          id: "21",
          label: "Data Sources",
        },
        {
          id: "22",
          label: "Metrics",
        },
        {
          id: "23",
          label: "Comparison",
        },
        {
          id: "24",
          label: "Comments",
        },
        {
          id: "25",
          label: "Activities",
        },
      ],
    },
    {
      id: "3",
      label: "Resources",
      children: [
        {
          id: "31",
          label: "Deliverables",
        },
        {
          id: "32",
          label: "Interfaces",
        },
        {
          id: "33",
          label: "Standards",
        },
      ],
    },
  ]);
  const [layout, setLayout] = React.useState("");
  const [bottomOpen, setBottomOpen] = React.useState(false);
  const [rightOpen, setRightOpen] = React.useState(false);
  const [layoutType, setLayoutType] = React.useState(1); // 1---excelTable   2---treeGrid
  const [selectedValue, setSelectedValue] = React.useState({ id: "11", label: "Details" });
  const [selectedRightValue, setSelectedRightValue] = React.useState({ id: "", label: "" });
  const [deliverableType, setDeliverableType] = React.useState({
    id: "1",
    labelId: "common.worksheet",
  });
  const [deliverablesProduct, setDeliverablesProduct] = React.useState("");
  const [deliverablesVersion, setDeliverablesVersion] = React.useState("");
  const [dataSourceType, setDataSourceType] = React.useState<any>("");
  const [rmType, setRmType] = React.useState("1");
  const [processData, setProcessData] = React.useState<any>(null);
  const [searchText, setSearchText] = React.useState("");
  const [filter1, setFilter1] = React.useState<any>("");
  const [filterList1] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter1",
    },
  ]);
  const [mockData, setMockData] = React.useState(null);
  const [openSettingsModal, setOpenSettingsModal] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState(WorkItemStatus.Draft.toString());
  const [showHistoryModal, setShowHistoryModal] = React.useState<boolean>(false);
  const [showWorkflowModal, setShowWorkflowModal] = React.useState<boolean>(false);
  const [filter2, setFilter2] = React.useState<any>("");
  const [filterList2] = React.useState<ListModel[]>([
    {
      id: "1",
      name: "Filter2",
    },
  ]);

  // const [showMenu, setShowMenu] = React.useState<boolean>(false);

  // React.useEffect(() => {
  //   window.addEventListener("mousemove", (e) => {
  //     const pos = mousePos(e);
  //     if (
  //       (document.body.clientWidth - pos.x <= 32 && layout !== "up_down") ||
  //       (document.body.clientHeight - pos.y <= 32 && layout === "up_down")
  //     ) {
  //       setShowMenu(true);
  //     } else {
  //       setShowMenu(false);
  //     }
  //   });
  //   return () => {
  //     window.removeEventListener("mousemove", (e) => {
  //       const pos = mousePos(e);
  //       if (
  //         (document.body.clientWidth - pos.x <= 32 && layout !== "up_down") ||
  //         (document.body.clientHeight - pos.y <= 32 && layout === "up_down")
  //       ) {
  //         setShowMenu(true);
  //       } else {
  //         setShowMenu(false);
  //       }
  //     });
  //   };
  // }, [layout]);

  // const mousePos = (e) => {
  //   e = e || window.event;
  //   var scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
  //   var scrollY = document.documentElement.scrollTop || document.body.scrollTop;
  //   var x = e.pageX || e.clientX + scrollX;
  //   var y = e.pageY || e.clientY + scrollY;
  //   return { x: x, y: y };
  // };

  React.useEffect(() => {
    switch (routeParam.type) {
      case "sha":
        setItemAnalysisMenu([
          {
            id: "1",
            label: "Work Item Analysis",
            children: [
              {
                id: "11",
                label: "Details",
              },
              {
                id: "12",
                label: "Impact Analysis",
              },
              {
                id: "13",
                label: "Fault Tree Analysis",
              },
              {
                id: "14",
                label: "Related Risks",
              },
              {
                id: "15",
                label: "Assurance Case",
              },
              {
                id: "16",
                label: "Tree Table View",
              },
            ],
          },
        ]);
        setInsightMenu([
          {
            id: "2",
            label: "Perspectives",
            children: [
              {
                id: "21",
                label: "Data Sources",
              },
              {
                id: "22",
                label: "Metrics",
              },
              {
                id: "23",
                label: "Comparison",
              },
              {
                id: "24",
                label: "Comments",
              },
              {
                id: "25",
                label: "Activities",
              },
            ],
          },
          {
            id: "3",
            label: "Resources",
            children: [
              {
                id: "31",
                label: "Deliverables",
              },
              {
                id: "32",
                label: "Interfaces",
              },
              {
                id: "33",
                label: "Standards",
              },
              // {
              //   id: "34",
              //   label: "Library",
              // },
            ],
          },
        ]);
        setLayoutType(1);
        break;
      case "und":
        setItemAnalysisMenu([
          {
            id: "1",
            label: "Work Item Analysis",
            children: [
              {
                id: "11",
                label: "Details",
              },
              {
                id: "12",
                label: "Impact Analysis",
              },
            ],
          },
        ]);
        setInsightMenu([
          {
            id: "2",
            label: "Perspectives",
            children: [
              {
                id: "22",
                label: "Metrics",
              },
              {
                id: "23",
                label: "Comparison",
              },
              {
                id: "24",
                label: "Comments",
              },
              {
                id: "25",
                label: "Activities",
              },
            ],
          },
          {
            id: "3",
            label: "Resources",
            children: [
              {
                id: "31",
                label: "Deliverables",
              },
              {
                id: "33",
                label: "Standards",
              },
            ],
          },
        ]);
        setLayoutType(2);
        break;
      case "dir":
        setItemAnalysisMenu([
          {
            id: "1",
            label: "Work Item Analysis",
            children: [
              {
                id: "11",
                label: "Details",
              },
              {
                id: "12",
                label: "Impact Analysis",
              },
            ],
          },
        ]);
        setInsightMenu([
          {
            id: "2",
            label: "Perspectives",
            children: [
              {
                id: "21",
                label: "Data Sources",
              },
              {
                id: "22",
                label: "Metrics",
              },
              {
                id: "23",
                label: "Comparison",
              },
              {
                id: "24",
                label: "Comments",
              },
              {
                id: "25",
                label: "Activities",
              },
            ],
          },
          {
            id: "3",
            label: "Resources",
            children: [
              {
                id: "31",
                label: "Deliverables",
              },
              {
                id: "33",
                label: "Standards",
              },
            ],
          },
        ]);
        setLayoutType(1);
        break;
      case "tc":
        setItemAnalysisMenu([
          {
            id: "1",
            label: "Work Item Analysis",
            children: [
              {
                id: "11",
                label: "Details",
              },
              {
                id: "12",
                label: "Impact Analysis",
              },
            ],
          },
        ]);
        setInsightMenu([
          {
            id: "2",
            label: "Perspectives",
            children: [
              {
                id: "22",
                label: "Metrics",
              },
              {
                id: "23",
                label: "Comparison",
              },
              {
                id: "24",
                label: "Comments",
              },
              {
                id: "25",
                label: "Activities",
              },
            ],
          },
          {
            id: "3",
            label: "Resources",
            children: [
              {
                id: "31",
                label: "Deliverables",
              },
              {
                id: "33",
                label: "Standards",
              },
            ],
          },
        ]);
        setLayoutType(2);
        break;
      case "tm":
        setItemAnalysisMenu([
          {
            id: "1",
            label: "Work Item Analysis",
            children: [
              {
                id: "11",
                label: "Details",
              },
              {
                id: "12",
                label: "Impact Analysis",
              },
            ],
          },
        ]);
        setInsightMenu([
          {
            id: "2",
            label: "Perspectives",
            children: [
              {
                id: "21",
                label: "Data Sources",
              },
              {
                id: "22",
                label: "Metrics",
              },
              {
                id: "23",
                label: "Comparison",
              },
              {
                id: "24",
                label: "Comments",
              },
              {
                id: "25",
                label: "Activities",
              },
            ],
          },
          {
            id: "3",
            label: "Resources",
            children: [
              {
                id: "31",
                label: "Deliverables",
              },
              {
                id: "33",
                label: "Standards",
              },
            ],
          },
        ]);
        setLayoutType(1);
        break;
      default:
        setItemAnalysisMenu([
          {
            id: "1",
            label: "Work Item Analysis",
            children: [
              {
                id: "11",
                label: "Details",
              },
              {
                id: "12",
                label: "Impact Analysis",
              },
              {
                id: "13",
                label: "Fault Tree Analysis",
              },
              {
                id: "14",
                label: "Related Risks",
              },
              {
                id: "15",
                label: "Assurance Case",
              },
              {
                id: "16",
                label: "Tree Table View",
              },
            ],
          },
        ]);
        setInsightMenu([
          {
            id: "2",
            label: "Perspectives",
            children: [
              {
                id: "21",
                label: "Data Sources",
              },
              {
                id: "22",
                label: "Metrics",
              },
              {
                id: "23",
                label: "Comparison",
              },
              {
                id: "24",
                label: "Comments",
              },
              {
                id: "25",
                label: "Activities",
              },
            ],
          },
          {
            id: "3",
            label: "Resources",
            children: [
              {
                id: "31",
                label: "Deliverables",
              },
              {
                id: "32",
                label: "Interfaces",
              },
              {
                id: "33",
                label: "Standards",
              },
              // {
              //   id: "34",
              //   label: "Library",
              // },
            ],
          },
        ]);
        setLayoutType(1);
        break;
    }
    if (routeParam?.type === "sha" || routeParam.type === "df" || routeParam.type === "ura") {
      setDataSourceType({
        id: "1",
        label: "Hazard Category",
      });
    } else {
      setDataSourceType({
        id: "6",
        label: "Design Input Requirements",
      });
    }
    routeParam?.type && setMockData(taskData[routeParam.type]);
    const initDataSourceTypeId = sessionStorage.getItem("dataSourceTypeId");
    const initLayout = sessionStorage.getItem("layout");
    const initopenSettingModal = sessionStorage.getItem("openSettingModal");
    const initDeliverablesVersion = sessionStorage.getItem("deliverablesVersion");
    const initDeliverablesProduct = sessionStorage.getItem("deliverablesProduct");
    const initDeliverableTypeId = sessionStorage.getItem("deliverableTypeId");
    const initRmtype = sessionStorage.getItem("rmType");
    const initSelectedRightValueId = sessionStorage.getItem("selectedRightValueId");
    const initSelectedValueId = sessionStorage.getItem("selectedValueId");
    const initBottomOpen = sessionStorage.getItem("bottomOpen");
    const initRightOpen = sessionStorage.getItem("rightOpen");
    initLayout && setLayout(initLayout);
    initDeliverablesVersion && setDeliverablesVersion(initDeliverablesVersion);
    initDeliverablesProduct && setDeliverablesProduct(initDeliverablesProduct);
    initRmtype && setRmType(initRmtype);
    initBottomOpen === "1" ? setBottomOpen(true) : setBottomOpen(false);
    initRightOpen === "1" ? setRightOpen(true) : setRightOpen(false);
    initopenSettingModal === "1" ? setOpenSettingsModal(true) : setOpenSettingsModal(false);
    const currentSelectedValue = [
      {
        id: "11",
        label: "Details",
      },
      {
        id: "12",
        label: "Relationships",
      },
      {
        id: "13",
        label: "Fault Tree Analysis",
      },
      {
        id: "14",
        label: "Related Risks",
      },
      {
        id: "15",
        label: "Assurance Case",
      },
      {
        id: "16",
        label: "Tree Table View",
      },
    ].filter((item) => item.id === initSelectedValueId)[0];
    currentSelectedValue && setSelectedValue(currentSelectedValue);
    const currentSelectedRightValue = [
      {
        id: "21",
        label: "Data Sources",
      },
      {
        id: "22",
        label: "Metrics",
      },
      {
        id: "23",
        label: "Comparison",
      },
      {
        id: "24",
        label: "Comments",
      },
      {
        id: "25",
        label: "Activities",
      },
      {
        id: "31",
        label: "Deliverables",
      },
      {
        id: "32",
        label: "Interfaces",
      },
      {
        id: "33",
        label: "Standards",
      },
    ].filter((item) => item.id === initSelectedRightValueId)[0];
    currentSelectedRightValue && setSelectedRightValue(currentSelectedRightValue);
    const currentDeliverableTypeValue = [
      {
        id: "1",
        labelId: "common.worksheet",
      },
      {
        id: "2",
        labelId: "common.impact.summary",
      },
      {
        id: "3",
        labelId: "common.report",
      },
    ].filter((item) => item.id === initDeliverableTypeId)[0];
    currentDeliverableTypeValue && setDeliverableType(currentDeliverableTypeValue);
    const currentDataSourceType = [
      {
        id: "1",
        label: "Hazard Category",
      },
      {
        id: "2",
        label: "System Hazards",
      },
      {
        id: "3",
        label: "Hazardous Situations",
      },
      {
        id: "4",
        label: "Sequence of Events/Causes",
      },
      {
        id: "5",
        label: "Safety Harms",
      },
      {
        id: "6",
        label: "Design Input Requirements",
      },
      {
        id: "7",
        label: "User Needs",
      },
      {
        id: "8",
        label: "Validations",
      },
      {
        id: "9",
        label: "HW Subsystem Requirements",
      },
      {
        id: "10",
        label: "Design Specification",
      },
      {
        id: "11",
        label: "DMR Design Outputs",
      },
      {
        id: "12",
        label: "System Verifications",
      },
      {
        id: "13",
        label: "SW Verifications",
      },
      {
        id: "14",
        label: "HW Verifications",
      },
      {
        id: "15",
        label: "IQ/OQ/PQ",
      },
    ].filter((item) => item.id === initDataSourceTypeId)[0];
    currentDataSourceType && setDataSourceType(currentDataSourceType);

    return () => {
      sessionStorage.setItem("layout", "");
      sessionStorage.setItem("openSettingsModal", "0");
      sessionStorage.setItem("dataSourceTypeId", "");
      sessionStorage.setItem("deliverablesVersion", "");
      sessionStorage.setItem("deliverablesProduct", "");
      sessionStorage.setItem("deliverableTypeId", "");
      sessionStorage.setItem("rmType", "");
      sessionStorage.setItem("selectedRightValueId", "");
      sessionStorage.setItem("selectedValueId", "");
      sessionStorage.setItem("bottomOpen", "0");
      sessionStorage.setItem("rightOpen", "0");
    };
  }, [routeParam.type]);

  React.useEffect(() => {
    if (insightMenu.length > 0 && itemAnalysisMenu.length > 0) {
      // const initSelectedRightValueId = sessionStorage.getItem("selectedRightValueId");
      const initSelectedValueId = sessionStorage.getItem("selectedValueId");
      // !initSelectedRightValueId && setSelectedRightValue(insightMenu[0].children[0]);
      !initSelectedValueId && setSelectedValue(itemAnalysisMenu[0].children[0]);
    }
  }, [insightMenu, itemAnalysisMenu]);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
  };

  const getSelectedPage = useCallback(() => {
    switch (selectedValue.id) {
      case "11":
        return routeParam.type === "sha" || routeParam.type === "df" || routeParam.type === "ura" ? (
          <Details />
        ) : (
          <RequireDetails type={routeParam.type} />
        );
      case "12":
        return <Relationships />;
      case "13":
        return <Analysis />;
      case "14":
        return <Risks />;
      case "15":
        return <AssuranceCase />;
      case "16":
        return <TreeTableView />;
      default:
        return routeParam.type === "sha" || routeParam.type === "df" || routeParam.type === "ura" ? (
          <Details />
        ) : (
          <RequireDetails type={routeParam.type} />
        );
    }
  }, [selectedValue, routeParam]);

  const getSelectedRightPage = useCallback(() => {
    switch (selectedRightValue.id) {
      case "21":
        return <DataSources dataSourceType={dataSourceType} />;
      case "22":
        return <Metrics />;
      case "23":
        return <Comparison />;
      case "24":
        return <Comments />;
      case "25":
        return <Activities />;
      case "31":
        return (
          <Deliverables
            deliverablesVersion={deliverablesVersion}
            deliverablesProduct={deliverablesProduct}
            deliverableType={deliverableType}
            setProcessData={setProcessData}
            processData={processData}
          />
        );
      case "32":
        return <Interfaces rmType={rmType} />;
      case "33":
        return <Standards />;
      default:
        return <DataSources dataSourceType={dataSourceType} />;
    }
  }, [
    selectedRightValue.id,
    dataSourceType,
    deliverablesVersion,
    deliverablesProduct,
    deliverableType,
    processData,
    rmType,
  ]);

  const getTopContentStyle = () => {
    let sxObj = {};
    if (rightOpen) {
      sxObj = { width: "100%" };
      // return { width: "50%" };
    } else if (bottomOpen) {
      sxObj = { width: "100%", maxHeight: "calc(50vh - 85px)", overflow: "hidden" };
      // return { width: "100%", maxHeight: "calc(50vh - 85px)", overflow: "auto" };
    } else {
      sxObj = { width: "100%", maxHeight: "calc(100vh - 170px)", overflow: "hidden" };
      // return { width: "100%", maxHeight: "calc(100vh - 170px)", overflow: "auto" };
    }
    sxObj["& .spreadsheet-box"] = {
      height: "100%",
      "& #spreadsheet": {
        maxHeight: "calc(100% - 52px)",
      },
    };
    sxObj["& .tree-grid-box"] = {
      height: "100%",
      "& .tree-grid-layout-box": {
        maxHeight: "100%",
        "& .my-tree-table-box": {
          height: "100%",
          "& .my-tree-table-container": {
            height: "calc(100% - 52px)",
            "& .my_table_container": {
              height: "100%",
            },
          },
        },
      },
    };
    return sxObj;
  };

  const getWorksheetContainerStyles = (layoutType) => {
    if (layoutType === 2) {
      return { padding: "0px", background: bgColor.main };
    }
    return {
      background: bgColor.main,
      padding: "20px",
    };
  };

  const handleLayout = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (layout === "up_down" && newAlignment !== "up_down") {
      setBottomOpen(false);
    }
    if (newAlignment === "left_right") {
      setRightOpen(true);
    } else if (newAlignment === "up_down") {
      setBottomOpen(true);
      setOpenSettingsModal(false);
      setRightOpen(false);
    } else {
      setRightOpen(false);
    }
    if (newAlignment && !selectedRightValue?.id) {
      setSelectedRightValue(insightMenu[0].children[0]);
    }
    if (!newAlignment) {
      setSelectedRightValue({ id: "", label: "" });
    }
    setLayout(newAlignment || "");
  };

  return (
    <WorksheetContainer sx={getWorksheetContainerStyles(layoutType)}>
      <AppScrollbar
        sx={{
          height: "calc(100vh - 104px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "relative",
            height: "calc(100vh - 104px)",
          }}
        >
          {layoutType === 2 && (
            <Box sx={{ width: rightOpen ? "15%" : "20%" }} className="tree-container">
              <MyTree />
            </Box>
          )}
          <Box
            className={layoutType === 2 ? "my-tree-grid-right" : "excel-table-center"}
            sx={
              layoutType === 2
                ? {
                    width: rightOpen ? "calc(55% - 40px)" : "calc(80% - 20px)",
                    padding: rightOpen ? "20px 20px 0px 20px" : "20px 20px 0 20px",
                    height: bottomOpen ? "calc(50% - 40px)" : "calc(100% - 40px)",
                    background: "#FFF",
                    overflow: "hidden",
                    ":hover": {
                      overflow: "auto",
                    },
                    margin: "20px",
                  }
                : {
                    width: rightOpen ? "70%" : "100%",
                    padding: rightOpen ? "20px 20px 0px 20px" : "20px 20px 0 20px",
                    height: bottomOpen ? "calc(50% - 40px)" : "calc(100% - 40px)",
                    background: "#FFF",
                    overflow: "hidden",
                    ":hover": {
                      overflow: "auto",
                    },
                  }
            }
          >
            <Box className="tool-bar">
              {
                <TableToolBar
                  showSearch={true}
                  searchText={searchText}
                  requestSearch={requestSearch}
                  saveFilter={() => {
                    console.log("save filter");
                  }}
                  filters={[
                    {
                      value: filter1,
                      list: filterList1,
                      setValue: setFilter1,
                    },
                  ]}
                >
                  {
                    <>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={openSettingsModal}
                              onChange={() => {
                                if (openSettingsModal) {
                                  setBottomOpen(false);
                                } else {
                                  if (layout === "up_down") {
                                    setSelectedRightValue({ id: "", label: "" });
                                    setLayout("");
                                  }
                                  setBottomOpen(true);
                                }
                                setOpenSettingsModal(!openSettingsModal);
                              }}
                            />
                          }
                          label={String(messages["common.show.setting"])}
                        />
                      </FormGroup>
                      {layoutType === 2 && (
                        <Box>
                          <Button
                            sx={{
                              mr: 2,
                              color: "#2e6ccb",
                            }}
                            onClick={() => {}}
                            startIcon={<AddCircleIcon sx={{ fontSize: "16px !important" }} color="primary" />}
                          >
                            <IntlMessages id="common.add" />
                          </Button>
                          <Button
                            sx={{
                              color: textColor[85],
                            }}
                            onClick={() => {}}
                            startIcon={<CustomizeIcon />}
                          >
                            <IntlMessages id="common.customize" />
                          </Button>
                        </Box>
                      )}
                      <ExportDropdown exportToWord={() => {}} exportToExcel={() => {}} exportToPdf={() => {}} />
                      <StyledToggleButtonGroup
                        size="small"
                        value={layout}
                        exclusive
                        onChange={handleLayout}
                        aria-label="text alignment"
                      >
                        <ToggleButton value="">
                          <AppSvgIcon size="16px">material-outline:check_box_outline_blank</AppSvgIcon>
                        </ToggleButton>
                        <ToggleButton value="up_down">
                          <AppSvgIcon size="16px">material-outline:view_agenda</AppSvgIcon>
                        </ToggleButton>
                        <ToggleButton value="left_right">
                          <AppSvgIcon size="16px" sxStyle={{ transform: "rotate(90deg)" }}>
                            material-outline:view_agenda
                          </AppSvgIcon>
                        </ToggleButton>
                      </StyledToggleButtonGroup>
                    </>
                  }
                </TableToolBar>
              }
            </Box>
            <Box
              className="worksheet-content"
              sx={
                rightOpen
                  ? {
                      flexDirection: "row",
                      justifyContent: "space-between",
                      height: bottomOpen ? "calc(50% - 20px)" : "calc(100% - 62px)",
                    }
                  : { flexDirection: "column", height: bottomOpen ? "calc(50% - 20px)" : "calc(100% - 62px)" }
              }
            >
              <Box sx={getTopContentStyle()}>
                {layoutType === 2 ? (
                  <MyTreeGrid
                    bottomOpen={bottomOpen}
                    setSelectedValue={setSelectedValue}
                    setBottomOpen={setBottomOpen}
                    setOpenSettingsModal={setOpenSettingsModal}
                    setLayout={setLayout}
                    layout={layout}
                  />
                ) : (
                  <ExcelTable
                    bottomOpen={bottomOpen}
                    setSelectedValue={setSelectedValue}
                    setBottomOpen={setBottomOpen}
                    setOpenSettingsModal={setOpenSettingsModal}
                    setLayout={setLayout}
                    layout={layout}
                  />
                )}
              </Box>
            </Box>
            <Drawer
              sx={{
                width: "100%",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: rightOpen ? (layoutType === 1 ? "70%" : "calc(55%)") : layoutType === 1 ? "100%" : "calc(80%)",
                  padding: (theme) => theme.spacing(5),
                  marginLeft: rightOpen ? (layoutType === 1 ? "0" : "15%") : layoutType === 1 ? "0" : "20%",
                  background: bgColor.main,
                },
              }}
              variant="persistent"
              anchor="bottom"
              open={bottomOpen && layout !== "up_down"}
            >
              <AppScrollbar
                sx={{
                  width: "100%",
                  height: "calc(50vh - 100px)",
                }}
              >
                <Box className="worksheet-content-bottom">
                  {openSettingsModal ? (
                    <>
                      <SettingsModal moduleType={routeParam.type} />
                    </>
                  ) : (
                    <>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ fontWeight: "bold" }}>
                          <EmojiPeopleOutlinedIcon sx={{ verticalAlign: "bottom" }} />
                          {mockData?.workItemAnalysis?.title
                            ? mockData.workItemAnalysis.title
                            : "S#254328 Incorrectly controlled delivery"}
                        </Box>
                        <Box sx={{ ml: 8, display: "flex", alignItems: "center" }}>
                          <Select
                            value={status}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setStatus(event.target.value);
                            }}
                            variant="outlined"
                            autoWidth
                            displayEmpty
                            disabled={false}
                          >
                            {NextStatus[status]?.length > 0 &&
                              NextStatus[status].map((statusEnum, index) => (
                                <MenuItem value={statusEnum.toString()} key={statusEnum.toString() + index}>
                                  {statusEnum.toString()}
                                </MenuItem>
                              ))}
                          </Select>
                          <Button
                            onClick={() => {
                              setShowHistoryModal(true);
                            }}
                            variant="outlined"
                            sx={{ mx: 2 }}
                          >
                            <IntlMessages id="common.change.history" />
                          </Button>
                          <Button
                            onClick={() => {
                              setShowWorkflowModal(true);
                            }}
                            variant="outlined"
                          >
                            <IntlMessages id="admin.nav.item.workflow" />
                          </Button>
                        </Box>
                      </Box>
                      <ToolBar
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        list={itemAnalysisMenu}
                        isTab={true}
                        dataSourceType={dataSourceType}
                        rmType={rmType}
                        deliverablesVersion={deliverablesVersion}
                        deliverablesProduct={deliverablesProduct}
                        deliverableType={deliverableType}
                        processData={processData}
                        setDataSourceType={setDataSourceType}
                        setRmType={setRmType}
                        setDeliverablesVersion={setDeliverablesVersion}
                        setDeliverablesProduct={setDeliverablesProduct}
                        setDeliverableType={setDeliverableType}
                      />
                      {getSelectedPage()}
                    </>
                  )}
                </Box>
              </AppScrollbar>
            </Drawer>
            <Drawer
              sx={
                layout === "up_down"
                  ? {
                      width: "100%",
                      flexShrink: 0,
                      "& .MuiDrawer-paper": {
                        width: rightOpen
                          ? layoutType === 1
                            ? "70%"
                            : "calc(55%)"
                          : layoutType === 1
                          ? "100%"
                          : "calc(80%)",
                        padding: (theme) => theme.spacing(5),
                        marginLeft: rightOpen ? (layoutType === 1 ? "0" : "15%") : layoutType === 1 ? "0" : "20%",
                        background: bgColor.main,
                      },
                    }
                  : {
                      width: "100%",
                      flexShrink: 0,
                      "& .MuiDrawer-paper": {
                        width: layoutType === 1 ? "30%" : "30%",
                        height: "calc(100vh - 104px)",
                        padding: (theme) => theme.spacing(5),
                        paddingLeft: (theme) => theme.spacing(3),
                        border: "none",
                        background: bgColor.main,
                        marginTop: "104px",
                      },
                    }
              }
              variant="persistent"
              anchor={layout === "up_down" ? "bottom" : "right"}
              open={rightOpen || (layout === "up_down" && bottomOpen)}
            >
              <AppScrollbar
                sx={{
                  width: "100%",
                  height: layout === "up_down" ? "calc(50vh - 100px)" : "calc(100vh - 144px)",
                }}
              >
                <Box className={layout === "up_down" ? "worksheet-content-bottom" : "worksheet-content-right"}>
                  <ToolBar
                    selectedValue={selectedRightValue}
                    setSelectedValue={setSelectedRightValue}
                    list={insightMenu}
                    dataSourceType={dataSourceType}
                    rmType={rmType}
                    deliverablesVersion={deliverablesVersion}
                    deliverablesProduct={deliverablesProduct}
                    deliverableType={deliverableType}
                    processData={processData}
                    setDataSourceType={setDataSourceType}
                    setRmType={setRmType}
                    setDeliverablesVersion={setDeliverablesVersion}
                    setDeliverablesProduct={setDeliverablesProduct}
                    setDeliverableType={setDeliverableType}
                  />
                  <AppScrollbar
                    sx={{
                      width: "100%",
                      height: layout === "up_down" ? "calc(50vh - 190px)" : "calc(100vh - 260px)",
                      "& .simplebar-content": {
                        height: "100%",
                      },
                    }}
                  >
                    {getSelectedRightPage()}
                  </AppScrollbar>
                </Box>
              </AppScrollbar>
            </Drawer>
          </Box>
          <Box
            sx={{
              left: rightOpen ? (layoutType === 1 ? "35%" : "42.5%") : layoutType === 1 ? "50%" : "60%",
              bottom: bottomOpen ? (layoutType === 1 ? "calc(50% + 12px)" : "calc(50% - 8px)") : "0px",
            }}
            className={bottomOpen ? "bottom-arrow-down" : "bottom-arrow"}
          >
            <AppSvgIcon
              onClick={() => {
                if (layout === "up_down") {
                  if (!selectedRightValue) {
                    setSelectedRightValue({ id: "21", label: "Data Sources" });
                  }
                } else {
                  if (!selectedValue) {
                    setSelectedValue({ id: "11", label: "Details" });
                  }
                }
                setOpenSettingsModal(false);
                setBottomOpen(!bottomOpen);
              }}
              color="action"
              size={16}
            >
              {bottomOpen ? "material-solid:keyboard_arrow_down" : "material-solid:keyboard_arrow_up"}
            </AppSvgIcon>
          </Box>
          <Box
            sx={{
              width: "calc(100% - 40px)",
            }}
            className={layout === "up_down" ? "menu_fixed_bottom" : "menu_fixed_right"}
          >
            <Box>
              {insightMenu.map((item) => {
                if (item?.children && item.children.length > 0) {
                  return item.children.map((child) => {
                    return (
                      <Box
                        className={selectedRightValue.id === child.id ? "menu_item selected" : "menu_item"}
                        key={child.id}
                        onClick={() => {
                          if (layout !== "up_down") {
                            setLayout("left_right");
                            setRightOpen(true);
                          } else {
                            setBottomOpen(true);
                          }
                          if (selectedRightValue.id === child.id) {
                            setSelectedRightValue({ id: "", label: "" });
                            setRightOpen(false);
                            setBottomOpen(false);
                            setLayout("");
                          } else {
                            setSelectedRightValue(child);
                          }
                          // setShowMenu(false);
                        }}
                      >
                        {child.label}
                      </Box>
                    );
                  });
                }
                return "";
              })}
            </Box>
          </Box>
          {showHistoryModal && (
            <HistoryModal
              handleClose={() => {
                setShowHistoryModal(false);
              }}
              open={showHistoryModal}
            />
          )}
          {showWorkflowModal && (
            <WorkflowModal
              handleClose={() => {
                setShowWorkflowModal(false);
              }}
              open={showWorkflowModal}
            />
          )}
        </Box>
      </AppScrollbar>
    </WorksheetContainer>
  );
};

export default Worksheet;
