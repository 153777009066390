import { ButtonMenuListItemModel } from "components/ButtonMenu/ButtonMenuModel";
import { ModuleType } from "shared/constants/AppEnums";
import Icons from "components/Icons";
import TaskItemIcon from "components/Icons/TaskItemIcon";
import IntlMessages from "@crema/utility/IntlMessages";

const {
  RequirementIcon,
  RiskIcon,
  CybersecurityIcon,
  AssuranceCaseIcon,
  DCDIcon,
  ProductionIcon,
  TechnicalFileIcon,
  ModulesIcon,
} = Icons;

export const ModuleMenuList: ButtonMenuListItemModel[] = [
  {
    id: ModuleType.DCD,
    labelId: "common.design.controls",
    icon: <DCDIcon />,
    value: <IntlMessages id="common.design.controls" />,
    name: "Design Controls",
  },
  // {
  //   id: ModuleType.REQ,
  //   labelId: "common.requirement.management",
  //   icon: <RequirementIcon />,
  //   value: <IntlMessages id="common.requirement.management" />,
  //   name: "Requirement Management",
  // },
  {
    id: ModuleType.RISK,
    labelId: "common.risk.management",
    icon: <RiskIcon />,
    value: <IntlMessages id="common.risk.management" />,
    name: "Risk Management",
  },
  {
    id: ModuleType.CYBER,
    labelId: "common.cybersecurity.management",
    icon: <CybersecurityIcon />,
    value: <IntlMessages id="common.cybersecurity.management" />,
    name: "Cybersecurity Management",
  },
  {
    id: ModuleType.AC,
    labelId: "common.assurance.case.management",
    icon: <AssuranceCaseIcon />,
    value: <IntlMessages id="common.assurance.case.management" />,
    name: "Assurance Case Management",
  },
  {
    id: ModuleType.PRODUCTION,
    labelId: "common.production.management",
    icon: <ProductionIcon />,
    value: <IntlMessages id="common.production.management" />,
    name: "Production Management",
  },
  {
    id: ModuleType.TF,
    labelId: "common.index.file.management",
    icon: <TechnicalFileIcon />,
    value: <IntlMessages id="common.index.file.management" />,
    name: "Index File Management",
  },
];

export const RequirementProcessData: any[] = [
  {
    id: "Requirement Management",
    value: "Requirement Management Deliverables",
    isModule: true,
    isBold: true,
    isFolder: true,
    items: [
      {
        id: "Design Inputs",
        value: "Design Inputs",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "User Needs",
            value: "User Needs",
            docId: "DOC-UND-001",
            taskId: "TK#011",
            taskType: "und",
            // color: "#FAB036",
            changed: true,
            labelIcon: TaskItemIcon,
          },
          {
            id: "Design Input Requirements",
            value: "Design Input Requirements",
            docId: "DOC-DIR-001",
            taskId: "TK#012",
            taskType: "dir",
            // color: "#DE350B",
            changed: true,
            labelIcon: TaskItemIcon,
          },
          {
            id: "HW Subsystem Requirements",
            value: "HW Subsystem Requirements",
            docId: "DOC-HWSR-001",
            taskId: "TK#013",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
          {
            id: "SW Subsystem Requirements",
            value: "SW Subsystem Requirements",
            docId: "DOC-SWSR-001",
            taskId: "TK#014",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design Outputs",
        value: "Design Outputs",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Design Specifications",
            value: "Design Specifications",
            docId: "DOC-DS-001",
            taskId: "TK#015",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
          {
            id: "DMR Design Outputs",
            value: "DMR Design Outputs",
            docId: "DOC-DMR-001",
            taskId: "TK#016",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design Verifications",
        value: "Design Verifications",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "System Verifications",
            value: "System Verifications",
            docId: "DOC-SV-001",
            taskId: "TK#017",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
          {
            id: "HW Verifications",
            value: "HW Verifications",
            docId: "DOC-HWV-001",
            taskId: "TK#018",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
          {
            id: "SW Verifications",
            value: "SW Verifications",
            docId: "DOC-SWV-001",
            taskId: "TK#019",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Test Cases",
            value: "Test Cases",
            docId: "DOC-TC-001",
            taskId: "TK#020",
            taskType: "tc",
            // color: "#FAB036",
            changed: true,
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design Validations",
        value: "Design Validations",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Validations",
            value: "Validations",
            docId: "DOC-VAL-001",
            taskId: "TK#021",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design Transfer",
        value: "Design Transfer",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "IQ_OQ_PQ",
            value: "IQ/OQ/PQ",
            docId: "DOC-3Q-001",
            taskId: "TK#022",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design Control Traceability",
        value: "Design Control Traceability",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Design Control Traceability Matrix",
            value: "Design Control Traceability Matrix",
            docId: "DOC-DCTM-001",
            taskId: "TK#023",
            taskType: "tm",
            // color: "#DE350B",
            changed: true,
            labelIcon: TaskItemIcon,
          },
          {
            id: "Essential Performance Traceability Matrix",
            value: "Essential Performance Traceability Matrix",
            docId: "DOC-EPTM-001",
            taskId: "TK#024",
            taskType: "tm",
            labelIcon: TaskItemIcon,
          },
        ],
      },
    ],
  },
];

export const RiskProcessData: any[] = [
  {
    id: "Risk Management",
    value: "Risk Management Deliverables",
    isModule: true,
    isBold: true,
    isFolder: true,
    items: [
      {
        id: "Risk Management Plan",
        value: "Risk Management Plan",
        docId: "DOC-Plan-001",
        taskId: "TK#002",
        taskType: "sha",
        labelIcon: TaskItemIcon,
      },
      {
        id: "Safety Characteristic Analysis",
        value: "Safety Characteristic Analysis",
        docId: "DOC-CharAnalysis-001",
        taskId: "TK#003",
        taskType: "sha",
        labelIcon: TaskItemIcon,
      },
      {
        id: "Top Down Analysis",
        value: "Top Down Analysis",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "System Hazard Analysis",
            value: "System Hazard Analysis",
            docId: "DOC-SHA-001",
            taskId: "TK#004",
            taskType: "sha",
            // color: "#FAB036",
            changed: true,
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Sub System  Analysis",
        value: "Sub System  Analysis",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Use Risk Analysis",
            value: "Use Risk Analysis",
            docId: "DOC-URA-001",
            taskId: "TK#005",
            taskType: "ura",
            added: true,
            labelIcon: TaskItemIcon,
            // color: "#DE350B",
          },
        ],
      },
      {
        id: "Bottom Up Analysis",
        value: "Bottom Up Analysis",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Design FMEA",
            value: "Design FMEA",
            docId: "DOC-DFMEA-001",
            taskId: "TK#006",
            taskType: "df",
            // color: "#FAB036",
            changed: true,
            labelIcon: TaskItemIcon,
          },
          {
            id: "Process FMEA",
            value: "Process FMEA",
            docId: "DOC-PFMEA-001",
            taskId: "TK#007",
            taskType: "df",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Risk Assessment Summary",
        value: "Risk Assessment Summary",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Risk Assessment Summary Matrix",
            value: "Risk Assessment Summary Matrix",
            docId: "DOC-RASM-001",
            taskId: "TK#008",
            taskType: "sha",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Benefit vs Risk Analysis",
            value: "Benefit vs Risk Analysis",
            docId: "DOC-BvsRA-001",
            taskId: "TK#009",
            taskType: "sha",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Risk Management Report",
        value: "Risk Management Report",
        docId: "DOC-Report-001",
        taskId: "TK#010",
        taskType: "sha",
        labelIcon: TaskItemIcon,
      },
    ],
  },
];

export const CyberProcessData: any[] = [
  {
    id: "Cybersecurity Risk Management",
    value: "Cybersecurity Risk Management Deliverables",
    isModule: true,
    isBold: true,
    isFolder: true,
    items: [
      {
        id: "C Security Risk Management Plan",
        value: "Security Risk Management Plan",
        labelIcon: TaskItemIcon,
      },
      {
        id: "C Top Down Analysis",
        value: "Top Down Analysis",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "C System Architecture Diagram",
            value: "System Architecture Diagram",
            // color: "#FAB036",
            changed: true,
            labelIcon: TaskItemIcon,
          },
          {
            id: "C Security Asset and Characteristic Analysis",
            value: "Security Asset and Characteristic Analysis",
            labelIcon: TaskItemIcon,
          },
          {
            id: "C Threat Modeling Diagram",
            value: "Threat Modeling Diagram",
            labelIcon: TaskItemIcon,
          },
          {
            id: "C Threat and Vulnerability Analysis",
            value: "Threat and Vulnerability Analysis",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "C Bottom Up Analysis",
        value: "Bottom Up Analysis",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "C Third Party Component Security Analysis",
            value: "Third Party Component Security Analysis",
            labelIcon: TaskItemIcon,
          },
          {
            id: "C Common Vulnerability Analysis",
            value: "Common Vulnerability Analysis",
            added: true,
            labelIcon: TaskItemIcon,
            // color: "#DE350B",
          },
        ],
      },
      {
        id: "C Security and Safety Risk Assessment",
        value: "Security and Safety Risk Assessment",
        labelIcon: TaskItemIcon,
      },
      {
        id: "C Summary Matrix",
        value: "Summary Matrix",
        labelIcon: TaskItemIcon,
      },
      {
        id: "C Benefit vs Risk Analysis",
        value: "Benefit vs Risk Analysis",
        labelIcon: TaskItemIcon,
      },
      {
        id: "C Security Risk Management Report",
        value: "Security Risk Management Report",
        labelIcon: TaskItemIcon,
      },
      {
        id: "C Post Market Security Management Plan",
        value: "Post Market Security Management Plan",
        labelIcon: TaskItemIcon,
      },
    ],
  },
];

export const ACProcessData: any[] = [
  {
    id: "Assurance Case Management",
    value: "Assurance Case Management Deliverables",
    isModule: true,
    isBold: true,
    isFolder: true,
    items: [
      {
        id: "Safety Assurance Case",
        value: "Safety Assurance Case",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Device is adequately safe for its intended use",
            value: "Device is adequately safe for its intended use",
            // color: "#FAB036",
            changed: true,
            labelIcon: TaskItemIcon,
          },
          {
            id: "Contextual information for the safety claim is adequately defined",
            value: "Contextual information for the safety claim is adequately defined",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Device requirements are adequate, and adequately verified and validated",
            value: "Device requirements are adequate, and adequately verified and validated",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Device risks are adequately mitigated",
            value: "Device risks are adequately mitigated",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Device has adequate reliability",
            value: "Device has adequate reliability",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Essential Performance Requirements",
            value: "Essential Performance Requirements",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Confidence Case: Software reliability is adequately assured",
            value: "Confidence Case: Software reliability is adequately assured",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Confidence Case: Device risks are adequately identified and assessed",
            value: "Confidence Case: Device risks are adequately identified and assessed",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Security Assurance Case",
        value: "Security Assurance Case",
        labelIcon: TaskItemIcon,
      },
      {
        id: "Compliance Assurance Case",
        value: "Compliance Assurance Case",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Compliance Assurance Case for CFR Title 21 Part 820 QSR",
            value: "Compliance Assurance Case for CFR Title 21 Part 820 QSR",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Compliance Assurance Case for ISO 13485",
            value: "Compliance Assurance Case for ISO 13485",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Compliance Assurance Case for ISO 14971",
            value: "Compliance Assurance Case for ISO 14971",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Compliance Assurance Case for IEC 62304",
            value: "Compliance Assurance Case for IEC 62304",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Compliance Assurance Case for IEC 60601",
            value: "Compliance Assurance Case for IEC 60601",
            labelIcon: TaskItemIcon,
          },
        ],
      },
    ],
  },
];

export const DCDProcessData: any[] = [
  {
    id: "Design Controls",
    value: "Design Controls Deliverables",
    isModule: true,
    isBold: true,
    isFolder: true,
    items: [
      {
        id: "Design and Development Planning",
        value: "Design and Development Planning",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Product Development Plan",
            value: "Product Development Plan",
            // color: "#FAB036",
            changed: true,
            labelIcon: TaskItemIcon,
          },
          {
            id: "Software Development Plan",
            value: "Software Development Plan",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design Inputs",
        value: "Design Inputs",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Design Input Sources",
            value: "Design Input Sources",
            labelIcon: TaskItemIcon,
          },
          {
            id: "User Needs",
            value: "User Needs",
            docId: "DOC-UND-001",
            taskId: "TK#011",
            taskType: "und",
            // color: "#FAB036",
            changed: true,
            labelIcon: TaskItemIcon,
          },
          {
            id: "Design Input Requirements",
            value: "Design Input Requirements",
            docId: "DOC-DIR-001",
            taskId: "TK#012",
            taskType: "dir",
            // color: "#DE350B",
            changed: true,
            labelIcon: TaskItemIcon,
          },
          {
            id: "HW Subsystem Requirements",
            value: "HW Subsystem Requirements",
            docId: "DOC-HWSR-001",
            taskId: "TK#013",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
          {
            id: "SW Subsystem Requirements",
            value: "SW Subsystem Requirements",
            docId: "DOC-SWSR-001",
            taskId: "TK#014",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design Outputs",
        value: "Design Outputs",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Design Specifications",
            value: "Design Specifications",
            docId: "DOC-DS-001",
            taskId: "TK#015",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
          {
            id: "DMR Design Outputs",
            value: "DMR Design Outputs",
            docId: "DOC-DMR-001",
            taskId: "TK#016",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design Reviews",
        value: "Design Reviews",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Design Input Review",
            value: "Design Input Review",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Design Verification Review",
            value: "Design Verification Review",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Design Validation Review",
            value: "Design Validation Review",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design Verifications",
        value: "Design Verifications",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "System Verifications",
            value: "System Verifications",
            docId: "DOC-SV-001",
            taskId: "TK#017",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
          {
            id: "HW Verifications",
            value: "HW Verifications",
            docId: "DOC-HWV-001",
            taskId: "TK#018",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
          {
            id: "SW Verifications",
            value: "SW Verifications",
            docId: "DOC-SWV-001",
            taskId: "TK#019",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
          {
            id: "Test Cases",
            value: "Test Cases",
            docId: "DOC-TC-001",
            taskId: "TK#020",
            taskType: "tc",
            // color: "#FAB036",
            changed: true,
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design Validations",
        value: "Design Validations",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Validations",
            value: "Validations",
            docId: "DOC-VAL-001",
            taskId: "TK#021",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design Transfer",
        value: "Design Transfer",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "IQ_OQ_PQ",
            value: "IQ/OQ/PQ",
            docId: "DOC-3Q-001",
            taskId: "TK#022",
            taskType: "dir",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design Control Traceability",
        value: "Design Control Traceability",
        isBold: true,
        isFolder: true,
        items: [
          {
            id: "Design Control Traceability Matrix",
            value: "Design Control Traceability Matrix",
            docId: "DOC-DCTM-001",
            taskId: "TK#023",
            taskType: "tm",
            // color: "#DE350B",
            changed: true,
            labelIcon: TaskItemIcon,
          },
          {
            id: "Essential Performance Traceability Matrix",
            value: "Essential Performance Traceability Matrix",
            docId: "DOC-EPTM-001",
            taskId: "TK#024",
            taskType: "tm",
            labelIcon: TaskItemIcon,
          },
        ],
      },
      {
        id: "Design Changes",
        value: "Design Changes",
        isBold: true,
        isFolder: true,
        // labelIcon: TaskItemIcon,
      },
    ],
  },
];

export const ProductionProcessData: any[] = [
  {
    id: "Production Management",
    value: "Production Management Deliverables",
    isModule: true,
    items: [],
  },
];

export const TechnicalFileProcessData: any[] = [
  {
    id: "Index File Management",
    value: "Index File Management Deliverables",
    isModule: true,
    isBold: true,
    isFolder: true,
    items: [
      {
        id: "510(k) Submission(s)",
        value: "510(k) Submission(s)",
        labelIcon: TaskItemIcon,
      },
      {
        id: "PMA Submission(s)",
        value: "PMA Submission(s)",
        labelIcon: TaskItemIcon,
      },
      {
        id: "NDA Submission(s)",
        value: "NDA Submission(s)",
        added: true,
        labelIcon: TaskItemIcon,
      },
      {
        id: "BLA Submission(s)",
        value: "BLA Submission(s)",
        added: true,
        labelIcon: TaskItemIcon,
      },
      {
        id: "MDR Technical File",
        value: "MDR Technical File",
        changed: true,
        labelIcon: TaskItemIcon,
      },
      {
        id: "Design History File",
        value: "Design History File",
        isModule: true,
        changed: true,
        labelIcon: TaskItemIcon,
      },
      {
        id: "Risk Management File",
        value: "Risk Management File",
        labelIcon: TaskItemIcon,
      },
      {
        id: "DMR - Device Master Records",
        value: "DMR - Device Master Records",
        labelIcon: TaskItemIcon,
      },
      {
        id: "DHR - Device History Records",
        value: "DHR - Device History Records",
        labelIcon: TaskItemIcon,
      },
      {
        id: "MBR Master Batch Records",
        value: "MBR Master Batch Records",
        added: true,
        labelIcon: TaskItemIcon,
      },
    ],
  },
];

export const ProcessData = {
  [ModuleType.DCD]: DCDProcessData,
  // [ModuleType.REQ]: RequirementProcessData,
  [ModuleType.RISK]: RiskProcessData,
  [ModuleType.CYBER]: CyberProcessData,
  [ModuleType.AC]: ACProcessData,
  [ModuleType.PRODUCTION]: ProductionProcessData,
  [ModuleType.TF]: TechnicalFileProcessData,
};
