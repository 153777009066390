import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import SaveIcon from "@mui/icons-material/Save";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
} from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import MySelectField from "components/MySelectField";
import MyTextField from "components/MyTextField";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { accordionStyle, bgColor, textColor } from "shared/constants/AppConst";
import * as yup from "yup";

import ContentHeader from "../components/ContentHeader";

const General = () => {
  const TimeZoneList = [
    {
      id: 1,
      key: "(UTC-08:00) Pacific Time (US & Canada)",
      name: "(UTC-08:00) Pacific Time (US & Canada)",
    },
    {
      id: 2,
      key: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
      name: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
    },
    {
      id: 3,
      key: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
      name: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    },
  ];

  const [edit, setEdit] = useState<boolean>(false);
  const { messages } = useIntl();
  const validationSchema = yup.object({
    organization: yup.string().required(String(messages["validation.organization"])),
    oem_license: yup.string().required(String(messages["validation.oem.license"])),
    global_id_prefix: yup.string().required(String(messages["validation.global.id.prefix"])),
  });

  const handleSave = () => {
    console.log(document.getElementById("submit"));
    document.getElementById("submit").click();
  };

  const handleCancel = () => {
    document.getElementById("cancel").click();
  };

  const cancel = (resetForm) => {
    resetForm();
    setEdit(false);
  };

  return (
    <>
      <ContentHeader titleId="admin.nav.item.general">
        <Box sx={{ display: edit ? "none" : "inline-block" }}>
          <Button
            onClick={() => {
              setEdit(true);
            }}
            variant="outlined"
            color="inherit"
            startIcon={<AppSvgIcon size={16}>{"material-solid:edit"}</AppSvgIcon>}
          >
            <IntlMessages id="common.edit" />
          </Button>
        </Box>
        <Box sx={{ display: edit ? "inline-block" : "none" }}>
          <Button
            className="text-color-08"
            sx={{
              mr: 3,
              borderRadius: "4px",
              border: "solid 1px rgba(0, 0, 0, 0.15)",
              color: textColor["85"],
            }}
            onClick={handleCancel}
            startIcon={
              <AppSvgIcon size={16} color={textColor["65"]}>
                {"material-solid:cancel"}
              </AppSvgIcon>
            }
            variant="outlined"
          >
            <IntlMessages id="common.cancel" />
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            startIcon={
              <AppSvgIcon size={16} color="#FFF">
                {"material-solid:save"}
              </AppSvgIcon>
            }
            variant="contained"
          >
            <IntlMessages id="common.save" />
          </Button>
        </Box>
      </ContentHeader>

      <AppScrollbar
        sx={{
          height: "calc(100vh - 104px)",
          padding: (theme) => theme.spacing(5),
        }}
      >
        <Formik
          initialValues={{
            organization: "GessNet",
            sub_domain: "",
            oem_license: "",
            description: "",
            logo_max_size: "",
            email: "",
            url: "",
            smtp_mail_host: "",
            mail_sender: "",
            mail_password: "",
            enable_ssl: "",
            date_time_format: "",
            global_id_prefix: "GID",
            global_id_next_counter_value: "347",
            timeZone: 1,
            autoAdjustCLock: true,
            hoursOfAllowUndoDeletedItem: 0,
            enableJamaConnectInterface: true,
            enablePolarionInterface: true,
            enableDoorsNextInterface: true,
            versionItems: true,
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting, setValues }) => {
            console.log(data);
            setValues({
              ...data,
            });
            setSubmitting(false);
            setEdit(false);
          }}
        >
          {({ isSubmitting, values, setFieldValue, resetForm }) => (
            <Form
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              noValidate
              autoComplete="off"
            >
              <Box sx={accordionStyle}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandCircleDownOutlinedIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box sx={{ width: "100%" }}>
                      <Divider sx={{ width: "100%" }} textAlign="left">
                        <h4 className="text-color-08">
                          <IntlMessages id="admin.profile" />
                        </h4>
                      </Divider>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={9}>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="organization"
                          fullWidth
                          label="admin.profile.form.organization"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: edit && values.organization ? "visible" : "hidden" }}
                                  onClick={() => {
                                    setFieldValue("organization", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                          required={edit ? true : false}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="sub_domain"
                          fullWidth
                          label="admin.profile.form.sub.domain"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: edit && values.sub_domain ? "visible" : "hidden" }}
                                  onClick={() => {
                                    setFieldValue("sub_domain", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="oem_license"
                          fullWidth
                          label="admin.profile.form.oem.license"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: edit && values.oem_license ? "visible" : "hidden" }}
                                  onClick={() => {
                                    setFieldValue("oem_license", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                          required={edit ? true : false}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="description"
                          fullWidth
                          label="admin.profile.form.description"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: edit && values.description ? "visible" : "hidden" }}
                                  onClick={() => {
                                    setFieldValue("description", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="logo_max_size"
                          fullWidth
                          label="admin.profile.form.logo.max.size"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: edit && values.logo_max_size ? "visible" : "hidden" }}
                                  onClick={() => {
                                    setFieldValue("logo_max_size", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="email"
                          fullWidth
                          label="admin.profile.form.email"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: edit && values.email ? "visible" : "hidden" }}
                                  onClick={() => {
                                    setFieldValue("email", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="url"
                          fullWidth
                          label="admin.profile.form.url"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: edit && values.url ? "visible" : "hidden" }}
                                  onClick={() => {
                                    setFieldValue("url", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandCircleDownOutlinedIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box sx={{ width: "100%" }}>
                      <Divider sx={{ width: "100%" }} textAlign="left">
                        <h4 className="text-color-08">
                          <IntlMessages id="common.profile.setting" />
                        </h4>
                      </Divider>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={9}>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="global_id_prefix"
                          fullWidth
                          label="admin.profile.form.global.id.prefix"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: edit && values.global_id_prefix ? "visible" : "hidden" }}
                                  onClick={() => {
                                    setFieldValue("global_id_prefix", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                          required={edit ? true : false}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="global_id_next_counter_value"
                          fullWidth
                          label="admin.profile.form.global.id.next.counter.value"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{
                                    visibility: edit && values.global_id_next_counter_value ? "visible" : "hidden",
                                  }}
                                  onClick={() => {
                                    setFieldValue("global_id_next_counter_value", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="smtp_mail_host"
                          fullWidth
                          label="admin.profile.form.smtp.mail.host"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: edit && values.smtp_mail_host ? "visible" : "hidden" }}
                                  onClick={() => {
                                    setFieldValue("smtp_mail_host", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="mail_sender"
                          fullWidth
                          label="admin.profile.form.mail.sender"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: edit && values.mail_sender ? "visible" : "hidden" }}
                                  onClick={() => {
                                    setFieldValue("mail_sender", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="mail_password"
                          fullWidth
                          label="admin.profile.form.mail.password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: edit && values.mail_password ? "visible" : "hidden" }}
                                  onClick={() => {
                                    setFieldValue("mail_password", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="enable_ssl"
                          fullWidth
                          label="admin.profile.form.enable.ssl"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: edit && values.enable_ssl ? "visible" : "hidden" }}
                                  onClick={() => {
                                    setFieldValue("enable_ssl", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <MyTextField
                          name="date_time_format"
                          fullWidth
                          label="admin.profile.form.date.time.format"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: edit && values.date_time_format ? "visible" : "hidden" }}
                                  onClick={() => {
                                    setFieldValue("date_time_format", "");
                                  }}
                                >
                                  <AppSvgIcon size={16} color="action">
                                    {"material-outline:clear"}
                                  </AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box className="checkboxTitle">&nbsp;</Box>
                        <FormControl className="checkboxList">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="autoAdjustCLock"
                                  onChange={(e) => {
                                    setFieldValue("autoAdjustCLock", e.target.checked);
                                  }}
                                  disabled={!edit}
                                  checked={values.autoAdjustCLock}
                                />
                              }
                              label={<IntlMessages id="settings.autoAdjustCLock" />}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <MySelectField
                          label="settings.timeZone"
                          onChange={(event: SelectChangeEvent) => {
                            setFieldValue("timeZone", Number(event.target.value));
                          }}
                          name="timeZone"
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                          value={`${values.timeZone}`}
                          fullWidth
                          list={TimeZoneList}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <MyTextField
                          name="hoursOfAllowUndoDeletedItem"
                          fullWidth
                          type="number"
                          label="settings.hoursOfAllowUndoDeletedItem"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IntlMessages id="settings.unit.hours" />
                                <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{
                                    visibility: edit && values.hoursOfAllowUndoDeletedItem ? "visible" : "hidden",
                                  }}
                                  onClick={() => {
                                    setFieldValue("hoursOfAllowUndoDeletedItem", "");
                                  }}
                                >
                                  <AppSvgIcon size="10px">{"material-outline:clear"}</AppSvgIcon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant={edit ? "outlined" : "standard"}
                          disabled={!edit}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box className="checkboxTitle">&nbsp;</Box>
                        <FormControl className="checkboxList">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="enableJamaConnectInterface"
                                  onChange={(e) => {
                                    setFieldValue("enableJamaConnectInterface", e.target.checked);
                                  }}
                                  disabled={!edit}
                                  checked={values.enableJamaConnectInterface}
                                />
                              }
                              label={<IntlMessages id="product.settings.confirm.enableJamaConnect" />}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box className="checkboxTitle">&nbsp;</Box>
                        <FormControl className="checkboxList">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="enablePolarionInterface"
                                  onChange={(e) => {
                                    setFieldValue("enablePolarionInterface", e.target.checked);
                                  }}
                                  disabled={!edit}
                                  checked={values.enablePolarionInterface}
                                />
                              }
                              label={<IntlMessages id="product.settings.confirm.enablePolarion" />}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box className="checkboxTitle">&nbsp;</Box>
                        <FormControl className="checkboxList">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="enableDoorsNextInterface"
                                  onChange={(e) => {
                                    setFieldValue("enableDoorsNextInterface", e.target.checked);
                                  }}
                                  disabled={!edit}
                                  checked={values.enableDoorsNextInterface}
                                />
                              }
                              label={<IntlMessages id="product.settings.confirm.enableDOORS" />}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box className="checkboxTitle">&nbsp;</Box>
                        <FormControl className="checkboxList">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="versionItems"
                                  onChange={(e) => {
                                    setFieldValue("versionItems", e.target.checked);
                                  }}
                                  disabled={!edit}
                                  checked={values.versionItems}
                                />
                              }
                              label={<IntlMessages id="product.settings.confirm.versionItems" />}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Button
                  sx={{
                    position: "relative",
                    display: "none",
                    minWidth: 100,
                  }}
                  id="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  type="submit"
                >
                  <IntlMessages id="common.save" />
                </Button>
                <Button
                  sx={{
                    position: "relative",
                    display: "none",
                    minWidth: 100,
                  }}
                  id="cancel"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    cancel(resetForm);
                  }}
                  disabled={isSubmitting}
                >
                  <IntlMessages id="common.cancel" />
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </AppScrollbar>
    </>
  );
};

export default General;
