import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import NoDataIcon from "components/Icons/NoDataIcon";
import React from "react";
import { textColor } from "shared/constants/AppConst";

const Container = styled(Box)(({ theme }) => ({
  ".my_table_container": {
    width: "100%",
    marginTop: "16px",
  },
  ".table_header_cell, .table_header": {
    background: "#F0F0F0",
    color: "rgba(0,0,0,0.85)",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    fontSize: "14px",
    position: "relative",
  },
  ".table_body_cell": {
    background: "#fff",
    color: "rgba(0,0,0,0.85)",
    fontWeight: "normal",
    fontSize: "14px",
  },
  ".table_cell:first-of-type": {
    borderLeft: "1px solid  rgba(0, 0, 0, 0.09)",
    background: "#3366ff1a",
  },
  ".table_header_cell:first-of-type": {
    background: "#F0F0F0",
  },
  "thead tr:first-of-type .table_header_cell": {
    borderTop: "1px solid  rgba(0, 0, 0, 0.09)",
  },
  ".table_cell": {
    padding: "10px 12px",
    lineHeight: "20px",
    minWidth: "220px",
    borderRight: "1px solid  rgba(0, 0, 0, 0.09)",
    borderBottom: "1px solid  rgba(0, 0, 0, 0.09)",
  },
  ".table_header_cell": {
    padding: "10px 12px",
  },
  ".cursor-pointer": {
    cursor: "pointer",
  },
  ".margin-right": {
    marginRight: "8px",
  },
  ".table_row_active": {
    position: "absolute",
    top: "50%",
    right: "10px",
    transform: "translateY(-50%)",
    display: "none",
    alignItems: "center",
  },
  ".table_header_cell:hover .table_row_active": {
    display: "flex",
  },
}));

interface SettingTableProps {}

const SettingTable: React.FC<SettingTableProps> = (props: SettingTableProps) => {
  const [columns, setColumns] = React.useState<any[]>([
    {
      id: "attributes",
      label: "Attributes/Columns",
      editLabel: "Attributes/Columns",
      isEdit: false,
    },
    {
      id: "hazard_category",
      label: "Hazard Category",
      editLabel: "Hazard Category",
      isEdit: false,
    },
    {
      id: "system_hazard",
      label: "System Hazard",
      editLabel: "System Hazard",
      isEdit: false,
    },
    {
      id: "hazardous_situation",
      label: "Hazardous Situation(HS)",
      editLabel: "Hazardous Situation(HS)",
      isEdit: false,
    },
    {
      id: "risk_control",
      label: "Risk Control",
      editLabel: "Risk Control",
      isEdit: false,
    },
    {
      id: "harm",
      label: "Harm",
      editLabel: "Harm",
      isEdit: false,
    },
  ]);
  const [rows, setRows] = React.useState<any[]>([
    {
      id: 1,
      attributes: "Library Data",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 2,
      attributes: "Data Source(s)",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 3,
      attributes: "Associated Column",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 4,
      attributes: "Association Type",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 5,
      attributes: "2nd Associated Column",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 6,
      attributes: "2nd Association Type",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 7,
      attributes: "Name Unique",
      hazard_category: false,
      system_hazard: false,
      hazardous_situation: false,
      risk_control: false,
      harm: false,
    },
    {
      id: 8,
      attributes: "System Type(s)",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 9,
      attributes: "Risk Value Display Binding (Local)",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 10,
      attributes: "Risk Value Display Binding (Safety)",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 11,
      attributes: "Risk Value Display Binding (Security)",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 12,
      attributes: "Hide Column",
      hazard_category: false,
      system_hazard: false,
      hazardous_situation: false,
      risk_control: false,
      harm: false,
    },
    {
      id: 13,
      attributes: "Show Hidden Node(s)",
      hazard_category: false,
      system_hazard: false,
      hazardous_situation: false,
      risk_control: false,
      harm: false,
    },
    {
      id: 14,
      attributes: "Allow New/Rename",
      hazard_category: false,
      system_hazard: false,
      hazardous_situation: false,
      risk_control: false,
      harm: false,
    },
    {
      id: 15,
      attributes: "Allow Adding Link",
      hazard_category: false,
      system_hazard: false,
      hazardous_situation: false,
      risk_control: false,
      harm: false,
    },
    {
      id: 16,
      attributes: "Show ID as Prefix",
      hazard_category: false,
      system_hazard: false,
      hazardous_situation: false,
      risk_control: false,
      harm: false,
    },
    {
      id: 17,
      attributes: "Display Indirect Items",
      hazard_category: false,
      system_hazard: false,
      hazardous_situation: false,
      risk_control: false,
      harm: false,
    },
    {
      id: 18,
      attributes: "Header Orientation",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 19,
      attributes: "Content Orientation",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 20,
      attributes: "Split Risk Value",
      hazard_category: false,
      system_hazard: false,
      hazardous_situation: false,
      risk_control: false,
      harm: false,
    },
    {
      id: 21,
      attributes: "Risk Value Columns",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 22,
      attributes: "Attribute Columns",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 23,
      attributes: "Category",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 24,
      attributes: "CWSS",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 25,
      attributes: "CVSS",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
    {
      id: 26,
      attributes: "Interaction View",
      hazard_category: "",
      system_hazard: "",
      hazardous_situation: "",
      risk_control: "",
      harm: "",
    },
  ]);

  const renderRow = (row, columns) => {
    return (
      <TableRow hover key={row.id} sx={{ borderBottom: "1px solid  rgba(0, 0, 0, 0.09)" }} role="checkbox">
        {columns.map((column) => {
          const value = row[column.id];
          if (column.id === "attributes") {
            return (
              <TableCell
                sx={{ minWidth: column.minWidth }}
                className="table_cell table_body_cell"
                key={column.id}
                align="left"
              >
                {value}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                sx={{ minWidth: column.minWidth }}
                className="table_cell table_body_cell"
                key={column.id}
                align={column.align}
              >
                {renderCell(row, column.id)}
              </TableCell>
            );
          }
        })}
      </TableRow>
    );
  };

  const renderCell = (row, id) => {
    switch (row.attributes) {
      case "Library Data":
      case "Data Source(s)":
      case "Associated Column":
      case "Association Type":
      case "2nd Associated Column":
        return (
          <Select
            value={row[id]}
            onChange={(e) => {
              handleValue(row.id, id, e.target.value);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Test1">Test1</MenuItem>
            <MenuItem value="Test2">Test2</MenuItem>
          </Select>
        );
      case "Name Unique":
      case "Hide Column":
      case "Show Hidden Node(s)":
      case "Allow New/Rename":
      case "Allow Adding Link":
      case "Show ID as Prefix":
      case "Display Indirect Items":
      case "Split Risk Value":
        return (
          <Checkbox
            checked={row[id]}
            onChange={(e) => {
              handleValue(row.id, id, e.target.checked);
            }}
          />
        );
      case "Interaction View":
        return "None";
      default:
        return row[id];
    }
  };

  const handleValue = (rowId, id, value) => {
    const newRows = [...rows];
    const currentRow = newRows.filter((item) => item.id === rowId)[0];
    currentRow && (currentRow[id] = value);
    setRows(newRows);
  };

  const addColumn = () => {
    const newColumns = [...columns];
    const newId = "new_column_" + newColumns.length + 1;
    newColumns.push({
      id: newId,
      isEdit: false,
      label: "New Column",
      editLabel: "New Column",
    });
    const newRows = [...rows];
    newRows.map((item) => {
      switch (item.attributes) {
        case "Name Unique":
        case "Hide Column":
        case "Show Hidden Node(s)":
        case "Allow New/Rename":
        case "Allow Adding Link":
        case "Show ID as Prefix":
        case "Display Indirect Items":
        case "Split Risk Value":
          item[newId] = false;
          break;
        case "Interaction View":
          item[newId] = "None";
          break;
        default:
          item[newId] = "";
          break;
      }
      return item;
    });
    setColumns(newColumns);
    setRows(newRows);
  };

  const deleteColumn = (id) => {
    const newColumns = [...columns];
    const index = newColumns.findIndex((item) => item.id === id);
    index !== -1 && newColumns.splice(index, 1);
    const newRows = [...rows];
    newRows.map((item) => {
      delete item[id];
      return item;
    });
    setColumns(newColumns);
    setRows(newRows);
  };

  const editColumn = (id) => {
    let newColumns = [...columns];
    newColumns = newColumns.map((item) => {
      if (item.id === id) {
        item.isEdit = true;
      } else {
        item.isEdit = false;
      }
      return item;
    });
    setColumns(newColumns);
  };

  const handleEditLabel = (value, id) => {
    const newColumns = [...columns];
    const currentColumn = newColumns.filter((item) => item.id === id)[0];
    currentColumn && (currentColumn.editLabel = value);
    setColumns(newColumns);
  };

  const handelEnter = (code, id) => {
    if (code === 13) {
      const newColumns = [...columns];
      const currentColumn = newColumns.filter((item) => item.id === id)[0];
      if (currentColumn) {
        currentColumn.editLabel
          ? (currentColumn.label = currentColumn.editLabel)
          : (currentColumn.editLabel = currentColumn.label);
        currentColumn.isEdit = false;
      }
      setColumns(newColumns);
    }
  };

  return (
    <Box>
      <Box sx={{ textAlign: "right" }}>
        <Button
          variant="outlined"
          onClick={addColumn}
          startIcon={
            <AppSvgIcon color={textColor["65"]} size="20px" className="margin_right cursor">
              material-solid:add_circle
            </AppSvgIcon>
          }
        >
          <IntlMessages id="common.add.new.column" />
        </Button>
      </Box>
      <Container>
        <TableContainer className="my_table_container">
          <AppScrollbar sx={{ maxHeight: 500, width: "100%" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ background: "#F0F0F0", color: "rgba(0,0,0,0.85)", fontSize: "14px" }}>
                <TableRow className="table_header">
                  {columns.map((column) => (
                    <TableCell className="table_cell table_header_cell" key={column.id} align="left">
                      <Tooltip title={column.label} placement="top">
                        {column.isEdit ? (
                          <Box sx={{ width: "70%" }}>
                            <TextField
                              variant="outlined"
                              value={column.editLabel}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleEditLabel(event.target.value, column.id)
                              }
                              onKeyDown={(e) => {
                                handelEnter(e.keyCode, column.id);
                              }}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      alignItems: "center",
                                      "& .MuiIconButton-root": {
                                        top: 0,
                                        right: 0,
                                      },
                                    }}
                                  >
                                    <IconButton
                                      title="Clear"
                                      aria-label="Clear"
                                      size="small"
                                      style={{
                                        visibility: column.editLabel ? "visible" : "hidden",
                                      }}
                                      onClick={() => {
                                        handleEditLabel("", column.id);
                                      }}
                                    >
                                      <AppSvgIcon color="action">material-outline:clear</AppSvgIcon>
                                    </IconButton>
                                  </Box>
                                ),
                              }}
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              width: "70%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {column.label}
                          </Box>
                        )}
                      </Tooltip>
                      {column.id !== "attributes" && (
                        <Box className="table_row_active">
                          <AppSvgIcon
                            onClick={() => {
                              deleteColumn(column.id);
                            }}
                            color="action"
                            size="20px"
                            className="cursor-pointer margin-right"
                          >
                            material-solid:delete
                          </AppSvgIcon>
                          <AppSvgIcon
                            onClick={() => {
                              editColumn(column.id);
                            }}
                            color="action"
                            size="20px"
                            className="cursor-pointer"
                          >
                            material-solid:edit
                          </AppSvgIcon>
                        </Box>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {rows && rows.length > 0 && <TableBody>{rows.map((row) => renderRow(row, columns))}</TableBody>}
            </Table>
          </AppScrollbar>
        </TableContainer>
      </Container>
      <Box
        sx={{
          display: rows.length > 0 ? "none" : "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 350,
          width: "100%",
        }}
      >
        <NoDataIcon />
      </Box>
    </Box>
  );
};

export default SettingTable;
