import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  ".rich-box": {
    width: "100%",
    minHeight: "400px",
    ".ck.ck-content": {
      width: "100%",
      height: "400px",
    },
  },
}));
interface RichEditorTemplateProps {
  data?: string;
  onChange?: (data) => void;
  inEdit?: boolean;
}
const ExportRichEditorTemplate = (props: RichEditorTemplateProps) => {
  const { data, onChange, inEdit } = props;

  const [editor, setEditor] = useState<any>();

  const handleChange = (event, editor) => {
    const res = editor.getData();
    console.log(res);
    onChange && onChange(res);
  };

  useEffect(() => {
    if (editor) {
      if (!inEdit) {
        editor.enableReadOnlyMode("report-template-editor");
      } else {
        editor.disableReadOnlyMode("report-template-editor");
      }
    }
  }, [editor, inEdit]);

  return (
    <>
      <Container>
        <Box className="rich-box">
          <CKEditor
            id="report-template-editor"
            editor={ClassicEditor}
            data={data}
            onReady={(editor) => {
              setEditor(editor);
            }}
            // onChange={handleChange}
            onBlur={handleChange}
          />
        </Box>
      </Container>
    </>
  );
};
export default ExportRichEditorTemplate;
