import IntlMessages from "@crema/utility/IntlMessages";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const configMockData = {
  risk: {
    titleTextId: "common.check.applicable.metrics.for.risk.management",
    initState: {
      riskHeatMap: true,
      cycleTime: true,
      pieChart: false,
      barChart: false,
    },
  },
  dc: {
    titleTextId: "common.check.applicable.metrics.for.risk.management",
    initState: {
      riskHeatMap: true,
      cycleTime: true,
      pieChart: false,
      barChart: false,
    },
  },
  cyber: {
    titleTextId: "common.check.applicable.metrics.for.risk.management",
    initState: {
      riskHeatMap: true,
      cycleTime: true,
      pieChart: false,
      barChart: false,
    },
  },
  ac: {
    titleTextId: "common.check.applicable.metrics.for.risk.management",
    initState: {
      riskHeatMap: true,
      cycleTime: true,
      pieChart: false,
      barChart: false,
    },
  },
  production: {
    titleTextId: "common.check.applicable.metrics.for.risk.management",
    initState: {
      riskHeatMap: true,
      cycleTime: true,
      pieChart: false,
      barChart: false,
    },
  },
  if: {
    titleTextId: "common.check.applicable.metrics.for.risk.management",
    initState: {
      riskHeatMap: true,
      cycleTime: true,
      pieChart: false,
      barChart: false,
    },
  },
  req: {
    titleTextId: "common.check.applicable.metrics.for.requirement.management",
    initState: {
      riskHeatMap: false,
      cycleTime: true,
      pieChart: true,
      barChart: true,
    },
  },
};

interface MetricsProps {
  [x: string]: any;
}

const Metrics: React.FC<MetricsProps> = () => {
  const params = useParams();
  const { type } = params;

  const [state, setState] = useState({
    riskHeatMap: true,
    cycleTime: true,
    pieChart: false,
    barChart: false,
  });

  const [titleTextId, setTitleTextId] = useState("common.check.applicable.metrics.for.risk.management");

  useEffect(() => {
    if (type) {
      const mockData = configMockData[type];
      if (mockData) {
        setTitleTextId(mockData.titleTextId);
        setState(mockData.initState);
      }
    }
  }, [type]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { riskHeatMap, cycleTime, pieChart, barChart } = state;

  return (
    <Box sx={{ display: "flex" }}>
      <Box>
        <IntlMessages id={titleTextId} />
      </Box>
      <Box>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={riskHeatMap} onChange={handleChange} name="riskHeatMap" />}
              label={<IntlMessages id={"common.risk.heat.map"} />}
            />
            <FormControlLabel
              control={<Checkbox checked={cycleTime} onChange={handleChange} name="cycleTime" />}
              label={<IntlMessages id={"common.cycle.time"} />}
            />
            <FormControlLabel
              control={<Checkbox checked={pieChart} onChange={handleChange} name="pieChart" />}
              label={<IntlMessages id={"sidebar.recharts.pieChart"} />}
            />
            <FormControlLabel
              control={<Checkbox checked={barChart} onChange={handleChange} name="barChart" />}
              label={<IntlMessages id={"sidebar.recharts.barChart"} />}
            />
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Metrics;
