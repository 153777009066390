import * as go from "gojs";
const colors = {
  white: "#FFF",
  pink: "rgba(236, 128, 141, 1)",
  black: "#000",
};
export const flowData = {
  nodeData: [
    {
      key: "1_Hazard_Category",
      text: "Hazard Category",
      color: "#FFF",
      group: "group_1",
      loc: "361.15185546875 155.1451416015625",
    },
    {
      key: "1_System_Hazard",
      text: "System Hazard",
      color: "#FFF",
      group: "group_1",
      loc: "626.588134765625 155.1451416015625",
    },
    {
      key: "1_Foreseeable_Scenarios",
      text: "Foreseeable Scenarios",
      color: "rgba(236, 128, 141, 1)",
      stroke: "#000",
      group: "group_1",
      loc: "626.436279296875 217.1451416015625",
    },
    {
      key: "1_Risk_Control",
      text: "System Risk Control",
      color: "#FFF",
      stroke: "#000",
      group: "group_1",
      loc: "422.384033203125 217.1451416015625",
    },
    {
      key: "1_Hazardous_Situation",
      text: "Hazardous Situation",
      color: "rgba(236, 128, 141, 1)",
      stroke: "#000",
      group: "group_1",
      loc: "890.596923828125 217.1451416015625",
    },
    {
      key: "1_P2",
      text: "P2",
      color: "rgba(236, 128, 141, 1)",
      group: "group_1",
      loc: "998.201904296875 155.1451416015625",
    },
    {
      key: "1_Harm",
      text: "Harm",
      color: "rgba(236, 128, 141, 1)",
      group: "group_1",
      loc: "1072.59326171875 217.1451416015625",
    },
    {
      key: "1_Severity",
      text: "Severity",
      color: "rgba(236, 128, 141, 1)",
      group: "group_1",
      loc: "1227.352294921875 217.1451416015625",
    },
    {
      key: "2_Foreseeable_Scenarios",
      text: "Foreseeable Scenarios",
      color: "rgba(236, 128, 141, 1)",
      group: "group_2",
      loc: "768.385986328125 326.69390055338545",
      stroke: "#000",
    },
    {
      key: "2_Hazardous_Situation",
      text: "Hazardous Situation",
      color: "rgba(236, 128, 141, 1)",
      group: "group_2",
      loc: "954.985595703125 326.69390055338545",
      stroke: "#000",
    },
    {
      key: "2_Harm",
      text: "Harm",
      color: "rgba(236, 128, 141, 1)",
      group: "group_2",
      loc: "1210.98193359375 326.69390055338545",
    },
    {
      key: "2_P2",
      text: "P2",
      color: "rgba(236, 128, 141, 1)",
      group: "group_2",
      loc: "1104.950439453125 395.69390055338545",
    },
    {
      key: "2_Severity",
      text: "Severity",
      color: "rgba(236, 128, 141, 1)",
      group: "group_2",
      loc: "1219.174560546875 501.69390055338545",
    },
    {
      key: "2_Module_Part",
      text: "Module / Part",
      color: "#FFF",
      group: "group_2",
      loc: "98.741455078125 326.69390055338545",
    },
    {
      key: "2_Design_Function",
      text: "Design Function",
      color: "#FFF",
      group: "group_2",
      loc: "262.356689453125 326.69390055338545",
    },
    {
      key: "2_Failure_Mode",
      text: "Failure Mode",
      color: "#FFF",
      group: "group_2",
      loc: "442.413818359375 326.69390055338545",
    },
    {
      key: "2_Failure_Effect",
      text: "Failure Effect",
      color: "#FFF",
      group: "group_2",
      loc: "586.69580078125 326.69390055338545",
    },
    {
      key: "2_Risk_Control",
      text: "Risk Control",
      color: "#FFF",
      group: "group_2",
      loc: "272.196533203125 393.69390055338545",
    },
    {
      key: "2_Cause_of_Failure",
      text: "Cause of Failure",
      color: "#FFF",
      group: "group_2",
      loc: "441.8671875 393.69390055338545",
    },
    { key: "2_P1", text: "P1", color: "#FFF", group: "group_2", loc: "930.732666015625 399.69390055338545" },
    { key: "2_Ph", text: "Ph", color: "#FFF", group: "group_2", loc: "1012.923095703125 508.69390055338545" },
    { key: "2_Risk", text: "Risk", color: "#FFF", group: "group_2", loc: "1106.12353515625 618.6939005533854" },
    // {
    //   key: "group_1",
    //   isGroup: true,
    //   text: "TK#001 System Hazard Analysis (V1.0)",
    //   fill: "#FFF",
    //   loc: "739.352294921875 184.1451416015625",
    // },
    // {
    //   key: "group_2",
    //   isGroup: true,
    //   text: "PRD#001-TK#002 Design FMEA(V1.0)",
    //   fill: "#FFF",
    //   loc: "649.674560546875 472.69390055338545",
    // },
  ],
  linkData: [
    {
      key: 1,
      from: "1_System_Hazard",
      to: "1_Hazard_Category",
      dash: [6, 3],
    },
    {
      key: 2,
      from: "1_Foreseeable_Scenarios",
      to: "1_Hazardous_Situation",
    },
    {
      key: 3,
      from: "1_Foreseeable_Scenarios",
      to: "1_System_Hazard",
      dash: [6, 3],
    },
    {
      key: 4,
      from: "1_Hazardous_Situation",
      to: "1_Harm",
    },
    {
      key: 5,
      from: "1_Harm",
      to: "1_Severity",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 6,
      from: "1_Hazardous_Situation",
      to: "1_P2",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 7,
      from: "1_P2",
      to: "1_Harm",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 8,
      from: "2_Design_Function",
      to: "2_Module_Part",
      dash: [6, 3],
    },
    {
      key: 9,
      from: "2_Failure_Mode",
      to: "2_Design_Function",
      dash: [6, 3],
    },
    {
      key: 10,
      from: "2_Cause_of_Failure",
      to: "2_Failure_Mode",
    },
    {
      key: 11,
      from: "2_Risk_Control",
      to: "2_Cause_of_Failure",
      dash: [6, 3],
    },
    {
      key: 12,
      from: "2_Failure_Mode",
      to: "2_Failure_Effect",
    },
    {
      key: 13,
      from: "2_Failure_Effect",
      to: "2_Foreseeable_Scenarios",
    },
    {
      key: 14,
      from: "2_Foreseeable_Scenarios",
      to: "2_Hazardous_Situation",
    },
    {
      key: 15,
      from: "2_Hazardous_Situation",
      to: "2_Harm",
    },
    {
      key: 16,
      from: "2_Cause_of_Failure",
      to: "2_P1",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 17,
      from: "2_P1",
      to: "2_Ph",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 18,
      from: "2_P1",
      to: "2_Hazardous_Situation",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 19,
      from: "2_Hazardous_Situation",
      to: "2_P2",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 20,
      from: "2_P2",
      to: "2_Ph",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 21,
      from: "2_Ph",
      to: "2_Risk",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 22,
      from: "2_P2",
      to: "2_Harm",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 23,
      from: "2_Harm",
      to: "2_Severity",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 24,
      from: "2_Severity",
      to: "2_Risk",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: 25,
      from: "2_Foreseeable_Scenarios",
      to: "1_Foreseeable_Scenarios",
      toArrow: "CircleFork",
      color: "#02a7f0",
      arrowColor: "#02a7f0",
      dash: [12, 4, 2, 4],
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
    },
    {
      key: 26,
      from: "2_Hazardous_Situation",
      to: "1_Hazardous_Situation",
      toArrow: "CircleFork",
      color: "#02a7f0",
      arrowColor: "#02a7f0",
      dash: [12, 4, 2, 4],
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
    },
    {
      key: 27,
      from: "2_Harm",
      to: "1_Harm",
      toArrow: "CircleFork",
      color: "#02a7f0",
      arrowColor: "#02a7f0",
      dash: [12, 4, 2, 4],
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
    },
    {
      key: 28,
      from: "1_Risk_Control",
      to: "1_Foreseeable_Scenarios",
      dash: [6, 3],
    },
  ],
};

export const flowDataShared = {
  nodeData: [
    {
      key: "1_Hazard_Category",
      text: "Hazard Category",
      color: "rgba(236, 128, 141, 1)",
      group: "group_1",
      loc: "212.15185546875 -75.8548583984375",
    },
    {
      key: "1_System_Hazard",
      text: "System Hazard",
      color: "#FFF",
      group: "group_1",
      loc: "398.588134765625 -75.8548583984375",
    },
    {
      key: "1_Hazardous_Situation",
      text: "Hazardous Situation",
      color: "#FFF",
      stroke: "#000",
      group: "group_1",
      loc: "595.596923828125 -75.8548583984375",
    },
    {
      key: "1_P2",
      text: "P2",
      color: "#FFF",
      group: "group_1",
      loc: "695.201904296875 -128.8548583984375",
    },
    {
      key: "1_Harm",
      text: "Harm",
      color: "#FFF",
      group: "group_1",
      loc: "778.59326171875 -75.8548583984375",
    },
    {
      key: "1_Severity",
      text: "Severity",
      color: "#FFF",
      group: "group_1",
      loc: "888.352294921875 -75.8548583984375",
    },
    {
      key: "1_Foreseeable_Scenarios",
      text: "Foreseeable Scenarios",
      color: "#FFF",
      stroke: "#000",
      group: "group_1",
      loc: "595.436279296875 16.665814644884207",
    },
    {
      key: "_shared_box_",
      color: "#f59a23",
      figure: "Database",
      width: 24,
      height: 30,
      loc: "209.94859395005162 68.77977931985518",
    },

    {
      key: "2_Hazardous_Situation",
      text: "Hazardous Situation",
      color: "#FFF",
      stroke: "#000",
      group: "group_2",
      loc: "565.9239668964393 212.63773014634396",
    },
    {
      key: "2_P2",
      text: "P2",
      color: "#FFF",
      group: "group_2",
      loc: "694.5011964979908 141.80945800590933",
    },
    {
      key: "2_Harm",
      text: "Harm",
      color: "#FFF",
      group: "group_2",
      loc: "770.8998856658371 212.37051116523588",
    },
    {
      key: "2_Severity",
      text: "Severity",
      color: "#FFF",
      group: "group_2",
      loc: "885.6520712586724 212.437234049905",
    },
    {
      key: "2_Foreseeable_Scenarios",
      text: "Foreseeable Scenarios",
      color: "#FFF",
      stroke: "#000",
      group: "group_2",
      loc: "566.0185249960001 294.9426422373932",
    },

    {
      key: "2_Hazard_Category",
      text: "Hazard Category",
      color: "rgba(236, 128, 141, 1)",
      group: "group_2",
      loc: "208.0351287657078 212.39132500471328",
    },
    {
      key: "2_System_Hazard",
      text: "System Hazard",
      color: "#FFF",
      group: "group_2",
      loc: "373.85906431258275 212.3913250047134",
    },
    // {
    //   key: "group_1",
    //   isGroup: true,
    //   text: "TK#001 System Hazard Analysis (V1.0)",
    //   fill: "#FFF",
    //   loc: "536.352294921875 -56.09452187677665",
    // },
    // {
    //   key: "group_2",
    //   isGroup: true,
    //   text: "PRD#002-TK@001 System Hazard Analysis(V1.1)",
    //   fill: "#FFF",
    //   loc: "532.9438197387526 218.37605012165128",
    // },
  ],
  linkData: [
    {
      key: "flowDataShared1",
      from: "1_System_Hazard",
      to: "1_Hazard_Category",
      dash: [6, 3],
    },
    {
      key: "flowDataShared2",
      from: "1_Foreseeable_Scenarios",
      to: "1_Hazardous_Situation",
    },
    {
      key: "flowDataShared3",
      from: "1_Hazardous_Situation",
      to: "1_System_Hazard",
      dash: [6, 3],
    },
    {
      key: "flowDataShared4",
      from: "1_Hazardous_Situation",
      to: "1_Harm",
    },
    {
      key: "flowDataShared5",
      from: "1_Harm",
      to: "1_Severity",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: "flowDataShared6",
      from: "1_Hazardous_Situation",
      to: "1_P2",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: "flowDataShared7",
      from: "1_P2",
      to: "1_Harm",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },

    {
      key: "flowDataShared8",
      from: "2_System_Hazard",
      to: "2_Hazard_Category",
      dash: [6, 3],
    },
    {
      key: "flowDataShared9",
      from: "2_Foreseeable_Scenarios",
      to: "2_Hazardous_Situation",
    },
    {
      key: "flowDataShared10",
      from: "2_Hazardous_Situation",
      to: "2_System_Hazard",
      dash: [6, 3],
    },
    {
      key: "flowDataShared11",
      from: "2_Hazardous_Situation",
      to: "2_Harm",
    },
    {
      key: "flowDataShared12",
      from: "2_Harm",
      to: "2_Severity",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: "flowDataShared13",
      from: "2_Hazardous_Situation",
      to: "2_P2",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },
    {
      key: "flowDataShared14",
      from: "2_P2",
      to: "2_Harm",
      toArrow: "Diamond",
      color: "#7f7f7f",
      arrowColor: "#7f7f7f",
      dash: [8, 4, 2, 4],
    },

    {
      key: "flowDataShared15",
      from: "1_Hazard_Category",
      to: "_shared_box_",
      dir: 0,
      color: "#f59a23",
      dash: [12, 4, 2, 4],
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
    },
    {
      key: "flowDataShared16",
      from: "_shared_box_",
      to: "2_Hazard_Category",
      dir: 0,
      color: "#f59a23",
      dash: [12, 4, 2, 4],
      routing: {
        class: "go.EnumValue",
        classType: "Link",
        name: "Normal",
      },
    },
  ],
};
