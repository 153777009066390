import IntlMessages from "@crema/utility/IntlMessages";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import MyTextFieldNoLabel from "components/MyTextFieldNoLabel";
import { useFormik } from "formik";
import * as go from "gojs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { bgColor, borderColor, textColor } from "shared/constants/AppConst";

import RichEditorTemplate from "./components/RichEditorTemplate";

const reportMockData = {
  "System Hazard Analysis": {
    tableMock: [
      {
        id: 1,
        filed: "filed1",
        name: "Id",
        value: "",
      },
      {
        id: 2,
        filed: "filed2",
        name: "Name",
        value: "System Hazard Analysis",
      },
      {
        id: 3,
        filed: "filed3",
        name: "NO",
        value: "",
      },
      {
        id: 4,
        filed: "filed4",
        name: "Version",
        value: "",
      },
    ],
    richEditorTemplate: {
      html: "<h2><strong>1 Scope</strong></h2><h4><strong>1.1 Description</strong></h4><p>&nbsp; &nbsp; Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus</p><h4><strong>1.2 Objective</strong></h4><p>&nbsp; &nbsp; Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus</p><h2><strong>2 Rules</strong></h2><h2><strong>3 Potential Risks</strong></h2><h2><strong>4 Risks Traceability</strong></h2>",
    },
    richEditorTreeData: [
      {
        id: "1 Scope",
        value: "1 Scope",
        isBold: true,
        items: [
          {
            id: "1.1 Description",
            value: "1.1 Description",
          },
          {
            id: "1.2 Objective",
            value: "1.2 Objective",
          },
        ],
      },
      {
        id: "2 Rules",
        value: "2 Rules",
      },
      {
        id: "3 Potential Risks",
        value: "3 Potential Risks",
      },
      {
        id: "4 Risks Traceability",
        value: "4 Risks Traceability",
      },
    ],
    attachmentSetting: {
      files: true,
      worksheet: true,
      comparison: true,
      checklist: true,
      metrics: true,
    },
  },
  "User Needs": {
    tableMock: [
      {
        id: 1,
        filed: "filed1",
        name: "Id",
        value: "",
      },
      {
        id: 2,
        filed: "filed2",
        name: "Name",
        value: "User Needs",
      },
      {
        id: 3,
        filed: "filed3",
        name: "NO",
        value: "",
      },
      {
        id: 4,
        filed: "filed4",
        name: "Version",
        value: "",
      },
    ],
    richEditorTemplate: {
      html: "<h2><strong>1 Overview</strong></h2><h2><strong>2 User Types</strong></h2><h2><strong>3 User Needs</strong></h2><h2><strong>4 User Stories</strong></h2><h2><strong>5 User Cases</strong></h2>",
    },
    richEditorTreeData: [
      {
        id: "1 Overview",
        value: "1 Overview",
      },
      {
        id: "2 User Types",
        value: "2 User Types",
      },
      {
        id: "3 User Needs",
        value: "3 User Needs",
      },
      {
        id: "4 User Stories",
        value: "4 User Stories",
      },
      {
        id: "4 User Cases",
        value: "4 User Cases",
      },
    ],
    attachmentSetting: {
      files: true,
      worksheet: true,
      comparison: true,
      checklist: true,
      metrics: true,
    },
  },
  "Design Input Requirements": {
    tableMock: [
      {
        id: 1,
        filed: "filed1",
        name: "Id",
        value: "",
      },
      {
        id: 2,
        filed: "filed2",
        name: "Name",
        value: "Design Input Requirements",
      },
      {
        id: 3,
        filed: "filed3",
        name: "NO",
        value: "",
      },
      {
        id: 4,
        filed: "filed4",
        name: "Version",
        value: "",
      },
    ],
    richEditorTemplate: {
      html: "<h2><strong>1 Introduction</strong></h2><h4><strong>&nbsp; &nbsp; 1.1 Purpose</strong></h4><h4><strong>&nbsp; &nbsp; 1.2 Scope</strong></h4><p><strong>&nbsp; &nbsp; 1.3 References</strong></p><h2><strong>2 General Overview</strong></h2><h2><strong>3 Requirements</strong></h2><h2><strong>4 Nonfunctional Requirements</strong></h2>",
    },
    richEditorTreeData: [
      {
        id: "1 Introduction",
        value: "1 Introduction",
        isBold: true,
        items: [
          {
            id: "1.1 Purpose",
            value: "1.1 Purpose",
          },
          {
            id: "1.2 Scope",
            value: "1.2 Scope",
          },
          {
            id: "1.2 References",
            value: "1.2 References",
          },
        ],
      },
      {
        id: "2 General Overview",
        value: "2 General Overview",
      },
      {
        id: "3 Requirements",
        value: "3 Requirements",
      },
      {
        id: "4 Nonfunctional Requirements",
        value: "4 Nonfunctional Requirements",
      },
    ],
    attachmentSetting: {
      files: true,
      worksheet: true,
      comparison: true,
      checklist: true,
      metrics: true,
    },
  },
  "Test Cases": {
    tableMock: [
      {
        id: 1,
        filed: "filed1",
        name: "Id",
        value: "",
      },
      {
        id: 2,
        filed: "filed2",
        name: "Name",
        value: "Test Case Specification",
      },
      {
        id: 3,
        filed: "filed3",
        name: "NO",
        value: "",
      },
      {
        id: 4,
        filed: "filed4",
        name: "Version",
        value: "",
      },
    ],
    richEditorTemplate: {
      html: "<h2><strong>1 Introduction</strong></h2><h4><strong>&nbsp; &nbsp; 1.1 Purpose</strong></h4><h4><strong>&nbsp; &nbsp; 1.2 Scope</strong></h4><p><strong>&nbsp; &nbsp; 1.3 References</strong></p><h2><strong>2 Test Items</strong></h2><h2><strong>3 Approach Refinement</strong></h2><h2><strong>4 Test Specification</strong></h2>",
    },
    richEditorTreeData: [
      {
        id: "1 Introduction",
        value: "1 Introduction",
        isBold: true,
        items: [
          {
            id: "1.1 Purpose",
            value: "1.1 Purpose",
          },
          {
            id: "1.2 Scope",
            value: "1.2 Scope",
          },
          {
            id: "1.2 References",
            value: "1.2 References",
          },
        ],
      },
      {
        id: "2 Test Items",
        value: "2 Test Items",
      },
      {
        id: "3 Approach Refinement",
        value: "3 Approach Refinement",
      },
      {
        id: "4 Test Specification",
        value: "4 Test Specification",
      },
    ],
    attachmentSetting: {
      files: true,
      worksheet: true,
      comparison: true,
      checklist: true,
      metrics: true,
    },
  },
  "Design Control Traceability Matrix": {
    tableMock: [
      {
        id: 1,
        filed: "filed1",
        name: "Id",
        value: "",
      },
      {
        id: 2,
        filed: "filed2",
        name: "Name",
        value: "Design Control Traceability Matrix",
      },
      {
        id: 3,
        filed: "filed3",
        name: "NO",
        value: "",
      },
      {
        id: 4,
        filed: "filed4",
        name: "Version",
        value: "",
      },
    ],
    richEditorTemplate: {
      html: "<h2><strong>1 Introduction</strong></h2><h4><strong>&nbsp; &nbsp; 1.1 Purpose</strong></h4><h4><strong>&nbsp; &nbsp; 1.2 Scope</strong></h4><p><strong>&nbsp; &nbsp; 1.3 References</strong></p><h2><strong>2 General Overview</strong></h2><h2><strong>3 Traceability Matrix</strong></h2><h2><strong>4 Coverage</strong></h2>",
    },
    richEditorTreeData: [
      {
        id: "1 Introduction",
        value: "1 Introduction",
        isBold: true,
        items: [
          {
            id: "1.1 Purpose",
            value: "1.1 Purpose",
          },
          {
            id: "1.2 Scope",
            value: "1.2 Scope",
          },
          {
            id: "1.2 References",
            value: "1.2 References",
          },
        ],
      },
      {
        id: "2 General Overview",
        value: "2 General Overview",
      },
      {
        id: "3 Traceability Matrix",
        value: "3 Traceability Matrix",
      },
      {
        id: "4 Coverage",
        value: "4 Coverage",
      },
    ],
    attachmentSetting: {
      files: true,
      worksheet: true,
      comparison: true,
      checklist: true,
      metrics: true,
    },
  },
};
const mockData = {
  tableMock: [
    {
      id: 1,
      filed: "filed1",
      name: "Id",
      value: "",
    },
    {
      id: 2,
      filed: "filed2",
      name: "Name",
      value: "System Hazard Analysis",
    },
    {
      id: 3,
      filed: "filed3",
      name: "NO",
      value: "",
    },
    {
      id: 4,
      filed: "filed4",
      name: "Version",
      value: "",
    },
  ],
  richEditorTemplate: {
    html: "<h2><strong>1 Scope</strong></h2><h4><strong>1.1 Description</strong></h4><p>&nbsp; &nbsp; Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus</p><h4><strong>1.2 Objective</strong></h4><p>&nbsp; &nbsp; Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus</p><h2><strong>2 Rules</strong></h2><h2><strong>3 Potential Risks</strong></h2><h2><strong>4 Risks Traceability</strong></h2>",
  },
  attachmentSetting: {
    files: true,
    worksheet: true,
    comparison: true,
    checklist: true,
    metrics: true,
  },
  workflowConfiguration: {
    nodeData: [
      { key: 0, text: "Draft", color: "#63A103" },
      { key: 1, text: "In Review", color: "#00FFFF" },
      { key: 2, text: "Approved", color: "#016FA0" },
      { key: 3, text: "Rejected", color: "#D9001B" },
    ],
    linkData: [
      { key: -1, from: 0, to: 1, fromSpot: go.Spot.Right, toSpot: go.Spot.Left },
      { key: -2, from: 1, to: 2, fromSpot: go.Spot.Right, toSpot: go.Spot.Left },
      { key: -3, from: 1, to: 3, fromSpot: go.Spot.Bottom, toSpot: go.Spot.Right },
      { key: -4, from: 3, to: 0, fromSpot: go.Spot.Left, toSpot: go.Spot.Bottom },
    ],
  },
};

const EditFormContainer = styled(Box)(({ theme }) => ({
  ".in_view": {
    ".MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
    ".filed_label": {
      display: "none",
    },
  },
  ".in_edit": {
    ".filed_label": {
      width: 0,
      height: "32px",
      position: "relative",
      float: "left",
      "& sup": {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: "5px",
      },
    },
  },
  ".fields_table": {
    display: "block",
    padding: theme.spacing(4, 0, 0, 0),
    ".table_header_cell, .table_header": {
      background: bgColor.tableHeaderColor,
      color: textColor[85],
      fontWeight: "bold",
      letterSpacing: "0.15px",
      fontSize: "14px",
    },
    ".table_body_cell": {
      background: theme.palette.background.paper,
      color: textColor[85],
      fontWeight: "normal",
      fontSize: "14px",
    },
    ".table_cell": {
      width: "25%",
      border: `1px solid  ${borderColor.dividerColor}`,
    },
  },

  ".rich_editor": {
    display: "block",
  },
}));
const CardContainer = styled(Card)(({ theme }) => ({
  "&.MuiCard-root": {
    padding: theme.spacing(0),
    boxShadow: "none",
    borderRadius: 0,
    ".MuiCardHeader-root": {
      padding: theme.spacing(4, 0),
    },
  },
}));
interface ReportProps {
  editable?: boolean;
}

const Report = (props: ReportProps) => {
  const params = useParams();
  const { pid } = params;

  const { editable = true } = props;

  const [inEdit, setInEdit] = useState(false);
  const [oldFormData, setOldFormData] = useState(null);
  const [fields, setFields] = useState([...mockData.tableMock]);
  const [richEditorTemplate, setRichEditorTemplate] = useState({
    ...mockData.richEditorTemplate,
  });
  const [attachmentSetting, setAttachmentSetting] = useState({
    ...mockData.attachmentSetting,
  });

  const [indexTree, setIndexTree] = useState([]);

  useEffect(() => {
    if (pid) {
      const mockDataObj = reportMockData[pid];
      if (mockDataObj) {
        setRichEditorTemplate(mockDataObj.richEditorTemplate);
        setFields(mockDataObj.tableMock);
        setAttachmentSetting(mockDataObj.attachmentSetting);
        setIndexTree(mockDataObj.richEditorTreeData);
      }
    }
  }, [pid]);

  const formik = useFormik({
    initialValues: {
      files: true,
      worksheet: true,
      comparison: true,
      checklist: true,
      metrics: true,
    },
    onSubmit: (data, { setSubmitting, resetForm }) => {
      console.log(data);
      resetForm();
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (fields) {
      fields.forEach((x) => {
        formik.setFieldValue(`${x.filed}_name`, x.name);
        formik.setFieldValue(`${x.filed}_value`, x.value);
      });
    }
    if (attachmentSetting) {
      formik.setFieldValue("files", attachmentSetting.files);
      formik.setFieldValue("worksheet", attachmentSetting.worksheet);
      formik.setFieldValue("comparison", attachmentSetting.comparison);
      formik.setFieldValue("checklist", attachmentSetting.checklist);
      formik.setFieldValue("metrics", attachmentSetting.metrics);
    }
  }, [fields, attachmentSetting]);

  const handleEditClick = useCallback(() => {
    setOldFormData({
      fields: [...fields],
      richEditorTemplate: { ...richEditorTemplate },
      attachmentSetting: { ...attachmentSetting },
    });
    setInEdit(true);
  }, [fields, richEditorTemplate, attachmentSetting]);
  const handleSaveClick = useCallback(() => {
    setInEdit(false);
    const values = formik.values;
    // save in state
    const newFields = [...fields];

    newFields.forEach((x) => {
      x.name = values[`${x.filed}_name`];
      x.value = values[`${x.filed}_value`];
    });

    setFields(newFields);
    // setRichEditorTemplate();
    setAttachmentSetting({
      files: values.files,
      worksheet: values.worksheet,
      comparison: values.comparison,
      checklist: values.checklist,
      metrics: values.metrics,
    });

    setOldFormData(null);

    const submitDom = document.getElementById("report_save_submit");
    submitDom && submitDom.click();
    // Do data save logic
  }, [fields, formik.values]);

  const handleCancelClick = useCallback(() => {
    setFields(oldFormData.fields);
    setRichEditorTemplate(oldFormData.richEditorTemplate);
    setAttachmentSetting(oldFormData.attachmentSetting);

    setOldFormData(null);
    setInEdit(false);
  }, [oldFormData]);

  const editSaveBtns = useMemo(() => {
    if (!editable) {
      return <></>;
    }
    if (inEdit) {
      return (
        <Box>
          <Button
            className="text-color-08"
            sx={{
              mr: 3,
              borderRadius: "4px",
              border: "solid 1px rgba(0, 0, 0, 0.15)",
              color: textColor["85"],
            }}
            variant="outlined"
            disabled={formik.isSubmitting}
            startIcon={
              <AppSvgIcon size={16} color={textColor["65"]}>
                {"material-solid:cancel"}
              </AppSvgIcon>
            }
            onClick={handleCancelClick}
            id="report_cancel"
          >
            <IntlMessages id="common.cancel" />
          </Button>
          <Button
            variant="contained"
            disabled={formik.isSubmitting}
            startIcon={
              <AppSvgIcon size={16} color="#FFF">
                {"material-solid:save"}
              </AppSvgIcon>
            }
            onClick={handleSaveClick}
            id="report_save_submit"
          >
            <IntlMessages id="common.save" />
          </Button>
        </Box>
      );
    }
    return (
      <Button
        variant="outlined"
        color="inherit"
        startIcon={<AppSvgIcon size={16}>{"material-solid:edit"}</AppSvgIcon>}
        onClick={handleEditClick}
      >
        <IntlMessages id="common.edit" />
      </Button>
    );
  }, [editable, inEdit, formik.isSubmitting, handleEditClick, handleSaveClick, handleCancelClick]);

  const tableRows = useMemo(() => {
    if (!fields || fields.length <= 0) {
      return <></>;
    }

    const rows = [];
    const rowsCount = Math.ceil(fields.length / 2);
    for (let r = 0; r < rowsCount; r += 1) {
      rows.push(
        <TableRow key={r}>
          <TableCell className="table_cell table_body_cell">
            <MyTextFieldNoLabel
              name={`${fields[2 * r].filed}_name`}
              fullWidth
              disabled={!inEdit}
              formik={formik}
              onChange={(e) => {
                formik.setFieldValue(`${fields[2 * r].filed}_name`, e.target.value);
              }}
              value={formik.values[`${fields[2 * r].filed}_name`] || ""}
            />
          </TableCell>
          <TableCell className="table_cell table_body_cell">
            <MyTextFieldNoLabel
              name={`${fields[2 * r].filed}_value`}
              fullWidth
              disabled={!inEdit}
              formik={formik}
              onChange={(e) => {
                formik.setFieldValue(`${fields[2 * r].filed}_value`, e.target.value);
              }}
              value={formik.values[`${fields[2 * r].filed}_value`] || ""}
            />
          </TableCell>
          {fields[2 * r + 1] ? (
            <>
              <TableCell className="table_cell table_body_cell">
                <MyTextFieldNoLabel
                  name={`${fields[2 * r + 1].filed}_name`}
                  fullWidth
                  disabled={!inEdit}
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldValue(`${fields[2 * r + 1].filed}_name`, e.target.value);
                  }}
                  value={formik.values[`${fields[2 * r + 1].filed}_name`] || ""}
                />
              </TableCell>
              <TableCell className="table_cell table_body_cell">
                <MyTextFieldNoLabel
                  name={`${fields[2 * r + 1].filed}_value`}
                  fullWidth
                  disabled={!inEdit}
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldValue(`${fields[2 * r + 1].filed}_value`, e.target.value);
                  }}
                  value={formik.values[`${fields[2 * r + 1].filed}_value`] || ""}
                />
              </TableCell>
            </>
          ) : (
            <>
              <TableCell className="table_cell table_body_cell"></TableCell>
              <TableCell className="table_cell table_body_cell"></TableCell>
            </>
          )}
        </TableRow>
      );
    }
    return <TableBody>{rows}</TableBody>;
  }, [fields, inEdit, formik]);

  // useEffect(() => {
  //   richtext.setValue(richEditorTemplate.html, "html");
  // }, [richEditorTemplate]);

  const handleCustomizeFieldClick = () => {
    const lastId = fields[fields.length - 1].id;
    const newFiled = {
      id: lastId + 1,
      filed: `filed${lastId + 1}`,
      name: "",
      value: "",
    };

    setFields([...fields, newFiled]);
  };

  return (
    <>
      <EditFormContainer>
        <form
          onSubmit={formik.handleSubmit}
          onReset={formik.handleReset}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          noValidate
          autoComplete="off"
          className={inEdit ? "in_edit" : "in_view"}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box></Box>
            {editSaveBtns}
          </Box>
          <Box className="fields_table">
            <TableContainer>
              <Table>{tableRows}</Table>
            </TableContainer>
            {inEdit ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "16px 0",
                }}
              >
                <Box></Box>
                <Button
                  onClick={handleCustomizeFieldClick}
                  variant="outlined"
                  startIcon={<AppSvgIcon color="action">{"material-outline:add"}</AppSvgIcon>}
                >
                  <IntlMessages id="setting.process.report.customizeField" />
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Box>
          <Box className="rich_editor">
            <CardContainer>
              <CardHeader sx={{}} title={<IntlMessages id="setting.process.report.richTextTitle" />}></CardHeader>
              <CardContent>
                <RichEditorTemplate
                  treeData={indexTree}
                  data={richEditorTemplate.html}
                  inEdit={inEdit}
                  onChange={(data) => {
                    setRichEditorTemplate({
                      html: data,
                    });
                  }}
                />
              </CardContent>
            </CardContainer>
          </Box>
          <Box className="attachments_settings">
            <CardContainer>
              <CardHeader
                sx={{}}
                title={<IntlMessages id="setting.process.report.attachmentsSettingTitle" />}
              ></CardHeader>
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={4} md={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="files"
                            disabled={!inEdit}
                            onChange={(e) => {
                              formik.setFieldValue("files", e.target.checked);
                            }}
                            checked={formik.values.files}
                          />
                        }
                        label={<IntlMessages id="setting.process.report.attachments.files" />}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="worksheet"
                            disabled={!inEdit}
                            onChange={(e) => {
                              formik.setFieldValue("worksheet", e.target.checked);
                            }}
                            checked={formik.values.worksheet}
                          />
                        }
                        label={<IntlMessages id="setting.process.report.attachments.worksheet" />}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="comparison"
                            disabled={!inEdit}
                            onChange={(e) => {
                              formik.setFieldValue("comparison", e.target.checked);
                            }}
                            checked={formik.values.comparison}
                          />
                        }
                        label={<IntlMessages id="setting.process.report.attachments.comparison" />}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checklist"
                            disabled={!inEdit}
                            onChange={(e) => {
                              formik.setFieldValue("checklist", e.target.checked);
                            }}
                            checked={formik.values.checklist}
                          />
                        }
                        label={<IntlMessages id="setting.process.report.attachments.checklist" />}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="metrics"
                            disabled={!inEdit}
                            onChange={(e) => {
                              formik.setFieldValue("metrics", e.target.checked);
                            }}
                            checked={formik.values.metrics}
                          />
                        }
                        label={<IntlMessages id="setting.process.report.attachments.metrics" />}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </CardContent>
            </CardContainer>
          </Box>
          <Box sx={{ display: "none" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{
                  position: "relative",
                  minWidth: 100,
                }}
                id="submit"
                color="primary"
                variant="contained"
                disabled={formik.isSubmitting}
                type="submit"
              >
                <IntlMessages id="common.save" />
              </Button>
            </Box>
          </Box>
        </form>
      </EditFormContainer>
    </>
  );
};

export default Report;
