import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CachedIcon from "@mui/icons-material/Cached";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import MyComponent from "components";
import CustomizeIcon from "components/Icons/CustomizeIcon";
import { taskData } from "mock/index";
import React from "react";
import { useParams } from "react-router-dom";
import { borderColor, textColor } from "shared/constants/AppConst";

const treeDataSet: any[] = [
  {
    id: "Design Verifications",
    value: "Design Verifications",
    items: [
      {
        id: "Test Cases",
        value: "Test Cases",
        items: [
          {
            id: "Fever Detection",
            value: "Fever Detection",
          },
          {
            id: "Cleaning",
            value: "Cleaning",
          },
        ],
      },
    ],
  },
];

const TreeContainer = styled(Box)(({ theme }) => ({}));

interface TreeProps {}

const MyTree: React.FC<TreeProps> = () => {
  const routeParam = useParams();
  const [mockData, setMockData] = React.useState(null);

  React.useEffect(() => {
    routeParam?.type && setMockData(taskData[routeParam.type]);
  }, [routeParam]);

  const [selected, setSelected] = React.useState<any>(routeParam?.type === "und" ? "User Needs" : "Test Cases");

  const onNodeSelect = (event: object, value: string) => {
    setSelected(value);
  };

  return (
    <TreeContainer>
      <Box
        sx={{
          py: 3,
          borderBottom: `1px solid ${borderColor.dividerColor}`,
          px: 3,
          mb: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Button
            sx={{
              color: "#2E6CCB",
            }}
            onClick={() => {}}
            startIcon={<AddCircleIcon sx={{ fontSize: "16px !important" }} color="primary" />}
          >
            <IntlMessages id="common.add" />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& svg": {
              cursor: "pointer",
            },
          }}
        >
          <CachedIcon sx={{ mr: 2, fontSize: "16px" }} />
          <CustomizeIcon />
        </Box>
      </Box>
      <AppScrollbar sx={{ height: "calc(100vh - 256px)" }}>
        <MyComponent.MyTree
          selected={selected}
          onNodeSelect={onNodeSelect}
          data={mockData?.treeDataSet ? mockData.treeDataSet : treeDataSet}
          sx={{
            ".not-last-child>.MuiTreeItem-content>.MuiTreeItem-label": {
              fontWeight: "bold",
            },
          }}
        />
      </AppScrollbar>
    </TreeContainer>
  );
};

export default MyTree;
