import Icon from "@mui/material/Icon";
import * as React from "react";

const RequirementSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <rect fill="#FAC31E" opacity=".1" width="20" height="20" rx="4" />
      <g fill="#FAC31E" fillRule="nonzero">
        <path d="M12.276 16.196H7.711c0 .72.584 1.304 1.304 1.304h1.957c.72 0 1.304-.584 1.304-1.304zM9.993 2.5a5.55 5.55 0 0 1 5.544 5.543 5.52 5.52 0 0 1-3.26 5.05v2.45H7.71v-2.45a5.52 5.52 0 0 1-3.261-5.05A5.55 5.55 0 0 1 9.993 2.5zM7.06 7.065a.815.815 0 1 0 0 1.63.815.815 0 0 0 0-1.63zm2.934 0a.815.815 0 1 0 0 1.63.815.815 0 0 0 0-1.63zm2.935 0a.815.815 0 1 0 0 1.63.815.815 0 0 0 0-1.63z" />
      </g>
    </g>
  </svg>
);

const RequirementIcon: React.FunctionComponent = () => {
  return <Icon component={RequirementSvg} />;
};

export default RequirementIcon;
