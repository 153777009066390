import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import TemplateHeader from "../components/TemplateHeader";

const Container = styled(Box)(({ theme }) => ({
  ".template-content-layout": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "calc(100vh - 104px)",
    ".MuiCard-root": {
      boxShadow: "none",
    },
  },
}));

interface TemplateItemsProps {
  titleText: string;
}

const ExcelDefault = (props: TemplateItemsProps) => {
  const { titleText } = props;
  return (
    <>
      <Container>
        <TemplateHeader title={titleText} />
        <Card></Card>
      </Container>
    </>
  );
};

export default ExcelDefault;
