import AppScrollbar from "@crema/core/AppScrollbar";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { borderColor } from "shared/constants/AppConst";

import Report from "../../Report";
import ExcelTable from "./ExcelTable";
import ImpactSummary from "./ImpactSummary";
import Tree from "./Tree";
import TreeGrid from "./TreeGrid";

const DeliverablesContainer = styled(Box)(({ theme }) => ({
  marginTop: "8px",
  minWidth: "1000px",
  ".content-layout": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderTop: `1px solid ${borderColor.dividerColor}`,
    height: "calc(65vh)",
  },
  ".left-container": {
    width: "20%",
    minWidth: "280px",
    height: "calc(65vh + 1px)",
    borderRight: `1px solid ${borderColor.dividerColor}`,
    backgroundColor: theme.palette.background.paper,
  },
  ".right-container": {
    width: "80%",
    height: "calc(65vh + 1px)",
  },
}));

interface ToolBarProps {
  deliverablesVersion: any;
  deliverablesProduct: any;
  deliverableType: any;
  processData: any;
  setProcessData: (item: any) => void;
}

const Deliverables: React.FC<ToolBarProps> = (props: ToolBarProps) => {
  const { setProcessData, processData, deliverableType } = props;

  const [layoutType, setLayoutType] = React.useState(1); // 1---excelTable   2---treeGrid

  React.useEffect(() => {
    if (processData?.id === "User Needs" || processData?.id === "Test Cases") {
      setLayoutType(2);
    } else {
      setLayoutType(1);
    }
  }, [processData]);

  const getPage = React.useMemo(() => {
    switch (deliverableType?.id) {
      case "1":
        if (layoutType === 1) {
          return <ExcelTable id={processData?.id} />;
        } else {
          return <TreeGrid id={processData?.id} />;
        }
      case "2":
        return <ImpactSummary />;
      case "3":
        return <Report />;
      default:
        return "";
    }
  }, [deliverableType, layoutType, processData]);
  return (
    <DeliverablesContainer>
      <Box className="content-layout">
        <AppScrollbar className="left-container">{<Tree handleSelectProcess={setProcessData} />}</AppScrollbar>
        {processData && <AppScrollbar className="right-container">{getPage}</AppScrollbar>}
      </Box>
    </DeliverablesContainer>
  );
};

export default Deliverables;
