import ButtonMenu from "./ButtonMenu";
import CustomTable from "./CustomTable";
import ExportDropdown from "./ExportDropdown";
import ForwardAndBackward from "./ForwardAndBackward";
import GeneralStats from "./GeneralStats";
import MySelectField from "./MySelectField";
import MyTextField from "./MyTextField";
import MyTree from "./MyTree";
import MyTreeTable from "./MyTreeTable";
import pageHeader from "./PageHeader";
import SearchStarredAndRecently from "./SearchStarredAndRecently";
import SimplePopup from "./SimplePopup";

const MyComponent = {
  GeneralStats: GeneralStats,
  pageHeader: pageHeader,
  CustomTable: CustomTable,
  MyTextField: MyTextField,
  MySelectField: MySelectField,
  SimplePopup: SimplePopup,
  ButtonMenu: ButtonMenu,
  SearchStarredAndRecently: SearchStarredAndRecently,
  ExportDropdown: ExportDropdown,
  ForwardAndBackward: ForwardAndBackward,
  MyTree: MyTree,
  MyTreeTable: MyTreeTable,
};

export default MyComponent;
