import IntlMessages from "@crema/utility/IntlMessages";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import AppSvgIcon from "components/AppSvgIcon";
import ExportIcon from "components/Icons/ExportIcon";
import WordIcon from "components/Icons/WordIcon";
import React from "react";

interface RowHeightProps {
  changeRowHeight: (height) => void;
  sxStyle?: any;
  variant?: string;
}

const RowHeightAction: React.FC<RowHeightProps> = (props) => {
  const { changeRowHeight, sxStyle, variant } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openList = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const rowHeights = [{
    height: 20,
    label: "Small",
    icon: "density_small"
  }, {
    height: 32,
    label: "Medium",
    icon: "density_medium"
  }, {
    height: 50,
    label: "Tall",
    icon: "density_large"
  }]

  return (
    <>
      <Button
        sx={{
          marginLeft: 2,
          color: "rgba(0,0,0,0.85)",
        }}
        variant={variant ? variant : "text"}
        onClick={handleClick}
        startIcon={<AppSvgIcon size={16}>material-outline:format_line_spacing</AppSvgIcon>}
        endIcon={openList ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        {...sxStyle}
      >
        Row Height
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {
          rowHeights.map(d => {
            return (
              <MenuItem onClick={() => { changeRowHeight(d.height); }} >
                <AppSvgIcon size={16}>{`material-outline:${d.icon}`}</AppSvgIcon>
                <Typography sx={{ pl: 2 }}>{d.label} </Typography>
              </MenuItem>
            )
          })
        }
      </Menu>
    </>
  );
};

export default RowHeightAction;
