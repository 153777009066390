import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, InputAdornment, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import CustomTable from "components/CustomTable";
import React from "react";
import { borderColor, boxShadow, textColor } from "shared/constants/AppConst";
import { Column } from "types/models/Common";

import ContentHeader from "../components/ContentHeader";

const columns: Column[] = [
  {
    id: "type",
    label: "common.license.type",
    minWidth: 150,
    align: "left",
  },
  {
    id: "purchased",
    label: "common.of.licenses.purchased",
    minWidth: 150,
    align: "left",
  },
  {
    id: "seats",
    label: "common.available.seats",
    minWidth: 150,
    align: "left",
  },
  {
    id: "register",
    label: "common.resister",
    minWidth: 150,
    align: "left",
  },
  {
    id: "admin",
    label: "common.admin",
    minWidth: 150,
    align: "left",
  },
  {
    id: "super_user",
    label: "common.super.user",
    minWidth: 150,
    align: "left",
  },
  {
    id: "normal_user",
    label: "common.normal.user",
    minWidth: 150,
    align: "left",
  },
  {
    id: "read_only_user",
    label: "common.read.only.user",
    minWidth: 150,
    align: "left",
  },
  {
    id: "seats_assigned",
    label: "common.of.seats.assigned",
    minWidth: 150,
    align: "left",
  },
  {
    id: "seats_open",
    label: "common.of.seats.open",
    minWidth: 200,
    align: "left",
  },
];
const initRows = [
  {
    id: "1",
    type: "Fixed",
    purchased: "5",
    seats: "5",
    register: "1",
    admin: "3",
    super_user: "1",
    normal_user: "0",
    read_only_user: "NA",
    seats_assigned: "5",
    seats_open: "0",
  },
  {
    id: "2",
    type: "Floating",
    purchased: "2",
    seats: "4",
    register: "NA",
    admin: "NA",
    super_user: "1",
    normal_user: "0",
    read_only_user: "NA",
    seats_assigned: "1",
    seats_open: "3",
  },
  {
    id: "3",
    type: "Fixed Read-only",
    purchased: "2",
    seats: "2",
    register: "NA",
    admin: "NA",
    super_user: "NA",
    normal_user: "NA",
    read_only_user: "1",
    seats_assigned: "1",
    seats_open: "1",
  },
  {
    id: "4",
    type: "Floating Read-only",
    purchased: "1",
    seats: "2",
    register: "NA",
    admin: "NA",
    super_user: "NA",
    normal_user: "NA",
    read_only_user: "0",
    seats_assigned: "0",
    seats_open: "2",
  },
  {
    id: "5",
    type: "Total",
    purchased: "10",
    seats: "13",
    register: "1",
    admin: "3",
    super_user: "2",
    normal_user: "0",
    read_only_user: "1",
    seats_assigned: "6",
    seats_open: "7",
  },
];
const initRows1 = [
  {
    id: "1",
    type: "Fixed",
    userType: "Admin",
    user: "Aaron Zhang(Aaron),Peng Zhang(Zhang Peng), sAm gReen(zhangp)",
  },
  {
    id: "2",
    type: "Fixed",
    userType: "Super User",
    user: "Elizai Chan(elizai)",
  },
  {
    id: "3",
    type: "Fixed",
    userType: "Normal User",
    user: "",
  },
  {
    id: "4",
    type: "Floating",
    userType: "Super User",
    user: "Peng Zhang(Peng Zhang)",
  },
  {
    id: "5",
    type: "Floating",
    userType: "Normal User",
    user: "",
  },
  {
    id: "6",
    type: "Fixed Read-only",
    userType: "Read-only",
    user: "Tang lan(Julia)",
  },
  {
    id: "7",
    type: "Floating Read-only",
    userType: "Read-only User",
    user: "",
  },
  {
    id: "8",
    type: "Unassigned",
    userType: "------",
    user: "Al Evans(tally)",
  },
  {
    id: "9",
    type: "Fixed",
    userType: "Register",
    user: "Eval Admin(evaladmin)",
  },
];

const LicensesContainer = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const Licenses = () => {
  const [toolType, setToolType] = React.useState("1");
  const [rows, setRows] = React.useState<any[]>(initRows);
  const [rows1, setRows1] = React.useState<any[]>(initRows1);
  const [editRow, setEditRow] = React.useState<any>(null);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const handleToolType = (event: SelectChangeEvent) => {
    setToolType(event.target.value);
  };

  const columns1: Column[] = [
    {
      id: "type",
      label: "common.license.type",
      minWidth: 150,
      align: "left",
    },
    {
      id: "userType",
      label: "common.user.type",
      minWidth: 150,
      align: "left",
    },
    {
      id: "user",
      label: "common.users",
      minWidth: 500,
      align: "left",
    },
    {
      id: "action",
      label: "setting.product.column.actions",
      minWidth: 100,
      align: "left",
      format: (_, record) => {
        return (
          <AppSvgIcon
            size={16}
            className="cursor-pointer"
            onClick={() => {
              setEditRow(record);
              setShowEditModal(true);
            }}
            color={textColor["65"]}
          >
            {"material-solid:edit"}
          </AppSvgIcon>
        );
      },
    },
  ];

  return (
    <LicensesContainer>
      <ContentHeader titleId="admin.nav.item.licenses">
        <Box sx={{ display: "inline-block", verticalAlign: "middle" }}>
          <Select
            sx={{
              m: 2,
              minWidth: 60,
              background: "#fff",
              "& .MuiInputAdornment-root": {
                color: textColor[85],
              },
              "& .MuiTypography-body1": {
                color: textColor[85],
              },
              "& .MuiInputBase-input": {
                padding: "6px 0px",
                color: textColor[85],
              },
            }}
            id="tool-type-select"
            value={toolType}
            onChange={handleToolType}
            startAdornment={<InputAdornment position="start">Tool Type:</InputAdornment>}
          >
            <MenuItem value="1">Risk and Assurance Case Management</MenuItem>
          </Select>
        </Box>
      </ContentHeader>
      <AppScrollbar
        sx={{
          width: "100%",
          height: "calc(100vh - 104px)",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            padding: (theme) => theme.spacing(5),
            pt: 0,
            background: (theme) => theme.palette.background.paper,
            boxShadow: boxShadow.cardBoxShadow,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box
              className="flex items-center"
              sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
            >
              <h3>
                <IntlMessages id="common.license.summary" />
              </h3>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ mr: 2, color: "rgba(0,0,0,0.85)" }}>License Expiration Date: 2024/12/1</Box>
              <AppSvgIcon color={textColor["65"]}>{"material-outline:refresh"}</AppSvgIcon>
            </Box>
          </Box>
          <Box sx={{ width: "100%", mt: 2, borderTop: `1px solid ${borderColor.dividerColor}` }}>
            <Box>
              <CustomTable columns={columns} rows={rows} showPage={false} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            padding: (theme) => theme.spacing(5),
            pt: 0,
            background: (theme) => theme.palette.background.paper,
            mt: (theme) => theme.spacing(5),
            boxShadow: boxShadow.cardBoxShadow,
          }}
        >
          <Box
            className="flex items-center"
            sx={{ height: "48px", fontSize: "16px", fontWeight: "bold", lineHeight: 1.5, color: textColor[85] }}
          >
            <h3>
              <IntlMessages id="common.user.accounts" />
            </h3>
          </Box>
          <Box sx={{ width: "100%", mt: 2, borderTop: `1px solid ${borderColor.dividerColor}` }}>
            <Box>
              <CustomTable columns={columns1} rows={rows1} showPage={false} />
            </Box>
          </Box>
        </Box>
      </AppScrollbar>
    </LicensesContainer>
  );
};

export default Licenses;
