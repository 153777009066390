import IntlMessages from "@crema/utility/IntlMessages";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import MyTree from "components/MyTree";
import * as go from "gojs";
import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { borderColor, textColor } from "shared/constants/AppConst";

import Diagram from "./components/Diagram";
import PreviewModal from "./components/PreviewModal";
import SettingModal from "./components/SettingModal";
import TransferList from "./components/TransferList";

const statusLists: any[] = [
  {
    id: "1",
    label: "Draft",
  },
  {
    id: "2",
    label: "In Review",
  },
  {
    id: "3",
    label: "Approved",
  },
];

const worksheetFormatMockData = {
  sha: {
    format: "1",
    diagramData: {
      nodeData: [
        { key: 0, text: "Hazard Category", group: "group_1", loc: "59.15185546875 114.1451416015625" },
        { key: 1, text: "System Hazard", group: "group_1", loc: "233.588134765625 114.1451416015625" },
        { key: 3, text: "Foreseeable Scenarios", group: "group_1", loc: "375.436279296875 23.1451416015625" },
        { key: 2, text: "Hazardous Situation", group: "group_1", loc: "399.596923828125 114.94514465332031" },
        { key: 5, text: "Harm", group: "group_1", loc: "533.59326171875 115.1451416015625" },
        { key: 6, text: "Severity", color: "lightgreen", group: "group_1", loc: "678.344970703125 115.1451416015625" },
        { key: 4, text: "P2", color: "lightgreen", group: "group_1", loc: "532.569091796875 59.1451416015625" },
        { key: "group_1", isGroup: true, text: "", fill: "#FFF", loc: "354.8486328125 69.1451416015625" },
      ],
      linkData: [
        { key: 4, from: 3, to: 2, color: "rgba(0,0,0,0.85)" },
        { key: 1, from: 1, to: 0, dash: [6, 3], toArrow: "Standard" },
        { key: 7, from: 5, to: 6, toArrow: "Diamond", dash: [6, 3] },
        { key: 3, from: 2, to: 1, dash: [6, 3], toArrow: "Standard" },
        { key: 6, from: 2, to: 5, color: "rgba(0,0,0,0.85)" },
        { key: 5, from: 2, to: 4, toArrow: "Diamond", dash: [6, 3] },
        { key: 2, from: 4, to: 5, toArrow: "Diamond", dash: [6, 3] },
      ],
    },
  },
  und: {
    format: "1",
    dataSourceDataSet: [
      {
        id: "Design Inputs",
        value: "Design Inputs",
        isBold: true,
        items: [
          {
            id: "User Needs",
            value: "User Needs",
          },
        ],
      },
    ],
    transferListSourceLeft: [
      {
        id: 1,
        name: "Global ID",
        displayOrder: 1,
      },
      {
        id: 2,
        name: "API ID",
        displayOrder: 2,
      },
      {
        id: 3,
        name: "Description",
        displayOrder: 3,
      },
      {
        id: 4,
        name: "Status",
        displayOrder: 4,
      },
      {
        id: 5,
        name: "Locked",
        displayOrder: 5,
      },
      {
        id: 6,
        name: "Item Type",
        displayOrder: 6,
      },
      {
        id: 7,
        name: "Product Code",
        displayOrder: 7,
      },
      {
        id: 8,
        name: "Created By",
        displayOrder: 8,
      },
      {
        id: 9,
        name: "Created Date",
        displayOrder: 9,
      },
      {
        id: 10,
        name: "Last Locked By",
        displayOrder: 10,
      },
      {
        id: 11,
        name: "Last locked",
        displayOrder: 11,
      },
      {
        id: 12,
        name: "Product",
        displayOrder: 12,
      },
      {
        id: 13,
        name: "# of Upstream Relationships",
        displayOrder: 13,
      },
      {
        id: 14,
        name: "# of Downstream Relationships",
        displayOrder: 14,
      },
      {
        id: 15,
        name: "# of Comments",
        displayOrder: 15,
      },
      {
        id: 16,
        name: "Tags",
        displayOrder: 16,
      },
    ],
    transferListSourceRight: [
      {
        id: 17,
        name: "Display ID",
        displayOrder: 17,
      },
      {
        id: 18,
        name: "User Types",
        displayOrder: 18,
      },
      {
        id: 19,
        name: "Name",
        displayOrder: 19,
      },
      {
        id: 20,
        name: "Priority",
        displayOrder: 20,
      },
      {
        id: 21,
        name: "Rationale",
        displayOrder: 21,
      },
    ],
  },
  dir: {
    format: "1",
    diagramData: {
      nodeData: [
        { key: "ID", text: "ID", group: "group_1", loc: "367.172119140625 -22.8548583984375" },
        { key: "Priority", text: "Priority", group: "group_1", loc: "446.704833984375 -26.8548583984375" },
        {
          key: "Requirements Criticality",
          text: "Requirements Criticality",
          group: "group_1",
          loc: "571.827392578125 -29.8548583984375",
        },
        { key: "Rationale", text: "Rationale", group: "group_1", loc: "712.446044921875 -30.8548583984375" },
        { key: "Revision", text: "Revision", group: "group_1", loc: "813.334228515625 -29.8548583984375" },
        {
          key: "Design Input Requirements",
          text: "Design Input Requirements",
          group: "group_1",
          loc: "558.88818359375 135.1451416015625",
        },
        { key: "UND Links", text: "UND Links", group: "group_1", loc: "791.9404296875 135.1451416015625" },
        { key: "Verifications", text: "Verifications", group: "group_1", loc: "559.55810546875 201.1451416015625" },
        { key: "group_1", isGroup: true, text: "", fill: "#FFF", loc: "599.698486328125 85.14514160156247" },
      ],
      linkData: [
        { key: 4, from: "Design Input Requirements", to: "Verifications", color: "rgba(0,0,0,0.85)" },
        { key: 1, from: "UND Links", to: "Design Input Requirements", dash: [6, 3], toArrow: "Standard" },
        {
          key: 7,
          from: "Design Input Requirements",
          to: "ID",
          toArrow: "Diamond",
          color: "#7f7f7f",
          arrowColor: "#7f7f7f",
          dash: [8, 4, 2, 4],
        },
        {
          key: 3,
          from: "Design Input Requirements",
          to: "Priority",
          toArrow: "Diamond",
          color: "#7f7f7f",
          arrowColor: "#7f7f7f",
          dash: [8, 4, 2, 4],
        },
        {
          key: 6,
          from: "Design Input Requirements",
          to: "Requirements Criticality",
          toArrow: "Diamond",
          color: "#7f7f7f",
          arrowColor: "#7f7f7f",
          dash: [8, 4, 2, 4],
        },
        {
          key: 5,
          from: "Design Input Requirements",
          to: "Rationale",
          toArrow: "Diamond",
          color: "#7f7f7f",
          arrowColor: "#7f7f7f",
          dash: [8, 4, 2, 4],
        },
        {
          key: 2,
          from: "Design Input Requirements",
          to: "Revision",
          toArrow: "Diamond",
          color: "#7f7f7f",
          arrowColor: "#7f7f7f",
          dash: [8, 4, 2, 4],
        },
      ],
    },
  },
  tc: {
    format: "1",
    dataSourceDataSet: [
      {
        id: "Design Verification",
        value: "Design Verification",
        isBold: true,
        items: [
          {
            id: "User Needs",
            value: "User Needs",
          },
        ],
      },
    ],
    transferListSourceLeft: [
      {
        id: 1,
        name: "Global ID",
        displayOrder: 1,
      },
      {
        id: 2,
        name: "API ID",
        displayOrder: 2,
      },
      {
        id: 3,
        name: "Description",
        displayOrder: 3,
      },
      {
        id: 4,
        name: "Priority",
        displayOrder: 4,
      },
      {
        id: 5,
        name: "Rationale",
        displayOrder: 5,
      },

      {
        id: 6,
        name: "Status",
        displayOrder: 6,
      },
      {
        id: 7,
        name: "Locked",
        displayOrder: 7,
      },
      {
        id: 8,
        name: "Item Type",
        displayOrder: 8,
      },
      {
        id: 9,
        name: "Product Code",
        displayOrder: 9,
      },
      {
        id: 10,
        name: "Created By",
        displayOrder: 10,
      },
      {
        id: 11,
        name: "Created Date",
        displayOrder: 11,
      },
      {
        id: 12,
        name: "Last Locked By",
        displayOrder: 12,
      },
      {
        id: 13,
        name: "Last locked",
        displayOrder: 13,
      },
      {
        id: 14,
        name: "Product",
        displayOrder: 14,
      },
      {
        id: 15,
        name: "# of Downstream Relationships",
        displayOrder: 15,
      },
      {
        id: 16,
        name: "# of Comments",
        displayOrder: 16,
      },
      {
        id: 17,
        name: "Tags",
        displayOrder: 17,
      },
    ],
    transferListSourceRight: [
      {
        id: 18,
        name: "ID",
        displayOrder: 18,
      },
      {
        id: 19,
        name: "Name",
        displayOrder: 19,
      },
      {
        id: 20,
        name: "Test Steps",
        displayOrder: 20,
      },
      {
        id: 21,
        name: "Acceptance Criteria",
        displayOrder: 21,
      },
      {
        id: 22,
        name: "Equipment/Supplies  Needed",
        displayOrder: 22,
      },
      {
        id: 23,
        name: "# of Upstream Relationships",
        displayOrder: 23,
      },
      {
        id: 24,
        name: "Result",
        displayOrder: 24,
      },
    ],
  },
  tm: {
    format: "1",
    diagramData: {
      nodeData: [
        { key: "User Needs", text: "User Needs", group: "group_1", loc: "150.15185546875 22.945144653320312" },
        { key: "Validations", text: "Validations", group: "group_1", loc: "151.588134765625 89.1451416015625" },
        {
          key: "Design Input Requirements",
          text: "Design Input Requirements",
          group: "group_1",
          loc: "323.436279296875 23.1451416015625",
        },
        {
          key: "System Verifications",
          text: "System Verifications",
          group: "group_1",
          loc: "322.596923828125 88.94514465332031",
        },
        {
          key: "HW Subsystem Requirements",
          text: "HW Subsystem Requirements",
          group: "group_1",
          loc: "575.59326171875 -49.8548583984375",
        },
        {
          key: "SW Subsystem Requirements",
          text: "SW Subsystem Requirements",
          group: "group_1",
          loc: "571.344970703125 23.1451416015625",
        },
        {
          key: "Design Specifications",
          text: "Design Specifications",
          group: "group_1",
          loc: "546.569091796875 99.1451416015625",
        },
        {
          key: "HW Verifications",
          text: "HW Verifications",
          group: "group_1",
          loc: "802.569091796875 -49.8548583984375",
        },
        {
          key: "SW Verifications",
          text: "SW Verifications",
          group: "group_1",
          loc: "804.569091796875 23.1451416015625",
        },
        {
          key: "DMR Design Outputs",
          text: "DMR Design Outputs",
          group: "group_1",
          loc: "766.569091796875 99.1451416015625",
        },
        { key: "IQ/OQ/PQ", text: "IQ/OQ/PQ", group: "group_1", loc: "739.569091796875 159.1451416015625" },
        { key: "group_1", isGroup: true, text: "", fill: "#FFF", loc: "484.60791015625 54.645141601562486" },
      ],
      linkData: [
        { key: 1, from: "User Needs", to: "Validations", color: "rgba(0,0,0,0.85)" },
        { key: 2, from: "User Needs", to: "Design Input Requirements" },
        { key: 3, from: "Design Input Requirements", to: "System Verifications" },
        {
          key: 4,
          from: "Design Input Requirements",
          to: "HW Subsystem Requirements",
          dash: [6, 3],
          toArrow: "Standard",
          routing: go.Link.Normal,
        },
        {
          key: 5,
          from: "Design Input Requirements",
          to: "SW Subsystem Requirements",
          dash: [6, 3],
          toArrow: "Standard",
          routing: go.Link.Normal,
        },
        {
          key: 6,
          from: "Design Input Requirements",
          to: "Design Specifications",
          toArrow: "Standard",
          dash: [6, 3],
          routing: go.Link.Normal,
        },
        { key: 7, from: "HW Subsystem Requirements", to: "HW Verifications" },
        { key: 8, from: "SW Subsystem Requirements", to: "SW Verifications" },
        { key: 9, from: "Design Specifications", to: "DMR Design Outputs", toArrow: "Standard", dash: [6, 3] },
        { key: 10, from: "Design Specifications", to: "IQ/OQ/PQ", routing: go.Link.Normal },
      ],
    },
  },
};

interface SettingsModalProps {
  moduleType: string;
}

const SettingsModal: React.FC<SettingsModalProps> = ({ moduleType }) => {
  const [selected, setSelected] = React.useState<any>("");

  const onNodeSelect = (event: object, value: string) => {
    setSelected(value);
  };

  const { messages } = useIntl();
  const [showSettingModal, setShowSettingModal] = React.useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = React.useState<boolean>(false);
  const [format, setFormat] = React.useState<string>("1");
  useEffect(() => {
    if (moduleType) {
      const mockData = worksheetFormatMockData[moduleType];
      if (mockData) {
        setFormat(mockData.format);
      }
    }
  }, [moduleType]);

  const contentReactNode = useMemo(() => {
    if (!moduleType) {
      return <></>;
    }
    const mockData = worksheetFormatMockData[moduleType];
    switch (moduleType) {
      case "und":
      case "tc":
        let dataSourceDataSet = [];
        let tLeft = [];
        let rRight = [];
        if (mockData) {
          dataSourceDataSet = mockData.dataSourceDataSet;
          if (!selected) setSelected(dataSourceDataSet[0].id);
          tLeft = mockData.transferListSourceLeft;
          rRight = mockData.transferListSourceRight;
        }
        return (
          <>
            <Box className="flex justify-between">
              <Box
                className="w-1/4"
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <Box
                  className="title"
                  sx={{
                    p: 5,
                  }}
                >
                  <IntlMessages id="process.scope.changeScope" />
                </Box>
                <MyTree
                  selected={selected}
                  onNodeSelect={onNodeSelect}
                  data={dataSourceDataSet}
                  useStyledTree={true}
                  showFolder={true}
                  sx={{
                    width: "100%",
                    height: "90%",
                  }}
                />
              </Box>
              <Box
                className="w-3/4"
                sx={{
                  p: 5,
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  borderLeft: "0",
                }}
              >
                <Box className="w-full text-center" sx={{ p: 5 }}>
                  <IntlMessages id="process.worksheet.item.type" />
                  <Typography component="span" sx={{ fontWeight: "500" }}>
                    {moduleType === "und" ? "User Needs" : "Test Cases"}
                  </Typography>
                </Box>
                <TransferList initLeft={tLeft} initRight={rRight} />
              </Box>
            </Box>
          </>
        );

      case "tm":
      case "dir":
      case "sha":
      default:
        let nodeData = [];
        let linkData = [];
        if (mockData && mockData.diagramData) {
          if (mockData.diagramData) {
            nodeData = mockData.diagramData.nodeData;
            linkData = mockData.diagramData.linkData;
          }
        }
        return (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Button
                  onClick={() => {
                    setShowSettingModal(true);
                  }}
                  sx={{
                    color: textColor[85],
                    border: `solid 1px ${borderColor.dividerColor}`,
                    fontWeight: "normal",
                    mr: 2,
                  }}
                  variant="outlined"
                >
                  <IntlMessages id="common.show.settings.table" />
                </Button>
                <Button
                  sx={{
                    color: textColor[85],
                    border: `solid 1px ${borderColor.dividerColor}`,
                    fontWeight: "normal",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setShowPreviewModal(true);
                  }}
                >
                  <IntlMessages id="common.preview" />
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{
                    color: textColor[85],
                    border: `solid 1px ${borderColor.dividerColor}`,
                    fontWeight: "normal",
                    mr: 2,
                  }}
                  startIcon={<AddCircleIcon />}
                  variant="outlined"
                >
                  <IntlMessages id="common.column" />
                </Button>
                <Button
                  sx={{
                    color: textColor[85],
                    border: `solid 1px ${borderColor.dividerColor}`,
                    fontWeight: "normal",
                  }}
                  variant="outlined"
                  onClick={() => {}}
                >
                  <IntlMessages id="common.legends" />
                </Button>
              </Box>
            </Box>
            <Box sx={{ width: "100%", height: "220px", mt: 2 }}>
              <Diagram nodeData={nodeData} linkData={linkData} />
            </Box>
            {showSettingModal && (
              <SettingModal
                open={showSettingModal}
                handleClose={() => {
                  setShowSettingModal(false);
                }}
              />
            )}
            {showPreviewModal && (
              <PreviewModal
                open={showPreviewModal}
                handleClose={() => {
                  setShowPreviewModal(false);
                }}
              />
            )}
          </>
        );
    }
  }, [moduleType, selected, showPreviewModal, showSettingModal]);

  const [status, setStatus] = React.useState(statusLists[0]?.id);

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  return (
    <Box>
      <Box
        sx={{
          mb: 3,
        }}
      >
        <Box className="toolbar-left" sx={{ mb: 4 }}>
          <Select
            sx={{
              minWidth: 60,
              m: 0,
              background: "#fff",
              "& .MuiInputAdornment-root": {
                color: textColor[85],
              },
              "& .MuiTypography-body1": {
                color: textColor[85],
              },
              "& .MuiInputBase-input": {
                padding: "6px 0px",
                color: textColor[85],
              },
            }}
            id="status-select"
            value={status}
            onChange={handleStatusChange}
            startAdornment={<InputAdornment position="start">Status:</InputAdornment>}
          >
            {statusLists.map((profile) => (
              <MenuItem key={profile.id} value={profile.id}>
                {profile.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ color: textColor[85], fontWeight: "bold" }}>
            <IntlMessages id="common.select.format" />
          </Box>
          <Box>
            <FormControl sx={{ ml: 2 }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="1"
                value={format}
                onChange={(e) => {
                  setFormat(e.target.value);
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label={<IntlMessages id="common.excel.table" />} />
                <FormControlLabel value="2" control={<Radio />} label={<IntlMessages id="common.tree.table" />} />
                <FormControlLabel value="3" control={<Radio />} label={<IntlMessages id="common.assurance.case" />} />
                <FormControlLabel value="4" control={<Radio />} label={<IntlMessages id="common.fault.tree" />} />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>
      {contentReactNode}
    </Box>
  );
};

export default SettingsModal;
