import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppSvgIcon from "components/AppSvgIcon";
import React, { useCallback, useEffect, useMemo, useState } from "react";

const RuleEnumLabel: { [key: string]: string } = {
  AutomaticPassDownstream: "setting.rule.tab.P2.automaticPassDownstream", // For P2
  Manual: "setting.rule.tab.Ph.manual", // For Ph, Acceptance
  FormulaP1XP2: "setting.rule.tab.Ph.formulaP1XP2", // Formula (Ph = P1 * P2), For Ph
  FormulaP1AddP2: "setting.rule.tab.Ph.formulaP1AddP2", // Formula (Ph = P1 + P2), For Ph
  FormulaAccPhXSev: "setting.rule.tab.acceptance.formulaAccPhXSev", // Formula (Acc = Ph * Sev) ,For Acceptance
  FormulaAccPhAddSev: "setting.rule.tab.acceptance.formulaAccPhAddSev", // Formula (Acc = Ph + Sev), For Acceptance
  LookupTable: "setting.rule.tab.acceptance.lookupTable",
};

interface RadioItem {
  labelKey: string;
  value: string | number;
}

export interface GroupItem {
  key: string;
  defaultValue: boolean | string | number;
  type: "checkbox" | "radio";
  /** if radio: this filed is required. */
  radioList?: RadioItem[];
}
interface CheckboxRadioGroupProps {
  groups: GroupItem[];
  selected?: CheckboxGroupOutputModel;
  onGroupChange: (output: CheckboxGroupOutputModel) => void;
}

const CheckboxRadioGroup = (props: CheckboxRadioGroupProps) => {
  const { groups, selected, onGroupChange } = props;
  const [output, setOutput] = useState(selected || {});

  useEffect(() => {
    const o = {};
    groups.forEach((g) => {
      o[g.key] = selected ? selected[g.key] : g.defaultValue;
    });
    setOutput(o);
  }, []);

  const handleBoxesChange = useCallback(
    (key: string, value: boolean | string | number) => {
      const newOutput = {
        ...output,
        [key]: value,
      };
      setOutput(newOutput);
      onGroupChange(newOutput);
    },
    [onGroupChange, output]
  );
  const renderBox = useMemo(() => {
    if (!groups || groups.length === 0) {
      return <></>;
    }
    const boxes = [];

    groups.forEach((g) => {
      if (g.type === "checkbox") {
        const box = (
          <Box key={`checkbox_${g.key}`}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name={g.key}
                    onChange={(e, checked) => {
                      handleBoxesChange(g.key, checked);
                    }}
                    checked={(output[g.key] as boolean) || false}
                  />
                }
                label={<IntlMessages id={RuleEnumLabel[g.key]} />}
              />
            </FormGroup>
          </Box>
        );
        boxes.push(box);
      } else {
        const box = g.radioList && (
          <FormControl key={`radio_${g.key}`}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name={g.key}
              value={output[g.key] || g.defaultValue}
              onChange={(e) => {
                handleBoxesChange(g.key, e.target.value);
              }}
            >
              {g.radioList.map((r) => {
                return (
                  <FormControlLabel
                    key={`${g.key}_${r.labelKey}`}
                    value={r.value}
                    control={<Radio value={r.value} />}
                    label={<IntlMessages id={RuleEnumLabel[r.labelKey]} />}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );
        boxes.push(box);
      }
    });
    return boxes;
  }, [groups, handleBoxesChange, output]);

  return (
    <>
      <Box>{renderBox}</Box>
    </>
  );
};

export default CheckboxRadioGroup;

export interface CheckboxGroupOutputModel {
  [key: string]: boolean | string | number;
}
