import "handsontable/dist/handsontable.full.css";

import { HotTable } from "@handsontable/react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { registerAllModules } from "handsontable/registry";
import React from "react";
import { bgColor, textColor } from "shared/constants/AppConst";

const ExcelTableContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  ".heaer-style": {
    fontWeight: "bold",
  },
}));

const data = [
  {
    category: "Hazard Category",
    system_hazard: "System Hazard",
    situation: "Hazardous Situation",
    causes: "Sequence of Events/Causes",
    harm: "Harm",
    safety: "Harm: Safety Severity",
    p2: "HS: Safety P2",
  },
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
];

const columns = [
  {
    data: "category",
  },
  {
    data: "system_hazard",
  },
  {
    data: "situation",
  },
  {
    data: "causes",
  },
  {
    data: "harm",
  },
  {
    data: "safety",
  },
  {
    data: "p2",
  },
];

interface ExcelTableProps {}

const ExcelTable: React.FC<ExcelTableProps> = (props: ExcelTableProps) => {
  const [hotSettings, setHotSettings] = React.useState<any>({
    // data: createSpreadsheetData(100, 26),
    data: data,
    columns: columns,
    // autoColumnSize: { syncLimit: 5 },
    rowHeaders: true,
    colHeaders: true,
    fixedRowsTop: 1,
    fixedColumnsStart: 1,
    cells: (row) => {
      if (row === 0) {
        return {
          readOnly: true,
          className: "heaer-style",
        };
      }
    },
    dropdownMenu: false,
    collapsibleColumns: true,
    filters: false,
    // colWidths: 120,
    readOnly: true,
    manualColumnResize: true,
    manualRowResize: true,
    colWidths: 120,
    rowHeights: 32,
    width: "100%",
    height: "100%",
    // stretchH: "'all'", // 'none' is default
    manualColumnMove: false,
    manualRowMove: false,
    // fixedRowsTop: 1,
    // fixedColumnsStart: 1,
    // colHeaders: [
    //   "Hazard Category",
    //   "System Hazard",
    //   "Hazardous Situation",
    //   "Sequence of Events/Causes",
    //   "Harm",
    //   "Harm: Safety Severity",
    //   "HS: Safety P2",
    // ],
    // columns: [
    //   {
    //     editor: CustomEditor
    //   }
    // ],
    contextMenu: false,
    licenseKey: "non-commercial-and-evaluation",
  });

  registerAllModules();

  const beforeOnCellMouseDown = (mouseEvent, coords, td) => {
    if (mouseEvent.button === 2) {
      var cellCoords = { row: coords.row, col: coords.col };
      if (coords.row >= -1) {
        //if (coords.col >-1) {
        setMenuItemsVisible(cellCoords);
      }
    }
  };

  const setMenuItemsVisible = (cellCoords) => {
    console.log(cellCoords);
    if (cellCoords.row === -1) {
      setHotSettings({
        ...hotSettings,
        contextMenu: false,
      });
    } else {
      setHotSettings({
        ...hotSettings,
        contextMenu: false,
      });
    }
  };

  return (
    <ExcelTableContainer>
      <Box
        id="spreadsheet"
        sx={{
          width: "100%",
          height: "300px",
          ".handsontable .htDimmed": {
            background: "#FFF",
            color: textColor[85],
            fontSize: "14px",
            fontFamily: "Roboto",
          },
          ".handsontable thead tr th": {
            color: textColor[85],
            background: bgColor.tableHeaderColor,
            fontWeight: "bold",
            height: "39px !important",
            fontSize: "14px",
            fontFamily: "Roboto",
          },
          ".handsontable thead th .relative": {
            padding: "9px 4px",
          },
        }}
      >
        <HotTable settings={hotSettings} stretchH={"all"} beforeOnCellMouseDown={beforeOnCellMouseDown} />
      </Box>
    </ExcelTableContainer>
  );
};

export default ExcelTable;
